import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import SICLogo from 'assets/images/SIC-Logo.svg';
import SICSLogo from 'assets/images/SIC-S-logo.png';
import Logo from 'assets/images/Logo.svg';
import clsx from 'clsx';
import { BreakpointsFlags } from 'shared/hooks/useDevice';
import React from 'react';
import { RefComponent } from 'types/ref-component';
import { Tab } from 'types/tab';
import { Tooltip, Skeleton, Stack } from '@mui/material';
import styles from './override-mui-styles';
import './styles.scss';
import MenuTab from './tab';
import { CmtCountries, CountryCode } from 'types/common';

interface Props {
  isOpen: boolean;
  tabs: Tab[];
  activeTab: Tab | null | undefined;
  optionsTabs?: Tab[];
  menuRef: RefComponent<HTMLDivElement>;
  breakpoints: BreakpointsFlags;
  onpenDrawer: (value: boolean) => void;
  onDrawerOpen(): any;
  onDrawerClose(): any;
  onTabClicked(tab: Tab): any;
  hideOptions?: boolean;
}

const SideMenu = (props: Props): JSX.Element => {
  const classes = styles();
  const { isDesktop } = props.breakpoints;
  const countryCode = localStorage.getItem('countryCode');

  const skeletonLoader = (): JSX.Element => {
    return (
      <Stack spacing={1} alignItems="center" justifyContent="center">
        <Skeleton
          variant="circular"
          width={44}
          height={44}
          sx={{ margin: '0.4rem' }}
        />
        <Skeleton variant="rectangular" width={210} height={500} />
      </Stack>
    );
  };

  return (
    <Drawer
      variant={isDesktop ? 'permanent' : 'temporary'}
      className={clsx('drawer', classes.drawer, {
        [classes.drawerOpen]: props.isOpen,
        [classes.drawerClose]: !props.isOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.isOpen,
          [classes.drawerClose]: !props.isOpen,
        }),
      }}
      onMouseEnter={() => {
        if (countryCode && !CmtCountries.includes(countryCode as CountryCode)) {
          props.onDrawerOpen();
        }
      }}
      onMouseLeave={() => {
        if (countryCode && !CmtCountries.includes(countryCode as CountryCode)) {
          props.onDrawerClose();
        }
      }}
      open={props.isOpen}
      onClose={() => props.onpenDrawer(false)}
      ModalProps={{
        keepMounted: true,
      }}
    >
      {!countryCode ? (
        skeletonLoader()
      ) : (
        <>
          <div className={clsx(classes.toolbar)}>
            <IconButton className={clsx(classes.toolbarButton)}>
              {props.isOpen ? (
                <img
                  src={
                    CmtCountries.includes(countryCode as CountryCode)
                      ? Logo
                      : SICLogo
                  }
                  className="drawer__sicLogo--large"
                  alt="SICLogo"
                />
              ) : (
                <img
                  src={
                    CmtCountries.includes(countryCode as CountryCode)
                      ? Logo
                      : SICSLogo
                  }
                  className="drawer__sicLogo"
                  alt="SICSLogo"
                />
              )}
            </IconButton>
          </div>
          {!props.hideOptions && (
            <div className="drawer__tabsContainer drawer__ListContent">
              <div className="top">
                <List>
                  {props.tabs
                    .filter((f) => f.isActive === true)
                    .map((tab) => {
                      if (CmtCountries.includes(countryCode as CountryCode)) {
                        return (
                          <React.Fragment key={tab.value}>
                            {tab.subtabs &&
                              tab.subtabs
                                .filter((f) => f.isActive === true)
                                .map((subtab) => {
                                  const tooltipTitle = subtab.label;

                                  return (
                                    <Tooltip
                                      key={subtab.value}
                                      title={tooltipTitle}
                                      placement="right"
                                    >
                                      <div className={classes.menuTabContainer}>
                                        <MenuTab
                                          key={subtab.value}
                                          tab={subtab}
                                          activeTab={props.activeTab}
                                          isOpen={props.isOpen}
                                          onTabClicked={props.onTabClicked}
                                          countryCode={countryCode}
                                        />
                                      </div>
                                    </Tooltip>
                                  );
                                })}
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <MenuTab
                            key={tab.value}
                            tab={tab}
                            activeTab={props.activeTab}
                            isOpen={props.isOpen}
                            onTabClicked={props.onTabClicked}
                          >
                            <React.Fragment key={tab.value}>
                              {tab.subtabs &&
                                tab.subtabs
                                  .filter((f) => f.isActive === true)
                                  .map((subtab) => (
                                    <MenuTab
                                      key={subtab.value}
                                      tab={subtab}
                                      activeTab={props.activeTab}
                                      isOpen={props.isOpen}
                                      onTabClicked={props.onTabClicked}
                                      countryCode={countryCode}
                                    />
                                  ))}
                            </React.Fragment>
                          </MenuTab>
                        );
                      }
                    })}
                </List>
              </div>
              <div className="bottom">
                <List>
                  {props.optionsTabs &&
                    props.optionsTabs
                      .filter((f) => f.isActive === true)
                      .map((tab) => {
                        const tooltipTitle =
                          tab.value === 'settings'
                            ? 'Configuración'
                            : tab.value === 'logout'
                            ? 'Cerrar sesión'
                            : '';

                        return (
                          <Tooltip
                            key={tab.value}
                            title={tooltipTitle}
                            placement="right"
                            disableHoverListener={
                              !CmtCountries.includes(countryCode as CountryCode)
                            }
                          >
                            <div className={classes.menuTabContainer}>
                              <MenuTab
                                key={tab.value}
                                tab={tab}
                                activeTab={props.activeTab}
                                isOpen={props.isOpen}
                                onTabClicked={props.onTabClicked}
                              />
                            </div>
                          </Tooltip>
                        );
                      })}
                </List>
              </div>
            </div>
          )}
        </>
      )}
    </Drawer>
  );
};

export default SideMenu;
