import { useEffect, useState } from 'react';
import { DEVICES_DIMENS } from 'static/constants/devices-dimens';

export interface BreakpointsFlags {
  width: number;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

const useDevice = (): BreakpointsFlags => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    width: width,
    isMobile: width < DEVICES_DIMENS.MOBILE,
    isTablet: width < DEVICES_DIMENS.TABLET && width >= DEVICES_DIMENS.MOBILE,
    isDesktop: width >= DEVICES_DIMENS.TABLET,
  };
};

export default useDevice;
