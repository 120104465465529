import React, { FunctionComponent } from 'react';
import { IPreInspectionState } from 'types/pre-inspection';
import preInspectReducer, { initialState } from './preInspectionReducer';

export const PreInspectionContext = React.createContext<
  IPreInspectionState | any
>(initialState);

export const PreInspectionProvider: FunctionComponent = (
  props,
): JSX.Element => {
  const [state, dispatch] = React.useReducer(preInspectReducer, initialState);

  return (
    <PreInspectionContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </PreInspectionContext.Provider>
  );
};
