import React from 'react';
import { Stack, Typography } from '@mui/material';
import Home from '../../../assets/icons/home.svg';
import User from '../../../assets/icons/usuario.svg';
import { CountryCode } from 'types/common';

interface DetailsProps {
  data: any;
  styles?: React.CSSProperties;
  classes?: string;
  label: string;
  value: string;
  hiddenDate?: boolean;
  count?: number;
  clickUrl?: boolean;
  handleChange?: any;
}

const Details: React.FC<DetailsProps> = ({
  data,
  styles,
  classes,
  label,
  value,
  hiddenDate,
  count,
  clickUrl,
  handleChange,
}) => {
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    return date.toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  return (
    <Stack direction="column" alignItems="flex-start" style={styles}>
      <Stack direction="row" alignItems="center" style={{ width: '100%' }}>
        <Stack direction="column" alignItems="flex-start">
          <Typography className={classes}>
            {label}
            {clickUrl ? (
              <a href="#" onClick={(e) => handleChange(e, data)}>
                <span>{value}</span>
              </a>
            ) : (
              <span>{value}</span>
            )}
          </Typography>
          {!hiddenDate && <span>{formatDate(data.eventDate)}</span>}
        </Stack>
        {count && <span className="count">({count})</span>}
      </Stack>
      <div className="account">
        {data.companyName && (
          <div className="details">
            <img src={Home} alt="icon"></img>
            <span>{data.companyName || ''}</span>
          </div>
        )}
        {localStorage.getItem('countryCode') !== CountryCode.PRI && (
          <div className="details">
            <img src={User} alt="icon"></img>
            <span>
              {typeof data.isThird !== 'undefined'
                ? data.isThird
                  ? 'Tercero'
                  : 'Asegurado'
                : data?.info.type}
            </span>
          </div>
        )}
      </div>
    </Stack>
  );
};

export default Details;
