import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import {
  getReminders,
  saveReminder,
} from 'context/inspections/inspectionUtils';
import styles from 'override-mui-styles';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { userActionsPermissions } from 'shared/utils';
import { ResourceAction } from 'types/common';
import RemindersList from '../remiders/RemindersList';
import RemindersModal from '../remiders/RemindersModal';
import './styles.scss';

interface ClaimInfoProps {
  sicId?: number;
  className?: string;
}

const Reminders: FunctionComponent<ClaimInfoProps> = ({
  sicId,
  className,
}): JSX.Element => {
  const classes = styles();
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    state: { currentInspection: event },
  } = useContext<IInspectionContext>(InspectionContext);

  const [showModal, setShowModal] = useState(false);
  const [reminders, setReminders] = useState<any[]>([]);

  const fetchReminders = async (eventId: number) => {
    //Espera de 800ms mientras actualiza en BD
    await new Promise((resolve) => setTimeout(resolve, 800));

    return (await getReminders(eventId)).map((reminder) => ({
      ...reminder,
      creationDate: new Date(reminder.creationDate).toUTCString(),
    }));
  };

  useEffect(() => {
    if (sicId) {
      fetchReminders(sicId).then(setReminders);
    } else if (event?.EventId) {
      fetchReminders(event.EventId).then(setReminders);
    }
  }, [event]);

  const addReminder = useCallback(
    async (
      eventId: number,
      reminderUserId: number,
      reminderText: string,
      reminderDate: string,
    ) => {
      const eventID = eventId ?? sicId;
      const newComment = {
        eventId: eventID,
        userId: Number(localStorage.getItem('userId')),
        reminderUserId: reminderUserId,
        reminderText: reminderText,
        reminderDate: reminderDate,
      };

      setShowModal(false);
      const resp = await saveReminder(eventID, newComment);
      setReminders(await fetchReminders(eventID));

      return resp;
    },
    [],
  );

  const addObservationPermission = (): boolean => {
    return Boolean(userActionsPermissions(ResourceAction.USE_BTN_ADD_OBSERV));
  };

  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.scrollTo(0, 0);
    }
  }, [reminders]);

  return (
    <div ref={containerRef} className={className}>
      <RemindersModal
        visible={showModal}
        event={
          {
            eventId: event?.EventId || sicId || 0,
            eventRecord: event?.EventRecord || '',
            reminders,
          } as any
        }
        onAddReminder={addReminder}
        onRefresh={(event) => fetchReminders(event.eventId)}
        onClose={() => setShowModal(false)}
      />
      {addObservationPermission() && (
        <div className="observations_head_menu">
          <Button
            variant="contained"
            color="primary"
            className={clsx(classes.button, 'add-note')}
            size="large"
            onClick={() => setShowModal(true)}
            startIcon={<AddIcon />}
          >
            Agregar Recordatorio
          </Button>
        </div>
      )}
      <RemindersList
        reminders={reminders}
        onRefresh={(eventId: number) =>
          fetchReminders(eventId).then(setReminders)
        }
      />
    </div>
  );
};

export default Reminders;
