import { Tab, Tabs } from '@mui/material';
import { TabPanel } from 'components/common/tabpanel/TabPanel';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { Card, CardContent } from '@mui/material';
import Loader from 'components/common/loader/Loader';
import DownloadFile from 'views/Reports/downloadFile';
import AjusteExpress from 'views/Reports/ajusteexpressReport';
import './styles.scss';
import EventReport from './eventReport';
import { BrandsId, CmtCountries, CountryCode, Rol } from 'types/common';

export default function Reports(): JSX.Element {
  const [loading] = useState(false);
  const history = useHistory();
  const qParams = history.location.search.split('lt=')[1];
  const logTab = Number(qParams ? qParams.split('&')[0] : 0);
  const [tabValue, setTabValue] = useState<number>(logTab);
  const adminCompanies = [14]; // Connect PRI
  const reportClaims = [
    BrandsId.PREMIER,
    BrandsId.MULTINATIONAL,
    BrandsId.OPTIMA_SEGUROS,
  ];

  const validatePartner = (): boolean => {
    return Number(localStorage.getItem('companyId')) === Number(BrandsId.SURA)
      ? true
      : false;
  };

  const validateReport = (): boolean => {
    return Number(localStorage.getItem('companyId')) !== BrandsId.MULTINATIONAL;
  };

  const validateReportClaim = (): boolean => {
    return (
      (reportClaims.includes(Number(localStorage.getItem('companyId'))) ||
        adminCompanies.includes(Number(localStorage.getItem('companyId')))) &&
      CmtCountries.includes(
        localStorage.getItem('countryCode') as CountryCode,
      ) &&
      Number(localStorage.getItem('rolId')) !== Number(Rol.PRE_ADMIN) &&
      Number(localStorage.getItem('companyId')) !==
        Number(BrandsId.OPTIMA_SEGUROS)
    );
  };

  const validateAjusteExpress = (): boolean => {
    return Number(localStorage.getItem('companyId')) ===
      Number(BrandsId.SURA) ||
      (localStorage.getItem('countryCode') === CountryCode.PAN &&
        Number(localStorage.getItem('rolId')) === Number(Rol.ADMIN))
      ? true
      : false;
  };

  return (
    <>
      <Loader isActive={loading} />
      <Card className={'gridCard'}>
        <CardContent>
          <Tabs
            value={tabValue}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#586982',
              },
            }}
            onChange={(event: any, newValue: number) => setTabValue(newValue)}
          >
            {validateReport() && (
              <Tab
                label="Reportes"
                style={{ color: 'inherit', minWidth: '160px' }}
              />
            )}
            {validateReportClaim() && (
              <Tab
                label="Reporte Reclamaciones"
                style={{ color: 'inherit', minWidth: '160px' }}
              />
            )}
            {validateAjusteExpress() && <Tab label="Acompañamiento SURA" />}
            {validatePartner() && <Tab label="Descarga de Archivos" />}
          </Tabs>

          {validateReport() && (
            <TabPanel value={tabValue} index={0}>
              <EventReport report="event" />
            </TabPanel>
          )}

          {validateReportClaim() ? (
            <TabPanel value={tabValue} index={validateReport() ? 1 : 0}>
              <EventReport report="claims" />
            </TabPanel>
          ) : (
            <>
              <TabPanel value={tabValue} index={1}>
                <AjusteExpress />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <DownloadFile />
              </TabPanel>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
}
