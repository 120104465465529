import React, { FC } from 'react';

export const toggleRange = (
  isRangeDates: boolean,
  setIsRangesDates: (value: boolean) => void,
  onChangeEndDate?: (value: Date | null) => void,
): void => {
  if (isRangeDates) {
    if (onChangeEndDate) {
      onChangeEndDate(null);
    }
  }
  setIsRangesDates(!isRangeDates);
};

interface Props {
  isRangeDates: boolean;
  setIsRangesDates: (value: boolean) => void;
  onChangeEndDate?: (value: Date | null) => void;
}

const CalendarContainer: FC<Props> = ({
  children,
  isRangeDates,
  setIsRangesDates,
  onChangeEndDate,
}) => {
  return (
    <div data-testid="calendar-container" className="datepicker">
      <div className="datepicker__calendar">{children}</div>
      <div
        data-testid="range-toggle"
        className="datepicker__footer"
        onClick={() =>
          toggleRange(isRangeDates, setIsRangesDates, onChangeEndDate)
        }
      >
        <span className="datepicker__footerButton">
          {!isRangeDates ? '+ Agregar rango' : '- Remover rango'}
        </span>
      </div>
    </div>
  );
};

export default CalendarContainer;
