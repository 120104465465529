import React from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Typography,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

// Interfaz genérica para los pasos
export interface GenericStep {
  icon: React.ReactNode;
  labelName: string;
  isComplete: boolean;
  subSteps?: GenericStep[];
}

// Propiedades del componente
export interface GenericEventTrackerProps {
  dataId: number;
  stepsData: GenericStep[];
}

// Estilos personalizados
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  backgroundColor: 'transparent',
}));

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  '& .MuiStepLabel-labelContainer': {
    marginLeft: theme.spacing(2),
  },
}));

const StepContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  transition: theme.transitions.create(['background-color', 'box-shadow']),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    boxShadow: theme.shadows[2],
  },
}));

const SubStepContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

// Componente para renderizar sub-pasos
const SubSteps: React.FC<{ subSteps: GenericStep[] }> = ({ subSteps }) => {
  return (
    <Stepper orientation="vertical">
      {subSteps.map((subStep, index) => (
        <Step key={index} completed={subStep.isComplete}>
          <StyledStepLabel
            icon={
              <Box
                sx={{
                  color: subStep.isComplete ? 'success.main' : 'warning.main',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {subStep.isComplete ? <CheckCircleIcon /> : <ErrorIcon />}
              </Box>
            }
          >
            <SubStepContentWrapper>
              {subStep.icon}
              <Typography variant="body2" sx={{ ml: 1 }}>
                {subStep.labelName}
              </Typography>
            </SubStepContentWrapper>
          </StyledStepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

// Componente principal
export const StepTracker: React.FC<GenericEventTrackerProps> = ({
  dataId,
  stepsData,
}) => {
  // Cálculo del paso activo
  const activeStep = stepsData.map((step) => step.isComplete).lastIndexOf(true);

  return (
    <StyledPaper elevation={3}>
      <Typography variant="h5" gutterBottom>
        ID de Trazabilidad: {dataId}
      </Typography>
      <Box mt={2}>
        <Typography variant="body1">
          Estado actual:{' '}
          <b>
            {activeStep === -1
              ? 'No iniciado'
              : activeStep === stepsData.length - 1 &&
                stepsData[activeStep].isComplete
              ? 'Proceso completado'
              : stepsData[activeStep].labelName}{' '}
          </b>
        </Typography>
      </Box>
      <StyledStepper activeStep={activeStep} orientation="vertical">
        {stepsData.map((step, index) => (
          <Step key={step.labelName} expanded={true}>
            <StyledStepLabel
              icon={
                <Box
                  sx={{
                    color: step.isComplete ? 'success.main' : 'warning.main',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {step.isComplete ? <CheckCircleIcon /> : <ErrorIcon />}
                </Box>
              }
            >
              <StepContentWrapper>
                {step.icon}
                <Typography variant="body1" sx={{ ml: 1 }}>
                  {step.labelName}
                </Typography>
              </StepContentWrapper>
            </StyledStepLabel>
            {step.subSteps && (
              <StepContent>
                <Box sx={{ mt: 2, ml: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Detalle:
                  </Typography>
                  <SubSteps subSteps={step.subSteps} />
                </Box>
              </StepContent>
            )}
          </Step>
        ))}
      </StyledStepper>
    </StyledPaper>
  );
};
