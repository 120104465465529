import {
  Box,
  Stack,
  ListSubheader,
  List,
  ListItemButton,
  ListItemText,
  Divider,
} from '@mui/material';
import React, { FunctionComponent, useContext } from 'react';
import Gallery from '../../inspection/photos';
import './index.scss';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { PhotoSection } from 'types/images';
import { ClaimantType } from 'types/claimsTracker';

interface ClaimsSections {
  sections: any;
  eventRecord: string;
  claimantType: ClaimantType;
}

const Photos: FunctionComponent<ClaimsSections> = ({
  sections,
  eventRecord,
  claimantType,
}): JSX.Element => {
  const { state } = useContext<IInspectionContext>(InspectionContext);
  const [open, setOpen] = React.useState('');

  const handleClick = (data: string, photoSection: PhotoSection) => {
    const element = document.getElementById(`imageSection_${photoSection}`);
    element?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    setOpen(data);
  };

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 1, sm: 2, md: 4 }}
    >
      <Box>
        <List
          className="menu"
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className="menu__header"
            >
              <p>Fotos</p>
            </ListSubheader>
          }
        >
          <ListItemButton
            className="menu__list"
            onClick={() =>
              handleClick('Odómetro', PhotoSection.INSURED_ODOMETER)
            }
          >
            <ListItemText primary="Odómetro" className="item-text" />
          </ListItemButton>
          <Divider variant="middle" component="li" />
          <ListItemButton
            className="menu__list"
            onClick={() => handleClick('Marbete', PhotoSection.INSURED_MARBETE)}
          >
            <ListItemText primary="Marbete" className="item-text" />
          </ListItemButton>
          <Divider variant="middle" component="li" />
          <ListItemButton
            className="menu__list"
            onClick={() =>
              handleClick('Otros', PhotoSection.INSURED_OTHERS_DOCS)
            }
          >
            <ListItemText primary="Otros" className="item-text" />
          </ListItemButton>
        </List>
      </Box>
      <Box
        sx={{
          width: '100%',
          minHeight: '300px',
          backgroundColor: 'white',
          borderRadius: '4px',
          padding: '10px',
        }}
      >
        <Gallery
          country={localStorage.getItem('countryCode') || ''}
          eventRecord={eventRecord}
          claimantType={claimantType}
          sectionType="INSPECTIONS"
          handleSavePhoto={() => {
            console.warn('saved photo');
          }}
        />
      </Box>
    </Stack>
  );
};

export default Photos;
