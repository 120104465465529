import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import Screen from './ScreensCreatePreinspection';
import Steps from './StepsCreatePreinspection';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { ActionType } from 'types/action';
import { isValidEmail } from 'components/inspection/section/utils';
import PATH_ROUTES from 'static/constants/path-routes';
import {
  createPreInspection,
  sharedPreInspection,
} from 'context/preinspections';
import { useForm } from 'react-hook-form';
import {
  CreatePreInspectionSendType,
  ICreatePreinspectionForm,
} from 'types/pre-inspection';
import { nanoid } from 'nanoid';

const PREINSPECTION_FALLBACK = 'No se encontró link de preinspecciones';

const CreatePreinspection: FC = () => {
  const { dispatch } = useContext<IInspectionContext>(InspectionContext);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [option, setOption] = useState<CreatePreInspectionSendType>();
  const [buttonText, setButtonText] = useState('Continuar');
  const history = useHistory();
  const form = useForm<ICreatePreinspectionForm>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
  });
  const preInspectionLink = localStorage.getItem('preInspectionLink');
  let url = PREINSPECTION_FALLBACK;
  const [uniqueId, setUniqueId] = useState('');
  if (preInspectionLink && preInspectionLink !== '') {
    url = preInspectionLink;
    url =
      url.split('').filter((x) => x === '/').length === 3
        ? url + uniqueId
        : url + '/' + uniqueId;
  }
  const showMessages = (message: string) => {
    dispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: true,
        alertMessage: message,
        alertType: 'error',
      },
    });
  };

  const isvalidInputs = (data: ICreatePreinspectionForm): boolean => {
    if (!data.email && data.option == CreatePreInspectionSendType.EMAIL) {
      showMessages('Correo electrónico es requerido.');

      return false;
    }

    if (
      !isValidEmail(data.email) &&
      data.option == CreatePreInspectionSendType.EMAIL
    ) {
      showMessages('Digite un correo electrónico válido.');

      return false;
    }

    if (!data.option) {
      showMessages('Debe seleccionar una opción.');

      return false;
    }

    if (!data.vin) {
      showMessages('Debe ingresar el número de chasis.');

      return false;
    }

    return true;
  };

  const submitForm = async (data: ICreatePreinspectionForm) => {
    if (currentIndex === 1) {
      if (isvalidInputs(data) && currentIndex === 1) {
        setCurrentIndex((currentIndex) => currentIndex + 1);
        setOption(data.option);
        if (data.option == CreatePreInspectionSendType.EMAIL) {
          await sharedPreInspection(data.email, url);
        }
        if (
          localStorage.getItem('runnerCode') !== undefined &&
          localStorage.getItem('runnerCode') !== ''
        ) {
          data.brokercode = String(localStorage.getItem('runnerCode'));
        }
        await createPreInspection(data, uniqueId);
        setButtonText('Cerrar');
      }
    } else {
      history.replace(PATH_ROUTES.PRE_INSPECTION_COMPLETE);
    }
  };

  useEffect(() => {
    setUniqueId(nanoid());
    if (url === PREINSPECTION_FALLBACK) {
      dispatch({
        type: ActionType.SET_GENERAL_ALERT,
        payload: {
          showAlert: true,
          alertMessage: PREINSPECTION_FALLBACK,
          alertType: 'error',
        },
      });
    }
  }, []);

  return (
    <Card className="create-preinspection">
      <CardContent className="create-preinspection__header">
        <Steps screenIndex={currentIndex}></Steps>
      </CardContent>
      <Screen
        form={form}
        screenIndex={currentIndex}
        option={option}
        url={url}
      ></Screen>
      <CardContent className="create-preinspection__footer">
        <Button
          className="cp-button"
          type="submit"
          onClick={form.handleSubmit(submitForm)}
        >
          {buttonText}
        </Button>
      </CardContent>
    </Card>
  );
};

export default CreatePreinspection;
