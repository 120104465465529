import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  Slider,
  TextField,
  Typography,
  Snackbar,
} from '@mui/material';
import {
  getDamageConfiguration,
  saveDamageConfiguration,
} from 'context/preinspections/preInspectionUtils';
import { IDamageConfiguration } from 'types/pre-inspection';
import './index.scss';

const ConfigDamagesParams = (): JSX.Element => {
  const [minimunGrade, setMinimunGrade] = useState(0);
  const [minorDamageValue, setMinorDamageValue] = useState(0);
  const [mediumDamageValue, setMediumDamageValue] = useState(0);
  const [majorDamageValue, setMajorDamageValue] = useState(0);
  const [minorDamageLabel, setMinorDamageLabel] = useState('');
  const [mediumDamageLabel, setMediumDamageLabel] = useState('');
  const [majorDamageLabel, setMajorDamageLabel] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [notifySave, setNotifySave] = useState(false);
  const [saveIsError, setSaveIsError] = useState(false);

  const sliderMarks = [
    {
      value: 0,
      label: '0 Punto(s)',
    },
    {
      value: 30,
      label: '30 Punto(s)',
    },
    {
      value: 70,
      label: '70 Punto(s)',
    },
    {
      value: 100,
      label: '100 Punto(s)',
    },
  ];

  const sliderGradeMarks = [
    {
      value: 0,
      label: 'Nota 0',
    },
    {
      value: 70,
      label: 'Nota 70',
    },
    {
      value: 100,
      label: 'Nota 100',
    },
  ];

  useEffect(() => {
    const getData = async () => {
      const companyId = Number(localStorage.getItem('companyId'));
      const data = await getDamageConfiguration(companyId);
      setMinimunGrade(data.minimunGrade);
      setMinorDamageValue(data.minorDamageValue);
      setMediumDamageValue(data.mediumDamageValue);
      setMajorDamageValue(data.majorDamageValue);
      setMinorDamageLabel(data.minorDamageLabel);
      setMediumDamageLabel(data.mediumDamageLabel);
      setMajorDamageLabel(data.majorDamageLabel);
    };
    getData();
  }, []);

  const saveParameters = async () => {
    const request: IDamageConfiguration = {
      companyId: Number(localStorage.getItem('companyId')),
      minimunGrade: minimunGrade ?? 0,
      minorDamageValue: minorDamageValue ?? 0,
      mediumDamageValue: mediumDamageValue ?? 0,
      majorDamageValue: majorDamageValue ?? 0,
      minorDamageLabel: minorDamageLabel ?? 'Daño Menor',
      mediumDamageLabel: mediumDamageLabel ?? 'Daño Medio',
      majorDamageLabel: majorDamageLabel ?? 'Daño Mayor',
    };
    const result = await saveDamageConfiguration(request);
    setOpenConfirm(false);
    setNotifySave(true);
    console.warn('result', result);
    setSaveIsError(result !== 'success');
  };

  const sliderFormtText = (value: number): string => {
    return `${value} punto(s)`;
  };

  const handleSnackbarClose = () => {
    setNotifySave(false);
  };

  return (
    <div className="configParams">
      <Snackbar
        open={notifySave}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={saveIsError ? 'error' : 'success'}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {saveIsError
            ? 'Ha ocurrido un problema, por favor intente nuevamente guardar los cambios.'
            : 'Cambios guadados satisfactoriamente.'}
        </Alert>
      </Snackbar>
      <Card className="gridCard">
        <CardContent className="card-content">
          <h1 className="settings__title">Parámetros Detección de Daños</h1>
          <TextField
            label="Nota Mínima"
            variant="outlined"
            value={minimunGrade}
            style={{ width: 200, padding: 15, display: 'none' }}
            onChange={(event) => {
              setMinimunGrade(
                event.target.value ? Number(event.target.value) : 0,
              );
            }}
          />

          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Calificación Mínima
              </Typography>
              <p>
                Este apartado define la calificación mínima necesaria para que
                sea un análsis aceptable.
              </p>
            </CardContent>
            <CardActions>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  style={{ textAlign: 'center' }}
                >
                  <Typography
                    id="input-slider"
                    gutterBottom
                    className="damageLabel"
                  >
                    Calificación Mínima:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={10} md={10}>
                  <Slider
                    className="damageSlider"
                    aria-label="Small steps"
                    defaultValue={0}
                    value={minimunGrade}
                    getAriaValueText={sliderFormtText}
                    step={1}
                    min={0}
                    max={100}
                    marks={sliderGradeMarks}
                    valueLabelDisplay="on"
                    onChange={(event, value) => {
                      setMinimunGrade(value as number);
                    }}
                  />
                </Grid>
              </Grid>
            </CardActions>
          </Card>
          <br />
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Daño Leve
              </Typography>
              <Alert severity="info">
                Por lo general son <b>marcas leves</b> en la carrocería que
                ameritan una penalización menor.
              </Alert>
            </CardContent>
            <CardActions>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  style={{ textAlign: 'center' }}
                >
                  <TextField
                    id="lblMinorDamage"
                    label="Etiqueta de Daño Leve"
                    value={minorDamageLabel}
                    onChange={(event) => {
                      setMinorDamageLabel(event.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  style={{ textAlign: 'center' }}
                >
                  <Typography
                    id="input-slider"
                    gutterBottom
                    className="damageLabel"
                  >
                    Valor de Penalización:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7} md={7}>
                  <Slider
                    className="damageSlider"
                    aria-label="Small steps"
                    defaultValue={0}
                    value={minorDamageValue}
                    getAriaValueText={sliderFormtText}
                    step={1}
                    min={0}
                    max={100}
                    marks={sliderMarks}
                    valueLabelDisplay="on"
                    onChange={(event, value) => {
                      setMinorDamageValue(value as number);
                    }}
                  />
                </Grid>
              </Grid>
            </CardActions>
          </Card>
          <br />
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Daño Medio
              </Typography>
              <Alert severity="warning">
                Por lo general <b>son golpes</b> en la carrocería catalogados de
                daño mediano.
              </Alert>
            </CardContent>
            <CardActions>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  style={{ textAlign: 'center' }}
                >
                  <TextField
                    id="lblMinorDamage"
                    label="Etiqueta de Daño Medio"
                    value={mediumDamageLabel}
                    onChange={(event) => {
                      setMediumDamageLabel(event.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  style={{ textAlign: 'center' }}
                >
                  <Typography
                    id="input-slider"
                    gutterBottom
                    className="damageLabel"
                  >
                    Valor de Penalización:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7} md={7}>
                  <Slider
                    className="damageSlider"
                    aria-label="Small steps"
                    defaultValue={0}
                    value={mediumDamageValue}
                    getAriaValueText={sliderFormtText}
                    step={1}
                    min={0}
                    max={100}
                    marks={sliderMarks}
                    valueLabelDisplay="on"
                    onChange={(event, value) => {
                      setMediumDamageValue(value as number);
                    }}
                  />
                </Grid>
              </Grid>
            </CardActions>
          </Card>
          <br />
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Daño Mayor
              </Typography>
              <Alert severity="error">
                Por lo general son <b>golpes severos</b> en la carrocería que
                requieren de una penalización mayor.
              </Alert>
            </CardContent>
            <CardActions>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  style={{ textAlign: 'center' }}
                >
                  <TextField
                    id="lblMajorDamage"
                    label="Etiqueta de Daño Mayor"
                    value={majorDamageLabel}
                    onChange={(event) => {
                      setMajorDamageLabel(event.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  style={{ textAlign: 'center' }}
                >
                  <Typography
                    id="input-slider"
                    gutterBottom
                    className="damageLabel"
                  >
                    Valor de Penalización:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7} md={7}>
                  <Slider
                    className="damageSlider"
                    aria-label="Small steps"
                    defaultValue={0}
                    value={majorDamageValue}
                    getAriaValueText={sliderFormtText}
                    step={1}
                    min={0}
                    max={100}
                    marks={sliderMarks}
                    valueLabelDisplay="on"
                    onChange={(event, value) => {
                      setMajorDamageValue(value as number);
                    }}
                  />
                </Grid>
              </Grid>
            </CardActions>
          </Card>
          <br />
          <div style={{ width: '100%' }}>
            <Button
              id="continue-btn"
              className="saveConfigBtn"
              variant="contained"
              onClick={() => setOpenConfirm(true)}
            >
              Guardar cambios
            </Button>
          </div>
        </CardContent>
      </Card>
      <Dialog open={openConfirm}>
        <DialogTitle id="confirm-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-payment-modal"
            style={{ width: '400px', height: '120px' }}
          >
            <br />
            ¿Está seguro que desea aplicar cambios?
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="cancel-confirm-btn"
            variant="outlined"
            color="secondary"
            onClick={() => setOpenConfirm(false)}
          >
            Cancelar
          </Button>
          <Button
            id="ok-confirm-btn"
            variant="contained"
            color="primary"
            onClick={saveParameters}
          >
            Sí, confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfigDamagesParams;
