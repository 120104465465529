import React, { createContext, useState, ReactNode } from 'react';

interface StyleContextProps {
  paddingTop: string;
  updateParentStyle: (newPaddingTop: string) => void;
}

const StyleContext = createContext<StyleContextProps>({} as StyleContextProps);

interface StyleProviderProps {
  children: ReactNode;
}

function StyleProvider({ children }: StyleProviderProps): JSX.Element {
  const [paddingTop, setPaddingTop] = useState('50px');

  const updateParentStyle = (newPaddingTop: string) => {
    setPaddingTop(newPaddingTop);
  };

  const contextValue: StyleContextProps = {
    paddingTop,
    updateParentStyle,
  };

  return (
    <StyleContext.Provider value={contextValue}>
      {children}
    </StyleContext.Provider>
  );
}

export { StyleContext, StyleProvider };
