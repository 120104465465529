import React, { useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { Logout, LockPerson } from '@mui/icons-material';
import { IUserContext, UserContext } from 'context/user';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  name?: string;
  onCloseSession: () => void;
  onGoToProfile: () => void;
}

const UserProfile: React.FC<Props> = React.memo((props: Props) => {
  const { state } = useContext<IUserContext>(UserContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!state.currentUserStatus || !state.currentUserStatus?.isOneAccess) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const initials = props.name
    ? props.name.split(' ')[0].toLocaleUpperCase()
    : undefined;

  return (
    <>
      <Button
        id="user-menu-button"
        onClick={handleClick}
        color="secondary"
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        endIcon={<span className="ml-2">⏷</span>}
        variant="text"
      >
        <PersonOutlineIcon />
        {initials ?? '/'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            props.onGoToProfile();
          }}
        >
          <ListItemIcon>
            <LockPerson fontSize="small" />
          </ListItemIcon>
          Cuenta de Perfil
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            props.onCloseSession();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Cerrar Sesión
        </MenuItem>
      </Menu>
    </>
  );
});

export default UserProfile;
