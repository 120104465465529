import React from 'react';
import { Box, Divider } from '@mui/material';
import alertIcon from '../../../assets/icons/alertIcon.svg';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Information from '../../../assets/icons/circulo_información.svg';
import Details from './details';
import { Rol } from 'types/common';
import './index.scss';

interface FraudProps {
  data: any;
  claim?: any;
  expanded: boolean;
}

const Fraud: React.FC<FraudProps> = ({ data, expanded, claim }) => {
  const rolId = Number(localStorage.getItem('rolId'));
  const clickUrl = [Rol.ADMIN, Rol.AUDIT, Rol.ADJUSTER].includes(rolId);
  const companyId = Number(localStorage.getItem('companyId'));

  const handleChange = (
    event: React.MouseEvent<HTMLAnchorElement>,
    item: any,
  ) => {
    event.preventDefault();
    const newRoute = item.isClaim
      ? `/claims/${item.claimUID}`
      : `/inspections/${item.eventId}`;
    window.open(newRoute, '_blank');
  };

  return (
    <Box className="fraud">
      <>
        <Box
          display="flex"
          alignItems="center"
          className="fraud__alert fraud__title"
          sx={{ borderRadius: expanded ? 0 : 100 }}
        >
          <img src={alertIcon} alt="Alert Icon" className="fraud__icon" />
          <label className="fraud__title--label">
            {expanded ? <>ALERTA:</> : <> ANTI-FRAUDE:</>}
          </label>
          <span className="fraud__title--span">
            Este vehículo tiene más casos asociados
          </span>
        </Box>
        {expanded && (
          <Box className="fraud__content">
            <div className="fraud__content--label">
              Inpección #{claim?.info.claim}
            </div>
            <div className="fraud__content--section">
              <div className="fraud__content--card">
                {data?.insured?.inspections?.length > 0 ? (
                  <Accordion className="accordion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="accordion-summary"
                    >
                      <Details
                        data={claim}
                        styles={{ width: '100%' }}
                        classes="label-general"
                        label="# Placa:"
                        value={data.insured.plate}
                        hiddenDate={true}
                        count={data.insured.inspections.length}
                      />
                    </AccordionSummary>
                    <AccordionDetails className="accordion-fraud-details">
                      {data?.insured?.inspections.map((item: any) => {
                        const canClickUrl =
                          clickUrl ||
                          (rolId === Rol.INSURER &&
                            companyId === item.companyId)
                            ? true
                            : false;

                        return (
                          <div
                            key={item.eventId}
                            style={{ padding: '0.5rem 0' }}
                          >
                            <Divider className="dashed-divider" />
                            <Details
                              data={item}
                              styles={{ width: '100%' }}
                              classes="label-details"
                              label="# Inspección:"
                              value={item.eventRecord}
                              clickUrl={canClickUrl}
                              handleChange={handleChange}
                            />
                          </div>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <span className="info">
                    <img src={Information} alt="icon" />
                    El asegurado no tiene reclamaciones previas.
                  </span>
                )}
              </div>
              <div className="fraud__content--card">
                {data?.third?.inspections?.length > 0 ? (
                  <Accordion className="accordion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="accordion-summary"
                    >
                      <Details
                        data={{ info: { type: 'Tercero' } }}
                        styles={{ width: '100%' }}
                        classes="label-general"
                        label="# Placa:"
                        value={data?.third?.plate}
                        hiddenDate={true}
                        count={data?.third?.inspections.length}
                      />
                    </AccordionSummary>
                    <AccordionDetails className="accordion-fraud-details">
                      {data?.third?.inspections.map((item: any) => {
                        const canClickUrl =
                          clickUrl ||
                          (rolId === Rol.INSURER &&
                            companyId === item.companyId)
                            ? true
                            : false;

                        return (
                          <div
                            key={item.eventId}
                            style={{ padding: '0.5rem 0' }}
                          >
                            <Divider className="dashed-divider" />
                            <Details
                              data={item}
                              styles={{ width: '100%' }}
                              classes="label-details"
                              label="# Inspección:"
                              value={item.eventRecord}
                              clickUrl={canClickUrl}
                              handleChange={handleChange}
                            />
                          </div>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <span className="info">
                    <img src={Information} alt="icon" />
                    El tercero no tiene reclamaciones previas.
                  </span>
                )}
              </div>
            </div>
          </Box>
        )}
      </>
    </Box>
  );
};

export default Fraud;
