import { endpoints } from 'shared/api';
import APIDocumentTemplates from 'shared/api/apiDocumentTemplates';
export default class DocumentTemplatesClient {
  async getAllTemplates(): Promise<any> {
    const result = await APIDocumentTemplates.get(endpoints.GET_TEMPLATES);

    return result.data;
  }

  async getVariables(id: string): Promise<any> {
    const result = await APIDocumentTemplates.get(
      endpoints.GET_VARIABLES.replace('{id}', id),
    );

    return result.data;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async createTemplate(template: any): Promise<any> {
    const result = await APIDocumentTemplates.post(
      endpoints.POST_CREATE_TEMPLATE,
      template,
    );

    return result.data;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async enabledPermission(data: any): Promise<any> {
    const result = await APIDocumentTemplates.post(
      endpoints.POST_ENABLED_PERMISSIONS,
      data,
    );

    return result.data;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async updateTemplate(template: any): Promise<any> {
    const result = await APIDocumentTemplates.put(
      endpoints.PUT_UPDATE_TEMPLATE,
      template,
    );

    return result.data;
  }
  async removePermissions(id: string): Promise<any> {
    const result = await APIDocumentTemplates.delete(
      endpoints.DELETE_REMOVE_PERMISSIONS.replace('{id}', id),
    );

    return result.data;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async generatePDF(data: any): Promise<any> {
    const result = await APIDocumentTemplates.post(
      endpoints.POST_GENERATE_PDF,
      data,
    );

    return result.data;
  }
}
