const useValidBrowser = (): boolean => {
  const agent = navigator.userAgent.toLowerCase();
  const isChromeEngine =
    agent.indexOf('chrome') > -1 || agent.indexOf('crios') > -1;
  const isFirefoxEngine = agent.indexOf('firefox') > -1;
  const isEdgeEngine =
    agent.indexOf('edge') > -1 ||
    agent.indexOf('edga') > -1 ||
    agent.indexOf('edgios') > -1;
  const isSamsungBrowser = agent.indexOf('samsungbrowser') > -1;
  const isUCBrowser = agent.indexOf('ucbrowser') > -1;

  if (isChromeEngine && !isSamsungBrowser && !isFirefoxEngine && !isUCBrowser) {
    return true;
  } else if (isEdgeEngine) {
    return true;
  }

  return false;
};

export default useValidBrowser;
