import * as Sentry from '@sentry/browser';
import APIClient, { endpoints } from 'shared/api';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import { validateModelKeys } from 'shared/utils';
import { IClaimForm } from 'types/claimsTracker';

type Data = Record<string, any>;
export const saveClaim = async (
  data: Data,
  claimForm: IClaimForm | undefined,
): Promise<any> => {
  try {
    const filterModel = await validateModelKeys(data, claimForm);

    const { data: respData } = await APIClient.post(endpoints.POST_CLAIM_SAVE, {
      ...filterModel,
      UserId: Number(localStorage.getItem('userId')) || 0,
      EventId: claimForm?.info.sicId
        ? String(claimForm?.info.sicId)
        : undefined,
      EventRecord: claimForm?.info.poNumber,
      Estado: claimForm?.info.sicStatusId,
      CountryId: claimForm?.info.countryId,
      CountryCode: claimForm?.info.countryCode,
    });

    return respData;
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log('Error request: ' + error?.response?.data);
    Sentry.captureException(error?.response?.data);

    return error?.response?.data;
  }
};

export const putClaimStatus = async (
  data: Data,
  guid: string,
): Promise<any> => {
  try {
    const result = await new ClaimsTrackerClient().putClaimStatus(data, guid);

    return result;
  } catch (error) {
    Sentry.captureException(error);
  }

  return null;
};

export const postGeneratePDFs = async (eventRecord: string): Promise<void> => {
  try {
    await APIClient.post(
      endpoints.POST_SEND_PDF_V2.replace('{id}', eventRecord),
    );
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log('Error request: ' + error?.response?.data);
    Sentry.captureException(error?.response?.data);
  }
};

export const postNotApplyEstimation = async (
  guiId: string,
  isNotApply: boolean,
): Promise<void> => {
  try {
    await new ClaimsTrackerClient().notApplyEstimation(guiId, isNotApply);
  } catch (error) {
    Sentry.captureException(error);
  }
};
