import {
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Chip,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Alert,
} from '@mui/material';
import {
  Check,
  MoreVertOutlined,
  ReportProblemOutlined,
  SmsOutlined,
} from '@mui/icons-material';
import { globalCss } from 'assets/connect/styles/_variables';
import clsx from 'clsx';
import Avatar from 'components/common/avatar/Avatar';
import SimpleChip from 'components/common/chip/Chip';
import {
  updateAssignedUser,
  linkTagWithEvent,
  cancelEvent,
  restoreEvent,
} from 'context/inspections/inspectionUtils';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import useDevice from 'shared/hooks/useDevice';
import React, { Fragment, useContext, useState } from 'react';
import { userActionsPermissions } from 'shared/utils';
import { EVENT_STATUSES } from 'static/constants/event-status';
import {
  GRID_COLUMNS,
  MENU_TABLE_OPTIONS,
  MENU_LIGHT,
} from 'static/constants/events-grid';
import {
  BrandsId,
  CountryCode,
  IGrid,
  ResourceAction,
  Rol,
  TransitionStatesIds,
} from 'types/common';
import { Event } from 'types/event';
import TableMenu, { MenuOption } from './menu';
import styles from './override-mui-styles';
import './styles.scss';
import TagPicker from './tag-picker/TagPicker';
import UserPicker from './user-picker/UserPicker';
import {
  FiltersContext,
  IFiltersContext,
} from 'context/filters/FiltersContext';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { IUserContext, UserContext } from 'context/user';

interface StatusProps {
  value: string;
  label: string;
  colorClass: string;
}

interface Props {
  events: Array<Event>;
  page: number;
  totalElements: number;
  onClickNote(event: Event): any;
  onClickEditView(row: any, eventId: number): any;
  onChangePage: (event: unknown, newPage: number) => void;
  reloadEvents?: () => void;
}

const getCurrentCountry = () => {
  return localStorage.getItem('countryCode')?.toUpperCase() as CountryCode;
};

const agentAssignPermission = (): boolean => {
  return Boolean(userActionsPermissions(ResourceAction.USE_ASSIGN_AGENT));
};

const notificationPermission = (): boolean => {
  return Boolean(userActionsPermissions(ResourceAction.VIEW_NOTIFICATIONS));
};

const eventAccessPermission = (): boolean => {
  return Boolean(userActionsPermissions(ResourceAction.VIEW_EVENT_DETAIL));
};

const getCurrentGridColumns = (): IGrid[] => {
  const columnsStr = localStorage.getItem('gridColumns') ?? '';
  if (columnsStr.length > 0) {
    return Array.from(JSON.parse(columnsStr));
  }

  return [];
};

const SHOW_ALWAYS = ['statusName', 'eventRecord', 'vehicleMetallicPlate'];

const EventsTable = (props: Props): JSX.Element => {
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );

  const classes = styles();
  const [showCancelEventModal, setShowCancelEventModal] = useState(false);
  const [showConfirmEventModal, setShowConfirmEventModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [showConfirmRestoreModal, setShowConfirmRestoreModal] = useState(false);
  const [showRequireMsg, setShowRequireMsg] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<number>(0);
  const [cancelReason, setCancelReason] = useState<string>('');
  const [restoreReason, setRestoreReason] = useState<string>('');
  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);
  const [pickerEvent, setPickerEvent] = useState<number | null>(null);
  const [tagPicker, setTagPicker] = useState<number | null>(null);
  const {
    state: { currentUser },
    dispatch: userDispatch,
  } = useContext(UserContext) as IUserContext;
  const [tagAnchorEl, setTagAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<null | {
    el: HTMLElement;
    event: any;
  }>(null);

  const USERS = props.events
    .map((e) => e.assignedUserId)
    .filter((u) => u !== null)
    .filter((u, i, a) => a.indexOf(u) === i);
  const { isDesktop, isMobile } = useDevice();

  const { queryFilters, setQueryFilters } = useContext<IFiltersContext>(
    FiltersContext,
  );

  const ShowCancelModal = async (): Promise<void> => {
    setCancelReason('');
    setShowCancelEventModal(true);
  };

  const ShowRestoreModal = async (): Promise<void> => {
    setRestoreReason('');
    setShowRestoreModal(true);
  };

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    event: Event,
  ) => {
    setSelectedRow({ el: e.currentTarget, event });
    if (event.eventId !== undefined) {
      setSelectedEventId(event.eventId);
    }
  };

  const handleClickModalCancel = (): void => {
    if (cancelReason.length > 5) {
      setShowRequireMsg(false);
      localStorage.setItem('cancelReason', cancelReason);
      setShowCancelEventModal(false);
      setShowConfirmEventModal(true);
    } else {
      setShowRequireMsg(true);
    }
  };

  const handleClickModalRestore = (): void => {
    if (restoreReason.length > 5) {
      setShowRequireMsg(false);
      setShowRestoreModal(false);
      setShowConfirmRestoreModal(true);
    } else {
      setShowRequireMsg(true);
    }
  };

  const handleClickConfirmlCancel = (): void => {
    if (selectedEventId > 0) {
      cancelEvent(selectedEventId, inspectionState, dispatch);
      setShowCancelEventModal(false);
      setShowConfirmEventModal(false);
      setSelectedEventId(0);
    }
  };

  const handleClickConfirmlRestore = (): void => {
    if (selectedEventId > 0) {
      restoreEvent(selectedEventId, restoreReason, inspectionState, dispatch);
      setShowRestoreModal(false);
      setShowConfirmRestoreModal(false);
      setSelectedEventId(0);
    }
  };

  const handleClose = () => {
    setSelectedRow(null);
  };

  const setStatusId = (statusId: number) => {
    let newStatusId = -1;
    let menuOption = 0;
    if (statusId == null) {
      newStatusId = 0;
    } else {
      newStatusId = statusId;
    }
    if (
      newStatusId === TransitionStatesIds.COMPLETED ||
      newStatusId === TransitionStatesIds.PROCESSED ||
      newStatusId === TransitionStatesIds.FINISHED
    ) {
      menuOption = 1;
    }

    return menuOption;
  };

  const StatusChip = (eventStatus: string) => {
    const eventStatusProps: StatusProps | undefined = Object.values<
      StatusProps
    >(EVENT_STATUSES).find(
      (status: StatusProps) => status.value === eventStatus,
    );

    if (eventStatusProps) {
      return isMobile ? (
        <div
          className={clsx(
            'status-mobile',
            eventStatusProps.colorClass &&
              `status-mobile--${eventStatusProps.colorClass}`,
          )}
        ></div>
      ) : (
        <SimpleChip
          label={eventStatusProps.label}
          color={eventStatusProps.colorClass}
          compact
        />
      );
    }

    return <></>;
  };

  const setAssignedUser = async (eventId: number, agentId?: number | null) => {
    const ids: number[] = [];
    if (selectedEvents.length > 0) {
      for (const i of selectedEvents) {
        ids.push(props.events[i].eventId);
      }
    } else {
      ids.push(eventId);
    }

    await updateAssignedUser(ids, currentUser.userCompanyID ?? 0, agentId);

    setPickerEvent(null);
    setSelectedEvents([]);
    if (props.reloadEvents) {
      props.reloadEvents();
    }
  };

  const setEventTag = async (eventId: number, tagId?: number | null) => {
    await linkTagWithEvent(eventId, tagId);
    setTagPicker(null);
    setSelectedEvents([]);
    if (props.reloadEvents) {
      props.reloadEvents();
    }
  };

  const columnActionPermission = Boolean(
    userActionsPermissions(ResourceAction.USE_COLUMN_ACTIONS),
  );

  const rowMenuWithPermission = (selectedRow: any): MenuOption[] => {
    const statusId = selectedRow.event.statusId;
    const linkPermission = userActionsPermissions(ResourceAction.USE_LINK_FORM);
    const cancelPermission = userActionsPermissions(
      ResourceAction.USE_BTN_CANCEL_EVENT,
    );
    const downloadPermission = userActionsPermissions(
      ResourceAction.USE_BTN_DOWNLOAD_EVENT,
    );
    const integrationPermission = userActionsPermissions(
      ResourceAction.USE_BTN_INTEGRATION_EVENT,
    );

    const menu = setStatusId(statusId) === 1 ? MENU_TABLE_OPTIONS : MENU_LIGHT;

    menu.forEach((fe: MenuOption) => {
      // By Permission
      if (fe.value === 'CANCEL') {
        fe.isActive = cancelPermission;
        fe.action = ShowCancelModal;
        if (statusId === TransitionStatesIds.CANCELLED) {
          fe.isActive = false;
        }
      } else if (fe.value === 'OPEN_FORM') {
        fe.isActive = linkPermission;
        if (statusId === TransitionStatesIds.CANCELLED) {
          fe.isActive = false;
        }
      } else if (fe.value === 'DOWNLOAD_INSPECTION') {
        fe.isActive = downloadPermission;
      } else if (fe.value === 'INTEGRATION') {
        fe.isActive =
          integrationPermission &&
          (statusId > 2 ||
            (statusId >= 2 && selectedRow.event.companyId === BrandsId.LAFISE));
      } else if (fe.value === 'RESTORE') {
        fe.isActive = true;
        fe.action = ShowRestoreModal;
        if (statusId !== TransitionStatesIds.CANCELLED) {
          fe.isActive = false;
        }
      }
    });

    return menu;
  };

  const toggleSelectedEvents = (index: number) => {
    const indexes = [...selectedEvents];
    const found = indexes.indexOf(index);
    if (found !== -1) {
      indexes.splice(found, 1);
    } else {
      indexes.push(index);
    }

    setSelectedEvents(indexes.sort());
  };

  const addSelectedEvents = (index: number) => {
    const indexes = [...selectedEvents];
    if (indexes.length === 0) {
      // add index to empty array
      indexes.push(index);
    } else if (index < indexes[0]) {
      // index is lt first element
      const first = indexes[0];
      const length = first - index;
      for (let x = index; x <= length; x++) {
        indexes.push(x);
      }
    } else if (index < Number(indexes.slice(-1).pop())) {
      // index is between first and last element
      const newLast = indexes.indexOf(index);
      indexes.splice(newLast + 1);
    } else {
      // index is gt first element
      const first = indexes[0];
      for (let x = first; x <= index; x++) {
        indexes.push(x);
      }
    }
    setSelectedEvents(
      indexes.sort().filter((v, i, self) => self.indexOf(v) === i),
    );
  };

  const rowClick = (
    e: React.MouseEvent,
    eventIndex: number,
    eventId: number,
  ) => {
    if ((e.metaKey || e.ctrlKey) && e.shiftKey) {
      addSelectedEvents(eventIndex);
    } else if (e.metaKey || e.ctrlKey) {
      toggleSelectedEvents(eventIndex);
    } else if (eventAccessPermission()) {
      props.onClickEditView(e, eventId);
    }
  };

  const createSortHandler = (property: string) => () => {
    setQueryFilters({
      sort: property,
      direction:
        queryFilters.direction === 'asc' || !queryFilters.direction
          ? 'desc'
          : 'asc',
    });
  };

  const checkSelectFilter = () => {
    return Boolean(
      queryFilters.statusId ||
        queryFilters.filterByExpressAdjust ||
        queryFilters.filterType === 'CUSTOM',
    );
  };

  const headerColumns = (classes: any): JSX.Element => {
    return (
      <>
        {isDesktop && columnActionPermission && (
          <TableCell key="menu" className={classes.header}>
            Acciones
          </TableCell>
        )}

        {agentAssignPermission() && isDesktop && (
          <TableCell key="avatar" className={classes.header}></TableCell>
        )}

        {getCurrentGridColumns()
          .sort((first, second) => 0 - (first.sortId > second.sortId ? -1 : 1))
          .map((g: IGrid, index: number) => {
            if (isDesktop) {
              if (
                [CountryCode.CRC, CountryCode.PRI].includes(
                  getCurrentCountry(),
                ) &&
                g.columnDataName === GRID_COLUMNS.AJUSTE_EXPRESS.name
              ) {
                return (
                  <Fragment key={`${g.columnDataName}-${index}`}></Fragment>
                );
              } else if (
                [CountryCode.PAN, CountryCode.PRI].includes(
                  getCurrentCountry(),
                ) &&
                g.columnDataName === GRID_COLUMNS.AAP.name
              ) {
                return (
                  <Fragment key={`${g.columnDataName}-${index}`}></Fragment>
                );
              } else if (
                [CountryCode.PAN, CountryCode.PRI].includes(
                  getCurrentCountry(),
                ) &&
                g.columnDataName === GRID_COLUMNS.QUALI_EXPRESS.name
              ) {
                return (
                  <Fragment key={`${g.columnDataName}-${index}`}></Fragment>
                );
              } else if (
                ![CountryCode.PRI].includes(getCurrentCountry()) &&
                [
                  GRID_COLUMNS.AUDITED.name,
                  GRID_COLUMNS.ESTIMATION.name,
                ].includes(g.columnDataName)
              ) {
                return (
                  <Fragment key={`${g.columnDataName}-${index}`}></Fragment>
                );
              } else if (
                getCurrentCountry() !== CountryCode.CRC &&
                g.columnDataName === 'siseSiniestro'
              ) {
                return (
                  <Fragment key={`${g.columnDataName}-${index}`}></Fragment>
                );
              }
            }

            if (isDesktop || SHOW_ALWAYS.includes(g.columnDataName)) {
              return (
                <TableCell
                  key={`${g.columnDataName}-${index}`}
                  className={classes.header}
                >
                  {g.columnName === 'Fecha' && checkSelectFilter() ? (
                    <TableSortLabel
                      active={queryFilters.sort === g.columnDataName}
                      direction={
                        queryFilters.sort === g.columnDataName
                          ? queryFilters.direction || 'asc'
                          : 'asc'
                      }
                      onClick={createSortHandler(g.columnDataName)}
                    >
                      {g.columnName}
                    </TableSortLabel>
                  ) : (
                    g.columnName
                  )}
                </TableCell>
              );
            }

            return null;
          })}
      </>
    );
  };

  const getEstimationLabel = (estimationFlag: boolean | null) => {
    if (estimationFlag === false) {
      return 'No';
    }
    if (estimationFlag === null) {
      return 'Pendiente';
    }

    return 'Sí';
  };
  const getEstimationColor = (estimationFlag: boolean | null) => {
    if (estimationFlag === false) {
      return 'gray';
    }
    if (estimationFlag === null) {
      return 'yellow';
    }

    return 'green';
  };

  const bodyColumns = (events: Event[]): JSX.Element => {
    return (
      <>
        {events.map((event: any, index: number) => {
          return (
            <TableRow
              key={event.eventId}
              className={clsx(
                classes.rowSelect,
                event.indInvestigation &&
                  Number(localStorage.getItem('rolId')) !== Rol.CUSTOMER &&
                  'investigate',
                selectedEvents.includes(index) && 'selected',
              )}
              onClick={(e) => rowClick(e, index, event.eventId)}
            >
              {isDesktop && columnActionPermission && (
                <TableCell
                  align="center"
                  className={clsx(classes.cell, classes.smallCell)}
                >
                  <IconButton onClick={(e) => handleClick(e, event)}>
                    <MoreVertOutlined className={classes.menuIcon} />
                  </IconButton>
                  {selectedRow && (
                    <TableMenu
                      anchorElement={selectedRow.el}
                      menuOptions={rowMenuWithPermission(selectedRow)}
                      onClickMenu={(e) => handleClick(e, event)}
                      onCloseMenu={handleClose}
                      currentValue={selectedRow.event}
                    />
                  )}
                </TableCell>
              )}

              {agentAssignPermission() && isDesktop && (
                <TableCell
                  align="center"
                  className={clsx(classes.cell, classes.smallCell)}
                >
                  <Avatar
                    size="small"
                    name={event.assignedUserName}
                    userIndex={USERS.findIndex(
                      (u) => u === event.assignedUserId,
                    )}
                    onClick={() => {
                      setPickerEvent(index);
                    }}
                  />
                  <UserPicker
                    hidden={pickerEvent !== index}
                    onChange={(agent) =>
                      setAssignedUser(event.eventId, agent?.id)
                    }
                    onClose={() => setPickerEvent(null)}
                    assignedUser={{
                      id: event.assignedUserId,
                      name: event.assignedUserName,
                      email: event.assignedUserEmail,
                    }}
                  />
                </TableCell>
              )}

              {getCurrentGridColumns()
                .sort(
                  (first, second) =>
                    0 - (first.sortId > second.sortId ? -1 : 1),
                )
                .map((g: IGrid, index: number) => {
                  if (isDesktop || SHOW_ALWAYS.includes(g.columnDataName)) {
                    return (
                      <Fragment key={`${g.columnDataName}-${index}`}>
                        {g.columnDataName === 'statusName' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.cell, classes.mediumCell)}
                          >
                            {StatusChip(event.statusName)}
                          </TableCell>
                        )}
                        {g.columnDataName === 'showAlert' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.cell, classes.mediumCell)}
                          >
                            {event.alertText !== '' && (
                              <Tooltip title={event.alertText}>
                                <ReportProblemOutlined className="warn" />
                              </Tooltip>
                            )}
                          </TableCell>
                        )}

                        {g.columnDataName === 'eventDate' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.cell, classes.dateCell)}
                          >
                            {format(
                              Date.parse(event.eventDate),
                              'dd/MM/yyyy HH:mm:ss',
                              {
                                locale: esLocale,
                              },
                            )}
                          </TableCell>
                        )}

                        {g.columnDataName === 'indRespAAP' &&
                          ![CountryCode.PAN, CountryCode.PRI].includes(
                            getCurrentCountry(),
                          ) && (
                            <TableCell align="center" className={classes.cell}>
                              <Switch
                                checked={event.indRespAAP}
                                readOnly
                                color="primary"
                              />
                            </TableCell>
                          )}

                        {g.columnDataName === 'indExpressAdjust' &&
                          ![CountryCode.PRI].includes(getCurrentCountry()) && (
                            <TableCell align="center" className={classes.cell}>
                              <SimpleChip
                                label={event.indExpressAdjust ? 'Sí' : 'No'}
                                color={
                                  event.indExpressAdjust ? 'green' : 'gray'
                                }
                                compact
                              />
                            </TableCell>
                          )}

                        {[CountryCode.PRI].includes(getCurrentCountry()) && (
                          <>
                            {g.columnDataName === 'indEstimate' && (
                              <TableCell
                                align="center"
                                className={classes.cell}
                              >
                                <SimpleChip
                                  label={getEstimationLabel(event.indEstimate)}
                                  color={getEstimationColor(event.indEstimate)}
                                  compact
                                />
                              </TableCell>
                            )}

                            {g.columnDataName === 'audited' && (
                              <TableCell
                                align="center"
                                className={classes.cell}
                              >
                                {event.audited && (
                                  <Check style={{ color: globalCss.fern }} />
                                )}
                              </TableCell>
                            )}
                          </>
                        )}
                        {g.columnDataName === 'TagName' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.cell, classes.smallCell)}
                          >
                            <Chip
                              className={clsx('event__tag', {
                                'event__tag--empty': !event.tagId,
                              })}
                              clickable={true}
                              label={event.tagName || 'Sin tag'}
                              onClick={(
                                element: React.MouseEvent<HTMLElement>,
                              ) => {
                                element.stopPropagation();
                                setTagAnchorEl(element.currentTarget);
                                setTagPicker(event.eventId);
                              }}
                            />
                            <TagPicker
                              anchorEl={tagAnchorEl}
                              value={{ id: event.tagId, name: event.tagName }}
                              onClose={() => setTagPicker(null)}
                              onChange={(value) => {
                                setEventTag(event.eventId, value?.id);
                              }}
                              open={tagPicker === event.eventId}
                            />
                          </TableCell>
                        )}
                        {![
                          'statusName',
                          'eventDate',
                          'indExpressAdjust',
                          'indRespAAP',
                          'indEstimate',
                          'audited',
                          'TagName',
                          'showAlert',
                        ].includes(g.columnDataName) && (
                          <TableCell align="center" className={classes.cell}>
                            {event[g.columnDataName]}
                          </TableCell>
                        )}
                      </Fragment>
                    );
                  }

                  return null;
                })}

              {notificationPermission() && (
                <TableCell
                  align="center"
                  className={clsx(classes.cell, classes.smallCell)}
                >
                  <IconButton onClick={() => props.onClickNote(event)}>
                    <SmsOutlined />
                    <span
                      hidden={event.nonAttended === 0}
                      className="notification-dot"
                    ></span>
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </>
    );
  };

  return (
    <>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={props.totalElements || -1}
        rowsPerPage={20}
        page={props.page}
        onPageChange={props.onChangePage}
      />
      <TableContainer className="table-container">
        <Table
          className={classes.table}
          size={isDesktop ? 'medium' : 'small'}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>{headerColumns(classes)}</TableRow>
          </TableHead>
          <TableBody>{bodyColumns(props.events)}</TableBody>
        </Table>
      </TableContainer>
      <Dialog open={showConfirmEventModal}>
        <DialogTitle id="confirm-cancel-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-cancel-modal"
            style={{ width: '400px', height: '70px' }}
          >
            ¿Está seguro que desea cancelar el evento?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setShowConfirmEventModal(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={handleClickConfirmlCancel}
          >
            Sí, confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showCancelEventModal} className="modal-for-cancelOrRestore">
        <DialogTitle id="cancel-event-title">Cancelación de Evento</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="cancel-event-modal"
            style={{ width: '400px', height: 'auto', overflowY: 'hidden' }}
          >
            {showRequireMsg && (
              <>
                <Alert severity="warning">
                  El <b>motivo / razón</b> para procesar la solicitud{' '}
                  <b>es requerida</b>.
                </Alert>
                <br />
              </>
            )}
            <TextField
              key="cancelDescription"
              className={clsx('inspectionForm__input')}
              name="cancelDescription"
              label="Digite la razón por la que cancela el evento..."
              variant="outlined"
              size="medium"
              multiline={true}
              rows={3}
              onChange={(event) => {
                setCancelReason(event.target.value);
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setShowCancelEventModal(false)}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => handleClickModalCancel()}
            color="primary"
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showRestoreModal} className="modal-for-cancelOrRestore">
        <DialogTitle id="restore-event-title">
          Restauración del Evento
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="restore-event-modal"
            style={{ width: '400px', height: 'auto', overflowY: 'hidden' }}
          >
            {showRequireMsg && (
              <>
                <Alert severity="warning">
                  El <b>motivo / razón</b> para procesar la solicitud{' '}
                  <b>es requerida</b>.
                </Alert>
                <br />
              </>
            )}
            <TextField
              key="restoreDescription"
              className={clsx('inspectionForm__input')}
              name="restoreDescription"
              label="Digite una razón para restaurar el evento..."
              variant="outlined"
              size="medium"
              multiline={true}
              rows={3}
              onChange={(event) => {
                setRestoreReason(event.target.value);
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setShowRestoreModal(false)}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => handleClickModalRestore()}
            color="primary"
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showConfirmRestoreModal}>
        <DialogTitle id="confirm-restore-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-restore-modal"
            style={{ width: '400px', height: '120px' }}
          >
            <Alert severity="warning">
              <b>Nota:</b> El evento se restaurará al último estado anterior a
              la cancelación.
            </Alert>
            <br />
            ¿Está seguro que desea restaurar este evento?
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setShowConfirmRestoreModal(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={handleClickConfirmlRestore}
          >
            Sí, confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventsTable;
