import { Box, Stack } from '@mui/material';
import Button from './../../components/claims/button';
import Loader from 'components/common/loader/Loader';
import ViewDocuments from 'components/componentDocuments/list';
import React, { useState } from 'react';

export default function Documents(): JSX.Element {
  const [loader, setLoader] = useState(false);
  const createTemplate = () => {
    window.location.replace('/CreateTemplate');
  };

  return (
    <>
      <Loader isActive={loader} />
      <Button text="Crear plantilla" onClick={createTemplate} />
      <Stack spacing={{ xs: 1, sm: 1, md: 1, xl: 1 }} direction="row">
        <Box sx={{ width: '100%' }}>
          <ViewDocuments setLoader={setLoader} />
        </Box>
      </Stack>
    </>
  );
}
