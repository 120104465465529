/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/HighlightOff';
import './UserPicker.scss';
import { Popper, TextField } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Autocomplete } from '@mui/material';
import { fetchAgents } from 'context/inspections';
import { Agent, IUserModel } from 'types/user';
import Avatar from 'components/common/avatar/Avatar';
import { BrandsId } from 'types/common';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiAutocomplete-listbox': {
        minHeight: 400,
        fontSize: 14,
      },
    },
  }),
);

interface UserPickerProps {
  assignedUser: Agent;
  hidden: boolean;
  companyId?: number;
  onClose: () => void;
  onChange: (newValue: Agent | null) => void;
}

const UserPicker: FunctionComponent<UserPickerProps> = (props): JSX.Element => {
  const [agents, setAgents] = useState<Agent[]>([]);
  const node = useRef<any>(null);
  const popper = useRef<any>(null);
  const classes = useStyles();

  useEffect(() => {
    const countryCode = localStorage.getItem('countryCode') || undefined;
    fetchAgents(countryCode).then((_agents) =>
      setAgents(
        _agents
          .filter((user) =>
            [
              BrandsId.CONNECT_CR,
              BrandsId.CONNECT_PAN,
              BrandsId.CONNECT_PRI,
              BrandsId.CONNECT_CO,
              BrandsId.CONNECT_MULTICOUNTRY,
              props.companyId ?? -1,
            ].includes(user.companyId),
          )
          .map(
            (a: IUserModel) =>
              ({
                id: a.userCompanyID,
                name: a.name,
                email: a.email,
              } as Agent),
          ),
      ),
    );
  }, []);

  useEffect(() => {
    const handleClickOutside = (el: Event) => {
      if (
        (node?.current && node.current!.contains(el.target)) ||
        (popper?.current && popper.current!.contains(el.target))
      ) {
        return;
      }

      props.onClose();
    };

    if (!props.hidden) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [props]);

  const heading =
    props.assignedUser.id !== null ? 'Agente asignado' : 'Asignar agente';

  return (
    <>
      {!props.hidden && (
        <div
          className="user-picker"
          ref={node}
          onClick={(e: any) => e.stopPropagation()}
        >
          <CloseIcon className="close" onClick={props.onClose} />
          <h5>{heading}</h5>
          <Autocomplete
            options={agents}
            getOptionLabel={(option) => option.name || ''}
            value={
              agents.find((agent) => agent.id === props.assignedUser.id) || null
            }
            size="small"
            onChange={(_, newValue: Agent | null) => {
              props.onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Agente" variant="outlined" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Avatar
                  size="xsmall"
                  name={option.name}
                  userIndex={agents.findIndex((a) => a.id === option.id)}
                />
                <span style={{ marginLeft: 5 }}>{option.name}</span>
              </li>
            )}
            PopperComponent={(props) => (
              <Popper ref={popper} {...props} className={classes.root} />
            )}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
          />
        </div>
      )}
    </>
  );
};

export default UserPicker;
