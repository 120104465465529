import React, { FC } from 'react';
import { Button, Card, CardContent } from '@mui/material';
import PATH_ROUTES from 'static/constants/path-routes';
import completeImage from '../../assets/images/complete_il.svg';
import { useHistory } from 'react-router-dom';

const CompletePreinspection: FC = () => {
  const history = useHistory();
  const backClick = () => {
    history.replace(PATH_ROUTES.PRE_INSPECTION_CREATE);
  };

  return (
    <Card className="create-preinspection">
      <CardContent className="create-preinspection__content">
        <p>¡Generación Finalizada!</p>
        <img src={completeImage} alt=""></img>
      </CardContent>
      <CardContent className="create-preinspection__footer">
        <Button className="cp-button" onClick={backClick}>
          VOLVER
        </Button>
      </CardContent>
    </Card>
  );
};

export default CompletePreinspection;
