import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { SelectOption } from 'types/select-option-inspection';
import './styles.scss';

interface FormStepsProps {
  steps: SelectOption[];
  activeStep: SelectOption;
  onSelectedTab: (value: SelectOption) => void;
}

const FormSteps: FunctionComponent<FormStepsProps> = ({
  steps,
  activeStep,
  onSelectedTab,
}): JSX.Element => {
  return (
    <div className="steps">
      {steps.map((step) => (
        <Step
          key={step.formSectionId}
          step={step}
          onSelectedTab={onSelectedTab}
          activeStep={activeStep}
        />
      ))}
    </div>
  );
};

interface StepProps {
  step: SelectOption;
  activeStep: SelectOption;
  onSelectedTab: (value: SelectOption) => void;
}

const Step: FunctionComponent<StepProps> = ({
  step,
  activeStep,
  onSelectedTab,
}) => {
  const selected = step.formSectionId === activeStep.formSectionId;

  return (
    <div
      className={clsx('steps__tab', {
        'steps__tab--selected': selected,
        'steps__tab--error': step.hasError,
      })}
      onClick={() => onSelectedTab(step)}
    >
      {step.title}
    </div>
  );
};

export default FormSteps;
