import { makeStyles } from '@mui/styles';
import { globalCss } from 'assets/connect/styles/_variables';

export default makeStyles((theme) => ({
  title: {
    [theme.breakpoints.up('md')]: {
      fontSize: '3.5rem',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '2.8rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.4rem',
    },
  },
  image: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/loginBack.png)`,
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
  },
  subtitle: {
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  description: {
    marginTop: '1.5rem',
    textAlign: 'justify',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'capitalize',
    color: globalCss.white,
  },
  back: {
    //margin: theme.spacing(3, 0, 2),
    textTransform: 'capitalize',
    backgroundColor: '#DB9261',
    color: globalCss.white,
  },
  submitNewPassword: {
    //margin: theme.spacing(3, 0, 2),
    textTransform: 'capitalize',
    color: globalCss.white,
    float: 'right',
  },
  loginTextField: {
    fontSize: '1.3rem',
  },
  passwordRestoreLink: {
    color: globalCss.info,
    fontWeight: 'bold',
    fontSize: '1rem',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  selectInput: {
    margin: theme.spacing(1, 0, 1),
    minWidth: 120,
    width: '100%',
  },
}));
