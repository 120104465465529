import { Grid } from '@mui/material';
import { Skeleton } from '@mui/material';
import React from 'react';

const SkeletonGrid = (): JSX.Element => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={100} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="rectangular" height={100} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="rectangular" height={100} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="rectangular" height={100} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="rectangular" height={100} />
        </Grid>
      </Grid>
      <br />
      <Skeleton variant="rectangular" height={400} />
    </>
  );
};

export default SkeletonGrid;
