import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import './styles.scss';

type MessageProps = {
  message: string;
  isInfo?: boolean;
  isWarning?: boolean;
  isError?: boolean;
};

const ValidationLabel = ({
  message,
  isWarning,
  isError,
}: MessageProps): JSX.Element => {
  return (
    <Grid
      container
      className={clsx(
        'validation--info',
        { 'validation--error': isError },
        { 'validation--warning': isWarning },
      )}
    >
      <Grid item xs>
        <label className="validation__label">{message}</label>
      </Grid>
      <Grid item>
        <InfoIcon />
      </Grid>
    </Grid>
  );
};

export default ValidationLabel;
