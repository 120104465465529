import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import './index.scss';
import {
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import DataTable from '../grid';
import Button from '../button';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import FileInput from '../inputFile';
import { PhotoSection } from 'types/images';
import {
  BUCKET_NAME_CLAIMS_TRACKER,
  BUCKET_REGION_CLAIMS_TRACKER,
} from 'shared/api/config';
import { uploadMediaFile } from 'context/inspections/inspectionUtils';
import { ActionType } from 'types/action';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import DialogComponent from 'components/common/dialog';
import { ClaimantType } from 'types/claimsTracker';
import { MetadataTag } from 'types/common';

export interface ExpenseFields {
  expenseType: string;
  expenseDescription: string;
  expenseAmount: number | undefined;
}

export interface PropsExpense {
  claimNumber: string;
  poNumber: string;
  claimantType: string;
  claimUId?: string;
  disabled: boolean;
}

const columns: GridColDef[] = [
  { field: 'expenseType', headerName: 'Tipo de Gasto', width: 200 },
  {
    field: 'expenseDescription',
    headerName: 'Descripcion del gasto',
    width: 300,
  },
  { field: 'expenseAmount', headerName: 'Valor', width: 150 },
  {
    field: 'fileUrl',
    headerName: 'Documento',
    width: 150,
    renderCell: (params) => (
      <Link
        className="expense__table--link"
        target="_blank"
        href={params.value}
        hidden={!params.value}
      >
        Ver documento
      </Link>
    ),
  },
];

const ClaimExpenses: FunctionComponent<PropsExpense> = ({
  claimNumber,
  poNumber,
  claimantType,
  claimUId,
  disabled,
}): JSX.Element => {
  useEffect(() => {
    getAllExpenses().catch(console.error);
  }, []);
  const [fileName, setFileName] = useState('');
  const [fileUpload, setFileUpload] = useState<File>();
  const { dispatch } = useContext<IInspectionContext>(InspectionContext);

  const [dataHistoric, setDataHistoric] = useState([]);
  const [expenseFields, setExpenseFields] = useState<ExpenseFields>({
    expenseType: '',
    expenseDescription: '',
    expenseAmount: undefined,
  });
  const [selectionFields, setSelectionFields] = useState<GridRowId[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const getAllExpenses = async (): Promise<void> => {
    try {
      dispatch({
        type: ActionType.SET_EXPENSES_AMOUNT,
        payload: 0,
      });

      const result = await new ClaimsTrackerClient().getAllExpenses(
        claimUId,
        claimNumber,
        poNumber,
      );

      setDataHistoric(result);

      if (result?.length > 0) {
        let totalExpenses = 0;

        for (const row of result) {
          totalExpenses += row.expenseAmount;
        }

        dispatch({
          type: ActionType.SET_EXPENSES_AMOUNT,
          payload: totalExpenses,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onClickSave = async (): Promise<void> => {
    try {
      dispatch({ type: ActionType.SET_LOADING, payload: true });
      const result = await new ClaimsTrackerClient().SaveExpenseAmount(
        expenseFields,
        claimUId,
      );

      if (result?.data?.id) {
        setExpenseFields({
          expenseType: '',
          expenseDescription: '',
          expenseAmount: 0,
        });

        if (fileUpload) {
          await uploadMediaFile(
            fileUpload,
            `${poNumber}/${claimNumber}`,
            claimantType?.toUpperCase() === ClaimantType.CLAIM_INJURED
              ? PhotoSection.INJURED_OTHERS_DOCS
              : PhotoSection.INSURED_OTHERS_DOCS,
            BUCKET_NAME_CLAIMS_TRACKER,
            BUCKET_REGION_CLAIMS_TRACKER,
            String(result.data.id),
            MetadataTag.CMT_EXPENSE_DOCUMENT,
          );

          setFileName('');
          setFileUpload(undefined);
        }

        await getAllExpenses();
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    } catch (e) {
      console.warn(e);
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const validateFields = (): boolean => {
    if (!expenseFields.expenseType) {
      return true;
    }

    if (!expenseFields.expenseDescription) {
      return true;
    }

    if (!expenseFields.expenseAmount) {
      return true;
    }

    return false;
  };

  const handleChange = (event: any): void => {
    const data = expenseFields;
    data[event?.target?.name] = event?.target?.value;
    setExpenseFields({ ...data });
  };

  const handleUploadFile = (event: any): void => {
    if (event?.target?.files.length > 0) {
      setFileName(event.target.files[0].name);
      setFileUpload(event.target.files[0]);
    }
  };

  const openConfirmDialog = (): void => {
    setOpenDialog(true);
  };

  const handleDelete = async (): Promise<void> => {
    try {
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      for (const id of selectionFields) {
        await new ClaimsTrackerClient().DeleteExpenseAmount(id as number);
      }

      setSelectionFields([]);
      await getAllExpenses();

      dispatch({ type: ActionType.SET_LOADING, payload: false });
    } catch (e) {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const handleCancel = (): void => {
    setSelectionFields([]);
  };

  const handleConfirm = async (): Promise<void> => {
    setOpenDialog(false);
    await handleDelete();
  };

  return (
    <Grid item xs={12} sm={6} md={6} className="expense">
      <Grid className="expense__data">
        <Grid>
          <FormControl style={{ width: 210 }}>
            <InputLabel id="label-select">Tipo de Gasto</InputLabel>
            <Select
              labelId="label-select"
              id="select"
              name="expenseType"
              value={expenseFields.expenseType}
              label="Tipo de Gasto *"
              onChange={handleChange}
              disabled={disabled}
            >
              <MenuItem value="Gasto 1">Gasto 1</MenuItem>
              <MenuItem value="Gasto 2">Gasto 2</MenuItem>
              <MenuItem value="Gasto 3">Gasto 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid>
          <TextField
            className="expense__data--description"
            name="expenseDescription"
            label="Descripción del gasto *"
            type="text"
            value={expenseFields.expenseDescription}
            onChange={handleChange}
            disabled={disabled}
          ></TextField>
        </Grid>
      </Grid>
      <Grid className="expense__data">
        <Grid>
          <TextField
            name="expenseAmount"
            label="Valor *"
            type="number"
            value={expenseFields.expenseAmount}
            onChange={handleChange}
            disabled={disabled}
          ></TextField>
        </Grid>
        <Grid>
          <FileInput
            fileName={fileName}
            handleFileChange={handleUploadFile}
            disabled={disabled}
          />
        </Grid>
        <Grid className="expense__data--button-save">
          <Button
            disabled={validateFields()}
            text="Agregar Gasto"
            onClick={() => {
              if (!disabled) {
                onClickSave();
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid className="expense__table">
        {selectionFields.length > 0 && (
          <Grid className="expense__table--options">
            <span>{selectionFields.length} items selected</span>
            <Grid className="expense__table--options--actions">
              <Button
                text="Eliminar"
                variant="text"
                style={{ color: 'white', fontSize: 14 }}
                onClick={openConfirmDialog}
              ></Button>
              <span>|</span>
              <Button
                text="Cancel"
                variant="text"
                style={{ color: 'white', fontSize: 14 }}
                onClick={handleCancel}
              ></Button>
            </Grid>
          </Grid>
        )}
        <DataTable
          columns={columns}
          data={dataHistoric}
          showCheckBox
          selectionModel={selectionFields}
          onRowSelectionModelChange={(rows: any): void =>
            setSelectionFields(rows)
          }
        ></DataTable>
      </Grid>

      <DialogComponent
        messageStyle={{ fontSize: '16px' }}
        message={`¿Desea eliminar los ${selectionFields.length} registros seleccionados?`}
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={handleConfirm}
      />
    </Grid>
  );
};

export default ClaimExpenses;
