import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import StatusChip from 'components/common/status/chip';
import {
  getEventTransitions,
  IInspectionContext,
  InspectionContext,
  saveEventLog,
  saveEventTransition,
  validateAAP,
} from 'context/inspections';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { userActionsPermissions } from 'shared/utils';
import { ActionType } from 'types/action';
import './styles.scss';
import { CountryCode, ResourceAction, Rol } from 'types/common';
import { roles, roleNames } from 'static/constants/roles';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import {
  getPreInspectTransitions,
  IPreInspectionContext,
  PreInspectionContext,
  savePreInspectTransition,
} from 'context/preinspections';
import { AAPValidationMessage, AAPValidationOptions } from 'types/aap';

export enum TransitionStates {
  CREATED = 'Creado',
  COMPLETED = 'Completado',
  PROCESSED = 'Procesado',
  FINISHED = 'Terminado',
  CANCELLED = 'Cancelado',
  AUTHORIZED = 'Autorizado',
  ELIMINATED = 'Eliminado',
  ABOUT_TO_SEND = 'Por Enviar',
  COMMENT = 'Comentario',
  EXPIRED = 'Expirado',
}

export enum TransitionStatesValues {
  CREATED = 1,
  COMPLETED = 2,
  PROCESSED = 3,
  FINISHED = 4,
  CANCELLED = 5,
  AUTHORIZED = 6,
  ELIMINATED = 7,
  ABOUT_TO_SEND = 8,
  COMMENT = 9,
}

interface NextTransition {
  eventStateId: number;
  eventStateDesc: string;
}

interface Transition {
  description: string;
  eventId: number;
  eventStateDesc: TransitionStates;
  eventStateId: number;
  isComentary: boolean;
  transitionDate: Date;
  userCompanyId: number;
  userName: string;
  nextTransition?: NextTransition;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NEXT_TRANSITION: { [key in TransitionStates]: NextTransition | null } = {
  [TransitionStates.CREATED]: {
    eventStateId: 2,
    eventStateDesc: TransitionStates.COMPLETED,
  },
  [TransitionStates.COMPLETED]: {
    eventStateId: 3,
    eventStateDesc: TransitionStates.PROCESSED,
  },
  [TransitionStates.PROCESSED]: {
    eventStateId: 4,
    eventStateDesc: TransitionStates.FINISHED,
  },
  [TransitionStates.FINISHED]: null,
  [TransitionStates.CANCELLED]: null,
  [TransitionStates.AUTHORIZED]: null,
  [TransitionStates.ELIMINATED]: null,
  [TransitionStates.ABOUT_TO_SEND]: null,
  [TransitionStates.COMMENT]: null,
  [TransitionStates.EXPIRED]: {
    eventStateId: -3,
    eventStateDesc: TransitionStates.EXPIRED,
  },
};

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: 'white',
      color: '#6d7b91',
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#F0F4F7',
      borderBottom: '10px solid white',
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 100,
  },
});

const fetchEventTransitions = async (
  eventId: number,
  setState: (data: any) => void,
) => {
  const eventStatus: Transition[] = await getEventTransitions(eventId);

  setState(
    eventStatus.map((e: Transition) => ({
      ...e,
      transitionDate: e.transitionDate,
      nextTransition: NEXT_TRANSITION[e.eventStateDesc],
    })),
  );
};

const fetchPreInspectTransitions = async (
  preInspectId: number,
  setState: (data: any) => void,
) => {
  const eventStatus: Transition[] = await getPreInspectTransitions(
    preInspectId,
  );

  setState(
    eventStatus.map((e: Transition) => ({
      ...e,
      transitionDate: e.transitionDate,
      nextTransition: NEXT_TRANSITION[e.eventStateDesc],
    })),
  );
};

interface Props {
  sectionType: string;
  isReadOnly?: boolean;
}

const ActivityLog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { dispatch, state: inspectionState } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const { state: preInspectState, dispatch: preInspectDispatch } = useContext<
    IPreInspectionContext
  >(PreInspectionContext);
  const { id } = useParams<{ id: string | undefined }>();
  const [transitions, setTransitions] = useState<Transition[]>([]);
  const [showAuditChangeState, setShowAuditChangeState] = useState(false);
  const [
    auditNextTransition,
    setAuditNextTransition,
  ] = useState<NextTransition | null>(null);

  const modelInspection = inspectionState.currentInspection;
  const modelPreInspection = preInspectState.currentPreInspection;
  const lastTransition = transitions
    .filter((f) => f.nextTransition !== null)
    ?.slice(-1)
    ?.pop();

  const isAuditProcess = Number(localStorage.getItem('rolId')) === Rol.AUDIT;

  useEffect(() => {
    if (props.sectionType === 'INSPECTIONS') {
      fetchEventTransitions(Number(id), setTransitions);
    } else if (modelPreInspection) {
      fetchPreInspectTransitions(
        modelPreInspection?.preInspectionId,
        setTransitions,
      );
    }
  }, [id, props, preInspectState, modelPreInspection]);

  const valDates = (): boolean => {
    // Validar fechas de inspección y de accidente
    const inspectionDate = new Date(
      modelInspection?.eventDateInspection +
        ' ' +
        modelInspection?.timeInspection,
    );
    let accidentDate = new Date(
      modelInspection?.eventDateSinister + ' ' + modelInspection?.timeSinister,
    );
    if (modelInspection?.eventDateSinisterVal !== undefined) {
      const splitStr = String(modelInspection?.eventDateSinisterVal).split(' ');
      const splitTime = String(splitStr[1]).split(':');
      const hour =
        splitTime[0].length === 1 ? '0' + splitTime[0] : splitTime[0];
      const minute =
        splitTime[1].length === 1 ? '0' + splitTime[1] : splitTime[1];
      const second =
        splitTime[2].length === 1 ? '0' + splitTime[2] : splitTime[2];
      const formatedTime = hour + ':' + minute + ':' + second;
      accidentDate = new Date(splitStr[0] + ' ' + formatedTime);
    }
    if (accidentDate > inspectionDate) {
      return false;
    } else {
      return true;
    }
  };

  const alertMessage = (type: string, message: string): void => {
    dispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: true,
        alertMessage: message,
        alertType: type,
      },
    });
  };

  const addTransition = async (
    transition: NextTransition,
    isComment = false,
  ) => {
    let validation = true;
    if (
      transition.eventStateId === TransitionStatesValues.COMPLETED &&
      (modelInspection?.CountryCode === CountryCode.CRC.toUpperCase() ||
        modelInspection?.CountryCode === CountryCode.PAN.toUpperCase())
    ) {
      validation = Boolean(valDates());
    }
    if (validation === true) {
      dispatch({ type: ActionType.SET_LOADING, payload: true });
      const body = {
        eventId: Number(id),
        eventStateId: transition.eventStateId,
        userCompanyId: Number(localStorage.getItem('userId')),
        transitionDate: new Date(),
        isComentary: isComment,
        countryCode: localStorage.getItem('countryCode') || 'PRI',
      };

      // Validate Pacto Amistoso
      const resultAAP = await validateAAP(
        inspectionState?.currentInspection?.EventRecord,
      );

      if (
        resultAAP &&
        resultAAP.statusCode !== AAPValidationOptions.OK &&
        modelInspection?.CountryCode === CountryCode.CRC.toUpperCase() &&
        transition.eventStateId === TransitionStatesValues.COMPLETED &&
        modelInspection?.IsVitualInspector === 0
      ) {
        alertMessage(
          'warning',
          AAPValidationMessage[resultAAP.statusCode] ??
            'No se puede determinar el uso del Pacto Amistoso.',
        );
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      } else {
        // Validate other form fields
        if (modelInspection && modelInspection.formIsValid) {
          const requiredSinisterData =
            transition.eventStateId === 3 &&
            modelInspection.CountryCode.toUpperCase() === CountryCode.PAN &&
            modelInspection.CompanyId !== 16 &&
            (modelInspection.sinisterCause === undefined ||
              Number(modelInspection.sinisterCause) === 0 ||
              modelInspection.sinisterType === undefined ||
              Number(modelInspection.sinisterType) === 0)
              ? true
              : false;
          const requiredSinisterTypeMsg =
            transition.eventStateId === 3 &&
            (modelInspection.sinisterType === undefined ||
              Number(modelInspection.sinisterType) === 0)
              ? 'El tipo de siniestro es requerido.'
              : '';
          const requiredSinisterCauseMsg =
            transition.eventStateId === 3 &&
            (modelInspection.sinisterCause === undefined ||
              Number(modelInspection.sinisterCause) === 0)
              ? 'La causa de siniestro es requerida.'
              : '';

          if (!requiredSinisterData) {
            const {
              data: { success },
            } = await saveEventTransition(body);

            if (success) {
              await fetchEventTransitions(Number(id), setTransitions);
              // Update event status in the current inspection
              dispatch({
                type: ActionType.SET_EVENT_STATUS,
                payload: transition.eventStateId,
              });
            }
          } else {
            const msg =
              requiredSinisterTypeMsg + ' ' + requiredSinisterCauseMsg;
            // Info for track
            alertMessage('warning', msg);
          }
        } else {
          // Info for track
          alertMessage(
            'warning',
            '<b>No es posible cambiar de estado, aún faltan <u>datos requeridos</u>.</b>',
          );
        }

        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    } else {
      alertMessage(
        'error',
        'La fecha de inspección debe ser mayor que la fecha del accidente.',
      );
    }
  };

  const addTransitionForPreInspect = async (
    transition: NextTransition,
    isComment = false,
  ) => {
    if (!props.isReadOnly) {
      preInspectDispatch({ type: ActionType.SET_LOADING, payload: true });
      if (modelPreInspection && modelPreInspection?.preInspectionId > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const body = {
          preInspectId: modelPreInspection?.preInspectionId,
          statusId: transition.eventStateId,
          transitionDate: new Date(),
          isComentary: isComment,
        };

        const {
          data: { success },
        } = await savePreInspectTransition(body);

        if (success) {
          await fetchPreInspectTransitions(
            modelPreInspection?.preInspectionId,
            setTransitions,
          );
          // Update event status in the current inspection
          preInspectDispatch({
            type: ActionType.SET_EVENT_STATUS,
            payload: transition.eventStateId,
          });
        }
      }
      preInspectDispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const addTransitionByAudit = async (transition: NextTransition) => {
    setAuditNextTransition(transition);
    if (inspectionState.auditEvent.sectionsAudit.length > 0) {
      await addTransition(transition);
    } else {
      setShowAuditChangeState(true);
    }
  };

  const triggerTransitionByAudit = async () => {
    setShowAuditChangeState(false);
    if (auditNextTransition) {
      await addTransition(auditNextTransition);
    }

    //Send Bitacora
    if (modelInspection?.EventId && modelInspection.formIsValid) {
      await saveEventLog(
        modelInspection?.EventId,
        Number(localStorage.getItem('userId')),
        'Se cambia de estado sin aplicar auditoría.',
      );
    }
  };

  const transitionPermission = (): NextTransition | undefined => {
    if (props.sectionType === 'PRE-INSPECTIONS') {
      return lastTransition?.nextTransition;
    }

    const currentCountry = localStorage.getItem('countryCode');
    const roleId = localStorage.getItem('rolId') ?? '0';
    const currentRole = roles.filter((f: any) => f.id === +roleId)[0];
    const processPermission = userActionsPermissions(
      ResourceAction.USE_BTN_PROCESS_EVENT,
    );
    const toCompletePermission = userActionsPermissions(
      ResourceAction.LOG_STATE_TO_COMPLETE,
    );
    const onlyTerminatePermission = userActionsPermissions(
      ResourceAction.LOG_STATE_ONLY_TERMINATE,
    );

    if (
      !processPermission &&
      lastTransition?.nextTransition?.eventStateDesc ===
        TransitionStates.PROCESSED
    ) {
      return undefined;
    }

    if (currentRole) {
      switch (currentRole.name) {
        case roleNames.INSURANCE:
          if (
            onlyTerminatePermission &&
            lastTransition?.nextTransition?.eventStateId &&
            ((String(currentCountry).toUpperCase() === CountryCode.PAN &&
              lastTransition?.nextTransition?.eventStateId < 4) ||
              (String(currentCountry).toUpperCase() === CountryCode.CRC &&
                lastTransition?.nextTransition?.eventStateId < 3))
          ) {
            return undefined;
          }
          break;
        case roleNames.ADMIN:
          if (
            !onlyTerminatePermission &&
            lastTransition?.nextTransition?.eventStateId &&
            lastTransition?.nextTransition?.eventStateId >= 4
          ) {
            return undefined;
          }
          break;
        default:
          if (
            toCompletePermission &&
            lastTransition?.nextTransition?.eventStateId &&
            lastTransition?.nextTransition?.eventStateId > 2
          ) {
            return undefined;
          }
          break;
      }
    }

    return lastTransition?.nextTransition;
  };

  const validTransition = transitionPermission();
  let transitionIsInclude = false;
  if (validTransition !== undefined) {
    transitionIsInclude =
      transitions.findIndex(
        (f: Transition) => f.eventStateId === validTransition.eventStateId,
      ) > 0;
  }

  return (
    <div className="activity-log">
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Nombre</StyledTableCell>
              <StyledTableCell>Estado</StyledTableCell>
              <StyledTableCell>Fecha de Transición</StyledTableCell>
              <StyledTableCell>Descripción</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(transitions).map((row: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{row.userName}</StyledTableCell>
                <StyledTableCell align="center">
                  <StatusChip eventStatus={row.eventStateDesc} />
                </StyledTableCell>
                <StyledTableCell>
                  {format(
                    Date.parse(row.transitionDate),
                    'dd/MM/yyyy HH:mm:ss',
                    {
                      locale: esLocale,
                    },
                  )}
                </StyledTableCell>
                <StyledTableCell>{row.description}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {validTransition && !transitionIsInclude && (
        <Button
          variant="contained"
          color="primary"
          className="transition__button"
          disabled={props.isReadOnly}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          onClick={() => {
            if (props.sectionType === 'INSPECTIONS') {
              if (isAuditProcess) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                addTransitionByAudit(validTransition!);
              } else {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                addTransition(validTransition!);
              }
            } else {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              addTransitionForPreInspect(validTransition!);
            }
          }}
        >
          {validTransition.eventStateDesc}
        </Button>
      )}

      <Dialog
        open={showAuditChangeState}
        onClose={() => setShowAuditChangeState(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cambiar de Estado</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              ¿Deseas <b>cambiar de estado</b> aún cuando no has auditado las
              secciones?
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setShowAuditChangeState(false)}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={triggerTransitionByAudit}
            color="primary"
          >
            Sí, confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActivityLog;
