import React, { useEffect, useState } from 'react';
import './styles.scss'; // Archivo de estilos CSS

interface ProgressBarModalProps {
  progress: number;
}

const ProgressBarModal: React.FC<ProgressBarModalProps> = ({ progress }) => {
  return (
    <div className="modal-content">
      <div className="modal-content-title">CARGANDO ARCHIVOS</div>
      <div className="modal-content-subtitle">
        ESPERA MIENTRAS COMPLETAMOS ESTE PROCESO :)
      </div>

      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progress}%` }} />
      </div>
      <div className="progress-bar-label">{`${progress}% Completado`}</div>
    </div>
  );
};

export default ProgressBarModal;
