import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { globalCss } from 'assets/connect/styles/_variables';

const drawerWidth = 200;

export default makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      borderRight: '1px solid #D9D9D9',
      cursor: 'pointer',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      borderRight: '1px solid #D9D9D9',
      '&:hover': {
        boxShadow: globalCss.boxShadow,
      },
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
      '&:hover': {
        boxShadow: globalCss.boxShadow,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    toolbarButton: {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },
    menuTabContainer: {
      borderBottom: '1px solid #f9f7f7',
    },
  }),
);
