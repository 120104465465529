import { set } from 'lodash';
import APIClient, { endpoints } from 'shared/api';
import { ActionType, DispatchFunction } from 'types/action';
import { FieldSection } from 'types/section-fields';
import { Field } from 'types/form-field';
import { EventPhoto } from 'types/images';
import {
  CompanyFolderRoot,
  CountryCode,
  CountryIds,
  IRotateImage,
  ITriggerAction,
} from 'types/common';
import { getFileInfo, requestChangeImageOrientantion } from 'shared/utils';
import { AxiosResponse } from 'axios';
import {
  ICreatePreinspectionForm,
  IDamageConfiguration,
  IPreInspection,
  IPreInspectionState,
  IPreInspectStepTrack,
} from 'types/pre-inspection';

// Get Pre-Inspection list events
export const getPreInspections = async (
  params: { [key: string]: any },
  dispatch: DispatchFunction,
): Promise<void> => {
  try {
    const userCompanyId = params.companyId || localStorage.getItem('companyId');
    const userRolId = localStorage.getItem('rolId');
    const countryCode = localStorage.getItem('countryCode');
    const runnerCode = localStorage.getItem('runnerCode');
    const companyIdList = localStorage.getItem('companyIdList') || undefined;
    if (
      countryCode !== undefined &&
      countryCode != null &&
      countryCode?.length > 0
    ) {
      // Validate if runner
      if (runnerCode && String(runnerCode).length > 0) {
        params = { ...params, runnerCode: runnerCode };
      }

      const companyId =
        userRolId === '1' && !params.companyId ? null : userCompanyId;
      const response = await APIClient.get(endpoints.GET_PRE_INSPECTIONS, {
        params: {
          ...params,
          companyId: companyIdList && !params.companyId ? undefined : companyId,
          rolId: userRolId,
          page: params.page + 1 || 1,
          countryCode: countryCode,
          companyIdList: companyIdList,
        },
      });

      if (response) {
        dispatch({
          type: ActionType.GET_PREINSPECTS_SUCCESS,
          payload: {
            preInspections: response.data.data.response.preInspects,
            indicators: response.data.data.response.indicators,
          },
        });
      }
    }
  } catch (error) {
    dispatch({
      type: ActionType.GET_EVENTS_FAIL,
      payload: 'No se pudo obtener la lista de pre-inspecciones',
    });
  }
};

// Get Damage Configuration By Company
export const getDamageConfiguration = async (
  companyId: number,
): Promise<IDamageConfiguration> => {
  const response = await APIClient.get(
    endpoints.GET_DAMAGE_CONFIG.replace('{id}', String(companyId)),
    {
      params: { companyId },
    },
  );

  return response.data;
};

//Save damage configuration
export async function saveDamageConfiguration(
  data: IDamageConfiguration,
): Promise<string> {
  const response = await APIClient.post(endpoints.POST_DAMAGE_CONFIG, data);

  return response.data.data;
}

// Get Pre-Inspection Event by Id
export const getPreInspectionById = async (
  preInspectId: number,
  dispatch: DispatchFunction,
): Promise<void> => {
  try {
    const response = await APIClient.get(
      endpoints.GET_PRE_INSPECTION.replace('{id}', String(preInspectId)),
    );

    if (response && response.data) {
      const preInspection = response.data;
      let countryCode = CountryCode.PAN;
      if (CountryIds.CRC === +preInspection.countryId) {
        countryCode = CountryCode.CRC;
      } else if (CountryIds.PRI === +preInspection.countryId) {
        countryCode = CountryCode.PRI;
      }

      dispatch({
        type: ActionType.SET_PREINSPECTS_UNIQUEID,
        payload: {
          uniqueId: preInspection.uniqueId,
          preInspectionId: preInspection.preInspectionId,
          countryCode: countryCode,
          statusId: preInspection.statusId,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ActionType.GET_EVENTS_FAIL,
      payload: 'No se pudo obtener la pre-inspección solicitada.',
    });
  }
};

// Get Form configuration
export const getFormConfiguration = async (
  params: { [key: string]: any },
  dispatch: DispatchFunction,
): Promise<FieldSection[] | null> => {
  try {
    const {
      data: {
        data: { sections },
      },
    } = await APIClient.post(endpoints.GET_FORM_DATA_CONFIG, params);

    return sections;
  } catch (error) {
    dispatch({
      type: ActionType.GET_EVENTS_FAIL,
      payload: 'No se pudo obtener la configuración del formulario',
    });
  }

  return null;
};

// Send the PreInspection Object with the last changes
export async function savePreInspection(
  preInspectId: number,
  state: IPreInspectionState,
): Promise<void> {
  const formDataSections = state.formPreInspection;
  if (formDataSections) {
    const preInspectObj: any = {};
    preInspectObj.PreInspectionId = preInspectId;

    formDataSections.forEach((fs: FieldSection) => {
      fs.fields.forEach((fv: Field) => {
        if (fv.modelFieldName) {
          let currentValue: any = fv.fieldFormValue;
          if (fv.endPoint) {
            if (
              fv.endPoint.toUpperCase() === 'INTEGER' ||
              fv.endPoint.toUpperCase() === 'DECIMAL'
            ) {
              currentValue = Number(fv.fieldFormValue);
            } else if (fv.endPoint.toUpperCase() === 'BOOLEAN') {
              currentValue =
                fv.fieldFormValue === '1' ||
                fv.fieldFormValue === 'ON' ||
                (typeof fv.fieldFormValue == 'boolean'
                  ? fv.fieldFormValue
                  : false);
            }
          }
          set(preInspectObj, fv.modelFieldName, currentValue);
        }
      });
    });

    if (preInspectObj.PreInspectionId > 0) {
      const result = await APIClient.post(
        endpoints.POST_PRE_INSPECTION,
        preInspectObj,
      );

      if (result && result.data) {
        const { response } = result.data.data;
        if (response) {
          // eslint-disable-next-line no-console
          console.log('Info :: Autosave is successfully');
        }
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn('Info :: Autosave is failed - Pre-inspection ID not found.');
    }
  }
}

export const triggerProcess = (
  action: ITriggerAction,
  state: IPreInspectionState,
): void => {
  try {
    switch (action) {
      case ITriggerAction.ROTATE_PREINSPECTION_IMAGES: {
        if (
          state.rotateImages &&
          state.rotateImages.filter(
            (f: IRotateImage) =>
              f.orientation !== null && f.imageURL.length > 0,
          ).length > 0
        ) {
          requestChangeImageOrientantion(state.rotateImages);
        }
        break;
      }
      default:
        break;
    }
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.log(ex);
  }
};

// Show or not show the Spinner
export const setLoading = (
  value: boolean,
  dispatch: DispatchFunction,
): void => {
  dispatch({ type: ActionType.SET_LOADING, payload: value });
};

// Get media files for any resources with folder root
export const getMediaFiles = async (
  folderRoot: string,
  preInspectId: string,
  dispatch: DispatchFunction,
): Promise<void> => {
  if (preInspectId.length > 0) {
    const root = `${folderRoot}/${preInspectId.toLowerCase()}`;
    const response = await APIClient.post(endpoints.GET_MEDIA_FILES, {
      folderPath: root,
    });

    const mediaFiles: EventPhoto[] = [];

    if (response.data.data) {
      Array.from(response.data.data).forEach((p: any) => {
        const fileInfo = getFileInfo(p.url);
        const file: EventPhoto = {
          awsUrl: p.url,
          section: +fileInfo.filePrefix,
          imageName: fileInfo.fileName,
          dataURL: p.url,
        };
        mediaFiles.push(file);
      });
    }

    dispatch({
      type: ActionType.SET_PREINSPECTS_MEDIAFILES,
      payload: mediaFiles,
    });
  }
};

export const getUrlByPreInspection = async (
  event: IPreInspection | any,
): Promise<void> => {
  const preinspectionUrl = event.preInspectionLink + event.uniqueId;
  window.open(preinspectionUrl, '_blanky');
};

// Download event
export const downloadPreinspection = async (
  event: IPreInspection | any,
  dispatch: DispatchFunction,
): Promise<void> => {
  setLoading(true, dispatch);
  const folderRoot = CompanyFolderRoot.find(
    (config: any) =>
      String(config.key).toLowerCase().indexOf(event.apiKey.toLowerCase()) > -1,
  )?.folder;
  const root = `preinspections/${folderRoot}/` + event.uniqueId.toLowerCase();
  const data = {
    folderPath: root,
  };
  // Send download request
  setTimeout(async () => {
    const response = await APIClient.post(
      endpoints.POST_DOWNLOAD_PREINSPECTION,
      data,
    );
    if (response) {
      const FileSaver = require('file-saver');
      FileSaver.saveAs(response.data?.data);
    }

    setLoading(false, dispatch);
  }, 1500);
};

// Remove media file for specific resource
export const deleteMediaFiles = async (resource: string): Promise<boolean> => {
  const response = await APIClient.post(endpoints.DELETE_MEDIA_FILES, {
    fileURL: resource,
  });

  return response.data.data;
};

// Save media files in the context state
export const saveMediaFiles = (
  files: EventPhoto[],
  dispatch: DispatchFunction,
): void => {
  try {
    dispatch({ type: ActionType.SET_PREINSPECTS_MEDIAFILES, payload: files });
  } catch (error) {
    dispatch({
      type: ActionType.MESSAGE_ACTION,
      payload: 'Se encontraron problemas al guardar la información.',
    });
  }
};

// Upload media files
export const uploadMediaFiles = async (
  preInspectionsId: string,
  folderRoot: string,
  files: EventPhoto[],
): Promise<void> => {
  const root = `${folderRoot}/${preInspectionsId.toLowerCase()}`;

  files.forEach((media: EventPhoto) => {
    setTimeout(async () => {
      const formData = new FormData();
      formData.append('FolderRoot', root);
      formData.append('FileDataBase64', media.dataURL);
      formData.append('FileName', media.imageName ?? '');
      formData.append('FileExtension', media.imageType ?? '');

      await APIClient.post(endpoints.POST_MEDIA_UPLOAD, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
    }, 700);
  });
};

// Get Transition
export const getPreInspectTransitions = async (
  preInspectId: number,
): Promise<any> => {
  const {
    data: { data },
  } = await APIClient.get(endpoints.GET_PRE_INSPECT_TRANSITIONS, {
    params: { preInspectId },
  });

  return data;
};

// Save transition
export const savePreInspectTransition = async (transitionData: {
  [key: string]: any;
}): Promise<AxiosResponse<any>> => {
  return await APIClient.post(
    endpoints.POST_PRE_INSPECT_TRANSITIONS,
    transitionData,
  );
};

// Update preinspection alert
export const updatePreinspectionAlert = async (
  alertId: number,
  isChecked: boolean,
): Promise<AxiosResponse<any>> => {
  const body = { alertId, isChecked };

  return await APIClient.post(endpoints.POST_PRE_INSPECTION_ALERT_UPDATE, body);
};

// Get preinpection alerts
export const getPreinspectionAlerts = async (
  preInspectId: number,
  dispatch: DispatchFunction,
): Promise<any> => {
  try {
    const {
      data: { data },
    } = await APIClient.get(endpoints.GET_PRE_INSPECTION_ALERTS, {
      params: { preInspectId },
    });

    return data;
  } catch (e) {
    dispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: true,
        alertMessage: 'Hubo un error al obtener la información de alertas',
        alertType: 'error',
      },
    });
  }

  return null;
};

// Get preinspection Damages
export const getPreinspectionDamages = async (
  preInspectId: number,
  dispatch: DispatchFunction,
): Promise<any> => {
  try {
    const {
      data: { data },
    } = await APIClient.get(endpoints.GET_PRE_INSPECTION_DAMAGES, {
      params: { preInspectId },
    });

    return data;
  } catch (e) {
    dispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: true,
        alertMessage:
          'Error de análisis: Se ha producido un error al analizar las fotografías.',
        alertType: 'error',
      },
    });
  }

  return null;
};

export const requestAnalysisDamages = async (
  uniqueId: string,
  dispatch: DispatchFunction,
): Promise<void> => {
  try {
    APIClient.get(endpoints.REQUEST_ANALYSIS_DAMAGE.replace('{id}', uniqueId));
  } catch (e) {
    dispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: true,
        alertMessage:
          'Error de análisis: Se ha producido un error al analizar las fotografías.',
        alertType: 'error',
      },
    });
  }
};

export const sharedPreInspection = async (
  email: string,
  link: string,
): Promise<any> => {
  APIClient.post(endpoints.POST_PRE_INSPECTION_SHARED, {
    Id: 0,
    CompanyId: Number(localStorage.getItem('companyId')),
    Type: 'EMAIL',
    Email: email,
    Link: link,
  });
};

// Update preinspection for remove expiration
export const sendRemoveExpiration = async (
  preinspectId: number,
): Promise<AxiosResponse<any>> => {
  return await APIClient.put(
    endpoints.PUT_PRE_INSPECTION_REMOVE_EXPIRE.replace(
      '{id}',
      preinspectId.toString(),
    ),
  );
};

export const createPreInspection = async (
  preInspection: ICreatePreinspectionForm,
  uniqueId: string,
): Promise<any> => {
  const lastNameParts = preInspection.lastName.split(' ');
  APIClient.post(endpoints.POST_PRE_INSPECTION, {
    PreInspectionId: 0,
    IdInsured: preInspection.id,
    Name: preInspection.name,
    LastName1: lastNameParts[0] || '',
    LastName2: lastNameParts[1] || '',
    UniqueId: uniqueId,
    VehiclePlate: preInspection.vehicleplate,
    BrokerCode: preInspection.brokercode,
    CompanyId: Number(localStorage.getItem('companyId')),
    Vin: preInspection.vin,
    CreatedBy: Number(localStorage.getItem('userId')),
  });
};

// Get Tracking Data from specifica preinspection
export const getPreinspectTrackData = async (
  event: IPreInspection | any,
  dispatch: DispatchFunction,
): Promise<void> => {
  setLoading(true, dispatch);
  const response = await APIClient.get(
    endpoints.GET_PRE_INSPECTION_TRACK.replace('{id}', event.preInspectionId),
  );

  dispatch({
    type: ActionType.SET_PREINSPECTS_STEPTRACK,
    payload: response.data as IPreInspectStepTrack,
  });

  setLoading(false, dispatch);
};
