/**
 * Method to evaluate if there are models to request retries in the local storage
 * @param name The name of the model to evaluate
 * @param currentModel The current model in the store
 * @returns The current model or the model in the local storage
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const evaluateRetries = (name: string, currentModel: any): any => {
  const model = localStorage.getItem(name) ?? undefined;
  if (model) {
    return JSON.parse(model);
  }

  return currentModel;
};

/**
 * Method to save the model in the local storage
 * @param name The name of the model to save
 * @param model The model to save in the local storage
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saveRetries = (name: string, model: any): void => {
  localStorage.setItem(name, JSON.stringify(model));
};

/**
 * Method to clear the model in the local storage
 * @param name The name of the model to clear
 */
export const clearRetries = (name: string): void => {
  localStorage.removeItem(name);
  localStorage.setItem('error-data', 'restore-model');
};

/**
 * Method to evaluate if there are models to request retries in the local storage
 * @param name The name of the model to evaluate
 * @returns  A boolean value that indicates if the model exists in the local storage
 */
export const existsRetries = (name: string): boolean => {
  const model = localStorage.getItem(name) ?? undefined;
  if (model) {
    return true;
  }

  return false;
};

export default {
  evaluateRetries,
  saveRetries,
  clearRetries,
  existsRetries,
};
