import { ActionType, IAction } from 'types/action';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const filterReducer = (state: any, action: IAction) => {
  switch (action.type) {
    case ActionType.SET_QUERY_FILTERS:
      return {
        ...action.payload,
      };
    default:
      return {
        ...state,
        loading: false,
      };
  }
};

export default filterReducer;
