import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';
import Loader from 'components/common/loader/Loader';
import FormSteps from 'components/inspection/form-steps';
import InspectorTopBar from 'components/inspection/inspector-topbar/InspectorTopBar';
import FormSection from 'components/inspection/section/FormSection';
import {
  customFieldDependency,
  defaultSignImg,
  filterSectionsByDependencies,
} from 'components/inspection/section/utils';
import {
  getInspectorsCatalog,
  getLocationsCatalog,
  IUserContext,
  UserContext,
} from 'context/user';
import INSPECTORS from 'static/constants/inspectors';
import PATH_ROUTES from 'static/constants/path-routes';
import CATALOGS, { availableCoverages } from 'static/constants/catalogs';
import {
  BrandsId,
  CheckBoxTypes,
  CountryCode,
  DropDownTypes,
  FormActiveType,
  FormType,
  ICheckListModel,
  InspectionDataSections,
  ResourceAction,
} from 'types/common';
import { Rol } from 'types/common';
import { Field } from 'types/form-field';
import { ICoverage, IInspection, IInspectionState } from 'types/inspection';
import { Dependency, FieldSection } from 'types/section-fields';
import './styles.scss';
import {
  IInspectionContext,
  InspectionContext,
  getInspectionById,
  getFormConfiguration,
  saveInspection,
  presignedAudioUrl,
  AddAuditLog,
} from 'context/inspections';
import { ActionType, DispatchFunction } from 'types/action';
import { FieldTypes } from 'components/inspection/section/InputField';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Modal,
  NativeSelect,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
//@ts-ignore
import { Color, Alert, AlertColor } from '@mui/lab';
import {
  scrollToTop,
  userActionsPermissions,
  email_validate,
  exportTxtFileError,
} from 'shared/utils';
import useDevice from 'shared/hooks/useDevice';
import { EventPhoto, PhotoSection } from 'types/images';
import InactiveScreen from 'components/common/inactive-screen/inactiveScreen';
import { IClaim, ClaimantType } from 'types/claimsTracker';
import useValidBrowser from 'shared/hooks/useValidBrowser';
import IncompatibleView from 'components/inspection/inspector-topbar/IncompatibleView';
import { existsRetries } from 'shared/utils/data-retries';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useJiraWidget from 'shared/hooks/useJiraWidget';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseState = (
  formSections: FieldSection[],
  inspectionState: IInspectionState,
  dispatch: DispatchFunction,
  isInspectorForm: boolean,
) => {
  const isAuditProcess =
    Number(localStorage.getItem('rolId')) === Rol.AUDIT && !isInspectorForm;
  const readOnlyPermission = Boolean(
    !isInspectorForm &&
      userActionsPermissions(ResourceAction.VIEW_EVENT_READ_ONLY),
  );
  const sections = [...formSections.filter((fs) => fs.visible !== false)]; // In case that visible comes as nullable, show as default.

  return sections.map((section) => {
    let provinceObj: any = undefined;
    section.fields = section.fields.map((field: any) => {
      const idName = field.idName;
      switch (section.name) {
        case InspectionDataSections.RESPONSABILITY:
          field.value = (inspectionState?.currentInspection?.guia as any)?.[
            idName
          ];
          break;
        case InspectionDataSections.AAP:
          field.value = (inspectionState?.currentInspection?.guia as any)?.[
            idName
          ];
          break;
        case InspectionDataSections.INSURED_OWNER:
          field.value = (inspectionState?.currentInspection
            ?.InsuredOwner as any)?.[idName];
          break;
        case InspectionDataSections.INJURED_OWNER:
          field.value = (inspectionState?.currentInspection
            ?.InjuredOwner as any)?.[idName];
          break;
        case InspectionDataSections.INSURED_DRIVER:
          field.value = (inspectionState?.currentInspection
            ?.InsuredDriver as any)?.[idName];
          break;
        case InspectionDataSections.INJURED_DRIVER:
          field.value = (inspectionState?.currentInspection
            ?.InjuredDriver as any)?.[idName];
          break;
        case InspectionDataSections.INSURED_VEHICLE:
          field.value = (inspectionState?.currentInspection
            ?.InsuredVehicle as any)?.[idName];
          break;
        case InspectionDataSections.INJURED_VEHICLE:
          field.value = (inspectionState?.currentInspection
            ?.InjuredVehicle as any)?.[idName];
          break;
        case InspectionDataSections.DEPOSITO_DIRECTO:
          field.value = (inspectionState?.currentInspection
            ?.Deposits as any)?.[0]?.deposit?.[idName];
          break;
        case InspectionDataSections.GLOBAL:
          field.value = (inspectionState?.currentInspection
            ?.InjuredOwner as any)?.[idName];
          break;
        case InspectionDataSections.SURA_ACCIDENT:
        case InspectionDataSections.OPTIMA_ACCIDENT:
          field.value = (inspectionState?.currentInspection?.optima as any)?.[
            idName
          ];
          break;
        case InspectionDataSections.OPTIMA_OWNER_A:
          if (inspectionState?.currentInspection?.AditionalInfo) {
            field.value = (inspectionState?.currentInspection
              ?.AditionalInfo[0] as any)?.[idName];
          }
          break;
        case InspectionDataSections.OPTIMA_OWNER_B:
          if (inspectionState?.currentInspection?.AditionalInfo) {
            field.value = (inspectionState?.currentInspection
              ?.AditionalInfo[1] as any)?.[idName];
          }
          break;
        case InspectionDataSections.RESPONSABLE_A:
          if (inspectionState?.currentInspection?.AditionalInfo) {
            field.value = (inspectionState?.currentInspection
              ?.AditionalInfo[0] as any)?.[idName];
          }
          break;
        case InspectionDataSections.RESPONSABLE_B:
          if (inspectionState?.currentInspection?.AditionalInfo) {
            field.value = (inspectionState?.currentInspection
              ?.AditionalInfo[1] as any)?.[idName];
          }
          break;
        case InspectionDataSections.THIRDS:
          if (inspectionState?.currentInspection?.thirds) {
            field.value = (inspectionState?.currentInspection
              ?.thirds[0] as any)?.[idName];
          }
          break;
        default:
          field.value = inspectionState?.currentInspection?.[idName];
          break;
      }

      if (field.idName === 'HasNotInsuredDamage') {
        if (field.value === null) {
          field.value = '1';
        }
      }

      if (
        field.fieldFormType === FieldTypes.AUDIO.toUpperCase() &&
        field.value
      ) {
        presignedAudioUrl(field.value).then((presignedUrl) => {
          switch (field.idName) {
            case 'AudioStoryUrl':
              dispatch({
                type: ActionType.SET_AUDIO_PRESIGNED,
                payload: presignedUrl,
              });
              break;
            case 'AudioVehicleInsured':
              dispatch({
                type: ActionType.SET_AUDIO_INSURED_PRESIGNED,
                payload: presignedUrl,
              });
              break;
            case 'AudioVehicleInjured':
              dispatch({
                type: ActionType.SET_AUDIO_INJURED_PRESIGNED,
                payload: presignedUrl,
              });
              break;
          }
        });
      } else if (field.fieldFormType === FieldTypes.AUDIO.toUpperCase()) {
        switch (field.idName) {
          case 'AudioStoryUrl':
            dispatch({
              type: ActionType.SET_AUDIO_PRESIGNED,
              payload: '',
            });
            break;
          case 'AudioVehicleInsured':
            dispatch({
              type: ActionType.SET_AUDIO_INSURED_PRESIGNED,
              payload: '',
            });
            break;
          case 'AudioVehicleInjured':
            dispatch({
              type: ActionType.SET_AUDIO_INJURED_PRESIGNED,
              payload: '',
            });
            break;
        }
      }

      // Validate dropdown values
      let dataType = '';
      if (field.dropDownOptions) {
        switch (field.dropDownOptions) {
          case DropDownTypes.INSPECTORS:
            dataType = DropDownTypes.INSPECTORS.toString();
            field.dropDownOptions = INSPECTORS.getDataToField();
            break;
          case DropDownTypes.PROVINCES:
            provinceObj = field;
            dataType = DropDownTypes.PROVINCES.toString();
            field.dropDownOptions = CATALOGS.getProvinces();
            break;
          case DropDownTypes.CANTONS: {
            dataType = DropDownTypes.CANTONS.toString();
            if (
              (!field.value || String(field.value).length === 0) &&
              provinceObj
            ) {
              field.dropDownOptions = CATALOGS.getCantons(
                '',
                +provinceObj.value,
              );
            } else {
              field.dropDownOptions = CATALOGS.getCantons(field.value);
            }
            break;
          }
          case DropDownTypes.DISTRICTS:
            dataType = DropDownTypes.DISTRICTS.toString();
            field.dropDownOptions = CATALOGS.getDistricts(field.value);
            break;
          default:
            break;
        }
      }

      // Validate checkbox list values
      if (field.checkBoxOptions) {
        switch (field.checkBoxOptions) {
          case CheckBoxTypes.COVERAGES: {
            dataType = CheckBoxTypes.COVERAGES.toString();
            const checkOptions = Array.from(availableCoverages);
            const checkDataList: ICheckListModel[] = [];
            if (inspectionState?.currentInspection?.coverages) {
              Array.from(inspectionState?.currentInspection?.coverages).forEach(
                (coverage: ICoverage) => {
                  const currentData: ICheckListModel = {
                    id: coverage.coverageId,
                    name: coverage.coverageName,
                    state: true,
                  };
                  checkDataList.push(currentData);
                },
              );
            }
            field.checkBoxOptions = JSON.stringify(checkOptions);
            field.value = JSON.stringify(checkDataList);
            break;
          }
          case CheckBoxTypes.COVERAGES_QUALITAS: {
            dataType = CheckBoxTypes.COVERAGES_QUALITAS.toString();
            if (inspectionState?.currentInspection?.qualitasCoverages) {
              const checkOptions: ICheckListModel[] = [];
              Array.from(
                inspectionState?.currentInspection?.qualitasCoverages,
              ).forEach((coverage: ICoverage) => {
                const currentData: ICheckListModel = {
                  id: coverage.coverageId,
                  name: coverage.coverageName,
                  state: true,
                };

                checkOptions.push(currentData);
              });
              const checkDataList: ICheckListModel[] = [];
              Array.from(inspectionState?.currentInspection?.qualitasCoverages)
                .filter(
                  (x: ICoverage) => x.Selected !== null && x.Selected === true,
                )
                .forEach((coverage: ICoverage) => {
                  const currentData: ICheckListModel = {
                    id: coverage.coverageId,
                    name: coverage.coverageName,
                    state: true,
                  };
                  checkDataList.push(currentData);
                });
              field.checkBoxOptions = JSON.stringify(checkOptions);
              field.value = JSON.stringify(checkDataList);
            }
            break;
          }
          default:
            break;
        }
      }

      // Validate if the field apply to audit process
      if (
        field.visible &&
        field.enabled &&
        isAuditProcess &&
        !isInspectorForm
      ) {
        field.isForAudit = true;
      }

      return {
        name: field.idName,
        label: field.labelName,
        type: field.fieldFormType,
        value: field.value,
        originalValue: field.value,
        options: (
          field.checkBoxOptions ||
          field.dropDownOptions ||
          field.radioButtonOptions
        )?.split('¬'),
        viewLength: field.viewLength,
        contentText: field.contentText,
        formSectionId: field.formSectionId,
        idOrder: field.idOrder,
        fieldPath: field.modelFieldName,
        dependency: field.dependency,
        isDisabled:
          isAuditProcess && !isInspectorForm
            ? isAuditProcess
            : readOnlyPermission
            ? true
            : !field.enabled ?? false,
        dataType: dataType,
        visible: field.visible,
        required: field.required,
        sendFieldType: field.sendFieldType,
        hasError: false,
        hadInteraction: false,
        isForAudit: field.isForAudit,
        isModifyForAudit: false,
        maxLength: field.maxLength,
        defaultValue: field.defaultValue,
      } as Field;
    });

    return section;
  });
};

const filterTabs = (
  sectionTabs: FieldSection[],
  sections: FieldSection[],
  isInspectorForm = false,
  currentInspection?: any,
) => {
  const isCustomer = Number(localStorage.getItem('rolId')) === Rol.CUSTOMER;
  const filterTabs = userActionsPermissions(ResourceAction.VIEW_EVENT_TABS);
  let tabs = sectionTabs.filter((s) => {
    const result = filterSectionsByDependencies(s, sections, currentInspection);
    if (
      sections.filter(
        (sec) =>
          sec.parentSectionId === s.formSectionId &&
          sec.name === InspectionDataSections.CIRCUNSTANCES,
      ).length > 0
    ) {
      localStorage.setItem('circunstancesTab', result ? '1' : '0');
    }

    return result;
  });
  tabs = tabs.filter(
    (s) =>
      !isCustomer ||
      (isCustomer && Array.from(filterTabs).includes(String(s.formSectionId))),
  );

  if (currentInspection?.ClaimantType) {
    if (
      String(currentInspection.ClaimantType).toUpperCase() ===
      ClaimantType.CLAIM_INSURED
    ) {
      tabs = tabs.filter(
        (t) => ![13].includes(t.formSectionId), // Remove Injured Tab
      );
    }
    if (
      String(currentInspection.ClaimantType).toUpperCase() ===
      ClaimantType.CLAIM_INJURED
    ) {
      tabs = tabs.filter(
        (t) => ![12].includes(t.formSectionId), // Remove Insured Tab
      );
    }
  }

  if (isInspectorForm) {
    tabs.splice(-1);

    return tabs.filter(
      (t) =>
        !['QUALITAS', 'RESPONSABILITY'].includes((t.name || '').toUpperCase()),
    );
  }

  let filteredTabs = tabs.filter(
    (t) => !['AAP'].includes((t.name || '').toUpperCase()),
  );

  const rolId = Number(localStorage.getItem('rolId'));
  if (rolId === Rol.INSPECTOR) {
    filteredTabs = tabs.filter(
      (t) => !['QUALITAS'].includes((t.name || '').toUpperCase()),
    );
  }

  return filteredTabs; // Circunstances
};

const validateField = (params: {
  field: Field;
  referFields: Field[];
  listRequiredFields: any[];
  section: FieldSection;
}) => {
  const { field, referFields, listRequiredFields, section } = params;
  const validationRules = (fieldObj: Field): boolean => {
    let valid =
      fieldObj.value !== undefined &&
      fieldObj.value !== null &&
      (String(fieldObj.value) !== '0' ||
        ['INTEGERSTR', 'DECIMAL', 'INTEGER'].includes(
          fieldObj.sendFieldType ?? '',
        ) ||
        (String(fieldObj.value) === '0' &&
          fieldObj.fieldPath === 'DriverIdentification'));
    if (valid) {
      switch (field.type?.toLowerCase()) {
        case FieldTypes.DROPDOWN:
          valid = String(field.value).trim().length > 0;
          if (String(field.value) === '0') {
            const exists = field.options?.filter((f: string) =>
              f.includes('0|'),
            )[0];

            valid = exists !== null && exists !== undefined;
          }
          break;
        case FieldTypes.STYLIZED_RADIOBUTTONS:
        case FieldTypes.DYNAMIC_DROPDOWN:
        case FieldTypes.TEXT:
        case FieldTypes.TEXTAREA:
        case FieldTypes.FILLED_TEXT:
          if (field.sendFieldType === 'EMAIL') {
            valid = email_validate(field.value);
          } else {
            valid =
              String(field.value).trim().length > 0 ||
              String(field.value) === '0';
          }
          break;
        case FieldTypes.RADIOBUTTON:
          valid = String(field.value).length > 0;
          break;
        default:
          break;
      }
    } else if (section.dependencyJson && !section.dependency) {
      valid = true;
    }

    if (field.type?.toLowerCase() === FieldTypes.SIGNATURE) {
      if (field.name === 'ResponsableAccidenteFirma1') {
        valid = valid && field.value !== defaultSignImg;
      }
    }

    return valid;
  };

  field.hasError = !validationRules(field);

  if (field.dependency) {
    const referenceField = referFields.filter(
      (f) =>
        f.name === field.dependency?.name &&
        f.value === field.dependency.expectedValue,
    )[0];

    if (
      referenceField &&
      referenceField.name === field.dependency?.name &&
      field.required
    ) {
      field.hasError = !validationRules(field);
    }
  }

  if (field.type?.toLowerCase() !== FieldTypes.DIAGRAM) {
    if (field.hasError) {
      listRequiredFields.push({
        parentSectionId: section.parentSectionId,
        sectionName: section.title,
        label: field.label,
        value: field.value,
      });
      // Log Info
      // eslint-disable-next-line no-console
      console.log(
        String('*')
          .concat('Tab: ')
          .concat(String(section.parentSectionId ?? 0))
          .concat(' | Sección: ')
          .concat(section.title)
          .concat(' | Campo: ')
          .concat(field.label),
      );
    }
  }
};

const InspectionForm = (): JSX.Element => {
  const { state: userState } = useContext<IUserContext>(UserContext);
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const { id, sectionId, countryCode } = useParams<any>();
  const [sectionTabs, setSectionTabs] = useState<FieldSection[]>([]);
  const [sections, setSections] = useState<FieldSection[]>([]);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [typeMessage, setTypeMessage] = useState<Color | undefined>('success');
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [activeRefreshvalidation, setActiveRefreshvalidation] = useState<
    boolean
  >(true);
  const [claimTrackerSelected, setClaimTrackerSelected] = useState<string>('');
  const [inspectionFetched, setInspectionFetched] = useState<boolean>(false);
  const [formLayout, setFormLayout] = useState<FieldSection[] | null>(null);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [formLoaded, setFormLoaded] = useState<boolean>(false);
  const [enableBackStep, setEnableBackStep] = useState(false);
  const [isFormActivated, setIsFormActivated] = useState(true);
  const [hasAvailableRetries, setHasAvailableRetries] = useState(false);
  const [errorMsgForRetries, setErrorMsgForRetries] = useState('');

  const history = useHistory();
  const isInspectorForm = history.location.pathname.includes('/inspector/');
  const inspectionModel = inspectionState.currentInspection;
  const formPath = isInspectorForm
    ? `/inspector/${countryCode}`
    : '/inspections';
  const [showSnackbarPhoto, setShowSnackbarPhoto] = useState<boolean>(false);
  const [typeMessagePhoto, setTypeMessagePhoto] = useState<
    AlertColor | undefined
  >('success');
  const [alertMessagePhoto, setAlertMessagePhoto] = useState<string>('');
  const { dispatch: inspectDispatch, state: inspectContext } = useContext<
    IInspectionContext
  >(InspectionContext);

  if (countryCode) {
    localStorage.setItem('countryCode', countryCode.toUpperCase());
  }
  const { isDesktop } = useDevice();
  const isValidBrowser = useValidBrowser();
  const isAuditProcess =
    Number(localStorage.getItem('rolId')) === Rol.AUDIT && !isInspectorForm;
  const readOnlyPermission = Boolean(
    !isInspectorForm &&
      userActionsPermissions(ResourceAction.VIEW_EVENT_READ_ONLY),
  );
  const country = localStorage.getItem('countryCode');
  const filteredTabs = filterTabs(
    sectionTabs,
    sections,
    isInspectorForm,
    inspectionModel,
  );
  const index =
    filteredTabs.findIndex((t) => t.formSectionId === +sectionId) + 1;
  const nextStep = filteredTabs[index]?.formSectionId;
  let isFinished = false;
  if (isInspectorForm) {
    isFinished = filteredTabs.length === index;
  } else {
    isFinished = filteredTabs.length === index + 1;
  }

  const { search } = useLocation();

  const fetchInspection = useCallback(async (): Promise<IInspection | null> => {
    // Get current event data
    const inspection: any = await getInspectionById(
      id,
      dispatch,
      isInspectorForm,
    );
    setInspectionFetched(true);

    // set default storage data
    if (
      localStorage.getItem('countryCode')?.toUpperCase() === CountryCode.CRC &&
      inspection !== undefined
    ) {
      localStorage.setItem(
        'eventProvince',
        inspection?.provinceDirectionInspect,
      );
      localStorage.setItem('eventCanton', inspection?.cantonDirectionInspect);
      localStorage.setItem(
        'eventDistrict',
        inspection?.districtDirectionInspect,
      );
      localStorage.setItem(
        'placeDirectionInspect',
        inspection?.placeDirectionInspect,
      );
      localStorage.setItem(
        'eventDateInspection',
        inspection?.eventDateInspection,
      );
      localStorage.setItem('timeInspection', inspection?.timeInspection);
    }

    return inspection;
  }, [id, dispatch, isInspectorForm]);
  //
  const getFormType = (
    compayId: number,
    isVirtualInspector: boolean,
  ): number => {
    let formType = isVirtualInspector
      ? FormType.VIRTUAL_INSPECTOR
      : FormType.INSPECTION;

    formType =
      isInspectorForm &&
      (country === 'PAN' || country === 'PRI') &&
      Number(compayId) !== BrandsId.OPTIMA
        ? FormType.INSPECTOR_FORM
        : formType;

    return formType;
  };

  const fetchForm = useCallback(
    async (companyId?: number, isVirtualInspector = false) => {
      // Get current form fields

      const country = localStorage.getItem('countryCode');
      const _companyId = companyId
        ? String(companyId)
        : localStorage.getItem('companyId');
      const hasCountryCode = country && country !== 'undefined';

      let userRolId = userState.currentUserStatus.role;
      if (isInspectorForm) {
        userRolId = Rol.INSPECTOR;
        if (country) {
          await getLocationsCatalog(country);
          await getInspectorsCatalog(country);
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let formType = getFormType(Number(_companyId), isVirtualInspector);

      formType =
        isInspectorForm &&
        country === 'PAN' &&
        Number(_companyId) !== BrandsId.OPTIMA
          ? FormType.INSPECTOR_FORM
          : formType;

      const parameters = {
        companyId: _companyId,
        applicationId: 3,
        countryCode: (hasCountryCode && country) || 'PRI',
        rolId: userRolId,
        formType,
        IsInspector: isInspectorForm,
      };

      if (parameters.countryCode.length > 0) {
        setFormLayout(await getFormConfiguration(parameters, dispatch));
      }
    },
    [dispatch, isInspectorForm, userState.currentUserStatus.role],
  );

  const handleSnackBarClearMessage = (): void => {
    setShowNotification(false);
    setAlertMessage('');
  };

  const validateForm = useCallback(
    (modifiedField?: Field, isFinish = false): boolean => {
      // If has data for retries the process not validate the form
      if (hasAvailableRetries) {
        return true;
      }

      const splitLocationPath = history.location.pathname.split('/sections/');
      const currentParentSectionId =
        splitLocationPath.length > 1 ? splitLocationPath[1] : '0';
      let alertMessage =
        '<div id="validation-message"><b>Debes completar los campos requeridos del formulario</b></div>';
      let alertIsError = true;
      const currentCountry = !countryCode
        ? inspectionModel?.CountryCode
        : countryCode;
      let isValid = true;

      // Get fields required
      const listRequiredFields: any[] = [];
      const referSections = [...sections];

      sections
        .filter((s) => {
          const dependency = s.dependency?.[0];

          if (dependency) {
            const field = sections
              .find((s) => s.formSectionId === dependency?.SectionId)
              ?.fields?.find?.((f) => f.name === dependency?.Name);

            if (dependency.ExpectedValue !== field?.value) {
              return false;
            }
          }

          return true;
        })
        .forEach((s) => {
          if (
            !isInspectorForm ||
            (isInspectorForm &&
              (s.parentSectionId === +currentParentSectionId || isFinish))
          ) {
            const parentSection = sectionTabs.filter(
              (f) => f.formSectionId === s.parentSectionId,
            )[0];

            if (parentSection?.dependency) {
              const listDependencies = Array.from(parentSection?.dependency);
              const resultDependencies: boolean[] = [];

              listDependencies.forEach((fe: Dependency) => {
                switch (fe.Name) {
                  case 'thirdType': {
                    const objInspect = inspectionModel;
                    if (
                      objInspect !== undefined &&
                      objInspect?.thirds !== undefined &&
                      objInspect.thirds.length > 0
                    ) {
                      resultDependencies.push(
                        objInspect.thirds[0][fe.Name] === fe.ExpectedValue,
                      );
                    }
                    break;
                  }
                  default: {
                    const referFieldValue = referSections
                      .find((s) => s.formSectionId === fe.SectionId)
                      ?.fields.find((f) => f.name === fe.Name)?.value;

                    resultDependencies.push(
                      referFieldValue === fe.ExpectedValue,
                    );
                    break;
                  }
                }
              });

              parentSection.visible =
                listDependencies.length ===
                resultDependencies.filter((f) => f === true).length;
            }

            if (s.visible && parentSection?.visible) {
              const fields = s.fields.filter((f) => {
                const customDependency = customFieldDependency(f.name);
                const dependency = f.dependency;
                let validate = true;
                if (dependency) {
                  const field = sections
                    .find((s) => s.formSectionId === dependency?.sectionId)
                    ?.fields?.find?.((f) => f.name === dependency?.name);

                  if (dependency.expectedValue !== field?.value) {
                    validate = false;
                  }
                }

                if (customDependency && customDependency.validations) {
                  let existValidation = false;
                  let resultValidation = false;
                  Array.from(customDependency.validations).forEach(
                    (val: any) => {
                      const field = sections
                        .find((s) => s.formSectionId === f.formSectionId)
                        ?.fields?.find?.(
                          (f) =>
                            f.name === val.nameRef &&
                            f.value === val.expectedValue,
                        );

                      if (field) {
                        existValidation = true;
                        resultValidation = val.visible;

                        return;
                      }
                    },
                  );

                  if (existValidation) {
                    f.visible = resultValidation;
                  } else {
                    f.visible = true;
                  }
                }

                return validate && f.required && f.visible;
              });

              const referFields = [...fields];
              const fieldCheckDamage = s.fields.find(
                (field) => field.name === 'HasNotInsuredDamage',
              );

              if (modifiedField) {
                const field = s.fields.find(
                  (f) =>
                    f.required && f.visible && f.name === modifiedField.name,
                );
                if (field) {
                  validateField({
                    field,
                    referFields,
                    section: s,
                    listRequiredFields,
                  });
                }
              } else {
                for (const field of fields) {
                  if (
                    fieldCheckDamage?.value === '0' &&
                    field.name === 'EventInjury'
                  ) {
                    continue;
                  }
                  validateField({
                    field,
                    referFields,
                    section: s,
                    listRequiredFields,
                  });
                }
              }
            }
          }
        });

      const sectionsWithError = listRequiredFields
        .flatMap((f) => f.parentSectionId)
        .filter((f, index, self) => self.indexOf(f) === index);

      for (const s of sectionTabs) {
        s.hasError = sectionsWithError.includes(s.formSectionId);
      }

      // The list has data
      isValid =
        listRequiredFields.filter(
          (f) =>
            !isAuditProcess ||
            (isAuditProcess &&
              f.parentSectionId === inspectionState.auditEvent.auditSection),
        ).length === 0;

      setShowNotification(false);

      if (
        isValid &&
        (isFinish || isInspectorForm) &&
        String(currentCountry).toUpperCase() === CountryCode.PAN &&
        !isAuditProcess &&
        !readOnlyPermission
      ) {
        const sectionDriverLicenseBack =
          inspectionState.currentImages.filter(
            (photo) => photo.section === PhotoSection.DRIVER_LICENSE_BACK,
          ).length > 0;
        if (!sectionDriverLicenseBack) {
          isValid = false;
          alertMessage =
            '<div id="validation-message"><b>La imagen de Licencia de conducir (Trasera) es requerida</b></div>';
        }
      }
      const requiredLegalAssistance =
        (inspectionState.currentInspection?.LegalAssistanceRequired !==
        undefined
          ? inspectionState.currentInspection?.LegalAssistanceRequired
          : '0') === '1' ||
        (inspectionState.currentInspection?.RequireAssistance !== undefined
          ? inspectionState.currentInspection?.RequireAssistance
          : '0') === '1';
      if (
        requiredLegalAssistance === true &&
        String(currentCountry).toUpperCase() === CountryCode.PAN &&
        inspectionState.currentInspection?.CompanyId === BrandsId.FEDPA
      ) {
        const sectionLegalAssistance =
          inspectionState.currentImages.filter(
            (photo) => photo.section === PhotoSection.LEGAL_ASSISTANCE,
          ).length > 0;
        if (!sectionLegalAssistance) {
          isValid = false;
          alertMessage =
            '<div id="validation-message"><b>La imagen de Asistencia Legal es requerida</b></div>';
        }
      }

      if (
        isValid &&
        (isFinish || !isInspectorForm) &&
        !isAuditProcess &&
        !readOnlyPermission
      ) {
        // Validate Photos | PRI is not valid | Is not valid for Audit Process
        if (String(currentCountry).toUpperCase() !== CountryCode.PRI) {
          if (inspectionState.currentImages.length < 10) {
            isValid = false;
            alertMessage =
              '<div id="validation-message"><b>Esta inspección requiere al menos 10 fotos del incidente</b></div>';
          } else if (
            inspectionState.currentImages.filter(
              (f: EventPhoto) =>
                f.awsUrl === undefined ||
                f.awsUrl === null ||
                f.awsUrl?.length === 0,
            ).length > 0
          ) {
            isValid = false;
            alertIsError = false;
            alertMessage =
              '<div id="validation-message"><b>Se detectaron imágenes en proceso, favor esperar hasta que termine la sincronización.</b><br/><br/><b>Si el problema persiste revisar su conexión a internet.</b></div>';
          }
        }
      }

      if (
        isValid &&
        (isFinish || !isInspectorForm) &&
        !isAuditProcess &&
        !readOnlyPermission &&
        inspectionState?.currentInspection?.hasTrafficTicket === '1'
      ) {
        if (String(currentCountry).toUpperCase() === CountryCode.CRC) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.OTHERS,
            imageType: 'TRAFFIC_PHOTO',
            dataURL: '',
            order: 2,
          };

          if (
            inspectionState?.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            alertMessage =
              '<div id="validation-message"><b>La imagen del parte es requerida.</b></div>';
            isValid = false;
          }
        }
      }

      if (!isValid) {
        setSections(sections);
        setSectionTabs(sectionTabs);
        if (isInspectorForm) {
          setTypeMessage(alertIsError ? 'error' : 'warning');
          setAlertMessage(alertMessage);
          setShowNotification(!modifiedField);
        } else {
          dispatch({
            type: ActionType.SET_GENERAL_ALERT,
            payload: {
              showAlert: true,
              alertMessage: alertMessage,
              alertType: 'error',
            },
          });
        }
      }

      // Set the current form status
      dispatch({
        type: ActionType.SET_CURRENT_FORM_STATUS,
        payload: isValid,
      });

      return isValid;
    },
    [
      dispatch,
      sections,
      setSections,
      countryCode,
      sectionTabs,
      isInspectorForm,
      history.location.pathname,
      inspectionState,
      inspectionModel,
      isAuditProcess,
      readOnlyPermission,
    ],
  );

  const refreshValidation = useCallback(() => {
    if (activeRefreshvalidation) {
      const currentSection = sections.filter(
        (f: any) => f.parentSectionId === Number(sectionId),
      )[0];
      if (currentSection && currentSection.name === 'AE_EXPRESS') {
        const firstField = currentSection.fields.filter(
          (f: any) => f.name === 'ResponsableOption',
        )[0];
        if (firstField) {
          validateForm(firstField, false);
        }
      }

      setActiveRefreshvalidation(false);
    }
  }, [sections, sectionId, activeRefreshvalidation, validateForm]);

  const backStep = useCallback(
    (request: boolean) => {
      const orderTabs = filteredTabs.sort((a, b) => {
        return a.idOrder - b.idOrder;
      });
      const firstTab = orderTabs[0];
      const currentIndexTab = orderTabs.findIndex(
        (f) => f.formSectionId === +sectionId,
      );
      const isFirstTab = firstTab
        ? firstTab.formSectionId === +sectionId
        : false;

      setEnableBackStep(!isFirstTab);

      if (!isFirstTab && request) {
        const backStepTab = orderTabs[currentIndexTab - 1];
        history.push(`${formPath}/${id}/sections/${backStepTab.formSectionId}`);
        scrollToTop(isInspectorForm);
      }
    },
    [filteredTabs, formPath, history, id, isInspectorForm, sectionId],
  );

  const verifyRetriesProcess = (): void => {
    const modelStateName = id + '_model_autosave';
    const existModelForRetries = existsRetries(modelStateName);
    let errMsg = localStorage.getItem('error-data');
    errMsg = errMsg && errMsg !== 'undefined' ? errMsg : '';

    setHasAvailableRetries(existModelForRetries);
    setErrorMsgForRetries('');
    if (existModelForRetries) {
      setErrorMsgForRetries(errMsg);
    }

    (async (): Promise<void> => {
      if (
        !existModelForRetries &&
        errMsg?.length > 0 &&
        errMsg === 'restore-model'
      ) {
        localStorage.setItem('error-data', '');
        window.location.reload();
      }
      dispatch({ type: ActionType.SET_RETRIES_PROCESS_FLAG, payload: false });
    })();
  };

  useEffect(() => {
    if (dataFetched) {
      return;
    }

    (async () => {
      if (!Number.isInteger(userState.currentUserStatus.role) || !country) {
        return;
      }
      const inspection = await fetchInspection();

      if (inspection) {
        await fetchForm(inspection?.CompanyId, inspection?.IsVitualInspector);
        const isActived =
          inspection?.isActive === FormActiveType.ACTIVE ||
          inspection?.isActive === FormActiveType.REQUEST_ACTIVATION;
        setIsFormActivated(isActived);
        setDataFetched(true);
      }
    })();
  }, [
    fetchInspection,
    fetchForm,
    dataFetched,
    userState.currentUserStatus.role,
    country,
  ]);

  useEffect(() => {
    if (!formLoaded && formLayout && inspectionFetched) {
      setSectionTabs(
        formLayout
          .filter((section: FieldSection) => section.level === '1')
          .sort((a: any, b: any) => (a.idOrder > b.idOrder ? 1 : -1)),
      );

      setSections(
        parseState(
          formLayout.filter((section: any) => section.level === '2'),
          inspectionState,
          dispatch,
          isInspectorForm,
        ),
      );

      setFormLoaded(true);
    }
  }, [
    formLayout,
    inspectionFetched,
    inspectionState,
    formLoaded,
    dispatch,
    isInspectorForm,
  ]);

  useEffect(() => {
    if (formLoaded) {
      // Refresh validation
      refreshValidation();
    }
  }, [formLoaded, refreshValidation]);

  useEffect(() => {
    // route section redirect
    if (filteredTabs?.length && formLayout && !sectionId) {
      const firstSectionId = filteredTabs[0].formSectionId;
      if (!history.location.pathname.includes('/sections/')) {
        localStorage.setItem(
          'codeInspector',
          new URLSearchParams(search).get('codeinspector') ?? '',
        );

        history.replace(`${formPath}/${id}/sections/${firstSectionId}`);
      } else {
        history.push(`${formPath}/${id}/sections/${firstSectionId}`);
      }
    }
  }, [history, formLayout, sectionId, id, filteredTabs, formPath]);

  useEffect(() => {
    verifyRetriesProcess();
  }, [inspectionState.initializeRetriesProcess]);

  const triggerAutoSave = useCallback(() => {
    (async (): Promise<void> => {
      if (validateForm()) {
        const result = await saveInspection(
          sections,
          inspectionState,
          false,
          isInspectorForm,
          dispatch,
        );

        if (result.success && result.error === null) {
          verifyRetriesProcess();
        }
      }

      dispatch({ type: ActionType.SET_LOADING, payload: false });
    })();
  }, [sections, inspectionState, isInspectorForm, dispatch, validateForm]);

  const tabClick = (tab: any) => {
    const isAuditProcess =
      Number(localStorage.getItem('rolId')) === Rol.AUDIT && !isInspectorForm;
    const currentCountry = !countryCode
      ? inspectionModel?.CountryCode
      : countryCode;
    let enableTab = true;

    // Validate Country Zone
    switch (String(currentCountry).toUpperCase()) {
      case CountryCode.PRI:
        enableTab = true;
        break;
      default:
        break;
    }

    // Validate if is audit
    if (isAuditProcess && !isInspectorForm) {
      if (inspectionState.auditEvent.auditIsActive) {
        enableTab =
          tab.formSectionId === inspectionState.auditEvent.auditSection ||
          tab.formSectionId === 97 ||
          tab.formSectionId === 14; // Audit tab section OR Damage tab section OR Photos tab section
      } else {
        dispatch({
          type: ActionType.SET_AUDIT_SECTION,
          payload: tab.formSectionId,
        });

        // Restore the flag from all fields in the current section
        restoreFieldAuditFlag(tab.formSectionId);
      }

      //Validate the form before change event state
      validateForm();
    }

    // Execute only in desktop mode or all insurances from PRI country
    if (
      (!isInspectorForm && enableTab) ||
      (isInspectorForm && enableTab && isFormActivated)
    ) {
      (async (): Promise<void> => {
        if (!isAuditProcess && !isInspectorForm && !readOnlyPermission) {
          await triggerAutoSave();
        }
        history.push(`${formPath}/${id}/sections/${tab.formSectionId}`);
        scrollToTop(isInspectorForm);
      })();
    }
  };

  const getPolicyType = (): string | null => {
    switch (inspectionState?.currentInspection?.ProjectId) {
      case '1':
        return 'Tradicional';
      case '2':
        return 'Seguro Obligatorio';
      default:
        return null;
    }
  };

  const restoreFieldAuditFlag = (tabSectionId: number): void => {
    setSections(
      sections.map((s: FieldSection) => {
        if (s.parentSectionId === tabSectionId) {
          s.fields.forEach((field: Field) => {
            if (field.isModifyForAudit === true) {
              field.isModifyForAudit = false;
              field.originalValue =
                field.originalValue !== field.value &&
                String(field.value).length > 0
                  ? field.value
                  : field.originalValue;
            }
          });
        }

        return s;
      }),
    );
  };

  const showPolicyType = getPolicyType() !== null;

  const tab = filteredTabs.find((t) => t.formSectionId === Number(sectionId));
  const activeStep = {
    formSectionId: Number(sectionId),
    title: '',
    hasError: Boolean(tab?.hasError),
  };

  const getClassNameInfo = () => {
    switch (inspectionModel?.PaymentStatus) {
      case 'NOT Funded':
        if (inspectionModel?.InRangeDays === 'No') {
          return 'inspection__title-container__data__status-premier alert';
        } else if (inspectionModel?.InRangeDays === 'Si') {
          return 'inspection__title-container__data__status-premier success';
        } else {
          return 'inspection__title-container__data__status-premier';
        }
      default:
        return 'inspection__title-container__data__status-premier';
    }
  };

  const handleClaimTrackerChange = (event) => {
    setClaimTrackerSelected(event.target.value);
    if (event.target.value !== 'default') {
      history.replace(
        PATH_ROUTES.CLAIMS_TRACKER_MAIN.replace(':id', event.target.value),
      );
    }
  };

  const exportFileError = (): void => {
    const modelStateName = id + '_model_autosave';
    exportTxtFileError(id, modelStateName);
  };

  const handleSnackBarClearMessagePhoto = (): void => {
    setShowSnackbarPhoto(false);
    inspectDispatch({
      type: ActionType.SET_PHOTO_ALERT,
      payload: {
        showAlertPhoto: false,
        alertMessagePhoto: '',
        alertTypePhoto: '',
      },
    });
  };

  useEffect(() => {
    if (
      inspectContext.showAlertPhoto &&
      inspectContext.alertMessagePhoto &&
      inspectContext.alertTypePhoto &&
      String(inspectContext.alertMessagePhoto).length > 0
    ) {
      setShowSnackbarPhoto(true);
      setTypeMessagePhoto(inspectContext.alertTypePhoto as AlertColor);
      setAlertMessagePhoto(inspectContext.alertMessagePhoto);
    }
  }, [
    inspectContext.showAlertPhoto,
    inspectContext.alertMessagePhoto,
    inspectContext.alertTypePhoto,
  ]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (inspectContext.inProgress) {
        const message =
          'Estás intentando salir de la página mientras se están subiendo imágenes. ¿Estás seguro?';
        event.returnValue = message;

        return message;
      }
    };

    const handleVisibility = async () => {
      if (inspectContext.inProgress) {
        if (document.hidden && isInspectorForm) {
          await AddAuditLog(
            Number(inspectionModel?.EventId),
            0,
            'Usuario salió de la aplicación sin terminar proceso subida de imágenes',
          );
        }
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibility);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibility);
    };
  }, [inspectContext.inProgress]);

  useEffect(() => {
    if (showSnackbarPhoto && typeMessagePhoto === 'success') {
      const timer = setTimeout(() => {
        handleSnackBarClearMessagePhoto();
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [showSnackbarPhoto, typeMessagePhoto]);

  useEffect(() => {
    if (isInspectorForm) {
      const interval = setInterval(() => {
        useJiraWidget();
      }, 2000);

      return () => clearInterval(interval);
    } else {
      console.warn('Jira-Widget :: Not Inspector Form');
    }
  }, []);

  const handleCloseErrorModal = () => {
    dispatch({
      type: ActionType.SET_ERROR_MODAL,
      payload: {
        showErrorModalImages: false,
      },
    });
    window.location.reload();
  };

  return (
    <>
      <InactiveScreen show={!isFormActivated && isInspectorForm} />
      <Loader isActive={inspectionState.loading || !formLoaded} />
      {!isValidBrowser ? (
        <IncompatibleView />
      ) : (
        <div
          className={clsx(
            'inspection',
            isInspectorForm ? 'inspector-form' : '',
          )}
        >
          {!isInspectorForm && (
            <div className="inspection__title-container">
              <h1 className="inspection__title-container__title">
                {isDesktop
                  ? 'Forma de Inspección para Vehículo de Motor'
                  : 'Formulario de Inspección'}
              </h1>
              <h1
                className={clsx(
                  'inspection__title-container__data',
                  isDesktop && 'MuiTypography-alignRight',
                )}
              >
                {inspectionState.currentInspection?.EnableClaimsTracker ? (
                  <>
                    Expediente:&nbsp;
                    <span>{inspectionModel?.EventRecord}</span>
                    <br />
                    <FormControl>
                      <NativeSelect
                        title="Reclamaciones"
                        value={claimTrackerSelected}
                        onChange={handleClaimTrackerChange}
                      >
                        <option key="default" value="default">
                          Reclamaciones Disponibles
                        </option>
                        {inspectionState.claimsTrackerInfo?.map(
                          (claim: IClaim) => {
                            return (
                              <option
                                key={claim.claimUId}
                                value={claim.claimUId}
                              >
                                Reclamación: {claim.claimNumber}
                              </option>
                            );
                          },
                        )}
                      </NativeSelect>
                    </FormControl>
                  </>
                ) : (
                  <>
                    Expediente:&nbsp;
                    <span>{inspectionModel?.EventRecord}</span>
                  </>
                )}

                {showPolicyType && (
                  <>
                    <span>&nbsp;|</span> Tipo de Póliza:
                    <span> {getPolicyType()}</span>
                  </>
                )}
                {inspectionModel?.CompanyId === BrandsId.PREMIER &&
                  inspectionModel?.PaymentStatus &&
                  inspectionModel?.InRangeDays && (
                    <>
                      <span
                        className={clsx(
                          getClassNameInfo(),
                          !isDesktop && 'block',
                        )}
                      >
                        Estatus de póliza:
                        <span>{inspectionModel.PaymentStatus}</span>
                      </span>
                      {inspectionModel.PaymentStatus === 'NOT Funded' && (
                        <span
                          className={clsx(
                            getClassNameInfo(),
                            !isDesktop && 'block',
                          )}
                        >
                          ¿Dentro de los 90 días?:
                          <span>{inspectionModel.InRangeDays}</span>
                        </span>
                      )}
                    </>
                  )}
                {inspectionModel?.CompanyId !== null &&
                  inspectionModel?.CompanyId === BrandsId.QUALITAS && // QUALITAS ID
                  ((inspectionModel?.siseReport !== undefined &&
                    inspectionModel?.siseReport.length > 0) ||
                    (inspectionModel?.siseSiniestro !== undefined &&
                      inspectionModel?.siseSiniestro?.length > 0)) && (
                    <>
                      <br />
                      <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                        SISE Reporte:&nbsp;
                      </span>
                      <span style={{ fontSize: '16px' }}>
                        {inspectionModel?.siseReport}
                      </span>
                      <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                        &nbsp;//&nbsp;
                      </span>
                      <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                        SISE Siniestro:&nbsp;
                      </span>
                      <span style={{ fontSize: '16px' }}>
                        {inspectionModel?.siseSiniestro}
                      </span>
                    </>
                  )}
                {inspectionModel?.CompanyId !== null &&
                  inspectionModel?.CompanyId === 2 && // ASSA ID
                  inspectionModel?.siseReport !== undefined &&
                  inspectionModel?.siseReport.length > 0 && (
                    <>
                      <br />
                      <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                        Reclamo #:&nbsp;
                      </span>
                      <span style={{ fontSize: '16px' }}>
                        {inspectionModel?.siseReport}
                      </span>
                    </>
                  )}
              </h1>
            </div>
          )}
          {isInspectorForm && formLoaded && (
            <InspectorTopBar
              tabs={filteredTabs}
              activeSectionId={Number(sectionId)}
              onTabClick={tabClick}
              policyType={getPolicyType()}
            />
          )}
          {formLoaded && (
            <>
              {!isInspectorForm && (
                <FormSteps
                  steps={filteredTabs}
                  activeStep={activeStep}
                  onSelectedTab={tabClick}
                />
              )}

              <FormSection
                sections={sections}
                setSections={setSections}
                activeStep={Number(sectionId)}
                inspectionId={id}
                nextStep={nextStep}
                isFinished={isFinished}
                isInspectorForm={isInspectorForm}
                enableBackStep={enableBackStep}
                scrollToTop={scrollToTop}
                fetchInspection={fetchInspection}
                formValidation={validateForm}
                goToBackStep={backStep}
                restoreAuditFlag={restoreFieldAuditFlag}
              />
            </>
          )}

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={showNotification}
          >
            <Alert onClose={handleSnackBarClearMessage} severity={typeMessage}>
              <div dangerouslySetInnerHTML={{ __html: alertMessage }} />
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={showSnackbarPhoto}
            className="vh-side-alert"
          >
            {typeMessagePhoto !== 'success' ? (
              <Alert severity={typeMessagePhoto}>
                <div dangerouslySetInnerHTML={{ __html: alertMessagePhoto }} />
              </Alert>
            ) : (
              <Alert
                onClose={() => handleSnackBarClearMessagePhoto()}
                severity={typeMessagePhoto}
              >
                <div dangerouslySetInnerHTML={{ __html: alertMessagePhoto }} />
              </Alert>
            )}
          </Snackbar>
        </div>
      )}

      <Modal
        id="modalForRetries"
        open={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={{ width: '100%' }} spacing={2} className="alertError">
          <Alert severity="error" sx={{ mb: 2 }}>
            <AlertTitle>
              <strong>¡Hemos tenido un problema!</strong>
            </AlertTitle>
            <p>No hemos podido procesar la información.</p>
            <p>
              Por favor intente nuevamente dando clic al botón que dice{' '}
              <b>Reintentar Envío</b>.
            </p>
            <p>
              Si el problema persiste verificar tu <b>conexión a internet</b>, o
              en su defecto comunicarse con soporte técnico y compartir el
              siguiente archivo de texto [
              <a href={sectionId} onClick={exportFileError}>
                descargar&nbsp;
              </a>
              ].
            </p>
            {errorMsgForRetries && errorMsgForRetries.length > 0 && (
              <Accordion sx={{ backgroundColor: '#FDEDED' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Detalle</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <code>{errorMsgForRetries}</code>
                </AccordionDetails>
              </Accordion>
            )}
            <br />
            <Button
              key="btnRetrySave"
              name="btnRetrySave"
              variant="outlined"
              className={clsx('inspectionForm__toggle-button')}
              style={{ float: 'right' }}
              onClick={() => {
                dispatch({
                  type: ActionType.SET_LOADING,
                  payload: true,
                });
                triggerAutoSave();
              }}
            >
              Reintentar Envío
            </Button>
          </Alert>
        </Stack>
      </Modal>

      <Dialog
        open={inspectionState.showErrorModalImages}
        onClose={handleCloseErrorModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`¡Ups! Parece que hubo un problema al cargar el contenido debido a
            problemas de conexión a internet. Hemos logrado cargar ${inspectionState.countUploadImages} de las ${inspectionState.totalUploadImages}
            imágenes. Por favor, inténtalo de nuevo para cargar todas las
            imágenes correctamente.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseErrorModal} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

/**
 * Used to unmount and mount the component whenever
 * the event id is changed from within the same component
 * @returns JSX.Element
 */
const InspectionFormWrapper: React.FC = () => {
  const { id } = useParams() as any;
  const [renderChild, setRenderChild] = useState(false);
  const ref = useRef(); // used to safe previous value

  useEffect(() => {
    if (id !== ref.current) {
      setRenderChild(false);
      ref.current = id;
      setTimeout(() => setRenderChild(true), 10);
    } else {
      setRenderChild(true);
    }
  }, [id]);

  return renderChild ? <InspectionForm /> : <></>;
};

export default InspectionFormWrapper;
