import React from 'react';
import {
  CancelOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from '@mui/icons-material';
import { ReactComponent as FolderCheckedOutlined } from 'assets/icons/folder-checked-outlined.svg';
import { ReactComponent as OpenFolderOutlined } from 'assets/icons/open-folder-outlined.svg';

const EVENT_STATUSES = {
  CREATED: {
    id: 1,
    value: 'Creado',
    label: 'Creado',
    colorClass: 'blue',
    description: 'Servicio creado por proveedor',
    icon: <OpenFolderOutlined />,
  },
  COMPLETED: {
    id: 2,
    value: 'Completado',
    label: 'Completado',
    colorClass: 'orange',
    description: 'Servicio completado por proveedor',
    icon: <FolderCheckedOutlined />,
  },
  PROCESSED: {
    id: 3,
    value: 'Procesado',
    label: 'Procesado',
    colorClass: 'green',
    description: 'Servicio procesado por proveedor',
    icon: <CheckCircleOutlined />,
  },
  DELETED: {
    id: 7,
    value: 'Eliminado',
    label: 'Eliminado',
    colorClass: 'gray',
    description: 'Servicio borrado por proveedor',
    icon: <DeleteOutlined />,
  },
  CANCELED: {
    id: 5,
    value: 'Cancelado',
    label: 'Cancelado',
    colorClass: 'red',
    description: 'Servicio cancelado por proveedor',
    icon: <CancelOutlined />,
  },
  FINISHED: {
    id: 4,
    value: 'Terminado',
    label: 'Terminado',
    colorClass: 'gray',
    description: 'Servicio finalizado por proveedor',
    icon: <CancelOutlined />,
  },
  COMMENT: {
    id: 9,
    value: 'Comentario',
    label: 'Comentario',
    colorClass: 'gray',
    description: 'Comentario agregado al evento',
    icon: <FolderCheckedOutlined />,
  },
  EXPIRED: {
    id: -3,
    value: 'Expirado',
    label: 'Expirado',
    colorClass: 'red',
    description: 'Servicio expirado',
    icon: <OpenFolderOutlined />,
  },
  PENDING: {
    id: 10,
    value: 'Pendiente',
    label: 'Pendiente',
    colorClass: 'yellow',
    description: 'Servicio pendiente',
    icon: <OpenFolderOutlined />,
  },
  CLAIM_IN_PROCESS: {
    id: 20,
    value: 'Claim_In_Process',
    label: 'En Proceso',
    colorClass: 'yellow',
    description: 'Paso en Proceso',
    icon: <OpenFolderOutlined />,
  },
  CLAIM_COMPLETE: {
    id: 21,
    value: 'Claim_Complete',
    label: 'Completado',
    colorClass: 'green',
    description: 'Paso Completado',
    icon: <OpenFolderOutlined />,
  },
  CLAIM_PENDING: {
    id: 22,
    value: 'Claim_Pending',
    label: 'Pendiente',
    colorClass: 'gray',
    description: 'Paso Pendiente',
    icon: <OpenFolderOutlined />,
  },
  CLAIM_REQUIRE_INFO: {
    id: 23,
    value: 'Claim_Require_Info',
    label: 'Requiere Información',
    colorClass: 'blue',
    description: 'Paso con Requerimiento',
    icon: <OpenFolderOutlined />,
  },
  CLAIM_REQUEST_COMPLETE: {
    id: 24,
    value: 'Claim_Request_Complete',
    label: 'Completa',
    colorClass: 'green',
    description: 'Solicitud Completa',
    icon: <OpenFolderOutlined />,
  },
  CLAIM_REQUEST_PENDING: {
    id: 25,
    value: 'Claim_Request_Pending',
    label: 'Pendiente',
    colorClass: 'yellow',
    description: 'Solicitud Pendiente',
    icon: <OpenFolderOutlined />,
  },
};

export { EVENT_STATUSES };
