import { Button } from '@mui/material';
import { globalCss } from 'assets/connect/styles/_variables';
import clsx from 'clsx';
import Avatar from 'components/common/avatar/Avatar';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import React, { forwardRef, useState } from 'react';
import { EventReminder } from 'types/event-reminder';
import './styles.scss';

interface Props {
  isInspectorForm: boolean;
  note: EventReminder;
  notes: EventReminder[];
  toggleDeleted: () => void;
}

const ReminderRow = React.memo(
  forwardRef<HTMLDivElement, Props>(
    (props: Props, ref): JSX.Element => {
      const [isOpen, setIsOpen] = useState(false);

      const iconStyle = { fill: `${globalCss.boulder}` };

      const handleToggleRow = () => {
        setIsOpen(!isOpen);
      };

      // Used to give some color to the avatar bubble
      const getUserIndex = (userId: number): number => {
        return props.notes
          .flatMap((n) => n.reminderUserId)
          .filter((n, i, self) => self.indexOf(n) === i)
          .findIndex((n) => n === userId);
      };

      const disabled =
        new Date(props.note.reminderDate) < new Date() ? true : false;

      return (
        <div
          ref={ref as React.RefObject<HTMLDivElement>}
          className="note"
          onClick={handleToggleRow}
        >
          <div className="note__header">
            <div className="note__header__info">
              <Avatar
                size="small"
                name={props.note.reminderUserName}
                userIndex={getUserIndex(props.note.reminderUserId)}
              />
              <div className="note__user-info">
                <p className="note__infoText note__infoText--bold">
                  {props.note.reminderUserName}
                </p>
                <p className="note__infoText note__infoText--light">
                  {format(
                    new Date(props.note.reminderDate),
                    'dd/MM/yyyy HH:mm:ss',
                    {
                      locale: esLocale,
                    },
                  )}
                </p>
              </div>
            </div>
            {!props.isInspectorForm && (
              <Button
                disabled={disabled}
                variant="contained"
                color="secondary"
                disableElevation
                onClick={props.toggleDeleted}
              >
                Eliminar
              </Button>
            )}
          </div>
          <div className="note__header__info note__header__info--spaceItems">
            <p
              className={clsx('note__message', {
                'note__message--open': isOpen,
              })}
            >
              {props.note.commentText}
            </p>
          </div>
        </div>
      );
    },
  ),
);

export default ReminderRow;
