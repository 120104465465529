import React, { FunctionComponent } from 'react';
import './index.scss';
import Button from '@mui/material/Button';
import clsx from 'clsx';

interface propsButton {
  id?: string;
  disabled?: boolean;
  text: string;
  type?: any;
  variant?: 'text' | 'outlined' | 'contained';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  onClick?: (event?: any) => void;
  style?: React.CSSProperties;
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

const ClaimButton: FunctionComponent<propsButton> = ({
  id,
  disabled,
  text,
  type,
  variant,
  color,
  onClick,
  style,
  size,
  className,
}): JSX.Element => {
  return (
    <>
      <Button
        id={id}
        className={clsx(
          'claim-button',
          {
            'claim-button__outline': variant && variant === 'outlined',
          },
          className,
        )}
        disabled={disabled}
        onClick={onClick}
        variant={variant ?? 'contained'}
        color={color ?? 'primary'}
        type={type}
        style={style}
        size={size}
      >
        {text}
      </Button>
    </>
  );
};

export default ClaimButton;
