import React, { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import './index.scss';
import AlertIcon from '../../../assets/icons/alert.svg';
import AlertIconTooltipError from '../../../assets/icons/alert-tooltip.svg';
import AlertIconTooltipWarning from '../../../assets/icons/alert-tooltip-warning.svg';

enum AlertsTypes {
  WARNING_ALERT = 1,
  ERROR_ALERT = 2,
}

interface PropsAlert {
  alertType: number;
  alertMessage: string;
}

const Alert: FunctionComponent<PropsAlert> = ({
  alertType,
  alertMessage,
}): JSX.Element => {
  const getAlertIcon = () => {
    switch (alertType) {
      case AlertsTypes.WARNING_ALERT:
        return AlertIconTooltipWarning;
      case AlertsTypes.ERROR_ALERT:
        return AlertIconTooltipError;
      default:
        '';
    }
  };

  return (
    <Box className="tooltip">
      <Box className="alert">
        <Box className={`alert__${alertType}`}>
          <Box className="alert__icon">
            <img src={AlertIcon} alt="icon"></img>
          </Box>
        </Box>
      </Box>

      <Box className="tooltiptext">
        <img src={getAlertIcon()} alt="icon"></img>
        <span>{alertMessage}</span>
      </Box>
    </Box>
  );
};

export default Alert;
