import React from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';
import { EventPhoto } from 'types/images';

export interface DraggableBoxProps {
  color?: string;
  item: EventPhoto;
  onToggleForbidDrag?: () => void;
}

export const DraggableBox: React.FC<DraggableBoxProps> = ({
  item,
  children,
}) => {
  const drag = useDrag({
    item: { ...(item as any), type: 'photo' } || {},
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })[1];

  return <div ref={drag}>{children}</div>;
};
