import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  Alert,
} from '@mui/material';
import style from 'override-mui-styles';
import { Event, ReportProblemOutlined, Room, Info } from '@mui/icons-material';
import { formatRelative } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { useParams } from 'react-router-dom';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import clsx from 'clsx';
import InfoTable from 'components/common/info-table';
import { TabPanel } from 'components/common/tabpanel/TabPanel';
import {
  IPreInspectionContext,
  PreInspectionContext,
  getPreinspectionDamages,
  requestAnalysisDamages,
} from 'context/preinspections';
import { IDamage, IDamageAutodetectionZone } from 'types/pre-inspection';
import noImage from 'assets/images/no-image.png';
import Gallery from 'components/inspection/photos/section/Gallery';
import { EventPhoto } from 'types/images';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import './styles.scss';
import { TransitionStatesIds } from 'types/common';
import Loader from 'components/common/loader/Loader';
import { range } from 'lodash';
import Box from '@mui/system/Box';

const DamageAutodetection: FC = () => {
  const classes = style();
  const { id } = useParams<any>();
  const { dispatch } = useContext<IInspectionContext>(InspectionContext);
  const { state } = useContext<IPreInspectionContext>(PreInspectionContext);
  const currentPreInspect = state.currentPreInspection;

  const [tabValue, setTabValue] = useState<number>(0);
  const [damageZones, setDamageZones] = useState<IDamageAutodetectionZone>({});
  const [openGallery, setOpenGallery] = useState<boolean>(false);
  const [galleryData, setGalleryData] = useState<EventPhoto[]>([]);
  const [imageToZoom, setImageToZoom] = useState<string | null>(null);
  const [showConfirmRequest, setShowConfirmRequest] = useState<boolean>(false);
  const [showInfoRequest, setShowInfoRequest] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(true);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: String(process.env.REACT_APP_GMAPS_KEY),
  });

  const fetchDamageZones = useCallback(async () => {
    const data = await getPreinspectionDamages(+id, dispatch);
    if (data?.length > 0) {
      // Create object to group the data for name
      const groupedData = {};

      // Keywords to group the data
      const keywords = [
        'FRONTAL',
        'LATERAL CONDUCTOR',
        'LATERAL PASAJERO',
        'TRASERO',
      ];

      data.forEach((item) => {
        const name = item.name;

        // Verify if the name contains the keyword
        const groupKey = keywords.find(
          (keyword) =>
            ((keyword === 'FRONTAL' || keyword === 'TRASERO') &&
              keyword === name) ||
            (keyword !== 'FRONTAL' &&
              keyword !== 'TRASERO' &&
              name.includes(keyword)),
        );

        if (groupKey) {
          if (!groupedData[groupKey]) {
            groupedData[groupKey] = [];
          }
          // Add the item to the group
          groupedData[groupKey].push(item);
        }
      });

      setDamageZones(groupedData);
    }

    setShowLoader(false);
  }, [id, dispatch]);

  const sendAnalysisRequest = async (): Promise<void> => {
    if (currentPreInspect?.preUniqueId) {
      await requestAnalysisDamages(currentPreInspect?.preUniqueId, dispatch);
      setShowInfoRequest(true);
      setShowConfirmRequest(false);
    }
  };

  useEffect(() => {
    // Get damage zones
    fetchDamageZones();
  }, [fetchDamageZones]);

  const tabProps = (index: number) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  };

  const getIconElement = (status: string): JSX.Element => {
    const parsedStatus = status ? status.toUpperCase() : '';
    switch (parsedStatus) {
      case 'WARN':
        return <ReportProblemOutlined className="warn" />;
      case 'INFO':
        return <Info className="info" />;

      default:
        return <></>;
    }
  };

  const headers = (
    <>
      <TableCell></TableCell>
      <TableCell align="center">Zona</TableCell>
      <TableCell align="center">Descripción</TableCell>
      <TableCell align="center">Nivel de Daño</TableCell>
    </>
  );

  const rows = (observations: IDamage[]) => {
    const filterZones = observations.filter(
      (o: IDamage) => o.zone.toLowerCase() !== 'clean',
    );

    return (
      <>
        {filterZones.length === 0 && (
          <TableRow key="tr-0">
            <TableCell align="center">{getIconElement('INFO')}</TableCell>
            <TableCell align="center">
              <b>No se encontraron daños en esta zona</b>
            </TableCell>
            <TableCell align="center">---</TableCell>
            <TableCell align="center">---</TableCell>
          </TableRow>
        )}

        {filterZones.map((observation, index: number) => (
          <TableRow key={`tr-${index}`}>
            <TableCell align="center">
              {getIconElement(observation.status)}
            </TableCell>
            <TableCell align="center">{observation.zone}</TableCell>
            <TableCell align="center">{observation.description}</TableCell>
            <TableCell align="center">{observation.grade}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const handleClickImage = (e: any, damageZoneId: number) => {
    if (e.detail === 2) {
      setImageToZoom(e.target.src);
      setGalleryByZone(damageZoneId);
      setOpenGallery(true);
    }
  };

  const setGalleryByZone = (damageIdToFind: number): void => {
    // Recorre todos los grupos
    Object.keys(damageZones).forEach((groupName) => {
      // eslint-disable-next-line no-prototype-builtins
      if (damageZones.hasOwnProperty(groupName)) {
        const group = damageZones[groupName];

        // Busca el elemento por damageId en el grupo actual
        const foundElement = group.find(
          (element) => element.damageId === damageIdToFind,
        );

        if (foundElement) {
          const galleryImages: EventPhoto[] = [];
          group.forEach((element) => {
            galleryImages.push({
              dataURL: element.uploadPhoto || noImage,
              awsUrl: element.uploadPhoto || noImage,
              section: 1,
            });
            galleryImages.push({
              dataURL: element.analyzedPhoto || noImage,
              awsUrl: element.analyzedPhoto || noImage,
              section: 1,
            });
          });

          setGalleryData(galleryImages);
        }
      }
    });
  };

  return (
    <div className="damage-autodetection">
      <Loader isActive={showLoader} />
      {openGallery && imageToZoom && (
        <Gallery
          imageToShow={imageToZoom}
          images={galleryData}
          isOpen={openGallery}
          onClose={() => setOpenGallery(false)}
        />
      )}

      {!showLoader &&
      Object.keys(damageZones).length === 0 &&
      currentPreInspect?.preStatusId &&
      (currentPreInspect?.preStatusId <= TransitionStatesIds.COMPLETED ||
        currentPreInspect?.preStatusId === TransitionStatesIds.PENDING) ? (
        <>
          {showInfoRequest && (
            <Alert severity="info">
              <b>Solicitud enviada satisfactoriamente.</b>
              <br />
              <br />
              <i>
                <b>Nota:</b> El análisis estará listo en aproximadamente 5
                minutos, si después de este tiempo no hay análisis por favor
                reintente nuevamente.
              </i>
            </Alert>
          )}

          {!showInfoRequest && (
            <Alert severity="warning">
              No se han detectado daños en la inspección. Puede solicitar el
              análisis desde el botón <b>&quot;Solicitar Análisis&quot;</b>.
              <br />
              <br />
              <i>
                <b>Nota:</b> Si recien ha solicitado el análisis por favor
                espere el tiempo recomendado.
              </i>
            </Alert>
          )}

          <div className="pre-inspection__request-analysis-content">
            <Button
              id="request-btn"
              variant="contained"
              className={clsx(classes.button, 'inspectionForm__button')}
              size="large"
              onClick={() => setShowConfirmRequest(true)}
              disabled={showInfoRequest}
            >
              Solicitar Análisis
            </Button>
          </div>
        </>
      ) : (
        <>
          {!showLoader && Object.keys(damageZones).length === 0 && (
            <Alert severity="info">
              <b>No se han procesado los daños en la inspección.</b>
              <br />
              <br />
              <i>
                Por favor esperar a que el proceso de recolección de información
                sea completado.
              </i>
            </Alert>
          )}
        </>
      )}

      {!showLoader && Object.keys(damageZones).length > 0 && (
        <>
          <Tabs
            scrollButtons="auto"
            variant="scrollable"
            textColor="primary"
            indicatorColor="primary"
            value={tabValue}
            onChange={(event: React.SyntheticEvent, newValue: number) => {
              setTabValue(newValue);
            }}
          >
            {Object.keys(damageZones).map((zoneName, index: number) => (
              <Tab key={`tab-${index}`} label={zoneName} {...tabProps(index)} />
            ))}
          </Tabs>
          {Object.keys(damageZones).map((zoneData, index: number) => (
            <TabPanel key={`tab-panel-${index}`} value={tabValue} index={index}>
              <Grid
                id={`damage-container-${index}`}
                key={`damage-container-${index}`}
                container
                spacing={2}
              >
                <Grid
                  id={`damage-images-${index}`}
                  key={`damage-images-${index}`}
                  container
                  justifyContent="center"
                  className="damage-autodetection__images"
                  item
                  sx={{ minHeight: 200 }}
                  xs={6}
                  lg={6}
                >
                  <Paper
                    elevation={2}
                    className="pp-container pp-container__total"
                  >
                    <p className="pp-header">Calificación Total</p>
                    <p className="pp-body">
                      {damageZones[zoneData][0].totalGrade}
                      <label>%</label>
                    </p>
                  </Paper>
                  <Paper
                    elevation={2}
                    className="pp-container pp-container__zone"
                  >
                    <p className="pp-header">Calificación Zona</p>
                    <p className="pp-body">
                      {damageZones[zoneData][0].grade}
                      <label>%</label>
                    </p>
                  </Paper>
                </Grid>
                <Grid
                  id={`damage-map-${index}`}
                  key={`damage-map-${index}`}
                  item
                  xs={6}
                  lg={6}
                  className="damage-autodetection__map"
                >
                  {isLoaded && (
                    <Grid
                      id={`damage-map-container-${index}`}
                      key={`damage-map-container-${index}`}
                      container
                      alignItems="center"
                      className="gmap-container"
                    >
                      <GoogleMap
                        zoom={19}
                        center={{
                          lat: damageZones[zoneData][0].latitude,
                          lng: damageZones[zoneData][0].longitude,
                        }}
                        options={{
                          fullscreenControl: false,
                          disableDefaultUI: true,
                          clickableIcons: false,
                          gestureHandling: 'auto',
                        }}
                        mapContainerStyle={{
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <Marker
                          position={{
                            lat: damageZones[zoneData][0].latitude,
                            lng: damageZones[zoneData][0].longitude,
                          }}
                        />
                      </GoogleMap>
                    </Grid>
                  )}
                  <Grid
                    id={`damage-map-data-${index}`}
                    key={`damage-map-data-${index}`}
                    container
                  >
                    <Grid container item xs lg={9} alignItems="center">
                      <Room /> {damageZones[zoneData][0].location}{' '}
                      <Divider orientation="vertical" flexItem />
                      {`${damageZones[zoneData][0].latitude}, ${damageZones[zoneData][0].longitude}`}
                    </Grid>
                    <Grid item xs={4} lg={3} sx={{ textAlign: 'right' }}>
                      <a
                        className="maps-button"
                        href={`https://www.google.com/maps/search/?api=1&query=${damageZones[zoneData][0].latitude},${damageZones[zoneData][0].longitude}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ver en Google Maps
                      </a>
                    </Grid>
                  </Grid>
                  <Grid
                    id={`damage-map-btn-${index}`}
                    key={`damage-map-btn-${index}`}
                    container
                    alignItems="center"
                  >
                    <Event />{' '}
                    {formatRelative(
                      new Date(damageZones[zoneData][0].date),
                      new Date(),
                      {
                        locale: esLocale,
                      },
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {damageZones[zoneData].map((damageData, key: number) => (
                <Grid
                  id={`damage-container-${key}`}
                  key={`damage-container-${key}`}
                  className="damage-autodetection__detail"
                  container
                  spacing={2}
                >
                  <Grid
                    id={`damage-images-${key}`}
                    key={`damage-images-${key}`}
                    container
                    justifyContent="center"
                    className="damage-autodetection__images"
                    item
                    xs={12}
                    lg={6}
                  >
                    <div className="image">
                      <small className="image__title">Imagen cargada</small>
                      <img
                        onClick={(e) =>
                          handleClickImage(e, damageData.damageId)
                        }
                        onKeyDown={(e) =>
                          handleClickImage(e, damageData.damageId)
                        }
                        src={damageData.uploadPhoto || noImage}
                        alt="Imagen cargada"
                      />
                    </div>
                    <div className="image">
                      <small className="image__title">Imagen analizada</small>
                      <img
                        onClick={(e) =>
                          handleClickImage(e, damageData.damageId)
                        }
                        onKeyDown={(e) =>
                          handleClickImage(e, damageData.damageId)
                        }
                        src={damageData.analyzedPhoto || noImage}
                        alt="Imagen analizada"
                      />
                    </div>
                  </Grid>
                  <Grid
                    id={`damage-info-${key}`}
                    key={`damage-info-${key}`}
                    item
                    xs={12}
                    lg={6}
                    className="damage-autodetection__info"
                  >
                    <InfoTable
                      key={`damage-table-${key}`}
                      size="small"
                      headers={headers}
                      rows={rows(damageData.observations)}
                    ></InfoTable>
                  </Grid>
                </Grid>
              ))}
            </TabPanel>
          ))}
        </>
      )}

      <Dialog
        id="modal-sic-analysis-request"
        open={showConfirmRequest}
        onClose={() => setShowConfirmRequest(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Solicitud de Análisis de Daños
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Deseas solicitar el análisis de daños?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmRequest(false)} size="small">
            Cancelar
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={sendAnalysisRequest}
          >
            Sí, solicitar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DamageAutodetection;
