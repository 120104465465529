import React, { useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SvgIcon from '@mui/material/SvgIcon';
import clsx from 'clsx';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import SICLogoCar from 'assets/images/Logo-car.svg';
import styles from './override-mui-styles';
import { Tab } from 'types/tab';
import { CmtCountries, CountryCode } from 'types/common';

interface Props {
  tab: Tab;
  activeTab: Tab | null | undefined;
  isOpen: boolean;
  children?: JSX.Element;
  onTabClicked(tab: Tab): any;
  countryCode?: string;
}

const MenuTab = (props: Props): JSX.Element => {
  const classes = styles();
  const [showChildren, setShowChildren] = useState(false);

  const handleOpenTab = () => {
    setShowChildren(!showChildren);
  };

  const isActiveTabAChild = (): boolean => {
    if (props.tab === props.activeTab) {
      return true;
    }
    if (props.tab.subtabs) {
      const isChildActive = props.tab.subtabs.find(
        (subtab) => subtab === props.activeTab,
      );

      return isChildActive ? true : false;
    }

    return false;
  };

  return (
    <div>
      {props.tab.value === 'activity' &&
      CmtCountries.includes(props.countryCode as CountryCode) ? (
        <div
          className="sub-section"
          onClick={() => {
            props.onTabClicked(props.tab);
          }}
        >
          <img src={SICLogoCar} className="drawer__sicLogoCar" alt="SICCard" />
        </div>
      ) : (
        <ListItem
          button
          key={props.tab.value}
          className={clsx(classes.tab, {
            [classes.tabActive]: isActiveTabAChild(),
          })}
          onClick={() => {
            props.tab.subtabs ? handleOpenTab() : props.onTabClicked(props.tab);
          }}
        >
          <div className={classes.tabContainer}>
            <ListItemIcon
              className={clsx(classes.tabIcon, {
                [classes.tabIconActive]: isActiveTabAChild(),
              })}
            >
              <SvgIcon>{props.tab.icon}</SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={props.tab.label}
              className={clsx(classes.tabText, {
                [classes.tabTextHide]: !props.isOpen,
              })}
              classes={{ primary: classes.span }}
            />
            {props.tab.subtabs &&
              (showChildren ? <ExpandLess /> : <ExpandMore />)}
          </div>
        </ListItem>
      )}
      <div
        className={clsx(
          { [classes.childrenContainer]: showChildren },
          { [classes.childrenContainerHidden]: !showChildren },
        )}
      >
        <List>{props.children}</List>
      </div>
    </div>
  );
};

export default MenuTab;
