import {
  downloadReport,
  downloadReportClaim,
  InspectionContext,
} from 'context/inspections';
import { Grid, Button } from '@mui/material';
import DatePicker from 'components/common/datepicker/DatePicker';
import Loader from 'components/common/loader/Loader';
import React, { useContext, useState } from 'react';
import { format } from 'date-fns';
import style from 'override-mui-styles';
import clsx from 'clsx';
import './styles.scss';
import { validateDateRange } from 'shared/utils';
import { ActionType } from 'types/action';

interface EventReportProps {
  report: string;
}

export default function EventReport(props: EventReportProps): JSX.Element {
  const { report } = props;
  const [downloadURL, setDownloadURL] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = style();
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(format(new Date(), 'MM/dd/yyyy')),
  );
  const [endDate, setEndDate] = useState<Date | null>(
    new Date(format(new Date(), 'MM/dd/yyyy')),
  );

  //Context
  const { dispatch } = useContext(InspectionContext);

  const onChangeStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const onChangeEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const generateReport = () => {
    if (startDate && endDate) {
      const adminCompanies = [3, 13, 14]; // Connect CRC, PA, PRI
      const companyId = localStorage.getItem('companyId') ?? '-1';
      const roleId = localStorage.getItem('rolId') ?? '1';

      setDownloadURL('');
      (async (): Promise<void> => {
        setLoading(true);
        if (validateDateRange(startDate, endDate)) {
          let result;
          const request = {
            countryCode: localStorage.getItem('countryCode'),
            startDate: format(startDate, 'yyyy-MM-dd'),
            endDate: format(endDate, 'yyyy-MM-dd'),
            inicio: format(startDate, 'yyyy-MM-dd') + ' 00:00:00',
            fin: format(endDate, 'yyyy-MM-dd') + ' 23:59:59',
            companyId: adminCompanies.includes(Number(companyId))
              ? -1
              : Number(companyId),
            rolId: Number(roleId),
            customerCode: localStorage.getItem('customerCode'),
          };
          switch (report) {
            case 'claims': {
              result = await downloadReportClaim(request, dispatch);
              break;
            }
            case 'event': {
              result = await downloadReport(request, dispatch);
              break;
            }
          }

          if (result === 'Fail') {
            dispatch({
              type: ActionType.SET_GENERAL_ALERT,
              payload: {
                showAlert: true,
                alertMessage:
                  'No se encontraron resultados en el rango seleccionado.',
                alertType: 'warning',
              },
            });
          } else {
            setDownloadURL(result);
          }
        } else {
          dispatch({
            type: ActionType.SET_GENERAL_ALERT,
            payload: {
              showAlert: true,
              alertMessage:
                'Por favor seleccione un rango de fechas <b>menor o igual a 1 mes</b>.',
              alertType: 'warning',
            },
          });
        }

        setTimeout(() => setLoading(false), 3000);
      })();
    }
  };

  const downloadFile = () => {
    setLoading(true);
    saveAs(downloadURL, 'reporteSIC');
    setTimeout(() => setLoading(false), 3000);
  };

  return (
    <>
      <Loader isActive={loading} />
      <div className="reportForm">
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sm={5} md={3} lg={3}>
            <label className="reportForm__label">Fecha Desde:</label>
            <DatePicker
              startDate={startDate}
              onChangeStartDate={(date) => onChangeStartDate(date)}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={3} lg={3}>
            <label className="reportForm__label">Fecha Hasta:</label>
            <DatePicker
              startDate={endDate}
              onChangeStartDate={(date) => onChangeEndDate(date)}
            />
          </Grid>
          <Grid className="generateSection" item xs={12} sm={1} md={3} lg={4}>
            <Button
              id="continue-btn"
              variant="contained"
              className={clsx(classes.button)}
              size="large"
              onClick={() => generateReport()}
            >
              Generar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={1} md={3} lg={4}></Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <div className="downloadSection">
                  {downloadURL?.length > 0 && (
                    <Button
                      id="continue-btn"
                      variant="contained"
                      className={clsx(classes.button)}
                      size="large"
                      onClick={() => downloadFile()}
                    >
                      Descargar Reporte
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={1} md={3} lg={4}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
