import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import clsx from 'clsx';
import Avatar from 'components/common/avatar/Avatar';
import { IUserContext, UserContext } from 'context/user';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import ButtonClaims from '../../components/claims/button';
import styles from '../../components/claims/table/override-mui-styles';
import { ApprovalDashboard, ApprovalResponse } from './approval';
import './styles.scss';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { ActionType } from 'types/action';

export default function Approval(): JSX.Element {
  const {
    state: { currentUser },
    dispatch: userDispatch,
  } = useContext(UserContext) as IUserContext;
  const [approvals, setApprovals] = useState<ApprovalDashboard[]>([]);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [approvalResponse, setAprovalResponse] = useState<ApprovalResponse>();
  const { dispatch } = useContext<IInspectionContext>(InspectionContext);
  const [rejectReason, setRejectReason] = useState<string>('');
  const classes = styles();
  const history = useHistory();

  useEffect(() => {
    getApprovalsByUser(currentUser.userName ?? '');
  }, []);

  const getApprovalsByUser = async (user: string): Promise<any> => {
    try {
      const result = await new ClaimsTrackerClient().getApprovalDashboardByUser(
        user,
      );

      setApprovals(result);

      return result;
    } catch (e) {
      console.error(e);
    }
  };

  const handleApprovation = async (): Promise<any> => {
    try {
      setOpenConfirmation(false);

      dispatch({ type: ActionType.SET_LOADING, payload: true });

      if (approvalResponse) {
        const result = await new ClaimsTrackerClient().respondApprovalRequest(
          approvalResponse.approvalStepId,
          approvalResponse.IsApproved,
          rejectReason,
        );

        getApprovalsByUser(currentUser.userName ?? '');
        setRejectReason('');
        dispatch({ type: ActionType.SET_LOADING, payload: false });

        return result;
      }

      dispatch({ type: ActionType.SET_LOADING, payload: false });
    } catch (e) {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
      console.error(e);
    }
  };

  function formatearFecha(fechaString) {
    const fecha = new Date(fechaString);

    const year = fecha.getFullYear();
    const month = String(fecha.getMonth() + 1).padStart(2, '0');
    const day = String(fecha.getDate()).padStart(2, '0');
    let hours = fecha.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convertir las horas al formato de 12 horas
    hours = hours % 12 || 12;

    const minutes = String(fecha.getMinutes()).padStart(2, '0');
    const seconds = String(fecha.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;

    return formattedDate;
  }

  const USERS = approvals
    .map((e) => e.assignedUserId)
    .filter((u) => u !== null)
    .filter((u, i, a) => a.indexOf(u) === i);

  const rowClick = (claimUId?: string) => {
    history.push(`/claims/${claimUId}`);
  };

  return (
    <div className="approval">
      <span className="approval-title">LISTA DE CASOS</span>
      <br></br>
      <span className="approval-subtitle">
        A continuación la lista de casos que solicitan aprobación de ajuste para
        el usuario: {currentUser.userName}
      </span>

      <Paper className="approval-table">
        <TableContainer sx={{ maxHeight: 780 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  className="tableHeader"
                  style={{ visibility: 'hidden' }}
                >
                  Id
                </TableCell>
                <TableCell className="tableHeader" style={{ minWidth: 100 }}>
                  Caso
                </TableCell>
                <TableCell className="tableHeader">Fecha Reclamación</TableCell>
                <TableCell className="tableHeader">
                  Fecha Solicitud de aprobación
                </TableCell>
                <TableCell className="tableHeader">Días abierto</TableCell>
                <TableCell className="tableHeader">Compañía</TableCell>
                <TableCell className="tableHeader">Poliza</TableCell>
                <TableCell className="tableHeader">Valor Ajuste</TableCell>
                <TableCell className="tableHeader">Agente</TableCell>
                <TableCell className="tableHeader">Aprobar</TableCell>
                <TableCell className="tableHeader">Rechazar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {approvals.slice().map((row) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className="tablerow"
                  onClick={(e) => rowClick(row.uId)}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ visibility: 'hidden' }}
                  >
                    {row.id}
                  </TableCell>
                  <TableCell>{row.claimNumber}</TableCell>
                  <TableCell>{formatearFecha(row.claimDate)}</TableCell>
                  <TableCell>{formatearFecha(row.requestDate)}</TableCell>
                  <TableCell>{row.daysOpened}</TableCell>
                  <TableCell>{row.companyName}</TableCell>
                  <TableCell>{row.policy}</TableCell>
                  <TableCell>{row.paymentAmount}</TableCell>
                  <TableCell
                    align="center"
                    className={clsx(classes.cell, classes.smallCell)}
                  >
                    <Box className="user-agent">
                      <Avatar
                        size="small"
                        name={row.assignedUserName}
                        userIndex={USERS.findIndex(
                          (u) => u === row.assignedUserId,
                        )}
                      />
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setAprovalResponse({
                          approvalStepId: row.id,
                          IsApproved: true,
                        });
                        setOpenConfirmation(true);
                      }}
                    >
                      Aprobar
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setAprovalResponse({
                          approvalStepId: row.id,
                          IsApproved: false,
                        });
                        setOpenConfirmation(true);
                      }}
                    >
                      Rechazar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog open={openConfirmation}>
        <DialogTitle id="confirm-restore-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-payment-modal"
            style={{ width: '400px', height: '120px' }}
          >
            <br />
            ¿Quiere {approvalResponse?.IsApproved ? 'aprobar' : 'rechazar'} el
            valor de ajuste del caso?
            <br />
            {!approvalResponse?.IsApproved ? (
              <TextField
                style={{ marginTop: '20px', width: '100%' }}
                name="customerResponsabilityAmount"
                className="claim-adjust__data--row--input"
                label="Describa la razón"
                onChange={(e) => {
                  setRejectReason(e.target.value);
                }}
              ></TextField>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonClaims
            text="Cancelar"
            variant="outlined"
            color="secondary"
            onClick={() => setOpenConfirmation(false)}
          />
          <ButtonClaims
            text="Sí, confirmar"
            variant="contained"
            color="primary"
            onClick={handleApprovation}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
