import React, { useState, useEffect, useContext } from 'react';
import { ReactMic } from 'react-mic';
import Modal from '@mui/material/Modal';
import {
  IInspectionContext,
  InspectionContext,
  presignedAudioUrl,
  transcriptAudioUrl,
  updateTranscriptInd,
  uploadAudio,
} from 'context/inspections';
import { ActionType } from 'types/action';
import micAnimate from '../../../assets/gifs/mic-animate.gif';
import { Box, IconButton, Tooltip } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import MicIcon from '@mui/icons-material/Mic';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import './styles.scss';
import { Field } from 'types/form-field';
import { ReportProblemOutlined } from '@mui/icons-material';
import fixWebmDuration from 'fix-webm-duration';

interface AudioFieldProps {
  Field: Field;
  save: (field?: Field) => void;
  onChange: (event: any) => any;
  poNumber?: string;
  hidden?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AudioField = ({
  Field,
  save,
  onChange,
  poNumber,
  hidden,
}: AudioFieldProps): JSX.Element => {
  const [recordState, setRecordState] = useState('');
  const [listening, setListening] = useState(false);
  const [countTime, setCountTime] = useState(0);
  const [intervalId, setIntervalId] = useState<any>(0);
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const [audioUrl, setAudioUrl] = useState('');
  const [story, setStory] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [alertTranscriptModal, setAlertTranscriptModal] = useState(false);

  useEffect(() => {
    if (countTime >= 45) {
      stopAudio();
    }
    // eslint-disable-next-line
  }, [countTime]);

  useEffect(() => {
    if (audioUrl) {
      save();
    }
    // eslint-disable-next-line
  }, [audioUrl]);

  useEffect(() => {
    if (story) {
      save();
    }
    // eslint-disable-next-line
  }, [story]);

  const startAudio = (): void => {
    setRecordState('start');
    setListening(true);
  };

  const stopAudio = (): void => {
    setRecordState('stop');
    setListening(false);

    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(0);
      setCountTime(0);
    }
  };

  const recordAudio = (): void => {
    if (recordState === 'start') {
      stopAudio();
    } else {
      startAudio();
      startTime();
    }
  };

  const startTime = (): void => {
    const interval = setInterval(() => {
      setCountTime((countTime) => countTime + 1);
    }, 1000);
    setIntervalId(interval);
  };

  const setFieldUrl = async (urlS3: string, presigned = false) => {
    switch (Field.name ? Field.name : Field.keyName) {
      case 'AudioStoryUrl':
        if (presigned) {
          dispatch({ type: ActionType.SET_AUDIO_PRESIGNED, payload: urlS3 });
        } else {
          dispatch({ type: ActionType.SET_AUDIO_STORY, payload: urlS3 });
        }
        break;
      case 'AudioVehicleInsured':
        if (presigned) {
          dispatch({
            type: ActionType.SET_AUDIO_INSURED_PRESIGNED,
            payload: urlS3,
          });
        } else {
          dispatch({ type: ActionType.SET_AUDIO_INSURED, payload: urlS3 });
        }
        break;
      case 'AudioVehicleInjured':
        if (presigned) {
          dispatch({
            type: ActionType.SET_AUDIO_INJURED_PRESIGNED,
            payload: urlS3,
          });
        } else {
          dispatch({ type: ActionType.SET_AUDIO_INJURED, payload: urlS3 });
        }
        break;
    }
  };

  const getUrlPresignedField = () => {
    switch (Field.name ? Field.name : Field.keyName) {
      case 'AudioStoryUrl':
        return inspectionState.audioPresignedUrl
          ? inspectionState.audioPresignedUrl
          : Field.defaultValue;
      case 'AudioVehicleInsured':
        return inspectionState.audioVehicleInsuredPresigned
          ? inspectionState.audioVehicleInsuredPresigned
          : Field.defaultValue
          ? Field.defaultValue
          : undefined;
      case 'AudioVehicleInjured':
        return inspectionState.audioVehicleInjuredPresigned
          ? inspectionState.audioVehicleInjuredPresigned
          : Field.defaultValue
          ? Field.defaultValue
          : undefined;
      default:
        return '';
    }
  };

  const setTranscriptState = (text: string) => {
    switch (Field.name ? Field.name : Field.keyName) {
      case 'AudioStoryUrl':
        dispatch({
          type: ActionType.SET_AUDIO_TRANSCRIPT,
          payload: text,
        });
        break;
      case 'AudioVehicleInsured':
        dispatch({
          type: ActionType.SET_AUDIO_TRANSCRIPT_INSURED,
          payload: text,
        });
        break;
      case 'AudioVehicleInjured':
        dispatch({
          type: ActionType.SET_AUDIO_TRANSCRIPT_INJURED,
          payload: text,
        });
        break;
    }
  };

  const getInputText = () => {
    switch (Field.name ? Field.name : Field.keyName) {
      case 'AudioStoryUrl':
        return 'storyDetail';
      case 'AudioVehicleInsured':
        return 'Ocurrido';
      case 'AudioVehicleInjured':
        return 'VehicleDescription2';
      default:
        return '';
    }
  };

  const onStop = async (audioData: any) => {
    try {
      dispatch({ type: ActionType.SET_LOADING, payload: true });
      const duration = audioData.stopTime - audioData.startTime;
      const blobFixed = await fixWebmDuration(audioData.blob, duration);
      const urlS3 = await uploadAudio(
        new File([blobFixed], 'audio_story.wav', { type: 'audio/wav' }),
        poNumber
          ? poNumber
          : inspectionState.currentInspection?.EventRecord || '',
        localStorage.getItem('countryCode') || '',
      );

      setFieldUrl(urlS3);

      const presignedUrl = await presignedAudioUrl(urlS3);

      setFieldUrl(presignedUrl, true);

      dispatch({ type: ActionType.SET_LOADING, payload: false });

      setAudioUrl(urlS3);
    } catch (error) {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const transcriptAudio = async () => {
    try {
      setShowConfirmModal(false);
      if (!getUrlPresignedField()) {
        setAlertTranscriptModal(true);

        return;
      }

      dispatch({ type: ActionType.SET_LOADING, payload: true });
      const transcript = await transcriptAudioUrl(
        getUrlPresignedField(),
        localStorage.getItem('countryCode') || '',
      );

      let storyData = '';
      for (const text of transcript) {
        storyData += text + ' ';
      }

      // Set IndTranscripted to true
      const idToUpdate =
        inspectionState.currentInspection?.EventId !== undefined
          ? inspectionState.currentInspection?.EventId
          : 0;
      await updateTranscriptInd(idToUpdate);

      setTranscriptState(storyData);

      localStorage.setItem(
        `${inspectionState.currentInspection?.EventId}-${
          Field.name ? Field.name : Field.keyName
        }-transcript`,
        'true',
      );

      dispatch({
        type: ActionType.SET_LOADING,
        payload: false,
      });

      setStory(storyData);
      const field = {
        target: {
          name: `${getInputText()}|${
            Field.formSectionId ? Field.formSectionId : Field.id
          }`,
          value: storyData,
        },
      };
      onChange(field);
    } catch (error) {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  return (
    <div>
      <Box component="div" display="inline">
        <label className="label-story">Narración de los hechos</label>
      </Box>
      <Box component="div" display="inline">
        {getUrlPresignedField() && !listening && (
          <Tooltip
            className="warn-icon"
            title="El cliente ya agregó un audio. Favor de revisarlo y seleccionar TRANSCRIBIR."
          >
            <ReportProblemOutlined className="warn" />
          </Tooltip>
        )}
      </Box>

      <div className="flex">
        <Modal
          className="confirm-modal"
          open={alertTranscriptModal}
          onClose={() => setAlertTranscriptModal(false)}
        >
          <div className="container">
            <h3>Debes grabar un audio antes de poder transcribir</h3>
            <div className="buttons-container">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setAlertTranscriptModal(false)}
              >
                Cerrar
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          className="confirm-modal"
          open={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
        >
          <div className="container">
            <h3>¿Desea transcribir este audio?</h3>
            <p>
              <b>Nota:</b> Solo puedes realizar una transcripción
            </p>

            <div className="buttons-container">
              <Button variant="contained" onClick={transcriptAudio}>
                Si
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowConfirmModal(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Modal>
        <ReactMic
          record={listening}
          className="sound-wave"
          onStop={onStop}
          strokeColor="#000000"
          backgroundColor="#FF4081"
          mimeType="audio/webm"
          audioBitsPerSecond={64000}
        />
        <div className="contents">
          {getUrlPresignedField() && !listening ? (
            <audio
              controls
              src={getUrlPresignedField()}
              controlsList="nodownload"
            ></audio>
          ) : (
            <audio controls src="#" controlsList="nodownload"></audio>
          )}
        </div>
        <div className="contents">
          {listening && (
            <img
              src={micAnimate}
              alt=""
              onClick={recordAudio}
              title="Grabar Audio"
            ></img>
          )}
          {!hidden && !listening && (
            <IconButton
              color="primary"
              aria-label="Grabar Audio"
              onClick={recordAudio}
            >
              <MicIcon />
            </IconButton>
          )}
        </div>
        {!hidden &&
          !localStorage.getItem(
            `${inspectionState.currentInspection?.EventId}-${
              Field.name ? Field.name : Field.keyName
            }-transcript`,
          ) && (
            <div className="contents">
              <Button
                variant="text"
                color="primary"
                startIcon={<CreateIcon />}
                title="Transcribir Audio"
                onClick={() => setShowConfirmModal(true)}
                disabled={listening}
              >
                Transcribir
              </Button>
            </div>
          )}
      </div>
      {!getUrlPresignedField() && !listening && (
        <div className="flex">
          <div className="icon-info">
            <InfoIcon />
          </div>
          <div className="text-info">
            <span>No se ha grabado ningún audio (No obligatorio)</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AudioField;
