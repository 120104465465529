import React, { useCallback, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import DatePicker from 'components/common/datepicker/DatePicker';
import COMPANIES from 'static/constants/companies';
import AGENCIES from 'static/constants/agencies';
import { Option } from 'types/option';
import styles from './override-mui-styles';
import './styles.scss';
import { userActionsPermissions } from 'shared/utils';
import { ResourceAction, Rol } from 'types/common';
import { companyFilterPermission } from 'shared/utils/company-filter-permission';
import { IUserModel } from 'types/user';

interface Props {
  showOnlyDatePicker?: boolean;
  company: Option | null;
  agency: Option | null;
  inspector?: Option | null;
  expressAdjust?: boolean | null;
  aapIndicator?: boolean | null;
  startDate: Date | null;
  endDate: Date | null;
  currentUser: IUserModel;
  onChangeCompany?: (option: Option | null) => void;
  onChangeAgency?: (option: Option | null) => void;
  onChangeStartDate: (date: Date | null) => void;
  onChangeEndDate: (date: Date | null) => void;
  onChangeDateRange: (dates: Date[]) => void;
  setCurrentCompany?: (option: Option[]) => void;
  setCurrentAgency?: (option: Option[]) => void;
}

const GridFilters = (props: Props): JSX.Element => {
  const classes = styles();
  const [activeRequest, setActiveRequest] = useState<boolean>(true);
  const [listCompanies, setListCompanies] = useState<any[]>([]);
  const [listAgencies, setListAgencies] = useState<any[]>([]);
  // Destructuring props
  const { setCurrentCompany, setCurrentAgency } = props;
  const userRolId = localStorage.getItem('rolId');
  const getCompanies = useCallback(() => {
    const filteredCompanies = COMPANIES(true, true).filter(
      (f) =>
        (f.type === 'INSURANCE' || f.type === 'INTERNAL') &&
        f.code === localStorage.getItem('countryCode'),
    );

    if (setCurrentCompany) {
      setCurrentCompany(filteredCompanies);
    }

    return filteredCompanies;
  }, [setCurrentCompany]);

  const getCompaniesByRol = useCallback(() => {
    return COMPANIES(true).filter(
      (f) =>
        (f.type === 'INSURANCE' || f.type === 'INTERNAL') &&
        f.code === localStorage.getItem('countryCode') &&
        f.id === Number(localStorage.getItem('companyId')),
    );
  }, []);

  const getAgencies = useCallback(() => {
    const agenciesData = AGENCIES();
    if (setCurrentAgency) {
      setCurrentAgency(agenciesData);
    }

    return agenciesData;
  }, [setCurrentAgency]);

  const myPermissionIsValid = (action: ResourceAction): boolean => {
    return Boolean(userActionsPermissions(action));
  };

  useEffect(() => {
    if (Array.from(listCompanies).length === 0 && activeRequest) {
      if (userRolId === Rol.INSURER.toString()) {
        setListCompanies(getCompaniesByRol());
      } else {
        setListCompanies(getCompanies());
      }
      setActiveRequest(false);
    }
  }, [
    listCompanies,
    setListCompanies,
    getCompanies,
    getCompaniesByRol,
    activeRequest,
  ]);

  useEffect(() => {
    if (Array.from(listAgencies).length === 0 && activeRequest) {
      setListAgencies(getAgencies());
      setActiveRequest(false);
    }
  }, [listAgencies, setListAgencies, getAgencies, activeRequest]);

  return (
    <div className="filters">
      <DatePicker
        startDate={props.startDate}
        endDate={props.endDate}
        onChangeStartDate={props.onChangeStartDate}
        onChangeEndDate={props.onChangeEndDate}
        onChangeDateRange={props.onChangeDateRange}
      />
      {!props.showOnlyDatePicker && (
        <>
          {companyFilterPermission(
            userRolId,
            props.currentUser.isMultiCountry,
            myPermissionIsValid,
          ) && (
            <Autocomplete
              options={listCompanies}
              getOptionLabel={(option: Option) => option.name}
              style={{ width: 300 }}
              onChange={(ev, option: Option | null) => {
                if (props.onChangeCompany) {
                  props.onChangeCompany(option);
                }
              }}
              value={props.company}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Aseguradora"
                  classes={{ root: classes.inputRoot }}
                />
              )}
            />
          )}
        </>
      )}
      {myPermissionIsValid(ResourceAction.USE_FILTER_RUNNERS) && (
        <Autocomplete
          options={listAgencies}
          getOptionLabel={(option: Option) => option.name}
          style={{ width: 300 }}
          onChange={(ev, option: Option | null) => {
            if (props.onChangeAgency) {
              props.onChangeAgency(option);
            }
          }}
          value={props.agency}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Corredor"
              classes={{ root: classes.inputRoot }}
            />
          )}
        />
      )}
    </div>
  );
};

export default GridFilters;
