import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import CheckSelected from 'assets/icons/check_icon.svg';
import clientUpload from 'assets/icons/letter-c.svg';
import RemoveSelected from 'assets/icons/remove_icon.svg';
import AddNote from 'assets/icons/add-note.svg';
import EditNote from 'assets/icons/edit-note.svg';
import VideoIcon from 'assets/images/i-360video.svg';
import PdfIcon from 'assets/images/pdf.svg';
import clsx from 'clsx';
import Button from 'components/claims/button';
import { IUserContext, UserContext } from 'context/user';
import { saveAs } from 'file-saver';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router';
import ConnectServicesClient from 'shared/clients/ConnectServiceClient';
import useSimpleAndDoubleClick from 'shared/hooks/useSimpleAndDoubleClick';
import { Rol } from 'types/common';
import { EventPhoto } from 'types/images';
import { DraggableBox } from '../dnd/DraggableBox';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { isGuid } from 'shared/utils';

interface ThumbnailProps {
  photo?: EventPhoto;
  selectedPhotos: string[];
  onClick: (imageSource: string, shiftKeyActive: boolean) => void;
  onDoubleClick: (imageSource: string) => void;
  onDeleteClick: (imgageSource: string) => void;
}

const Thumbnail: FunctionComponent<ThumbnailProps> = ({
  photo,
  selectedPhotos,
  onClick,
  onDoubleClick,
  onDeleteClick,
}): JSX.Element => {
  const fileUrl = (photo?.awsUrl != null ? photo.awsUrl : photo?.dataURL) || '';
  const isPDF =
    fileUrl.indexOf('.pdf') > -1 || fileUrl.indexOf('application/pdf') > -1;

  const isNotBroker = ![Rol.PRE_BROKER, Rol.PRE_CONSULTANT].includes(
    Number(localStorage.getItem('rolId')),
  );
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [documentObservation, setDocumentObservation] = useState<string>('');
  const { id } = useParams<any>();
  const {
    state: { currentUser },
    dispatch: userDispatch,
  } = useContext(UserContext) as IUserContext;
  const {
    state: { claimsManagementInfo },
  } = useContext<IInspectionContext>(InspectionContext);
  const isInsuranceRol = Number(localStorage.getItem('rolId')) === Rol.INSURER;

  const [isV2, setIsV2] = useState<boolean>(isGuid(id));

  const isVideo =
    fileUrl.indexOf('.mp4') > -1 ||
    fileUrl.indexOf('.avi') > -1 ||
    fileUrl.indexOf('.mov') > -1 ||
    fileUrl.indexOf('.qt') > -1 ||
    fileUrl.indexOf('.quicktime') > -1 ||
    fileUrl.indexOf('.webm') > -1;

  const clickImage = useSimpleAndDoubleClick(
    () => {
      if (!photo?.isWaiting) {
        onClick(fileUrl, false);
      }
    },
    () => {
      if (!photo?.isWaiting) {
        onDoubleClick(fileUrl);
      }
    },
  );

  const clickFile = useSimpleAndDoubleClick(
    () => {
      if (!isVideo) {
        onDeleteClick(fileUrl);
      }
    },
    () => {
      saveAs(fileUrl, 'reportePDF');
    },
  );

  useEffect(() => {
    if (isV2) {
      searchForNote();
    }
  }, []);

  const searchForNote = async () => {
    const clientRequest = new ConnectServicesClient();
    const docObservation = await clientRequest.getDocumentObservationByDocName(
      String(id),
      photo?.imageName ?? '',
    );

    if (docObservation) {
      setDocumentObservation(docObservation.documentObservation);
    } else {
      setDocumentObservation('');
    }
  };

  // Crear una variable booleana que indique si el id es un GUID
  const isIdGuid = isGuid(id);

  const handleOpenModal = async () => {
    setOpenConfirmation(true);
  };

  const handleSaveDocumentObservation = async () => {
    const data = {
      documentName: photo?.imageName,
      documentObservation: documentObservation,
      userId: currentUser.userCompanyID,
    };

    const clientRequest = new ConnectServicesClient();
    const response = await clientRequest.uploadDocumentObservation(
      String(id),
      data,
    );
    if (response.data.success) {
      setOpenConfirmation(false);

      return response.data.data;
    }
  };

  const handleOpenFile = (url: string): void => {
    window.open(url, '_blank');
  };

  return (
    <>
      <Grid item>
        {photo && !isPDF && !isVideo ? (
          <DraggableBox item={photo}>
            <div
              onClick={clickImage}
              className={clsx('gallerySelector', 'renderImage', {
                selected: selectedPhotos.includes(fileUrl),
              })}
            >
              {!selectedPhotos ||
                (selectedPhotos.length === 0 && isNotBroker && (
                  <div
                    className="removeItem"
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    onClick={() => onDeleteClick(photo.awsUrl!)}
                  >
                    <img src={RemoveSelected} alt="Remover" />
                  </div>
                ))}

              <img
                src={fileUrl}
                title={photo?.imageName ?? ''}
                alt="Imagen de galería"
              />
              <img
                className={clsx(
                  selectedPhotos.includes(fileUrl)
                    ? 'activeCheck'
                    : 'inactiveCheck',
                )}
                src={CheckSelected}
                alt="Seleccionado"
              />
              {photo.coInspectionUpload === '1' && (
                <div className="clientItem">
                  <img src={clientUpload} alt="Cargada por coinspección" />
                  <div className="tooltip">
                    <span className="tooltiptext">Foto Cliente</span>
                  </div>
                </div>
              )}

              {/* Si es V1 o aseguradora y no tiene habilitado el flujo de aprobación no permite ver las notas. */}
              {!isV2 ||
              claimsManagementInfo === undefined ||
              (isInsuranceRol && !claimsManagementInfo?.requiresApproval) ||
              selectedPhotos.length !== 0 ? null : (
                <div
                  className="removeItem"
                  style={{
                    right: '20px',
                    backgroundColor:
                      documentObservation !== '' ? '#F15B2B' : '#FFFFFF',
                  }}
                  onClick={handleOpenModal}
                >
                  <img
                    src={documentObservation !== '' ? EditNote : AddNote}
                    alt="Notas"
                  />
                </div>
              )}
            </div>
          </DraggableBox>
        ) : (
          <>
            <div
              className="gallerySelector renderImage"
              onDoubleClick={() => {
                handleOpenFile(fileUrl);
              }}
            >
              {isNotBroker && (
                <div className="removeItem" onClick={clickFile}>
                  <img src={RemoveSelected} alt="Remover" />
                </div>
              )}
              {claimsManagementInfo === undefined ||
              (isInsuranceRol &&
                !claimsManagementInfo?.requiresApproval) ? null : (
                <div
                  className="removeItem"
                  style={{
                    right: '20px',
                    backgroundColor:
                      documentObservation !== '' ? '#F15B2B' : '#FFFFFF',
                  }}
                  onClick={handleOpenModal}
                >
                  <img
                    src={documentObservation !== '' ? EditNote : AddNote}
                    alt="Notas"
                  />
                </div>
              )}

              <img
                src={isVideo ? VideoIcon : PdfIcon}
                title={photo?.imageName ?? ''}
                alt="Archivo PDF"
              />
            </div>
          </>
        )}
      </Grid>
      <Dialog open={openConfirmation}>
        <DialogTitle id="confirm-restore-title">Agregar Nota</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-payment-modal"
            className="gallerySelector_NotesModal"
          >
            {photo?.imageName}
            <TextField
              style={{ width: '100%', marginTop: '15px' }}
              disabled={isInsuranceRol}
              name="customerResponsabilityAmount"
              className="claim-adjust__data--row--input gallerySelector_NotesText"
              label="Escriba la nota..."
              value={documentObservation}
              InputProps={{
                inputProps: {
                  maxLength: 200,
                },
              }}
              onChange={(e) => {
                setDocumentObservation(e.target.value);
              }}
            ></TextField>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            text="Cancelar"
            variant="outlined"
            color="secondary"
            onClick={() => setOpenConfirmation(false)}
          />
          {isInsuranceRol ? null : (
            <Button
              text="Guardar"
              variant="contained"
              color="primary"
              onClick={handleSaveDocumentObservation}
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Thumbnail;
