import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowIdGetter,
  GridRowsProp,
} from '@mui/x-data-grid';
import { Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import './index.scss';

interface GridProps {
  data: GridRowsProp;
  columns: GridColDef[];
  showCheckBox?: boolean;
  selectionModel?: GridRowId[];
  onRowSelectionModelChange?: (rows: any) => void;
  getRowId?: GridRowIdGetter<any>;
  disableRowSelectionClick?: boolean;
}

const DataTable: FunctionComponent<GridProps> = ({
  data,
  columns,
  showCheckBox,
  selectionModel,
  onRowSelectionModelChange,
  getRowId,
  disableRowSelectionClick,
}): JSX.Element => {
  return (
    <Box>
      <DataGrid
        sx={{
          border: 0,
          borderBottom: 0,
          '& .MuiDataGrid-cell': {
            borderBottomColor: '#B9D3DE',
            minHeight: '48px !important',
            maxHeight: '48px !important',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#FFF2EE',
            borderBottom: 0,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: 14,
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#EBF1F5',
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: '#FCCCBD',
          },
        }}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        checkboxSelection={showCheckBox}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={onRowSelectionModelChange}
        disableRowSelectionOnClick={
          disableRowSelectionClick === undefined
            ? true
            : disableRowSelectionClick
        }
        getRowId={getRowId}
      />
    </Box>
  );
};

export default DataTable;
