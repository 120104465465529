import {
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Chip,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Box,
  Alert,
} from '@mui/material';
import {
  Check,
  MoreVertOutlined,
  ReportProblemOutlined,
  SmsOutlined,
} from '@mui/icons-material';
import { globalCss } from 'assets/connect/styles/_variables';
import clsx from 'clsx';
import Avatar from 'components/common/avatar/Avatar';
import SimpleChip from 'components/common/chip/Chip';
import {
  updateAssignedUser,
  linkTagWithEvent,
  cancelEvent,
  restoreEvent,
  getEventThirds,
  createSufix,
} from 'context/inspections/inspectionUtils';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import useDevice from 'shared/hooks/useDevice';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { userActionsPermissions } from 'shared/utils';
import { EVENT_STATUSES } from 'static/constants/event-status';
import {
  GRID_COLUMNS,
  MENU_TABLE_OPTIONS,
  MENU_LIGHT,
} from 'static/constants/events-grid';
import {
  BrandsId,
  CountryCode,
  IGrid,
  ResourceAction,
  Rol,
  TransitionStatesIds,
} from 'types/common';
import { Event } from 'types/event';
import TableMenu, { MenuOption } from '../../events/table/menu';
import styles from './override-mui-styles';
import './styles.scss';
import TagPicker from '../../events/table/tag-picker/TagPicker';
import UserPicker from '../../events/table/user-picker/UserPicker';
import {
  FiltersContext,
  IFiltersContext,
} from 'context/filters/FiltersContext';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import AlertGrid from '../../common/alert';
import { IUserContext, UserContext } from 'context/user';
import { ActionType } from 'types/action';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import DataTable from '../grid';
import { ClaimantType } from 'types/claimsTracker';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';

const columnsGrid: IGrid[] = [
  {
    columnId: 1,
    columnDataName: 'alertType',
    columnName: '',
    sortId: 1,
  },
  {
    columnId: 2,
    columnDataName: 'claimNumber',
    columnName: 'Casos',
    sortId: 2,
  },
  {
    columnId: 3,
    columnDataName: 'stateDescription',
    columnName: 'Estado',
    sortId: 3,
  },
  {
    columnId: 4,
    columnDataName: 'eventDate',
    columnName: 'Fecha Apertura',
    sortId: 4,
  },
  {
    columnId: 5,
    columnDataName: 'scheduleDate',
    columnName: 'Fecha Inspección',
    sortId: 5,
  },
  {
    columnId: 6,
    columnDataName: 'daysOpen',
    columnName: 'Días abierta',
    sortId: 6,
  },
  {
    columnId: 7,
    columnDataName: 'lastModified',
    columnName: 'Días sin movimiento',
    sortId: 7,
  },
  {
    columnId: 8,
    columnDataName: 'companyName',
    columnName: 'Compañía',
    sortId: 8,
  },
  {
    columnId: 9,
    columnDataName: 'claimantType',
    columnName: 'Tipo de reclamante',
    sortId: 9,
  },
  {
    columnId: 10,
    columnDataName: 'vehicleMetallicPlate',
    columnName: 'Placa',
    sortId: 10,
  },
  {
    columnId: 11,
    columnDataName: 'insurance',
    columnName: 'Póliza',
    sortId: 11,
  },
  {
    columnId: 12,
    columnDataName: 'audited',
    columnName: 'Auditado',
    sortId: 12,
  },
];

const columnsGridOptima: IGrid[] = [
  {
    columnId: 1,
    columnDataName: 'alertType',
    columnName: '',
    sortId: 1,
  },
  {
    columnId: 2,
    columnDataName: 'claimNumber',
    columnName: 'Casos',
    sortId: 2,
  },
  {
    columnId: 3,
    columnDataName: 'reclamacion',
    columnName: 'Reclamación',
    sortId: 3,
  },
  {
    columnId: 4,
    columnDataName: 'stateDescription',
    columnName: 'Estado',
    sortId: 4,
  },
  {
    columnId: 5,
    columnDataName: 'eventDate',
    columnName: 'Fecha Apertura',
    sortId: 5,
  },
  {
    columnId: 6,
    columnDataName: 'scheduleDate',
    columnName: 'Fecha Inspección',
    sortId: 6,
  },
  {
    columnId: 7,
    columnDataName: 'daysOpen',
    columnName: 'Días abierta',
    sortId: 7,
  },
  {
    columnId: 8,
    columnDataName: 'lastModified',
    columnName: 'Días sin movimiento',
    sortId: 8,
  },
  {
    columnId: 9,
    columnDataName: 'companyName',
    columnName: 'Compañía',
    sortId: 9,
  },
  {
    columnId: 10,
    columnDataName: 'claimantType',
    columnName: 'Tipo de reclamante',
    sortId: 10,
  },
  {
    columnId: 11,
    columnDataName: 'vehicleMetallicPlate',
    columnName: 'Placa',
    sortId: 11,
  },
  {
    columnId: 12,
    columnDataName: 'insurance',
    columnName: 'Póliza',
    sortId: 12,
  },
  {
    columnId: 13,
    columnDataName: 'audited',
    columnName: 'Auditado',
    sortId: 13,
  },
];

interface StatusProps {
  value: string;
  label: string;
  colorClass: string;
}

interface Props {
  events: Array<Event>;
  page: number;
  paginator: any;
  totalElements: number;
  onClickEditView(row: any, event: any): any;
  onClickNote(event: Event): any;
  onChangePage: (event: unknown, newPage: number) => void;
  reloadEvents?: () => void;
}

const getCurrentCountry = () => {
  return localStorage.getItem('countryCode')?.toUpperCase() as CountryCode;
};

const agentAssignPermission = (): boolean => {
  return Boolean(userActionsPermissions(ResourceAction.USE_ASSIGN_AGENT));
};

const notificationPermission = (): boolean => {
  return Boolean(userActionsPermissions(ResourceAction.VIEW_NOTIFICATIONS));
};

const eventAccessPermission = (): boolean => {
  return Boolean(userActionsPermissions(ResourceAction.VIEW_EVENT_DETAIL));
};

const SHOW_ALWAYS = [
  'statusName',
  'eventRecord',
  'vehicleMetallicPlate',
  'stateDescription',
];

interface IThirdSufix {
  eventThirdId: number;
  nombreTercero: string;
  eventThirdBrand: string;
  eventThirdLicensePlate: string;
}

const columnsThirds: GridColDef[] = [
  { field: 'nombreTercero', headerName: 'Nombre', width: 150 },
  { field: 'eventThirdBrand', headerName: 'Vehículo', width: 100 },
  { field: 'eventThirdLicensePlate', headerName: 'Placa', width: 100 },
];

const EventsTable = (props: Props): JSX.Element => {
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );

  const classes = styles();
  const [showCancelEventModal, setShowCancelEventModal] = useState(false);
  const [showSendMailModal, setShowSendMailModal] = useState(false);
  const [showConfirmEventModal, setShowConfirmEventModal] = useState(false);
  const [txtEmailSend, setTxtEmailSend] = useState<string>('');
  const [txtPhoneSend, setTxtPhoneSend] = useState<string>('');
  const [uid, setUid] = useState<string>('');
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [showCreateSufixModal, setShowCreateSufixModal] = useState(false);
  const [listThirds, setListThirds] = useState<IThirdSufix[]>([]);
  const [selectionFields, setSelectionFields] = useState<GridRowId[]>([]);
  const [disabledSendMail, setDisabledSendMail] = useState(true);
  const [showConfirmRestoreModal, setShowConfirmRestoreModal] = useState(false);
  const [selectedEventRecord, setSelectedEventRecord] = useState<string>('');
  const [showRequireMsg, setShowRequireMsg] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<number>(0);
  const [cancelReason, setCancelReason] = useState<string>('');
  const [restoreReason, setRestoreReason] = useState<string>('');
  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);
  const [pickerEvent, setPickerEvent] = useState<number | null>(null);
  const [tagPicker, setTagPicker] = useState<number | null>(null);
  const [tagAnchorEl, setTagAnchorEl] = useState<null | HTMLElement>(null);
  const {
    state: { currentUser },
    dispatch: userDispatch,
  } = useContext(UserContext) as IUserContext;
  const [selectedRow, setSelectedRow] = useState<null | {
    el: HTMLElement;
    event: any;
  }>(null);

  const USERS = props.events
    .map((e) => e.assignedUserId)
    .filter((u) => u !== null)
    .filter((u, i, a) => a.indexOf(u) === i);
  const { isDesktop, isMobile } = useDevice();

  const { queryFilters, setQueryFilters } = useContext<IFiltersContext>(
    FiltersContext,
  );

  useEffect(() => {
    setDisabledSendMail(!(txtEmailSend && txtPhoneSend));
  }, [txtEmailSend, txtPhoneSend]);

  const onChangeMailField = (event) => {
    setTxtEmailSend(event?.target?.value);
  };
  const onChangePhoneField = (event) => {
    setTxtPhoneSend(event?.target?.value);
  };
  const getCurrentGridColumns = (): IGrid[] => {
    //for version 2 cmt
    const countryCode = localStorage.getItem('countryCode') as CountryCode;

    if ([CountryCode.PAN, CountryCode.CRC].includes(countryCode)) {
      const columnsStr = localStorage.getItem('gridColumns') ?? '';

      if (columnsStr.length > 0) {
        return Array.from(JSON.parse(columnsStr));
      }

      return [];
    } else {
      if (
        queryFilters.companyId === BrandsId.OPTIMA_SEGUROS ||
        ((Number(localStorage.getItem('rolId') ?? 0) === Rol.INSURER ||
          Number(localStorage.getItem('rolId') ?? 0) ===
            Rol.CLAIMS_ASSISTANT) &&
          Number(localStorage.getItem('companyId') ?? 0) ===
            BrandsId.OPTIMA_SEGUROS)
      ) {
        return columnsGridOptima;
      } else {
        return columnsGrid;
      }
    }
  };

  const ShowCancelModal = async (): Promise<void> => {
    setCancelReason('');
    setShowCancelEventModal(true);
  };

  const ShowSendModal = async (): Promise<void> => {
    setUid(selectedRow?.event.uid);
    setTxtPhoneSend(selectedRow?.event.contactPhone);
    setTxtEmailSend(selectedRow?.event.contactEmail);
    setShowSendMailModal(true);
  };
  const ShowRestoreModal = async (): Promise<void> => {
    setRestoreReason('');
    setShowRestoreModal(true);
  };

  const ShowModalCreateSufix = async (event: any) => {
    try {
      dispatch({ type: ActionType.SET_LOADING, payload: true });
      const result: IThirdSufix[] = await getEventThirds(
        event.countryCode,
        event.eventId,
      );

      setListThirds(result);
      dispatch({ type: ActionType.SET_LOADING, payload: false });
      setShowCreateSufixModal(true);
    } catch (e) {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const handleCreateSufix = async () => {
    try {
      const _eventRecord = selectedEventRecord;
      if (selectionFields.length > 0 && _eventRecord) {
        setShowCreateSufixModal(false);
        dispatch({ type: ActionType.SET_LOADING, payload: true });

        const result = await createSufix(
          _eventRecord,
          Number(selectionFields[0]),
        );

        setSelectionFields([]);
        setSelectedEventRecord('');
        dispatch({ type: ActionType.SET_LOADING, payload: false });
        dispatch({
          type: ActionType.SET_GENERAL_ALERT,
          payload: {
            showAlert: true,
            alertMessage: `Sufijo creado correctamente No: ${result.response}`,
            alertType: 'success',
          },
        });
      }
    } catch (e) {
      dispatch({
        type: ActionType.SET_GENERAL_ALERT,
        payload: {
          showAlert: true,
          alertMessage: 'Error al crear el sufijo de reclamación',
          alertType: 'error',
        },
      });
      setShowCreateSufixModal(true);
      setSelectionFields([]);
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    event: Event,
  ) => {
    setSelectedRow({ el: e.currentTarget, event });
    if (event.eventId !== undefined) {
      setSelectedEventId(event.eventId);
    }

    if (event.eventRecord) {
      setSelectedEventRecord(event.eventRecord);
    }
  };

  const handleClickModalCancel = (): void => {
    if (cancelReason.length > 5) {
      setShowRequireMsg(false);
      localStorage.setItem('cancelReason', cancelReason);
      setShowCancelEventModal(false);
      setShowConfirmEventModal(true);
    } else {
      setShowRequireMsg(true);
    }
  };

  const handleClickModalSendMail = async (): Promise<void> => {
    setDisabledSendMail(true);
    const result = await new ClaimsTrackerClient().ForwardingMailClaim(
      uid,
      txtEmailSend,
      txtPhoneSend,
    );
    setDisabledSendMail(false);
    setShowSendMailModal(false);
  };
  const handleClickModalRestore = (): void => {
    if (restoreReason.length > 5) {
      setShowRequireMsg(false);
      setShowRestoreModal(false);
      setShowConfirmRestoreModal(true);
    } else {
      setShowRequireMsg(true);
    }
  };

  const handleClickConfirmlCancel = (): void => {
    if (selectedEventId > 0) {
      cancelEvent(selectedEventId, inspectionState, dispatch, true);
      setShowCancelEventModal(false);
      setShowConfirmEventModal(false);
      setSelectedEventId(0);
    }
  };

  const handleClickConfirmlRestore = (): void => {
    if (selectedEventId > 0) {
      restoreEvent(
        selectedEventId,
        restoreReason,
        inspectionState,
        dispatch,
        true,
      );
      setShowRestoreModal(false);
      setShowConfirmRestoreModal(false);
      setSelectedEventId(0);
    }
  };

  const handleClose = () => {
    setSelectedRow(null);
  };

  const setStatusId = (statusId: number) => {
    let newStatusId = -1;
    let menuOption = 0;
    if (statusId == null) {
      newStatusId = 0;
    } else {
      newStatusId = statusId;
    }
    if (
      newStatusId === TransitionStatesIds.COMPLETED ||
      newStatusId === TransitionStatesIds.PROCESSED ||
      newStatusId === TransitionStatesIds.FINISHED
    ) {
      menuOption = 1;
    }

    return menuOption;
  };

  const StatusChip = (eventStatus: string) => {
    if (!eventStatus) {
      return <></>;
    }

    const eventStatusProps: StatusProps | undefined = Object.values<
      StatusProps
    >(EVENT_STATUSES).find(
      (status: StatusProps) => status.value === eventStatus,
    );

    if (eventStatusProps) {
      return isMobile ? (
        <div
          className={clsx(
            'status-mobile',
            eventStatusProps.colorClass &&
              `status-mobile--${eventStatusProps.colorClass}`,
          )}
        ></div>
      ) : (
        <SimpleChip
          label={eventStatusProps.label}
          color={eventStatusProps.colorClass}
          compact
          isClaim
        />
      );
    } else {
      return isMobile ? (
        <div className={clsx('status-mobile')}></div>
      ) : (
        <SimpleChip label={eventStatus} color="" compact isClaim />
      );
    }
  };

  const setAssignedUser = async (
    eventId: number,
    agentId?: number | null,
    agentName?: string,
    agentEmail?: string,
  ) => {
    const ids: number[] = [];
    if (selectedEvents.length > 0) {
      for (const i of selectedEvents) {
        ids.push(props.events[i].eventId);
      }
    } else {
      ids.push(eventId);
    }

    await updateAssignedUser(ids, currentUser.userCompanyID ?? 0, agentId);

    setPickerEvent(null);
    setSelectedEvents([]);

    const _events = inspectionState.inspections;

    const indexEvent = _events.findIndex((event) => event.eventId === eventId);

    if (indexEvent >= 0) {
      _events[indexEvent].assignedUserId = agentId ?? null;
      _events[indexEvent].assignedUserName = agentName ?? '';
      _events[indexEvent].assignedUserEmail = agentEmail ?? '';

      dispatch({
        type: ActionType.GET_EVENTS_SUCCESS,
        payload: {
          events: _events,
          indicators: inspectionState.statusesIndicators,
        },
      });
    }

    if (props.reloadEvents) {
      props.reloadEvents();
    }
  };

  const setEventTag = async (eventId: number, tagId?: number | null) => {
    await linkTagWithEvent(eventId, tagId);
    setTagPicker(null);
    setSelectedEvents([]);
    if (props.reloadEvents) {
      props.reloadEvents();
    }
  };

  const columnActionPermission = Boolean(
    userActionsPermissions(ResourceAction.USE_COLUMN_ACTIONS),
  );

  const rowMenuWithPermission = (selectedRow: any): MenuOption[] => {
    const statusId = selectedRow.event.statusId;
    const claimStatusId = selectedRow.event.claimStatusId;
    const stageId = selectedRow.event.claimStageId;
    const linkPermission = userActionsPermissions(ResourceAction.USE_LINK_FORM);
    const cancelPermission = userActionsPermissions(
      ResourceAction.USE_BTN_CANCEL_EVENT,
    );
    const downloadPermission = userActionsPermissions(
      ResourceAction.USE_BTN_DOWNLOAD_EVENT,
    );
    const integrationPermission = userActionsPermissions(
      ResourceAction.USE_BTN_INTEGRATION_EVENT,
    );
    const createSufixPermission = userActionsPermissions(
      ResourceAction.USE_BTN_CREATE_SUFIX_EVENT,
    );

    const menu = setStatusId(statusId) === 1 ? MENU_TABLE_OPTIONS : MENU_LIGHT;

    menu.forEach((fe: MenuOption) => {
      // By Permission
      if (fe.value === 'CANCEL') {
        fe.isActive = cancelPermission;
        fe.action = ShowCancelModal;
        if (statusId === TransitionStatesIds.CANCELLED) {
          fe.isActive = false;
        }
      } else if (fe.value === 'OPEN_FORM') {
        fe.isActive = linkPermission;
        if (statusId === TransitionStatesIds.CANCELLED) {
          fe.isActive = false;
        }
      } else if (fe.value === 'DOWNLOAD_INSPECTION') {
        fe.isActive = downloadPermission;
      } else if (fe.value === 'SEND_MAIL') {
        fe.isActive = true;
        fe.action = ShowSendModal;
      } else if (fe.value === 'INTEGRATION') {
        fe.isActive =
          integrationPermission &&
          (statusId > 2 ||
            stageId > 3 ||
            (statusId >= 2 &&
              selectedRow.event.companyId === BrandsId.LAFISE) ||
            (claimStatusId == 28 &&
              selectedRow.event.companyId === BrandsId.CSM));
      } else if (fe.value === 'RESTORE') {
        fe.isActive = true;
        fe.action = ShowRestoreModal;
        if (statusId !== TransitionStatesIds.CANCELLED) {
          fe.isActive = false;
        }
      } else if (fe.value === 'CREATE_SUFIX') {
        fe.action = () => {
          ShowModalCreateSufix(selectedRow.event);
        };

        fe.isActive = Boolean(
          createSufixPermission &&
            statusId >= TransitionStatesIds.COMPLETED && //Inspection Completed
            String(selectedRow.event?.claimantType).toUpperCase() ===
              ClaimantType.CLAIM_INSURED &&
            selectedRow.event.companyId === BrandsId.GENERAL_DE_SEGUROS,
        );
      }
    });

    return menu;
  };

  const toggleSelectedEvents = (index: number) => {
    const indexes = [...selectedEvents];
    const found = indexes.indexOf(index);
    if (found !== -1) {
      indexes.splice(found, 1);
    } else {
      indexes.push(index);
    }

    setSelectedEvents(indexes.sort());
  };

  const addSelectedEvents = (index: number) => {
    const indexes = [...selectedEvents];
    if (indexes.length === 0) {
      // add index to empty array
      indexes.push(index);
    } else if (index < indexes[0]) {
      // index is lt first element
      const first = indexes[0];
      const length = first - index;
      for (let x = index; x <= length; x++) {
        indexes.push(x);
      }
    } else if (index < Number(indexes.slice(-1).pop())) {
      // index is between first and last element
      const newLast = indexes.indexOf(index);
      indexes.splice(newLast + 1);
    } else {
      // index is gt first element
      const first = indexes[0];
      for (let x = first; x <= index; x++) {
        indexes.push(x);
      }
    }
    setSelectedEvents(
      indexes.sort().filter((v, i, self) => self.indexOf(v) === i),
    );
  };

  const rowClick = (e: React.MouseEvent, eventIndex: number, event: any) => {
    if ((e.metaKey || e.ctrlKey) && e.shiftKey) {
      addSelectedEvents(eventIndex);
    } else if (e.metaKey || e.ctrlKey) {
      toggleSelectedEvents(eventIndex);
    } else if (eventAccessPermission()) {
      props.onClickEditView(e, event);
    }
  };

  const createSortHandler = (property: string) => () => {
    setQueryFilters({
      sort: property,
      direction:
        queryFilters.direction === 'asc' || !queryFilters.direction
          ? 'desc'
          : 'asc',
    });
  };

  const checkSelectFilter = () => {
    return Boolean(
      queryFilters.statusId ||
        queryFilters.filterByExpressAdjust ||
        queryFilters.filterType === 'CUSTOM',
    );
  };

  const headerColumns = (classes: any): JSX.Element => {
    return (
      <>
        {getCurrentGridColumns()
          .sort((first, second) => 0 - (first.sortId > second.sortId ? -1 : 1))
          .map((g: IGrid, index: number) => {
            if (isDesktop) {
              if (
                [CountryCode.CRC, CountryCode.PRI].includes(
                  getCurrentCountry(),
                ) &&
                g.columnDataName === GRID_COLUMNS.AJUSTE_EXPRESS.name
              ) {
                return (
                  <Fragment key={`${g.columnDataName}-${index}`}></Fragment>
                );
              } else if (
                [CountryCode.PAN, CountryCode.PRI].includes(
                  getCurrentCountry(),
                ) &&
                g.columnDataName === GRID_COLUMNS.AAP.name
              ) {
                return (
                  <Fragment key={`${g.columnDataName}-${index}`}></Fragment>
                );
              } else if (
                [CountryCode.PAN, CountryCode.PRI].includes(
                  getCurrentCountry(),
                ) &&
                g.columnDataName === GRID_COLUMNS.QUALI_EXPRESS.name
              ) {
                return (
                  <Fragment key={`${g.columnDataName}-${index}`}></Fragment>
                );
              } else if (
                ![CountryCode.PRI].includes(getCurrentCountry()) &&
                [
                  GRID_COLUMNS.AUDITED.name,
                  GRID_COLUMNS.ESTIMATION.name,
                ].includes(g.columnDataName)
              ) {
                return (
                  <Fragment key={`${g.columnDataName}-${index}`}></Fragment>
                );
              } else if (
                getCurrentCountry() !== CountryCode.CRC &&
                g.columnDataName === 'siseSiniestro'
              ) {
                return (
                  <Fragment key={`${g.columnDataName}-${index}`}></Fragment>
                );
              }
            }

            if (isDesktop || SHOW_ALWAYS.includes(g.columnDataName)) {
              return (
                <TableCell
                  key={`${g.columnDataName}-${index}`}
                  className={classes.header}
                >
                  {g.columnName === 'Fecha' && checkSelectFilter() ? (
                    <TableSortLabel
                      active={queryFilters.sort === g.columnDataName}
                      direction={
                        queryFilters.sort === g.columnDataName
                          ? queryFilters.direction || 'asc'
                          : 'asc'
                      }
                      onClick={createSortHandler(g.columnDataName)}
                    >
                      {g.columnName}
                    </TableSortLabel>
                  ) : (
                    g.columnName
                  )}
                </TableCell>
              );
            }

            return null;
          })}

        {agentAssignPermission() && isDesktop && (
          <TableCell key="avatar" className={classes.header}>
            Agente
          </TableCell>
        )}

        {isDesktop && columnActionPermission && (
          <TableCell key="menu" className={classes.header}>
            Más Acciones
          </TableCell>
        )}

        {notificationPermission() && isDesktop && (
          <TableCell key="notes" className={classes.header}>
            Notas
          </TableCell>
        )}
      </>
    );
  };

  const getEstimationLabel = (estimationFlag: boolean | null) => {
    if (estimationFlag === false) {
      return 'No';
    }
    if (estimationFlag === null) {
      return 'Pendiente';
    }

    return 'Sí';
  };
  const getEstimationColor = (estimationFlag: boolean | null) => {
    if (estimationFlag === false) {
      return 'gray';
    }
    if (estimationFlag === null) {
      return 'yellow';
    }

    return 'green';
  };

  const bodyColumns = (events: Event[]): JSX.Element => {
    return (
      <>
        {events.map((event: any, index: number) => {
          return (
            <TableRow
              key={`${event.eventId}-${index}`}
              className={clsx(
                classes.rowSelect,
                event.indInvestigation &&
                  Number(localStorage.getItem('rolId')) !== Rol.CUSTOMER &&
                  'investigate',
                selectedEvents.includes(index) && 'selected',
              )}
              onClick={(e) => rowClick(e, index, event)}
            >
              {getCurrentGridColumns()
                .sort(
                  (first, second) =>
                    0 - (first.sortId > second.sortId ? -1 : 1),
                )
                .map((g: IGrid, index: number) => {
                  if (isDesktop || SHOW_ALWAYS.includes(g.columnDataName)) {
                    return (
                      <Fragment key={`${g.columnDataName}-${index}`}>
                        {g.columnDataName === 'alertType' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.smallCell)}
                          >
                            {event.alertType ? (
                              <AlertGrid
                                alertType={event.alertType}
                                alertMessage={event.alertMessage}
                              />
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        )}
                        {g.columnDataName === 'stateDescription' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.cell, classes.mediumCell)}
                          >
                            {StatusChip(event.stateDescription)}
                          </TableCell>
                        )}
                        {g.columnDataName === 'showAlert' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.cell, classes.mediumCell)}
                          >
                            {event.alertText !== '' && (
                              <Tooltip title={event.alertText}>
                                <ReportProblemOutlined className="warn" />
                              </Tooltip>
                            )}
                          </TableCell>
                        )}
                        {g.columnDataName === 'eventDate' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.cell, classes.dateCell)}
                          >
                            {format(
                              Date.parse(event.eventDate),
                              'dd LLLL, yyyy hh:mm a',
                              {
                                locale: esLocale,
                              },
                            )}
                          </TableCell>
                        )}
                        {g.columnDataName === 'scheduleDate' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.cell, classes.dateCell)}
                          >
                            {format(
                              new Date(event.scheduleDate ?? event.eventDate),
                              'dd LLLL, yyyy hh:mm a',
                              {
                                locale: esLocale,
                              },
                            )}
                          </TableCell>
                        )}
                        {g.columnDataName === 'daysOpen' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.smallCell)}
                          >
                            {event.daysOpen}
                          </TableCell>
                        )}
                        {g.columnDataName === 'lastModified' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.smallCell)}
                          >
                            {event.lastModified}
                          </TableCell>
                        )}
                        {g.columnDataName === 'claimNumber' && (
                          <TableCell
                            align="center"
                            className={clsx(
                              classes.cell,
                              classes.claimNumberCell,
                            )}
                          >
                            {event.claimNumber ?? event.eventRecord}
                          </TableCell>
                        )}
                        {g.columnDataName === 'indRespAAP' &&
                          ![CountryCode.PAN, CountryCode.PRI].includes(
                            getCurrentCountry(),
                          ) && (
                            <TableCell align="center" className={classes.cell}>
                              <Switch
                                checked={event.indRespAAP}
                                readOnly
                                color="primary"
                              />
                            </TableCell>
                          )}
                        {g.columnDataName === 'indExpressAdjust' &&
                          ![CountryCode.PRI].includes(getCurrentCountry()) && (
                            <TableCell align="center" className={classes.cell}>
                              <SimpleChip
                                label={event.indExpressAdjust ? 'Sí' : 'No'}
                                color={
                                  event.indExpressAdjust ? 'green' : 'gray'
                                }
                                compact
                              />
                            </TableCell>
                          )}
                        {[CountryCode.PRI].includes(getCurrentCountry()) && (
                          <>
                            {g.columnDataName === 'indEstimate' && (
                              <TableCell
                                align="center"
                                className={classes.cell}
                              >
                                <SimpleChip
                                  label={getEstimationLabel(event.indEstimate)}
                                  color={getEstimationColor(event.indEstimate)}
                                  compact
                                />
                              </TableCell>
                            )}

                            {g.columnDataName === 'audited' && (
                              <TableCell
                                align="center"
                                className={classes.cell}
                              >
                                {event.audited && (
                                  <Check style={{ color: globalCss.fern }} />
                                )}
                              </TableCell>
                            )}
                          </>
                        )}
                        {g.columnDataName === 'TagName' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.cell, classes.smallCell)}
                          >
                            <Chip
                              className={clsx('event__tag', {
                                'event__tag--empty': !event.tagId,
                              })}
                              clickable={true}
                              label={event.tagName || 'Sin tag'}
                              onClick={(
                                element: React.MouseEvent<HTMLElement>,
                              ) => {
                                element.stopPropagation();
                                setTagAnchorEl(element.currentTarget);
                                setTagPicker(event.eventId);
                              }}
                            />
                            <TagPicker
                              anchorEl={tagAnchorEl}
                              value={{ id: event.tagId, name: event.tagName }}
                              onClose={() => setTagPicker(null)}
                              onChange={(value) => {
                                setEventTag(event.eventId, value?.id);
                              }}
                              open={tagPicker === event.eventId}
                            />
                          </TableCell>
                        )}
                        {![
                          'alertType',
                          'claimNumber',
                          'statusName',
                          'stateDescription',
                          'daysOpen',
                          'lastModified',
                          'eventDate',
                          'scheduleDate',
                          'indExpressAdjust',
                          'indRespAAP',
                          'indEstimate',
                          'audited',
                          'TagName',
                          'showAlert',
                        ].includes(g.columnDataName) && (
                          <TableCell align="center" className={classes.cell}>
                            {event[g.columnDataName]}
                          </TableCell>
                        )}
                      </Fragment>
                    );
                  }

                  return null;
                })}

              {agentAssignPermission() && isDesktop && (
                <TableCell
                  align="center"
                  className={clsx(classes.cell, classes.smallCell)}
                >
                  <Box className="user-agent">
                    <Avatar
                      size="small"
                      name={event.assignedUserName}
                      userIndex={USERS.findIndex(
                        (u) => u === event.assignedUserId,
                      )}
                      onClick={() => {
                        setPickerEvent(index);
                      }}
                    />
                    <UserPicker
                      hidden={pickerEvent !== index}
                      onChange={(agent) =>
                        setAssignedUser(
                          event.eventId,
                          agent?.id,
                          agent?.name,
                          agent?.email,
                        )
                      }
                      onClose={() => setPickerEvent(null)}
                      assignedUser={{
                        id: event.assignedUserId,
                        name: event.assignedUserName,
                        email: event.assignedUserEmail,
                      }}
                      companyId={event.companyId}
                    />
                  </Box>
                </TableCell>
              )}

              {isDesktop && columnActionPermission && (
                <TableCell align="center" className={clsx(classes.mediumCell)}>
                  <IconButton onClick={(e) => handleClick(e, event)}>
                    <MoreVertOutlined className={classes.menuIcon} />
                  </IconButton>
                  {selectedRow && (
                    <TableMenu
                      anchorElement={selectedRow.el}
                      menuOptions={rowMenuWithPermission(selectedRow)}
                      onClickMenu={(e) => handleClick(e, event)}
                      onCloseMenu={handleClose}
                      currentValue={selectedRow.event}
                    />
                  )}
                </TableCell>
              )}

              {notificationPermission() && isDesktop && (
                <TableCell
                  align="center"
                  className={clsx(classes.cell, classes.smallCell)}
                >
                  <IconButton onClick={() => props.onClickNote(event)}>
                    <SmsOutlined />
                    <span
                      hidden={event.nonAttended === 0}
                      className="notification-dot"
                    ></span>
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </>
    );
  };

  return (
    <>
      <TableContainer className="table-container">
        <Table
          className={classes.table}
          size={isDesktop ? 'medium' : 'small'}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>{headerColumns(classes)}</TableRow>
          </TableHead>
          <TableBody>{bodyColumns(props.events)}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        labelDisplayedRows={() =>
          localStorage.getItem('countryCode')?.toString() === 'PRI'
            ? props.paginator?.rows === 0
              ? 'Cargando...'
              : `${props.paginator?.initial} - ${props.paginator?.final} de ${props.paginator?.rows}`
            : `Página ${props.page + 1}`
        }
        count={
          localStorage.getItem('countryCode')?.toString() === 'PRI'
            ? props.paginator?.rows
            : props.totalElements || -1
        }
        rowsPerPage={20}
        page={props.page}
        onPageChange={props.onChangePage}
      />
      <Dialog open={showConfirmEventModal}>
        <DialogTitle id="confirm-cancel-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-cancel-modal"
            style={{ width: '400px', height: '70px' }}
          >
            ¿Está seguro que desea cancelar el evento?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setShowConfirmEventModal(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={handleClickConfirmlCancel}
          >
            Sí, confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showCancelEventModal} className="modal-for-cancelOrRestore">
        <DialogTitle id="cancel-event-title">Cancelación de Evento</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="cancel-event-modal"
            style={{ width: '400px', height: 'auto', overflowY: 'hidden' }}
          >
            {showRequireMsg && (
              <>
                <Alert severity="warning">
                  El <b>motivo / razón</b> para procesar la solicitud{' '}
                  <b>es requerida</b>.
                </Alert>
                <br />
              </>
            )}
            <TextField
              key="cancelDescription"
              className={clsx('inspectionForm__input')}
              name="cancelDescription"
              label="Digite la razón por la que cancela el evento..."
              variant="outlined"
              size="medium"
              multiline={true}
              rows={3}
              onChange={(event) => {
                setCancelReason(event.target.value);
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setShowCancelEventModal(false)}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => handleClickModalCancel()}
            color="primary"
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showCreateSufixModal}>
        <DialogTitle id="create-sufix-title">Crear Sufijo Terceros</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="create-sufix-modal"
            style={{ width: '400px', height: 'auto', overflowY: 'hidden' }}
          >
            <DataTable
              columns={columnsThirds}
              data={listThirds}
              getRowId={(row) => row?.eventThirdId}
              disableRowSelectionClick={false}
              onRowSelectionModelChange={(rows: any): void =>
                setSelectionFields(rows)
              }
            ></DataTable>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setShowCreateSufixModal(false);
              setSelectionFields([]);
            }}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disableElevation
            disabled={selectionFields.length === 0}
            onClick={handleCreateSufix}
            color="primary"
          >
            Crear Sufijo
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showSendMailModal} className="modal-for-cancelOrRestore">
        <DialogTitle id="cancel-event-title">
          Reenviar mensaje Claim Tracker
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="cancel-event-modal"
            style={{ width: '400px', height: 'auto', overflowY: 'hidden' }}
          >
            {showRequireMsg && (
              <>
                <Alert severity="warning">
                  El <b>correo / teléfono es requerido</b>.
                </Alert>
                <br />
              </>
            )}
            <p>Por favor verifica que los datos sean correctos</p>
            <TextField
              value={txtEmailSend}
              key="email"
              name="email"
              label="Digite el correo"
              variant="outlined"
              onChange={onChangeMailField}
            />
            <TextField
              value={txtPhoneSend}
              key="phone"
              name="phone"
              label="Digite el teléfono"
              variant="outlined"
              onChange={onChangePhoneField}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setShowSendMailModal(false)}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            disabled={disabledSendMail}
            variant="contained"
            disableElevation
            onClick={() => handleClickModalSendMail()}
            color="primary"
          >
            Reenviar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showRestoreModal} className="modal-for-cancelOrRestore">
        <DialogTitle id="restore-event-title">
          Restauración del Evento
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="restore-event-modal"
            style={{ width: '400px', height: 'auto', overflowY: 'hidden' }}
          >
            {showRequireMsg && (
              <>
                <Alert severity="warning">
                  El <b>motivo / razón</b> para procesar la solicitud{' '}
                  <b>es requerida</b>.
                </Alert>
                <br />
              </>
            )}
            <TextField
              key="restoreDescription"
              className={clsx('inspectionForm__input')}
              name="restoreDescription"
              label="Digite una razón para restaurar el evento..."
              variant="outlined"
              size="medium"
              multiline={true}
              rows={3}
              onChange={(event) => {
                setRestoreReason(event.target.value);
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setShowRestoreModal(false)}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => handleClickModalRestore()}
            color="primary"
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showConfirmRestoreModal}>
        <DialogTitle id="confirm-restore-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-restore-modal"
            style={{ width: '400px', height: '120px' }}
          >
            <Alert severity="warning">
              <b>Nota:</b> El evento se restaurará al último estado anterior a
              la cancelación.
            </Alert>
            <br />
            ¿Está seguro que desea restaurar este evento?
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setShowConfirmRestoreModal(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={handleClickConfirmlRestore}
          >
            Sí, confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventsTable;
