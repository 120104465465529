import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { globalCss } from 'assets/connect/styles/_variables';
import clsx from 'clsx';
import ButtonClaim from 'components/claims/button';
import Avatar from 'components/common/avatar/Avatar';
import { editNote } from 'context/inspections/inspectionUtils';
import { IUserContext, UserContext } from 'context/user';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import React, { forwardRef, useContext, useState } from 'react';
import { NoteType } from 'types/common';
import { EventNote } from 'types/event-note';
import './styles.scss';

interface Props {
  isInspectorForm: boolean;
  note: EventNote;
  notes: EventNote[];
  toggleAttended: () => void;
  onRefresh: (eventId: number) => void;
  noteTypes: NoteType[];
}

const NoteRow = React.memo(
  forwardRef<HTMLDivElement, Props>(
    (props: Props, ref): JSX.Element => {
      const [isOpen, setIsOpen] = useState(false);
      const iconStyle = { fill: `${globalCss.boulder}` };
      const {
        state: { currentUser },
        dispatch: userDispatch,
      } = useContext(UserContext) as IUserContext;
      const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
      const [documentObservation, setDocumentObservation] = useState<string>(
        props.note.commentText,
      );
      const [noteTypeSelected, setNoteTypeSelected] = React.useState(
        props.note.noteTypeId ?? 0,
      );

      const handleToggleRow = () => {
        setIsOpen(!isOpen);
      };

      // Used to give some color to the avatar bubble
      const getUserIndex = (userId: number): number => {
        return props.notes
          .flatMap((n) => n.userId)
          .filter((n, i, self) => self.indexOf(n) === i)
          .findIndex((n) => n === userId);
      };

      const variant = props.note.attended ? 'contained' : 'outlined';

      const handleNoteTypeChange = (event) => {
        const selectedValue = event.target.value;
        setNoteTypeSelected(selectedValue);
        console.warn(props.noteTypes);
      };

      const handleEditNote = async () => {
        const editedComment = {
          eventId: props.note.eventId,
          userId: Number(localStorage.getItem('userId')),
          commentText: documentObservation,
          commentId: props.note.commentId,
          noteTypeId: Number(noteTypeSelected),
        };

        setOpenConfirmation(false);
        const resp = await editNote(
          props.note.eventId,
          props.note.commentId,
          editedComment,
        );

        //Espera de 800ms mientras actualiza en BD
        await new Promise((resolve) => setTimeout(resolve, 800));

        props.onRefresh(props.note.eventId);
      };

      return (
        <div
          ref={ref as React.RefObject<HTMLDivElement>}
          className="note"
          onClick={handleToggleRow}
        >
          <div className="note__header">
            <div className="note__header__info">
              <Avatar
                size="small"
                name={props.note.userName}
                userIndex={getUserIndex(props.note.userId)}
              />
              <div className="note__user-info">
                <p className="note__infoText note__infoText--bold">
                  {props.note.userName}
                </p>
                <p className="note__infoText note__infoText--light">
                  {format(
                    new Date(props.note.creationDate),
                    'dd/MM/yyyy HH:mm:ss',
                    {
                      locale: esLocale,
                    },
                  )}
                </p>
              </div>
              {props.note.modifiedDate ? (
                <div style={{ marginLeft: '10px', color: '#3c546e' }}>
                  <b>(editado)</b>
                </div>
              ) : null}
            </div>
            <div>
              {props.note.userId === currentUser.userCompanyID &&
              props.note.editable ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  onClick={() => setOpenConfirmation(true)}
                  style={{ marginRight: '15px' }}
                >
                  Editar
                </Button>
              ) : null}
              {!props.isInspectorForm && (
                <Button
                  variant={variant}
                  color="secondary"
                  disableElevation
                  onClick={props.toggleAttended}
                >
                  Atendido
                </Button>
              )}
            </div>
          </div>
          <div className="note__header__info note__header__info--spaceItems">
            <p
              className={clsx('note__message', {
                'note__message--open': isOpen,
              })}
            >
              {props.note.commentText}
            </p>
            {isOpen ? (
              <KeyboardArrowDown fontSize="large" style={iconStyle} />
            ) : (
              <ChevronRight fontSize="large" style={iconStyle} />
            )}
          </div>

          <Dialog
            open={openConfirmation}
            className="note__editmodal"
            maxWidth={'sm'}
            fullWidth={true}
          >
            <DialogTitle id="confirm-restore-title">Editar Nota</DialogTitle>
            <DialogContent>
              <DialogContentText
                id="confirm-payment-modal"
                className="gallerySelector_NotesModal"
              >
                <div className="notesModal__clasification">
                  <div className="labelClasification">Tipo de Nota: </div>
                  <div>
                    <select
                      name="noteTypes"
                      id="noteTypes"
                      className="selectClasification"
                      value={noteTypeSelected}
                      onChange={handleNoteTypeChange}
                    >
                      {props.noteTypes?.map((noteType) => (
                        <option key={noteType.id} value={noteType.id}>
                          {noteType.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <TextField
                  style={{ width: '100%', marginTop: '15px' }}
                  name="customerResponsabilityAmount"
                  className="claim-adjust__data--row--input gallerySelector_NotesText"
                  label="Edita la nota..."
                  value={documentObservation}
                  multiline
                  rows={7}
                  InputProps={{
                    inputProps: {
                      style: { fontSize: '0.8em' }, // Tamaño de letra deseado
                    },
                  }}
                  onChange={(e) => {
                    setDocumentObservation(e.target.value);
                  }}
                ></TextField>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonClaim
                text="Cancelar"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setDocumentObservation(props.note.commentText);
                  setNoteTypeSelected(props.note.noteTypeId ?? 0);
                  setOpenConfirmation(false);
                }}
              />

              <ButtonClaim
                text="Guardar"
                variant="contained"
                color="primary"
                onClick={handleEditNote}
              />
            </DialogActions>
          </Dialog>
        </div>
      );
    },
  ),
);

export default NoteRow;
