import { Option } from 'types/option';

const getTags = (): Option[] => {
  let tags: Option[] = [];
  const tagsStr = localStorage.getItem('tags');

  if (tagsStr) {
    const rawData: { tagId: number; tagName: string }[] = Array.from(
      JSON.parse(tagsStr),
    );
    tags = rawData.map((t) => ({
      id: t.tagId,
      name: t.tagName,
    }));
  }

  return tags;
};

export default getTags;
