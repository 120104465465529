import { makeStyles, styled } from '@mui/styles';
import { Theme } from '@mui/material/styles';
declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default makeStyles((theme: Theme) => ({
  input: {
    marginBottom: '20px',
    /*[theme.breakpoints.up('md')]: {
      marginRight: '10px',
      maxWidth: '270px',
    },*/
  },
  textarea: {
    marginBottom: '20px',
    /*[theme.breakpoints.up('md')]: {
      marginRight: '10px',
      maxWidth: '550px',
    },*/
  },
  button: {
    borderRadius: '38px',
    textTransform: 'none',
  },
  tab: {
    root: {
      borderRadius: '10px',
    },
  },
}));
