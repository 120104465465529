import { createTheme } from '@mui/material/styles';
import { globalCss } from './styles/_variables';

const theme = createTheme({
  palette: {
    primary: {
      main: globalCss.primary,
    },
    secondary: {
      main: globalCss.blueBayoux,
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
});

export default theme;
