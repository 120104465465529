import { ICoverage, IInspectionState } from 'types/inspection';
import { FieldSection } from 'types/section-fields';
import { ActionType, DispatchFunction } from 'types/action';
import {
  AltaReporteInput,
  AltaReporteOutput,
  AltaSiniestroInput,
  AltaSiniestroOutput,
  QualitasUpdateModel,
} from 'types/AltaReporteInput';
import {
  postAltaReporte,
  postAltaSiniestro,
  updateQualitas,
} from 'context/inspections/inspectionUtils';
import { IEventData } from 'types/claimsTracker';

export const siniestrar = async (
  inspectionState: IInspectionState,
  sections: FieldSection[],
  inspectDispatch: DispatchFunction,
): Promise<void> => {
  // Get SISE and event data
  const eventId =
    inspectionState?.currentInspection?.EventId !== undefined
      ? inspectionState?.currentInspection?.EventId
      : 0;
  const codCierre =
    inspectionState?.currentInspection?.ReasonId !== undefined
      ? inspectionState?.currentInspection?.ReasonId
      : '0';
  const coverages = inspectionState.currentInspection?.coverages;
  let altaReporte =
    inspectionState?.currentInspection?.siseReport !== undefined
      ? inspectionState?.currentInspection?.siseReport
      : '';
  let altaSiniestro =
    inspectionState?.currentInspection?.siseSiniestro !== undefined
      ? inspectionState?.currentInspection?.siseSiniestro
      : '';
  const notCoverages = coverages
    ? coverages.filter((f: any) => String(f.coverageId) === '0').length
    : 0;

  // Validate Causa
  if (codCierre.length === 0 || codCierre === '0') {
    inspectDispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: true,
        alertMessage:
          '<b><i>Causa</i> es requerida</b> para el proceso de siniestrar.',
        alertType: 'warning',
      },
    });

    return;
    // Validate Coverages
  } else if (!coverages || coverages.length === 0) {
    inspectDispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: true,
        alertMessage:
          'Para el <b><i>proceso de siniestrar</i></b> debes cumplir con <b>al menos una de las 2 validaciones</b>: <br/>' +
          '<ul><li>Debe seleccionar <b><i>al menos una cobertura</i>.</b>' +
          '<li>Debes seleccionar que <b><i>NO APLICA COBERTURA</i>.</b>' +
          '</ul>',
        alertType: 'warning',
      },
    });

    return;
    // View the loader
  } else {
    inspectDispatch({ type: ActionType.SET_LOADING, payload: true });
  }

  // Execute the process
  setTimeout(async () => {
    // Obtner coberturas seleccionadas
    let coveragesStr = '';
    if (notCoverages > 0) {
      coveragesStr = '0¬';
    } else if (notCoverages === 0) {
      const coverages = sections
        ?.find((s) => s.formSectionId === 113)
        ?.fields.find((f) => f.name === 'coverageList');
      const list = JSON.parse(coverages?.value);
      for (const v of list) {
        coveragesStr = coveragesStr + v.id + '¬';
      }
    }

    if (altaReporte === '') {
      // Generar Alta Reporte y Alta siniestro
      const reporteInput: AltaReporteInput = {
        eventId: String(eventId),
        codCierre: String(codCierre),
      };

      const reporteOutput: AltaReporteOutput = await postAltaReporte(
        reporteInput,
      );
      if (String(reporteOutput.codError).toUpperCase() === 'OK') {
        altaReporte = reporteOutput.numReporte;

        if (Number(altaReporte) === 0) {
          altaSiniestro = '0000000000';

          if (altaReporte !== '' && altaSiniestro !== '') {
            const updateQualitasRequest: QualitasUpdateModel = {
              eventId: eventId,
              altaReporte: altaReporte,
              altaSiniestro: altaSiniestro,
              userInspectorId: Number(localStorage.getItem('userId')),
              message: `AR: ${altaReporte} AS: ${altaSiniestro}`,
            };
            await updateQualitas(updateQualitasRequest);
            inspectDispatch({
              type: ActionType.SET_GENERAL_ALERT,
              payload: {
                showAlert: true,
                alertMessage: 'Siniestralidad generada con éxito',
                alertType: 'success',
              },
            });

            inspectDispatch({ type: ActionType.SET_LOADING, payload: false });

            return;
          }
        } else {
          // Generar solo alta siniestro
          // Si es estado procede o revisar invocar Alta Siniestro
          if (
            inspectionState?.currentInspection?.Procede === 'PROCEDE' ||
            inspectionState?.currentInspection?.Procede === 'REVISAR'
          ) {
            // Invocar alta siniestro
            const siniestroInput: AltaSiniestroInput = {
              eventId: String(eventId),
              AltaReporte: altaReporte,
              Coberturas: coveragesStr.slice(0, -1),
              codCierre: String(codCierre),
            };
            const siniestroOutput: AltaSiniestroOutput = await postAltaSiniestro(
              siniestroInput,
            );

            if (String(siniestroOutput.codError).toUpperCase() === 'OK') {
              altaSiniestro = siniestroOutput.numReporte;

              if (altaReporte !== '' && altaSiniestro !== '') {
                const updateQualitasRequest: QualitasUpdateModel = {
                  eventId: eventId,
                  altaReporte: altaReporte,
                  altaSiniestro: altaSiniestro,
                  userInspectorId: Number(localStorage.getItem('userId')),
                  message: `AR: ${altaReporte} AS: ${altaSiniestro}`,
                };
                await updateQualitas(updateQualitasRequest);
                inspectDispatch({
                  type: ActionType.SET_GENERAL_ALERT,
                  payload: {
                    showAlert: true,
                    alertMessage: 'Siniestralidad generada con éxito',
                    alertType: 'success',
                  },
                });
              }
            } else {
              const updateQualitasRequest: QualitasUpdateModel = {
                eventId: eventId,
                altaReporte: altaReporte,
                altaSiniestro: 'Se genera error en AS',
                userInspectorId: Number(localStorage.getItem('userId')),
                message: siniestroOutput.codError,
              };
              await updateQualitas(updateQualitasRequest);
              inspectDispatch({
                type: ActionType.SET_GENERAL_ALERT,
                payload: {
                  showAlert: true,
                  alertMessage:
                    'Error generando siniestralidad: ' +
                    siniestroOutput.codError,
                  alertType: 'error',
                },
              });
            }

            inspectDispatch({ type: ActionType.SET_LOADING, payload: false });
          }
        }
      } else {
        inspectDispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    } else {
      // Generar solo alta siniestro
      // Si es estado procede o revisar invocar Alta Siniestro
      if (
        inspectionState?.currentInspection?.Procede === 'PROCEDE' ||
        inspectionState?.currentInspection?.Procede === 'REVISAR'
      ) {
        // Invocar alta siniestro
        const siniestroInput: AltaSiniestroInput = {
          eventId: String(eventId),
          AltaReporte: altaReporte,
          Coberturas: coveragesStr.slice(0, -1),
          codCierre: String(codCierre),
        };
        const siniestroOutput: AltaSiniestroOutput = await postAltaSiniestro(
          siniestroInput,
        );

        if (String(siniestroOutput.codError).toUpperCase() === 'OK') {
          altaSiniestro = siniestroOutput.numReporte;

          if (altaReporte !== '' && altaSiniestro !== '') {
            const updateQualitasRequest: QualitasUpdateModel = {
              eventId: eventId,
              altaReporte: altaReporte,
              altaSiniestro: altaSiniestro,
              userInspectorId: Number(localStorage.getItem('userId')),
              message: `AR: ${altaReporte} AS: ${altaSiniestro}`,
            };
            await updateQualitas(updateQualitasRequest);
            inspectDispatch({
              type: ActionType.SET_GENERAL_ALERT,
              payload: {
                showAlert: true,
                alertMessage: 'Siniestralidad generada con éxito',
                alertType: 'success',
              },
            });
          }
        } else {
          const updateQualitasRequest: QualitasUpdateModel = {
            eventId: eventId,
            altaReporte: altaReporte,
            altaSiniestro: 'Se genera error en AS',
            userInspectorId: Number(localStorage.getItem('userId')),
            message: siniestroOutput.codError,
          };
          await updateQualitas(updateQualitasRequest);
          inspectDispatch({ type: ActionType.SET_LOADING, payload: false });
          inspectDispatch({
            type: ActionType.SET_GENERAL_ALERT,
            payload: {
              showAlert: true,
              alertMessage:
                'Error generando siniestralidad: ' + siniestroOutput.codError,
              alertType: 'error',
            },
          });
        }

        inspectDispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    }
  }, 4000);
};

export const sinisterClaims = async (
  data: IEventData | undefined,
  inspectDispatch: DispatchFunction,
): Promise<boolean> => {
  // Get SISE and event data
  const eventId = data?.event?.EventId ?? 0;
  const codCierre = data?.event?.ReasonId ?? '0';
  const coverages = data?.event?.coverages ?? data?.qualitasCoverages;
  let altaReporte = data?.event?.siseReport ?? '';
  let altaSiniestro = data?.event?.siseSiniestro ?? '';

  const notCoverages = coverages
    ? coverages.filter(
        (f: ICoverage) => String(f.coverageId) === '0' && f.Selected,
      ).length
    : 0;

  // Validate Causa
  if (codCierre.length === 0 || codCierre === '0') {
    inspectDispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: true,
        alertMessage:
          '<b><i>Causa</i> es requerida</b> para el proceso de siniestrar.',
        alertType: 'warning',
      },
    });

    return false;
    // Validate Coverages
  } else if (!coverages || coverages.length === 0) {
    inspectDispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: true,
        alertMessage:
          'Para el <b><i>proceso de siniestrar</i></b> debes cumplir con <b>al menos una de las 2 validaciones</b>: <br/>' +
          '<ul><li>Debe seleccionar <b><i>al menos una cobertura</i>.</b>' +
          '<li>Debes seleccionar que <b><i>NO APLICA COBERTURA</i>.</b>' +
          '</ul>',
        alertType: 'warning',
      },
    });

    return false;
    // View the loader
  } else {
    inspectDispatch({ type: ActionType.SET_LOADING, payload: true });
  }

  // Execute the process
  // Obtner coberturas seleccionadas
  let coveragesStr = '';
  if (notCoverages > 0) {
    coveragesStr = '0¬';
  } else if (notCoverages === 0) {
    const list = coverages.filter((coverage) => coverage.Selected === true);
    if (list) {
      for (const v of list) {
        coveragesStr = coveragesStr + v.coverageId.toString() + '¬';
      }
    }
  }

  if (altaReporte === '') {
    // Generar Alta Reporte y Alta siniestro
    const reporteInput: AltaReporteInput = {
      eventId: String(eventId),
      codCierre: String(codCierre),
    };

    const reporteOutput: AltaReporteOutput = await postAltaReporte(
      reporteInput,
    );

    if (String(reporteOutput.codError).toUpperCase() === 'OK') {
      altaReporte = reporteOutput.numReporte;

      if (Number(altaReporte) === 0) {
        altaSiniestro = '0000000000';

        const updateQualitasRequest: QualitasUpdateModel = {
          eventId: eventId,
          altaReporte: altaReporte,
          altaSiniestro: altaSiniestro,
          userInspectorId: Number(localStorage.getItem('userId')),
          message: `AR: ${altaReporte} AS: ${altaSiniestro}`,
        };
        await updateQualitas(updateQualitasRequest);
        inspectDispatch({
          type: ActionType.SET_GENERAL_ALERT,
          payload: {
            showAlert: true,
            alertMessage: 'Siniestralidad generada con éxito',
            alertType: 'success',
          },
        });

        inspectDispatch({ type: ActionType.SET_LOADING, payload: false });

        return true;
      } else {
        // Generar solo alta siniestro
        // Si es estado procede o revisar invocar Alta Siniestro
        let result = true;

        if (
          data?.event?.Procede === 'PROCEDE' ||
          data?.event?.Procede === 'REVISAR'
        ) {
          // Invocar alta siniestro
          const siniestroInput: AltaSiniestroInput = {
            eventId: String(eventId),
            AltaReporte: altaReporte,
            Coberturas: coveragesStr.slice(0, -1),
            codCierre: String(codCierre),
          };
          const siniestroOutput: AltaSiniestroOutput = await postAltaSiniestro(
            siniestroInput,
          );

          if (String(siniestroOutput.codError).toUpperCase() === 'OK') {
            altaSiniestro = siniestroOutput.numReporte;

            if (altaReporte !== '' && altaSiniestro !== '') {
              const updateQualitasRequest: QualitasUpdateModel = {
                eventId: eventId,
                altaReporte: altaReporte,
                altaSiniestro: altaSiniestro,
                userInspectorId: Number(localStorage.getItem('userId')),
                message: `AR: ${altaReporte} AS: ${altaSiniestro}`,
              };
              await updateQualitas(updateQualitasRequest);
              inspectDispatch({
                type: ActionType.SET_GENERAL_ALERT,
                payload: {
                  showAlert: true,
                  alertMessage: 'Siniestralidad generada con éxito',
                  alertType: 'success',
                },
              });
            }
          } else {
            const updateQualitasRequest: QualitasUpdateModel = {
              eventId: eventId,
              altaReporte: altaReporte,
              altaSiniestro: 'Se genera error en AS',
              userInspectorId: Number(localStorage.getItem('userId')),
              message: siniestroOutput.codError,
            };
            await updateQualitas(updateQualitasRequest);
            inspectDispatch({
              type: ActionType.SET_GENERAL_ALERT,
              payload: {
                showAlert: true,
                alertMessage:
                  'Error generando siniestralidad: ' + siniestroOutput.codError,
                alertType: 'error',
              },
            });

            result = false;
          }

          inspectDispatch({ type: ActionType.SET_LOADING, payload: false });
        }

        return result;
      }
    } else {
      inspectDispatch({ type: ActionType.SET_LOADING, payload: false });

      return true;
    }
  } else {
    // Generar solo alta siniestro
    // Si es estado procede o revisar invocar Alta Siniestro
    let result = true;

    if (
      data?.event?.Procede === 'PROCEDE' ||
      data?.event?.Procede === 'REVISAR'
    ) {
      // Invocar alta siniestro
      const siniestroInput: AltaSiniestroInput = {
        eventId: String(eventId),
        AltaReporte: altaReporte,
        Coberturas: coveragesStr.slice(0, -1),
        codCierre: String(codCierre),
      };
      const siniestroOutput: AltaSiniestroOutput = await postAltaSiniestro(
        siniestroInput,
      );

      if (String(siniestroOutput.codError).toUpperCase() === 'OK') {
        altaSiniestro = siniestroOutput.numReporte;

        if (altaReporte !== '' && altaSiniestro !== '') {
          const updateQualitasRequest: QualitasUpdateModel = {
            eventId: eventId,
            altaReporte: altaReporte,
            altaSiniestro: altaSiniestro,
            userInspectorId: Number(localStorage.getItem('userId')),
            message: `AR: ${altaReporte} AS: ${altaSiniestro}`,
          };
          await updateQualitas(updateQualitasRequest);
          inspectDispatch({
            type: ActionType.SET_GENERAL_ALERT,
            payload: {
              showAlert: true,
              alertMessage: 'Siniestralidad generada con éxito',
              alertType: 'success',
            },
          });
        }
      } else {
        const updateQualitasRequest: QualitasUpdateModel = {
          eventId: eventId,
          altaReporte: altaReporte,
          altaSiniestro: 'Se genera error en AS',
          userInspectorId: Number(localStorage.getItem('userId')),
          message: siniestroOutput.codError,
        };
        await updateQualitas(updateQualitasRequest);
        inspectDispatch({ type: ActionType.SET_LOADING, payload: false });
        inspectDispatch({
          type: ActionType.SET_GENERAL_ALERT,
          payload: {
            showAlert: true,
            alertMessage:
              'Error generando siniestralidad: ' + siniestroOutput.codError,
            alertType: 'error',
          },
        });

        result = false;
      }

      inspectDispatch({ type: ActionType.SET_LOADING, payload: false });
    }

    return result;
  }
};
