// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Agencies = () => {
  const dataAgenciesStr = localStorage.getItem('agencies');
  let data: any[] = [];

  if (dataAgenciesStr) {
    const localData: any = Array.from(JSON.parse(dataAgenciesStr));
    data = localData
      .map(
        (agency: { id: number; runnerCode: string; runnerName: string }) => ({
          id: agency.id,
          name: agency.runnerName,
        }),
      )
      .sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
  }

  return data;
};

export default Agencies;
