import { useLocation } from 'react-router-dom';
import { ActionType } from 'types/action';
import filterReducer from './filtersReducer';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
} from 'react';

export interface Params {
  companyId?: number;
  inspectorId?: number;
  inspectorForm?: boolean;
  initialDate?: Date | string;
  finalDate?: Date | string;
  page?: number;
  eventRecord?: string;
  plate?: string;
  filterType?: string;
  filterText?: string;
  statusId?: number;
  countryCode?: any;
  filterByExpressAdjust?: boolean | null;
  filterByAlert?: boolean | null;
  filterByAAP?: boolean | null;
  tagId?: number;
  agencyId?: number;
  sort?: string;
  direction?: 'asc' | 'desc';
  logAgentId?: number;
  filterBySubrogation?: boolean;
  filterByTotalLoss?: boolean;
}

export interface IFiltersContext {
  queryFilters: Params;
  searchQuery: string | null;
  setQueryFilters: (params: Params) => void;
}

export const FiltersContext = React.createContext<IFiltersContext | any>({});

export const FiltersProvider: FunctionComponent = (props): JSX.Element => {
  const [queryFilters, dispatch] = React.useReducer(filterReducer, {});
  const prevQueryFilters = usePrevious(queryFilters);

  const obj: any = {};
  const { search } = useLocation();
  search
    .replace('?', '')
    .split('&')
    .forEach((s) => {
      if (s !== '') {
        const [key, value] = s.split('=');
        obj[key] =
          ['plate', 'eventRecord'].includes(key) || Number.isNaN(+value)
            ? value
            : +value;
      }
    });

  const setQueryFilters = (params: any) => {
    const qFilters = { ...queryFilters, ...params };

    if (!qFilters.initialDate) {
      delete qFilters.finalDate;
    }
    for (const key of Object.keys(qFilters)) {
      if (qFilters[key] === undefined || qFilters[key] === '') {
        delete qFilters[key];
      }
    }

    dispatch({ type: ActionType.SET_QUERY_FILTERS, payload: { ...qFilters } });
  };

  const getSearchQuery = useCallback(() => {
    if (JSON.stringify(prevQueryFilters) === JSON.stringify(queryFilters)) {
      return '';
    }
    const _params: any = { ...queryFilters };
    const searchQuery = `${Object.keys(_params)
      .map((key) => `${key}=${_params[key]}`)
      .join('&')}`;

    return searchQuery ? `?${searchQuery}` : null;
  }, [prevQueryFilters, queryFilters]);

  return (
    <FiltersContext.Provider
      value={{
        queryFilters,
        setQueryFilters,
        searchQuery: getSearchQuery(),
      }}
    >
      {props.children}
    </FiltersContext.Provider>
  );
};

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};
