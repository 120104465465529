import React, { FunctionComponent, useEffect, useRef } from 'react';
import APIClient, { endpoints } from 'shared/api';
import { EventNote } from 'types/event-note';
import NoteRow from './note-row/NoteRow';
import { NoteType } from 'types/common';

interface NotesListProps {
  isInspectorForm?: boolean;
  notes: EventNote[];
  onRefresh?: (eventId: number) => void;
  noteTypes: NoteType[];
}

const NotesList: FunctionComponent<NotesListProps> = ({
  isInspectorForm = false,
  notes,
  onRefresh,
  noteTypes,
}): JSX.Element => {
  const lastElementRef = useRef<HTMLDivElement>();

  const scrollToEnd = () => {
    if (lastElementRef.current) {
      lastElementRef.current.scrollIntoView({ block: 'end' });
    }
  };

  const previousNotes = useRef<EventNote[]>([]);

  useEffect(() => {
    const previousNotesLength = previousNotes.current.length;
    if (notes.length > previousNotesLength) {
      scrollToEnd();
    }
  }, [notes, previousNotes.current.length]);

  useEffect(() => {
    previousNotes.current = notes;
  }, [notes]);

  const toggleAttended = async (note: EventNote) => {
    await APIClient.patch(
      endpoints.PATCH_COMMENT_ATTENDED.replace(
        '{commentId}',
        String(note.commentId),
      ).replace('{attended}', String(!note.attended)),
    );
    if (onRefresh) {
      onRefresh(note.eventId);
    }
  };

  return (
    <div className="notes_list_body">
      {notes.map((note: EventNote, index: number) => {
        const props: any = {
          isInspectorForm: isInspectorForm,
          note: note,
          notes: notes,
          toggleAttended: () => toggleAttended(note),
          noteTypes: noteTypes,
        };
        if (index === notes.length - 1) {
          props.ref = lastElementRef;
        }

        return (
          <NoteRow key={note.commentId} {...props} onRefresh={onRefresh} />
        );
      })}
    </div>
  );
};

export default NotesList;
