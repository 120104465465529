import './index.scss';
import React, { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { Field } from 'formik';
import {
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  SelectChangeEvent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import DataTable from '../grid';
import { IClaimForm } from 'types/claimsTracker';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import Button from '../button';
import APIClient, { endpoints } from 'shared/api';

interface Props {
  claimUId: string;
  claimForm?: IClaimForm;
}

const SubrogationEventTracking: FunctionComponent<Props> = ({
  claimUId,
  claimForm,
}): JSX.Element => {
  const [dataHistoric, setDataHistoric] = useState<any>([]);
  const [eventTypes, setEventTypes] = useState<any>([]);
  const [eventType, setEventType] = useState<any>();
  const [description, setDescription] = useState<any>();
  const [disabledSave, setDisabledSave] = useState<boolean>(true);

  const columns: GridColDef[] = [
    { field: 'eventType', headerName: 'Tipo de Evento', width: 200 },
    { field: 'user', headerName: 'Usuario', width: 200 },
    { field: 'description', headerName: 'Detalle', width: 400 },
    { field: 'createDate', headerName: 'Fecha de creación', width: 400 },
  ];

  useEffect(() => {
    setDisabledSave(!(description && eventType));
  }, [description, eventType]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [claimForm?.info?.case]);

  const loadData = async () => {
    const data = await new ClaimsTrackerClient().getEventTracking(claimUId);
    setDataHistoric(data);
    const dataEventType = await new ClaimsTrackerClient().getEventTypes();
    setEventTypes(dataEventType);
  };

  const changeEventType = (event) => {
    setEventType(event?.target.value);
  };

  const changeDescription = (event) => {
    setDescription(event?.target.value);
  };

  const saveEvent = async () => {
    await new ClaimsTrackerClient().saveEventTracking(
      claimUId,
      eventType,
      description,
      Number(localStorage.getItem('userId')),
    );
    const data = await new ClaimsTrackerClient().getEventTracking(claimUId);
    setDataHistoric(data);
    setEventType('');
    setDescription('');
  };

  return (
    <>
      <Box className="subrogation">
        <>
          <Grid className="subrogation__historic">
            <FormControl sx={{ minWidth: 235, backgroundColor: '#ffffff' }}>
              <InputLabel id="nonRecoveryCause">Tipo de evento</InputLabel>
              <Select
                autoWidth
                value={eventType ?? 0}
                onChange={changeEventType}
                label="Tipo de evento"
              >
                {eventTypes?.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <br />
          <Grid className="subrogation__historic">
            <TextField
              className="claim-adjust__data--row--input"
              label="Detalle"
              value={description}
              onChange={changeDescription}
            ></TextField>
            <Button
              disabled={disabledSave}
              text="Agregar"
              type="button"
              onClick={saveEvent}
            />
          </Grid>
          <Grid className="subrogation__historic">
            <Grid>
              <p>HISTORIAL</p>
            </Grid>
            <Grid className="subrogation__table">
              <DataTable columns={columns} data={dataHistoric}></DataTable>
            </Grid>
          </Grid>
        </>
      </Box>
    </>
  );
};

export default SubrogationEventTracking;
