import { makeStyles, createStyles } from '@mui/styles';
import { globalCss } from 'assets/connect/styles/_variables';

export default makeStyles(() =>
  createStyles({
    table: {
      minWidth: 100,
      position: 'relative',
    },
    header: {
      color: globalCss.blueClaims,
      fontSize: 16,
      textAlign: 'center',
      backgroundColor: globalCss.orangeLigth,
      fontFamily: 'Connect, sans-serif',
      fontWeight: 200,
    },
    cell: {
      fontSize: 14,
      color: globalCss.blueClaims,
      userSelect: 'none',
      fontFamily: 'Connect, sans-serif',
      fontWeight: 200,
      lineHeight: '18px',
    },
    claimNumberCell: {
      width: '100px',
    },
    dateCell: {
      textTransform: 'capitalize',
      width: '152px',
    },
    smallCell: {
      width: '30px',
      padding: '6px 6px',
    },
    mediumCell: {
      width: '155px',
      boxSizing: 'border-box',
    },
    icon: {
      margin: '0 10px',
    },
    row: {
      backgroundColor: globalCss.white,
      '&:nth-of-type(odd)': {
        backgroundColor: globalCss.concrete,
      },
    },
    rowSelect: {
      '&.investigate': {
        backgroundColor: 'rgba(255,229,5, .25)',
      },
      '&.selected': {
        backgroundColor: globalCss.concrete,
      },
      cursor: 'pointer',
    },
    menuIcon: {
      color: globalCss.alto,
    },
  }),
);
