import './styles.scss';
import InputField from './InputField';
import clsx from 'clsx';
import style from 'override-mui-styles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FieldSection } from 'types/section-fields';
import { filterSectionsByDependencies } from './utils';
import {
  IPreInspectionContext,
  PreInspectionContext,
  savePreInspection,
  sendRemoveExpiration,
  setLoading,
} from 'context/preinspections';
import {
  setFormFieldValue,
  userActionsPermissions,
  validationForm,
} from 'shared/utils';
//import { InspectionDataSections } from 'types/common';
import { Field } from 'types/form-field';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Snackbar,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
  CompanyFolderRoot,
  InspectionDataSections,
  ResourceAction,
  TransitionStatesIds,
} from 'types/common';
import Photos from '../photos';
import Alertas from '../../pre-inspection/alerts';
import { IUserContext, UserContext } from 'context/user';
import DamageAutodetection from 'components/pre-inspection/damage-autodetection';
import EventState from '../event-state';
import { ActionType } from 'types/action';
import Alert from '@mui/material/Alert';

interface Props {
  sections: FieldSection[];
  setSections: (sections: FieldSection[]) => void;
  activeStep: number;
  nextStep: number;
  lastStep: number;
  partnerKey: string;
  preId: string;
  goToBackStep?: (request: boolean) => void;
}

const FormSectionData = ({
  sections,
  setSections,
  activeStep,
  nextStep,
  lastStep,
  partnerKey,
  preId,
  goToBackStep,
}: Props): JSX.Element => {
  const history = useHistory();
  const classes = style();
  const { state: userState } = useContext<IUserContext>(UserContext);
  const { state, dispatch } = useContext<IPreInspectionContext>(
    PreInspectionContext,
  );
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [showGoToTop, setShowGoToTop] = useState<boolean>(false);
  const [showBtnContinue, setShowBtnContinue] = useState<boolean>(true);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showBtnRemoveExpire, setShowBtnRemoveExpire] = useState<boolean>(
    false,
  );
  const [confirmRemoveExpire, setConfirmRemoveExpire] = useState<boolean>(
    false,
  );

  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<any>('info');

  const folderRoot = CompanyFolderRoot.find(
    (config: any) =>
      String(config.key).toLowerCase().indexOf(partnerKey.toLowerCase()) > -1,
  )?.folder;

  const modelPreInspection = state.currentPreInspection;

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const checkScrollTop = () => {
    if (window.pageYOffset > 300) {
      setShowGoToTop(true);
    } else {
      setShowGoToTop(false);
    }
  };
  const readOnlyPermission = Boolean(
    userActionsPermissions(ResourceAction.VIEW_EVENT_READ_ONLY),
  );

  window.addEventListener('scroll', checkScrollTop);

  const filterSections = sections
    .filter((section: FieldSection) => {
      return (
        section.parentSectionId === activeStep &&
        filterSectionsByDependencies(section, sections)
      );
    })
    .sort((a, b) => (a.idOrder > b.idOrder ? 1 : -1));

  const triggerNextStep = (): void => {
    const currentSection = sections.filter(
      (f: FieldSection) => f.parentSectionId === activeStep,
    )[0];

    const isForm =
      currentSection !== undefined &&
      (currentSection.name === undefined ||
        currentSection.name === null ||
        currentSection.name.length === 0);
    const result: any = isForm
      ? validationForm(activeStep, sections)
      : undefined;

    if (isForm && result && result.sections) {
      setSections(result.sections);
    }

    if (nextStep > 0 && (!isForm || (result && result.valid))) {
      history.replace(
        `/preinspections/${partnerKey}/${preId}/sections/${nextStep}`,
      );
    }
  };

  const handleInputChange = (event: any) => {
    if (readOnlyPermission) {
      return;
    }

    const { target } = event;
    setFormFieldValue(
      target,
      dispatch,
      state.currentPreInspection,
      state.formPreInspection,
    );
  };

  const handleSaveForm = () => {
    (async (): Promise<void> => {
      if (!readOnlyPermission) {
        await savePreInspection(Number(preId), state);
      }
    })();
  };

  const handleSaveMedia = () => {
    // eslint-disable-next-line no-console
    console.log('Media File is saved.');
  };

  const validateExpire = useCallback(() => {
    if (
      modelPreInspection &&
      modelPreInspection.preStatusId === TransitionStatesIds.EXPIRED
    ) {
      setShowBtnRemoveExpire(true);
    } else {
      setShowBtnRemoveExpire(false);
    }
  }, [modelPreInspection]);

  const triggerRemoveExpire = async () => {
    const response = await sendRemoveExpiration(
      modelPreInspection?.preInspectionId ?? 0,
    );

    const message = response
      ? '<b>Solicitud enviada satisfactoriamente. Ver más detalles en la bitácora del evento.</b>'
      : '<b>No se puede enviar la solicitud. Reintente nuevamente.</b>';

    setAlertMessage(message);
    setAlertType('info');

    setShowNotification(true);
    setConfirmRemoveExpire(false);
    setShowBtnRemoveExpire(false);
    setLoading(true, dispatch);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleSnackBarClearMessage = (): void => {
    setShowNotification(false);
    dispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: false,
        alertMessage: '',
        alertType: '',
      },
    });
  };

  useEffect(() => {
    if (firstRender) {
      validateExpire();
      setFirstRender(false);
    }
  }, [firstRender, goToBackStep, validateExpire]);

  useEffect(() => {
    if (showBtnContinue && lastStep === activeStep) {
      setShowBtnContinue(false);
    }
  }, [showBtnContinue, setShowBtnContinue, activeStep, lastStep]);

  return (
    <>
      <div className="inspectionForm" id="form-section">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={showNotification}
        >
          <Alert onClose={handleSnackBarClearMessage} severity={alertType}>
            <div dangerouslySetInnerHTML={{ __html: alertMessage }} />
          </Alert>
        </Snackbar>
        {showBtnRemoveExpire && (
          <div>
            <Button
              key="btnRemoveExpire"
              name="btnRemoveExpire"
              variant="contained"
              className={clsx('pre-inspection__toggle-button')}
              onClick={() => {
                setConfirmRemoveExpire(true);
              }}
            >
              Remover Expiración
            </Button>
          </div>
        )}

        {filterSections.map((section: FieldSection, index: number) => {
          return (
            <div key={index}>
              {section.title &&
                section.title.length > 0 &&
                section.title.indexOf('{EMPTY}') === -1 && (
                  <>
                    <div className="title-container">
                      <h2 className="inspectionForm__title">{section.title}</h2>
                    </div>
                    <Divider />
                  </>
                )}

              {section.name === InspectionDataSections.PRE_PHOTOS && (
                <Photos
                  country={userState.currentUserStatus.country}
                  folderRoot={`preinspections/${folderRoot}`}
                  sectionType="PRE-INSPECTIONS"
                  handleSavePhoto={handleSaveMedia}
                  isReadOnly={readOnlyPermission}
                />
              )}

              {section.name === InspectionDataSections.PRE_DETECT_DAMAGE && (
                <DamageAutodetection />
              )}

              {section.name === InspectionDataSections.PRE_ALERTS && (
                <Alertas />
              )}

              {section.name === InspectionDataSections.PRE_STATUS && (
                <EventState
                  states={state?.currentPreInspection?.transitions as any}
                  sectionType="PRE-INSPECTIONS"
                  isReadOnly={readOnlyPermission}
                />
              )}

              {String(section.name ?? '').length === 0 && (
                <Grid container className="inspectionForm__row" spacing={2}>
                  {section.fields
                    .sort((a: any, b: any) => (a.idOrder > b.idOrder ? 1 : -1))
                    .map(
                      (dataField: Field, index: number) =>
                        dataField.visible && (
                          <Grid
                            key={`form-field-${index}`}
                            item
                            md={dataField.viewLength || 12}
                            xs={12}
                          >
                            <InputField
                              key={dataField.idName}
                              field={dataField}
                              defaultValue={
                                dataField.fieldFormValue ??
                                dataField.defaultValue
                              }
                              onChange={handleInputChange}
                              saveFn={handleSaveForm}
                              isDisabled={!dataField.enabled ?? false}
                              isPreInspection={true}
                              formValidation={() => {
                                return true;
                              }}
                            />
                          </Grid>
                        ),
                    )}
                </Grid>
              )}
            </div>
          );
        })}
        {showBtnContinue && (
          <Button
            id="continue-btn"
            variant="contained"
            className={clsx(classes.button, 'inspectionForm__button')}
            size="large"
            onClick={triggerNextStep}
          >
            Continuar
          </Button>
        )}
      </div>

      <Button
        id="scroll-to-top"
        variant="contained"
        className={clsx(classes.button, showGoToTop && 'show')}
        size="large"
        color="secondary"
        onClick={scrollTop}
      >
        <ArrowUpwardIcon />
      </Button>

      <Dialog
        open={confirmRemoveExpire}
        onClose={() => setConfirmRemoveExpire(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Acceso a Formulario de PreInspección
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Desea remover la expiración para este servicio de preinspección?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setConfirmRemoveExpire(false)}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={triggerRemoveExpire}
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormSectionData;
