import React from 'react';
import clsx from 'clsx';
import './styles.scss';

const COLORS = [
  '#586982', //BLUE_BAYOUX
  '#747474', //BOULDER
  '#40a5cb', //SHAKESPEARE
  '#8da4a7', //GRANNY
  '#5db77e', //FERN
  '#df2f2f', //ALIZARIN_CRIMSON
  '#0440D7', // BLUE
];

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  src?: string;
  size: 'xsmall' | 'small' | 'medium' | 'large';
  name?: string;
  userIndex?: number;
  onClick?: () => void;
}

const Avatar: React.FC<Props> = React.memo((props: Props) => {
  const initials = props.name
    ? props.name
        .split(' ')
        .map((s) => s[0])
        .join('')
        .slice(0, 2)
        .toLocaleUpperCase()
    : undefined;

  const userColor = COLORS[(props.userIndex || 0) % COLORS.length];
  const assigned = props.userIndex !== undefined && props.userIndex >= 0;

  let style: React.CSSProperties;
  if (assigned) {
    style = {
      backgroundColor: userColor,
      borderColor: userColor,
      color: 'white',
    };
  } else {
    style = { borderStyle: 'dashed' };
  }

  return (
    <div
      className={clsx('avatar', `avatar--${props.size}`, props.className)}
      title={props.name}
      style={style}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {props.src ? (
        <img src={props.src} alt="avatar" />
      ) : (
        <span>{initials || '/'}</span>
      )}
    </div>
  );
});

export default Avatar;
