import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import './styles.scss';
import FooterContext from 'context/footer/footer-context';

interface Props {
  isDrawerOpen: boolean;
  propFromChildren?: any;
}

const Footer = (props: Props): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const { propFromChildren, setPropFromChildren } = useContext(FooterContext);
  const handleFooterClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      {propFromChildren && (
        <div
          className={clsx('footerBar', {
            'footerBar--smaller': props.isDrawerOpen,
          })}
        >
          <div onClick={handleFooterClick}>{propFromChildren}</div>
        </div>
      )}
    </>
  );
};

export default Footer;
