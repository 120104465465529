import React, { FunctionComponent } from 'react';
import { DispatchFunction } from 'types/action';
import { IUserState } from 'types/user';
import userReducer, { initialState } from './userReducer';

export interface IUserContext {
  state: IUserState;
  dispatch: DispatchFunction;
}

export const UserContext = React.createContext<IUserState | any>(initialState);

export const UserProvider: FunctionComponent = (props): JSX.Element => {
  let _initialState = initialState;
  const stateBackup: IUserState = JSON.parse(
    localStorage.getItem('userState') || '{}',
  );
  if (Object.keys(stateBackup).length) {
    _initialState = { ...initialState, ...stateBackup };
  }

  const [state, dispatch] = React.useReducer(userReducer, _initialState);

  try {
    localStorage.setItem('userState', JSON.stringify(state));
  } catch (e: any) {
    if (e?.name === 'QuotaExceededError') {
      console.error(
        'Local storage quota exceeded. Consider cleaning up storage.',
      );
      localStorage.removeItem('userState');
    } else {
      console.error('An error occurred while setting local storage:', e);
    }
  }

  return (
    <UserContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
