import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { Event } from 'types/event';
import styles from './override-mui-styles';
import './styles.scss';
import Avatar from 'components/common/avatar/Avatar';
import Loader from 'components/common/loader/Loader';
import { fetchAgents } from 'context/inspections';
import { IUserContext, UserContext } from 'context/user';
import { Agent, IUserModel } from 'types/user';
import { ReminderHours } from './reminders';

interface Props {
  visible: boolean;
  event: Event | null;
  onAddReminder(
    eventId: number,
    reminderUserId: number,
    reminderText: string,
    reminderDate: string,
  ): Promise<boolean>;
  onRefresh(event: Event): void;
  onClose(): any;
}

const RemindersModal = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedTime, setSelectedTime] = useState('01:00:00');
  const [lblError, setLblError] = useState('');
  const [comment, setComment] = useState('');
  const modalRef = React.createRef<HTMLDivElement>();
  const [agents, setAgents] = useState<Agent[]>([]);
  const classes = styles();
  const {
    state: { currentUser },
    dispatch: userDispatch,
  } = useContext(UserContext) as IUserContext;
  const [selectedUser, setSelectedUser] = useState<number | null>(
    currentUser.userCompanyID ?? 1,
  );

  useEffect(() => {
    const countryCode = localStorage.getItem('countryCode') || undefined;
    fetchAgents(countryCode).then((_agents) =>
      setAgents(
        _agents.map(
          (a: IUserModel) =>
            ({
              id: a.userCompanyID,
              name: a.name,
              email: a.email,
            } as Agent),
        ),
      ),
    );
  }, []);

  const reminderHours: ReminderHours[] = [
    { key: 1, value: '01:00:00', text: '1:00 am' },
    { key: 2, value: '02:00:00', text: '2:00 am' },
    { key: 3, value: '03:00:00', text: '3:00 am' },
    { key: 4, value: '04:00:00', text: '4:00 am' },
    { key: 5, value: '05:00:00', text: '5:00 am' },
    { key: 6, value: '06:00:00', text: '6:00 am' },
    { key: 7, value: '07:00:00', text: '7:00 am' },
    { key: 8, value: '08:00:00', text: '8:00 am' },
    { key: 9, value: '09:00:00', text: '9:00 am' },
    { key: 10, value: '10:00:00', text: '10:00 am' },
    { key: 11, value: '11:00:00', text: '11:00 am' },
    { key: 12, value: '12:00:00', text: '12:00 pm' },
    { key: 13, value: '13:00:00', text: '1:00 pm' },
    { key: 14, value: '14:00:00', text: '2:00 pm' },
    { key: 15, value: '15:00:00', text: '3:00 pm' },
    { key: 16, value: '16:00:00', text: '4:00 pm' },
    { key: 17, value: '17:00:00', text: '5:00 pm' },
    { key: 18, value: '18:00:00', text: '6:00 pm' },
    { key: 19, value: '19:00:00', text: '7:00 pm' },
    { key: 20, value: '20:00:00', text: '8:00 pm' },
    { key: 21, value: '21:00:00', text: '9:00 pm' },
    { key: 22, value: '22:00:00', text: '10:00 pm' },
    { key: 23, value: '23:00:00', text: '11:00 pm' },
    { key: 24, value: '23:59:00', text: '12:00 am' },
  ];

  const minDate = new Date();
  const currentHour = new Date().getHours();

  const handleDateTimeChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedTime(selectedValue);
  };

  const formatDateToString = (date: Date | null): string => {
    if (!date) {
      return ''; // Manejar el caso en el que la fecha es nula
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ajusta el mes para tener dos dígitos
    const day = String(date.getDate()).padStart(2, '0'); // Ajusta el día para tener dos dígitos

    return `${year}/${month}/${day}`;
  };

  const setAssignedUser = async (agentId?: number | null) => {
    const ids: number[] = [];
    setSelectedUser(agentId ?? currentUser.userCompanyID ?? 1);
  };

  const saveReminder = async (): Promise<void> => {
    if (selectedDate !== null && selectedTime !== '') {
      const formattedDate =
        formatDateToString(selectedDate) + ' ' + selectedTime;
      const reminderDate = new Date(formattedDate);

      if (reminderDate < new Date()) {
        setLblError(
          'La fecha y la hora no deben ser menores a la fecha actual.',
        );
        console.warn(lblError);

        return;
      }

      if (comment === '') {
        setLblError('Escribe algún recordatorio antes de guardar.');
        console.warn(lblError);

        return;
      }

      if (props.event) {
        setLoading(true);
        await props.onAddReminder(
          props.event?.eventId,
          selectedUser ?? 1,
          comment,
          formattedDate,
        );
        setComment('');
        setLblError('');
        setSelectedDate(new Date());
        setSelectedTime('01:00:00');
        setSelectedUser(currentUser.userCompanyID ?? 1);
        props.onRefresh(props.event);
        setLoading(false);
      }
    } else {
      setLblError('Por favor llena todos los datos requeridos.');
      console.warn(lblError);

      return;
    }
  };

  return (
    <div
      ref={modalRef}
      className={clsx('remindersModal', {
        'remindersModal--hide': !props.visible,
      })}
    >
      <Loader isActive={loading} />
      <Grid container spacing={1}>
        <Grid item xs={12} className="remindersModal_Header">
          <Typography
            variant="h6"
            gutterBottom
            style={{ float: 'left', fontWeight: 'bold' }}
          >
            Agregar Recordatorio
          </Typography>
          <IconButton
            style={{ float: 'right' }}
            className={classes.closeIconButton}
            onClick={props.onClose}
          >
            <SvgIcon className={classes.closeIcon}>
              <Close />
            </SvgIcon>
          </IconButton>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '15px' }}>
          <Autocomplete
            style={{ width: '100%', marginLeft: 0 }}
            options={agents}
            getOptionLabel={(option) => option.name || ''}
            value={agents.find((agent) => agent.id === selectedUser) || null}
            // size="small"
            onChange={(event, newValue: Agent | null) => {
              setAssignedUser(newValue?.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Agente"
                variant="outlined"
                InputLabelProps={{
                  style: { marginLeft: '-25px', marginBottom: '-6px' }, // Ajusta el marginLeft según sea necesario
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Avatar
                  size="xsmall"
                  name={option.name}
                  userIndex={agents.findIndex((a) => a.id === option.id)}
                />
                <span style={{ marginLeft: 5 }}>{option.name}</span>
              </li>
            )}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
          />
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Fecha"
              format={'yyyy-MM-dd'}
              value={selectedDate}
              minDate={minDate}
              onChange={handleDateTimeChange}
              slotProps={{
                textField: {
                  size: 'small',
                  variant: 'outlined',
                  fullWidth: true,
                  margin: 'none',
                  'aria-label': 'change date',
                  InputLabelProps: {
                    style: { marginLeft: '-10px', marginBottom: '-6px' },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={6}>
          <select
            name="reminderTime"
            id="reminderTime"
            className="selectTime"
            value={selectedTime}
            onChange={handleTimeChange}
          >
            {reminderHours?.map((hour) => (
              <option key={hour.key} value={hour.value}>
                {hour.text}
              </option>
            ))}
          </select>
        </Grid>

        <Grid item xs={12}>
          <div className={clsx('remindersModal__addNote')}>
            <div>
              <textarea
                className={clsx('remindersModal__textArea')}
                value={comment}
                rows={4}
                placeholder="Escribir recordatorio..."
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              ></textarea>
            </div>

            <div className="remindersModal__addButton">
              <Typography variant="h5" className="remindersModal__lblError">
                {lblError}
              </Typography>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.addNoteButton}
                onClick={saveReminder}
              >
                Agregar recordatorio
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default RemindersModal;
