import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
} from '@mui/material';

interface MyDialogProps {
  dialogTitle?: string;
  messageStyle?: React.CSSProperties;
  message: string;
  open: boolean;
  buttonConfirmText?: string;
  buttonCancelText?: string;
  handleClose: () => void;
  handleConfirm?: () => void;
}

const DialogComponent: FC<MyDialogProps> = ({
  dialogTitle,
  messageStyle,
  message,
  open,
  buttonConfirmText,
  buttonCancelText,
  handleClose,
  handleConfirm,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{dialogTitle ? dialogTitle : 'Alerta'}</DialogTitle>
      <DialogContent>
        <DialogContentText style={messageStyle}>
          {' '}
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </DialogContentText>
      </DialogContent>
      <Grid style={{ display: 'flex', justifyContent: 'center' }}>
        {handleConfirm && (
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleConfirm}>
              {buttonConfirmText ? buttonConfirmText : 'Sí'}
            </Button>
          </DialogActions>
        )}
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            {buttonCancelText ? buttonCancelText : 'Cerrar'}
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default DialogComponent;
