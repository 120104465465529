import React from 'react';
import {
  Grid,
  Box,
  Divider,
  Stack,
  useMediaQuery,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import './index.scss';

interface DataItem {
  number: number;
  percentage: number;
  defaultTitle: string;
  name: string;
}

interface PercentageComponentProps {
  data: DataItem[];
}

const PercentageSteps: React.FC<PercentageComponentProps> = ({ data }) => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });
  const maxPercentage = Math.max(...data.map((item) => item.percentage));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const fontSize = '10px';

  const customTickFormatter = (name: string): string => {
    switch (name) {
      case 'Caso Finalizado':
        return 'Finalizado';
      case 'Ajuste de Daños':
        return 'Ajuste';
      case 'Acuerdo Final':
        return 'Acuerdo';
      case 'Pendiente de Aprobación':
        return 'Aprobación';
      case 'Procesado Asegurador':
        return 'Procesado';
      default:
        return name;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ paddingTop: '1rem', fontSize }} className="claimBySteps">
        <Stack
          direction={isSmallScreen ? 'column' : 'row'}
          spacing={{ xs: 1, sm: 1, md: 2 }}
        >
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <Box className="claimBySteps__information">
                <Stack direction="column">
                  <Box className="claimBySteps__information--step">
                    {customTickFormatter(item.defaultTitle)}
                  </Box>
                  <Box
                    className={`claimBySteps__information--percentage ${
                      item.percentage === maxPercentage
                        ? 'claimBySteps__information--orange'
                        : ''
                    }`}
                  >
                    {item.percentage}%
                  </Box>
                  <Box className="claimBySteps__information--cases">
                    {item.number} casos
                  </Box>
                </Stack>
              </Box>
              {index < data.length - 1 && !isSmallScreen && (
                <Divider orientation="vertical" flexItem />
              )}{' '}
            </React.Fragment>
          ))}
        </Stack>
      </div>
    </ThemeProvider>
  );
};

export default PercentageSteps;
