import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles, withStyles } from '@mui/styles';
import { globalCss } from 'assets/connect/styles/_variables';
import Tooltip from '@mui/material/Tooltip';

export default makeStyles(() =>
  createStyles({
    helpIcon: {
      width: '14px',
      height: '14px',
      color: globalCss.white,
    },
  }),
);

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: globalCss.white,
    borderRadius: '2px',
    color: globalCss.boulder,
    fontWeight: 600,
    maxWidth: 170,
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip);

export { HtmlTooltip };
