import { makeStyles, createStyles } from '@mui/styles';
import { globalCss } from 'assets/connect/styles/_variables';

export default makeStyles(() =>
  createStyles({
    closeIconButton: {
      backgroundColor: globalCss.alto,
      width: '16px',
      height: '16px',
      borderRadius: '16px',
    },
    closeIcon: {
      width: '14px',
      height: '14px',
      fontWeight: 'bold',
    },
    addNoteButton: {
      textTransform: 'none',
    },
    continueButton: {
      textTransform: 'none',
      marginTop: '1rem',
      alignSelf: 'start',
    },
  }),
);
