import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  TextField,
  ButtonGroup,
  Chip,
  Checkbox,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Snackbar from '@mui/material/Snackbar';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Alert from '@mui/material/Alert';
import clsx from 'clsx';
import EventState from 'components/inspection/event-state';
import Photos from 'components/inspection/photos';
import { IUserContext, UserContext } from 'context/user';
import style from 'override-mui-styles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { differenceInSeconds, format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import {
  BrandsId,
  CountryCode,
  InspectionDataSections,
  ResourceAction,
  Rol,
  TransitionStatesIds,
} from 'types/common';
import { FieldSection } from 'types/section-fields';
import {
  downloadEvent,
  sendClientPdf,
  AddAuditLog,
  sendActiveLink,
  validateAAP,
  finishEvent,
} from 'context/inspections/inspectionUtils';
import ThirdsForm from '../thirds/Thirds';
import InjuryPersonForm from '../injuryPersons/InjuryPersons';
import SectionFields from './SectionFields';
import './styles.scss';
import { filterSectionsByDependencies } from './utils';
import {
  getImages,
  getInspectionById,
  IInspectionContext,
  InspectionContext,
  saveInspection,
  updateExpressAdjust,
} from 'context/inspections';
import { IInspection } from 'types/inspection';
import { Field } from 'types/form-field';
import CircunstancesForm from '../aap/CircunstancesForm';
import CATALOGS, {
  aapSelectOptions,
  aapSelectOtherOptions,
} from 'static/constants/catalogs';
import { roles, roleNames } from 'static/constants/roles';
import AAPForm from '../aap/AAPForm';
import { ActionType } from 'types/action';
import { Visibility } from '@mui/icons-material';
import { userActionsPermissions } from 'shared/utils';
import { AAPValidationMessage, AAPValidationOptions } from 'types/aap';
import { IEventData } from 'types/claimsTracker';

const ROL_ID = Number(localStorage.getItem('rolId'));
const CURRENT_COUNTRY = localStorage.getItem('countryCode') as CountryCode;

interface Props {
  sections: FieldSection[];
  setSections: (sections: FieldSection[]) => void;
  activeStep: number;
  nextStep: number;
  inspectionId: number;
  isFinished: boolean;
  isInspectorForm?: boolean;
  enableBackStep?: boolean;
  scrollToTop: (isInspectorForm: boolean) => void;
  fetchInspection?: () => Promise<IInspection | null>;
  formValidation: (field?: Field, isFinish?: boolean) => boolean;
  goToBackStep: (request: boolean) => void;
  restoreAuditFlag?: (requestSectionId: number) => void;
}

const FormSection = ({
  sections,
  setSections,
  activeStep,
  nextStep,
  inspectionId,
  isFinished,
  isInspectorForm,
  enableBackStep,
  scrollToTop,
  fetchInspection,
  formValidation,
  goToBackStep,
  restoreAuditFlag,
}: Props): JSX.Element => {
  const classes = style();
  const { countryCode } = useParams<any>();
  const history = useHistory();
  const { state: userState } = useContext<IUserContext>(UserContext);
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const modelInspection = inspectionState.currentInspection;
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [typeMessage, setTypeMessage] = useState<any | undefined>('success');
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showFinishedModal, setShowFinishedModal] = useState<boolean>(
    Boolean(isInspectorForm) &&
      Number(modelInspection?.EventStateId) >= TransitionStatesIds.COMPLETED,
  );
  const showExpressAdjustCSM =
    isInspectorForm === true && modelInspection?.CompanyId === BrandsId.CSM;
  const [showAuditedModal, setShowAuditedModal] = useState<boolean>(
    CURRENT_COUNTRY === CountryCode.PRI &&
      ROL_ID === 1 &&
      !isInspectorForm &&
      Boolean(modelInspection?.Audited),
  );
  const [showGoToTop, setShowGoToTop] = useState<boolean>(false);
  const [requestImages, setRequestImages] = useState<boolean>(true);
  const [showConfirmAAPModal, setShowConfirmAAPModal] = useState<boolean>(
    false,
  );
  const [showOptionsAAPModal, setShowOptionsAAPModal] = useState<boolean>(
    false,
  );
  const [useOtherOptionForAAPModal, setUseOtherOptionForAAPModal] = useState<
    boolean
  >(false);
  const [optionDataForAAPModal, setOptionDataForAAPModal] = useState<string>(
    '',
  );
  const [optionTextDataForAAPModal, setOptionTextDataForAAPModal] = useState<
    string
  >('');
  const [showAlertCustomPhotos, setShowAlertCustomPhotos] = useState<boolean>(
    false,
  );
  const [valFirstLoad, setValFirstLoad] = useState<boolean>(true);
  const [valAuditFirstLoad, setValAuditFirstLoad] = useState<boolean>(false);
  const [showBtnActive, setShowBtnActive] = useState<boolean>(false);
  const [confirmActiveLink, setConfirmActiveLink] = useState<boolean>(false);
  const [showConfirmAudit, setShowConfirmAudit] = useState<boolean>(false);
  const [showConfirmNoAudit, setShowConfirmNoAudit] = useState<boolean>(false);
  const [showAuditActions, setShowAuditActions] = useState<boolean>(false);
  const [
    showAuditActionsConfirmSave,
    setShowAuditActionsConfirmSave,
  ] = useState<boolean>(false);
  const [auditBeginTime, setAuditBeginTime] = useState(0);
  const [checkedAudit, setCheckedAudit] = React.useState([false, false, false]);
  const [checkedAuditMsg, setCheckedAuditMsg] = React.useState(
    inspectionState.auditEvent.auditMessage,
  );

  const isAuditProcess =
    Number(localStorage.getItem('rolId')) === Rol.AUDIT && !isInspectorForm;

  const readOnlyPermission = Boolean(
    userActionsPermissions(ResourceAction.VIEW_EVENT_READ_ONLY),
  );

  const validateIfIsEnable = (): boolean | undefined => {
    const baseValidation = modelInspection?.EventStateId
      ? modelInspection?.EventStateId === +TransitionStatesIds.COMPLETED ||
        ((Number(localStorage.getItem('rolId')) === Rol.ADMIN ||
          Number(localStorage.getItem('rolId')) === Rol.ADJUSTER) &&
          modelInspection?.EventStateId === +TransitionStatesIds.PROCESSED) ||
        (isInspectorForm &&
          modelInspection?.EventStateId <= +TransitionStatesIds.COMPLETED)
      : false;

    if (baseValidation && !navigator.onLine) {
      return false;
    }

    return baseValidation;
  };

  const isEnabled = validateIfIsEnable();

  const valExpressAdjust =
    localStorage.getItem('countryCode')?.toUpperCase() === CountryCode.PRI ||
    Number(modelInspection?.CompanyId) === BrandsId.QUALITAS ||
    Number(modelInspection?.CompanyId) === BrandsId.SURA
      ? true
      : false;

  let isAAPIndicators = false;

  const shouldShowPdfButton =
    (modelInspection?.EventStateId !== undefined
      ? modelInspection?.EventStateId
      : 0) >= 2 &&
    modelInspection?.CountryCode === 'CRC' &&
    Number(localStorage.getItem('rolId')) !== Rol.INSURER &&
    Number(localStorage.getItem('rolId')) !== Rol.AUDIT &&
    Number(localStorage.getItem('rolId')) !== Rol.CUSTOMER;

  const shouldShowDownloadButton =
    (modelInspection?.EventStateId !== undefined
      ? modelInspection?.EventStateId
      : 0) >= 2 &&
    Number(localStorage.getItem('rolId')) !== Rol.AUDIT &&
    Number(localStorage.getItem('rolId')) !== Rol.CUSTOMER;

  const shouldShowAuditActions =
    Number(localStorage.getItem('rolId')) === Rol.AUDIT;

  const enableAuditButton = activeStep !== 17; // Exclude Audit from Status Tab

  const checkQualiExpress = modelInspection?.IndExpressAdjust;

  const checkDiagram = modelInspection?.Croquis !== undefined ? true : false;

  const handleSnackBarClearMessage = (): void => {
    setShowNotification(false);
    setAlertMessage('');
  };

  const autoSave = useCallback(
    (field?: Field) => {
      (async (): Promise<void> => {
        const currentSection = sections.filter(
          (f: any) => f.parentSectionId === activeStep,
        )[0];

        if (
          currentSection &&
          currentSection.name === InspectionDataSections.THIRDS
        ) {
          // Trigger to ThirdSection
          dispatch({
            type: ActionType.SET_AUDIT_TRIGGER_THIRD_SECTION,
            payload: true,
          });
        } else if (
          currentSection &&
          currentSection.name === InspectionDataSections.INJURIES
        ) {
          // Trigger to InjuredSection
          dispatch({
            type: ActionType.SET_AUDIT_TRIGGER_INJURED_SECTION,
            payload: true,
          });
        } else if (
          currentSection &&
          currentSection.name === InspectionDataSections.PHOTOS &&
          !inspectionState.auditEvent.auditPhotoSection &&
          isAuditProcess
        ) {
          // Trigger to Photo Section
          dispatch({
            type: ActionType.SET_AUDIT_TRIGGER_PHOTO_SECTION,
            payload: true,
          });
        } else {
          formValidation(field); // Only for info to the user
          await saveInspection(
            sections,
            inspectionState,
            false,
            false,
            dispatch,
          );

          //Refresh event data
          if (currentSection && currentSection.name === 'AE_EXPRESS') {
            setTimeout(async () => {
              if (modelInspection?.EventId) {
                await getInspectionById(modelInspection?.EventId, dispatch);
              }
            }, 500);
          }

          if (isAuditProcess && restoreAuditFlag) {
            restoreAuditFlag(inspectionState.auditEvent.auditSection);
          }
        }
      })();
    },
    [
      sections,
      inspectionState,
      dispatch,
      formValidation,
      activeStep,
      modelInspection,
      isAuditProcess,
      restoreAuditFlag,
    ],
  );

  const msgExpressAdjustRequired = () => {
    const textoMsj =
      localStorage.getItem('countryCode')?.toUpperCase() === CountryCode.CRC
        ? '<b><i>QualiExpress</i> es requerida</b> para finalizar'
        : Number(modelInspection?.CompanyId) === BrandsId.SURA
        ? '<b><i>Acompañamiento</i> es requerido</b> para finalizar.'
        : '<b><i>Ajuste Express</i> es requerida</b> para finalizar.';
    if (isInspectorForm) {
      setTypeMessage('error');
      setAlertMessage(textoMsj);
      setShowNotification(true);
    } else {
      dispatch({
        type: ActionType.SET_GENERAL_ALERT,
        payload: {
          showAlert: true,
          alertMessage: textoMsj,
          alertType: 'error',
        },
      });
    }
  };

  const goToNextStep = async (
    isFinished: boolean,
    forceGenerateDocument = false,
  ) => {
    const isFormValid = formValidation();
    const formPath = isInspectorForm
      ? `/inspector/${countryCode}`
      : '/inspections';

    setShowConfirmModal(false);

    if (isFormValid) {
      if (forceGenerateDocument) {
        await saveInspection(
          sections,
          inspectionState,
          false,
          isInspectorForm,
          dispatch,
        );

        const data: IEventData = {
          event: inspectionState.currentInspection ?? ({} as any),
          qualitasCoverages: [],
        };

        await finishEvent(data, false);

        await AddAuditLog(
          Number(data.event?.EventId),
          0,
          'Usuario Inspector envió la Boleta de Inspección',
        );
      } else {
        await saveInspection(
          sections,
          inspectionState,
          isFinished,
          isInspectorForm,
          dispatch,
        );
      }
    }

    if (isFinished && isFormValid && !forceGenerateDocument) {
      setShowFinishedModal(true);
    } else {
      setShowSnackbar(isFormValid);
    }

    if (!isFinished && (!isInspectorForm || (isInspectorForm && isFormValid))) {
      history.push(`${formPath}/${inspectionId}/sections/${nextStep}`);
    }
    scrollToTop(Boolean(isInspectorForm));
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const checkScrollTop = () => {
    if (window.pageYOffset > 300) {
      setShowGoToTop(true);
    } else {
      setShowGoToTop(false);
    }
  };

  window.addEventListener('scroll', checkScrollTop);

  const reloadImages = useCallback(() => {
    const countryCode = inspectionState?.currentInspection?.CountryCode;
    const eventRecord = inspectionState?.currentInspection?.EventRecord;
    (async (): Promise<void> => {
      // Get current images for syncronize
      if (countryCode && eventRecord && requestImages) {
        setRequestImages(false);

        // Get Image from API
        await getImages(countryCode, String(eventRecord), dispatch);

        //Get Images to rotate
        const rotateDataStr = localStorage.getItem('rotateImageList') ?? '';
        if (rotateDataStr.length > 0) {
          dispatch({
            type: ActionType.SET_LIST_FOR_ROTATE_IMAGES,
            payload: {
              data: Array.from(JSON.parse(rotateDataStr)),
              forceUpdate: false,
            },
          });
        }
      }
    })();
  }, [dispatch, inspectionState, requestImages]);

  const expressAdjustChanged = useCallback(
    (origin: boolean) => {
      const valAE =
        modelInspection?.IndExpressAdjust === true ||
        modelInspection?.IndExpressAdjust === false
          ? !modelInspection?.IndExpressAdjust
          : origin;
      updateExpressAdjust(Number(modelInspection?.EventId), valAE, dispatch);
      const QualiExpressName =
        localStorage.getItem('countryCode')?.toUpperCase() === CountryCode.PRI
          ? 'Ajuste Express'
          : Number(modelInspection?.CompanyId) === BrandsId.SURA
          ? 'Acompañamiento'
          : 'QualiExpress';
      const messageLog =
        origin === true
          ? QualiExpressName + ' pasa a Si.'
          : QualiExpressName + ' pasa a No.';
      const userInspectorId = Number(localStorage.getItem('userId'));
      AddAuditLog(
        Number(modelInspection?.EventId),
        userInspectorId,
        messageLog,
      );

      dispatch({
        type: ActionType.SET_REQUIRED_QUALIEXPRESS,
        payload: false,
      });
    },
    [modelInspection, dispatch],
  );

  const valDates = (): boolean => {
    // Validar fechas de inspección y de accidente
    const inspectionDate = new Date(
      modelInspection?.eventDateInspection +
        ' ' +
        modelInspection?.timeInspection,
    );
    let accidentDate = new Date(
      modelInspection?.eventDateSinister + ' ' + modelInspection?.timeSinister,
    );
    if (modelInspection?.eventDateSinisterVal !== undefined) {
      const splitStr = String(modelInspection?.eventDateSinisterVal).split(' ');
      const splitTime = String(splitStr[1]).split(':');
      const hour = splitTime[0]
        ? splitTime[0].length === 1
          ? '0' + splitTime[0]
          : splitTime[0]
        : '';
      const minute = splitTime[1]
        ? splitTime[1].length === 1
          ? '0' + splitTime[1]
          : splitTime[1]
        : '';
      const second = splitTime[2]
        ? splitTime[2].length === 1
          ? '0' + splitTime[2]
          : splitTime[2]
        : '';
      const formatedTime = hour + ':' + minute + ':' + second;
      accidentDate = new Date(splitStr[0] + ' ' + formatedTime);
    }
    if (accidentDate > inspectionDate) {
      return false;
    } else {
      return true;
    }
  };

  const insurancePermission = (): boolean => {
    const currentRoleId = localStorage.getItem('rolId') ?? '0';

    if (+currentRoleId > 0) {
      const roleData = roles.filter((f) => f.id === +currentRoleId)[0];
      if (roleData && roleData.name === roleNames.INSURANCE) {
        // Insurance Role
        return false;
      }
    }

    return true;
  };

  const customPhotosValidation = (): boolean => {
    let requirePhotos =
      localStorage.getItem('requireCustomPhotoValidation') && !isInspectorForm
        ? localStorage.getItem('requireCustomPhotoValidation')
        : '0';

    if (
      inspectionState.currentInspection &&
      inspectionState.currentInspection?.CompanyId === BrandsId.CSM &&
      requirePhotos === '0' &&
      isFinished
    ) {
      requirePhotos = !inspectionState.customPhotosIsValid ? '1' : '0';
    }

    return requirePhotos === '0';
  };

  const valReqResponsible = (): boolean => {
    let result = true;
    // Val Qualitas Responsible options
    if (
      modelInspection?.CompanyId === BrandsId.QUALITAS &&
      modelInspection?.indRespAAP === '2' && // AAP Not apply
      localStorage.getItem('circunstancesTab') === '1' // Circunstances Tab is Active
    ) {
      const containData =
        modelInspection.consequence &&
        String(modelInspection?.consequence?.CodigoAsegurado ?? '').length >
          0 &&
        String(modelInspection?.consequence?.CodigoTercero ?? '').length > 0 &&
        String(modelInspection?.consequence?.Responsable ?? '').length > 0;

      if (!containData) {
        setTypeMessage('error');
        setAlertMessage(
          '<b>Debe seleccionar las circunstancias del accidente y determinar el responsable</b>',
        );
        setShowNotification(true);

        result = false;
      }
    }

    return result;
  };

  const desktopFinish = () => {
    let validation = true;
    if (
      modelInspection?.CountryCode === CountryCode.CRC.toUpperCase() ||
      modelInspection?.CountryCode === CountryCode.PAN.toUpperCase()
    ) {
      validation = Boolean(valDates());
    }

    if (validation === true) {
      if (!customPhotosValidation()) {
        setShowAlertCustomPhotos(true);
      } else if (valReqResponsible()) {
        // Validar indicador de ajuste express
        if (modelInspection?.IsRequiredQualiExpress && valExpressAdjust) {
          msgExpressAdjustRequired();
        }
        goToNextStep(isFinished);
      }
    } else {
      dispatch({
        type: ActionType.SET_GENERAL_ALERT,
        payload: {
          showAlert: true,
          alertMessage:
            'La fecha de inspección debe ser mayor que la fecha del accidente.',
          alertType: 'error',
        },
      });
    }
  };

  const inspectorFinish = (forceGenerateDocument = false) => {
    const isRightsLetterSignRequired =
      isFinished === true &&
      modelInspection?.CountryCode === CountryCode.PRI.toUpperCase() &&
      (modelInspection?.AcceptTermsSignature === null ||
        modelInspection?.AcceptTermsSignature === undefined);

    let validation = true;
    if (
      isFinished === true &&
      (modelInspection?.CountryCode === CountryCode.CRC.toUpperCase() ||
        modelInspection?.CountryCode === CountryCode.PAN.toUpperCase())
    ) {
      validation = Boolean(valDates());
    }
    if (isRightsLetterSignRequired === true) {
      setTypeMessage('error');
      setAlertMessage(
        'La firma de la carta de derechos del consumidor es requerida.',
      );
      setShowNotification(true);
    } else {
      if (validation === true) {
        if (
          isFinished &&
          modelInspection?.Croquis === null &&
          modelInspection?.DiagramIsShowed === true &&
          modelInspection?.CountryCode === CountryCode.CRC.toUpperCase()
        ) {
          setTypeMessage('error');
          setAlertMessage('Debe llenar el diagrama del accidente');
          setShowNotification(true);
        } else {
          if (modelInspection?.IsRequiredQualiExpress && valExpressAdjust) {
            msgExpressAdjustRequired();
          } else {
            if (isFinished) {
              let isFormValid = formValidation(undefined, isFinished);
              isFormValid = valReqResponsible() ? isFormValid : false;

              if (isFormValid) {
                valReqResponsible();

                if (forceGenerateDocument) {
                  if (!customPhotosValidation()) {
                    setShowAlertCustomPhotos(true);

                    return;
                  }

                  goToNextStep(isFinished, forceGenerateDocument);

                  return;
                }

                if (
                  countryCode &&
                  inspectionState &&
                  String(countryCode).toUpperCase() ===
                    CountryCode.CRC.toUpperCase() &&
                  modelInspection?.usePactoAmistoso === '1'
                ) {
                  setShowConfirmAAPModal(true);
                } else if (
                  countryCode &&
                  inspectionState &&
                  String(countryCode).toUpperCase() ===
                    CountryCode.CRC.toUpperCase() &&
                  modelInspection?.usePactoAmistoso === '2' &&
                  modelInspection?.accountStatus === 'Validada'
                ) {
                  setOptionDataForAAPModal('');
                  setOptionTextDataForAAPModal('');
                  setShowOptionsAAPModal(true);
                } else if (!customPhotosValidation()) {
                  setShowAlertCustomPhotos(true);
                } else if (
                  countryCode &&
                  inspectionState &&
                  String(countryCode).toUpperCase() ===
                    CountryCode.CRC.toUpperCase() &&
                  modelInspection?.accountStatus === 'No Validada'
                ) {
                  // Cuenta No Autorizada Base de Datos
                  dispatch({
                    type: ActionType.SET_OPTIONS_AAP_FORM,
                    payload: {
                      option: '',
                      optionText: 'Cuenta No Autorizada Base de Datos',
                    },
                  });
                  setShowConfirmModal(true);
                } else {
                  setShowConfirmModal(true);
                }
              }
            } else {
              goToNextStep(isFinished);
            }
          }
        }
      } else {
        setTypeMessage('error');
        setAlertMessage(
          'La fecha de inspección debe ser mayor que la fecha del accidente.',
        );
        setShowNotification(true);
      }
    }
  };

  const confirmAAPForm = async () => {
    const result = await validateAAP(
      inspectionState?.currentInspection?.EventRecord,
    );

    if (result && result.statusCode === AAPValidationOptions.OK) {
      dispatch({ type: ActionType.SET_CONFIRM_AAP_FORM, payload: true });
      dispatch({
        type: ActionType.SET_OPTIONS_AAP_FORM,
        payload: {
          option: '',
          optionText: '',
        },
      });
      setShowConfirmAAPModal(false);
      setShowConfirmModal(true);
    } else {
      const aapMsg = result
        ? AAPValidationMessage[result.statusCode] ??
          AAPValidationMessage['default']
        : 'No se puede determinar el uso del Pacto Amistoso.';
      setShowNotification(true);
      setTypeMessage('warning');
      setAlertMessage(aapMsg);
    }
  };

  const confirmOtherOptionsAAPForm = async () => {
    if (
      optionDataForAAPModal.length > 0 &&
      optionTextDataForAAPModal.length > 0
    ) {
      dispatch({ type: ActionType.SET_CONFIRM_AAP_FORM, payload: false });
      dispatch({
        type: ActionType.SET_OPTIONS_AAP_FORM,
        payload: {
          option: optionDataForAAPModal,
          optionText: optionTextDataForAAPModal,
        },
      });
      setShowOptionsAAPModal(false);
      setShowConfirmModal(true);
    } else {
      setShowNotification(true);
      setTypeMessage('warning');
      setAlertMessage(
        'Debe <b>seleccionar la razón</b> del por qué NO se aplica Pacto Amistoso.',
      );
    }
  };

  const sendPdf = async () => {
    const eventNumber =
      modelInspection?.EventRecord !== undefined
        ? modelInspection?.EventRecord
        : '';
    const response = await sendClientPdf(eventNumber);

    const message =
      response === ''
        ? 'El Pdf fue enviado con éxito.'
        : 'Ocurrió un error al enviar el Pdf.';
    dispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: true,
        alertMessage: message,
        alertType: response === '' ? 'success' : 'error',
      },
    });
  };

  const download = async () => {
    // La descarga del evento se hará depenindo de si es un estado mayor a 2
    if (modelInspection !== undefined && modelInspection?.EventRecord) {
      downloadEvent(
        modelInspection?.EventRecord,
        modelInspection?.CountryCode,
        Number(localStorage.getItem('rolId')),
        dispatch,
      );
    }
  };

  const onChangeAAPOptions = (event: any) => {
    if (event.target) {
      const sData = String(event.target.value).split('|');
      if (sData.length > 0) {
        setOptionDataForAAPModal(sData[0]); // ID Option
        setOptionTextDataForAAPModal(sData[1]); // Text Option
        if (+sData[0] === 8) {
          setOptionTextDataForAAPModal('');
          setUseOtherOptionForAAPModal(true);
        } else {
          setUseOtherOptionForAAPModal(false);
        }
      }
    }
  };

  const onChangeAAPOtherOptions = (event: any) => {
    if (event.target) {
      const sData = String(event.target.value).split('|');
      if (sData.length > 0) {
        //setOptionDataForAAPModal(sData[0]); // ID Option
        setOptionTextDataForAAPModal(sData[1]); // Text Option
      }
    }
  };

  const validateActiveLink = useCallback(() => {
    const rolId_Info = Number(localStorage.getItem('rolId'));
    const countryCode_Info = localStorage.getItem('countryCode') as CountryCode;
    if (
      (countryCode_Info === CountryCode.PAN ||
        countryCode_Info === CountryCode.PRI) &&
      (rolId_Info === Rol.ADMIN || rolId_Info === Rol.ADJUSTER) &&
      modelInspection &&
      modelInspection.isActive !== 2
    ) {
      setShowBtnActive(true);
    } else {
      setShowBtnActive(false);
    }
  }, [modelInspection]);

  const triggerActiveLink = async () => {
    const response = await sendActiveLink(modelInspection?.EventId);

    const message = response
      ? '<b>Solicitud enviada satisfactoriamente. Ver más detalles en la bitácora del evento.</b>'
      : '<b>No se puede enviar la solicitud. Reintente nuevamente.</b>';
    dispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: true,
        alertMessage: message,
        alertType: response ? 'success' : 'error',
      },
    });

    setConfirmActiveLink(false);
    setShowBtnActive(!response);
  };

  const applyAuditTimes = () => {
    const currentDateTime = Date.now();
    const formatTime = format(currentDateTime, 'yyyy-MM-dd HH:mm:ss', {
      locale: esLocale,
    });
    const effectiveTime = differenceInSeconds(currentDateTime, auditBeginTime);

    dispatch({
      type: ActionType.SET_AUDIT_EVENT_TIMES,
      payload: {
        EffectiveTime: effectiveTime,
        TimeFini: formatTime,
        TimeInit: inspectionState.auditEvent.auditTimeInit,
      },
    });
  };

  const triggerActiveAuditEvent = async () => {
    setShowConfirmAudit(false);
    setShowAuditActions(true);
    const currentDateTime = Date.now();
    const formatTime = format(currentDateTime, 'yyyy-MM-dd HH:mm:ss', {
      locale: esLocale,
    });
    setAuditBeginTime(currentDateTime);

    dispatch({
      type: ActionType.SET_AUDIT_EVENT_STATUS,
      payload: true,
    });
    dispatch({
      type: ActionType.SET_NOT_AUDIT_EVENT,
      payload: false,
    });
    dispatch({
      type: ActionType.SET_AUDIT_EVENT_TIMES,
      payload: {
        EffectiveTime: 0,
        TimeFini: formatTime,
        TimeInit: formatTime,
      },
    });
  };

  const triggerNoAuditEvent = async () => {
    setShowConfirmNoAudit(true);
    applyAuditTimes();

    dispatch({
      type: ActionType.SET_NOT_AUDIT_EVENT,
      payload: true,
    });

    dispatch({
      type: ActionType.SET_AUDIT_EVENT_STATUS,
      payload: false,
    });
  };

  const triggerCancelNoAuditEvent = async () => {
    setShowConfirmNoAudit(false);

    dispatch({
      type: ActionType.SET_NOT_AUDIT_EVENT,
      payload: false,
    });

    dispatch({
      type: ActionType.SET_AUDIT_EVENT_STATUS,
      payload: true,
    });
  };

  const triggerConfirmNoAuditEvent = async () => {
    setShowConfirmNoAudit(false);
    setShowAuditActions(false);

    // Send field changes and Audit data to API
    autoSave();
  };

  const triggerAuditText = async (element: any) => {
    dispatch({
      type: ActionType.SET_AUDIT_EVENT_MSG,
      payload: element.target.value,
    });
  };

  const triggerAuditSaveAction = async () => {
    if (formValidation()) {
      setShowAuditActionsConfirmSave(true);
      applyAuditTimes();
    }
  };

  const triggerConfirmSaveAction = async () => {
    dispatch({ type: ActionType.SET_LOADING, payload: true });
    setShowAuditActions(false);
    setShowAuditActionsConfirmSave(false);

    dispatch({
      type: ActionType.SET_AUDIT_EVENT_STATUS,
      payload: false,
    });

    let auditMessage = checkedAuditMsg;

    // Gallery Step
    if (activeStep === 14 || activeStep === 50) {
      auditMessage =
        checkedAuditMsg && checkedAuditMsg.length > 0
          ? checkedAuditMsg
          : 'Auditoría en fotos por motivo no definido';
    }

    dispatch({
      type: ActionType.SET_AUDIT_EVENT_MSG,
      payload: auditMessage,
    });

    // Send field changes and Audit data to API
    autoSave();
  };

  const renderAuditActions = (): JSX.Element => {
    return (
      <>
        <ButtonGroup style={{ float: 'right' }}>
          <Button
            key="btnNoAuditAction"
            name="btnNoAuditAction"
            variant="outlined"
            color="primary"
            onClick={triggerNoAuditEvent}
          >
            No hice cambios
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="saveAuditAction-btn"
            onClick={triggerAuditSaveAction}
          >
            Sí hice cambios
          </Button>
        </ButtonGroup>
        <div className="inspectionForm__audit-status">
          <Chip
            icon={<Visibility />}
            label="AUDITORÍA ACTIVA"
            color="primary"
          />
        </div>
      </>
    );
  };

  const triggerCheckAudits = (event: any) => {
    if (event.target) {
      let currentMsg = checkedAuditMsg;
      let result = false;
      let checkComment = 'Auditoría por ';
      switch (event.target.name) {
        case 'quality-photos': {
          result = !checkedAudit[0];
          checkComment += 'Calidad de fotos';
          setCheckedAudit([result, checkedAudit[1], checkedAudit[2]]);
          break;
        }
        case 'missing-photos': {
          result = !checkedAudit[1];
          checkComment += 'Falta de fotos';
          setCheckedAudit([checkedAudit[0], result, checkedAudit[2]]);
          break;
        }
        case 'location-photos': {
          result = !checkedAudit[2];
          checkComment += 'Ubicación de las fotos por sección';
          setCheckedAudit([checkedAudit[0], checkedAudit[1], result]);
          break;
        }
        default:
          break;
      }

      if (result) {
        currentMsg +=
          currentMsg.length > 0 ? ' // ' + checkComment : checkComment;
      } else {
        currentMsg = currentMsg.replace(' // ' + checkComment, '');
        currentMsg = currentMsg.replace(checkComment, '');
      }

      setCheckedAuditMsg(currentMsg);
    }
  };

  const handleBackdropClick = (event: any) => {
    //these fail to keep the modal open
    event.stopPropagation();

    return false;
  };

  useEffect(() => {
    reloadImages();
  }, [reloadImages, activeStep]);

  useEffect(() => {
    if (isInspectorForm) {
      goToBackStep(false);
    }
  }, [isInspectorForm, goToBackStep]);

  useEffect(() => {
    if (valFirstLoad) {
      const countryCode = localStorage.getItem('countryCode');
      if (
        countryCode?.toUpperCase() === CountryCode.CRC ||
        countryCode?.toUpperCase() === CountryCode.PAN
      ) {
        dispatch({
          type: ActionType.SET_REQUIRED_QUALIEXPRESS,
          payload:
            checkQualiExpress === true || checkQualiExpress === false
              ? false
              : true,
        });
        dispatch({
          type: ActionType.SET_REQUIRED_DIAGRAM,
          payload: checkDiagram === true ? false : true,
        });
      }

      if (!isInspectorForm) {
        setTimeout(() => {
          validateActiveLink();
        }, 2000);

        if (shouldShowAuditActions) {
          dispatch({
            type: ActionType.SET_AUDIT_PROCESS_SECTION,
            payload: 0,
          });
        }

        setValAuditFirstLoad(shouldShowAuditActions);
      }

      setValFirstLoad(false);
    }
  }, [
    valFirstLoad,
    dispatch,
    checkQualiExpress,
    checkDiagram,
    validateActiveLink,
    isInspectorForm,
    shouldShowAuditActions,
  ]);

  useEffect(() => {
    if (valAuditFirstLoad && shouldShowAuditActions) {
      dispatch({
        type: ActionType.SET_AUDIT_SECTION,
        payload: activeStep,
      });
      dispatch({
        type: ActionType.SET_AUDIT_EVENT_STATUS,
        payload: false,
      });
      dispatch({
        type: ActionType.SET_NOT_AUDIT_EVENT,
        payload: false,
      });
      dispatch({
        type: ActionType.SET_AUDIT_EVENT_TIMES,
        payload: {
          EffectiveTime: 0,
          TimeFini: '',
          TimeInit: '',
        },
      });
      dispatch({
        type: ActionType.SET_AUDIT_EVENT_MSG,
        payload: '',
      });
      dispatch({
        type: ActionType.SET_AUDIT_TRIGGER_THIRD_SECTION,
        payload: false,
      });
      dispatch({
        type: ActionType.SET_AUDIT_TRIGGER_INJURED_SECTION,
        payload: false,
      });

      setValAuditFirstLoad(false);
    }
  }, [
    setValAuditFirstLoad,
    activeStep,
    dispatch,
    shouldShowAuditActions,
    valAuditFirstLoad,
  ]);

  return (
    <>
      <div className="inspectionForm" id="form-section">
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={showNotification}
          >
            <Alert onClose={handleSnackBarClearMessage} severity={typeMessage}>
              <div dangerouslySetInnerHTML={{ __html: alertMessage }} />
            </Alert>
          </Snackbar>

          {shouldShowPdfButton && (
            <Button
              key="btnPdfbutton"
              name="btnPdfbutton"
              variant="outlined"
              className={clsx('settingsForm__toggle-button')}
              style={{ float: 'right' }}
              onClick={() => sendPdf()}
            >
              Enviar PDF
            </Button>
          )}

          {shouldShowDownloadButton && !isInspectorForm && (
            <Button
              key="btnDownloadButton"
              name="btnDownloadButton"
              variant="outlined"
              className={clsx('settingsForm__toggle-button')}
              style={{ float: 'right' }}
              onClick={() => download()}
            >
              Descargar
            </Button>
          )}

          {showBtnActive && (
            <Button
              key="btnActiveInspection"
              name="btnActiveInspection"
              variant="outlined"
              className={clsx('settingsForm__toggle-button')}
              style={{ float: 'right' }}
              onClick={() => {
                setConfirmActiveLink(true);
              }}
            >
              Habilitar Enlace
            </Button>
          )}

          {shouldShowAuditActions && enableAuditButton && !isInspectorForm && (
            <>
              {!isInspectorForm && showAuditActions && renderAuditActions()}
              {!showAuditActions && (
                <ButtonGroup style={{ float: 'right' }}>
                  <Button
                    key="btnAuditAction"
                    name="btnAuditAction"
                    variant="contained"
                    color="primary"
                    onClick={() => setShowConfirmAudit(true)}
                  >
                    Auditar
                  </Button>
                </ButtonGroup>
              )}
            </>
          )}
        </div>
        {sections
          .filter((section) => {
            return (
              section.parentSectionId === activeStep &&
              filterSectionsByDependencies(section, sections)
            );
          })
          .sort((a, b) => (a.idOrder > b.idOrder ? 1 : -1))
          .map((section, index) => {
            if (index === 0) {
              isAAPIndicators = section.formSectionId === 133; // AAP Section Indicators
            }

            return (
              <div key={section.formSectionId}>
                {section.name !== InspectionDataSections.PHOTOS &&
                  section.name !== InspectionDataSections.THIRDS &&
                  section.name !== InspectionDataSections.INJURIES &&
                  section.title && (
                    <>
                      <div className="title-container">
                        <h2 className="inspectionForm__title">
                          {section.title}
                        </h2>
                        {modelInspection?.ShouldUseExpressAdjust &&
                          ((index === 1 && isAAPIndicators) || index === 0) && (
                            <>
                              {!showExpressAdjustCSM && (
                                <div>
                                  <span>
                                    {modelInspection?.IndExpressAdjust}
                                  </span>
                                  <br />
                                  <InputLabel key="LabelQuali">
                                    {localStorage
                                      .getItem('countryCode')
                                      ?.toUpperCase() === CountryCode.CRC
                                      ? 'QualiExpress'
                                      : Number(modelInspection?.CompanyId) ===
                                        BrandsId.SURA
                                      ? 'Acompañamiento'
                                      : 'Ajuste Express'}
                                  </InputLabel>
                                  <RadioGroup
                                    aria-label="TEXT"
                                    name={
                                      localStorage
                                        .getItem('countryCode')
                                        ?.toUpperCase() === CountryCode.CRC
                                        ? 'QualiExpress'
                                        : Number(modelInspection?.CompanyId) ===
                                          BrandsId.SURA
                                        ? 'Acompañamiento'
                                        : 'Ajuste Express'
                                    }
                                    value="null"
                                    row
                                  >
                                    <FormControlLabel
                                      key="Quali2"
                                      className={
                                        modelInspection.IsRequiredQualiExpress
                                          ? 'rdError'
                                          : ''
                                      }
                                      value={
                                        modelInspection?.IndExpressAdjust
                                          ? true
                                          : false
                                      }
                                      control={
                                        <Radio
                                          disabled={
                                            Number(
                                              localStorage.getItem('rolId'),
                                            ) === Rol.CUSTOMER
                                          }
                                          name="QualiSi"
                                          required={true}
                                          onClick={() =>
                                            expressAdjustChanged(true)
                                          }
                                          style={{ float: 'right' }}
                                          checked={
                                            checkQualiExpress === true
                                              ? true
                                              : undefined
                                          }
                                        />
                                      }
                                      label="Si"
                                    />
                                    <FormControlLabel
                                      key="Quali1"
                                      className={
                                        modelInspection.IsRequiredQualiExpress
                                          ? 'rdError'
                                          : ''
                                      }
                                      value={
                                        modelInspection?.IndExpressAdjust
                                          ? false
                                          : true
                                      }
                                      control={
                                        <Radio
                                          disabled={
                                            Number(
                                              localStorage.getItem('rolId'),
                                            ) === Rol.CUSTOMER
                                          }
                                          name="QualiNo"
                                          required={true}
                                          onClick={() =>
                                            expressAdjustChanged(false)
                                          }
                                          style={{ float: 'left' }}
                                          checked={
                                            checkQualiExpress === false
                                              ? true
                                              : undefined
                                          }
                                        />
                                      }
                                      label="No"
                                    />
                                  </RadioGroup>
                                </div>
                              )}
                            </>
                          )}
                      </div>
                      <Divider />
                    </>
                  )}

                {section.name === InspectionDataSections.PHOTOS && (
                  <Photos
                    country={userState.currentUserStatus.country}
                    sectionType="INSPECTIONS"
                    handleSavePhoto={autoSave}
                    setSections={setSections}
                    sections={sections}
                  />
                )}
                {section.name === InspectionDataSections.AAP && <AAPForm />}
                {section.name === InspectionDataSections.STATUS && (
                  <EventState
                    states={
                      inspectionState?.currentInspection?.Transitions as any
                    }
                    sectionType="INSPECTIONS"
                  />
                )}

                {section.name === InspectionDataSections.THIRDS &&
                  index === 0 && (
                    <ThirdsForm
                      sections={sections}
                      setSections={setSections}
                      isInspectorForm={Boolean(isInspectorForm)}
                      fetchInspection={fetchInspection}
                      formValidation={formValidation}
                    />
                  )}

                {section.name === InspectionDataSections.INJURIES &&
                  index === 0 && (
                    <InjuryPersonForm
                      sections={sections.filter(
                        (s) => s.name === InspectionDataSections.INJURIES,
                      )}
                      setSections={setSections}
                      isInspectorForm={Boolean(isInspectorForm)}
                      fetchInspection={fetchInspection}
                      formValidation={formValidation}
                    />
                  )}
                {section.name === InspectionDataSections.CIRCUNSTANCES && (
                  <CircunstancesForm
                    circunstancesList={CATALOGS.circunstances}
                    thirdPlate={modelInspection?.plateCircunstancesThird}
                    thirdInsurance={
                      modelInspection?.insuranceCircunstancesThird
                    }
                    isActive={insurancePermission()}
                    codeVehicleA={modelInspection?.insuredCodeConsequences}
                    codeVehicleB={modelInspection?.thirdCodeConsequences}
                    responsable={modelInspection?.responsableConsequences}
                    save={autoSave}
                  />
                )}

                {section.name !== InspectionDataSections.THIRDS &&
                  section.name !== InspectionDataSections.INJURIES && (
                    <SectionFields
                      fields={section.fields}
                      sections={sections}
                      setSections={setSections}
                      isVirtualInspector={Boolean(
                        modelInspection?.IsVitualInspector,
                      )}
                      isInspectorForm={Boolean(isInspectorForm)}
                      classes={classes}
                      save={autoSave}
                      formValidation={formValidation}
                    />
                  )}
              </div>
            );
          })}
        {isInspectorForm && (!isFinished || isEnabled) && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Button
                id="back-btn"
                variant="contained"
                className={clsx(classes.button, 'inspectionForm__button')}
                size="large"
                color="secondary"
                disabled={!enableBackStep}
                onClick={() => goToBackStep(true)}
              >
                Volver
              </Button>
            </Grid>
            {isFinished &&
              (countryCode === CountryCode.CRC ||
                localStorage.getItem('countryCode')?.toUpperCase() ===
                  CountryCode.CRC) && (
                <Grid item xs={12} md={4}>
                  <Button
                    id="document-btn"
                    variant="contained"
                    className={clsx(classes.button, 'inspectionForm__button')}
                    size="large"
                    onClick={() => inspectorFinish(true)}
                  >
                    Boleta de Inspección
                  </Button>
                </Grid>
              )}
            <Grid item xs={12} md={4}>
              <Button
                id="continue-btn"
                variant="contained"
                className={clsx(classes.button, 'inspectionForm__button')}
                size="large"
                onClick={() => inspectorFinish()}
              >
                {isFinished ? 'Finalizar' : 'Continuar'}
              </Button>
            </Grid>
          </Grid>
        )}

        {!isInspectorForm && showAuditActions && (
          <div style={{ float: 'right', paddingTop: '30px' }}>
            {renderAuditActions()}
          </div>
        )}

        {nextStep &&
          !isInspectorForm &&
          !showAuditActions &&
          !readOnlyPermission &&
          (!isFinished || isEnabled) && (
            <Button
              id="continue-btn"
              variant="contained"
              className={clsx(classes.button, 'inspectionForm__button')}
              size="large"
              onClick={desktopFinish}
            >
              {isFinished ? 'Finalizar' : 'Continuar'}
            </Button>
          )}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={() => setShowSnackbar(false)}
        >
          <Alert onClose={() => setShowSnackbar(false)} severity="success">
            Los cambios se han guardado satisfactoriamente.
          </Alert>
        </Snackbar>

        <Dialog
          open={showConfirmModal}
          onClick={handleBackdropClick}
          onClose={() => setShowConfirmModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirmar</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Seguro que desea marcar como completado este formulario?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setShowConfirmModal(false)}
              color="secondary"
            >
              No
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={() => goToNextStep(isFinished)}
              color="primary"
            >
              Si
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showFinishedModal}
          onClick={handleBackdropClick}
          onClose={() => setShowFinishedModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Completado</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              El formulario se encuentra completado
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              disableElevation
              onClick={() => setShowFinishedModal(false)}
              color="primary"
            >
              Entendido
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showAuditedModal}
          onClick={handleBackdropClick}
          onClose={() => setShowAuditedModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Auditado</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Este evento ya fue auditado.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              disableElevation
              onClick={() => setShowAuditedModal(false)}
              color="primary"
            >
              Entendido
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showConfirmAAPModal}
          onClose={() => setShowConfirmAAPModal(false)}
          onClick={handleBackdropClick}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirmar Uso de Pacto Amistoso
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Usaste el Formulario de Pacto Amistoso?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setShowConfirmAAPModal(false)}
              color="secondary"
            >
              Volver
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={() => confirmAAPForm()}
              color="primary"
            >
              Sí, confirmar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showOptionsAAPModal}
          onClick={handleBackdropClick}
          onClose={() => setShowOptionsAAPModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Formulario de Pacto Amistoso
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <label>
                Seleccione la razón del por qué NO se aplica Pacto Amistoso:
              </label>
              <FormControl
                style={{ marginTop: '10px' }}
                variant="outlined"
                className={clsx('inspectionForm__input')}
              >
                <InputLabel
                  key="ilAAPOptions"
                  style={{
                    // Fixes select label drawn with input border
                    backgroundColor: 'white',
                    paddingRight: 5,
                    paddingLeft: 5,
                  }}
                >
                  Seleccione...
                </InputLabel>
                <Select
                  key="sltAAPOptions"
                  onChange={onChangeAAPOptions}
                  name="sltAAPOptions"
                  error={!optionDataForAAPModal}
                >
                  {Array.from(aapSelectOptions).map((fe: any) => (
                    <MenuItem key={fe.id} value={fe.id + '|' + fe.description}>
                      {fe.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {useOtherOptionForAAPModal && (
                <FormControl
                  style={{ marginTop: '10px' }}
                  variant="outlined"
                  className={clsx('inspectionForm__input')}
                >
                  <InputLabel
                    key="ilAAPOtherOptions"
                    style={{
                      // Fixes select label drawn with input border
                      backgroundColor: 'white',
                      paddingRight: 5,
                      paddingLeft: 5,
                    }}
                  >
                    Seleccione...
                  </InputLabel>
                  <Select
                    key="sltAAPOtherOptions"
                    onChange={onChangeAAPOtherOptions}
                    name="sltAAPOtherOptions"
                    error={!optionTextDataForAAPModal}
                  >
                    {Array.from(aapSelectOtherOptions).map((fe: any) => (
                      <MenuItem
                        key={fe.id}
                        value={fe.id + '|' + fe.description}
                      >
                        {fe.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setShowOptionsAAPModal(false)}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={() => confirmOtherOptionsAAPForm()}
              color="primary"
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showAlertCustomPhotos}
          onClick={handleBackdropClick}
          onClose={() => setShowAlertCustomPhotos(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Validación de Fotografías
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Se requieren fotografías en la sección de fotos.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              disableElevation
              onClick={() => setShowAlertCustomPhotos(false)}
              color="primary"
            >
              Entendido
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={confirmActiveLink}
          onClick={handleBackdropClick}
          onClose={() => setConfirmActiveLink(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Acceso a Formulario de Inspección
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Desea activar el enlace externo para acceder al formulario de
              inspección?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setConfirmActiveLink(false)}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={triggerActiveLink}
              color="primary"
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showConfirmAudit}
          onClick={handleBackdropClick}
          onClose={() => setConfirmActiveLink(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Aplicar Proceso de Auditoría
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Desea <b>activar el proceso de auditoría</b> para esta sección
              del formulario?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setShowConfirmAudit(false)}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={triggerActiveAuditEvent}
              color="primary"
            >
              Sí, auditar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showConfirmNoAudit}
          onClick={handleBackdropClick}
          onClose={() => setConfirmActiveLink(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Aplicar Proceso de Auditoría
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>
                ¿Deseas confirmar que la sección <b>no tiene cambios</b>?
              </p>
              <p>
                <TextField
                  key="txtNoAuditDescription"
                  className={clsx('inspectionForm__input')}
                  name="txtNoAuditDescription"
                  label="Digite comentario y/o motivo"
                  variant="outlined"
                  size="small"
                  multiline={true}
                  rows={3}
                  onChange={triggerAuditText}
                  onBlur={triggerAuditText}
                />
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={triggerCancelNoAuditEvent}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={triggerConfirmNoAuditEvent}
              color="primary"
            >
              Sí, confirmar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showAuditActionsConfirmSave}
          onClick={handleBackdropClick}
          onClose={() => setConfirmActiveLink(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Guardar Proceso de Auditoría
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>
                ¿Deseas <b>guardar los cambios</b> de la actual sección?
              </p>
              {
                // Gallery Photo Section
                (activeStep === 14 || activeStep === 50) && (
                  <p>
                    <label>Indique el motivo:</label>
                    <br />
                    <FormControlLabel
                      label="Calidad de fotos"
                      control={
                        <Checkbox
                          name="quality-photos"
                          checked={checkedAudit[0]}
                          onChange={triggerCheckAudits}
                        />
                      }
                    />
                    <br />
                    <FormControlLabel
                      label="Faltaron fotos"
                      control={
                        <Checkbox
                          name="missing-photos"
                          checked={checkedAudit[1]}
                          onChange={triggerCheckAudits}
                        />
                      }
                    />
                    <br />
                    <FormControlLabel
                      label="Ubicación de las fotos por sección"
                      control={
                        <Checkbox
                          name="location-photos"
                          checked={checkedAudit[2]}
                          onChange={triggerCheckAudits}
                        />
                      }
                    />
                  </p>
                )
              }
              {activeStep !== 14 && activeStep !== 50 && (
                <p>
                  <TextField
                    key="txtNoAuditDescription"
                    className={clsx('inspectionForm__input')}
                    name="txtNoAuditDescription"
                    label="Digite comentario y/o motivo"
                    variant="outlined"
                    size="small"
                    multiline={true}
                    rows={3}
                    onChange={triggerAuditText}
                    onBlur={triggerAuditText}
                  />
                </p>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setShowAuditActionsConfirmSave(false)}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={triggerConfirmSaveAction}
              color="primary"
            >
              Sí, guardar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Button
        id="scroll-to-top"
        variant="contained"
        className={clsx(classes.button, showGoToTop && 'show')}
        size="large"
        color="secondary"
        onClick={scrollTop}
      >
        <ArrowUpwardIcon />
      </Button>
    </>
  );
};

export default FormSection;
