export interface AAPRequest {
  apiKey: string;
  countryCode: string;
  eventFormId: number;
  eventId: string;
  formulario: string;
  eventStateId: number;
  aapData: string;
  reportDateTime: string;
  driverName: string;
  driverLastNames: string;
  driverEmail: string;
  vehicleLisencePlate: string;
  vehicleDef: string;
  eventRecord: string;
}

export interface AAPResponse {
  eventId: number;
  apiKey: string;
  type: string;
  typeData: string;
  aapData: string;
}

export interface AAPValidationResponse {
  statusCode: string;
  message: string;
}

export enum AAPValidationOptions {
  MISSING_AAP_DOCUMENT = 'MISSING_AAP_DOCUMENT',
  MISSING_NOT_AAP_REASON = 'MISSING_NOT_AAP_REASON',
  MISSING_OTHER_REASON = 'MISSING_OTHER_REASON',
  OK = 'OK',
}

export const AAPValidationMessage = {
  [AAPValidationOptions.MISSING_AAP_DOCUMENT]:
    'El formulario de Pacto Amistoso sigue activo, actualmente <b>NO se ha completado</b>.',
  [AAPValidationOptions.MISSING_NOT_AAP_REASON]:
    'Falta <b>definir una razón</b> para NO aplicar el Pacto Amistoso.',
  [AAPValidationOptions.MISSING_OTHER_REASON]:
    'Falta <b>definir una razón en la opción de "Otro"</b> para NO aplicar el Pacto Amistoso.',
  default:
    'Se requiere información para determinar la aplicación del Pacto Amistoso.',
};
