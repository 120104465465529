import { AxiosResponse } from 'axios';
import APIClient, { endpoints } from 'shared/api';
import { RUV } from '../../types/form-field';
import { DocumentObservation } from 'types/event-note';

export default class ConnectServicesClient {
  async uploadImage(
    eventId: number,
    data: { [key: string]: any },
  ): Promise<AxiosResponse> {
    return await APIClient.post(
      `${endpoints.PHOTO_IMAGE}/${eventId}/bucket`,
      JSON.stringify(data),
    );
  }

  async deleteImage(
    eventId: number,
    data: { [key: string]: any },
  ): Promise<AxiosResponse> {
    return await APIClient.delete(
      `${endpoints.PHOTO_IMAGE}/${eventId}/bucket`,
      { data: data },
    );
  }

  async networkStatus(): Promise<AxiosResponse> {
    return await APIClient.get(endpoints.GET_SERVICE_STATUS);
  }

  async getWorkshopByCompany(companyId: string): Promise<any> {
    const endpoint = endpoints.GET_WORKSHOPS.replace(
      '{companyId}',
      companyId ?? '',
    );
    const result = await APIClient.get(endpoint);

    return result?.data;
  }

  async extractRUV(s3Url: string): Promise<RUV> {
    const HEIMDALL_API = endpoints.HEIMDALL_API;
    const response = await axios.get(
      `${HEIMDALL_API}/ruv-extraction?s3Url=${s3Url}`,
    );

    return response.data;
  }
  async getFieldsTemplates(formId: string, companyId: string): Promise<any> {
    const endpoint = endpoints.GET_FIELDS_TEMPLATES.replace(
      '{companyId}',
      companyId,
    ).replace('{formTypeId}', formId);
    const result = await APIClient.get(endpoint);

    return result?.data;
  }
  async getVariablesTemplate(formId: string, templateId: string): Promise<any> {
    const endpoint = endpoints.GET_VARIABLES_TEMPLATE.replace(
      '{templateId}',
      templateId,
    ).replace('{formTypeId}', formId);
    const result = await APIClient.get(endpoint);

    return result?.data;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async addVariableTemplate(model: any): Promise<any> {
    const endpoint = endpoints.POST_VARIABLES_TEMPLATE;
    const result = await APIClient.post(endpoint, model);

    return result?.data;
  }
  async deleteVariableTemplate(id: string): Promise<any> {
    const endpoint = endpoints.DELETE_VARIABLES_TEMPLATE.replace('{id}', id);
    const result = await APIClient.post(endpoint);

    return result?.data;
  }

  async uploadDocumentObservation(
    claimUId: string,
    data: { [key: string]: any },
  ): Promise<AxiosResponse> {
    return await APIClient.post(
      `${endpoints.POST_DOCUMENT_OBSERVATION.replace('{ClaimUId}', claimUId)}`,
      data,
    );
  }

  async getDocumentObservationsByClaim(
    claimUId: string,
  ): Promise<DocumentObservation[]> {
    const result = await APIClient.get(
      `${endpoints.GET_DOCUMENT_OBSERVATION.replace('{ClaimUId}', claimUId)}`,
    );

    return result?.data;
  }

  async getDocumentObservationByDocName(
    claimUId: string,
    documentName: string,
  ): Promise<DocumentObservation> {
    const result = await APIClient.get(
      `${endpoints.GET_DOCUMENT_OBSERVATION_BYNAME.replace(
        '{ClaimUId}',
        claimUId,
      )}?documentName=${documentName}`,
    );

    return result?.data?.data;
  }
}
