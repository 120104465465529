export const FormDataFilter = (
  dataObj: Record<string, any>,
): Record<string, string> => {
  const list = { ...dataObj };

  for (const key in dataObj) {
    if (key === 'datosDelPolicia&estuvo') {
      if (dataObj[key] === '1') {
        dataObj[key] = 'X';
      } else {
        dataObj['datosDelPolicia&NoEstuvo'] = 'X';
      }
    } else if (key === 'IndInvestigation') {
      dataObj['IndInvestigation'] = dataObj['IndInvestigation'] === 'true';
    }

    if (
      (key === 'IdSinisterType' || key === 'IdSinisterCause') &&
      dataObj[key].length > 0
    ) {
      dataObj[key] = Number(dataObj[key]);
    }

    if (key === 'Coberturas') {
      dataObj['Coberturas'] = JSON.parse(list['Coberturas']);
    }
  }

  return dataObj;
};
