import {
  Card,
  CardActions,
  CardHeader,
  IconButton,
  Link,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import './index.scss';
import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import DocumentTemplatesClient from 'shared/clients/DocumentTemplatesClient';

const ListDocument: FunctionComponent<any> = ({ setLoader }): JSX.Element => {
  const effectRan = useRef(false);
  const [documents, setDocuments] = useState<any>([]);
  useEffect(() => {
    if (effectRan.current === false) {
      const getDataInitial = async () => {
        setLoader(true);
        const docs = await new DocumentTemplatesClient().getAllTemplates();
        setDocuments(docs);
        setLoader(false);
      };

      getDataInitial();
      effectRan.current = true;
    }
  }, []);

  return (
    <>
      <Typography className={'gridCard-claim__title'}>
        Listado de plantillas
      </Typography>
      {documents.map((doc: any, index: number) => {
        return (
          <Card key={index} className="templateList__card">
            <CardHeader
              title={doc.name}
              action={
                <SpeedDial
                  direction="left"
                  ariaLabel="Plantilla"
                  icon={<SpeedDialIcon />}
                >
                  <SpeedDialAction
                    className="speedDial_button"
                    icon={
                      <Link href={'/editTemplate/' + doc.id}>
                        <EditIcon />
                      </Link>
                    }
                    tooltipTitle="Editar"
                  />
                  <SpeedDialAction
                    className="speedDial_button"
                    icon={
                      <Link href={'/setVariablesTemplate/' + doc.id}>
                        <SettingsIcon />
                      </Link>
                    }
                    tooltipTitle="Asignar variables"
                  />
                </SpeedDial>
              }
            />
          </Card>
        );
      })}
    </>
  );
};

export default ListDocument;
