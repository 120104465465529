import { EventNote } from './event-note';

export interface Event {
  assignedUserId: number | null;
  assignedUserName: string;
  assignedUserEmail: string;
  companyId: number;
  companyName: string;
  countryCode: string | null;
  eventDate: string;
  eventId: number;
  eventRecord: string;
  indExpressAdjust: boolean;
  inspectorId: number;
  inspectorName: string;
  ownerName: string;
  plate: string;
  providerCode: string;
  providerName: string;
  statusId: number;
  statusName: string;
  insurance: string | null;
  notes?: Array<EventNote>;
  nonAttended: number;
  siseReport?: string;
  siseSiniestro?: string;
  indEstimate?: boolean;
  audited?: boolean;
  indRespAAP?: boolean;
  indInvestigation?: boolean;
  investigation?: string;
  tagId?: number;
  tagName?: string;
}

export const eventDefault: Event = {
  eventRecord: '0',
  assignedUserId: 0,
  assignedUserName: '',
  assignedUserEmail: '',
  companyId: 0,
  companyName: '',
  countryCode: '',
  eventDate: '',
  eventId: 0,
  indExpressAdjust: false,
  inspectorId: 0,
  inspectorName: '',
  ownerName: '',
  plate: '',
  providerCode: '',
  providerName: '',
  statusId: 0,
  statusName: '',
  insurance: '',
  nonAttended: -1,
  siseReport: '',
  siseSiniestro: '',
  indEstimate: false,
  audited: false,
  indRespAAP: false,
  indInvestigation: false,
  investigation: '',
};
