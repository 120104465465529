import { Close, Send } from '@mui/icons-material';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import clsx from 'clsx';
import Avatar from 'components/common/avatar/Avatar';
import Loader from 'components/common/loader/Loader';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { IUserContext, UserContext } from 'context/user';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import useClickedOutside from 'shared/hooks/useClickedOutside';
import { NoteType } from 'types/common';
import { Event } from 'types/event';
import NotesList from './NotesList';
import styles from './override-mui-styles';
import './styles.scss';

interface Props {
  visible: boolean;
  event: Event | null;
  showNotes?: boolean;
  onAddNote(
    eventId: number,
    comment: string,
    noteTypeId?: number,
  ): Promise<boolean>;
  onRefresh(event: Event): void;
  onClose(): any;
  inspectorName: string | null;
  noteTypes?: NoteType[] | [];
}

const NotesModal = (props: Props): JSX.Element => {
  const classes = styles();
  const {
    state: { currentUser },
  } = useContext(UserContext) as IUserContext;
  const modalRef = React.createRef<HTMLDivElement>();
  const notes = props.event?.notes || [];
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [noteTypeSelected, setNoteTypeSelected] = React.useState('1');

  const { state: inspectionState } = useContext<IInspectionContext>(
    InspectionContext,
  );

  const inspectorName = inspectionState?.inspectorUserName
    ? inspectionState?.inspectorUserName
    : '';

  const history = useHistory();
  const isInspectorForm = history.location.pathname.includes('/inspector/');

  const saveNote = async (): Promise<void> => {
    if (props.event) {
      setLoading(true);
      const user =
        inspectorName && inspectorName.trim().length > 0
          ? '[' + inspectorName + ']: '
          : '';
      await props.onAddNote(
        props.event?.eventId,
        user + comment,
        parseInt(noteTypeSelected),
      );
      setComment('');

      props.onRefresh(props.event);
      setLoading(false);
    }
  };

  const setText = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setComment(event.target.value);
  };

  useClickedOutside(modalRef, (callback) => {
    if (callback && props.visible) {
      props.onClose();
    }
  });

  const title = props.showNotes
    ? `Notas de evento ${props?.event?.eventRecord + ' ' + inspectorName}`
    : 'Añadir nota';

  const handleNoteTypeChange = (event) => {
    const selectedValue = event.target.value;
    setNoteTypeSelected(selectedValue);
  };

  return (
    <div
      ref={modalRef}
      className={clsx('notesModal', {
        'notesModal--hide': !props.visible,
        'no-notes': !props.showNotes,
        grid: props.showNotes,
        'notesModal--inspector': isInspectorForm,
      })}
    >
      <Loader isActive={loading} />
      {props.event && (
        <>
          <div className="notesModal__notes">
            <div className="notesModal__title">
              <h3>{title}</h3>
              <IconButton
                className={classes.closeIconButton}
                onClick={props.onClose}
              >
                <SvgIcon className={classes.closeIcon}>
                  <Close />
                </SvgIcon>
              </IconButton>
            </div>
            {props.showNotes && (
              <NotesList
                isInspectorForm={isInspectorForm}
                notes={notes}
                noteTypes={props.noteTypes ?? []}
                onRefresh={(eventId) => props.onRefresh({ eventId } as Event)}
              />
            )}
          </div>

          <div className="notesModal__clasification">
            <div className="labelClasification">Tipo de Nota: </div>
            <div>
              <select
                name="noteTypes"
                id="noteTypes"
                className="selectClasification"
                value={noteTypeSelected}
                onChange={handleNoteTypeChange}
              >
                {props.noteTypes?.map((noteType) => (
                  <option key={noteType.id} value={noteType.id}>
                    {noteType.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div
            className={clsx('notesModal__addNote', {
              'notesModal__addNote--hidden': !props.showNotes && !props.visible,
            })}
          >
            <div>
              <Avatar size="small" name={currentUser.name} userIndex={0} />
              <textarea
                className={clsx('notesModal__textArea', {
                  'notesModal__textArea--inspector': isInspectorForm,
                })}
                value={comment}
                rows={isInspectorForm ? 2 : 4}
                placeholder="Escribir nota..."
                onChange={setText}
              ></textarea>
              {isInspectorForm && (
                <IconButton
                  className="notesModal__inspector-addButton"
                  onClick={saveNote}
                >
                  <Send />
                </IconButton>
              )}
            </div>
            {!isInspectorForm && (
              <div className="notesModal__addButton">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.addNoteButton}
                  onClick={saveNote}
                >
                  Agregar nota
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default NotesModal;
