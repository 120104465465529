const API_URL = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL_CTRACKER = process.env.REACT_APP_API_URL_CLAIMS_TRACKER;
const API_USR_CTRACKER = process.env.REACT_APP_API_USERNAME_CLAIMS_TRACKER;
const API_PSW_CTRACKER = process.env.REACT_APP_API_PASSWORD_CLAIMS_TRACKER;
const BUCKET_NAME_CLAIMS_TRACKER =
  process.env.REACT_APP_BUCKET_NAME_CLAIMS_TRACKER;
const BUCKET_REGION_CLAIMS_TRACKER =
  process.env.REACT_APP_BUCKET_REGION_CLAIMS_TRACKER;
const API_URL_DOCUMENT_TEMPLATES = process.env.REACT_APP_URL_DOCUMENT_TEMPLATES;

export {
  API_KEY,
  API_URL,
  API_URL_CTRACKER,
  API_USR_CTRACKER,
  API_PSW_CTRACKER,
  BUCKET_NAME_CLAIMS_TRACKER,
  BUCKET_REGION_CLAIMS_TRACKER,
  API_URL_DOCUMENT_TEMPLATES,
};
