import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SkeletonGrid from 'components/common/skeleton/skGrid';
import { getUserInitialScreen, IUserContext, UserContext } from 'context/user';

const InProgress = (): JSX.Element => {
  const { state } = useContext<IUserContext>(UserContext);
  const { currentUserStatus } = state;
  const currentHistory = useHistory();

  useEffect(() => {
    setTimeout(() => {
      if (!currentUserStatus.isLogin || !currentUserStatus.token) {
        currentHistory.push('/');
      } else {
        currentHistory.push(getUserInitialScreen());
      }
    }, 3000);
  }, []);

  return <SkeletonGrid />;
};

export default InProgress;
