import './index.scss';
import React, { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { Field } from 'formik';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatNumber, maskValue, formatNumberMoney } from 'shared/utils';
import { IClaimForm } from 'types/claimsTracker';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import CATALOGS from 'static/constants/catalogs';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '../button';
import APIClient, { endpoints } from 'shared/api';
import { Rol } from 'types/common';
import { number } from 'joi';

interface Props {
  claimUId: string;
  claimForm?: IClaimForm;
  notifySubrogation?: boolean;
  disabled: boolean;
}

const SubrogationFinalResolution: FunctionComponent<Props> = ({
  claimUId,
  claimForm,
  notifySubrogation,
  disabled,
}): JSX.Element => {
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [confirmApplySubroga, setConfirmApplySubroga] = useState<boolean>(
    false,
  );
  const [subrogIsApply, setSubrogIsApply] = useState<boolean>(false);
  const [subrogAmountRecovered, setSubrogAmountRecovered] = useState<boolean>(
    false,
  );
  const [subrogRecoveryAmount, setSubrogRecoveryAmount] = useState<string>('');
  const [subrogNonRecoveryCause, setSubrogNonRecoveryCause] = useState<string>(
    '',
  );
  const [subrogTxtDate, setSubrogTxtDate] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const [insuranceFinal, setInsuranceFinal] = useState('');
  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledLocal, setDisabledLocal] = useState(disabled);

  const handleIsRecoveredChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setSubrogAmountRecovered(value === 'true');
    setSubrogRecoveryAmount('');
    setSubrogNonRecoveryCause('');
    setSubrogTxtDate('');

    if (firstRender) {
      setFirstRender(false);
    }
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numberValue = value.replace(/[^0-9.-]+/g, '');
    setSubrogRecoveryAmount(numberValue);
  };

  const handleNonRecoveryCauseChange = (event: SelectChangeEvent) => {
    setSubrogNonRecoveryCause(event.target.value);
  };

  const submitSubrogation = async (confirmSave = false): Promise<void> => {
    const amountRecovered = subrogAmountRecovered;
    const insuranceData = insuranceFinal;
    const recoveryAmount = subrogAmountRecovered
      ? parseFloat(subrogRecoveryAmount)
      : null;
    const nonRecoveryCause = !subrogAmountRecovered
      ? subrogNonRecoveryCause
      : null;
    const subrogationDate =
      confirmSave && dayjs(subrogTxtDate).isValid() ? subrogTxtDate : null;
    setConfirmApplySubroga(confirmSave && dayjs(subrogTxtDate).isValid());

    await new ClaimsTrackerClient().applySubrogation(
      claimUId,
      true,
      null,
      amountRecovered,
      recoveryAmount,
      nonRecoveryCause,
      subrogationDate,
      insuranceData,
      Number(localStorage.getItem('userId')),
      null,
    );
  };

  const confirmSubrogation = (confirmSave = false): void => {
    (async (): Promise<void> => {
      await submitSubrogation(confirmSave);
      setOpenConfirm(false);
    })();
  };

  const loadData = () => {
    if (Number(localStorage.getItem('rolId') ?? 0) === Rol.CLAIMS_ASSISTANT) {
      setDisabledLocal(false);
    }
    if (claimForm?.info?.subrogation?.subrogationDate) {
      setSubrogTxtDate(claimForm?.info?.subrogation?.subrogationDate);
      setConfirmApplySubroga(true);
    }

    if (claimForm?.info?.subrogation?.recoveryAmount) {
      setSubrogRecoveryAmount(claimForm?.info?.subrogation?.recoveryAmount);
    }

    if (claimForm?.info?.subrogation?.amountRecovered) {
      setSubrogAmountRecovered(claimForm?.info?.subrogation?.amountRecovered);
    }

    if (claimForm?.info?.subrogation?.nonRecoveryCause) {
      setSubrogNonRecoveryCause(claimForm?.info?.subrogation?.nonRecoveryCause);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [claimForm?.info?.case]);

  useEffect(() => {
    setDisabledSave(
      !(
        subrogTxtDate !== '' &&
        ((subrogAmountRecovered && parseFloat(subrogRecoveryAmount) !== 0) ||
          (!subrogAmountRecovered && subrogNonRecoveryCause !== ''))
      ),
    );
  }, [
    subrogTxtDate,
    subrogAmountRecovered,
    subrogRecoveryAmount,
    subrogNonRecoveryCause,
  ]);

  return (
    <>
      <Box className="subrogation">
        {confirmApplySubroga && dayjs(subrogTxtDate).isValid() ? (
          <Box>
            <p className="subrogation__data--title-result">
              Valor de subrogación:{' '}
              {formatNumberMoney(parseFloat(subrogRecoveryAmount))}
            </p>
            <p className="subrogation__data--subtitle-result">
              Causa de no recuperación:{' '}
              {subrogNonRecoveryCause ? subrogNonRecoveryCause : 'N/A'}
            </p>
            <p className="subrogation__data--subtitle-result">
              Fecha de Pago: {dayjs(subrogTxtDate).format('YYYY-MM-DD')}
            </p>
          </Box>
        ) : (
          <>
            <Box className="subrogation__data" sx={{ mt: 2 }}>
              <Box>
                <label>¿Se recuperó algún monto?</label>
                <br />
                <FormControl sx={{ textAlign: 'left' }}>
                  <RadioGroup
                    row
                    aria-labelledby="amount-recovered-subrogation"
                    name="position"
                    defaultValue={
                      claimForm?.info?.subrogation?.amountRecovered ?? false
                    }
                    onChange={handleIsRecoveredChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Sí"
                      labelPlacement="start"
                      disabled={disabledLocal}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                      labelPlacement="start"
                      disabled={disabledLocal}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>

            <Box className="subrogation__data">
              {subrogAmountRecovered && (
                <Box className="subrogation__amount">
                  <br />
                  <Field
                    key="recovery-amount"
                    as={TextField}
                    type="text"
                    label="Valor de recuperación"
                    name="recovery-amount"
                    value={formatNumber(subrogRecoveryAmount.toString())}
                    onChange={handleAmountChange}
                  />
                </Box>
              )}

              {!subrogAmountRecovered && (
                <Box>
                  <br />
                  <FormControl
                    sx={{ minWidth: 235, backgroundColor: '#ffffff' }}
                  >
                    <InputLabel id="nonRecoveryCause">
                      Causa de no recuperación
                    </InputLabel>
                    <Select
                      labelId="nonRecoveryCause"
                      id="slct-NonRecoveryCause"
                      value={subrogNonRecoveryCause}
                      onChange={handleNonRecoveryCauseChange}
                      label="Causa de no recuperación"
                    >
                      <MenuItem value={'Option 1'}>Option 1</MenuItem>
                      <MenuItem value={'Option 2'}>Option 2</MenuItem>
                      <MenuItem value={'Option 3'}>Option 3</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}

              <Box>
                <br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Fecha"
                    format={CATALOGS.getCountryFormat().dateTimeFormat}
                    value={dayjs(subrogTxtDate)}
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        margin: 'none',
                        'aria-label': 'change date',
                      },
                    }}
                    onChange={(date: any) => {
                      if (date) {
                        const dayjsDate = dayjs(date);
                        if (dayjsDate.isValid()) {
                          setSubrogTxtDate(dayjsDate.format('YYYY-MM-DD'));
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>

            <Box className="subrogation__data">
              <Box>
                <br />
                <Button
                  disabled={disabledSave}
                  text="Finalizar Subrogación"
                  type="button"
                  onClick={() => setOpenConfirm(true)}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Dialog open={openConfirm}>
        <DialogTitle id="confirm-restore-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-payment-modal"
            style={{ width: '400px', height: '120px' }}
          >
            <br />
            ¿Está seguro que desea aplicar los cambios de subrogación?
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            text="Cancelar"
            variant="outlined"
            color="secondary"
            onClick={() => setOpenConfirm(false)}
          />
          <Button
            text="Sí, confirmar"
            variant="contained"
            color="primary"
            onClick={() => {
              confirmSubrogation(true);
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubrogationFinalResolution;
