import Grid from '@mui/material/Grid';
import React, { FunctionComponent } from 'react';
import BrowserIcon from 'assets/icons/chrome-icon.png';
import { List, ListItem, ListItemText, Typography } from '@mui/material';

const IncompatibleView: FunctionComponent = () => {
  const isIOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIphone = userAgent.indexOf('iphone') > -1;
    const isIpad = userAgent.indexOf('ipad') > -1;

    return isIphone || isIpad;
  };

  const redirectClick = (): void => {
    let url = 'https://play.google.com/store/search?q=chrome&c=apps';

    if (isIOS()) {
      url = 'https://www.apple.com/es/search/chrome?src=globalnav';
    }

    window.open(url, '_blank', 'noreferrer');
  };

  const renderSteps = (): JSX.Element => {
    return (
      <>
        <List>
          <Typography gutterBottom>
            ¡Optimiza tu experiencia de navegación para SIC configurando Google
            Chrome como tu navegador por defecto!
          </Typography>
          <br />
          <Typography gutterBottom>Sigue estos simples pasos:</Typography>
          {isIOS() ? (
            <>
              <ListItem>
                <ListItemText
                  primary="1. Abre la Configuración:"
                  secondary={
                    <Typography className="li-text-custom">
                      Desde la pantalla de inicio de tu iPhone, toca el ícono de{' '}
                      <b>Configuración</b> con forma de engranaje.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="2. Desplázate hacia Abajo:"
                  secondary={
                    <Typography className="li-text-custom">
                      Desplázate hacia abajo y busca la sección <b>Chrome</b> o{' '}
                      <b>Navegador</b> en la lista de aplicaciones.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="3. Selecciona Chrome:"
                  secondary={
                    <Typography className="li-text-custom">
                      Toca la entrada de <b>Chrome</b> para acceder a las
                      configuraciones específicas de la aplicación.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="4. App Predeterminada para Navegador:"
                  secondary={
                    <Typography className="li-text-custom">
                      Dentro de la configuración de Chrome, busca la opción{' '}
                      <b>App Predeterminada para Navegador</b> o{' '}
                      <b>Navegador Predeterminado</b>.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="5. Elige Chrome:"
                  secondary={
                    <Typography className="li-text-custom">
                      Selecciona &rsquo;Google Chrome&rsquo; en la lista de
                      opciones disponibles. Si Chrome no aparece, asegúrate de
                      tenerlo instalado desde la{' '}
                      <a
                        className="app-link"
                        rel="noopener noreferrer"
                        onClick={redirectClick}
                      >
                        App Store
                      </a>
                      .
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="6. Confirma tu Elección:"
                  secondary={
                    <Typography className="li-text-custom">
                      Si se te solicita confirmar el cambio de navegador
                      predeterminado, toca <b>Aceptar</b> o <b>Confirmar</b>{' '}
                      para completar el proceso.
                    </Typography>
                  }
                />
              </ListItem>
            </>
          ) : (
            <>
              <ListItem>
                <ListItemText
                  primary="1. Abre la Configuración:"
                  secondary={
                    <Typography className="li-text-custom">
                      Desliza hacia abajo desde la parte superior de la pantalla
                      para acceder al menú de notificaciones y toca el ícono de{' '}
                      <b>Configuración</b>.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="2. Selecciona Aplicaciones:"
                  secondary={
                    <Typography className="li-text-custom">
                      En la sección <b>Dispositivo</b>, busca y selecciona{' '}
                      <b>Aplicaciones</b> o <b>Aplicaciones y notificaciones</b>
                      , según la versión de Android que estés utilizando.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="3. Administrar Aplicaciones:"
                  secondary={
                    <Typography className="li-text-custom">
                      En la lista de aplicaciones, busca y selecciona{' '}
                      <b>Aplicaciones predeterminadas</b> o{' '}
                      <b>Todas las aplicaciones</b>.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="4. Elige Navegador:"
                  secondary={
                    <Typography className="li-text-custom">
                      Encuentra la categoría de <b>Navegador</b> o{' '}
                      <b>Navegador predeterminado</b>. Luego, selecciona la
                      aplicación que actualmente está configurada como navegador
                      por defecto.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="5. Selecciona Google Chrome:"
                  secondary={
                    <Typography className="li-text-custom">
                      Selecciona &rsquo;Google Chrome&rsquo; de la lista de
                      navegadores disponibles. Si Chrome no aparece en la lista,
                      asegúrate de tenerlo instalado desde la tienda de
                      aplicaciones de{' '}
                      <a
                        className="app-link"
                        rel="noopener noreferrer"
                        onClick={redirectClick}
                      >
                        Google Play
                      </a>
                      .
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="6. Confirma tu Elección:"
                  secondary={
                    <Typography className="li-text-custom">
                      Te llevará a una pantalla de confirmación. Toca{' '}
                      <b>Aceptar</b> o <b>Establecer como predeterminado</b>{' '}
                      para confirmar que deseas usar Google Chrome como tu
                      navegador por defecto.
                    </Typography>
                  }
                />
              </ListItem>
            </>
          )}
        </List>
      </>
    );
  };

  return (
    <>
      <div className="incompatible-view">
        <Grid container spacing={2}>
          <Grid xs={2}></Grid>
          <Grid xs={8} className="incompatible-view__bodyMsg">
            <img id="browser-icon" src={BrowserIcon} alt="Browser" />
            {renderSteps()}
            <p id="foot-signed">
              {' '}
              Powered by <span>Connect Assistance</span>
            </p>
          </Grid>
          <Grid xs={2}></Grid>
        </Grid>
      </div>
    </>
  );
};

export default IncompatibleView;
