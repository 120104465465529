import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { globalCss } from 'assets/connect/styles/_variables';

export default makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      width: '9rem',
      backgroundColor: globalCss.white,
      height: '42px',
      borderRadius: '5px',
      minWidth: '164px',
      '&:last-of-type': {
        marginLeft: '0',
      },
      border: '1px solid #E5E5E5',
    },
    inputInput: {
      // vertical padding + font size from searchIcon
      padding: '6px 15px',
      transition: theme.transitions.create('width'),
      width: '100%',
      '&::placeholder': {
        color: globalCss.blueBayoux,
        fontFamily: 'Connect',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: '200',
      },
    },
    icon: {
      padding: 0,
    },
    iconArrow: {
      color: globalCss.blueBayoux,
    },
    iconBell: {
      color: globalCss.cadetBlue,
    },
    iconSearch: {
      marginLeft: '4px',
    },
  }),
);
