import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import './styles.scss';
import React, { FC } from 'react';

const InfoTable: FC<{
  headers: JSX.Element;
  rows: JSX.Element;
  size?: 'small' | 'medium' | undefined;
}> = ({ headers, rows, size }) => {
  return (
    <TableContainer className="info-table">
      <Table aria-label="simple table" size={size ?? 'medium'}>
        <TableHead>
          <TableRow>{headers}</TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default InfoTable;
