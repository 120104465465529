import React from 'react';
import clsx from 'clsx';

import LoaderImg from 'assets/icons/link_broken.svg';
import './styles.scss';

interface Props {
  show: boolean;
}

const InactiveScreen = (props: Props): JSX.Element => {
  const overlayStatus = props.show
    ? 'inactiveScreen--on'
    : 'inactiveScreen--off';

  return (
    <div className={clsx('inactiveScreen', overlayStatus)}>
      <img className="inactiveScreen__icon" src={LoaderImg} alt="SIC" />
      <h3>Inspección inactiva</h3>
    </div>
  );
};

export default InactiveScreen;
