import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import Lightbox from 'rhino-react-image-lightbox-rotate';
import { EventPhoto } from 'types/images';
import 'react-image-lightbox/style.css';
import './Gallery.scss';
import { codeToNumber } from 'shared/utils';

interface GalleryProps {
  imageToShow: string;
  images: EventPhoto[];
  isOpen: boolean;
  onClose: (source?: string, rotation?: number) => void;
}

const getGeolocation = (awsUrl: string): string => {
  let refGeolocation = '';
  if (awsUrl.includes('%C2%AC') === true) {
    const splittedURL = awsUrl.split('%C2%AC');
    refGeolocation =
      'LATITUD: ' +
      codeToNumber(splittedURL[1]) +
      ', LONGITUD: ' +
      codeToNumber(splittedURL[2]);
  }

  return refGeolocation;
};

const Gallery: FunctionComponent<GalleryProps> = (props): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState(
    props.images.findIndex((i) => i.awsUrl === props.imageToShow),
  );
  const previousPhoto =
    props.images[(currentIndex + 1) % props.images.length]?.awsUrl || '';
  const currentPhoto = props.images[currentIndex]?.awsUrl || '';
  const nexPhoto =
    props.images[(currentIndex + props.images.length - 1) % props.images.length]
      ?.awsUrl || '';

  const moveToPrevious = (): void => {
    if (rotate > 0) {
      onCloseHandler(); // Detect posible rotate trigger
    }
    setCurrentIndex(
      (currentIndex + props.images.length - 1) % props.images.length,
    );
    handleImageLoad();
  };

  const moveToNext = (): void => {
    if (rotate > 0) {
      onCloseHandler(); // Detect posible rotate trigger
    }
    setCurrentIndex((currentIndex + 1) % props.images.length);
    handleImageLoad();
  };

  const [rotate, setRotate] = useState(0);
  const lightboxRef = useRef<any>(null);

  useEffect(() => {
    if (!props.images.length) {
      props.onClose();
    }
  }, [props]);

  useEffect(() => {
    if (currentPhoto === '') {
      moveToPrevious();
    }
  });

  const onCloseHandler = () => {
    props.onClose(props.images[currentIndex]?.awsUrl || '', rotate);
  };

  const handleImageLoad = () => {
    const { current: lightboxInstance } = lightboxRef;
    if (lightboxInstance) {
      lightboxInstance.setState((prevState) => ({
        ...prevState,
        rotate: props.images[currentIndex].rotate,
      }));
    }
  };

  return (
    <div className="gallery">
      <Lightbox
        mainSrc={currentPhoto}
        nextSrc={previousPhoto}
        prevSrc={nexPhoto}
        onCloseRequest={onCloseHandler}
        onMovePrevRequest={moveToPrevious}
        onMoveNextRequest={moveToNext}
        ref={lightboxRef}
        imageCaption={getGeolocation(currentPhoto)}
        onImageRotate={(rotate: number) => {
          const newRotate = rotate < 360 ? rotate : 0;
          if (rotate >= 360 && lightboxRef.current) {
            lightboxRef.current.setState((prevState) => ({
              ...prevState,
              rotate: 0,
            }));
          }
          props.images[currentIndex].rotate = newRotate;
          setRotate(newRotate);
        }}
        onImageLoad={handleImageLoad}
        imagePadding={10}
      ></Lightbox>
    </div>
  );
};

export default Gallery;
