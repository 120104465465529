import React from 'react';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import { Close } from '@mui/icons-material';
import clsx from 'clsx';
import DriverList from '../DriverList';
import styles from './override-mui-styles';
import './GridModal.scss';

interface Props {
  visible: boolean;
  referenceId: string;
  referenceTitle: string;
  gridType: string;
  onClose(): any;
}

const GridModal = (props: Props): JSX.Element => {
  const classes = styles();
  const modalRef = React.createRef<HTMLDivElement>();

  const renderDataTitle = (): string => {
    switch (props.gridType.toUpperCase()) {
      case 'DRIVERS':
        return `Conductores (${props.referenceTitle})`;
      default:
        return '';
    }
  };

  const renderDataInfo = (): JSX.Element => {
    switch (props.gridType.toUpperCase()) {
      case 'DRIVERS':
        return <DriverList userId={props.referenceId} />;
      default:
        return <></>;
    }
  };

  return (
    <div
      ref={modalRef}
      className={clsx('gridModal', {
        'gridModal--hide': !props.visible,
      })}
    >
      {props.visible && (
        <>
          <div className="gridModal__container">
            <div className="gridModal__title">
              <h3>{renderDataTitle()}</h3>
              <IconButton
                className={classes.closeIconButton}
                onClick={props.onClose}
              >
                <SvgIcon className={classes.closeIcon}>
                  <Close />
                </SvgIcon>
              </IconButton>
            </div>

            {renderDataInfo()}
          </div>
        </>
      )}
    </div>
  );
};

export default GridModal;
