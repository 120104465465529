import {
  LocationSearch,
  Map,
  SceneTypes,
  Sketch,
} from '@connect-technology/connect-react-components-lib';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLoadScript } from '@react-google-maps/api';
import InputField, { FieldTypes } from '../section/InputField';
import { Button, Card, CardActionArea, CardMedia } from '@mui/material';
import clsx from 'clsx';
import './Scene.scss';

import ParkingAIcon from '../../../assets/icons/parking-scenes/parking-A.svg';
import ParkingBIcon from '../../../assets/icons/parking-scenes/parking-B.svg';

import RoadAPhoto from '../../../assets/icons/road-scenes/road-A-photo.svg';
import RoadBPhoto from '../../../assets/icons/road-scenes/road-B-photo.svg';
import RoadCPhoto from '../../../assets/icons/road-scenes/road-C-photo.svg';
import RoadDPhoto from '../../../assets/icons/road-scenes/road-D-photo.svg';
import RoadEPhoto from '../../../assets/icons/road-scenes/road-E-photo.svg';
import RoadFPhoto from '../../../assets/icons/road-scenes/road-F-photo.svg';
import RoadGPhoto from '../../../assets/icons/road-scenes/road-G-photo.svg';
import RoadHPhoto from '../../../assets/icons/road-scenes/road-H-photo.svg';
import RoadIPhoto from '../../../assets/icons/road-scenes/road-I-photo.svg';
import sketchImg from '../../../assets/images/croquis.png';
import {
  IInspectionContext,
  InspectionContext,
  saveSketch,
} from 'context/inspections';
import { ActionType } from 'types/action';
import localforage from 'localforage';

const mock = () => null;

const LIBS: any[] = ['places'];

const PARKING_SCENES = [
  {
    value: 'parkingA',
    icon: ParkingAIcon,
    letter: 'A',
  },
  {
    value: 'parkingB',
    icon: ParkingBIcon,
    letter: 'B',
  },
];

const ROAD_SCENES = [
  {
    value: 'roadA',
    icon: RoadAPhoto,
    letter: 'A',
  },
  {
    value: 'roadB',
    icon: RoadBPhoto,
    letter: 'B',
  },
  {
    value: 'roadC',
    icon: RoadCPhoto,
    letter: 'C',
  },
  {
    value: 'roadD',
    icon: RoadDPhoto,
    letter: 'D',
  },
  {
    value: 'roadE',
    icon: RoadEPhoto,
    letter: 'E',
  },
  {
    value: 'roadF',
    icon: RoadFPhoto,
    letter: 'F',
  },
  {
    value: 'roadG',
    icon: RoadGPhoto,
    letter: 'G',
  },
  {
    value: 'roadH',
    icon: RoadHPhoto,
    letter: 'H',
  },
  {
    value: 'roadI',
    icon: RoadIPhoto,
    letter: 'I',
  },
];

const options = ['map|Ubicación', 'parking|Estacionamiento', 'road|Carretera'];

const getSceneFromType = (type: SceneTypes) => {
  switch (type) {
    case SceneTypes.PARKING_A:
    case SceneTypes.PARKING_B:
      return 'parking';
    case SceneTypes.ROAD_A:
    case SceneTypes.ROAD_B:
    case SceneTypes.ROAD_C:
    case SceneTypes.ROAD_D:
    case SceneTypes.ROAD_E:
    case SceneTypes.ROAD_F:
      return 'road';
    default:
      return 'map';
  }
};

const Scene: FunctionComponent<{ editable: boolean }> = (
  props,
): JSX.Element => {
  const {
    dispatch,
    state: { currentInspection },
  } = useContext(InspectionContext) as IInspectionContext;

  const [lat, lng] =
    currentInspection?.coordinates &&
    currentInspection.coordinates.includes(',')
      ? currentInspection.coordinates.split(',')
      : [8.9824, -79.5199];
  const [newLocation, setNewLocation] = useState<boolean>(false);
  const [coords, setCoords] = useState<{ lat: number; lng: number }>({
    lat: +lat,
    lng: +lng,
  });
  const [sketchUrl, setSketchUrl] = useState(currentInspection?.Croquis);
  const [editMode, setEditMode] = useState(!currentInspection?.Croquis);

  const [sceneType, setSceneType] = useState<SceneTypes>(
    (currentInspection?.scene as SceneTypes) || SceneTypes.MAP,
  );
  const [scene, setScene] = useState<string | undefined>(
    getSceneFromType(currentInspection?.scene as SceneTypes),
  );
  const [sceneConfirmed, setSceneConfirmed] = useState(false);
  const [open, setOpen] = useState(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: String(process.env.REACT_APP_GMAPS_KEY),
    libraries: LIBS,
  });

  const sceneChanged = (scene: string | undefined) => {
    setSceneConfirmed(false);
    setScene(scene);
    if (scene === 'map') {
      setSceneType(SceneTypes.MAP);
      const data = { scene: 'map' as SceneTypes };
      saveSketch(data, currentInspection);
      dispatch({ type: ActionType.SET_SKETCH_DATA, payload: data });
    }
  };

  const sketchChanged = (sketchUrl: string) => {
    setSketchUrl(sketchUrl);
    setEditMode(false);
    const data = {
      Croquis: sketchUrl,
      coordinates: `${coords.lat},${coords.lng}`,
    };
    saveSketch(data, currentInspection);
    dispatch({ type: ActionType.SET_SKETCH_DATA, payload: data });
    localforage.removeItem('_sketchState');
  };

  const locationChanged = (lat: number, lng: number) => {
    setCoords({ lat, lng });
    setNewLocation(true);
  };

  const onSceneConfirmed = () => {
    setSceneConfirmed(true);
    const data = { scene: sceneType };
    saveSketch(data, currentInspection);
    dispatch({ type: ActionType.SET_SKETCH_DATA, payload: data });
  };

  useEffect(() => {
    localforage.removeItem('_sketchState');
  }, []);

  const croquisClass = !currentInspection?.scene ? 'croquis__img' : '';

  return (
    <div className="scene">
      {editMode ? (
        <>
          <div>
            <InputField
              formValidation={() => {
                return true;
              }}
              field={{
                type: FieldTypes.DROPDOWN,
                options,
                name: 'scene',
                label: 'Escenario',
                value: '',
                defaultValue: '',
              }}
              defaultValue={scene}
              onChange={(event) => sceneChanged(event?.target?.value)}
              saveFn={mock}
            />

            {!sceneConfirmed && (
              <div className="container">
                {scene === 'map' && (
                  <div className="map-container">
                    <LocationSearch
                      currentAddress=""
                      gmapsLoaded={isLoaded}
                      searchOpen={open}
                      onFocus={() => setOpen(true)}
                      onLocationRetrieved={locationChanged}
                      onPlaceChanged={mock}
                    />
                    <Map
                      gmapsLoaded={isLoaded}
                      location={{ coords, newLocation } as any}
                      onLocationRetrieved={locationChanged}
                    />
                  </div>
                )}
                {scene === 'parking' && (
                  <ScenePhotoOptions
                    scenesPhotosRows={PARKING_SCENES}
                    selectedScene={String(sceneType)}
                    onClickScene={setSceneType}
                  />
                )}
                {scene === 'road' && (
                  <ScenePhotoOptions
                    scenesPhotosRows={ROAD_SCENES}
                    selectedScene={String(sceneType)}
                    onClickScene={setSceneType}
                  />
                )}
              </div>
            )}
            {sceneConfirmed && (
              <div className="sketch-container">
                <Sketch
                  googleMapsApiKey={String(process.env.REACT_APP_GMAPS_KEY)}
                  onConfirm={sketchChanged}
                  coords={coords}
                  sceneType={sceneType === SceneTypes.MAP ? null : sceneType}
                />
              </div>
            )}
            {scene && (
              <Button
                className="confirm"
                variant={sceneConfirmed ? 'outlined' : 'contained'}
                disableElevation
                onClick={() => {
                  if (sceneConfirmed) {
                    sceneChanged(scene);
                  } else {
                    onSceneConfirmed();
                  }
                }}
              >
                {sceneConfirmed ? 'Editar Escenario' : 'Confirmar Escenario'}
              </Button>
            )}
          </div>
        </>
      ) : (
        <>
          {props.editable && (
            <Button
              className="edit"
              onClick={() => setEditMode(true)}
              variant="contained"
              disableElevation
            >
              Editar Croquis
            </Button>
          )}
          <div className="croquis">
            <Card className="croquis__carMedia">
              <CardActionArea>
                <>
                  {!currentInspection?.scene && (
                    <CardMedia
                      className="croquis__diagram"
                      component="img"
                      image={sketchImg}
                      alt="sketch img"
                    />
                  )}
                  <CardMedia
                    className={croquisClass}
                    component="img"
                    image={sketchUrl}
                    alt="sketch img"
                  />
                </>
              </CardActionArea>
            </Card>
          </div>
        </>
      )}
    </div>
  );
};

export default Scene;

interface Props {
  selectedScene: string;
  scenesPhotosRows: Array<any>;
  onClickScene: (value: SceneTypes) => void;
}

const ScenePhotoOptions = React.memo(
  (props: Props): JSX.Element => {
    return (
      <>
        <div className="scenesRow">
          {props.scenesPhotosRows.map((scene: any) => (
            <div
              key={scene.value}
              className={clsx('photoOption', {
                'photoOption--selected': scene.value === props.selectedScene,
              })}
              onClick={() => props.onClickScene(scene.value)}
            >
              <div
                className={clsx('photoOption__label', {
                  'photoOption__label--selected':
                    scene.value === props.selectedScene,
                })}
              >
                {scene.letter}
              </div>
              <img
                className="photoOption__icon"
                src={scene.icon}
                alt={scene.value}
              />
            </div>
          ))}
        </div>
      </>
    );
  },
);
