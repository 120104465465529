import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const COLORS = ['#001D3D', '#f15d2e'];
const BORDER_COLOR = '#f15d2e';
const BORDER_WIDTH = 15;

interface DataItem {
  number: number;
  percentage: number;
  claimantType: string;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { value } = payload[0];

    return (
      <div
        style={{
          background: 'white',
          width: '60px',
          borderRadius: '6px',
          padding: '0.1rem',
          textAlign: 'center',
          fontWeight: '600',
          boxShadow:
            '0px 1.0019142627716064px 8.015314102172852px 0px #6E6E6E40',
        }}
      >
        <p>{`${value}%`}</p>
      </div>
    );
  }

  return null;
};

interface PieChartComponentProps {
  data: DataItem[];
}

const PieChartComponent: React.FC<PieChartComponentProps> = ({ data }) => {
  const sortedData = [...data].sort((a, b) => a.percentage - b.percentage);

  return (
    <ResponsiveContainer width={140} height={120}>
      <PieChart>
        <Pie
          data={sortedData}
          cx="50%"
          cy="50%"
          innerRadius={30}
          outerRadius={50}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="percentage"
          labelLine={true}
        >
          {sortedData.map((entry, index) => {
            return (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                stroke={
                  entry.claimantType === 'Asegurado' ? BORDER_COLOR : 'none'
                }
                strokeWidth={
                  entry.claimantType === 'Asegurado' ? BORDER_WIDTH : 0
                }
              />
            );
          })}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
