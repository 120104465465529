import { createStyles, makeStyles, withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { getAuditLogs } from 'context/inspections';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './styles.scss';

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: 'white',
      color: '#6d7b91',
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#F0F4F7',
      borderBottom: '10px solid white',
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

interface AuditLog {
  auditLog: number;
  eventId: number;
  userId: number;
  message: number;
  createdDate: number;
  userName: string;
  userEmail: string;
}

const fetchAuditLogs = async (
  eventId: number,
  setLogs: (data: AuditLog[]) => void,
) => {
  const data = await getAuditLogs(eventId);
  setLogs(data);
};

const AuditLogs: FunctionComponent = (): JSX.Element => {
  const classes = useStyles();
  const { id } = useParams<{ id: string | undefined }>();
  const [logs, setLogs] = useState<AuditLog[]>([]);

  useEffect(() => {
    fetchAuditLogs(Number(id), setLogs);
  }, [id]);

  return (
    <div className="activity-log">
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Nombre</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Creado</StyledTableCell>
              <StyledTableCell>Descripción</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{log.userName}</StyledTableCell>
                <StyledTableCell>{log.userEmail}</StyledTableCell>
                <StyledTableCell>
                  {format(new Date(log.createdDate), 'd MMMM yyyy h:mm:ss a', {
                    locale: es,
                  })}
                </StyledTableCell>
                <StyledTableCell>{log.message}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AuditLogs;
