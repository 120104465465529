import style from 'override-mui-styles';
import clsx from 'clsx';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import PATH_ROUTES from 'static/constants/path-routes';
import { Button, Grid, Tab, Tabs } from '@mui/material';
import { TabPanel } from 'components/common/tabpanel/TabPanel';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import {
  IInspectionContext,
  InspectionContext,
  getClaimById,
} from 'context/inspections';
import { IClaim } from 'types/claimsTracker';
import { Skeleton } from '@mui/material';
import ClaimInfo from './claimInfo';
import ClaimPayment from './claimPayment';
import ClaimRequest from './claimRequest';
import ClaimStatus from './claimStatus';
import './styles.scss';
import Loader from 'components/common/loader/Loader';
import { ActionType } from 'types/action';
import ClaimFiles from './claimFiles';
import { CmtCountries, CountryCode } from 'types/common';

export default function Claims(): JSX.Element {
  const classes = style();
  const history = useHistory();
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );

  const { id } = useParams<any>();
  const [loading, setLoading] = useState(true);
  const [currentClaim, setCurrentClaim] = useState<IClaim | undefined>(
    undefined,
  );
  const [tabValue, setTabValue] = useState<number>(0);

  const handleBackInspection = () => {
    const searchClaim = inspectionState.claimsTrackerInfo?.filter(
      (f) => f.claimUId === id,
    )[0];
    const eventId = searchClaim?.eventId?.toString() ?? '';
    if (eventId.length > 0) {
      history.replace(PATH_ROUTES.INSPECTION_ID.replace(':id', eventId));
    } else {
      if (
        CmtCountries.includes(
          localStorage.getItem('countryCode') as CountryCode,
        )
      ) {
        history.replace(PATH_ROUTES.EVENTS_CLAIMS);
      } else {
        history.replace(PATH_ROUTES.EVENTS);
      }
    }
  };

  const getClaim = useCallback((UId: string) => {
    if (UId) {
      (async (): Promise<void> => {
        const searchClaim = await getClaimById(UId);
        setCurrentClaim(searchClaim);
        setLoading(false);
      })();
    }
  }, []);

  const refreshClaim = (): void => {
    setLoading(true);
    onLoad(false);
    getClaim(id);
  };

  const onLoad = (value: boolean): void => {
    dispatch({ type: ActionType.SET_LOADING, payload: value });
  };

  const onNotify = (message: string, isWarn = false): void => {
    dispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: true,
        alertMessage: message,
        alertType: isWarn ? 'warning' : 'success',
      },
    });
  };

  const skeletonLoader = (): JSX.Element => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Skeleton variant="rectangular" height={50} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rectangular" height={50} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rectangular" height={50} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rectangular" height={50} />
          </Grid>
        </Grid>
        <br />
        <Skeleton variant="rectangular" height={450} />
      </>
    );
  };

  useEffect(() => {
    getClaim(String(id));
    dispatch({ type: ActionType.SET_LOADING, payload: false });
  }, [id, getClaim, dispatch]);

  return (
    <div className="claims">
      <Loader isActive={inspectionState.loading} />
      <div className="claims__title-container">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h1 className="claims__title-container__title">
              Reclamación # {currentClaim?.claimNumber}
            </h1>
          </Grid>
          <Grid item xs={6}>
            <Button
              id="back-btn"
              variant="contained"
              className={clsx(classes.button, 'claimsForm__button')}
              size="small"
              onClick={handleBackInspection}
            >
              Volver al Expediente
            </Button>
          </Grid>
        </Grid>
      </div>

      <Card className={'gridCard'}>
        <CardContent>
          {loading ? (
            skeletonLoader()
          ) : (
            <>
              <Tabs
                value={tabValue}
                onChange={(event: any, newValue: number) =>
                  setTabValue(newValue)
                }
              >
                <Tab label="Estado" />
                <Tab label="Solicitudes" />
                <Tab label="General" />
                <Tab label="Pagos" />
                <Tab label="Archivos" />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <ClaimStatus
                  currentClaim={currentClaim}
                  onRefresh={refreshClaim}
                  onLoad={onLoad}
                  onNotify={onNotify}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <ClaimRequest
                  currentClaim={currentClaim}
                  onRefresh={refreshClaim}
                  onLoad={onLoad}
                  onNotify={onNotify}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <ClaimInfo currentClaim={currentClaim} />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <ClaimPayment currentClaim={currentClaim} onNotify={onNotify} />
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <ClaimFiles currentClaim={currentClaim} onLoad={onLoad} />
              </TabPanel>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
