import { TableCell, TableRow } from '@mui/material';
import { Check, ReportProblemOutlined } from '@mui/icons-material';
import { ToggleButton } from '@mui/lab';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import {
  getPreinspectionAlerts,
  updatePreinspectionAlert,
} from 'context/preinspections';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IAlert } from 'types/pre-inspection';
import InfoTable from '../../common/info-table';
import './styles.scss';
import { userActionsPermissions } from 'shared/utils';
import { ResourceAction } from 'types/common';

const Alerts: FC = () => {
  const [alerts, setAlerts] = useState<IAlert[]>([]);
  const { partnerKey, id } = useParams<any>();
  const { dispatch } = useContext<IInspectionContext>(InspectionContext);
  const readOnlyPermission = Boolean(
    userActionsPermissions(ResourceAction.VIEW_EVENT_READ_ONLY),
  );

  const fetchAlerts = useCallback(async () => {
    const data: IAlert[] = await getPreinspectionAlerts(+id, dispatch);
    if (data) {
      setAlerts(data);
    }
  }, [id, dispatch]);

  useEffect(() => {
    // GET preinspection Alerts
    fetchAlerts();
  }, [fetchAlerts]);

  const updateAlert = async (alertId: number, isChecked: boolean) => {
    if (!readOnlyPermission) {
      const res = await updatePreinspectionAlert(alertId, !isChecked);
      if (!res.data.error) {
        setAlerts((prevAlerts) => {
          const tmpAlerts = [...prevAlerts];
          const alert = tmpAlerts.find((a) => a.alertId === alertId);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          alert!.isChecked = !isChecked;

          return tmpAlerts;
        });
      }
    }
  };

  const headers = (
    <>
      <TableCell></TableCell>
      <TableCell>Descripción</TableCell>
      <TableCell align="center">Sección</TableCell>
      <TableCell align="center"></TableCell>
    </>
  );

  const rows = (
    <>
      {alerts.map((alert: IAlert) => (
        <TableRow key={alert.alertId}>
          <TableCell>
            <ReportProblemOutlined className="warn" />
          </TableCell>
          <TableCell>{alert.description}</TableCell>
          <TableCell align="center">
            <Link
              to={`/preinspections/${partnerKey}/${id}/sections/${alert.sectionId}`}
            >
              {alert.sectionName.toUpperCase()}
            </Link>
          </TableCell>
          <TableCell align="left">
            <ToggleButton
              value={'check'}
              selected={alert.isChecked}
              onChange={() => {
                updateAlert(alert.alertId, alert.isChecked);
              }}
              disabled={readOnlyPermission}
            >
              <Check /> {alert.isChecked ? 'Revisado' : 'Marcar como revisado'}
            </ToggleButton>
          </TableCell>
        </TableRow>
      ))}
    </>
  );

  return (
    <div className="alerts">
      {alerts.length === 0 ? (
        <p>No se encontraron alertas</p>
      ) : (
        <InfoTable headers={headers} rows={rows} />
      )}
    </div>
  );
};

export default Alerts;
