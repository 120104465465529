import { FormControlLabel, InputLabel, Radio, RadioGroup } from '@mui/material';
import {
  getServiceType,
  saveServiceType,
} from 'context/inspections/inspectionUtils';
import React, { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import { Rol } from 'types/common';

interface ReinspectionProps {
  eventId: any;
}

const Reinspection: FunctionComponent<ReinspectionProps> = ({ eventId }) => {
  const [indVirtualCallInspection, setIndVirtualCallInspection] = useState<
    boolean | undefined
  >(undefined);
  const [
    indConnectInspectorInspection,
    setIndConnectInspectorInspection,
  ] = useState<boolean | undefined>(undefined);
  const [indConnectAdjustInspection, setIndConnectAdjustInspection] = useState<
    boolean | undefined
  >(undefined);
  const [indCsmInspection, setIndCsmInspection] = useState<boolean | undefined>(
    undefined,
  );
  const [indRefund, setIndRefund] = useState<boolean | undefined>(undefined);
  const [indObligatoryAdjust, setIndObligatoryAdjust] = useState<
    boolean | undefined
  >();
  const history = useHistory();
  const showReinspection =
    (Number(localStorage.getItem('rolId')) === Rol.ADMIN ||
      Number(localStorage.getItem('rolId')) === Rol.ADJUSTER) &&
    !history.location.pathname.includes('/inspector/');

  const getServiceTypeData = async (): Promise<void> => {
    const serviceData = await getServiceType(eventId);
    setIndConnectAdjustInspection(serviceData?.indConnectAdjustInspection);
    setIndConnectInspectorInspection(
      serviceData?.indConnectInspectorInspection,
    );
    setIndCsmInspection(serviceData?.indCsmInspection);
    setIndObligatoryAdjust(serviceData?.indObligatoryAdjust);
    setIndRefund(serviceData?.indRefund);
    setIndVirtualCallInspection(serviceData?.indVirtualCallInspection);
  };

  useEffect(() => {
    getServiceTypeData();
  }, []);

  useEffect(() => {
    if (
      indObligatoryAdjust !== undefined ||
      indVirtualCallInspection !== undefined ||
      indConnectInspectorInspection !== undefined ||
      indConnectAdjustInspection !== undefined ||
      indCsmInspection !== undefined ||
      indRefund !== undefined
    ) {
      saveServiceType(
        eventId,
        indObligatoryAdjust,
        indVirtualCallInspection,
        indConnectInspectorInspection,
        indConnectAdjustInspection,
        indCsmInspection,
        indRefund,
      );
    }
  }, [
    indObligatoryAdjust,
    indVirtualCallInspection,
    indConnectInspectorInspection,
    indConnectAdjustInspection,
    indCsmInspection,
    indRefund,
  ]);

  return (
    <>
      {' '}
      {showReinspection && (
        <ul>
          <li>
            <InputLabel key="radAjuste">Ajuste seguro obligatorio</InputLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              name="radAjusteObligatorio"
            >
              <FormControlLabel
                value={true}
                checked={indObligatoryAdjust === true}
                control={<Radio />}
                label="Si"
                onChange={(event) => setIndObligatoryAdjust(true)}
              />
              <FormControlLabel
                value={false}
                checked={indObligatoryAdjust === false}
                control={<Radio />}
                label="No"
                onChange={(event) => setIndObligatoryAdjust(false)}
              />
            </RadioGroup>
          </li>
          <li>
            <InputLabel key="rad1">Re-inspección</InputLabel>
            <ul>
              <li>
                <InputLabel key="rad1">Inspección llamada virtual</InputLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radLlamadaVirtual"
                >
                  <FormControlLabel
                    value={true}
                    checked={indVirtualCallInspection === true}
                    control={<Radio />}
                    label="Si"
                    onChange={(event) => setIndVirtualCallInspection(true)}
                  />
                  <FormControlLabel
                    value={false}
                    checked={indVirtualCallInspection === false}
                    control={<Radio />}
                    label="No"
                    onChange={(event) => setIndVirtualCallInspection(false)}
                  />
                </RadioGroup>
              </li>
              <li>
                <InputLabel key="rad1">
                  Inspección a través de inspector Connect
                </InputLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radInspectorConnect"
                >
                  <FormControlLabel
                    value={true}
                    checked={indConnectInspectorInspection === true}
                    control={<Radio />}
                    label="Si"
                    onChange={(event) => setIndConnectInspectorInspection(true)}
                  />
                  <FormControlLabel
                    value={false}
                    checked={indConnectInspectorInspection === false}
                    control={<Radio />}
                    label="No"
                    onChange={(event) =>
                      setIndConnectInspectorInspection(false)
                    }
                  />
                </RadioGroup>
              </li>
              <li>
                <InputLabel key="rad1">
                  Inspección a través de ajustador Connect
                </InputLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radAjustadorConnect"
                >
                  <FormControlLabel
                    value={true}
                    checked={indConnectAdjustInspection === true}
                    control={<Radio />}
                    label="Si"
                    onChange={(event) => setIndConnectAdjustInspection(true)}
                  />
                  <FormControlLabel
                    value={false}
                    checked={indConnectAdjustInspection === false}
                    control={<Radio />}
                    label="No"
                    onChange={(event) => setIndConnectAdjustInspection(false)}
                  />
                </RadioGroup>
              </li>
              <li>
                <InputLabel key="rad1">Inspección a través de CSM</InputLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radCsm"
                >
                  <FormControlLabel
                    value={true}
                    checked={indCsmInspection === true}
                    control={<Radio />}
                    label="Si"
                    onChange={(event) => setIndCsmInspection(true)}
                  />
                  <FormControlLabel
                    value={false}
                    checked={indCsmInspection === false}
                    control={<Radio />}
                    label="No"
                    onChange={(event) => setIndCsmInspection(false)}
                  />
                </RadioGroup>
              </li>
            </ul>
          </li>
          <li>
            <InputLabel key="rad1">Reembolso </InputLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="si"
              name="radReembolso"
            >
              <FormControlLabel
                value={true}
                checked={indRefund === true}
                control={<Radio />}
                label="Si"
                onChange={(event) => setIndRefund(true)}
              />
              <FormControlLabel
                value={false}
                checked={indRefund === false}
                control={<Radio />}
                label="No"
                onChange={(event) => setIndRefund(false)}
              />
            </RadioGroup>
          </li>
        </ul>
      )}
    </>
  );
};

export default Reinspection;
