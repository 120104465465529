import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import esLocale from 'date-fns/locale/es';
import addMonths from 'date-fns/addMonths';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarContainer from './CalendarContainer';
import DatePickerInput from './DatePickerInput';
import './styles.scss';

interface DateChangeOptions {
  dates: Date | Date[] | null;
  isRangeDates: boolean;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  onChangeStartDate: (date: Date | null) => void;
  onChangeDateRange?: (dates: Date[]) => void;
}

export const onDateChange = ({
  dates,
  isRangeDates,
  setStartDate,
  setEndDate,
  onChangeStartDate,
  onChangeDateRange,
}: DateChangeOptions): void => {
  let start: Date | null, end: Date | null;
  if (Array.isArray(dates)) {
    start = dates[0];
    end = dates[1];
  } else {
    start = dates;
    end = null;
  }

  setStartDate(start);
  setEndDate(end);

  if (!isRangeDates) {
    onChangeStartDate(start);
  }

  if (start && end) {
    if (onChangeDateRange) {
      onChangeDateRange([start, end]);
    }
  }
};

interface Props {
  startDate: Date | null;
  endDate?: Date | null;
  onChangeStartDate: (date: Date | null) => void;
  onChangeEndDate?: (date: Date | null) => void;
  onChangeDateRange?: (dates: Date[]) => void;
}

const DatePicker = React.memo(
  (props: Props): JSX.Element => {
    const [isRangeDates, setIsRangesDates] = useState<boolean>(
      Boolean(props.startDate && props.endDate),
    );

    const [startDate, setStartDate] = useState<Date | null>(props.startDate);
    const [endDate, setEndDate] = useState<Date | null>(props.endDate || null);

    const limitsForDateRange = (type: string): Date | null => {
      if (type.toLocaleLowerCase() === 'min' && isRangeDates) {
        return startDate ? addMonths(startDate, -2) : addMonths(new Date(), -2);
      }

      if (type.toLocaleLowerCase() === 'max' && startDate && isRangeDates) {
        return startDate ? addMonths(startDate, 2) : addMonths(new Date(), 2);
      }

      return null;
    };

    return (
      <ReactDatePicker
        selected={startDate}
        onChange={(dates) =>
          onDateChange({
            dates,
            isRangeDates,
            setStartDate,
            setEndDate,
            onChangeStartDate: props.onChangeStartDate,
            onChangeDateRange: props.onChangeDateRange,
          })
        }
        startDate={startDate}
        endDate={endDate}
        selectsRange={isRangeDates}
        shouldCloseOnSelect={false}
        customInput={
          <DatePickerInput
            startDate={startDate}
            endDate={endDate}
            isRangeDates={isRangeDates}
            setIsRangesDates={setIsRangesDates}
            onChangeStartDate={props.onChangeStartDate}
            onChangeEndDate={props.onChangeEndDate}
          />
        }
        calendarContainer={({ children }) => (
          <CalendarContainer
            isRangeDates={isRangeDates}
            setIsRangesDates={setIsRangesDates}
            onChangeEndDate={props.onChangeEndDate}
          >
            {children}
          </CalendarContainer>
        )}
        locale={esLocale}
        maxDate={limitsForDateRange('max')}
      />
    );
  },
);

export default DatePicker;
