import './styles.scss';
import { globalCss } from 'assets/connect/styles/_variables';
import SICLogo from 'assets/images/SIC-Logo.svg';
import notificationSound from 'assets/audios/short.mp3';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AppBar, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SmsOutlined } from '@mui/icons-material';
import {
  IInspectionContext,
  InspectionContext,
  getNotes,
  saveNote,
} from 'context/inspections';
import { FieldSection } from 'types/section-fields';
import { BrandsId } from 'types/common';
import { Event, eventDefault } from 'types/event';
import { IInspection } from 'types/inspection';
import NotesModal from 'components/events/notes/NotesModal';
import UserNameModal from 'components/events/notes/UserNameModal';
import { TransitionStatesValues } from 'components/events/event-state/ActivityLog';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    backgroundColor: 'white',
  },
  toolbar: {
    minHeight: 128,
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    padding: '8px 16px',
    flexGrow: 1,
    alignSelf: 'flex-start',
    color: globalCss.secondary,
    fontWeight: 'bold',
  },
  subtitle2: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 16,
    flexGrow: 1,
    alignSelf: 'flex-end',
    color: globalCss.secondary,
  },
  tab: {
    color: globalCss.primary,
  },
}));

interface InspectorBarProps {
  tabs: FieldSection[];
  activeSectionId: number;
  policyType: string | null;
  onTabClick: (tab: FieldSection) => void;
}

// Audio for notifications
const notificationAudio = new Audio(notificationSound);

const InspectorTopBar: FunctionComponent<InspectorBarProps> = ({
  activeSectionId,
  tabs,
  policyType,
  onTabClick,
}): JSX.Element => {
  const classes = useStyles();
  const {
    state: { currentInspection },
    state: inspectionState,
    dispatch,
  } = useContext<IInspectionContext>(InspectionContext);

  const [isNoteVisible, setIsNoteVisible] = useState(false);
  const [isUserNameVisible, setIsUserNameVisible] = useState(false);
  const [selectedEventNote, setSelectedEventNote] = useState<Event>({
    ...eventDefault,
    notes: [],
    eventRecord: currentInspection?.EventRecord ?? '',
    eventId: currentInspection?.EventId ?? 0,
  });
  const [notesAmount, setNotesAmount] = useState<number>(0);

  const hasInspectorUserName = inspectionState.inspectorUserName ? true : false;
  const isSURAEvent = currentInspection?.CompanyId === BrandsId.SURA;
  const isCreated =
    currentInspection?.EventStateId === undefined
      ? false
      : currentInspection?.EventStateId < TransitionStatesValues.COMPLETED
      ? true
      : false;

  const handleNoteRefresh = useCallback(
    async (event: Event): Promise<void> => {
      const currentEvent = {
        ...selectedEventNote,
        ...event,
        notes: await getNotes(event.eventId),
      };
      setSelectedEventNote(currentEvent);
    },
    [selectedEventNote],
  );

  const handleNoteClicked = async (
    event: IInspection | null,
  ): Promise<void> => {
    if (event) {
      const currentEvent: Event = {
        ...selectedEventNote,
        notes: await getNotes(Number(event?.EventId)),
        eventRecord: event.EventRecord,
        eventId: event.EventId ?? 0,
      };

      setSelectedEventNote(currentEvent);
      setIsNoteVisible(true);
    }
  };

  const handleUserInspector = (): void => {
    if (hasInspectorUserName) {
      handleNoteClicked(currentInspection);
    } else {
      setIsUserNameVisible(true);
    }
  };

  const handleCloseNotesModal = () => {
    setIsNoteVisible(false);
  };

  const handleCloseUserModal = () => {
    setIsUserNameVisible(false);
  };

  const handleAddNote = useCallback(
    async (eventId: number, comment: string) => {
      const userid =
        Number(localStorage.getItem('userId')) === 0
          ? parseInt(process.env?.REACT_APP_GENERIC_USER ?? '0')
          : Number(localStorage.getItem('userId'));
      const newComment = {
        eventId: eventId,
        userId: Number(userid),
        commentText: comment,
      };

      return saveNote(eventId, newComment);
    },
    [],
  );

  // Setting prev non attended notifications amount
  const prevNotesAmount = useRef<number>();

  useEffect(() => {
    if (selectedEventNote && selectedEventNote.notes) {
      setNotesAmount(selectedEventNote.notes.length);
    }
  }, [selectedEventNote]);

  useEffect(() => {
    let ref: NodeJS.Timeout;
    if (selectedEventNote && isSURAEvent) {
      ref = setInterval(
        (event) => {
          if (hasInspectorUserName) {
            handleNoteRefresh(event);
          }
        },
        5000,
        selectedEventNote,
      );
    }

    return () => {
      clearInterval(ref);
    };
  }, [selectedEventNote, handleNoteRefresh, isSURAEvent]);

  useEffect(() => {
    if (prevNotesAmount.current && notesAmount > prevNotesAmount.current) {
      const soundPromise = notificationAudio.play();
      if (soundPromise !== undefined) {
        soundPromise.catch((e) => {
          // eslint-disable-next-line no-console
          console.log('Error playing sound', e);
        });
      }
    }
    prevNotesAmount.current = notesAmount;
  }, [notesAmount]);

  const showDot = notesAmount > 0;

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <img src={SICLogo} className="topbar-logo" alt="SICLogo" />
          </Grid>
          <Grid item>
            <Grid container direction="column" justifyContent="flex-end">
              <Grid item style={{ textAlign: 'end' }}>
                <Typography
                  className={classes.subtitle2}
                  style={{
                    marginTop: policyType ? 5 : 16,
                  }}
                  variant="subtitle2"
                >
                  Expediente:&nbsp;
                  <span style={{ fontWeight: 'bold' }}>
                    {currentInspection?.EventRecord}
                  </span>
                  {currentInspection?.CompanyId !== null &&
                    currentInspection?.CompanyId === BrandsId.QUALITAS && // QUALITAS ID
                    ((currentInspection?.siseReport !== undefined &&
                      currentInspection?.siseReport.length > 0) ||
                      (currentInspection?.siseSiniestro !== undefined &&
                        currentInspection?.siseSiniestro?.length > 0)) && (
                      <>
                        <br />
                        SISE Reporte:&nbsp;
                        <span style={{ fontWeight: 'bold' }}>
                          {currentInspection?.siseReport}
                        </span>
                        <br />
                        SISE Siniestro:&nbsp;
                        <span style={{ fontWeight: 'bold' }}>
                          {currentInspection?.siseSiniestro}
                        </span>
                      </>
                    )}
                  {currentInspection?.CompanyId !== null &&
                    currentInspection?.CompanyId === 2 && // ASSA ID
                    currentInspection?.siseReport !== undefined &&
                    currentInspection?.siseReport.length > 0 && (
                      <>
                        <br />
                        Reclamo #:&nbsp;
                        <span style={{ fontWeight: 'bold' }}>
                          {currentInspection?.siseReport}
                        </span>
                      </>
                    )}
                </Typography>
              </Grid>
              {policyType && (
                <Grid
                  item
                  justifyContent="flex-end"
                  style={{ textAlign: 'end' }}
                >
                  <Typography className={classes.subtitle2} variant="subtitle2">
                    Tipo de Póliza:&nbsp;
                    <span style={{ fontWeight: 'bold' }}>{policyType}</span>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div style={{ padding: '20px' }}>
          <div style={{ float: 'left' }}>
            <Typography className={classes.title} variant="h5">
              Forma de Inspección
            </Typography>
          </div>
          <div style={{ float: 'left' }}>
            {isSURAEvent && isCreated ? (
              <IconButton onClick={() => handleUserInspector()}>
                <SmsOutlined />
                <span hidden={!showDot} className="notification-dot"></span>
              </IconButton>
            ) : (
              ''
            )}
          </div>
        </div>
        <Tabs
          value={activeSectionId}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons
          aria-label="scrollable auto tabs example"
        >
          {tabs.map((t) => (
            <Tab
              key={t.formSectionId}
              label={t.title}
              className={classes.tab}
              value={t.formSectionId}
              onClick={() => onTabClick(t)}
            />
          ))}
        </Tabs>
      </AppBar>
      <NotesModal
        showNotes
        visible={isNoteVisible}
        event={selectedEventNote}
        onAddNote={handleAddNote}
        onRefresh={handleNoteRefresh}
        onClose={handleCloseNotesModal}
        inspectorName={String(currentInspection?.inspectorUserName)}
      />
      <UserNameModal
        visible={isUserNameVisible}
        onClose={handleCloseUserModal}
        onClick={async () => {
          handleCloseUserModal();
          await handleNoteClicked(currentInspection);
        }}
        dispatch={dispatch}
      />
    </>
  );
};

export default InspectorTopBar;
