import { createContext, useContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import React from 'react';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface SnackbarContextType {
  showSnackbar: (message: string, showRefresh: boolean) => void;
}

const defaultShowSnackbar: SnackbarContextType['showSnackbar'] = () => {
  throw new Error('SnackbarContext not initialized');
};

const SnackbarContext = createContext<SnackbarContextType>({
  showSnackbar: defaultShowSnackbar,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    showRefresh: false,
  });

  const showSnackbar = (message: string, showRefresh: boolean) => {
    setSnackbar({ open: true, message, showRefresh });
  };

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  function handleRefresh(): void {
    window.location.reload();
  }

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbar.open}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="info"
          sx={{ width: '100%' }}
          action={
            <>
              {snackbar.showRefresh ? (
                <Button
                  size="small"
                  color="inherit"
                  type="button"
                  onClick={handleRefresh}
                >
                  Refrescar
                </Button>
              ) : null}
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </>
          }
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSnackbar = () => useContext(SnackbarContext);
