import clsx from 'clsx';
import React from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

interface Props {
  searchFilter: string;
  companyFilter: number;
  countryFilter?: string;
  currentCompanies: any[];
  currentCountries?: any[];
  useCountries: boolean;
  onEnableForm: (show: boolean, isNew: boolean) => void;
  onChangeSearchFilter: (value: any) => void;
  onChangeCompanyFilter: (value: any) => void;
  onChangeCountryFilter?: (value: any) => void;
  onTriggerSearch: (event: any) => void;
}

const UserFilters = (props: Props): JSX.Element => {
  return (
    <>
      <Grid container className="settingsForm__row" spacing={2}>
        <Grid key="grid-input" item>
          <TextField
            key="searchName"
            className={clsx('settingsForm__input')}
            name="searchName"
            label="Filtrar Nombre / Correo"
            defaultValue={props.searchFilter}
            variant="outlined"
            onChange={props.onChangeSearchFilter}
          />
        </Grid>

        <Grid key="grid-select" item>
          {props.useCountries && (
            <FormControl
              variant="outlined"
              className={clsx('settingsForm__input', 'settingsForm__select')}
            >
              <InputLabel
                style={{
                  // Fixes select label drawn with input border
                  backgroundColor: 'white',
                  paddingRight: 5,
                  paddingLeft: 5,
                }}
              >
                Filtrar País
              </InputLabel>
              <Select
                defaultValue={props.countryFilter}
                onChange={props.onChangeCountryFilter}
                name="selectCountry"
              >
                {props.currentCountries &&
                  props.currentCountries.map((option: any) => (
                    <MenuItem key={option.id} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

          {!props.useCountries && (
            <FormControl
              variant="outlined"
              className={clsx('settingsForm__input', 'settingsForm__select')}
            >
              <InputLabel
                style={{
                  // Fixes select label drawn with input border
                  backgroundColor: 'white',
                  paddingRight: 5,
                  paddingLeft: 5,
                }}
              >
                Filtrar Compañía
              </InputLabel>
              <Select
                defaultValue={props.companyFilter}
                onChange={props.onChangeCompanyFilter}
                name="selectCompany"
              >
                <MenuItem key="0" value="0">
                  - Todos -
                </MenuItem>
                {props.currentCompanies.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid key="grid-btn-1" item>
          <Button
            key="btnSearch"
            name="btnSearch"
            variant="outlined"
            disableElevation
            onClick={props.onTriggerSearch}
            className={clsx('settingsForm__toggle-button')}
          >
            Buscar
          </Button>
        </Grid>
        <Grid key="grid-btn-2" item>
          <Button
            key="btnActiveAddUser"
            name="btnActiveAddUser"
            variant="outlined"
            disableElevation
            onClick={() => {
              props.onEnableForm(true, true);
            }}
            className={clsx('settingsForm__toggle-button-2')}
          >
            Agregar Usuario
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UserFilters;
