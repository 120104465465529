import React, { useCallback, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  DialogActions,
  DialogContentText,
  DialogContent,
  Dialog,
  DialogTitle,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import Loader from 'components/common/loader/Loader';
import ValidationLabel from 'components/common/validation-label';
import {
  getUserByGuid,
  IUserContext,
  updateUserProfile,
  UserContext,
} from 'context/user';
import { IUserModel } from 'types/user';
import { ActionType } from 'types/action';
import { CmtCountries, CountryCode, phoneMasks, Rol } from 'types/common';
import { extractContryCode } from 'shared/utils';
import './styles.scss';

const emailWithTldRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const validationSchema = Yup.object({
  firstName: Yup.string().required('Nombre es requerido'),
  lastName: Yup.string().required('Apellido es requerido'),
  email: Yup.string()
    .required('Correo es requerido')
    .test(
      'is-valid-email',
      'Correo no válido: debe tener un dominio válido',
      (value) => emailWithTldRegex.test(value),
    ),
});

const formRulesIsValid = (values: any, setFieldError: any): boolean => {
  if (values.changePassword === true) {
    if (!values.password) {
      setFieldError('password', 'Contraseña es requerida');

      return false;
    }
    if (values.password.length < 8) {
      setFieldError('password', 'Contraseña debe tener al menos 8 caracteres');

      return false;
    }
    if (!/[a-z]/.test(values.password)) {
      setFieldError('password', 'Debe contener al menos una letra minúscula');

      return false;
    }
    if (!/[A-Z]/.test(values.password)) {
      setFieldError('password', 'Debe contener al menos una letra mayúscula');

      return false;
    }
    if (!/[0-9]/.test(values.password)) {
      setFieldError('password', 'Debe contener al menos un número');

      return false;
    }
    if (!/[@$!%*?&#]/.test(values.password)) {
      setFieldError('password', 'Debe contener al menos un carácter especial');

      return false;
    }
    if (values.password !== values.confirmPassword) {
      setFieldError('confirmPassword', 'Las contraseñas deben coincidir');

      return false;
    }
  }

  if (values.contactNumber && String(values.contactNumber).length > 0) {
    const contactData = String(values.contactNumber).replaceAll('_', '');
    const formatPhoneCountry = phoneMasks[values.country ?? ''];
    const formatIsValid =
      contactData.length === String(formatPhoneCountry).length;

    if (values.twoFactorAuthEnabled && !formatIsValid) {
      setFieldError(
        'contactNumber',
        'Digite un # contacto válido para activar el Doble Factor de Autenticación (2FA)',
      );

      return false;
    }

    if (!formatIsValid) {
      setFieldError('contactNumber', 'Digite un # contacto válido');

      return false;
    }
  }

  return true;
};

const ProfileSettings = (): JSX.Element => {
  const { state: userState, dispatch } = useContext<IUserContext>(UserContext);
  const [loading, setLoading] = useState(true);
  const [changePassword, setChangePassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      userGuiId: '',
      userId: 0,
      isMultiCountry: false,
      active: false,
      country: '',
      firstName: '',
      lastName: '',
      email: '',
      contactNumber: '',
      changePassword: false,
      password: '',
      confirmPassword: '',
      ssoEnabled: false,
      twoFactorAuthEnabled: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setFieldError }) => {
      if (formRulesIsValid(values, setFieldError)) {
        onUpdateUser(values);
      }
    },
  });

  const handleCountryChange = (event) => {
    formik.setFieldValue('country', event.target.value);
    formik.setFieldValue('contactNumber', '');
  };

  const handleChangePassword = (event) => {
    const currentValue = !changePassword;
    formik.setFieldValue('changePassword', currentValue);
    setChangePassword(currentValue);
  };

  const onGetUser = useCallback(() => {
    (async (): Promise<void> => {
      console.warn('GET USER');
      const currentUser = JSON.parse(
        localStorage.getItem('currentUser') ?? '{}',
      );

      if (currentUser != null) {
        const resultUser = await getUserByGuid(currentUser.guidID);
        if (resultUser != null) {
          formik.setFieldValue('userGuiId', resultUser?.guidID ?? '');
          formik.setFieldValue('userId', resultUser?.userCompanyID ?? 0);
          formik.setFieldValue('companyId', resultUser?.companyId ?? 0);
          formik.setFieldValue(
            'isMultiCountry',
            resultUser?.isMultiCountry ?? false,
          );
          formik.setFieldValue('firstName', resultUser?.name ?? '');
          formik.setFieldValue('lastName', resultUser?.lastName ?? '');
          formik.setFieldValue('email', resultUser?.email ?? '');
          formik.setFieldValue('contactNumber', resultUser?.cellphone ?? '');
          formik.setFieldValue('ssoEnabled', resultUser?.useSSO ?? false);
          formik.setFieldValue(
            'twoFactorAuthEnabled',
            resultUser?.use2FA ?? false,
          );

          // Validate Country Code
          const countryCodeByNumber = extractContryCode(
            resultUser?.cellphone ?? '',
          );
          formik.setFieldValue('country', countryCodeByNumber);
        }
      }

      setLoading(false);
    })();
  }, []);

  const onUpdateUser = useCallback((formData: any) => {
    setLoading(true);
    setTimeout(() => {
      (async (): Promise<void> => {
        console.warn('UPDATE USER');
        const sendData: IUserModel = {
          guidID: formData.userGuiId,
          name: formData.firstName + ' ' + formData.lastName,
          userName: formData.email,
          email: formData?.email ?? '',
          cellphone: formData.contactNumber,
          use2FA: formData?.use2FA,
          useSSO: formData?.useSSO,
          isNewUser: false,
          companyId: formData?.companyId || 0,
          isMultiCountry: formData?.isMultiCountry || false,
          updatedBy: formData.userId || 0,
          changePW: formData?.changePassword || false,
          password: formData?.changePassword ? formData.confirmPassword : null,
        };

        await updateUserProfile(sendData, dispatch);
        setLoading(false);
      })();
    }, 1000);
  }, []);

  const closeAlert = () => {
    dispatch({
      type: ActionType.SET_ALERT_NEW_USER,
      payload: {
        show: false,
        isError: false,
      },
    });
  };

  useEffect(() => {
    onGetUser();
  }, []);

  return (
    <>
      <div className={clsx('profileSettings')}>
        <h1 className="profileSettings__title">Cuenta de Perfil</h1>
        <Loader isActive={loading} />
        <div className="profileSettingsContainer">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label="Nombre"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName &&
                    formik.errors.firstName && (
                      <ValidationLabel
                        message={formik.errors.firstName ?? ''}
                        isError={true}
                      />
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label="Apellido"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={
                    formik.touched.lastName &&
                    formik.errors.lastName && (
                      <ValidationLabel
                        message={formik.errors.lastName ?? ''}
                        isError={true}
                      />
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Correo"
                  aria-readonly={true}
                  disabled={true}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={
                    formik.touched.email &&
                    formik.errors.email && (
                      <ValidationLabel
                        message={formik.errors.email ?? ''}
                        isError={true}
                      />
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  select
                  label="Código País"
                  value={formik.values.country}
                  onChange={handleCountryChange}
                >
                  <MenuItem value="CRC">CRC +506</MenuItem>
                  <MenuItem value="PAN">PAN +507</MenuItem>
                  <MenuItem value="COL">COL +57</MenuItem>
                  <MenuItem value="PRI">PRI +1</MenuItem>
                  <MenuItem value="MEX">MEX +52</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputMask
                  mask={phoneMasks[formik.values.country ?? '']}
                  value={formik.values.contactNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {() => (
                    <TextField
                      fullWidth
                      id="contactNumber"
                      name="contactNumber"
                      label="# Contacto"
                      error={
                        formik.touched.contactNumber &&
                        Boolean(formik.errors.contactNumber)
                      }
                      helperText={
                        formik.touched.contactNumber &&
                        formik.errors.contactNumber && (
                          <ValidationLabel
                            message={formik.errors.contactNumber ?? ''}
                            isError={true}
                          />
                        )
                      }
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={changePassword}
                      onChange={handleChangePassword}
                    />
                  }
                  label="Cambiar Contraseña"
                />
              </Grid>
              {changePassword && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="password"
                      name="password"
                      label="Nueva Contraseña"
                      type="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password &&
                        formik.errors.password && (
                          <ValidationLabel
                            message={formik.errors.password ?? ''}
                            isError={true}
                          />
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="confirmPassword"
                      name="confirmPassword"
                      label="Confirmar Contraseña"
                      type="password"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword && (
                          <ValidationLabel
                            message={formik.errors.confirmPassword ?? ''}
                            isError={true}
                          />
                        )
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={10} sm={8}></Grid>
              <Grid item xs={12} md={2} sm={4}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Guardar Cambios
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      <Dialog
        open={userState.showCreateUsrAlert}
        onClose={closeAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cuenta de Perfil</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!userState.isErrorCreateUsrAlert && (
              <p>Tu cuenta ha sido actualizada satisfactoriamente.</p>
            )}
            {userState.isErrorCreateUsrAlert && (
              <>
                {userState.msgCreateUsrAlert.length > 0 && (
                  <p>{userState.msgCreateUsrAlert}</p>
                )}
                {userState.msgCreateUsrAlert.length === 0 && (
                  <p>
                    Se ha encontrado un incoveniente al actualizar tu cuenta,
                    por favor intente en un momento.
                  </p>
                )}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeAlert} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileSettings;
