import { Damages } from '@connect-technology/connect-react-components-lib';
import { IRotateImage, ITransition } from './common';
import { EventPhoto } from './images';
import { FieldSection } from './section-fields';

export interface IPreInspection {
  PreInspectionId: number;
  UniqueId: string;
  ApiKey: string;
  QuoteNumber?: string;
  CompanyId: number;
  StatusId: number;
  StatusName: string;
  InsuranceType?: string;
  IdType?: string;
  IdInsured?: string;
  Name?: string;
  LastName1?: string;
  LastName2?: string;
  BirthDate?: string;
  Owner?: string;
  PhoneNumber?: string;
  Email?: string;
  CountryCode: string;
  Province?: string;
  Canton?: string;
  District?: string;
  VehiclePlate?: string;
  VehicleBrand?: string;
  VehicleModel?: string;
  VehicleYear?: string;
  VehicleType?: string;
  VehicleColor?: string;
  Vin?: string;
  Chasis?: string;
  Transmission?: string;
  PaxNumber?: number;
  CylinderCapacity?: number;
  InsuranceAmount?: number;
  InitialValidity?: string;
  FinalValidity?: string;
  PlanType?: string;
  CoveragePlan?: string;
  Benefits?: string;
  CreationDate: string;
  BrokerName?: string;
  BrokerCode?: string;
  DamagesDesc: string;
  Signature: string;
  PreInspectionLink: string;
  ShowAlert: boolean;
  AnalysisStatus: string;
}
export interface IPreInspectionModel {
  preUniqueId: string;
  preInspectionId: number;
  preInsuranceType: string;
  preIdType: string;
  preIdInsured: string;
  preName: string;
  preLastName1: string;
  preLastName2: string;
  preBirthDate: string;
  prePhoneNumber: string;
  preEmail: string;
  preCountryCode: string;
  preProvince: string;
  preCanton: string;
  preDistrict: string;
  preBrokerCode: string;
  preBrokerName: string;
  preCreationDate: string;
  preVehicleBrand: string;
  preVehicleModel: string;
  preVehicleYear: number;
  preVehicleType: string;
  preVehicleVin: string;
  preVehicleMotor: string;
  preVehicleColor: string;
  prePaxNumber: number;
  preTransmission: string;
  preCylinderCapacity: number;
  preInsuranceAmount: number;
  preInitialValidity: string;
  preFinalValidity: string;
  prePlanType: string;
  preCoveragePlan: string;
  preBenefits: string;
  preQuoteNumber: string;
  preVehiclePlate: string;
  preSignature: string;
  preTermsAcceptance: boolean;
  preAcceptConditions: boolean;
  transitions?: ITransition[];
  preStatusId: number;
}

export interface IPreInspectionGrid {
  eventId?: number;
  statusName?: string;
  quotationNumber?: number;
  eventDate?: string;
  placa?: string;
  ownerName?: string;
  id?: string;
  cuentaCliente?: string;
  inspeccionadoPor?: string;
  nombre?: string;
  identificacion?: string;
  insertDate?: string;
  idPreinspeccion?: number;
  estado?: number;
}
export interface IPreInspectionState {
  preInspections: IPreInspection[];
  currentPreInspection: IPreInspectionModel | null;
  statusesIndicators: any[];
  formPreInspection: FieldSection[] | null;
  currentMediaFiles: EventPhoto[];
  rotateImages: IRotateImage[];
  carDamages?: Damages | null;
  actionMessage: string;
  loading: boolean;
  plateFilter: string;
  recordFilter: string;
  requestParams: any;
  alertMessage?: string;
  alertType?: string;
  showAlert: boolean;
  currentPreInspectTrack?: PreInspectStepTrack | null;
  showStepTrack: boolean;
}

export const preInspectionDefault: IPreInspection = {
  PreInspectionId: 0,
  UniqueId: '',
  ApiKey: '',
  QuoteNumber: '',
  CompanyId: 0,
  StatusId: 0,
  StatusName: '',
  InsuranceType: '',
  IdType: '',
  IdInsured: '',
  Name: '',
  LastName1: '',
  LastName2: '',
  BirthDate: '',
  Owner: '',
  PhoneNumber: '',
  Email: '',
  CountryCode: '',
  Province: '',
  Canton: '',
  District: '',
  VehiclePlate: '',
  VehicleBrand: '',
  VehicleModel: '',
  VehicleYear: '',
  VehicleType: '',
  VehicleColor: '',
  Vin: '',
  Chasis: '',
  Transmission: '',
  PaxNumber: 0,
  CylinderCapacity: 0,
  InsuranceAmount: 0,
  InitialValidity: '',
  FinalValidity: '',
  PlanType: '',
  CoveragePlan: '',
  Benefits: '',
  CreationDate: '',
  BrokerName: '',
  BrokerCode: '',
  DamagesDesc: '',
  Signature: '',
  PreInspectionLink: '',
  ShowAlert: false,
  AnalysisStatus: '',
};

export const preInspectDefault: IPreInspectionModel = {
  preUniqueId: '',
  preInspectionId: 0,
  preInsuranceType: '',
  preIdType: '',
  preIdInsured: '',
  preName: '',
  preLastName1: '',
  preLastName2: '',
  preBirthDate: '',
  prePhoneNumber: '',
  preEmail: '',
  preCountryCode: '',
  preProvince: '',
  preCanton: '',
  preDistrict: '',
  preBrokerCode: '',
  preBrokerName: '',
  preCreationDate: '',
  preVehicleBrand: '',
  preVehicleModel: '',
  preVehicleYear: 0,
  preVehicleType: '',
  preVehicleVin: '',
  preVehicleMotor: '',
  preVehicleColor: '',
  prePaxNumber: 0,
  preTransmission: '',
  preCylinderCapacity: 0,
  preInsuranceAmount: 0,
  preInitialValidity: '',
  preFinalValidity: '',
  prePlanType: '',
  preCoveragePlan: '',
  preBenefits: '',
  preQuoteNumber: '',
  preVehiclePlate: '',
  preSignature: '',
  preTermsAcceptance: false,
  preAcceptConditions: false,
  preStatusId: 0,
};

export interface IAlert {
  alertId: number;
  preInspectionId: number;
  sectionId: number;
  sectionName: string;
  description: string;
  isChecked: boolean;
}

export interface IDamageConfiguration {
  companyId: number;
  minimunGrade: number;
  minorDamageValue: number;
  mediumDamageValue: number;
  majorDamageValue: number;
  minorDamageLabel: string;
  mediumDamageLabel: string;
  majorDamageLabel: string;
}

export interface IDamage {
  id: number;
  status: string;
  section: number;
  zone: string;
  description: string;
  grade: string;
}

export interface IDamageAutodetectionZone {
  [groupName: string]: {
    damageId: number;
    preinspectionId: number;
    name: string;
    date: string;
    latitude: number;
    longitude: number;
    location: string;
    uploadPhoto: string;
    analyzedPhoto: string;
    grade: string;
    totalGrade: string;
    observations: IDamage[];
  }[];
}

export interface ICreatePreinspectionForm {
  email: string;
  id: string;
  name: string;
  lastName: string;
  vehicleplate: string;
  brokercode: string;
  option: CreatePreInspectionSendType;
  vin: string;
}

export enum CreatePreInspectionSendType {
  EMAIL = 1,
  QR = 2,
}

export interface IPreInspectStepTrack {
  preInspectId: number;
  isInitModule: boolean;
  isLocationModule: boolean;
  isImageModule: boolean;
  isFinishModule: boolean;
  images: IPreInspectTrackImage[];
}

export interface IPreInspectTrackImage {
  zoneName: string;
  urlImage: string;
}
