export const globalCss = {
  primary: '#de6d19',
  secondary: '#586982',
  white: '#ffffff',
  black: '#000000',
  cadetBlue: '#A4B0C1',
  blueBayoux: '#586982',
  boulder: '#747474',
  shakespeare: '#40A5CB',
  granny: '#8DA4A7',
  fern: '#5DB77E',
  alizarinCrimson: '#DF2F2F',
  dustyGray: '#979797',
  red: '#FD0000',
  alto: '#d8d8d8',
  aquaHaze: '#f1f4f7',
  aquaHazeLight: '#f8fafb',
  neptune: '#7DC2B0',
  coolGray: '#E2E8EF',
  alabaster: '#fafafa',
  cascade: '#87A5A8',
  info: '#439efb',
  concrete: '#F3F3F3',
  slateGray: '#6d7b91',
  ghost: '#c9cfd6',
  mineShaft: '#333333',
  hawkesBlue: '#e9effe',
  yellow: '#ffe505',
  purple: '#941b80',
  orangeLigth: '#fffaf9',
  blueClaims: '#001D3D',
  // Colors with opacity
  primaryOp10: 'rgba(201, 101, 40, .10)',
  hawkesBlueOp80: 'rgba(233, 239, 254, .80)',

  boxShadow: '0 2px 4px 0 rgba(147,147,147,0.5)',
};
