import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { saveAs } from 'file-saver';
import ClaimTrackerClient from 'shared/clients/ClaimsTrackerClient';
import { IClaim, IClaimFile, IClaimGallery } from 'types/claimsTracker';
import { claimFileSections } from 'types/images';
import { getFileNameAndExtensionFromURL } from 'shared/utils';
import PdfIcon from 'assets/images/pdf.svg';
import clsx from 'clsx';

interface props {
  currentClaim?: IClaim;
  onLoad: (value: boolean) => void;
}

const getFilesWithExtension = (files: IClaimFile[]): IClaimFile[] => {
  return files.map((f: IClaimFile) => {
    const fileExt = getFileNameAndExtensionFromURL(f.url);

    return {
      name: fileExt.fileName,
      extension: fileExt.extension,
      url: f.url,
    };
  });
};

const filterSectionFiles = (files: IClaimGallery[]): IClaimGallery[] => {
  const galleryToRender: IClaimGallery[] = [];

  claimFileSections.forEach((fs: any) => {
    const filterFiles = files.filter(
      (f: IClaimGallery) =>
        f.sectionName === fs.sectionName && f.files.length > 0,
    )[0];
    if (filterFiles) {
      const renderObj = galleryToRender.filter(
        (src: IClaimGallery) => src['sectionName'] === fs.description,
      )[0];

      if (renderObj) {
        renderObj.files = getFilesWithExtension(
          renderObj.files.concat(filterFiles.files),
        );
      } else {
        galleryToRender.push({
          sectionName: fs.description,
          files: getFilesWithExtension(filterFiles.files),
        });
      }
    }
  });

  return galleryToRender;
};

export default function ClaimFiles({
  currentClaim,
  onLoad,
}: props): JSX.Element {
  const [callGallery, setCallGallery] = useState(true);
  const [galleryFiles, setGalleryFiles] = useState<IClaimGallery[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [imgModal, setImgModal] = useState('');

  const retrieveGallery = useCallback(async () => {
    const result = await new ClaimTrackerClient().getFiles(
      currentClaim?.claimUId ?? '',
    );

    setGalleryFiles(filterSectionFiles(result));
    onLoad(false);
  }, [currentClaim, onLoad]);

  const viewThumbnail = (url: string, isFile: boolean): void => {
    setOpenModal(!isFile);
    setImgModal(url);

    if (isFile) {
      saveAs(url, 'download-file');
    }
  };

  useEffect(() => {
    if (callGallery) {
      (async (): Promise<void> => {
        onLoad(true);
        await retrieveGallery();
      })();
      setCallGallery(false);
    }
  }, [callGallery, retrieveGallery, onLoad]);

  return (
    <div className="container-files">
      <div className="title-container">
        <h2 className="title">Archivos</h2>
      </div>
      <Divider />
      <br />
      {galleryFiles.map((gFile: IClaimGallery, index: number) => {
        const idElem = index + '_accordion';

        return (
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={idElem}
                id={idElem}
                key={idElem}
              >
                {gFile.sectionName}
              </AccordionSummary>
              <AccordionDetails>
                {gFile.files.map((fileData: IClaimFile, index: number) => {
                  const idKey = 'idCard_' + index;
                  const isFile: boolean =
                    fileData.extension !== undefined &&
                    fileData.extension === 'pdf';

                  return (
                    <div key={idKey} className="card-contained">
                      <Card
                        key={idKey}
                        variant="outlined"
                        className={clsx('card-img', {
                          isPdf: isFile,
                        })}
                      >
                        <CardContent>
                          {isFile ? (
                            <img
                              key={idKey}
                              src={PdfIcon}
                              alt={fileData.name ?? ''}
                            />
                          ) : (
                            <img
                              key={idKey}
                              src={fileData.url}
                              alt={fileData.name ?? ''}
                            />
                          )}
                        </CardContent>
                        <CardActions>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => viewThumbnail(fileData.url, isFile)}
                          >
                            Visualizar
                          </Button>
                        </CardActions>
                      </Card>
                    </div>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}

      <Dialog
        id="img-modal"
        open={openModal}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <img src={imgModal} alt="Thumbnail" />
        </DialogContent>
      </Dialog>
    </div>
  );
}
