import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import clsx from 'clsx';
import Loader from 'components/common/loader/Loader';
import ValidationLabel from 'components/common/validation-label';
import { IUserContext, UserContext } from 'context/user';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { roles } from 'static/constants/roles';
import style from './override-mui-styles';
import './styles.scss';
import {
  Typography,
  Button,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

const COMPANIES = [
  {
    id: 1,
    ApiKey: '7DBABF06-4345-4323-BD94-3AB7A0BE7FF3',
    name: 'Qualitas',
    Code: 'CRC',
  },
  {
    id: 2,
    ApiKey: 'B584A33B-0F06-40FE-BC8D-25C7FCC91723',
    name: 'Assa',
    Code: 'CRC',
  },
  {
    id: 3,
    ApiKey: '',
    name: 'Connect CR',
    Code: 'CRC',
  },
  {
    id: 4,
    ApiKey: '3FA07D64-243F-4E75-BB36-C121A5BD9796',
    name: 'MNK Seguros',
    Code: 'CRC',
  },
  {
    id: 6,
    ApiKey: 'FF08FAA6-0514-4A84-84FA-442B0472F98F',
    name: 'Lafise',
    Code: 'CRC',
  },
  {
    id: 7,
    ApiKey: '877EEEE1-1E8F-406C-8AA4-19D087482452',
    name: 'Internacional de Seguros',
    Code: 'PAN',
  },
  {
    id: 8,
    ApiKey: '5E73673D-0739-4653-B6F0-3A8515094CE5',
    name: 'General de Seguros',
    Code: 'PAN',
  },
  {
    id: 9,
    ApiKey: '641D2C28-2EE9-486B-90CF-9110E602B217',
    name: 'Connect Insurance PA',
    Code: 'PAN',
  },
  {
    id: 10,
    ApiKey: '15F3A989-0B81-41A6-88EE-7B168F85662F',
    name: 'Connect Insurance PR',
    Code: 'PRI',
  },
  {
    id: 11,
    ApiKey: 'D10D9310-F685-4A9F-B789-FBCEE2C00FB1',
    name: 'Cooperativa de Seguros Múltiples',
    Code: 'PRI',
  },
  {
    id: 13,
    ApiKey: '',
    name: 'Connect PA',
    Code: 'PAN',
  },
  {
    id: 14,
    ApiKey: '',
    name: 'Connect PR',
    Code: 'PRI',
  },
  {
    id: 15,
    ApiKey: '04EACE32-8B87-4755-9255-FF7A836DA733',
    name: 'SURA',
    Code: 'PAN',
  },
  {
    id: 16,
    ApiKey: '6634744D-BACE-4943-861A-838C5E07A8A1',
    name: 'Optima Compania de Seguro',
    Code: 'PAN',
  },
  {
    id: 17,
    ApiKey: '86644658-9152-45F9-A1D6-D433DCEBBC35',
    name: 'ADISA',
    Code: 'CRC',
  },
  {
    id: 18,
    ApiKey: '7BD77B49-097B-4951-9F58-5ED1B48CAB30',
    name: 'NACIONAL DE SEGUROS',
    Code: 'PAN',
  },
  {
    id: 25,
    ApiKey: '0902D7DE-CC70-427B-9369-62B5A875B1A6',
    name: 'PREMIER',
    Code: 'PRI',
  },
];

interface ModalProps {
  style: any;
  open: boolean | false;
}

const ConfirmModal = (props: ModalProps) => {
  const currentHistory = useHistory();

  const handleOk = () => {
    currentHistory.push('/');
  };

  return (
    <Dialog aria-labelledby="dialog-title" open={props.open}>
      <DialogTitle id="dialog-title">Cuenta Connect</DialogTitle>
      <DialogContent>
        <Typography
          component="h4"
          variant="h4"
          className={clsx('description', props.style.description)}
        >
          La cuenta de usuario ha sido creada satisfactoriamente. Revisa tu
          correo de confirmación en tu bandeja de entrada.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button type="button" color="primary" onClick={handleOk} autoFocus>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SignUp = (): JSX.Element => {
  const overrideMuiClasses = style();
  const { handleSubmit, register, errors } = useForm();
  const { state, dispatch } = useContext<IUserContext>(UserContext);
  const { loading, userSession, currentUserStatus } = state;
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [listCompanies, setListCompanies] = useState<any[]>([]);
  const [selectCountry, setSelectCountry] = useState('');
  const [selectCompany, setSelectCompany] = useState(0);
  const [selectRole, setSelectRole] = useState(0);
  const currentHistory = useHistory();

  const onSubmit = (data: any) => {
    //signUpCognito(data, dispatch);
    console.warn('Not implemented.');
  };

  const returnToLogin = () => {
    currentHistory.push('/');
  };

  const handleCountry = (event: any) => {
    setSelectCountry(event.target.value);
    setListCompanies(
      COMPANIES.sort((a, b) => (a.name > b.name ? 1 : -1)) || [],
    );
  };

  const handleCompany = (event: any) => {
    setSelectCompany(event.target.value);
  };

  const handleRole = (event: any) => {
    setSelectRole(event.target.value);
  };

  useEffect(() => {
    if (userSession.isNewUser) {
      setOpenConfirmModal(true);
    }

    setListCompanies(COMPANIES);
  }, [userSession]);

  return (
    <>
      <Loader isActive={loading} />
      <Typography
        className={clsx('title', overrideMuiClasses.title)}
        component="h1"
        variant="h2"
      >
        Registrar Cuenta Connect
      </Typography>
      <Typography
        className={clsx('subtitle', overrideMuiClasses.subtitle)}
        component="h4"
        variant="h4"
      >
        Datos de acceso
      </Typography>
      <Typography
        className={clsx('description', overrideMuiClasses.description)}
        component="h4"
        variant="h4"
      >
        Complete la información requerida para la nueva cuenta:
      </Typography>
      <form className={'form'} onSubmit={handleSubmit(onSubmit)} noValidate>
        {currentUserStatus.isError && (
          <Alert severity="error">{currentUserStatus.message}</Alert>
        )}
        <TextField
          error={errors.name !== undefined}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nombre Completo"
          name="name"
          autoFocus
          inputRef={register({ required: 'Nombre es requerido' })}
          InputProps={{
            classes: {
              input: overrideMuiClasses.loginTextField,
            },
          }}
        />
        {errors.name && (
          <ValidationLabel message={errors.name.message} isError={true} />
        )}

        <TextField
          error={errors.username !== undefined}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Correo de usuario"
          name="username"
          autoComplete="email"
          autoFocus
          inputRef={register({ required: 'Correo es requerido' })}
          InputProps={{
            classes: {
              input: overrideMuiClasses.loginTextField,
            },
          }}
        />
        {errors.username && (
          <ValidationLabel message={errors.username.message} isError={true} />
        )}

        <TextField
          error={errors.newPassword !== undefined}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="newPassword"
          label="Nueva Contraseña"
          type="password"
          id="newPassword"
          autoComplete="newPassword"
          inputRef={register({
            required: 'Nueva Contraseña es requerida',
          })}
          InputProps={{
            classes: {
              input: overrideMuiClasses.loginTextField,
            },
          }}
        />
        {errors.newPassword && (
          <ValidationLabel
            message={errors.newPassword.message}
            isError={true}
          />
        )}

        <TextField
          name="country"
          type="hidden"
          id="country"
          inputRef={register}
          value={selectCountry}
        />
        <FormControl
          variant="outlined"
          className={clsx('selectInput', overrideMuiClasses.selectInput)}
        >
          <InputLabel id="select-outlined-label">País</InputLabel>
          <Select
            labelId="select-outlined-label"
            id="select-outlined"
            label="País"
            name="country"
            onChange={handleCountry}
          >
            <MenuItem value="PRI">Puerto Rico</MenuItem>
            <MenuItem value="CRC">Costa Rica</MenuItem>
            <MenuItem value="PAN">Panamá</MenuItem>
          </Select>
        </FormControl>

        <TextField
          name="company"
          type="hidden"
          id="company"
          inputRef={register}
          value={selectCompany}
        />
        <FormControl
          variant="outlined"
          className={clsx('selectInput', overrideMuiClasses.selectInput)}
        >
          <InputLabel id="select-outlined-label">Compañia</InputLabel>
          <Select
            labelId="select-outlined-label"
            id="select-outlined"
            label="Compañia"
            onChange={handleCompany}
          >
            {listCompanies.map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <TextField
          name="role"
          type="hidden"
          id="role"
          inputRef={register}
          value={selectRole}
        />
        <FormControl
          variant="outlined"
          className={clsx('selectInput', overrideMuiClasses.selectInput)}
        >
          <InputLabel id="select-outlined-label">Tipo de Rol</InputLabel>
          <Select
            labelId="select-outlined-label"
            id="select-outlined"
            label="Tipo de Rol"
            onChange={handleRole}
          >
            {roles.map((r) => (
              <option key={r.id} value={r.id}>
                {r.name}
              </option>
            ))}
          </Select>
        </FormControl>
        <Grid>
          <Grid item xs={12}>
            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Button
                  type="button"
                  variant="contained"
                  className={overrideMuiClasses.back}
                  onClick={() => returnToLogin()}
                >
                  Volver
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={overrideMuiClasses.submitNewPassword}
                >
                  Crear cuenta
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {openConfirmModal && (
        <ConfirmModal open={openConfirmModal} style={overrideMuiClasses} />
      )}
    </>
  );
};

export default SignUp;
