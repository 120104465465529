export interface IImageData {
  IdPreinspeccion: number;
  ImageUrl: string;
  Description: string;
  ImageSectionId: number;
  ImageSource: string;
}

export enum PhotoSection {
  PANORAMIC = 1, // Panoramica
  INSURED_VEHICLE = 2, // VehiculoAsegurado
  INSURED_DOCUMENTS = 3, // DocumentosAsegurado
  COUNTERPARTS = 4, // Terceros
  COUNTERPARTS_DOCUMENTS = 5, // DocumentosTerceros
  OTHERS = 6, // Otras
  INSURED_LICENSE = 20,
  INSURED_VEHICLE_LICENSE = 21,
  INSURED_ODOMETER = 22,
  INSURED_MARBETE = 23,
  INSURED_VIN = 24,
  INSURED_TABLILLA = 25,
  INSURED_VEH_PHOTOS = 26,
  INSURED_VEH_DAMAGE_PHOTOS = 27,
  INSURED_VEH_PANORAMIC = 28,
  INSURED_OTHERS_DOCS = 29,
  INJURED_LICENSE = 8,
  INJURED_VEHICLE_LICENSE = 9,
  INJURED_ODOMETER = 10,
  INJURED_MARBETE = 11,
  INJURED_VIN = 12,
  INJURED_TABLILLA = 13,
  INJURED_VEH_PHOTOS = 14,
  INJURED_VEH_DAMAGE_PHOTOS = 15,
  INJURED_VEH_PANORAMIC = 16,
  INJURED_OTHERS_DOCS = 17,
  RESOLUTION = 18,
  VEHICLE_VIDEO = 19,
  DRIVER_LICENSE_BACK = 30,
  DETAIL_ESTIMATED = 80,
  CMT_DOCUMENTS = 81,
  LEGAL_ASSISTANCE = 82,
  RUV = 83, //Registro Único de Propiedad Vehicular
  INSURED_VEHICLE_VIDEO = 84,
  PREINSPECT_DOCS = 43,
}

export enum MediaSection {
  VEHICLE_EXTERIOR = 40,
  VEHICLE_INTERIOR = 41,
  VEHICLE_VIDEO = 42,
  DOCUMENTS = 43,
  RUV = 83,
  ACCESSORIES = 84,
}

export enum MediaId {
  VEHICLE_EXTERIOR_PLATE = 'VEHICLE_EXTERIOR_PLATE',
  VEHICLE_EXTERIOR_FRONT = 'VEHICLE_EXTERIOR_FRONT',
  VEHICLE_EXTERIOR_RIGHT = 'VEHICLE_EXTERIOR_RIGHT',
  VEHICLE_EXTERIOR_BACK = 'VEHICLE_EXTERIOR_BACK',
  VEHICLE_EXTERIOR_LEFT = 'VEHICLE_EXTERIOR_LEFT',

  VEHICLE_INTERIOR_ODOMETER = 'VEHICLE_INTERIOR_ODOMETER',
  VEHICLE_INTERIOR_VIN = 'VEHICLE_INTERIOR_VIN',
  VEHICLE_INTERIOR_1 = 'VEHICLE_INTERIOR_1',
  VEHICLE_INTERIOR_2 = 'VEHICLE_INTERIOR_2',

  VEHICLE_VIDEO = 'VEHICLE_VIDEO',

  DOCUMENTS_VEHICLE_PROPERTY_REGISTRY = 'DOCUMENTS_VEHICLE_PROPERTY_REGISTRY',
  DOCUMENTS_CEDULA = 'DOCUMENTS_CEDULA',
  DOCUMENTS_DRIVERS_LICENSE = 'DOCUMENTS_DRIVERS_LICENSE',
}

export interface EventPhoto {
  dataURL: string;
  awsUrl?: string;
  imageId?: number;
  imageName?: string;
  section: PhotoSection | MediaSection;
  imageType?: string;
  order?: number;
  location?: string;
  isWaiting?: boolean;
  orientation?: any;
  icon?: string;
  inputRef?: any;
  coInspectionUpload?: string;
  urlUpload?: string;
  expenseDocument?: string;
  estimatedMitchellDocument?: string;
  lastModified?: Date;
  rotate?: number;
}

export interface SectionType {
  section: PhotoSection | MediaSection;
  type?: string;
  description: string;
  photos?: EventPhoto[];
}

export const SectionTypes = {
  CRC: {
    name: 'CRC',
    default: [
      {
        section: PhotoSection.PANORAMIC,
        description: 'Panorámica Lugar del Evento',
      },
      {
        section: PhotoSection.INSURED_VEHICLE,
        description: 'Vehículo Asegurado',
      },
      {
        section: PhotoSection.INSURED_DOCUMENTS,
        description: 'Documentos de Asegurado',
      },
      {
        section: PhotoSection.COUNTERPARTS,
        description: 'Terceros',
      },
      {
        section: PhotoSection.COUNTERPARTS_DOCUMENTS,
        description: 'Documentos de Terceros',
      },
      {
        section: PhotoSection.OTHERS,
        description: 'Otros Documentos',
      },
      {
        section: PhotoSection.VEHICLE_VIDEO,
        description: 'Video 360°',
      },
    ],
    preInspections: [
      {
        section: MediaSection.VEHICLE_EXTERIOR,
        description: 'Exterior del Vehículo',
      },
      {
        section: MediaSection.VEHICLE_INTERIOR,
        description: 'Interior del Vehículo',
      },
      {
        section: MediaSection.ACCESSORIES,
        description: 'Accesorios',
      },
      {
        section: MediaSection.VEHICLE_VIDEO,
        description: 'Video 360°',
      },
      {
        section: MediaSection.DOCUMENTS,
        description: 'Documentos',
      },
    ],
    preInspectionsBcr: [
      {
        section: MediaSection.VEHICLE_EXTERIOR,
        description: 'Exterior del Vehículo',
      },
      {
        section: MediaSection.VEHICLE_INTERIOR,
        description: 'Interior del Vehículo',
      },
      {
        section: MediaSection.ACCESSORIES,
        description: 'Accesorios',
      },
      {
        section: MediaSection.DOCUMENTS,
        description: 'Documentos',
      },
    ],
  },
  PAN: {
    name: 'PAN',
    default: [
      {
        section: PhotoSection.PANORAMIC,
        description: 'Foto Panorámica',
      },
      {
        section: PhotoSection.INSURED_VEHICLE,
        description: 'Vehículo Asegurado',
      },
      {
        section: PhotoSection.INSURED_DOCUMENTS,
        description: 'Documentos de Asegurado',
      },
      {
        section: PhotoSection.COUNTERPARTS,
        description: 'Terceros',
      },
      {
        section: PhotoSection.COUNTERPARTS_DOCUMENTS,
        description: 'Documentos de Terceros',
      },
      {
        section: PhotoSection.OTHERS,
        description: 'Otros Documentos',
      },
      {
        section: PhotoSection.VEHICLE_VIDEO,
        description: 'Video 360°',
      },
      {
        section: PhotoSection.DRIVER_LICENSE_BACK,
        description: 'Licencia de conducir (Trasera)',
      },
    ],
    fedpa: [
      {
        section: PhotoSection.PANORAMIC,
        description: 'Foto Panorámica',
      },
      {
        section: PhotoSection.INSURED_VEHICLE,
        description: 'Vehículo Asegurado',
      },
      {
        section: PhotoSection.INSURED_DOCUMENTS,
        description: 'Documentos de Asegurado',
      },
      {
        section: PhotoSection.COUNTERPARTS,
        description: 'Terceros',
      },
      {
        section: PhotoSection.COUNTERPARTS_DOCUMENTS,
        description: 'Documentos de Terceros',
      },
      {
        section: PhotoSection.OTHERS,
        description: 'Otros Documentos',
      },
      {
        section: PhotoSection.VEHICLE_VIDEO,
        description: 'Video 360°',
      },
      {
        section: PhotoSection.DRIVER_LICENSE_BACK,
        description: 'Licencia de conducir (Trasera)',
      },
      {
        section: PhotoSection.LEGAL_ASSISTANCE,
        description: 'Asistencia Legal',
      },
    ],
    acerta: [
      {
        section: PhotoSection.PANORAMIC,
        description: 'Foto Panorámica',
      },
      {
        section: PhotoSection.INSURED_VEHICLE,
        description: 'Vehículo Asegurado',
      },
      {
        section: PhotoSection.INSURED_DOCUMENTS,
        description: 'Documentos de Asegurado',
      },
      {
        section: PhotoSection.COUNTERPARTS,
        description: 'Terceros',
      },
      {
        section: PhotoSection.COUNTERPARTS_DOCUMENTS,
        description: 'Documentos de Terceros',
      },
      {
        section: PhotoSection.OTHERS,
        description: 'Otros Documentos',
      },
      {
        section: PhotoSection.RESOLUTION,
        description: 'Resolución Audiencia',
      },
      {
        section: PhotoSection.DRIVER_LICENSE_BACK,
        description: 'Licencia de conducir (Trasera)',
      },
    ],
    preInspections: [
      {
        section: MediaSection.VEHICLE_EXTERIOR,
        description: 'Exterior del Vehículo',
      },
      {
        section: MediaSection.VEHICLE_INTERIOR,
        description: 'Interior del Vehículo',
      },
      {
        section: MediaSection.VEHICLE_VIDEO,
        description: 'Video 360°',
      },
      {
        section: MediaSection.DOCUMENTS,
        description: 'Documentos',
      },
    ],
  },
  PRI: {
    name: 'PRI',
    csm: [
      {
        section: PhotoSection.INSURED_LICENSE,
        type: 'insured',
        description: 'Licencia de Conducir de Asegurado',
      },
      {
        section: PhotoSection.INSURED_VEHICLE_LICENSE,
        type: 'insured',
        description: 'Licencia de Vehículo de Asegurado',
      },
      {
        section: PhotoSection.INSURED_ODOMETER,
        type: 'insured',
        description: 'Odómetro',
      },
      {
        section: PhotoSection.INSURED_MARBETE,
        type: 'insured',
        description: 'Marbete',
      },
      {
        section: PhotoSection.INSURED_VIN,
        type: 'insured',
        description: 'Chasis (VIN Number)',
      },
      {
        section: PhotoSection.INSURED_TABLILLA,
        type: 'insured',
        description: 'Tablilla de Asegurado',
      },
      {
        section: PhotoSection.INSURED_VEH_PHOTOS,
        type: 'insured',
        description: 'Vehículo (Laterales, Frontal y Trasera) de Asegurado',
      },
      {
        section: PhotoSection.INSURED_VEH_DAMAGE_PHOTOS,
        type: 'insured',
        description: 'Daños de Asegurado',
      },
      {
        section: PhotoSection.INSURED_VEH_PANORAMIC,
        type: 'insured',
        description: 'Panorámica',
      },
      {
        section: PhotoSection.INSURED_OTHERS_DOCS,
        type: 'insured',
        description: 'Otros',
      },
      {
        section: PhotoSection.INJURED_LICENSE,
        type: 'injured',
        description: 'Licencia de Conducir de Perjudicado',
      },
      {
        section: PhotoSection.INJURED_VEHICLE_LICENSE,
        type: 'injured',
        description: 'Licencia de Vehículo de Perjudicado',
      },
      {
        section: PhotoSection.INJURED_ODOMETER,
        type: 'injured',
        description: 'Odómetro',
      },
      {
        section: PhotoSection.INJURED_MARBETE,
        type: 'injured',
        description: 'Marbete',
      },
      {
        section: PhotoSection.INJURED_VIN,
        type: 'injured',
        description: 'Chasis (VIN Number)',
      },
      {
        section: PhotoSection.INJURED_TABLILLA,
        type: 'injured',
        description: 'Tablilla de Perjudicado',
      },
      {
        section: PhotoSection.INJURED_VEH_PHOTOS,
        type: 'injured',
        description: 'Vehículo (Laterales, Frontal y Trasera) de Perjudicado',
      },
      {
        section: PhotoSection.INJURED_VEH_DAMAGE_PHOTOS,
        type: 'injured',
        description: 'Daños de Perjudicado',
      },
      {
        section: PhotoSection.INJURED_VEH_PANORAMIC,
        type: 'injured',
        description: 'Panorámica',
      },
      {
        section: PhotoSection.INJURED_OTHERS_DOCS,
        type: 'injured',
        description: 'Otros',
      },
      {
        section: PhotoSection.VEHICLE_VIDEO,
        type: 'injured',
        description: 'Video 360°',
      },
      {
        section: PhotoSection.INSURED_VEHICLE_VIDEO,
        type: 'insured',
        description: 'Video 360°',
      },
    ],
  },
};

export const claimFileSections = [
  {
    sectionName: '20_INSURED_LICENSE',
    description: 'Información del Vehículo',
  },
  {
    sectionName: '21_INSURED_VEHICLE_LICENSE',
    description: 'Información del Vehículo',
  },
  {
    sectionName: '22_INSURED_ODOMETER',
    description: 'Información del Vehículo',
  },
  {
    sectionName: '23_INSURED_MARBETE',
    description: 'Información del Vehículo',
  },
  {
    sectionName: '24_INSURED_VIN',
    description: 'Información del Vehículo',
  },
  {
    sectionName: '25_INSURED_TABLILLA',
    description: 'Información del Vehículo',
  },
  {
    sectionName: '26_INSURED_VEH_PHOTOS',
    description: 'Fotos del Vehículo',
  },
  {
    sectionName: '27_INSURED_VEH_DAMAGE_PHOTOS',
    description: 'Fotos de Daños (Vehículo)',
  },
  {
    sectionName: '28_INSURED_VEH_PANORAMIC',
    description: 'Panorámica (Vehículo)',
  },
  {
    sectionName: '29_INSURED_OTHERS_DOCS',
    description: 'Otros Archivos',
  },
  {
    sectionName: '8_INJURED_LICENSE',
    description: 'Información del Vehículo',
  },
  {
    sectionName: '9_INJURED_VEHICLE_LICENSE',
    description: 'Información del Vehículo',
  },
  {
    sectionName: '10_INJURED_ODOMETER',
    description: 'Información del Vehículo',
  },
  {
    sectionName: '11_INJURED_MARBETE',
    description: 'Información del Vehículo',
  },
  {
    sectionName: '12_INJURED_VIN',
    description: 'Información del Vehículo',
  },
  {
    sectionName: '13_INJURED_TABLILLA',
    description: 'Información del Vehículo',
  },
  {
    sectionName: '14_INJURED_VEH_PHOTOS',
    description: 'Fotos del Vehículo',
  },
  {
    sectionName: '15_INJURED_VEH_DAMAGE_PHOTOS',
    description: 'Fotos de Daños (Vehículo)',
  },
  {
    sectionName: '16_INJURED_VEH_PANORAMIC',
    description: 'Panorámica (Vehículo)',
  },
  {
    sectionName: '17_INJURED_OTHERS_DOCS',
    description: 'Otros Archivos',
  },
  {
    sectionName: 'CT_OTHERS',
    description: 'Otros Archivos',
  },
];
