import React, { createContext } from 'react';

interface ContextProps {
  propContentTitle: any;
  setPropContentTitle: React.Dispatch<React.SetStateAction<any>>;
}

const HeaderContext = createContext<ContextProps>({
  propContentTitle: '',
  setPropContentTitle: () => {
    throw new Error('setPropFromChildren is not implemented');
  },
});

export default HeaderContext;
