import './Form.scss';
import clsx from 'clsx';
import {
  getFormConfiguration,
  getPreInspectionById,
  IPreInspectionContext,
  PreInspectionContext,
  setLoading,
} from 'context/preinspections';
import { IUserContext, UserContext } from 'context/user';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dependency, FieldSection } from 'types/section-fields';
import FormSteps from 'components/inspection/form-steps';
import { DropDownTypes, FormType, ResourceAction } from 'types/common';
import { maskValue, scrollToTop, userActionsPermissions } from 'shared/utils';
import FormSectionData from 'components/inspection/section/FormSectionData';
import { ActionType } from 'types/action';
import CATALOGS from 'static/constants/catalogs';
import { Field } from 'types/form-field';
import Loader from 'components/common/loader/Loader';
import useDevice from 'shared/hooks/useDevice';

const filterSectionsByDependencies = (
  s: FieldSection,
  sections: FieldSection[],
): boolean => {
  if (!s.visible) {
    return false;
  }

  if (s.dependencyJson) {
    s.dependency = JSON.parse(s.dependencyJson);
  }

  if (!s.dependency) {
    return true;
  }

  const resultDependencies: boolean[] = [];
  s.dependency.forEach((fe: Dependency) => {
    const dFieldName = fe.Name;
    const dSectionId = fe.SectionId;
    const dExpectedValue = fe?.ExpectedValue;

    const fieldValue = sections
      .find((s) => s.formSectionId === dSectionId)
      ?.fields.find((f) => f.name === dFieldName)?.value;
    resultDependencies.push(fieldValue === dExpectedValue);
  });

  return (
    s.dependency.length === resultDependencies.filter((f) => f === true).length
  );
};

const filterTabs = (
  sectionTabs: FieldSection[],
  sections: FieldSection[],
): FieldSection[] => {
  const tabs = sectionTabs.filter((s) =>
    filterSectionsByDependencies(s, sections),
  );

  return tabs;
};

const parseFieldOptions = (formSections: FieldSection[]): FieldSection[] => {
  const readOnlyPermission = Boolean(
    userActionsPermissions(ResourceAction.VIEW_EVENT_READ_ONLY),
  );
  const sections = [...formSections.filter((fs) => fs.visible !== false)]; // In case that visible comes as nullable, show as default.

  return sections.map((section) => {
    let provinceObj: any = undefined;
    section.fields = section.fields.map((field: Field) => {
      if (section.level === '1') {
        return field;
      }

      // Validate dropdown values
      let dataType = '';
      let triggerDataType = '';
      if (field.dropDownOptions) {
        switch (field.dropDownOptions) {
          case DropDownTypes.PROVINCES:
            provinceObj = field;
            dataType = DropDownTypes.PROVINCES.toString();
            triggerDataType = DropDownTypes.CANTONS.toString();
            field.dropDownOptions = CATALOGS.getProvinces();
            break;
          case DropDownTypes.CANTONS: {
            dataType = DropDownTypes.CANTONS.toString();
            triggerDataType = DropDownTypes.DISTRICTS.toString();
            if (
              (!field.fieldFormValue ||
                String(field.fieldFormValue).length === 0) &&
              provinceObj
            ) {
              field.dropDownOptions = CATALOGS.getCantons(
                '',
                +provinceObj.fieldFormValue,
              );
            } else {
              field.dropDownOptions = CATALOGS.getCantons(
                field.fieldFormValue ?? '',
              );
            }
            break;
          }
          case DropDownTypes.DISTRICTS:
            dataType = DropDownTypes.DISTRICTS.toString();
            field.dropDownOptions = CATALOGS.getDistricts(
              field.fieldFormValue ?? '',
            );
            break;
          default:
            break;
        }
      }

      const updateField = {
        ...field,
        dataType: dataType,
        triggerDataType: triggerDataType,
        enabled: readOnlyPermission ? false : field.enabled,
      };

      return updateField;
    });

    return section;
  });
};

const PreInspectionForm = (): JSX.Element => {
  const readOnlyPermission = Boolean(
    userActionsPermissions(ResourceAction.VIEW_EVENT_READ_ONLY),
  );
  const { state: userState } = useContext<IUserContext>(UserContext);
  const { state: preInspectState, dispatch } = useContext<
    IPreInspectionContext
  >(PreInspectionContext);
  const history = useHistory();
  const { partnerKey, id, sectionId } = useParams<any>();
  const [menuSteps, setMenuSteps] = useState<FieldSection[]>([]);
  const [menuContent, setMenuContent] = useState<FieldSection[]>([]);
  const [activeFetch, setActiveFetch] = useState<boolean>(true);
  const { isDesktop } = useDevice();
  const userRolId = userState.currentUserStatus.role;
  const filteredTabs = filterTabs(menuSteps, menuContent);
  const tab = filteredTabs.find((t) => t.formSectionId === Number(sectionId));

  const tabIndex =
    filteredTabs.findIndex((t) => t.formSectionId === +sectionId) + 1;
  const nextStep = filteredTabs[tabIndex]?.formSectionId;
  const lastStep = filteredTabs[filteredTabs.length - 1]?.formSectionId;

  const activeStep = {
    formSectionId: Number(sectionId),
    title: '',
    hasError: Boolean(tab?.hasError),
  };

  const tabClick = (tab: any) => {
    (async (): Promise<void> => {
      history.push(
        `/preinspections/${partnerKey}/${id}/sections/${tab.formSectionId}`,
      );
      scrollToTop(false);
    })();
  };

  const fetchModelData = useCallback(async (): Promise<void> => {
    await getPreInspectionById(id, dispatch);
  }, [id, dispatch]);

  const fetchForm = useCallback(async (): Promise<void> => {
    const parameters = {
      preEventId: Number(id),
      companyApiKey: partnerKey,
      applicationId: 1,
      rolId: userRolId,
      formType: FormType.PRE_INSPECTION,
    };

    // Get current preinspect form data
    const formData: FieldSection[] | null = await getFormConfiguration(
      parameters,
      dispatch,
    );
    if (formData) {
      const updateFormData = parseFieldOptions(formData);
      setMenuSteps(
        updateFormData
          .filter((section: FieldSection) => section.level === '1')
          .sort((a: any, b: any) => (a.idOrder > b.idOrder ? 1 : -1)),
      );
      setMenuContent(
        updateFormData.filter((section: any) => section.level === '2'),
      );

      // Set form model data
      dispatch({
        type: ActionType.SET_PREINSPECTS_FORM,
        payload: updateFormData,
      });
    }
  }, [id, partnerKey, dispatch, userRolId]);

  useEffect(() => {
    if (activeFetch) {
      setLoading(true, dispatch);
      (async () => {
        await fetchModelData();
        await fetchForm();
        setActiveFetch(false);
        setLoading(false, dispatch);
      })();
    }
  }, [activeFetch, fetchModelData, fetchForm, dispatch]);

  useEffect(() => {
    // route section redirect
    if (filteredTabs?.length && !sectionId) {
      const firstSectionId = filteredTabs[0].formSectionId;
      history.push(
        `/preinspections/${partnerKey}/${id}/sections/${firstSectionId}`,
      );
    }
  }, [history, sectionId, id, partnerKey, filteredTabs]);

  return (
    <>
      <Loader isActive={preInspectState.loading} />
      <div className={clsx('pre-inspection')}>
        <div className="pre-inspection__title-container">
          <h1 className="pre-inspection__title-container__title">
            Formulario Pre-inspección
          </h1>

          <h1
            className={clsx(
              'pre-inspection__title-container__data',
              isDesktop && 'MuiTypography-alignRight',
            )}
          >
            Expediente:&nbsp;
            <span>
              {maskValue(
                preInspectState.currentPreInspection?.preInspectionId,
                '000000',
                '0',
                true,
              )}
            </span>
          </h1>
        </div>

        <FormSteps
          steps={filteredTabs}
          activeStep={activeStep}
          onSelectedTab={tabClick}
        />

        <FormSectionData
          key={`tbc_${activeStep.formSectionId}`}
          sections={menuContent}
          setSections={setMenuContent}
          activeStep={activeStep.formSectionId}
          nextStep={nextStep}
          lastStep={lastStep}
          preId={id}
          partnerKey={partnerKey}
        />
      </div>
    </>
  );
};

/**
 * Used to unmount and mount the component whenever
 * the event id is changed from within the same component
 * @returns JSX.Element
 */
const PreInspectionFormWrapper: React.FC = () => {
  const { id } = useParams() as any;
  const [renderChild, setRenderChild] = useState(false);
  const ref = useRef(); // used to safe previous value

  useEffect(() => {
    if (id !== ref.current) {
      setRenderChild(false);
      ref.current = id;
      setTimeout(() => setRenderChild(true), 10);
    } else {
      setRenderChild(true);
    }
  }, [id]);

  return renderChild ? <PreInspectionForm /> : <></>;
};

export default PreInspectionFormWrapper;
