import React, { useContext } from 'react';
import {
  Typography,
  Button,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import Loader from 'components/common/loader/Loader';
import ValidationLabel from 'components/common/validation-label';
import { IUserContext, requestChangePassword, UserContext } from 'context/user';
import { ActionType } from 'types/action';
import style from './override-mui-styles';
import './styles.scss';

interface ModalProps {
  style: any;
  open: boolean | false;
  isError: boolean | false;
  onClick?: () => void;
}

const ConfirmModal = (props: ModalProps) => {
  return (
    <Dialog aria-labelledby="dialog-title" open={props.open}>
      <DialogTitle id="dialog-title">SIC | Solicitar Acceso</DialogTitle>
      <DialogContent>
        <Typography
          component="h4"
          variant="h4"
          className={clsx('description', props.style.description)}
        >
          {!props.isError && (
            <>
              Se te enviará un correo con un <b>código de verificación</b> y los
              siguientes pasos para que pueda renovar su acceso.
            </>
          )}

          {props.isError && (
            <>
              El servicio de usuarios tiene incovenientes para enviar la
              solicitud, por favor intente en un momento.
            </>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          color="primary"
          onClick={() => {
            if (props.onClick) {
              props.onClick();
            }
          }}
          autoFocus
        >
          {!props.isError && <>Aceptar</>}
          {props.isError && <>Cerrar</>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RequestAccess = (): JSX.Element => {
  const overrideMuiClasses = style();
  const { handleSubmit, register, errors } = useForm();
  const { state: userState, dispatch } = useContext<IUserContext>(UserContext);
  const { loading } = userState;
  const currentHistory = useHistory();

  const onSubmit = async (data: any) => {
    dispatch({
      type: ActionType.SET_LOADING,
      payload: true,
    });
    await requestChangePassword(data.username, dispatch);
    dispatch({
      type: ActionType.SET_LOADING,
      payload: false,
    });
  };

  const returnToLogin = (): void => {
    currentHistory.push('/');
  };

  const closeAlert = (): void => {
    const isError = userState.isErrorPasswdReqAlert;
    dispatch({
      type: ActionType.SET_ALERT_REQUEST_PASSWORD,
      payload: {
        show: false,
        isError: false,
      },
    });

    if (!isError) {
      returnToLogin();
    }
  };

  return (
    <>
      <Loader isActive={loading} />
      <Typography
        className={clsx('title', overrideMuiClasses.title)}
        component="h1"
        variant="h2"
      >
        Solicitar Acceso
      </Typography>
      <Typography
        className={clsx('subtitle', overrideMuiClasses.subtitle)}
        component="h4"
        variant="h4"
      >
        Datos de verificación
      </Typography>
      <Typography
        className={clsx('description', overrideMuiClasses.description)}
        component="h4"
        variant="h4"
      >
        Complete la información requerida para solicitar acceso:
      </Typography>
      <form className={'form'} onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextField
          error={errors.username !== undefined}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Correo de usuario"
          name="username"
          autoComplete="email"
          autoFocus
          inputRef={register({ required: 'Correo es requerido' })}
          InputProps={{
            classes: {
              input: overrideMuiClasses.loginTextField,
            },
          }}
        />
        {errors.username && (
          <ValidationLabel message={errors.username.message} isError={true} />
        )}

        <Grid>
          <Grid item xs={12}>
            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Button
                  type="button"
                  variant="contained"
                  className={overrideMuiClasses.back}
                  onClick={() => returnToLogin()}
                >
                  Volver
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={overrideMuiClasses.submitNewPassword}
                >
                  Solicitar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <ConfirmModal
        open={userState.showPasswdReqAlert}
        isError={userState.isErrorPasswdReqAlert}
        onClick={closeAlert}
        style={overrideMuiClasses}
      />
    </>
  );
};

export default RequestAccess;
