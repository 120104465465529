import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Loader from 'components/common/loader/Loader';
import GridFilters from 'components/events/filters/GridFilters';
import NotesModal from 'components/events/notes/NotesModal';
import StatusCard from 'components/events/status-card';
import EventsTable from 'components/events/table/EventsTable';
import {
  IFiltersContext,
  FiltersContext,
} from 'context/filters/FiltersContext';
import { addDays } from 'date-fns';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { EVENT_STATUSES } from 'static/constants/event-status';
import { ActionType } from 'types/action';
import { Event } from 'types/event';
import { Option } from 'types/option';
import './styles.scss';
import {
  IInspectionContext,
  InspectionContext,
  getNotes,
  saveNote,
  getInspections,
  setLoading,
  fetchAgents,
  resendIntegration,
  transferEstimateCSM,
} from 'context/inspections';
import { IUserContext, UserContext } from 'context/user';
import { userActionsPermissions } from 'shared/utils';
import { BrandsId, CountryCode, ResourceAction, Rol } from 'types/common';
import {
  AssignmentOutlined,
  AssignmentLateOutlined,
} from '@mui/icons-material';
import { IAppFormType } from 'types/user';
import useDevice from 'shared/hooks/useDevice';
import SearchError from 'components/common/search-error/SearchError';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { addMonths } from 'date-fns/esm';

interface StatusIndicator {
  statusName: string;
  currentRows: number;
}

export default function Events(): JSX.Element {
  const history = useHistory();

  //Context
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const { queryFilters, searchQuery, setQueryFilters } = useContext<
    IFiltersContext
  >(FiltersContext);
  const {
    state: { currentUser },
    dispatch: userDispatch,
  } = useContext(UserContext) as IUserContext;

  // State
  const [selectedEventNote, setSelectedEventNote] = useState<Event | null>(
    null,
  );
  const [isNoteVisible, setIsNoteVisible] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<number | undefined>(
    queryFilters.statusId,
  );
  const [showMyServices, setShowMyServices] = useState(false);

  // States for filters
  const [company, setCompany] = React.useState<Option | null>(null);
  const [inspector, setInspector] = React.useState<Option | null>(null);
  const [tag, setTag] = React.useState<Option | null>(null);
  const [expressAdjust, setExpressAdjust] = React.useState<
    boolean | null | undefined
  >(Boolean(queryFilters?.filterByExpressAdjust));
  const [aapIndicator, setAapIndicator] = React.useState<
    boolean | null | undefined
  >(Boolean(queryFilters?.filterByAAP));
  const [pendingSinisters, setPendingSinisters] = React.useState<
    boolean | null | undefined
  >(Boolean(queryFilters?.filterText === 'QUALITAS-GET-SINISTERS'));
  const { isMobile } = useDevice();

  const countryCode = localStorage.getItem('countryCode'); //To use as a trigger to fetch inspections whenever the value changes.
  const isSURAEvent = selectedEventNote?.companyId === BrandsId.SURA;
  const title = `Hola ${currentUser.name}`;

  useEffect((): void => {
    if (selectedEventNote) {
      setIsNoteVisible(true);
    }
  }, [selectedEventNote]);

  useEffect((): void => {
    getInspections({ ...queryFilters, showMyServices }, dispatch);
    dispatch({
      type: ActionType.SET_GRID_REQUEST_PARAMS,
      payload: {
        ...queryFilters,
      },
    });
  }, [queryFilters, dispatch, showMyServices]);

  useEffect(() => {
    dispatch({ type: ActionType.SET_LOADING, payload: true });
    userDispatch({
      type: ActionType.SET_APP_FORM_TYPE,
      payload: IAppFormType.INSPECTION,
    });
  }, [dispatch, userDispatch]);

  useEffect(() => {
    const countryCode = localStorage.getItem('countryCode') || undefined;
    fetchAgents(countryCode);
  }, [countryCode]);

  const handleAddNote = useCallback(
    async (eventId: number, comment: string) => {
      const newComment = {
        eventId: eventId,
        userId: Number(localStorage.getItem('userId')),
        commentText: comment,
      };

      return saveNote(eventId, newComment);
    },
    [],
  );

  const handleNoteClicked = useCallback(
    async (event: Event): Promise<void> => {
      const currentEvent = {
        ...selectedEventNote,
        ...event,
        notes: await getNotes(event.eventId),
      };
      setSelectedEventNote(currentEvent);
      // getInspections({ ...queryFilters, showMyServices }, dispatch);
    },
    [selectedEventNote],
  );

  useEffect(() => {
    let ref: NodeJS.Timeout;
    if (selectedEventNote && isSURAEvent) {
      ref = setInterval(
        (event) => {
          handleNoteClicked(event);
        },
        5000,
        selectedEventNote,
      );
    }

    return () => {
      clearInterval(ref);
    };
  }, [selectedEventNote, handleNoteClicked, isSURAEvent]);

  if (searchQuery || searchQuery === null) {
    return <Redirect to={{ pathname: '/events', search: searchQuery || '' }} />;
  }

  const handleEventView = (row: any, eventId: number) => {
    row.stopPropagation();
    if (
      !row.target.classList.contains('MuiButtonBase-root') &&
      !row.target.classList.contains('MuiSvgIcon-root') &&
      row.target.classList?.length > 0
    ) {
      history.push(`/inspections/${eventId}`);
    }
  };

  const handleCloseNotesModal = () => {
    setIsNoteVisible(false);
    setSelectedEventNote(null);
  };

  const getAvailableFilterStatus = () => {
    const filterPermission = userActionsPermissions(ResourceAction.USE_FILTERS);
    if (filterPermission) {
      const newStatus: any = [];
      Array.from(filterPermission).forEach((p: any) => {
        const status = Object.values(EVENT_STATUSES).filter(
          (f) => f.id === +p,
        )[0];
        newStatus.push(status);
      });

      return newStatus;
    } else {
      return Object.values(EVENT_STATUSES);
    }
  };

  const getStatusIndicatorAmount = (
    id?: number,
    statusName?: string,
  ): number => {
    const status =
      statusName ||
      getAvailableFilterStatus().find((e: any) => e.id === id)?.value;
    if (id || statusName) {
      const _currentStatus = inspectionState.statusesIndicators.find(
        (indicator: StatusIndicator) => indicator.statusName === status,
      );

      return _currentStatus ? _currentStatus.currentRows : 0;
    } else {
      let total = 0;
      inspectionState.statusesIndicators
        .filter((i) => i.statusName !== 'AjusteExpress')
        .forEach((i: StatusIndicator) => (total += i.currentRows));

      return total;
    }
  };

  const expressIndicatorPermission = (): boolean => {
    const userRolId = localStorage.getItem('rolId');

    return (
      userRolId !== Rol.CUSTOMER.toString() &&
      (Number(localStorage.getItem('companyId')) === BrandsId.QUALITAS ||
        Number(localStorage.getItem('companyId')) === BrandsId.SURA ||
        userRolId === Rol.ADMIN.toString() ||
        userRolId === Rol.ADJUSTER.toString() ||
        userRolId === Rol.AUDIT.toString())
    );
  };

  const sinistersPendingPermission = (): boolean => {
    const userRolId = localStorage.getItem('rolId');
    const countryCode = localStorage.getItem('countryCode');

    return (
      userRolId !== Rol.CUSTOMER.toString() &&
      (Number(localStorage.getItem('companyId')) === BrandsId.QUALITAS ||
        ((userRolId === Rol.ADMIN.toString() ||
          userRolId === Rol.ADJUSTER.toString()) &&
          countryCode === CountryCode.CRC))
    );
  };

  const filterSinistersPending = (): void => {
    setLoading(true, dispatch);

    const userRolId = localStorage.getItem('rolId');
    let userCompanyId: number | undefined = Number(
      localStorage.getItem('companyId'),
    );
    if (
      userRolId === Rol.ADMIN.toString() ||
      userRolId === Rol.ADJUSTER.toString()
    ) {
      userCompanyId = undefined;
    }

    if (pendingSinisters) {
      setQueryFilters({
        ...queryFilters,
        statusId: undefined,
        companyId: userCompanyId,
        countryCode: localStorage.getItem('countryCode'),
        filterType: undefined,
        filterText: undefined,
      });
    } else {
      setQueryFilters({
        ...queryFilters,
        statusId: undefined,
        companyId: userCompanyId,
        countryCode: localStorage.getItem('countryCode'),
        filterType: 'CUSTOM',
        filterText: 'QUALITAS-GET-SINISTERS',
        sort: undefined,
        direction: undefined,
      });
    }
    setPendingSinisters(!pendingSinisters);
  };

  const setIntegrationConfirm = (): void => {
    dispatch({
      type: ActionType.SET_INTEGRATION_CONFIRM_RESEND,
      payload: false,
    });

    dispatch({
      type: ActionType.SET_INTEGRATION_RESEND_ID_EVENT,
      payload: -1,
    });
  };

  const resendEventToIntegration = async (): Promise<void> => {
    await resendIntegration(inspectionState.integrationResendEventId, dispatch);
  };

  const transferWithoutEstimateCSM = async (): Promise<void> => {
    await transferEstimateCSM(queryFilters, showMyServices, dispatch);
  };

  return (
    <>
      <Loader isActive={inspectionState.loading} />

      <div className="eventStatuses">
        {!isMobile && (
          <StatusCard
            className="eventStatuses__card--notif eventStatuses__card--md"
            title={title}
          />
        )}
        {sinistersPendingPermission() && (
          <StatusCard
            className="eventStatuses__card eventStatuses__card--small statusCard--siniestrar"
            status={
              {
                label: 'Pendiente Siniestrar',
                icon: <AssignmentLateOutlined />,
              } as any
            }
            amount={getStatusIndicatorAmount(undefined, 'PendingSinisters')}
            selected={Boolean(pendingSinisters)}
            onSelectStatus={filterSinistersPending}
          />
        )}
        {expressIndicatorPermission() && (
          <StatusCard
            className="eventStatuses__card eventStatuses__card--small statusCard--ajusteExpress"
            status={
              {
                label:
                  localStorage.getItem('countryCode')?.toUpperCase() ===
                  CountryCode.CRC
                    ? 'QualiExpress'
                    : isSURAEvent
                    ? 'Acompañamiento'
                    : 'Ajuste Express',
                icon: <AssignmentOutlined />,
              } as any
            }
            amount={getStatusIndicatorAmount(undefined, 'AjusteExpress')}
            selected={Boolean(expressAdjust)}
            onSelectStatus={() => {
              setLoading(true, dispatch);
              setQueryFilters({
                page: 0,
                filterByExpressAdjust: !expressAdjust,
                statusId: undefined,
                sort: undefined,
                direction: undefined,
              });
              setExpressAdjust(!expressAdjust);
            }}
          />
        )}
        {getAvailableFilterStatus().map((status: any) => (
          <StatusCard
            className="eventStatuses__card eventStatuses__card--small"
            key={status.value}
            status={status}
            amount={getStatusIndicatorAmount(status.id)}
            selected={status.id === currentStatus}
            onSelectStatus={(value) => {
              setLoading(true, dispatch);
              const newValue =
                value.id === queryFilters.statusId ? undefined : value;
              setCurrentStatus(newValue?.id);
              setQueryFilters({
                statusId: newValue?.id,
                page: 0,
                filterByExpressAdjust: undefined,
                sort: undefined,
                direction: undefined,
              });
            }}
          />
        ))}
      </div>
      <Card className="gridCard">
        <NotesModal
          showNotes
          visible={isNoteVisible}
          event={selectedEventNote}
          onAddNote={handleAddNote}
          onRefresh={handleNoteClicked}
          onClose={handleCloseNotesModal}
          inspectorName={''}
          noteTypes={[]}
        />
        <CardContent className="card-content">
          <h2 className={'gridCard__title'}>Actividad</h2>

          {expressAdjust && company?.id === BrandsId.CSM && (
            <div>
              <Button
                style={{ float: 'right', marginTop: '-35px' }}
                variant="contained"
                onClick={transferWithoutEstimateCSM}
              >
                Transferir sin Estimar
              </Button>
            </div>
          )}

          <GridFilters
            company={company}
            inspector={inspector}
            tag={tag}
            aapIndicator={aapIndicator}
            startDate={
              queryFilters?.initialDate
                ? new Date(queryFilters?.initialDate)
                : null
            }
            endDate={
              queryFilters?.finalDate ? new Date(queryFilters?.finalDate) : null
            }
            onChangeStartDate={(date) => {
              setLoading(true, dispatch);
              setQueryFilters({ page: 0, initialDate: date || undefined });
            }}
            onChangeEndDate={(date) => {
              setLoading(true, dispatch);
              setQueryFilters({
                page: 0,
                finalDate: !date ? undefined : addDays(date, 1),
              });
            }}
            onChangeDateRange={([start, end]) => {
              setLoading(true, dispatch);
              // Set Max End Date
              const maxEndDate = addMonths(start, 2);
              setQueryFilters({
                page: 0,
                initialDate: start,
                finalDate: end > maxEndDate ? maxEndDate : end,
              });
            }}
            onChangeCompany={(option) => {
              setLoading(true, dispatch);
              setQueryFilters({ page: 0, companyId: option?.id });
              setCompany(option);
            }}
            onChangeInspector={(option) => {
              setLoading(true, dispatch);
              setQueryFilters({ page: 0, inspectorId: option?.id });
              setInspector(option);
            }}
            onChangeTag={(option) => {
              setLoading(true, dispatch);
              setQueryFilters({ page: 0, tagId: option?.id });
              setTag(option);
            }}
            onChangeExpressAdjust={(value: boolean | null) => {
              setLoading(true, dispatch);
              setQueryFilters({
                page: 0,
                filterByExpressAdjust: value,
              });
              setExpressAdjust(value);
            }}
            onChangeAapIndicator={(value: boolean | null) => {
              setLoading(true, dispatch);
              setQueryFilters({
                page: 0,
                filterByAAP: value,
              });
              setAapIndicator(value);
            }}
            setCurrentInspector={(inspectors) => {
              const option =
                inspectors.find((i) => i.id === queryFilters.inspectorId) ||
                null;

              setInspector(option);
            }}
            setCurrentCompany={(companies) => {
              const option =
                companies.find((c) => c.id === queryFilters.companyId) || null;
              setCompany(option);
            }}
            setCurrentTag={(tags) => {
              const option =
                tags.find((t) => t.id === queryFilters.tagId) || null;
              setTag(option);
            }}
            onServicesCheckChange={setShowMyServices}
            showMyServices={showMyServices}
            currentUser={currentUser}
          />
          {inspectionState.inspections &&
            inspectionState.inspections.length > 0 && (
              <EventsTable
                events={inspectionState.inspections}
                totalElements={getStatusIndicatorAmount(
                  currentStatus,
                  expressAdjust ? 'AjusteExpress' : undefined,
                )}
                page={queryFilters.page || 0}
                onClickEditView={handleEventView}
                onClickNote={handleNoteClicked}
                onChangePage={(event: unknown, newPage: number) =>
                  setQueryFilters({ page: newPage })
                }
                reloadEvents={() =>
                  getInspections({ ...queryFilters, showMyServices }, dispatch)
                }
              />
            )}
          {!inspectionState.loading &&
            inspectionState.inspections &&
            inspectionState.inspections.length === 0 && <SearchError />}
        </CardContent>
      </Card>

      <Dialog
        open={inspectionState.integrationResendConfirm}
        onClose={() => setIntegrationConfirm()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Desea ejecutar un reenvío hacia la integración?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setIntegrationConfirm()}
            color="secondary"
          >
            No
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => resendEventToIntegration()}
            color="primary"
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
