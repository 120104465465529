import { Photo } from '@connect-technology/connect-react-components-lib/dist/components/camera/interfaces';
import { set } from 'lodash';
import { Orientation } from 'get-orientation';
import ExifReader from 'exifreader';
import APIClient from 'shared/api';
import ConnectServicesClient from 'shared/clients/ConnectServiceClient';
import CATALOGS from 'static/constants/catalogs';
import { ActionType, DispatchFunction } from 'types/action';
import {
  BrandsId,
  DropDownTypes,
  IRotateImage,
  ResourceAction,
  Rol,
} from 'types/common';
import { Field } from 'types/form-field';
import { IPreInspectionModel } from 'types/pre-inspection';
import { FieldSection } from 'types/section-fields';
import { rotateImage } from 'context/inspections/inspectionUtils';
import { format, parseISO, sub, differenceInDays } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { phoneMasks } from 'types/common';

/**
 * Feature to get the copy from the clipboard.
 * @param value
 */
export const copyToClipboard = (value: string): void => {
  const el = document.createElement('textarea');
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  // TODO: Add react notification for simple actions messages
  // Show message for the user => 'Enlace copiado en portapapeles.'
};

/**
 * Action to get shorten URL from a long URL.
 * @param url
 */
export const shortenUrl = (url: string | null): void => {
  const shortenAuthKey = `Bearer ${process.env.REACT_APP_SHORTEN_LINK_AUTH}`;
  const serviceURI = process.env.REACT_APP_SHORTEN_LINK_URL ?? '';
  url = url ?? window.location.href;
  APIClient.defaults.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: shortenAuthKey,
  };
  (async (): Promise<void> => {
    const response = await APIClient.post(serviceURI, {
      originalUrl: url,
      account: 'connect',
      type: 'SIC',
      branch: 'CRC', // Define branch with country global variable
      meta: {},
    });
    const resultURI = response.data.data?.shortUrl ?? 'none_uri';
    copyToClipboard(resultURI);
  })();
};

/**
 * Action to upload a photo.
 * @param photo
 * @param eventId
 * @param inspectionId
 * @returns
 */
export const uploadPhoto = async (
  photo: Photo,
  eventId: number,
  inspectionId: number,
): Promise<Photo> => {
  const data = {
    inspectionId: inspectionId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    file: photo.dataUrl!,
    type: 'jpeg',
    applyResize: false,
    sectionId: +photo.section.toString(),
  };
  const clientRequest = new ConnectServicesClient();
  const response = await clientRequest.uploadImage(eventId, data);
  if (response.data.success) {
    photo.awsUrl = response.data.data;
  }

  return photo;
};

/**
 * Convert data url to blob.
 */
export const dataUrl2Blob = (dataUrl: string): Blob => {
  const arr = dataUrl?.split(',');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mime = arr![0]?.match(/:(.*?);/)![1];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const bstr = atob(arr![1]);
  let n = bstr.length;
  // eslint-disable-next-line no-undef
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
};

/**
 * Map the model data
 * @param source
 * @param target
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mergeObjectData = (source: any, target: any) => {
  Object.keys(source).forEach((key) => {
    try {
      target[key] = source[key];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('The target field is not found.');
      // eslint-disable-next-line no-console
      console.log(error);
    }
  });

  return target;
};

/**
 * Get permission status
 * @param action
 * @param allPermissions
 * @param defaultValue
 * @returns
 */
const getPermissionStatus = (
  action: ResourceAction,
  allPermissions: any,
  defaultValue?: boolean,
) => {
  let permissionStatus = defaultValue ?? true;

  Array.from(allPermissions)
    .filter((f: any) => f.resourceName === action && f.isEnable === true)
    .forEach((f: any) => {
      permissionStatus = f.action === '1';
      if (permissionStatus) {
        return permissionStatus;
      }
    });

  return permissionStatus;
};

/**
 * Convert a code to number.
 * @param hilera
 * @returns
 */
export const codeToNumber = (hilera: string): string => {
  let codedString = '';
  if (hilera && hilera.length > 0) {
    for (let i = 0; i < hilera.length; i++) {
      switch (hilera.charAt(i)) {
        case 'a':
          codedString = codedString + '1';
          break;
        case 'b':
          codedString = codedString + '2';
          break;
        case 'c':
          codedString = codedString + '3';
          break;
        case 'd':
          codedString = codedString + '4';
          break;
        case 'e':
          codedString = codedString + '5';
          break;
        case 'f':
          codedString = codedString + '6';
          break;
        case 'g':
          codedString = codedString + '7';
          break;
        case 'h':
          codedString = codedString + '8';
          break;
        case 'i':
          codedString = codedString + '9';
          break;
        case 'j':
          codedString = codedString + '0';
          break;
        case 'k':
          codedString = codedString + '.';
          break;
        case 'l':
          codedString = codedString + '-';
          break;
        default:
          codedString = codedString + '';
          break;
      }
    }
  }

  return codedString;
};

/**
 * Convert a number to code.
 * @param hilera
 * @returns
 */
export const numberToCode = (hilera: string): string => {
  let codedString = '';
  for (let i = 0; i < hilera.length; i++) {
    switch (hilera.charAt(i)) {
      case '1':
        codedString = codedString + 'a';
        break;
      case '2':
        codedString = codedString + 'b';
        break;
      case '3':
        codedString = codedString + 'c';
        break;
      case '4':
        codedString = codedString + 'd';
        break;
      case '5':
        codedString = codedString + 'e';
        break;
      case '6':
        codedString = codedString + 'f';
        break;
      case '7':
        codedString = codedString + 'g';
        break;
      case '8':
        codedString = codedString + 'h';
        break;
      case '9':
        codedString = codedString + 'i';
        break;
      case '0':
        codedString = codedString + 'j';
        break;
      case '.':
        codedString = codedString + 'k';
        break;
      case '-':
        codedString = codedString + 'l';
        break;
      default:
        codedString = codedString + '';
        break;
    }
  }

  return codedString;
};

/**
 * Validate if the user has permission for the action
 * @param action
 * @returns
 */
export const userActionsPermissions = (action: ResourceAction): any => {
  const localData = localStorage.getItem('permissions') ?? '';
  const allPermissions: any = localData.length > 0 ? JSON.parse(localData) : [];
  if (!ResourceAction[action]) {
    return true;
  }

  switch (action) {
    case ResourceAction.USE_FILTERS: {
      const permissions: any = Array.from(allPermissions).filter(
        (f: any) =>
          f.resourceName === ResourceAction.USE_FILTERS && f.isEnable === true,
      );
      if (permissions) {
        const lastItem = Array.from(permissions).length - 1;
        const lastRulePermission = permissions[lastItem];
        if (lastRulePermission) {
          return lastRulePermission.action.split('|');
        }
      }
      break;
    }
    case ResourceAction.LOG_STATE_TO_COMPLETE: {
      return getPermissionStatus(
        ResourceAction.LOG_STATE_TO_COMPLETE,
        allPermissions,
        false,
      );
    }
    case ResourceAction.LOG_STATE_ONLY_TERMINATE: {
      return getPermissionStatus(
        ResourceAction.LOG_STATE_ONLY_TERMINATE,
        allPermissions,
        false,
      );
    }
    case ResourceAction.VIEW_EVENT_READ_ONLY: {
      return getPermissionStatus(
        ResourceAction.VIEW_EVENT_READ_ONLY,
        allPermissions,
        false,
      );
    }
    case ResourceAction.USE_PREINSPECT_FILTERS: {
      const permission: any = Array.from(allPermissions).filter(
        (f: any) =>
          f.resourceName === ResourceAction.USE_PREINSPECT_FILTERS &&
          f.isEnable === true,
      )[0];
      if (permission) {
        return permission.action.split('|');
      }
      break;
    }
    case ResourceAction.VIEW_EVENT_TABS: {
      const permission: any = Array.from(allPermissions).filter(
        (f: any) =>
          f.resourceName === ResourceAction.VIEW_EVENT_TABS &&
          f.isEnable === true,
      )[0];
      if (permission) {
        return permission.action.split('|');
      }
      break;
    }
    case ResourceAction.USE_BTN_INTEGRATION_EVENT: {
      return getPermissionStatus(
        ResourceAction.USE_BTN_INTEGRATION_EVENT,
        allPermissions,
        false,
      );
    }
    case ResourceAction.USE_BTN_CREATE_SUFIX_EVENT: {
      return getPermissionStatus(
        ResourceAction.USE_BTN_CREATE_SUFIX_EVENT,
        allPermissions,
        false,
      );
    }
    default:
      return getPermissionStatus(action, allPermissions);
  }
};

/**
 * Validate if the render component is enable for the company
 * @param action
 * @param companyId
 * @returns
 */
export const renderComponentIsEnable = (
  action: ResourceAction,
  companyId?: number,
): boolean => {
  const validationPatterns = {
    [ResourceAction.VIEW_CLAIM_ADJUST_SCREEN]: () =>
      ![BrandsId.CSM, BrandsId.SEGUROS_MUNDIAL].includes(companyId ?? 0),
  };

  return validationPatterns[action]() ?? true;
};

/**
 * Detect scroll for inspector form or regular form
 * @param isInspectorForm
 * @returns
 */
export const scrollToTop = (isInspectorForm: boolean): void => {
  if (isInspectorForm) {
    return window.scrollTo({ top: 0 });
  }

  const section = document.getElementById('app-container');
  if (section) {
    section.scrollTop = 0;
  }
};

/**
 * Validate the form.
 * @param sectionId
 * @param sections
 * @returns
 */
export const validationForm = (
  sectionId: number,
  sections: FieldSection[],
): any => {
  let isValid = false;
  const checkSections = sections.map((s: FieldSection) => {
    // State before revision
    let fieldsUpdated = s.fields;

    if (s.parentSectionId === sectionId) {
      const totalRequired = s.fields.filter(
        (f: Field) => f.required && f.required === true,
      ).length;

      const totalWithValue = s.fields.filter(
        (f: Field) =>
          f.required &&
          f.required === true &&
          f.fieldFormValue &&
          f.fieldFormValue.length > 0,
      ).length;

      isValid = totalRequired === totalWithValue;

      fieldsUpdated = s.fields.map((f: Field) => {
        f.hasError =
          f.required &&
          f.required === true &&
          (!f.fieldFormValue || f.fieldFormValue.length === 0);

        return f;
      });
    }

    return { ...s, fields: fieldsUpdated };
  });

  return {
    valid: isValid,
    sections: checkSections,
  };
};

/**
 * Set the field value.
 * @param currentValue
 * @param dispatch
 * @param currentPreInspect
 * @param currentSections
 */
export const setFormFieldValue = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  currentValue: any,
  dispatch: DispatchFunction,
  currentPreInspect: IPreInspectionModel | null,
  currentSections: FieldSection[] | null,
): void => {
  const [fieldName, formSection] = currentValue.name.split('|');
  let fieldValue = currentValue.value;

  if (fieldName === 'preSignature') {
    // No conviertas el valor a mayúsculas si no es necesario.
    fieldValue = currentValue.value;
  } else if (fieldName === 'preTermsAcceptance') {
    // Convierte el valor a boolean directamente.
    fieldValue = Boolean(currentValue.value);
  }

  // Actualiza solo las secciones y campos necesarios.
  if (currentSections) {
    const updatedSections = currentSections.map((section: FieldSection) => {
      if (section.formSectionId === Number(formSection)) {
        const updatedFields = section.fields.map((field: Field) => {
          if (field.idName.toLowerCase() === fieldName.toLowerCase()) {
            field.fieldFormValue = fieldValue;
          }

          return field;
        });
        section.fields = updatedFields;
      }

      return section;
    });

    // Actualiza solo si es necesario.
    if (updatedSections !== currentSections) {
      dispatch({
        type: ActionType.SET_PREINSPECTS_FORM,
        payload: updatedSections,
      });
    }
  }

  // Actualiza el modelo de preinspección.
  if (currentPreInspect) {
    set(currentPreInspect, fieldName, fieldValue);
    dispatch({
      type: ActionType.SET_PREINSPECTS_MODEL,
      payload: currentPreInspect,
    });
  }
};

/**
 * Location filter.
 * @param fieldName
 * @param fields
 * @returns
 */
export const locationFilter = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  fieldName: string,
  fields: Field[],
): Field[] => {
  const fieldObj = fields.find(
    (f) => String(f.idName).toLowerCase() === String(fieldName).toLowerCase(),
  );

  let updateFields = fields;

  if (fieldObj && fieldObj.triggerDataType) {
    updateFields = fields.map((f: Field) => {
      if (f.dataType === fieldObj.triggerDataType) {
        let optionsSelect = '';
        switch (String(fieldObj.dataType)) {
          case DropDownTypes.PROVINCES.toString(): {
            if (f.dataType === DropDownTypes.CANTONS.toString()) {
              optionsSelect = CATALOGS.getCantons(
                '',
                Number(fieldObj.fieldFormValue ?? '0'),
              );
            }
            if (fieldObj.idName === 'preProvince') {
              localStorage.setItem(
                'eventProvince',
                fieldObj.fieldFormValue ?? '',
              );
            }
            break;
          }
          case DropDownTypes.CANTONS.toString(): {
            if (f.dataType === DropDownTypes.DISTRICTS.toString()) {
              optionsSelect = CATALOGS.getDistricts(
                '',
                Number(fieldObj.fieldFormValue ?? '0'),
              );
            }
            if (fieldObj?.idName === 'preCanton') {
              localStorage.setItem(
                'eventCanton',
                fieldObj.fieldFormValue ?? '',
              );
            }
            break;
          }
          default:
            if (fieldObj?.idName === 'preDistrict') {
              localStorage.setItem(
                'eventDistrict',
                String(fieldObj?.fieldFormValue).length > 0
                  ? fieldObj?.fieldFormValue
                  : fieldObj?.originalValue,
              );
            }
            break;
        }

        f.dropDownOptions = optionsSelect;
      }

      return f;
    });
  }

  return updateFields;
};

/**
 * Get file information from a url.
 * @param uri
 * @param prefixTrack
 * @returns
 */
export const getFileInfo = (
  uri: string,
  prefixTrack = '_',
): { filePrefix: string; fileName: string; fileExtension: string } => {
  let prefix = '';
  let name = '';
  let ext = '';

  if (uri.length > 0) {
    const baseURL = uri.split('?')[0];
    const segmentURL = baseURL.split('/');

    if (segmentURL.length > 0) {
      const segmentName = segmentURL[segmentURL.length - 1].split('.');
      if (Array.from(segmentName).length > 0) {
        prefix = prefixTrack
          ? String(segmentName[0]).split(prefixTrack)[0]
          : '';
        name = String(segmentName[0]);
        ext = String(segmentName[1]);

        prefix = prefix === name ? '' : prefix;
      }
    }
  }

  return {
    filePrefix: prefix,
    fileName: name,
    fileExtension: ext,
  };
};

/**
 *
 * @param value The input Value.
 * @param template Template format for the value. Example: ##,####.##
 * @param defaultTag The tag value for merge with the input value. Example: #
 * @param useTemplateLength Return the complete format length or only the input value length.
 * @returns
 */
export const maskValue = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  value: any,
  template: string,
  defaultTag: string,
  useTemplateLength: boolean,
): string => {
  const arrValues: string[] = [];
  const valueStr = String(value);

  let arrTemplate: string[] = [];
  let finalValue = '';
  let idxReference = 1;

  // Process template
  for (let i = 0; i < template.length; i++) {
    const chardata = template.charAt(i);
    if (chardata.length > 0) {
      arrTemplate.push(chardata);
    }
  }

  // Process value
  for (let i = valueStr.length - 1; i >= 0; i--) {
    const chardata = valueStr.charAt(i);
    if (chardata.length > 0) {
      arrValues.push(chardata);
    }
  }

  if (arrValues.length > arrTemplate.filter((f) => f === defaultTag).length) {
    const diffIndex =
      arrValues.length - arrTemplate.filter((f) => f === defaultTag).length;
    arrTemplate = [];
    for (let i = 0; i < diffIndex; i++) {
      template = defaultTag + template;
    }

    // Update template
    for (let i = 0; i < template.length; i++) {
      const chardata = template.charAt(i);
      if (chardata.length > 0) {
        arrTemplate.push(chardata);
      }
    }
  }

  const arrTemplateWithValues: string[] = new Array(arrTemplate.length);
  let lastIndex = 0;

  for (let i = arrTemplate.length - 1; i >= 0; i--) {
    if (arrTemplate[i] === defaultTag) {
      // if has value print the value if not then print the tag
      if (idxReference <= arrValues.length) {
        arrTemplateWithValues[i] = arrValues[idxReference - 1];
        lastIndex = i;
      } else {
        arrTemplateWithValues[i] = defaultTag;
      }
      // Next position for arrValue
      idxReference++;
    } else {
      arrTemplateWithValues[i] = arrTemplate[i];
    }
  }

  for (let i = 0; i <= arrTemplateWithValues.length - 1; i++) {
    if (arrTemplateWithValues[i] !== undefined) {
      if (useTemplateLength) {
        finalValue += arrTemplateWithValues[i];
      } else if (i >= lastIndex) {
        finalValue += arrTemplateWithValues[i];
      }
    }
  }

  return finalValue;
};

/**
 * Format a number to a string with thousand separator.
 * @param value The input value.
 */
export const formatNumber = (value: string): string => {
  if (!value) {
    return '';
  }

  return String(value).replace(/(?<=\d)(,)(?=\d{3}\.)/g, '.');
};

/**
 * Format a number to a string with thousand separator.
 * @param value
 * @returns
 */
export const formatNumberPoint = (value: string): string => {
  if (!value) {
    return '';
  }

  return parseFloat(value.replace(',', '.')).toFixed(2);
};

/**
 * Format string to number value
 * @param value
 * @returns
 */
export const formatNumberMoney = (value: number): string => {
  if (!value) {
    return '';
  }

  return new Intl.NumberFormat('en-US').format(value);
};

/**
 *
 * @returns Detect if the app have access to internet
 */
export const networkIsOnline = async (): Promise<boolean> => {
  try {
    const clientRequest = new ConnectServicesClient();
    const response = await clientRequest.networkStatus();

    if (response.data.success) {
      // eslint-disable-next-line no-console
      console.log('Client network is up.');

      return true;
    } else {
      // eslint-disable-next-line no-console
      console.log('There was a network error.');

      return false;
    }
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.log('There was a network error.');

    return false;
  }
};

/**
 * Validate if the password is valid.
 * @param password
 * @returns
 */
export const validatePassword = (password: string): boolean => {
  if (password.length === 0) {
    return true;
  }
  if (password.length < 8) {
    return false;
  }
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasNonalphas = /\W/.test(password);

  if (hasUpperCase && hasLowerCase && hasNumbers && hasNonalphas) {
    return true;
  }

  return false;
};

/**
 * Request to rotate the image.
 * @param files
 * @returns
 */
export const requestChangeImageOrientantion = (
  files: IRotateImage[],
): IRotateImage[] => {
  const updatePhotos: IRotateImage[] = [];
  files.forEach(async (f: IRotateImage) => {
    let rotation = 0;
    switch (f.orientation) {
      case Orientation.LEFT_BOTTOM:
        rotation = 1;
        break;
      case Orientation.BOTTOM_RIGHT:
        rotation = 2;
        break;
      case Orientation.RIGHT_TOP:
        rotation = -1;
        break;
      default:
        break;
    }

    if (rotation > 0 || rotation < 0) {
      const sendUpdate = {
        imageURL: f.imageURL,
        rotateSide: rotation,
      };
      await rotateImage(sendUpdate);
    } else {
      updatePhotos.push(f);
    }
  });

  return updatePhotos;
};

/**
 * Get the base64 from a file.
 * @param file
 * @returns
 */
export const getBase64PDF = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

/**
 * Get the exif data from a image.
 * @param file
 * @returns
 */
export const exifDataFromImage = async (file: File): Promise<string> => {
  let imgLocation = '';

  try {
    const tags = await ExifReader.load(
      (await getBase64PDF(file)) as ArrayBuffer,
    );
    if (
      tags['GPSLongitude']?.description !== undefined &&
      tags['GPSLatitude']?.description !== undefined
    ) {
      const lng = tags['GPSLongitude']?.description;
      const lngRef = tags['GPSLongitudeRef']?.description;
      const lat = tags['GPSLatitude']?.description;
      const latRef = tags['GPSLatitudeRef']?.description;
      const lngFmt =
        lngRef === 'West longitude' ? '-' + String(lng) : String(lng);
      const latFmt =
        latRef === 'South latitude' ? '-' + String(lat) : String(lat);
      imgLocation =
        '¬' + numberToCode(latFmt) + '¬' + numberToCode(lngFmt) + '¬';
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  return imgLocation;
};

/**
 * Get date by country.
 * @param dateStr
 * @param countryCode
 * @returns
 */
export const getDateByCountry = (
  dateStr: string,
  countryCode: string,
): string => {
  switch (countryCode) {
    case 'CRI':
      return format(
        sub(parseISO(dateStr), { hours: 6 }),
        'dd/MM/yyyy HH:mm:ss',
        {
          locale: esLocale,
        },
      );
    case 'PAN':
      return format(
        sub(parseISO(dateStr), { hours: 5 }),
        'dd/MM/yyyy HH:mm:ss',
        {
          locale: esLocale,
        },
      );
    case 'COL':
      return format(
        sub(parseISO(dateStr), { hours: 5 }),
        'dd/MM/yyyy HH:mm:ss',
        {
          locale: esLocale,
        },
      );
    default:
      return format(
        sub(parseISO(dateStr), { hours: 4 }),
        'dd/MM/yyyy HH:mm:ss',
        {
          locale: esLocale,
        },
      );
  }
};

/**
 * Email validation.
 * @param email
 * @returns
 */
export const email_validate = (email: string): boolean => {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{1,}$/.test(email);
};

/**
 * Document ID validation.
 * @returns
 */
export const docIdValidate = (
  id: string,
  countryCode: string | undefined,
): boolean => {
  switch (countryCode) {
    case 'PAN':
      return /^(0|N-\d{4}-\d{4}|PE-\d{1,4}-\d{5,6}|E-\d{1,4}-\d{6}|\d{1,2}-\d{4}-\d{5}|\d{1,2}(AV|PI)-\d{4}-\d{5}|\d-\d{3}-\d{3})$/.test(
        id,
      );
    default:
      return true;
  }
};

/**
 * Get the file name and extension from a url.
 * @param url
 * @returns
 */
export const getFileNameAndExtensionFromURL = (
  url: string,
): { fileName: string; extension: string } => {
  const cleaningURL = url.includes('?') ? url.split('?')[0] : url;

  // eslint-disable-next-line no-useless-escape
  const regex = /\/([^\/]+\.\w+)$/; // Buscar cualquier cadena que termine en un punto y una o más letras o números para la extensión.
  const match = cleaningURL.match(regex);
  if (match) {
    const fileNameWithExtension = match[1];
    const fileName = fileNameWithExtension.slice(
      0,
      fileNameWithExtension.lastIndexOf('.'),
    );
    const extension = fileNameWithExtension.slice(
      fileNameWithExtension.lastIndexOf('.') + 1,
    );

    return { fileName, extension };
  } else {
    return {
      fileName: '',
      extension: '',
    };
  }
};

/**
 * Validate if the date range is valid.
 * @param dateInit
 * @param dateFin
 * @returns
 */
export const validateDateRange = (dateInit: Date, dateFin: Date): boolean => {
  const diffBetweenDays: number = differenceInDays(dateFin, dateInit);

  return diffBetweenDays <= 31; // 31 days
};

/**
 * Export the error data to a file.
 * @param id
 * @param modelName
 */
export const exportTxtFileError = (id: number, modelName: string): void => {
  const element = document.createElement('a');
  let data = localStorage.getItem('error-data') ?? '';
  data += ' || ';
  data += localStorage.getItem(modelName) ?? '';
  const file = new Blob([data], {
    type: 'text/plain',
  });
  element.href = URL.createObjectURL(file);
  element.download = id + '-sic-error.txt';
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

/**
 * Transform the value by field type from a section.
 * @param dataSections
 * @param modelBindingName
 * @returns
 */
export const transformFormValueByType = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  dataSections: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  currentValue: any,
  modelBindingName: string,
): any => {
  const fieldType = dataSections?.sections
    .map((section: any) => {
      let fieldType = '';
      Array.from(section.containers).forEach((f: any) => {
        f.fields?.forEach((f) => {
          if (f.modelBinding === modelBindingName) {
            fieldType = f.constraints.type;
          }
        });
      });

      return fieldType;
    })
    .filter((f) => f !== '')[0];

  let newValue = currentValue;

  if (fieldType === 'boolean') {
    const numberValues = ['1', '2'];

    if (numberValues.includes(currentValue)) {
      newValue = currentValue === '1';
    }
  } else if (fieldType === 'string') {
    const values = ['true', 'false'];

    if (values.includes(currentValue)) {
      newValue = currentValue === 'true';
    }
  } else if (fieldType === 'integer') {
    newValue = Number(currentValue);
  }

  return newValue;
};

/**
 * Transform the data to a key value structure.
 * @param data
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const keyValueStructure = (data: any): string[] => {
  const processObject = (obj: any, prefix = ''): string[] => {
    return Object.keys(obj).flatMap((key) => {
      const value = obj[key];
      if (key === 'datosDelPolicia') {
        const estuvoValue = obj[key]['estuvo'];
        if (estuvoValue !== undefined) {
          const newValue = estuvoValue === 'X' ? '1' : estuvoValue;

          return [`${prefix}datosDelPolicia&estuvo::${newValue}`];
        }
      }

      if (typeof value === 'object') {
        return processObject(value, `${prefix}${key}&`);
      }

      const keyValue = `${prefix}${key}::${value}`;

      return [keyValue];
    });
  };

  return processObject(data);
};

/**
 * Validate model keys for filter any custom value
 * @param data
 * @param claimForm
 * @returns
 */
export const validateModelKeys = async (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  claimForm: any,
): Promise<any> => {
  const claim: any = {};

  try {
    const currentStep = claimForm?.info.currentStep;
    const currentSections = claimForm?.steps.find(
      (s) => s.stageName === currentStep,
    )?.sections;

    if (claimForm) {
      Object.entries(data).forEach(async ([key, value]) => {
        if (String(key).indexOf('_Time') > 0) {
          const splitKeyName = String(key).split('_');
          if (splitKeyName.length === 3) {
            const referenceDateValue = await getFieldValueByName(
              currentSections,
              splitKeyName[0],
            );

            const splitBaseDate = referenceDateValue
              ? String(referenceDateValue).split('T')
              : [];

            let fieldDate: any =
              splitBaseDate.length > 0 ? splitBaseDate[0] : '';
            if (String(fieldDate).length > 0 && String(value).length > 0) {
              fieldDate = validateDate(fieldDate);
              set(claim, splitKeyName[1], fieldDate + ' ' + value + ':00');
            }
          }
        } else {
          set(claim, key, value);
        }
      });
    }
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log('Error Validate-Model-Keys: ' + error?.response?.data);
  }

  return claim;
};

/**
 * Get the field value from the form v2
 * @param sections
 * @param searchFieldName
 * @returns
 */
const getFieldValueByName = async (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  sections: any,
  searchFieldName: string,
): Promise<any> => {
  let value = '';
  sections.forEach((section) => {
    const fields = section?.containers[0]?.fields;
    if (fields && Array.isArray(fields)) {
      fields.forEach((field) => {
        if (field.keyName === searchFieldName) {
          value = field.defaultValue;
        }
      });
    }
  });

  return value;
};

/**
 * Validate if the date is valid.
 * @param data
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validateDate = (data: any) => {
  if (data === null) {
    return null;
  }

  const d = new Date(data);
  const dP = parseISO(data);

  let value: string | null =
    d instanceof Date && !isNaN(d.getTime()) ? format(dP, 'yyyy-MM-dd') : '';

  if (
    (value.length > 0 && new Date(value).getFullYear() < 1900) ||
    value.length === 0
  ) {
    value = null;
  }

  return value;
};

/**
 * Get if value isGUId
 * @param value
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isGuid = (value: any) => {
  // Expresión regular para validar un GUID
  const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

  // Comprobar si el valor coincide con el patrón del GUID
  return guidRegex.test(value);
};

/**
 * Get Inspector Url Base
 * @returns url
 */
export const getInspectorUrlBase = (): string => {
  const { href, pathname, origin } = window.location;

  // Check if the URL contains '/inspector/' and the pathname has at least 4 segments
  if (href.includes('/inspector/') && pathname.split('/').length >= 4) {
    // Destructure the pathname into segments
    const [, ...segments] = pathname.split('/');
    const [firstSegment, secondSegment, thirdSegment] = segments;

    return `${origin}/${firstSegment}/${secondSegment}/${thirdSegment}`;
  }

  // Return a default value
  return window.location.href;
};

/**
 * Transform Data To UpperCase
 * @param obj
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const transformToUpperCase = (obj: any): any => {
  // Validate if the object is an array
  if (Array.isArray(obj)) {
    return obj.map((item) => transformToUpperCase(item));
  }

  // Validate if the object is a plane object
  if (typeof obj === 'object' && obj !== null) {
    const newObj = {};

    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        // Apply text transform
        newObj[key] =
          typeof obj[key] === 'string'
            ? obj[key].toUpperCase()
            : transformToUpperCase(obj[key]);
      }
    }

    return newObj;
  }

  return obj;
};

/**
 * Extract Contry Code
 * @param contactNumber
 * @returns
 */
export const extractContryCode = (contactNumber: string): string => {
  // Remove spaces from the phone number for easier comparison
  const cleanedNumber = contactNumber.replace(/\s+/g, '');

  // Iterate over the formats in phoneMasks
  for (const [country, mask] of Object.entries(phoneMasks)) {
    // Extract the country code from the mask
    const countryCode = mask.split(' ')[0];
    const expectedLength = mask.replace(/\s+/g, '').length;

    // Compare the country code and the length of the number
    if (
      cleanedNumber.startsWith(countryCode) &&
      cleanedNumber.length === expectedLength
    ) {
      return country;
    }
  }

  return ''; // No match found
};

/**
 * Validate Constraint Field By Conditions
 * @param fieldKey
 * @param contraints
 * @param brandId
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validateConstraintFieldByConditions = (
  fieldKey: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  contraints: any,
  brandId: number,
): any => {
  const currentUserRolId = Number(localStorage.getItem('rolId') ?? '-1');

  if (brandId === BrandsId.ACERTA) {
    if (fieldKey === 'noPoliza' && currentUserRolId !== Rol.ADMIN) {
      const modifyData = { ...contraints, isReadOnly: true };

      return modifyData;
    }
  }

  return contraints;
};
