import { Button, Card, CardActionArea, CardMedia, Modal } from '@mui/material';
import sketchImg from 'assets/images/croquis.png';
import eraserIcon from 'assets/images/eraser.svg';
import clsx from 'clsx';
import DrawCanvas from 'components/common/drawing-canvas';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
interface SketchModalProps {
  isVirtualInspector: boolean;
  value: any;
  name: string;
  disabled: boolean;
  saveFn: () => void;
  onChange: (event: any) => void;
}

const Sketch: FunctionComponent<SketchModalProps> = ({
  isVirtualInspector,
  value,
  saveFn,
  onChange,
  name,
}): JSX.Element => {
  const {
    state: { currentInspection },
  } = useContext<IInspectionContext>(InspectionContext);
  const [open, setOpen] = useState(false);
  const [canErase, setCanErase] = useState<boolean>(false);
  const [dataUrl, setDataUrl] = useState<string | undefined>();
  const [canvasSize, setCanvasSize] = useState<{
    height: number;
    width: number;
  }>({ height: 0, width: 0 });

  const onClose = () => {
    setOpen(false);
    setCanErase(false);
    saveFn();
  };

  const croquisClass =
    !isVirtualInspector && !currentInspection?.scene
      ? 'croquis__img'
      : 'croquis__diagram';

  useEffect(() => {
    const percentOverHeight = 1.0449101796407185; // width should be 1.0449101796407185 of height
    const viewportHeight = window.innerHeight;
    const height = viewportHeight * 0.7; // set the canvas height to 70% of viewport
    const width = height * percentOverHeight;

    setCanvasSize({ height, width });
  }, [open]);

  return (
    <>
      <div className="croquis">
        <Card className="croquis__carMedia">
          <CardActionArea>
            <>
              {!isVirtualInspector && !currentInspection?.scene && (
                <CardMedia
                  className="croquis__diagram"
                  component="img"
                  image={sketchImg}
                  alt="sketch img"
                />
              )}
              <CardMedia
                className={croquisClass}
                component="img"
                image={value || sketchImg}
                alt="sketch img"
              />
            </>
          </CardActionArea>
        </Card>
      </div>
      <Modal className="sketch-modal" open={open} onClose={onClose}>
        <>
          <button
            className={clsx('eraserIcon', { active: canErase })}
            onClick={(): void => setCanErase(!canErase)}
          >
            <img src={eraserIcon} alt="" />
          </button>
          <div className="container" id="sketch-modal">
            <div className="scroll-handle">
              Arrastre por aquí para desplazarse
            </div>
            <DrawCanvas
              className="croquis__diagram"
              onChange={(canvas: any) => {
                setDataUrl(canvas.canvasContainer.childNodes[1].toDataURL());
              }}
              imgSrc={sketchImg}
              brushRadius={canErase ? 8 : 2}
              canvasHeight={canvasSize.height}
              canvasWidth={canvasSize.width}
              lazyRadius={0}
              erase={canErase}
            />
            <div className="scroll-handle">
              Arrastre por aquí para desplazarse
            </div>
          </div>
          <div className="buttons-container">
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                setCanErase(false);
                onChange({
                  target: {
                    value: dataUrl,
                    name,
                  },
                });
                setTimeout(saveFn, 1000);
              }}
            >
              Confirmar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                setCanErase(false);
              }}
            >
              Cancelar
            </Button>
          </div>
        </>
      </Modal>
    </>
  );
};

export default Sketch;
