import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSimpleAndDoubleClick = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  actionSimpleClick: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  actionDoubleClick: any,
  delay = 250,
) => {
  const [click, setClick] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      // simple click
      if (click === 1) {
        actionSimpleClick();
      }
      setClick(0);
    }, delay);

    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    if (click === 2) {
      actionDoubleClick();
    }

    return () => clearTimeout(timer);
  }, [click, actionSimpleClick, actionDoubleClick, delay]);

  return () => setClick((prev) => prev + 1);
};

export default useSimpleAndDoubleClick;
