import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { IClaim } from 'types/claimsTracker';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import ClaimTrackerClient from 'shared/clients/ClaimsTrackerClient';
import { uploadMediaFile } from 'context/inspections/inspectionUtils';
import { PhotoSection } from 'types/images';
import {
  BUCKET_NAME_CLAIMS_TRACKER,
  BUCKET_REGION_CLAIMS_TRACKER,
} from 'shared/api/config';
import { ActionType } from 'types/action';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { maskValue } from 'shared/utils';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
interface props {
  currentClaim?: IClaim;
  onNotify: (msg: string, warn: boolean) => void;
}

export default function ClaimPayment({
  currentClaim,
  onNotify,
}: props): JSX.Element {
  const { dispatch } = useContext<IInspectionContext>(InspectionContext);
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [estimatedAmount, setEstimatedAmount] = useState<number>(0);
  const [depreciationAmount, setDepreciationAmount] = useState<number>(0);
  const [deductibleAmount, setDeductibleAmount] = useState<number>(0);
  const [historicAmounts, setHistoricAmounts] = useState<any>();
  const [estimatedRepairDate, setEstimatedRepairDate] = useState<
    Date | undefined
  >(new Date());
  const [fileUpload, setFileUpload] = useState<File>();
  const inputFileRef = useRef<any>(null);

  useEffect(() => {
    getAllClaimPaymentAmount(
      currentClaim?.claimId ?? 0,
      currentClaim?.claimNumber ?? '',
    );
    setPaymentAmount(currentClaim?.agreementAmount ?? 0);
    setEstimatedAmount(currentClaim?.estimatedAmount ?? 0);
    setDepreciationAmount(currentClaim?.depreciationAmount ?? 0);
    setDeductibleAmount(currentClaim?.deductibleAmount ?? 0);
    setEstimatedRepairDate(currentClaim?.estimatedRepairDate);
  }, [currentClaim]);

  const getAllClaimPaymentAmount = async (
    claimId: number,
    claimNumber: string,
  ) => {
    if (claimId > 0) {
      const result = await new ClaimTrackerClient().getAllClaimPaymentAmount(
        claimId,
        claimNumber,
      );
      setHistoricAmounts(result);
    }
  };

  const onChangePaymentAmount = (event: any): void => {
    setPaymentAmount(event.target.value);
  };

  const onChangeEstimatedAmount = (event: any): void => {
    setEstimatedAmount(event.target.value);
  };

  const onChangeDepreciationAmount = (event: any): void => {
    setDepreciationAmount(event.target.value);
  };

  const onChangeDeductibleAmount = (event: any): void => {
    setDeductibleAmount(event.target.value);
  };

  const validateAmounts = (): boolean => {
    return Boolean(
      paymentAmount > 0 &&
        estimatedAmount > -1 &&
        estimatedAmount.toString().length > 0 &&
        depreciationAmount > -1 &&
        depreciationAmount.toString().length > 0 &&
        deductibleAmount > -1 &&
        deductibleAmount.toString().length > 0,
    );
  };

  const sendPaymentAmount = async (): Promise<void> => {
    if (!validateAmounts() || !fileUpload) {
      onNotify(
        'Se necesita un monto y un documento con el detalle del acuerdo.',
        true,
      );
    } else {
      try {
        dispatch({ type: ActionType.SET_LOADING, payload: true });
        const result = await new ClaimTrackerClient().createClaimPaymentAmount(
          currentClaim?.claimId ?? 0,
          paymentAmount,
          estimatedAmount,
          depreciationAmount,
          deductibleAmount,
        );

        if (result?.id) {
          inputFileRef.current.value = '';
          setFileUpload(undefined);

          if (fileUpload) {
            if (currentClaim?.claimNumber) {
              await uploadMediaFile(
                fileUpload,
                `${currentClaim?.claimNumber}`,
                PhotoSection.INSURED_OTHERS_DOCS,
                BUCKET_NAME_CLAIMS_TRACKER,
                BUCKET_REGION_CLAIMS_TRACKER,
                String(result.id),
              );
            }
          }

          await getAllClaimPaymentAmount(
            currentClaim?.claimId ?? 0,
            currentClaim?.claimNumber ?? '',
          );

          onNotify('Acuerdo de pago guardado correctamente.', false);
        }
      } catch (error) {
        dispatch({ type: ActionType.SET_LOADING, payload: false });
        onNotify(
          'No se pudo establecer el acuerdo de pago, por favor intente nuevamente.',
          true,
        );
      } finally {
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    }
  };

  const handleInputFileChange = async ({
    target: { files: fileList },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!fileList) {
      return;
    }
    const files = Array.from(fileList);
    if (files.length > 0) {
      setFileUpload(files[0]);
    }
  };

  const handleEstimatedDateChange = async (date: any) => {
    setEstimatedRepairDate(date);

    if (date) {
      const data = {
        claimUId: currentClaim?.claimUId,
        estimatedRepairDate: date.toISOString().split('T')[0],
      };

      await new ClaimTrackerClient().updateClaim(data);
    }
  };

  return (
    <>
      <Grid container className="claimsForm__row" spacing={2}>
        <Grid key={'form-field-12'} item md={4} xs={12}>
          <FormControl variant="outlined" className={clsx('claimsForm__input')}>
            <InputLabel
              key="lbl-payment-type"
              style={{
                // Fixes select label drawn with input border
                backgroundColor: 'white',
                paddingRight: 5,
                paddingLeft: 5,
              }}
            >
              Tipo de Pago
            </InputLabel>
            <Select
              key="select-payment-type"
              // eslint-disable-next-line no-console
              onChange={() => {
                // eslint-disable-next-line no-console
                console.log('change');
              }}
              name="select-payment-type"
              value={currentClaim?.payment?.type ?? ''}
              disabled
            >
              <MenuItem key="NONE" value=""></MenuItem>
              <MenuItem key="CHECK-Key" value="CHECK">
                Pago por Cheque
              </MenuItem>
              <MenuItem key="ACH-Key" value="ACH">
                Pago por Deposito Directo
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid key={'form-field-13'} item md={2} xs={12}>
          <Grid item style={{ minWidth: 149 }}>
            <InputLabel
              key="enable-ACH"
              className="claimsForm__toggle-label"
              style={{ marginRight: 15 }}
            >
              ¿Habilitado Deposito Directo?
            </InputLabel>
            <Button
              key="opt-enable-ach-1"
              name="opt-enable-ach-1"
              variant="outlined"
              disableElevation
              onClick={() => {
                // eslint-disable-next-line no-console
                console.log('change');
              }}
              className={clsx(
                'claimsForm__toggle-button',
                currentClaim?.payment?.enableACH === true
                  ? 'claimsForm__toggle-button--selected'
                  : '',
              )}
              disabled
            >
              SI
            </Button>
            <Button
              key="opt-enable-ach-2"
              name="opt-enable-ach-2"
              variant="outlined"
              disableElevation
              onClick={() => {
                // eslint-disable-next-line no-console
                console.log('change');
              }}
              className={clsx(
                'claimsForm__toggle-button',
                currentClaim?.payment?.enableACH === false
                  ? 'claimsForm__toggle-button--selected'
                  : '',
              )}
              disabled
            >
              NO
            </Button>
          </Grid>
        </Grid>
        <Grid key={'form-field-14'} item md={3} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              value={
                estimatedRepairDate !== null ? dayjs(estimatedRepairDate) : null
              }
              label="Estimado reparación (YYYY-MM-DD)"
              slotProps={{ textField: { variant: 'outlined' } }}
              onChange={handleEstimatedDateChange}
              format="YYYY-MM-DD"
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <div className="title-container">
        <br />
        <h2 className="title">Acuerdo de Pago</h2>
      </div>
      <Divider />
      <Grid container className="claimsForm__row" spacing={2}>
        <Grid key={'form-field-0'} item md={3} xs={6}>
          <TextField
            id="paymentAmount"
            variant="outlined"
            label="Monto a Pagar"
            className={clsx('claimsForm__input')}
            value={paymentAmount}
            onChange={onChangePaymentAmount}
            type="number"
          ></TextField>
        </Grid>
        <Grid key={'form-field-1'} item md={3} xs={6}>
          <TextField
            id="estimatedAmount"
            variant="outlined"
            label="Monto Estimado"
            className={clsx('claimsForm__input')}
            value={estimatedAmount}
            onChange={onChangeEstimatedAmount}
            type="number"
          ></TextField>
        </Grid>
        <Grid key={'form-field-2'} item md={3} xs={6}>
          <TextField
            id="depreciationAmount"
            variant="outlined"
            label="Monto Depreciación"
            className={clsx('claimsForm__input')}
            value={depreciationAmount}
            onChange={onChangeDepreciationAmount}
            type="number"
          ></TextField>
        </Grid>
        <Grid key={'form-field-3'} item md={3} xs={6}>
          <TextField
            id="deductibleAmount"
            variant="outlined"
            label="Monto Deducible"
            className={clsx('claimsForm__input')}
            value={deductibleAmount}
            onChange={onChangeDeductibleAmount}
            type="number"
          ></TextField>
        </Grid>
        <Grid key={'form-field-4'} item md={3} xs={6}>
          <input
            title="file"
            type="file"
            onChange={(event) => {
              handleInputFileChange(event);
            }}
            ref={inputFileRef}
            accept="application/pdf"
          ></input>
        </Grid>
        <Grid key={'form-field-5'} item md={3} xs={12}>
          <Button variant="contained" onClick={sendPaymentAmount}>
            Agregar
          </Button>
        </Grid>
      </Grid>
      <div className="title-container">
        <br />
        <h2 className="title">Pago por Cheque</h2>
      </div>
      <Divider />
      <Grid container className="claimsForm__row" spacing={2}>
        <Grid key={'form-field-14'} item md={4} xs={12}>
          <FormControl variant="outlined" className={clsx('claimsForm__input')}>
            <InputLabel
              key="lbl-payment-type"
              className="claimsForm__label-for-select"
            >
              Recibe Cheque por medio de
            </InputLabel>
            <Select
              key="select-check-type"
              value={currentClaim?.payment?.receiveCheck?.type ?? ''}
              // eslint-disable-next-line no-console
              onChange={() => {
                // eslint-disable-next-line no-console
                console.log('change');
              }}
              name="select-check-type"
              disabled
            >
              <MenuItem key="NONE" value=""></MenuItem>
              <MenuItem key="POSTMAIL-Key" value="POST_MAIL">
                Envío por correo postal
              </MenuItem>
              <MenuItem key="PICKUP_OFFICE-Key" value="PICKUP_OFFICE">
                Recoger en oficina
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid key={'form-field-19'} item md={4} xs={12}>
          <TextField
            disabled
            id="office"
            label="Oficina"
            variant="outlined"
            value={currentClaim?.payment?.receiveCheck?.office?.address}
          />
        </Grid>
        <Grid key={'form-field-18'} item md={4} xs={12}>
          <TextField
            disabled
            id="postal-code"
            label="Código Postal"
            variant="outlined"
            value={currentClaim?.payment?.receiveCheck?.postalCode}
          />
        </Grid>
        <Grid key={'form-field-15'} item md={4} xs={12}>
          <TextField
            disabled
            id="town"
            label="Pueblo"
            variant="outlined"
            value={currentClaim?.payment?.receiveCheck?.town}
          />
        </Grid>
        <Grid key={'form-field-16'} item md={4} xs={12}>
          <TextField
            disabled
            id="urbanization"
            label="Urb, Barrio, Edificio"
            variant="outlined"
            value={currentClaim?.payment?.receiveCheck?.urbanization}
          />
        </Grid>
        <Grid key={'form-field-17'} item md={4} xs={12}>
          <TextField
            disabled
            id="detailed-address"
            label="Casa, Apartamento, Calle o AV"
            variant="outlined"
            value={currentClaim?.payment?.receiveCheck?.detailedAddress}
          />
        </Grid>
      </Grid>
      <div className="title-container">
        <br />
        <h2 className="title">Pago por Deposito Directo</h2>
      </div>
      <Divider />
      <Grid container className="claimsForm__row" spacing={2}>
        <Grid item style={{ minWidth: 149 }} md={12} xs={12}>
          <InputLabel
            key="isAuth-ACH"
            className="claimsForm__toggle-label"
            style={{ marginRight: 15 }}
          >
            ¿Está Autorizado?
          </InputLabel>
          <Button
            key="opt-isauth-ach-1"
            name="opt-isauth-ach-1"
            variant="outlined"
            disableElevation
            onClick={() => {
              // eslint-disable-next-line no-console
              console.log('change');
            }}
            className={clsx(
              'claimsForm__toggle-button',
              currentClaim?.payment?.receiveACH?.isAuthorize === true
                ? 'claimsForm__toggle-button--selected'
                : '',
            )}
            disabled
          >
            SI
          </Button>
          <Button
            key="opt-isauth-ach-2"
            name="opt-isauth-ach-2"
            variant="outlined"
            disableElevation
            onClick={() => {
              // eslint-disable-next-line no-console
              console.log('change');
            }}
            className={clsx(
              'claimsForm__toggle-button',
              currentClaim?.payment?.receiveACH?.isAuthorize === false
                ? 'claimsForm__toggle-button--selected'
                : '',
            )}
            disabled
          >
            NO
          </Button>
        </Grid>
        <Grid key={'form-field-17'} item md={4} xs={12}>
          <TextField
            disabled
            id="clament-name"
            label="Reclamante"
            variant="outlined"
            value={currentClaim?.payment?.receiveACH?.claimentName}
          />
        </Grid>
        <Grid key={'form-field-18'} item md={4} xs={12}>
          <TextField
            disabled
            id="name-bank"
            label="Entidad Bancaria"
            variant="outlined"
            value={currentClaim?.payment?.receiveACH?.nameBank}
          />
        </Grid>
        <Grid key={'form-field-19'} item md={4} xs={12}>
          <TextField
            disabled
            id="route-number"
            label="# de Ruta"
            variant="outlined"
            value={currentClaim?.payment?.receiveACH?.routeNumber}
          />
        </Grid>
        <Grid key={'form-field-20'} item md={4} xs={12}>
          <TextField
            disabled
            id="account-number"
            label="# de Cuenta"
            variant="outlined"
            value={currentClaim?.payment?.receiveACH?.accountNumber}
          />
        </Grid>
        <Grid key={'form-field-21'} item md={4} xs={12}>
          <TextField
            disabled
            id="phone"
            label="Teléfono"
            variant="outlined"
            value={currentClaim?.payment?.receiveACH?.phone}
          />
        </Grid>
        <Grid key={'form-field-22'} item md={4} xs={12}>
          <FormControl variant="outlined" className={clsx('claimsForm__input')}>
            <InputLabel
              key="lbl-account-type"
              className="claimsForm__label-for-select"
            >
              Tipo de Cuenta
            </InputLabel>
            <Select
              key="select-account-type"
              value={currentClaim?.payment?.receiveACH?.accountType}
              // eslint-disable-next-line no-console
              onChange={() => {
                // eslint-disable-next-line no-console
                console.log('change');
              }}
              name="select-account-type"
              disabled
            >
              <MenuItem key="AHORRO-Key" value="A">
                Ahorro
              </MenuItem>
              <MenuItem key="CORRIENTE-Key" value="C">
                Corriente
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className="title-container">
        <br />
        <h2 className="title">Histórico de Pagos</h2>
      </div>
      <Divider />
      <div className="claims__title-container__payment-log">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Monto a Pagar</TableCell>
                <TableCell>Monto Estimado</TableCell>
                <TableCell>Monto Depreciación</TableCell>
                <TableCell>Monto Deducible</TableCell>
                <TableCell>Documento</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historicAmounts?.map((claimAmount, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {format(
                        Date.parse(
                          String(claimAmount?.createdDate).concat('Z'),
                        ),
                        'dd/MM/yyyy HH:mm:ss',
                        { locale: esLocale },
                      )}
                    </TableCell>
                    <TableCell>
                      {maskValue(claimAmount?.amount, '###,###.00', '#', false)}
                    </TableCell>
                    <TableCell>
                      {maskValue(
                        claimAmount?.estimatedAmount ?? 0,
                        '###,###.00',
                        '#',
                        false,
                      )}
                    </TableCell>
                    <TableCell>
                      {maskValue(
                        claimAmount?.depreciationAmount ?? 0,
                        '###,###.00',
                        '#',
                        false,
                      )}
                    </TableCell>
                    <TableCell>
                      {maskValue(
                        claimAmount?.deductibleAmount ?? 0,
                        '###,###.00',
                        '#',
                        false,
                      )}
                    </TableCell>
                    <TableCell>
                      {claimAmount?.linkDocument && (
                        <Link
                          target="_blank"
                          href={claimAmount.linkDocument}
                          color="primary"
                        >
                          Ver
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
