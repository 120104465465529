import React, { FunctionComponent, useEffect, useRef } from 'react';
import APIClient, { endpoints } from 'shared/api';
import { EventNote } from 'types/event-note';
import ReminderRow from './reminder-row/ReminderRow';
import { EventReminder } from 'types/event-reminder';
import { updateReminder } from 'context/inspections';

interface RemindersListProps {
  isInspectorForm?: boolean;
  reminders: EventReminder[];
  onRefresh?: (eventId: number) => void;
}

const RemindersList: FunctionComponent<RemindersListProps> = ({
  isInspectorForm = false,
  reminders,
  onRefresh,
}): JSX.Element => {
  const lastElementRef = useRef<HTMLDivElement>();

  const scrollToEnd = () => {
    if (lastElementRef.current) {
      lastElementRef.current.scrollIntoView({ block: 'end' });
    }
  };

  const previousNotes = useRef<EventReminder[]>([]);

  useEffect(() => {
    const previousNotesLength = previousNotes.current.length;
    if (reminders.length > previousNotesLength) {
      scrollToEnd();
    }
  }, [reminders, previousNotes.current.length]);

  useEffect(() => {
    previousNotes.current = reminders;
  }, [reminders]);

  const toggleDeleted = async (reminder: EventReminder) => {
    const newComment = {
      isDeleted: true,
    };

    await updateReminder(reminder.eventId, reminder.reminderId, newComment);

    //Espera de 800ms mientras actualiza en BD
    await new Promise((resolve) => setTimeout(resolve, 800));

    if (onRefresh) {
      onRefresh(reminder.eventId);
    }
  };

  return (
    <div className="notes_list_body">
      {reminders.map((reminder: EventReminder, index: number) => {
        const props: any = {
          isInspectorForm: isInspectorForm,
          note: reminder,
          notes: reminders,
          toggleDeleted: () => toggleDeleted(reminder),
        };
        if (index === reminders.length - 1) {
          props.ref = lastElementRef;
        }

        return <ReminderRow key={reminder.reminderId} {...props} />;
      })}
    </div>
  );
};

export default RemindersList;
