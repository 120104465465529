import useDevice from 'shared/hooks/useDevice';
import React, { FunctionComponent } from 'react';
import { EVENT_STATUSES } from 'static/constants/event-status';
import Chip from '../chip/Chip';
import clsx from 'clsx';
interface StatusProps {
  value: string;
  label: string;
  colorClass: string;
}

const StatusChip: FunctionComponent<{ eventStatus: string }> = ({
  eventStatus,
}): JSX.Element => {
  const eventStatusProps: StatusProps | undefined = Object.values<StatusProps>(
    EVENT_STATUSES,
  ).find((status: StatusProps) => status.value === eventStatus);

  const { isDesktop } = useDevice();

  if (eventStatusProps) {
    return isDesktop ? (
      <Chip
        label={eventStatusProps.label}
        color={eventStatusProps.colorClass}
        style={{ maxWidth: 125 }}
      />
    ) : (
      <div
        className={clsx(
          'status-mobile',
          eventStatusProps.colorClass &&
            `status-mobile--${eventStatusProps.colorClass}`,
        )}
      ></div>
    );
  }

  return <></>;
};

export default StatusChip;
