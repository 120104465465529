import React, { useEffect } from 'react';
import { CssBaseline, Typography, Paper, Link, Grid, Box } from '@mui/material';
import clsx from 'clsx';
import style from './override-mui-styles';
import './styles.scss';
import SICLogoPowerBy from '../../assets/images/SIC-logo-power-by.png';
import useJiraWidget from 'shared/hooks/useJiraWidget';

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'@'}
      {new Date().getFullYear()}
      {' CONNECT | '}
      <Link color="inherit" href="https://sic.connectasistencia.com">
        Sistema de Inspecciones
      </Link>
      {' | Version: 5.1.0'}
    </Typography>
  );
};

interface Props {
  children?: JSX.Element;
}

const Auth = (props: Props): JSX.Element => {
  const overrideMuiClasses = style();

  useEffect(() => {
    const interval = setInterval(() => {
      useJiraWidget();
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Grid container component="main" className={'root'}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={8} component={Paper} elevation={6} square>
          <Grid container className={'loginForm'}>
            <Grid item xs={10} sm={8} md={6}>
              <div className={'paper'}>
                <div className={'logo'}>
                  <img src={SICLogoPowerBy} alt="SIC" className="imageLogo" />
                </div>
                {props.children}
              </div>
              <Box mt={8}>
                <Copyright />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={4}
          className={clsx('image', overrideMuiClasses.image)}
        />
      </Grid>
    </>
  );
};

export default Auth;
