import './index.scss';
import { SketchProvider } from '@connect-technology/connect-react-components-lib';
import '@connect-technology/connect-react-components-lib/dist/index.css';
import { FiltersProvider } from 'context/filters/FiltersContext';
import { InspectionProvider } from 'context/inspections';
import { StyleProvider } from 'context/style/style-context';
import { PreInspectionProvider } from 'context/preinspections';
import { UserProvider } from 'context/user';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PATH_ROUTES from 'static/constants/path-routes';
import Auth from 'views/Authentication';
import SignIn from 'views/Authentication/signIn';
import SignUp from 'views/Authentication/signUp';
import Grid from 'views/Events/Grid';
import GridClaims from 'views/Claims/Events/Grid';
import GridPI from 'views/PreInspection/Grid';
import InspectionForm from 'views/Inspection/Form';
import PreInspectionForm from 'views/PreInspection/Form';
import Layout from 'views/Layout';
import Reports from 'views/Reports';
import Settings from 'views/Settings';
import CreatePreinspection from 'views/PreInspection/CreatePreinspection';
import CompletePreinspection from 'views/PreInspection/CompletePreinspection';
import InProgress from 'views/InProgress/inProgress';
import RenewCredentials from 'views/Authentication/renewCredentials';
import RequestAccess from 'views/Authentication/requestAccess';
import NotAccessRequest from 'views/Inspection/notAccessRequest';
import ConfigDamagesParams from 'views/ConfigDamages';
import ClaimsTracker from 'views/ClaimsTracker';
import Claims from 'views/Claims';
import Approval from 'views/Approval';
import DocumentTemplates from 'views/DocumentTemplates/Index';
import CreateTemplate from 'views/DocumentTemplates/create';
import EditTemplate from 'views/DocumentTemplates/edit';
import SetVariablesTemplate from 'views/DocumentTemplates/setVariables';
import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import { getInspectorUrlBase, networkIsOnline } from 'shared/utils';
import { sicSaveQueue } from 'shared/utils/requestQueue';
import ProfileSettings from 'views/User/profileSettings';
import { useSnackbar } from 'context/snackbar/snackbar-context';
import GuardRoute from 'guards/guardRoute';

export const AuthSignIn = (): any => {
  return (
    <Auth data-testid="auth-sign-in">
      <SignIn data-testid="sign-in" />
    </Auth>
  );
};

export const AuthSignUp = (): any => {
  return (
    <Auth>
      <SignUp />
    </Auth>
  );
};

export const AuthRecovery = (): any => {
  return (
    <Auth>
      <RenewCredentials />
    </Auth>
  );
};

export const AuthRequestAccess = (): any => {
  return (
    <Auth>
      <RequestAccess />
    </Auth>
  );
};

export const NotFoundAccess = (): any => {
  return <NotAccessRequest />;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const App = ({ onUpdateCallback }): JSX.Element => {
  const [showLossNetworkInfo, setShowLossNetworkInfo] = useState(false);
  const [showQueueProcess, setShowQueueProcess] = React.useState(false);
  const [showButtonRefresh, setShowButtonRefresh] = React.useState(false);
  const { showSnackbar } = useSnackbar();

  const enableToRefresh = (): void => {
    setShowButtonRefresh(true);
  };

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const netFlag = localStorage.getItem('networkFlag');

      if (netFlag && netFlag === '1') {
        const netValue = await networkIsOnline();
        setShowLossNetworkInfo(!netValue);

        if (netValue) {
          localStorage.removeItem('networkFlag');
        }
      }
    }, 3000); // Verificar cada 3 segundos

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    sicSaveQueue.processQueue(enableToRefresh);
    if (sicSaveQueue.processIsActive()) {
      console.warn('Sending pending request...');
      setShowQueueProcess(true);
    } else {
      console.warn('Not found pending request.');
      setShowQueueProcess(false);
    }
  }, []);

  useEffect(() => {
    onUpdateCallback.onUpdate = () => {
      showSnackbar(
        'Nueva versión disponible, actualiza la página para ver los cambios.',
        true,
      );
    };
  }, []);

  return (
    <>
      {showLossNetworkInfo && (
        <Alert
          severity="warning"
          className="networkMessage"
          onClose={() => setShowLossNetworkInfo(false)}
        >
          Verificar tu conexión a Internet. No podemos enviar la información
          pendiente.
        </Alert>
      )}
      <Backdrop sx={{ color: '#fff', zIndex: 6000 }} open={showQueueProcess}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={true}
        >
          <Alert severity={'info'}>
            {showButtonRefresh ? (
              <>
                <b>¡Cambios enviados!</b> &nbsp;&nbsp;
                <button
                  onClick={() => {
                    window.location.replace(getInspectorUrlBase());
                  }}
                >
                  Ver Inspección
                </button>
              </>
            ) : (
              <b>Por favor espere. Enviando cambios pendientes...</b>
            )}
          </Alert>
        </Snackbar>
        <CircularProgress color="inherit" />
      </Backdrop>
      <UserProvider>
        <InspectionProvider>
          <PreInspectionProvider>
            <StyleProvider>
              <SketchProvider>
                <Router>
                  <Switch>
                    <Route exact path="/" component={AuthSignIn} />
                    <Route
                      exact
                      path={PATH_ROUTES.USER_REQUEST_PASSWORD}
                      component={AuthRequestAccess}
                    />
                    <Route
                      exact
                      path={PATH_ROUTES.USER_RENEW_PASSWORD}
                      component={AuthRecovery}
                    />
                    <Route
                      path={PATH_ROUTES.INSPECTOR_FORM_NOT_ACCESS}
                      component={NotFoundAccess}
                    />
                    <Route
                      path={PATH_ROUTES.INSPECTOR_FORM_SECTION}
                      component={InspectionForm}
                    />
                    <Route
                      path={PATH_ROUTES.INSPECTOR_FORM_ID}
                      component={InspectionForm}
                    />
                    <Route>
                      <FiltersProvider>
                        <Layout>
                          <Switch>
                            <GuardRoute
                              path={PATH_ROUTES.IN_PROGRESS}
                              component={InProgress}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.INSPECTION_SECTION}
                              component={InspectionForm}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.INSPECTION_ID}
                              component={InspectionForm}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.PRE_INSPECTIONS_FORM_SECTION}
                              component={PreInspectionForm}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.PRE_INSPECTIONS_FORM_ID}
                              component={PreInspectionForm}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.EVENTS}
                              component={Grid}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.EVENTS_CLAIMS}
                              component={GridClaims}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.PRE_INSPECTIONS}
                              component={GridPI}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.PRE_INSPECTION_CREATE}
                              component={CreatePreinspection}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.PRE_INSPECTION_COMPLETE}
                              component={CompletePreinspection}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.REPORTS}
                              component={Reports}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.SETTINGS}
                              component={Settings}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.USERS_PROFILE}
                              component={ProfileSettings}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.PRE_INSPECTION_CONFIG_DAMAGES}
                              component={ConfigDamagesParams}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.CLAIMS_TRACKER_MAIN}
                              component={ClaimsTracker}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.CLAIMS}
                              component={Claims}
                            />
                            <GuardRoute
                              path={PATH_ROUTES.APPROVAL}
                              component={Approval}
                            />
                            <GuardRoute
                              exact
                              path="/documentTemplates"
                              component={DocumentTemplates}
                            />
                            <GuardRoute
                              exact
                              path="/createTemplate"
                              component={CreateTemplate}
                            />
                            <GuardRoute
                              exact
                              path="/editTemplate/:id"
                              component={EditTemplate}
                            />
                            <GuardRoute
                              exact
                              path="/setVariablesTemplate/:id"
                              component={SetVariablesTemplate}
                            />
                          </Switch>
                        </Layout>
                      </FiltersProvider>
                    </Route>
                  </Switch>
                </Router>
              </SketchProvider>
            </StyleProvider>
          </PreInspectionProvider>
        </InspectionProvider>
      </UserProvider>
    </>
  );
};

export default App;
