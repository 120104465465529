import { makeStyles, createStyles } from '@mui/styles';
import { globalCss } from 'assets/connect/styles/_variables';

export default makeStyles(() =>
  createStyles({
    tabContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '100%',
    },
    tab: {
      padding: '12px 6px',
      color: globalCss.cadetBlue,
      flexDirection: 'column',
    },
    tabIcon: {
      marginLeft: '10px',
      color: globalCss.cadetBlue,
      margin: '4px 4px 4px 10px',
      minWidth: 'unset',
    },
    tabText: {
      margin: '4px',
    },
    span: {
      fontSize: '0.85rem',
      fontWeight: 500,
    },
    tabActive: {
      color: globalCss.primary,
      fontWeight: 'bold',
      borderRight: `4px solid ${globalCss.primary}`,
      borderRadius: '4px',
      backgroundColor: `${globalCss.primaryOp10}`,
    },
    tabIconActive: {
      color: globalCss.primary,
    },
    tabTextHide: {
      display: 'none',
    },
    childrenContainer: {
      display: 'block',
      marginLeft: '14px',
    },
    childrenContainerHidden: {
      display: 'none',
    },
  }),
);
