import { Button, Paper, Theme, Chip, Grid } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import clsx from 'clsx';
import {
  getResponsableAAP,
  IInspectionContext,
  InspectionContext,
} from 'context/inspections';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ActionType } from 'types/action';
import { IConsequence } from 'types/inspection';
import './styles.scss';
import { useFormikContext } from 'formik';

interface CircunstancesProps {
  circunstancesList: any;
  thirdPlate?: string;
  thirdInsurance?: string;
  codeVehicleA?: string;
  codeVehicleB?: string;
  responsable?: string;
  isActive: boolean;
  save: (data: IConsequence) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

const CircunstancesForm: FunctionComponent<CircunstancesProps> = ({
  circunstancesList,
  thirdPlate,
  thirdInsurance,
  codeVehicleA,
  codeVehicleB,
  responsable,
  isActive,
  save,
}): JSX.Element => {
  const classes = useStyles();
  const {
    dispatch,
    state: { currentInspection: event },
  } = useContext<IInspectionContext>(InspectionContext);
  const [vehicleA_Code, setVehicleA_Code] = useState(codeVehicleA);
  const [vehicleB_Code, setVehicleB_Code] = useState(codeVehicleB);
  const [responsableData, setResponsableData] = useState(responsable);
  const formik = useFormikContext<any>();

  const getResponsable = () => {
    dispatch({ type: ActionType.SET_LOADING, payload: true });
    if (event && isActive && vehicleA_Code && vehicleB_Code) {
      (async (): Promise<void> => {
        // Get Data from AAP
        const valueFromCodeA: any = Array.from(circunstancesList).filter(
          (f: any) => f.vehicleA.code === vehicleA_Code,
        )[0];
        const valueFromCodeB: any = Array.from(circunstancesList).filter(
          (f: any) => f.vehicleB.code === vehicleB_Code,
        )[0];

        if (valueFromCodeA != null && valueFromCodeB != null) {
          const result: any = await getResponsableAAP(
            event.APIKey,
            valueFromCodeA.value,
            valueFromCodeB.value,
          );
          setResponsableData(result.data);

          const dataConsequences: IConsequence = {
            CodigoAsegurado: vehicleA_Code,
            CodigoTercero: vehicleB_Code,
            Responsable: result.data,
            Placa1: formik.values['VehicleLisencePlate'],
            Placa2: thirdPlate ?? '',
            Aseguradora1: formik.values['Aseguradora1'],
            Aseguradora2: thirdInsurance ?? '',
          };

          dispatch({
            type: ActionType.SET_CONSEQUENCES,
            payload: dataConsequences,
          });

          save(dataConsequences);
          dispatch({ type: ActionType.SET_LOADING, payload: false });
        }
      })();
    } else {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const setVehicleCode = (type: string, value: string) => {
    if (type === 'A') {
      setVehicleA_Code(value);
    } else {
      setVehicleB_Code(value);
    }
  };

  useEffect(() => {
    setVehicleA_Code(codeVehicleA);
  }, [codeVehicleA]);

  useEffect(() => {
    setVehicleB_Code(codeVehicleB);
  }, [codeVehicleB]);

  useEffect(() => {
    setResponsableData(responsable);
  }, [responsable]);

  const circunstanceRow = (
    currentCodeVehicleA: string | undefined,
    currentCodeVehicleB: string | undefined,
  ) => {
    const isEven = (index: number) => {
      return index % 2 === 0;
    };

    return (
      <>
        {Array.from(circunstancesList).map((m: any, index: any) => {
          return (
            <div key={index}>
              <Grid
                container
                spacing={1}
                className={
                  isEven(+m.id)
                    ? clsx(
                        'circunstancesForm__rowEven',
                        'circunstancesForm__rowEven__top',
                      )
                    : clsx(
                        'circunstancesForm__rowOdd',
                        'circunstancesForm__rowOdd__top',
                      )
                }
              >
                <Grid item xs></Grid>
                <Grid item xs={8}>
                  <b>
                    {m.id} - {m.label}
                  </b>
                </Grid>
                <Grid item xs></Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                className={
                  isEven(+m.id)
                    ? clsx(
                        'circunstancesForm__rowEven',
                        'circunstancesForm__rowEven__bottom',
                      )
                    : clsx(
                        'circunstancesForm__rowOdd',
                        'circunstancesForm__rowOdd__bottom',
                      )
                }
              >
                <Grid item xs={6}>
                  <input
                    type="radio"
                    id={m.vehicleA?.code}
                    name={m.vehicleA?.name}
                    value={m.vehicleA?.value}
                    checked={m.vehicleA?.code === currentCodeVehicleA}
                    onClick={() => setVehicleCode('A', m.vehicleA?.code)}
                    onChange={() => setVehicleCode('A', m.vehicleA?.code)}
                  />
                  {m.vehicleA?.code === currentCodeVehicleA && (
                    <Chip color="primary" label="Vehículo A" />
                  )}

                  {m.vehicleA?.code !== currentCodeVehicleA && (
                    <label htmlFor={m.vehicleA?.code}>Vehículo A</label>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <input
                    type="radio"
                    id={m.vehicleB?.code}
                    name={m.vehicleB?.name}
                    value={m.vehicleB?.value}
                    checked={m.vehicleB?.code === currentCodeVehicleB}
                    onClick={() => setVehicleCode('B', m.vehicleB?.code)}
                    onChange={() => setVehicleCode('B', m.vehicleB?.code)}
                  />

                  {m.vehicleB?.code === currentCodeVehicleB && (
                    <Chip color="primary" label="Vehículo B" />
                  )}

                  {m.vehicleB?.code !== currentCodeVehicleB && (
                    <label htmlFor={m.vehicleB?.code}>Vehículo B</label>
                  )}
                </Grid>
              </Grid>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="circunstancesForm">
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={1}
          className={clsx('circunstancesForm__rowTitle')}
        >
          <Grid item xs={6}>
            <b>VEHÍCULO A</b>
          </Grid>
          <Grid item xs={6}>
            <b>VEHÍCULO B</b>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className={clsx('circunstancesForm__rowTitle')}
        >
          <Grid item xs></Grid>
          <Grid item xs={8}>
            <label className={clsx('circunstancesForm__rowSubTitle')}>
              * Marque la circunstancia que describe el accidente.
            </label>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
        {circunstanceRow(vehicleA_Code, vehicleB_Code)}
      </Paper>

      <div className="MuiTypography-alignCenter">
        {responsableData && responsableData.length > 0 && (
          <>
            <br />
            <Chip color="primary" label={'Responsable es ' + responsableData} />
            <br />
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          className={clsx('circunstancesForm__button')}
          size="large"
          onClick={() => getResponsable()}
          disabled={!isActive}
        >
          Responsable
        </Button>
      </div>
    </div>
  );
};

export default CircunstancesForm;
