import { CognitoUser } from 'amazon-cognito-identity-js';

export interface ILoginModel {
  username: string;
  password: string;
  newPassword?: string;
  country?: string;
  role?: string;
  company?: number;
  name?: string;
  verifyCode?: string;
  challengeSession?: string;
}

export interface IUserStatusModel {
  isError: boolean;
  isLogin: boolean | false;
  token: string;
  message: string;
  country: string;
  role: number;
  userId: number;
  guid: string;
  name: string;
  isOneAccess: boolean | false;
}

export interface IUserAttrSession {
  sessionAttr: any;
  isNewUser: boolean | false;
  confirmAccount: boolean | false;
}

export enum IAppFormType {
  INSPECTION = 1,
  PRE_INSPECTION = 2,
}

export interface IUserModel {
  userCompanyID?: number;
  guidID: string;
  userName?: string;
  rolId?: number;
  rolName?: string;
  rolDescription?: string;
  companyId: number;
  companyName?: string;
  email: string;
  active?: boolean;
  name: string;
  lastName?: string;
  currentCount?: number;
  runnerCode?: string;
  vendorID?: string;
  isNewUser?: boolean;
  password?: string;
  customerCode?: string;
  codPais?: string;
  preInspectionLink?: string;
  countryId?: number;
  isMultiCountry: boolean;
  companyIdList?: string;
  countryIdList?: string;
  currentCountryCode?: string;
  cellphone?: string;
  useSSO?: boolean;
  use2FA?: boolean;
  updatedDate?: string;
  updatedBy?: number;
  changePW?: boolean;
}

export interface IDriverModel {
  driverId?: number;
  driverCode: string;
  isActive: boolean;
  currentCount?: number;
}

export interface Agent {
  id: number | null;
  name: string;
  email: string;
}

export interface IUserState {
  loading: boolean;
  userSession: IUserAttrSession;
  currentUserStatus: IUserStatusModel;
  currentUser: IUserModel;
  appFormType: IAppFormType;
  restoreNetConnection: boolean;
  hasNetConnection: boolean;
  showPasswdReqAlert: boolean;
  isErrorPasswdReqAlert: boolean;
  showCreateUsrAlert: boolean;
  msgCreateUsrAlert: string;
  isErrorCreateUsrAlert: boolean;
  showChangePasswdAlert: boolean;
  isErrorChangePasswdAlert: boolean;
  msgChangePasswdAlert: string;
  showMfaChallenge: boolean;
  sessionMFAChallenge?: string;
}
