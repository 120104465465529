import { keepOrderPhotos } from 'components/inspection/photos/helpers';
import { ActionType, IAction } from 'types/action';
import { IInspectionState, inspectionDefault } from 'types/inspection';

export const initialState: IInspectionState = {
  inspections: [],
  currentInspection: inspectionDefault,
  statusesIndicators: [],
  newInspection: inspectionDefault,
  currentImages: [],
  rotateImages: [],
  actionMessage: '',
  loading: true,
  plateFilter: '',
  paginator: { rows: 0, initial: 0, final: 0 },
  recordFilter: '',
  requestParams: {
    countryCode: localStorage.getItem('countryCode') || 'PRI',
    page: 1,
  },
  showAlert: false,
  showAlertPhoto: false,
  showErrorModalImages: false,
  totalUploadImages: 0,
  countUploadImages: 0,
  inspectorUserName: null,
  auditEvent: {
    auditSection: 0,
    auditIsActive: false,
    auditNoApply: false,
    auditEffectiveTime: 0,
    auditTimeFini: '',
    auditTimeInit: '',
    auditMessage: '',
    sectionsAudit: [],
    auditThirdSection: false,
    auditInjuredSection: false,
    auditPhotoSection: false,
  },
  integrationResendConfirm: false,
  integrationResendEventId: -1,
  customPhotosIsValid: true,
  initializeRetriesProcess: false,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const inspectionReducer = (state: IInspectionState, action: IAction) => {
  switch (action.type) {
    case ActionType.GET_EVENTS_SUCCESS:
      return {
        ...state,
        inspections: action.payload.events,
        statusesIndicators: action.payload.indicators,
        loading: false,
      };
    case ActionType.SET_PLATE_FILTER:
      return {
        ...state,
        plateFilter: action.payload,
      };
    case ActionType.GET_PAGINATOR_SUCCESS:
      return {
        ...state,
        paginator: action.payload.paginator,
      };
    case ActionType.CLEAR_PLATE_FILTER:
      return {
        ...state,
        plateFilter: '',
      };
    case ActionType.SET_RECORD_FILTER:
      return {
        ...state,
        recordFilter: action.payload,
      };
    case ActionType.CLEAR_RECORD_FILTER:
      return {
        ...state,
        recordFilter: '',
      };
    case ActionType.SET_INSPECTION_MODEL_TO_DEFAULT:
      return {
        ...state,
        newInspection: inspectionDefault,
      };
    case ActionType.GET_EVENTS_FAIL:
      return {
        ...state,
        actionMessage: action.payload,
        loading: false,
      };
    case ActionType.SET_INSPECCION_IMAGES: {
      const finaList = keepOrderPhotos(state.currentImages, action.payload);

      return {
        ...state,
        currentImages: finaList,
      };
    }
    case ActionType.SET_CURRENT_INSPECTION:
      return {
        ...state,
        currentInspection: action.payload,
        loading: false,
      };
    case ActionType.SET_INSPECTION_THIRDS: {
      const thirdsData = action.payload;

      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          thirds: thirdsData,
        },
        loading: false,
      };
    }
    case ActionType.SET_INSPECTION_INJURY_PERSONS:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          injuryPersons: action.payload,
        },
        loading: false,
      };
    case ActionType.SET_INSPECTION_COVERAGES:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          coverages: action.payload,
        },
        loading: false,
      };
    case ActionType.SET_EXPRESS_ADJUST:
      // eslint-disable-next-line no-case-declarations
      const currInsp: any = {
        ...state.currentInspection,
        IndExpressAdjust: action.payload,
      };

      return {
        ...state,
        currentInspection: currInsp,
      };
    case ActionType.SET_GRID_REQUEST_PARAMS:
      return {
        ...state,
        requestParams: action.payload,
      };
    case ActionType.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionType.INIT_STATE:
      return {
        ...initialState,
      };
    case ActionType.SET_GENERAL_ALERT:
      return {
        ...state,
        showAlert: action.payload.showAlert,
        alertMessage: action.payload.alertMessage,
        alertType: action.payload.alertType,
      };
    case ActionType.SET_PHOTO_ALERT:
      return {
        ...state,
        showAlertPhoto: action.payload.showAlertPhoto,
        alertMessagePhoto: action.payload.alertMessagePhoto,
        alertTypePhoto: action.payload.alertTypePhoto,
      };
    case ActionType.SET_ERROR_MODAL:
      return {
        ...state,
        showErrorModalImages: action.payload.showErrorModalImages,
      };
    case ActionType.SET_COUNT_PROGREES_UPLOAD_IMAGES:
      return {
        ...state,
        totalUploadImages: action.payload.totalUploadImages,
        countUploadImages: action.payload.countUploadImages,
      };
    case ActionType.SET_UPLOAD_PROGRESS:
      return {
        ...state,
        inProgress: action.payload.inProgress,
      };
    case ActionType.SET_CURRENT_FORM_STATUS:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          formIsValid: action.payload,
        },
      };
    case ActionType.SET_CONSEQUENCES:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          consequence: action.payload,
        },
      };
    case ActionType.SET_CONFIRM_AAP_FORM:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          confirmAAPForm: action.payload,
        },
      };
    case ActionType.SET_SKETCH_DATA:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          ...action.payload,
        },
      };
    case ActionType.SET_OPTIONS_AAP_FORM:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          optionAAPForm: action.payload.option,
          optionTextAAPForm: action.payload.optionText,
        },
      };
    case ActionType.SET_AAP_FLAG:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          usePactoAmistoso: action.payload,
        },
      };
    case ActionType.SET_VEHICLE_DEFINITION:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          vehicleDefinition: action.payload,
        },
        aapFormRefresh: true,
      };
    case ActionType.SET_NOT_COVERAGE:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          NoCoverageApplied: action.payload,
        },
      };
    case ActionType.SET_REQUIRED_QUALIEXPRESS:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          IsRequiredQualiExpress: action.payload,
        },
      };
    case ActionType.SET_REQUIRED_DIAGRAM:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          IsRequiredDiagram: action.payload,
        },
      };
    case ActionType.SET_ACCIDENT_PLACE:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          DiagramIsShowed: action.payload,
          isAccidentPlace: action.payload === true ? '1' : '2',
        },
      };
    case ActionType.SET_REQUIRED_SINISTERTYPE:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          sinisterType: action.payload,
        },
      };
    case ActionType.SET_REQUIRED_SINISTERCAUSE:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          sinisterCause: action.payload,
        },
      };
    case ActionType.SET_REQUIRED_ACCEPT_SIGNATURE:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          AcceptTermsSignature: action.payload,
        },
      };
    case ActionType.SET_REASON_ID:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          ReasonId: action.payload,
        },
      };
    case ActionType.SET_ASSISTANCE_REQUIRED:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          LegalAssistanceRequired: action.payload,
        },
      };
    case ActionType.SET_EVENT_STATUS:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          EventStateId: action.payload,
        },
      };
    case ActionType.SET_IND_PART_ACC:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          hasTrafficTicket: action.payload,
        },
      };
    case ActionType.SET_USER_INSPECTOR:
      return {
        ...state,
        inspectorUserName: action.payload,
      };
    case ActionType.SET_SINISTER_DATE:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          eventDateSinisterVal: action.payload,
        },
      };
    case ActionType.SET_AUDIO_STORY:
      return {
        ...state,
        audioStoryUrl: action.payload,
      };
    case ActionType.SET_AUDIO_PRESIGNED:
      return {
        ...state,
        audioPresignedUrl: action.payload,
      };
    case ActionType.SET_BILLRIGHTS_MAIL:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          AcceptTermsEmail: action.payload,
        },
      };
    case ActionType.SET_AUDIO_TRANSCRIPT:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          storyDetail: action.payload,
        },
      };
    case ActionType.SET_AUDIO_INSURED:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          InsuredVehicle: {
            ...state.currentInspection?.InsuredVehicle,
            AudioOcurrido: action.payload,
          },
        },
      };
    case ActionType.SET_AUDIO_INSURED_PRESIGNED:
      return {
        ...state,
        audioVehicleInsuredPresigned: action.payload,
      };
    case ActionType.SET_AUDIO_TRANSCRIPT_INSURED:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          InsuredVehicle: {
            ...state.currentInspection?.InsuredVehicle,
            Ocurrido: action.payload,
          },
        },
      };
    case ActionType.SET_AUDIO_INJURED:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          InjuredVehicle: {
            ...state.currentInspection?.InjuredVehicle,
            AudioOcurrido: action.payload,
          },
        },
      };
    case ActionType.SET_AUDIO_INJURED_PRESIGNED:
      return {
        ...state,
        audioVehicleInjuredPresigned: action.payload,
      };
    case ActionType.SET_AUDIO_TRANSCRIPT_INJURED:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          InjuredVehicle: {
            ...state.currentInspection?.InjuredVehicle,
            VehicleDescription2: action.payload,
          },
        },
      };
    case ActionType.SET_LIST_FOR_ROTATE_IMAGES: {
      let listData = action.payload.data;
      const rotateDataStr = localStorage.getItem('rotateImageList') ?? '';

      if (
        rotateDataStr.length > 0 &&
        listData !== null &&
        !action.payload.forceUpdate
      ) {
        const currentList = Array.from(JSON.parse(rotateDataStr));
        Array.from(listData).forEach((fe: any) => {
          if (
            fe.orientation !== null &&
            currentList.filter(
              (f: any) => String(f.imageName).indexOf(fe.imageName) > -1,
            ).length === 0
          ) {
            currentList.push(fe);
          }
        });

        listData = currentList;
        localStorage.setItem('rotateImageList', JSON.stringify(currentList));
      } else {
        localStorage.setItem('rotateImageList', JSON.stringify(listData));
      }

      return {
        ...state,
        rotateImages: listData,
      };
    }
    case ActionType.SET_AUDIT_EVENT_STATUS:
      return {
        ...state,
        auditEvent: {
          ...state.auditEvent,
          auditIsActive: action.payload,
        },
      };
    case ActionType.SET_NOT_AUDIT_EVENT:
      return {
        ...state,
        auditEvent: {
          ...state.auditEvent,
          auditNoApply: action.payload,
        },
      };
    case ActionType.SET_AUDIT_EVENT_MSG:
      return {
        ...state,
        auditEvent: {
          ...state.auditEvent,
          auditMessage: action.payload,
        },
      };
    case ActionType.SET_AUDIT_EVENT_TIMES:
      return {
        ...state,
        auditEvent: {
          ...state.auditEvent,
          auditEffectiveTime: action.payload.EffectiveTime,
          auditTimeFini: action.payload.TimeFini,
          auditTimeInit: action.payload.TimeInit,
        },
      };
    case ActionType.SET_AUDIT_SECTION:
      return {
        ...state,
        auditEvent: {
          ...state.auditEvent,
          auditSection: action.payload,
        },
      };
    case ActionType.SET_AUDIT_PROCESS_SECTION: {
      let sections = state.auditEvent.sectionsAudit;
      if (action.payload > 0) {
        sections.push(action.payload);
      } else {
        sections = [];
      }

      return {
        ...state,
        auditEvent: {
          ...state.auditEvent,
          sectionsAudit: sections,
        },
      };
    }
    case ActionType.SET_AUDIT_TRIGGER_THIRD_SECTION:
      return {
        ...state,
        auditEvent: {
          ...state.auditEvent,
          auditThirdSection: action.payload,
        },
      };
    case ActionType.SET_AUDIT_TRIGGER_INJURED_SECTION:
      return {
        ...state,
        auditEvent: {
          ...state.auditEvent,
          auditInjuredSection: action.payload,
        },
      };
    case ActionType.SET_INTEGRATION_CONFIRM_RESEND:
      return {
        ...state,
        integrationResendConfirm: action.payload,
      };
    case ActionType.SET_INTEGRATION_RESEND_ID_EVENT:
      return {
        ...state,
        integrationResendEventId: action.payload,
      };
    case ActionType.SET_IND_AIRBAG:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          indAirbagsActive: action.payload,
        },
      };
    case ActionType.SET_IND_SEATBELT:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          indSeatBeltStagnant: action.payload,
        },
      };
    case ActionType.SET_IND_FRONTAXLE:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          indFrontAxleDamage: action.payload,
        },
      };
    case ActionType.SET_IND_REARAXLE:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          indRearAxleDamage: action.payload,
        },
      };
    case ActionType.SET_IND_LOOSEPARTS:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          indVehicleLooseParts: action.payload,
        },
      };
    case ActionType.SET_IND_OPENTRUNK:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          indCustomerOpenTrunk: action.payload,
        },
      };
    case ActionType.SET_IND_SINIESTER_TYPE:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          sinisterTypePR: action.payload,
        },
      };
    case ActionType.SET_IND_AIRBAG_INSURED:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          indAirbagsActiveInsured: action.payload,
        },
      };
    case ActionType.SET_IND_SEATBELT_INSURED:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          indSeatBeltStagnantInsured: action.payload,
        },
      };
    case ActionType.SET_IND_FRONTAXLE_INSURED:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          indFrontAxleDamageInsured: action.payload,
        },
      };
    case ActionType.SET_IND_REARAXLE_INSURED:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          indRearAxleDamageInsured: action.payload,
        },
      };
    case ActionType.SET_IND_LOOSEPARTS_INSURED:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          indVehicleLoosePartsInsured: action.payload,
        },
      };
    case ActionType.SET_IND_OPENTRUNK_INSURED:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          indCustomerOpenTrunkInsured: action.payload,
        },
      };
    case ActionType.SET_IND_SINIESTER_TYPE_INSURED:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          sinisterTypeInsuredPR: action.payload,
        },
      };
    case ActionType.SET_IS_VALID_CUSTOM_PHOTOS:
      return {
        ...state,
        customPhotosIsValid: action.payload,
      };
    case ActionType.SET_AUDIT_TRIGGER_PHOTO_SECTION:
      return {
        ...state,
        auditEvent: {
          ...state.auditEvent,
          auditPhotoSection: action.payload,
        },
      };
    case ActionType.SET_DRIVER_NAME:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          driverName: action.payload,
        },
      };
    case ActionType.SET_DRIVER_LAST_NAME:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          driverLastName: action.payload,
        },
      };
    case ActionType.SET_DRIVER_ID:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          driverId: action.payload,
        },
      };
    case ActionType.SET_DRIVER_LICENSE_NUMBER:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          driverNumLicense: action.payload,
        },
      };
    case ActionType.SET_DRIVER_LICENSE_EXPIRATION:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          driverExpireLicenseDate: action.payload,
        },
      };
    case ActionType.SET_DRIVER_BIRTH_DATE:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          driverBirthDate: action.payload,
        },
      };
    case ActionType.SET_DRIVER_LICENSE_TYPE:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          driverLicenseType: action.payload,
        },
      };
    case ActionType.SET_PLATE_CIRCUNSTANCES_INSURED:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          plateCircunstancesInsured: action.payload,
        },
      };
    case ActionType.SET_CLAIMS_TRACKER_DATA:
      return {
        ...state,
        claimsTrackerInfo: action.payload,
      };
    case ActionType.SET_CLAIMS_INFO_DATA:
      return {
        ...state,
        claimsManagementInfo: action.payload,
      };
    case ActionType.SET_REFRESH_AAP_IFRAME:
      return {
        ...state,
        aapFormRefresh: false,
      };
    case ActionType.SET_EXPENSES_AMOUNT:
      return {
        ...state,
        expenseAmount: action.payload,
      };
    case ActionType.SET_PARTS_AMOUNT:
      return {
        ...state,
        partsAmount: action.payload,
      };
    case ActionType.SET_ADJUST_AMOUNT:
      return {
        ...state,
        adjustAmount: action.payload,
      };
    case ActionType.SET_ESTIMATED_AMOUNT:
      return {
        ...state,
        estimatedAmount: action.payload,
      };
    case ActionType.SET_WORKSHOPS_DATA:
      return {
        ...state,
        workshopsInfo: action.payload,
      };
    case ActionType.SET_RETRIES_PROCESS_FLAG:
      return {
        ...state,
        initializeRetriesProcess: action.payload,
      };
    default:
      return state;
  }
};

export default inspectionReducer;
