import React, { FunctionComponent } from 'react';
import { IInspectionState } from 'types/inspection';
import inspectionReducer, { initialState } from './inspectionReducer';

export const InspectionContext = React.createContext<IInspectionState | any>(
  initialState,
);

export const InspectionProvider: FunctionComponent = (props): JSX.Element => {
  const [state, dispatch] = React.useReducer(inspectionReducer, initialState);

  return (
    <InspectionContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </InspectionContext.Provider>
  );
};
