import React from 'react';
import clsx from 'clsx';
import './styles.scss';

interface Props {
  color: string;
  label: string;
  compact?: boolean;
  style?: React.CSSProperties;
  isClaim?: boolean;
}

const Chip: React.FC<Props> = React.memo((props: Props) => (
  <div
    className={
      !props.isClaim
        ? clsx(
            'chip',
            `chip--${props.color}`,
            props.compact ? 'chip--compact' : '',
          )
        : clsx('chip-status')
    }
    style={props.style}
  >
    {props.label}
  </div>
));

export default Chip;
