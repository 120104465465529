import { Cancel, DateRange } from '@mui/icons-material';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import React, { FC } from 'react';

export const onCancelClicked = (
  setIsRangesDates: (value: boolean) => void,
  onChangeStartDate: (date: Date | null) => void,
  onChangeEndDate?: (date: Date | null) => void,
): void => {
  if (onChangeEndDate) {
    onChangeEndDate(null);
  }
  onChangeStartDate(null);
  setIsRangesDates(false);
};

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  isRangeDates: boolean;
  onClick?: () => void; //! I don't know how it works, but do not remove even if is not passed as a prop. If removed, it wont open the calendar.
  onChangeStartDate: (date: Date | null) => void;
  onChangeEndDate?: (date: Date | null) => void;
  setIsRangesDates: (value: boolean) => void;
}

const DatePickerInput: FC<Props> = React.forwardRef(
  (
    {
      onClick,
      startDate,
      endDate,
      isRangeDates,
      setIsRangesDates,
      onChangeStartDate,
      onChangeEndDate,
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref,
  ) => {
    return (
      <div className="datepicker__container">
        <div
          onClick={onClick}
          data-testid="datepicker-input"
          className="datepicker__input"
        >
          {startDate ? (
            <div>
              <span>
                {format(startDate, 'dd/MM/yyyy', { locale: esLocale })}
              </span>
              {isRangeDates && (
                <span className="datepicker__inputSeparator">-</span>
              )}
              {isRangeDates && endDate && (
                <span>
                  {format(endDate, 'dd/MM/yyyy', { locale: esLocale })}
                </span>
              )}
            </div>
          ) : (
            <>
              <span className="datepicker__inputPlaceholder">
                {isRangeDates
                  ? 'Fecha inicio - Fecha final'
                  : 'Seleccione una fecha'}
              </span>
              <DateRange className="datepicker__calendarIcon" />
            </>
          )}
        </div>
        {startDate && (
          <span
            data-testid="datepicker-cancel-btn"
            onClick={() =>
              onCancelClicked(
                setIsRangesDates,
                onChangeStartDate,
                onChangeEndDate,
              )
            }
          >
            <Cancel className="datepicker__cancelIcon" />
          </span>
        )}
      </div>
    );
  },
);

export default DatePickerInput;
