import React, { FC } from 'react';
import ErrorImg from 'assets/images/search_error.svg';
import './SearchError.scss';

const SearchError: FC = () => {
  return (
    <div className="search-error">
      <h2>Oh no...</h2>
      <p>Desafortunadamente, lo que buscaste no se encontró o no existe.</p>
      <p>Intenta ingresando otro termino.</p>
      <img src={ErrorImg} alt="error de busqueda" />
    </div>
  );
};

export default SearchError;
