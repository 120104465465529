import React from 'react';
import clsx from 'clsx';

const steps = ({ screenIndex }: { screenIndex: number }): JSX.Element => {
  const screens = [
    { index: 1, screen: 'Principal' },
    { index: 2, screen: 'Codigo QR' },
  ];

  return (
    <p>
      Paso:
      {screens.map((obj) => {
        const className = clsx('step', {
          selected: screenIndex === obj.index,
        });

        return (
          <span key={obj.index} className={className}>
            {obj.index}
          </span>
        );
      })}
    </p>
  );
};

export default steps;
