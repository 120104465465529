import clsx from 'clsx';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import {
  createOrUpdateDriver,
  getDriversByUser,
  inactiveDriver,
} from 'context/user';
import { useForm } from 'react-hook-form';
import Chip from 'components/common/chip/Chip';
import { GRID_USER_DRIVER_COLUMNS } from 'static/constants/events-grid';
import { Column } from 'types/column';
import { IDriverModel } from 'types/user';
import ValidationLabel from 'components/common/validation-label';
import Loader from 'components/common/loader/Loader';
import styles from '../override-mui-styles';

export const StatusChip = (eventStatus: boolean): JSX.Element => {
  if (eventStatus) {
    return <Chip label="Activo" color="green" />;
  } else {
    return <Chip label="Inactivo" color="red" />;
  }
};

interface Props {
  userId: string;
}

const DriverList = (props: Props): JSX.Element => {
  const classes = styles();
  const { handleSubmit, register, errors } = useForm();
  const elementToScroll = useRef<null | HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [listDrivers, setListDrivers] = useState<IDriverModel[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [cantRegisters, setCantRegisters] = useState<number>(20);
  const [disabledModify, setDisabledModify] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [usefetchData, setUseFetchData] = useState(true);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [showForm, setShowForm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertIsError, setAlertIsError] = useState(false);

  //Form Data
  const [currentDriver, setCurrentDriver] = useState<IDriverModel | null>(null);
  const [driverIsActive, setDriverIsActive] = useState(true);

  const executeScroll = () => {
    if (elementToScroll !== null && elementToScroll.current !== null) {
      elementToScroll.current.scrollIntoView();
    }
  };

  const onChangePage = (event: any, page: number) => {
    const newPage = page === 0 ? 1 : page + 1;
    setCurrentPage(newPage);
    setUseFetchData(true);
  };

  const onChangeSearchFilter = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setCurrentPage(1);
    setSearchFilter(event.target.value);
  };

  const fetchDrivers = useCallback(() => {
    const filters: any = [];
    setLoading(true);

    if (searchFilter.length > 0) {
      filters.push({
        name: 'filter',
        value: searchFilter,
      });
    }

    filters.push({
      name: 'page',
      value: currentPage,
    });
    filters.push({
      name: 'results',
      value: 10,
    });

    (async (): Promise<void> => {
      const resultDrivers = await getDriversByUser(props.userId, filters);
      if (resultDrivers) {
        setListDrivers(resultDrivers);
        setCantRegisters(resultDrivers[0]?.currentCount ?? 0);
      }
      setLoading(false);
    })();
  }, [currentPage, searchFilter, props]);

  const triggerInactiveDriver = useCallback(
    (driverId: number) => {
      (async (): Promise<void> => {
        if (driverId > 0) {
          const result = await inactiveDriver(driverId);
          setShowAlert(false);
          setAlertMessage('');
          setAlertIsError(false);

          if (!result.error) {
            fetchDrivers();
          } else {
            setShowAlert(true);
            setAlertMessage(
              'Hubo problemas al realizar la solicitud. Por favor reintente de nuevo.',
            );
            setAlertIsError(true);
          }

          setShowModalConfirm(false);
        }
      })();
    },
    [fetchDrivers],
  );

  const onSubmit = useCallback(
    (data: any) => {
      (async (): Promise<void> => {
        const dataUser: IDriverModel = {
          isActive: driverIsActive,
          driverCode: data.driverCode,
          driverId: currentDriver?.driverId,
        };

        const result = await createOrUpdateDriver(props.userId, dataUser);
        if (!result.error) {
          setCurrentDriver(null);
          setShowForm(false);
          setDisabledModify(false);
          fetchDrivers();
        } else {
          let message =
            'Hubo problemas en la solucitud de creación / actualización. Por favor reintente de nuevo.';
          message =
            result.error.code === '-1' ? 'Conductor ya existe.' : message;
          message =
            result.error.code === '-2'
              ? 'No hay referencias al usuario proveedor, reintente de nuevo.'
              : message;

          setShowAlert(true);
          setAlertMessage(message);
          setAlertIsError(true);
        }
      })();
    },
    [props, currentDriver, driverIsActive, fetchDrivers],
  );

  const renderForm = (): JSX.Element => {
    return (
      <>
        <form className={'form'} onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container className="settingsForm__row" spacing={2}>
            <Grid key="grid-btn-3" item>
              <Button
                key="btnHideForm"
                name="btnHideForm"
                variant="outlined"
                disableElevation
                onClick={() => {
                  setCurrentDriver(null);
                  setDisabledModify(false);
                  setShowForm(false);
                }}
                className={clsx('settingsForm__toggle-button-3')}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid key="grid-btn-4" item>
              <Button
                key="btnAddOrUpdateUser"
                name="btnAddOrUpdateUser"
                variant="outlined"
                disableElevation
                type="submit"
                className={clsx('settingsForm__toggle-button-2')}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
          <Grid container className="settingsForm__row" spacing={2}>
            <Grid key="grid-input-email" item>
              <TextField
                error={errors.driverCode !== undefined}
                required
                key="driverCode"
                className={clsx('settingsForm__input-large')}
                name="driverCode"
                label="User Inspector ID"
                defaultValue={currentDriver?.driverCode}
                variant="outlined"
                inputRef={register({
                  required: 'Código es requerido',
                })}
              />
              {errors.driverCode && (
                <ValidationLabel
                  message={errors.driverCode.message}
                  isError={true}
                />
              )}
              {showAlert && (
                <ValidationLabel
                  message={alertMessage}
                  isError={alertIsError}
                />
              )}
            </Grid>
            <Grid key="grid-input-check2" item>
              <FormControlLabel
                key="check-change-active"
                control={
                  // eslint-disable-next-line react/jsx-no-undef
                  <Checkbox
                    defaultChecked={
                      currentDriver?.driverId === undefined
                        ? true
                        : currentDriver?.isActive
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDriverIsActive(event.target.checked);
                    }}
                    color="primary"
                  />
                }
                label="Activo"
              />
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  useEffect(() => {
    if (usefetchData) {
      fetchDrivers();
      setUseFetchData(false);
    }
  }, [setUseFetchData, usefetchData, fetchDrivers]);

  return (
    <>
      <div ref={elementToScroll}></div>
      <Loader isActive={loading} />
      {showForm && renderForm()}
      {!showForm && (
        <Grid container className="settingsForm__row" spacing={2}>
          <Grid key="grid-input" item>
            <TextField
              key="searchCode"
              className={clsx('settingsForm__input')}
              name="searchCode"
              label="Filtrar Código"
              defaultValue={searchFilter}
              variant="outlined"
              onChange={onChangeSearchFilter}
            />
          </Grid>
          <Grid key="grid-btn-1" item>
            <Button
              key="btnSearch"
              name="btnSearch"
              variant="outlined"
              disableElevation
              onClick={fetchDrivers}
              className={clsx('settingsForm__toggle-button')}
            >
              Buscar
            </Button>
          </Grid>
          <Grid key="grid-btn-2" item>
            <Button
              key="btnActiveAddDriver"
              name="btnActiveAddDriver"
              variant="outlined"
              disableElevation
              onClick={() => {
                setShowForm(true);
                setDisabledModify(true);
                setCurrentDriver(null);
              }}
              className={clsx('settingsForm__toggle-button-2')}
            >
              Agregar Conductor
            </Button>
          </Grid>
        </Grid>
      )}
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={cantRegisters}
        rowsPerPage={10}
        page={currentPage - 1}
        onPageChange={onChangePage}
      />
      <TableContainer component={Paper} id="customTable">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {Object.values(GRID_USER_DRIVER_COLUMNS).map((column: Column) => (
                <TableCell key={column.name} className={classes.header}>
                  {column.header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listDrivers.length === 0 && (
              <TableRow key={'no-data'} className={classes.rowSelect}>
                <TableCell colSpan={3} align="center" className={classes.cell}>
                  No hay registros para mostrar.
                </TableCell>
              </TableRow>
            )}
            {listDrivers.map((user: IDriverModel) => (
              <TableRow key={user.driverId} className={classes.rowSelect}>
                <TableCell align="center" className={classes.cell}>
                  {user.driverCode}
                </TableCell>
                <TableCell
                  align="center"
                  className={clsx(classes.cell, classes.mediumCell)}
                >
                  {StatusChip(user.isActive)}
                </TableCell>
                <TableCell align="center" className={classes.cell}>
                  <Button
                    key="btnEdit"
                    name="btnEdit"
                    variant="outlined"
                    disabled={disabledModify}
                    disableElevation
                    onClick={() => {
                      setCurrentDriver(user);
                      setShowForm(true);
                      setDisabledModify(true);
                      executeScroll();
                    }}
                    className={clsx('settingsForm__toggle-button')}
                  >
                    Editar
                  </Button>
                  <Button
                    key="btnInactive"
                    name="btnInactive"
                    variant="outlined"
                    disabled={!user.isActive || disabledModify}
                    disableElevation
                    onClick={() => {
                      setCurrentDriver(user);
                      setShowModalConfirm(true);
                    }}
                    className={clsx('settingsForm__toggle-button-5')}
                  >
                    Inactivar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modals */}
      <Dialog
        open={showModalConfirm}
        onClose={() => setShowModalConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmación</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Desea inactivar este conductor?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setCurrentDriver(null);
              setShowModalConfirm(false);
            }}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() =>
              triggerInactiveDriver(
                currentDriver !== null && currentDriver.driverId
                  ? currentDriver.driverId
                  : 0,
              )
            }
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DriverList;
