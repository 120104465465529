import { ActionType, IAction } from 'types/action';
import { IPreInspectionState, preInspectDefault } from 'types/pre-inspection';

export const initialState: IPreInspectionState = {
  preInspections: [],
  currentPreInspection: preInspectDefault,
  statusesIndicators: [],
  formPreInspection: [],
  currentMediaFiles: [],
  rotateImages: [],
  actionMessage: '',
  loading: true,
  plateFilter: '',
  recordFilter: '',
  requestParams: {
    countryCode: localStorage.getItem('countryCode') || 'PRI',
    page: 1,
  },
  showAlert: false,
  showStepTrack: false,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const preInspectionReducer = (state: IPreInspectionState, action: IAction) => {
  switch (action.type) {
    case ActionType.GET_EVENTS_FAIL:
      return {
        ...state,
        actionMessage: action.payload,
        loading: false,
      };
    case ActionType.GET_PREINSPECTS_SUCCESS:
      return {
        ...state,
        preInspections: action.payload.preInspections,
        statusesIndicators: action.payload.indicators,
        loading: false,
      };
    case ActionType.SET_PREINSPECTS_MODEL:
      return {
        ...state,
        currentPreInspection: action.payload,
      };
    case ActionType.SET_PREINSPECTS_FORM:
      return {
        ...state,
        formPreInspection: action.payload,
      };
    case ActionType.SET_PREINSPECTS_MEDIAFILES:
      return {
        ...state,
        currentMediaFiles: action.payload,
      };
    case ActionType.SET_PREINSPECTS_UNIQUEID:
      return {
        ...state,
        currentPreInspection: {
          ...state.currentPreInspection,
          preUniqueId: action.payload.uniqueId,
          preInspectionId: action.payload.preInspectionId,
          preCountryCode: action.payload.countryCode,
          preStatusId: action.payload.statusId,
        },
      };
    case ActionType.SET_GRID_REQUEST_PARAMS:
      return {
        ...state,
        requestParams: action.payload,
      };
    case ActionType.SET_PLATE_FILTER:
      return {
        ...state,
        plateFilter: action.payload,
      };
    case ActionType.CLEAR_PLATE_FILTER:
      return {
        ...state,
        plateFilter: '',
      };
    case ActionType.SET_RECORD_FILTER:
      return {
        ...state,
        recordFilter: action.payload,
      };
    case ActionType.CLEAR_RECORD_FILTER:
      return {
        ...state,
        recordFilter: '',
      };
    case ActionType.SET_EVENT_STATUS:
      return {
        ...state,
        currentPreInspection: {
          ...state.currentPreInspection,
          preStatusId: action.payload,
        },
      };
    case ActionType.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionType.SET_PREINSPECTS_STEPTRACK:
      return {
        ...state,
        currentPreInspectTrack: action.payload,
        showStepTrack: true,
      };
    case ActionType.SET_PREINSPECTS_STEPTRACK_CLOSE:
      return {
        ...state,
        currentPreInspectTrack: null,
        showStepTrack: false,
      };
    default:
      return state;
  }
};

export default preInspectionReducer;
