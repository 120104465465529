import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { API_URL, API_KEY } from 'shared/api/config';
import * as endpoints from 'shared/api/endpoints';
import { sicSaveQueue } from 'shared/utils/requestQueue';

const APIClient = axios.create({
  baseURL: `${API_URL}/api`,
  headers: {
    Accept: 'application/json',
    Authorization: API_KEY,
  },
  timeout: 360000,
});

APIClient.interceptors.request.use((config) => {
  return config;
});

APIClient.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error, (scope) => {
      scope.clear();
      scope.setTag('sic-uri', error?.request?.responseURL ?? 'Uri not found');
      scope.setExtra('error-data', JSON.stringify(error?.response?.data));
      localStorage.setItem('error-data', JSON.stringify(error?.response?.data));

      return scope;
    });

    if (
      ['/v1/events/save'].includes(error.config.url) &&
      (error.message === 'Network Error' ||
        (error.response && error.response.status >= 400))
    ) {
      return new Promise((resolve, reject) => {
        if (error.message === 'Network Error') {
          localStorage.setItem('networkFlag', '1');
        }
        sicSaveQueue.enqueue({
          config: error.config,
          resolve,
          reject,
        });
      });
    }

    return Promise.reject(error);
  },
);

export { endpoints };
export default APIClient;
