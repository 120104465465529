import { Damages } from '@connect-technology/connect-react-components-lib';
import { CountryCode, Rol } from 'types/common';
import { IInspection } from 'types/inspection';
import { Dependency, FieldSection } from 'types/section-fields';

export const defaultSignImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAnsAAADICAIAAAAfl7AyAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAM5SURBVHhe7dUxEQQhAARB+Aj/MlFAdp9ggYm6k61VMHPvPQCAx353AYCXFBcACooLAAXFBYCC4gJAQXEBoKC4AFBQXAAoKC4AFBQXAAqKCwAFxQWAguICQEFxAaCguABQUFwAKCguABQUFwAKigsABcUFgILiAkBBcQGgoLgAUFBcACgoLgAUFBcACooLAAXFBYCC4gJAQXEBoKC4AFBQXAAoKC4AFBQXAAqKCwAFxQWAguICQEFxAaCguABQUFwAKCguABQUFwAKigsABcUFgILiAkBBcQGgoLgAUFBcACgoLgAUFBcACooLAAXFBYCC4gJAQXEBoKC4AFBQXAAoKC4AFBQXAAqKCwAFxQWAguICMOac55x7eENxARjf96217uENxQWAguICQEFxAaCguABQUFwAKCguABQUFwAKigsABcUFgILiAkBBcQGgoLgAUFBcACgoLgAUFBcACooLAAXFBYCC4gJAQXEBoKC4AFBQXAAoKC4AFBQXAAqKCwAFxQWAguICQEFxAaCguABQUFwAKCguABQUFwAKigsABcUFgILiAkBBcQGgoLgAUFBcACgoLgAUFBcACooLAAXFBYCC4gJAQXEBoKC4AFBQXAAoKC4AFBQXAAqKCwAFxQWAguICQEFxAaCguABQUFwAKCguABQUFwAKigsABcUFgILiAkBBcQGgoLgAUFBcACgoLgAUFBcACooLAAXFBYCC4gJAQXEBoKC4AFBQXAAoKC4AFBQXAAqKCwAFxQWAguICQEFxAaCguABQUFwAKCguABQUFwAKigsABcUFgILiAkBBcQGgoLgAUFBcACgoLgAUFBcACooLAAXFBYCC4gJAQXEBoKC4AFBQXAAoKC4AFBQXAAqKCwAFxQWAguICQEFxAaCguABQUFwAKCguABQUFwAKigsABcUFgILiAkBBcQGgoLgAUFBcACgoLgAUFBcACooLAAXFBYCC4gJAQXEBoKC4AFBQXAAoKC4AFBQXAAqKCwAFxQWAguICQEFxAaCguABQUFwAKCguABQUFwAKigsABcUFgILiAkBBcQGgoLgAUFBcACgoLgAUFBcACooLAAXFBYD3xvgDGt0KTqN85xIAAAAASUVORK5CYII=';

export enum DamageAreas {
  FRONT_END = 'Parte Frontal', // area 1, 2, 14, 15
  RIGHT_SIDE = 'Lateral Derecho', // area 3, 4, 5, 6
  REAR_END = 'Parte Trasera', // area 7, 8, 9, 19
  LEFT_SIDE = 'Lateral Izquierdo', // area 10, 11, 12, 13
  WINDSHIELD = 'Vidrio Frontal', // area 16
  TOP = 'Techo', // area 17
  REAR_WINDOW = 'Vidrio Trasero', // area 18
}

export const filterSectionsByDependencies = (
  s: FieldSection,
  sections: FieldSection[],
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  entity?: any,
): boolean => {
  if (s.visible !== true) {
    return false;
  }

  if (s.dependencyJson) {
    s.dependency = JSON.parse(s.dependencyJson);
  }

  if (
    s.formSectionId === 133 && // AAP Options
    Number(localStorage.getItem('rolId')) === Rol.CUSTOMER
  ) {
    return false;
  }

  if (!s.dependency) {
    return true;
  }

  const resultDependencies: boolean[] = [];
  s.dependency.forEach((fe: Dependency) => {
    const dFieldName = fe.Name;
    const dSectionId = fe.SectionId;
    const dExpectedValue = fe?.ExpectedValue;

    switch (fe.Name) {
      case 'thirdType': {
        if (
          entity !== undefined &&
          entity?.thirds !== undefined &&
          entity.thirds.length > 0
        ) {
          resultDependencies.push(
            entity.thirds[0][fe.Name] === fe.ExpectedValue,
          );
        }
        break;
      }
      default: {
        const fieldValue = sections
          .find((s) => s.formSectionId === dSectionId)
          ?.fields.find((f) => f.name === dFieldName)?.value;
        resultDependencies.push(fieldValue === dExpectedValue);
        break;
      }
    }
  });

  return (
    s.dependency.length === resultDependencies.filter((f) => f === true).length
  );
};

export const customFieldDependency = (fieldName: string): any => {
  const customValidations = [
    {
      fieldName: 'InSchedule',
      validations: [
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'policivoticket',
          visible: false,
        },
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'none',
          visible: false,
        },
      ],
    },
    {
      fieldName: 'IndMRSAccompaniment',
      validations: [
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'policivoticket',
          visible: false,
        },
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'none',
          visible: false,
        },
      ],
    },
    {
      fieldName: 'MRSAValueNumber',
      validations: [
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'policivoticket',
          visible: false,
        },
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'none',
          visible: false,
        },
      ],
    },
    {
      fieldName: 'IndFalconHeavy',
      validations: [
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'policivoticket',
          visible: false,
        },
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'none',
          visible: true,
        },
      ],
    },
    {
      fieldName: 'FalconHeavyAmount',
      validations: [
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'policivoticket',
          visible: false,
        },
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'none',
          visible: false,
        },
      ],
    },
    {
      fieldName: 'IndFalconHeavyAffected',
      validations: [
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'policivoticket',
          visible: false,
        },
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'none',
          visible: true,
        },
      ],
    },
    {
      fieldName: 'AffectedAccompanimentId',
      validations: [
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'none',
          visible: false,
        },
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'policivoticket',
          visible: false,
        },
      ],
    },
    {
      fieldName: 'orbikaCode',
      validations: [
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'policivoticket',
          visible: false,
        },
      ],
    },
    {
      fieldName: 'orbikaCodeAffected',
      validations: [
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'counterpart',
          visible: false,
        },
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'policivoticket',
          visible: false,
        },
        {
          nameRef: 'ResponsableOption',
          expectedValue: 'none',
          visible: false,
        },
      ],
    },
  ];

  return customValidations.filter((f: any) => f.fieldName === fieldName)[0];
};

export const getDamages = (
  damagesStr: string,
): {
  forDiagram: Damages;
  list: { key: number; title: string; description: string }[];
} => {
  if (!damagesStr) {
    return { forDiagram: {}, list: [] };
  }

  let damagedSection: string[] = [];

  if (damagesStr.includes('//')) {
    damagedSection = damagesStr.split('//')[1].split('|');
  } else {
    damagedSection = damagesStr.split('|');
  }

  const forDiagram: Damages = {};
  const forList: { key: number; title: string; description: string }[] = [];

  if (damagedSection.length === 1 && damagedSection[0].trim() === '') {
    return { forDiagram: forDiagram, list: forList };
  }
  for (const section of damagedSection) {
    if (!section.trim()) {
      continue;
    }
    if (section.indexOf(':') > -1) {
      const [title, areaAndDescription] = section.split(':');
      if (areaAndDescription.indexOf('-') > -1) {
        const [area, description] = areaAndDescription.split('-');
        const key = Number(area.trim().split('#')[1]);
        forDiagram[key] = [...description.split(',').map((d) => d.trim())];

        forList.push({
          key,
          title: title
            .replace('Daños en la', '')
            .replace('con el detalle', '')
            .trim(),
          description: description.trim(),
        });
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(
        'Alert:: No se puede separar el texto de los daños para renderizar.',
      );
    }
  }

  return { forDiagram: forDiagram, list: forList };
};

export const getDamagesString = (
  damages: Damages,
  originalDamagesString = '',
): string => {
  const damagesDescriptions: string[] = [];

  const hasDescription =
    originalDamagesString && originalDamagesString.includes('//');

  const accidentDescription = hasDescription
    ? originalDamagesString.split('//')?.[0]
    : '';

  Object.keys(damages).forEach((key) => {
    damagesDescriptions.push(
      buildDamageString(Number(key), damages[Number(key)].join(', ')),
    );
  });

  if (hasDescription) {
    return `${accidentDescription.trim()} // ${damagesDescriptions.join(
      ' | ',
    )}`;
  }

  return damagesDescriptions.join(' | ');
};

export const buildDamageString = (areaKey: number, damages: string): string => {
  const area = getDamageArea(areaKey);

  return `Daños en la ${area} con el detalle: #${+areaKey} - ${damages}`;
};

export const getDamageArea = (index: number): DamageAreas => {
  switch (index) {
    case 1:
    case 2:
    case 14:
    case 15:
      return DamageAreas.FRONT_END;
    case 3:
    case 4:
    case 5:
    case 6:
      return DamageAreas.RIGHT_SIDE;
    case 7:
    case 8:
    case 9:
    case 19:
      return DamageAreas.REAR_END;
    case 10:
    case 11:
    case 12:
    case 13:
      return DamageAreas.LEFT_SIDE;
    case 16:
      return DamageAreas.WINDSHIELD;
    case 17:
      return DamageAreas.TOP;
    case 18:
      return DamageAreas.REAR_WINDOW;
    default:
      return '' as DamageAreas;
  }
};

export const patternEmail = (): RegExp => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
};

export const isValidEmail = (email: string): boolean => {
  return patternEmail().test(email);
};

export function isValidPhoneNumber(
  phoneNumber: string | undefined | null,
  countryCode: string | undefined | null,
): boolean {
  if (!phoneNumber) {
    return false;
  }

  let startNumber: string[];

  switch (countryCode) {
    case CountryCode.PRI:
      startNumber = ['787', '939'];

      return Boolean(
        phoneNumber.length === 10 &&
          startNumber.includes(phoneNumber.substring(0, 3)),
      );
    case CountryCode.PAN:
      startNumber = ['6'];

      return Boolean(
        phoneNumber.length === 8 && startNumber.includes(phoneNumber.charAt(0)),
      );
    case CountryCode.CRC:
      startNumber = ['6', '7', '8'];

      return Boolean(
        phoneNumber.length === 8 && startNumber.includes(phoneNumber.charAt(0)),
      );
    case CountryCode.COL:
      startNumber = ['3'];

      return Boolean(
        phoneNumber.length === 10 &&
          startNumber.includes(phoneNumber.charAt(0)),
      );
    default:
      return false;
  }
}

export const isInjuredOrInsured = (
  inspection: IInspection,
): {
  isInjured: boolean;
  isInsured: boolean;
} => {
  const resultIsInsured =
    inspection.InsuredVehicle &&
    inspection.InsuredVehicle?.Reclamacion?.length > 0 &&
    inspection.InsuredVehicle?.VehicleLisencePlate?.length > 0 &&
    inspection.InsuredVehicle?.Ocurrido?.length > 0;
  const resultIsInjured =
    inspection.InjuredVehicle &&
    inspection.InjuredVehicle?.Reclamacion?.length > 0 &&
    inspection.InjuredVehicle?.VehicleLicensePlate2?.length > 0 &&
    inspection.InjuredVehicle?.VehicleDescription2?.length > 0;

  return {
    isInjured: resultIsInjured ?? false,
    isInsured: resultIsInsured ?? false,
  };
};
