// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getDataInspectors = () => {
  const dataInspectorsStr = localStorage.getItem('inspectors');
  let data: any[] = [];

  if (dataInspectorsStr) {
    const localData: any = Array.from(JSON.parse(dataInspectorsStr));
    data = localData
      .map(
        (inspector: {
          name: string;
          unitCodeInspector: string;
          userInspectorId: number;
        }) => ({
          ...inspector,
          id: inspector.userInspectorId,
          name: `${inspector.name} - ${inspector.unitCodeInspector}`,
        }),
      )
      .sort((a: any, b: any) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      );
  }

  return data;
};

const getDataToField = (): any => {
  let toField = '';
  getDataInspectors().forEach(function (inspector: any) {
    const newData = inspector.unitCodeInspector + '|' + inspector.name;
    toField += toField.length > 0 ? '¬' + newData : newData;
  });

  return toField;
};

export default {
  getDataInspectors,
  getDataToField,
};
