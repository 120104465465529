import { Grid } from '@mui/material';
import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Field } from 'types/form-field';
import { FieldSection } from 'types/section-fields';
import CATALOGS from 'static/constants/catalogs';
import InputField, { FieldTypes } from './InputField';
import {
  CheckBoxTypes,
  CountryCode,
  DropDownTypes,
  ICheckListModel,
  ResourceAction,
  Rol,
} from 'types/common';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { ActionType } from 'types/action';
import { ICoverage } from 'types/inspection';
import { userActionsPermissions } from 'shared/utils';
import { useFormikContext } from 'formik';

interface SectionFieldsProps {
  fields: Field[];
  sections: FieldSection[];
  save: (field?: Field) => void;
  isVirtualInspector: boolean;
  isInspectorForm: boolean;
  classes: any;
  options?: any;
  setSections: (value: FieldSection[]) => void;
  formValidation: (field?: Field, isFinish?: boolean) => boolean;
  handleChangeDiagram?: any;
  claimInfo?: any;
  isCMT?: boolean;
}

export const SectionFields: FunctionComponent<SectionFieldsProps> = ({
  fields,
  sections,
  setSections,
  save,
  isVirtualInspector,
  isInspectorForm,
  classes,
  options,
  formValidation,
  handleChangeDiagram,
  claimInfo,
  isCMT,
}): JSX.Element => {
  const { dispatch, state: inspectionState } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const formik = useFormikContext<any>();
  const isAuditProcess = [Rol.AUDIT].includes(
    Number(localStorage.getItem('rolId')),
  );
  const isAdminProcess = [Rol.ADMIN].includes(
    Number(localStorage.getItem('rolId')),
  );
  const isInspectorProcess = [Rol.INSPECTOR].includes(
    Number(localStorage.getItem('rolId')),
  );
  const readOnlyPermission = Boolean(
    userActionsPermissions(ResourceAction.VIEW_EVENT_READ_ONLY),
  );
  const [defaultEmail, setDefaultEmail] = useState('');
  const countryCode = localStorage.getItem('countryCode')?.toUpperCase();
  const isCRCorPAN =
    countryCode === CountryCode.CRC || countryCode === CountryCode.PAN;
  const isPAN = countryCode === CountryCode.PAN;
  const isPRI = countryCode === CountryCode.PRI;
  const eventProvince: any = localStorage.getItem('eventProvince');
  const eventCanton: any = localStorage.getItem('eventCanton');
  const eventDistrict: any = localStorage.getItem('eventDistrict');
  const eventAddress: any = localStorage.getItem('placeDirectionInspect');

  useEffect(() => {
    if (defaultEmail) {
      save();
    }
    // eslint-disable-next-line
  }, [defaultEmail]);

  const checkFieldDependency = (field: Field) => {
    if (!field.dependency) {
      return true;
    }

    const {
      name: dFieldName,
      sectionId: dSectionId,
      expectedValue: dExpectedValue,
    } = field.dependency;
    const fieldDependency = sections
      .find((s) => s.formSectionId === dSectionId)
      ?.fields.find((f) => f.name === dFieldName);

    if (fieldDependency) {
      const isDependencyMet =
        String(fieldDependency.value ?? fieldDependency.defaultValue) ===
        dExpectedValue;

      return isDependencyMet ? checkFieldDependency(fieldDependency) : false;
    }
  };

  const getFields = (fields: Field[]) => {
    return fields
      .filter((s) => {
        //Validate audit process
        if (isAuditProcess && s.isForAudit === true && !isInspectorForm) {
          s.isDisabled = !inspectionState.auditEvent.auditIsActive;
        }

        // Validate audit process for Claims
        s.renderType = undefined;
        if (claimInfo && String(claimInfo?.case).length > 0) {
          s.renderType = 'useClaims';
          if (isAuditProcess && !isInspectorForm && s.isForAudit === true) {
            s.isDisabled = false;
          } else if (
            (isAuditProcess || isAdminProcess || isInspectorProcess) &&
            !isInspectorForm
          ) {
            s.isDisabled = false;
          } else {
            s.isDisabled = true;
          }

          if (s.type === 'DAMAGESDIAGRAM') {
            s.isDisabled = true;
          }
        }

        // Apply read only permission
        if (readOnlyPermission) {
          s.isDisabled = readOnlyPermission;
        }

        return checkFieldDependency(s);
      })
      .sort((a: any, b: any) => (a.idOrder > b.idOrder ? 1 : -1));
  };

  function handleCoverageChange(elements, dispatchActions) {
    let coverages = elements;

    if (
      !Array.isArray(elements) &&
      typeof elements === 'object' &&
      elements !== null &&
      (elements['value'] !== undefined ||
        (elements['target'] !== undefined &&
          elements['target'].value !== undefined))
    ) {
      coverages =
        elements['target'] !== undefined
          ? JSON.parse(elements['target'].value)
          : JSON.parse(elements['value']);
    }

    const coverageUpdate = coverages.map((elem) => ({
      coverageId: elem.id,
      coverageName: elem.name,
    }));

    dispatchActions.push({
      type: ActionType.SET_INSPECTION_COVERAGES,
      payload: [...coverageUpdate],
    });
  }

  function handleFieldChange(
    fieldName,
    fieldValue,
    dispatchActions,
    _sections,
  ) {
    let mailTo = '';
    const allFields = _sections.map((s) => s.fields).flat();

    switch (fieldName) {
      case 'RequireAssistance':
        dispatchActions.push({
          type: ActionType.SET_ASSISTANCE_REQUIRED,
          payload: fieldValue,
        });
        break;
      case 'sinisterType':
        if (Number(fieldValue) > 0) {
          dispatchActions.push({
            type: ActionType.SET_REQUIRED_SINISTERTYPE,
            payload: fieldValue,
          });
        }

        break;
      case 'sinisterCause':
        if (Number(fieldValue) > 0) {
          dispatchActions.push({
            type: ActionType.SET_REQUIRED_SINISTERCAUSE,
            payload: fieldValue,
          });
        }
        break;
      case 'AcceptTermsSignature':
        if (fieldValue?.length > 0) {
          dispatchActions.push({
            type: ActionType.SET_REQUIRED_ACCEPT_SIGNATURE,
            payload: fieldValue,
          });
        }
        break;
      case 'IndNoEmail':
        if (fieldValue === '2') {
          mailTo =
            inspectionState.currentInspection?.configdata === undefined
              ? ''
              : inspectionState.currentInspection?.configdata[0]
                  .NotificationEmail;
        }

        for (const f of allFields) {
          if (f.name === 'AcceptTermsEmail') {
            dispatchActions.push({
              type: ActionType.SET_BILLRIGHTS_MAIL,
              payload: mailTo,
            });
            setDefaultEmail(mailTo);
          }
        }
        break;
      case 'isAccidentPlace':
        switch (fieldValue) {
          case '1':
            dispatchActions.push({
              type: ActionType.SET_SHOW_DIAGRAM,
              payload: true,
            });
            break;
          default:
            dispatchActions.push({
              type: ActionType.SET_SHOW_DIAGRAM,
              payload: false,
            });
            break;
        }
        break;
      case 'storyDetail':
        dispatchActions.push({
          type: ActionType.SET_AUDIO_TRANSCRIPT,
          payload: fieldValue,
        });
        break;
      case 'AcceptTermsEmail':
        dispatchActions.push({
          type: ActionType.SET_BILLRIGHTS_MAIL,
          payload: fieldValue,
        });
        break;
      case 'Ocurrido':
        dispatchActions.push({
          type: ActionType.SET_AUDIO_TRANSCRIPT_INSURED,
          payload: fieldValue,
        });
        break;
      case 'VehicleDescription2':
        dispatchActions.push({
          type: ActionType.SET_AUDIO_TRANSCRIPT_INJURED,
          payload: fieldValue,
        });
        break;
      case 'driverName':
        dispatchActions.push({
          type: ActionType.SET_DRIVER_NAME,
          payload: fieldValue,
        });
        break;
      case 'driverLastName':
        dispatchActions.push({
          type: ActionType.SET_DRIVER_LAST_NAME,
          payload: fieldValue,
        });
        break;
      case 'driverId':
        dispatchActions.push({
          type: ActionType.SET_DRIVER_ID,
          payload: fieldValue,
        });
        break;
      case 'driverNumLicense':
        dispatchActions.push({
          type: ActionType.SET_DRIVER_LICENSE_NUMBER,
          payload: fieldValue,
        });
        break;
      case 'driverExpireLicenseDate':
        dispatchActions.push({
          type: ActionType.SET_DRIVER_LICENSE_EXPIRATION,
          payload: fieldValue,
        });
        break;
      case 'driverBirthDate':
        dispatchActions.push({
          type: ActionType.SET_DRIVER_BIRTH_DATE,
          payload: fieldValue,
        });
        break;
      case 'driverLicenseType':
        dispatchActions.push({
          type: ActionType.SET_DRIVER_LICENSE_TYPE,
          payload: fieldValue,
        });
        break;
      case 'plateMetallic':
        dispatchActions.push({
          type: ActionType.SET_PLATE_CIRCUNSTANCES_INSURED,
          payload: fieldValue,
        });
        break;
      default:
        break;
      // code block
    }
  }

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let refreshPage = false;
    const dispatchActions: Array<{ type: ActionType; payload: any }> = [];

    if (
      event.target.name === CheckBoxTypes.COVERAGES.toString() ||
      event.target.name === CheckBoxTypes.COVERAGES_QUALITAS.toString()
    ) {
      handleCoverageChange(event, dispatchActions);
    } else {
      const [fieldName, formSection] = String(event.target.name ?? '').split(
        '|',
      );
      const fieldValue = event.target.value;
      const _sections = [...sections];
      let enableSwitchAddress = false;
      refreshPage = fieldName === 'usePactoAmistoso';

      const section = _sections.find(
        (s) => s.formSectionId === Number(formSection),
      );
      const field = section?.fields.find((f) => f.name === fieldName);

      if (field) {
        if (options?.fromThirds && formik) {
          if (fieldName === 'thirdType') {
            formik.setFieldValue(fieldName, fieldValue);
          }
        }

        field.value = fieldValue;
        setSections([..._sections]);
        handleFieldChange(fieldName, fieldValue, dispatchActions, _sections);
        const allFields = _sections.map((s) => s.fields).flat();
        for (const f of allFields) {
          if (
            f.dependency?.sectionId === +formSection &&
            f.dependency.name === fieldName &&
            f.dependency.expectedValue !== fieldValue
          ) {
            f.value = undefined;
          }

          if (f.formSectionId === +formSection) {
            // Special Data Types like DropdDowns or CheckBox
            switch (field?.dataType) {
              case DropDownTypes.PROVINCES:
                if (f.dataType === DropDownTypes.CANTONS) {
                  f.options = CATALOGS.getCantons('', +field.value)?.split('¬');
                }
                if (field?.name === 'provinceDirectionInspect') {
                  localStorage.setItem('eventProvince', field?.value);
                }
                break;
              case DropDownTypes.CANTONS:
                if (f.dataType === DropDownTypes.DISTRICTS) {
                  f.options = CATALOGS.getDistricts('', +field.value)?.split(
                    '¬',
                  );
                }
                if (field?.name === 'cantonDirectionInspect') {
                  localStorage.setItem('eventCanton', field?.value);
                }
                break;
              case DropDownTypes.DISTRICTS:
                if (field?.name === 'districtDirectionInspect') {
                  localStorage.setItem(
                    'eventDistrict',
                    String(field?.value).length > 0
                      ? field?.value
                      : field?.originalValue,
                  );
                }
                break;
              default:
                if (field?.name === 'placeDirectionInspect') {
                  localStorage.setItem('placeDirectionInspect', field?.value);
                }
                break;
            }

            // Common Types for FieldTypes
            if (field?.name === f.name) {
              switch (field?.type?.toLocaleLowerCase()) {
                case FieldTypes.STYLIZED_RADIOBUTTONS:
                  f.originalValue = 'change'; // Force to the control to send the selected value
                  break;
                default:
                  break;
              }
            }
          }

          if (!enableSwitchAddress && isCRCorPAN) {
            enableSwitchAddress =
              f?.name === 'isAccidentPlace' && f.value === '1';
          }

          //For Audit
          if (isAuditProcess && !isInspectorForm) {
            f.isModifyForAudit =
              f.isModifyForAudit || f.value !== f.originalValue;
          }
        }

        // LOCATION DATA FOR SWITCH
        enableSwitchAddress =
          field?.name === 'isAccidentPlace' && field.value === '1';

        // Update fields for accident location
        const updateLocations = (
          eventProvince: string,
          eventCanton: string,
          eventDistrict: string,
          eventAddress: string,
        ) => {
          const eventProvinceIns =
            inspectionState.currentInspection?.provinceDirectionInspect;
          const eventCantonIns =
            inspectionState.currentInspection?.cantonDirectionInspect;
          const eventDistrictIns =
            inspectionState.currentInspection?.districtDirectionInspect;
          const eventAddressIns =
            inspectionState.currentInspection?.placeDirectionInspect;

          if (eventProvince === undefined || eventProvince === null) {
            eventProvince =
              eventProvinceIns === undefined ? '' : eventProvinceIns;
          }
          if (eventCanton === undefined || eventCanton === null) {
            eventCanton = eventCantonIns === undefined ? '' : eventCantonIns;
          }
          if (eventDistrict === undefined || eventDistrict === null) {
            eventDistrict =
              eventDistrictIns === undefined ? '' : eventDistrictIns;
          }
          if (eventAddress === undefined || eventAddress === null) {
            eventAddress = eventAddressIns === undefined ? '' : eventAddressIns;
          }

          for (const f of allFields) {
            switch (f?.dataType) {
              case DropDownTypes.PROVINCES:
                if (f.name === 'provinceDirectionSinister') {
                  f.value = eventProvince?.toString();
                }
                break;
              case DropDownTypes.CANTONS:
                if (f.name === 'cantonDirectionSinister') {
                  f.value = eventCanton?.toString();
                  f.options = CATALOGS.getCantons('', +eventProvince)?.split(
                    '¬',
                  );
                }
                break;
              case DropDownTypes.DISTRICTS:
                if (f.name === 'districtDirectionSinister') {
                  f.value = eventDistrict?.toString();
                  f.options = CATALOGS.getDistricts('', +eventCanton)?.split(
                    '¬',
                  );
                }
                break;
              default:
                if (
                  f.name === 'placeDirectionSinister' &&
                  (!isCRCorPAN || !enableSwitchAddress)
                ) {
                  f.value = eventAddress?.toString();
                  f.hasError = f.required && String(f.value).length < 1;
                }
            }

            //For Audit
            if (isAuditProcess && !isInspectorForm) {
              f.isModifyForAudit =
                f.isModifyForAudit || f.value !== f.originalValue;
            }
          }
        };

        // Validate only in CRC
        if (isCRCorPAN) {
          if (enableSwitchAddress) {
            updateLocations(
              eventProvince,
              eventCanton,
              eventDistrict,
              eventAddress,
            );
          } else if (fieldName === 'isAccidentPlace') {
            updateLocations('', '', '', '');
          }

          if (refreshPage) {
            dispatchActions.push({
              type: ActionType.SET_AAP_FLAG,
              payload: fieldValue,
            });
          } else if (fieldName === 'vehicleDefinition') {
            dispatchActions.push({
              type: ActionType.SET_VEHICLE_DEFINITION,
              payload: fieldValue,
            });
          } else if (fieldName === 'NoCoverageApplied') {
            dispatchActions.push({
              type: ActionType.SET_NOT_COVERAGE,
              payload: fieldValue,
            });
          } else if (fieldName === 'ReasonId') {
            dispatchActions.push({
              type: ActionType.SET_REASON_ID,
              payload: fieldValue,
            });
          } else if (fieldName === 'hasTrafficTicket') {
            dispatchActions.push({
              type: ActionType.SET_IND_PART_ACC,
              payload: fieldValue,
            });
          }
        }

        // Validate only in PRI
        if (isPRI) {
          // Injured Data Options
          if (fieldName === 'indAirbagsActive') {
            dispatchActions.push({
              type: ActionType.SET_IND_AIRBAG,
              payload: fieldValue,
            });
          } else if (fieldName === 'indSeatBeltStagnant') {
            dispatchActions.push({
              type: ActionType.SET_IND_SEATBELT,
              payload: fieldValue,
            });
          } else if (fieldName === 'indFrontAxleDamage') {
            dispatchActions.push({
              type: ActionType.SET_IND_FRONTAXLE,
              payload: fieldValue,
            });
          } else if (fieldName === 'indRearAxleDamage') {
            dispatchActions.push({
              type: ActionType.SET_IND_REARAXLE,
              payload: fieldValue,
            });
          } else if (fieldName === 'indVehicleLooseParts') {
            dispatchActions.push({
              type: ActionType.SET_IND_LOOSEPARTS,
              payload: fieldValue,
            });
          } else if (fieldName === 'indCustomerOpenTrunk') {
            dispatchActions.push({
              type: ActionType.SET_IND_OPENTRUNK,
              payload: fieldValue,
            });
          } else if (fieldName === 'sinisterTypePR') {
            dispatchActions.push({
              type: ActionType.SET_IND_SINIESTER_TYPE,
              payload: fieldValue,
            });

            if (fieldValue !== '1') {
              dispatchActions.push({
                type: ActionType.SET_IND_AIRBAG,
                payload: null,
              });

              dispatchActions.push({
                type: ActionType.SET_IND_SEATBELT,
                payload: null,
              });

              dispatchActions.push({
                type: ActionType.SET_IND_FRONTAXLE,
                payload: null,
              });

              dispatchActions.push({
                type: ActionType.SET_IND_REARAXLE,
                payload: null,
              });
            }
          }

          // Insured Data Options
          if (fieldName === 'indAirbagsActiveInsured') {
            dispatchActions.push({
              type: ActionType.SET_IND_AIRBAG_INSURED,
              payload: fieldValue,
            });
          } else if (fieldName === 'indSeatBeltStagnantInsured') {
            dispatchActions.push({
              type: ActionType.SET_IND_SEATBELT_INSURED,
              payload: fieldValue,
            });
          } else if (fieldName === 'indFrontAxleDamageInsured') {
            dispatchActions.push({
              type: ActionType.SET_IND_FRONTAXLE_INSURED,
              payload: fieldValue,
            });
          } else if (fieldName === 'indRearAxleDamageInsured') {
            dispatchActions.push({
              type: ActionType.SET_IND_REARAXLE_INSURED,
              payload: fieldValue,
            });
          } else if (fieldName === 'indVehicleLoosePartsInsured') {
            dispatchActions.push({
              type: ActionType.SET_IND_LOOSEPARTS_INSURED,
              payload: fieldValue,
            });
          } else if (fieldName === 'indCustomerOpenTrunkInsured') {
            dispatchActions.push({
              type: ActionType.SET_IND_OPENTRUNK_INSURED,
              payload: fieldValue,
            });
          } else if (fieldName === 'sinisterTypeInsuredPR') {
            dispatchActions.push({
              type: ActionType.SET_IND_SINIESTER_TYPE_INSURED,
              payload: fieldValue,
            });

            if (fieldValue !== '1') {
              dispatchActions.push({
                type: ActionType.SET_IND_AIRBAG_INSURED,
                payload: null,
              });

              dispatchActions.push({
                type: ActionType.SET_IND_SEATBELT_INSURED,
                payload: null,
              });

              dispatchActions.push({
                type: ActionType.SET_IND_FRONTAXLE_INSURED,
                payload: null,
              });

              dispatchActions.push({
                type: ActionType.SET_IND_REARAXLE_INSURED,
                payload: null,
              });
            }
          }
        }
        if (field && isAuditProcess && !isInspectorForm) {
          field.isModifyForAudit =
            field.isModifyForAudit || field.value !== field.originalValue;
        }
      }
    }
    // Llamadas a dispatch agrupadas al final
    dispatchActions.forEach((action) => {
      dispatch(action);
    });
  };

  //handleInputChange Original
  const handleInputChangeRespaldo = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let refreshPage = false;
    switch (event.target.name) {
      case CheckBoxTypes.COVERAGES.toString(): {
        const elements: ICheckListModel[] = Array.from(
          JSON.parse(event.target.value),
        );
        const coverageUpdate: ICoverage[] = elements.map(
          (elem: ICheckListModel) => {
            return {
              coverageId: elem.id,
              coverageName: elem.name,
            };
          },
        );

        dispatch({
          type: ActionType.SET_INSPECTION_COVERAGES,
          payload: [...coverageUpdate],
        });

        break;
      }
      case CheckBoxTypes.COVERAGES_QUALITAS.toString(): {
        const elements: ICheckListModel[] = Array.from(
          JSON.parse(event.target.value),
        );
        const coverageUpdate: ICoverage[] = elements.map(
          (elem: ICheckListModel) => {
            return {
              coverageId: elem.id,
              coverageName: elem.name,
            };
          },
        );

        dispatch({
          type: ActionType.SET_INSPECTION_COVERAGES,
          payload: [...coverageUpdate],
        });

        break;
      }
      default: {
        const [fieldName, formSection] = String(event.target.name ?? '').split(
          '|',
        );
        const fieldValue = event.target.value;
        const _sections = [...sections];
        let enableSwitchAddress = false;
        refreshPage = fieldName === 'usePactoAmistoso';

        const section = _sections.find(
          (s) => s.formSectionId === Number(formSection),
        );
        const field = section?.fields.find((f) => f.name === fieldName);
        if (field) {
          field.value = fieldValue;
        }
        if (fieldName === 'RequireAssistance') {
          dispatch({
            type: ActionType.SET_ASSISTANCE_REQUIRED,
            payload: fieldValue,
          });
        }
        if (fieldName === 'sinisterType' && Number(fieldValue) > 0) {
          dispatch({
            type: ActionType.SET_REQUIRED_SINISTERTYPE,
            payload: fieldValue,
          });
        }
        if (fieldName === 'sinisterCause' && Number(fieldValue) > 0) {
          dispatch({
            type: ActionType.SET_REQUIRED_SINISTERCAUSE,
            payload: fieldValue,
          });
        }
        if (fieldName === 'AcceptTermsSignature' && fieldValue?.length > 0) {
          dispatch({
            type: ActionType.SET_REQUIRED_ACCEPT_SIGNATURE,
            payload: fieldValue,
          });
        }
        if (fieldName === 'IndNoEmail') {
          let mailTo = '';
          if (fieldValue === '2') {
            mailTo =
              inspectionState.currentInspection?.configdata === undefined
                ? ''
                : inspectionState.currentInspection?.configdata[0]
                    .NotificationEmail;
          }
          const allFields = _sections.map((s) => s.fields).flat();

          for (const f of allFields) {
            if (f.name === 'AcceptTermsEmail') {
              dispatch({
                type: ActionType.SET_BILLRIGHTS_MAIL,
                payload: mailTo,
              });
              setDefaultEmail(mailTo);
            }
          }
        }
        if (fieldName === 'isAccidentPlace' && fieldValue === '1') {
          dispatch({
            type: ActionType.SET_ACCIDENT_PLACE,
            payload: true,
          });
        } else {
          dispatch({
            type: ActionType.SET_ACCIDENT_PLACE,
            payload: false,
          });
        }
        const allFields = _sections.map((s) => s.fields).flat();
        for (const f of allFields) {
          if (
            f.dependency?.sectionId === +formSection &&
            f.dependency.name === fieldName &&
            f.dependency.expectedValue !== fieldValue
          ) {
            f.value = undefined;
            break;
          }

          if (f.formSectionId === +formSection) {
            // Special Data Types like DropdDowns or CheckBox
            switch (field?.dataType) {
              case DropDownTypes.PROVINCES:
                if (f.dataType === DropDownTypes.CANTONS) {
                  f.options = CATALOGS.getCantons('', +field.value)?.split('¬');
                }
                if (field?.name === 'provinceDirectionInspect') {
                  localStorage.setItem('eventProvince', field?.value);
                }
                break;
              case DropDownTypes.CANTONS:
                if (f.dataType === DropDownTypes.DISTRICTS) {
                  f.options = CATALOGS.getDistricts('', +field.value)?.split(
                    '¬',
                  );
                }
                if (field?.name === 'cantonDirectionInspect') {
                  localStorage.setItem('eventCanton', field?.value);
                }
                break;
              case DropDownTypes.DISTRICTS:
                if (field?.name === 'districtDirectionInspect') {
                  localStorage.setItem(
                    'eventDistrict',
                    String(field?.value).length > 0
                      ? field?.value
                      : field?.originalValue,
                  );
                }
                break;
              default:
                if (field?.name === 'placeDirectionInspect') {
                  localStorage.setItem('placeDirectionInspect', field?.value);
                }
                break;
            }

            // Common Types for FieldTypes
            if (field?.name === f.name) {
              switch (field?.type?.toLocaleLowerCase()) {
                case FieldTypes.STYLIZED_RADIOBUTTONS:
                  f.originalValue = 'change'; // Force to the control to send the selected value
                  break;
                default:
                  break;
              }
            }
          }

          if (
            !enableSwitchAddress &&
            (localStorage.getItem('countryCode')?.toUpperCase() ===
              CountryCode.CRC ||
              localStorage.getItem('countryCode')?.toUpperCase() ===
                CountryCode.PAN)
          ) {
            enableSwitchAddress =
              f?.name === 'isAccidentPlace' && f.value === '1';
          }

          //For Audit
          if (isAuditProcess && !isInspectorForm) {
            f.isModifyForAudit = f.value !== f.originalValue;
          }
        }

        // LOCATION DATA FOR SWITCH
        enableSwitchAddress =
          field?.name === 'isAccidentPlace' && field.value === '1';
        const eventProvince: any = localStorage.getItem('eventProvince');
        const eventCanton: any = localStorage.getItem('eventCanton');
        const eventDistrict: any = localStorage.getItem('eventDistrict');
        const eventAddress: any = localStorage.getItem('placeDirectionInspect');

        // Update fields for accident location
        const updateLocations = (
          eventProvince: string,
          eventCanton: string,
          eventDistrict: string,
          eventAddress: string,
        ) => {
          const eventProvinceIns =
            inspectionState.currentInspection?.provinceDirectionInspect;
          const eventCantonIns =
            inspectionState.currentInspection?.cantonDirectionInspect;
          const eventDistrictIns =
            inspectionState.currentInspection?.districtDirectionInspect;
          const eventAddressIns =
            inspectionState.currentInspection?.placeDirectionInspect;

          if (eventProvince === undefined || eventProvince === null) {
            eventProvince =
              eventProvinceIns === undefined ? '' : eventProvinceIns;
          }
          if (eventCanton === undefined || eventCanton === null) {
            eventCanton = eventCantonIns === undefined ? '' : eventCantonIns;
          }
          if (eventDistrict === undefined || eventDistrict === null) {
            eventDistrict =
              eventDistrictIns === undefined ? '' : eventDistrictIns;
          }
          if (eventAddress === undefined || eventAddress === null) {
            eventAddress = eventAddressIns === undefined ? '' : eventAddressIns;
          }

          for (const f of allFields) {
            switch (f?.dataType) {
              case DropDownTypes.PROVINCES:
                if (f.name === 'provinceDirectionSinister') {
                  f.value = eventProvince?.toString();
                }
                break;
              case DropDownTypes.CANTONS:
                if (f.name === 'cantonDirectionSinister') {
                  f.value = eventCanton?.toString();
                  f.options = CATALOGS.getCantons('', +eventProvince)?.split(
                    '¬',
                  );
                }
                break;
              case DropDownTypes.DISTRICTS:
                if (f.name === 'districtDirectionSinister') {
                  f.value = eventDistrict?.toString();
                  f.options = CATALOGS.getDistricts('', +eventCanton)?.split(
                    '¬',
                  );
                }
                break;
              default:
                if (f.name === 'placeDirectionSinister') {
                  f.value = eventAddress?.toString();
                  f.hasError = f.required && String(f.value).length < 1;
                }
            }

            //For Audit
            if (isAuditProcess && !isInspectorForm) {
              f.isModifyForAudit = f.value !== f.originalValue;
            }
          }
        };

        // Validate only in CRC
        if (
          localStorage.getItem('countryCode')?.toUpperCase() ===
            CountryCode.CRC ||
          localStorage.getItem('countryCode')?.toUpperCase() === CountryCode.PAN
        ) {
          if (enableSwitchAddress) {
            updateLocations(
              eventProvince,
              eventCanton,
              eventDistrict,
              eventAddress,
            );
          } else if (fieldName === 'isAccidentPlace') {
            updateLocations('', '', '', '');
          }

          if (refreshPage) {
            dispatch({ type: ActionType.SET_AAP_FLAG, payload: fieldValue });
          } else if (fieldName === 'vehicleDefinition') {
            dispatch({
              type: ActionType.SET_VEHICLE_DEFINITION,
              payload: fieldValue,
            });
          } else if (fieldName === 'NoCoverageApplied') {
            dispatch({
              type: ActionType.SET_NOT_COVERAGE,
              payload: fieldValue,
            });
          } else if (fieldName === 'ReasonId') {
            dispatch({ type: ActionType.SET_REASON_ID, payload: fieldValue });
          } else if (fieldName === 'hasTrafficTicket') {
            dispatch({
              type: ActionType.SET_IND_PART_ACC,
              payload: fieldValue,
            });
          }
        }

        // Validate only in PAN
        if (
          localStorage.getItem('countryCode')?.toUpperCase() === CountryCode.PAN
        ) {
          if (fieldName === 'ResponsableOption') {
            dispatch({ type: ActionType.SET_LOADING, payload: true });
            setTimeout(() => {
              dispatch({ type: ActionType.SET_LOADING, payload: false });
            }, 800);
          }
        }

        // Validate only in PRI
        if (
          localStorage.getItem('countryCode')?.toUpperCase() === CountryCode.PRI
        ) {
          // Injured Data Options
          if (fieldName === 'indAirbagsActive') {
            dispatch({
              type: ActionType.SET_IND_AIRBAG,
              payload: fieldValue,
            });
          } else if (fieldName === 'indSeatBeltStagnant') {
            dispatch({
              type: ActionType.SET_IND_SEATBELT,
              payload: fieldValue,
            });
          } else if (fieldName === 'indFrontAxleDamage') {
            dispatch({
              type: ActionType.SET_IND_FRONTAXLE,
              payload: fieldValue,
            });
          } else if (fieldName === 'indRearAxleDamage') {
            dispatch({
              type: ActionType.SET_IND_REARAXLE,
              payload: fieldValue,
            });
          } else if (fieldName === 'indVehicleLooseParts') {
            dispatch({
              type: ActionType.SET_IND_LOOSEPARTS,
              payload: fieldValue,
            });
          } else if (fieldName === 'indCustomerOpenTrunk') {
            dispatch({
              type: ActionType.SET_IND_OPENTRUNK,
              payload: fieldValue,
            });
          } else if (fieldName === 'sinisterTypePR') {
            dispatch({
              type: ActionType.SET_IND_SINIESTER_TYPE,
              payload: fieldValue,
            });

            if (fieldValue !== '1') {
              dispatch({
                type: ActionType.SET_IND_AIRBAG,
                payload: null,
              });

              dispatch({
                type: ActionType.SET_IND_SEATBELT,
                payload: null,
              });

              dispatch({
                type: ActionType.SET_IND_FRONTAXLE,
                payload: null,
              });

              dispatch({
                type: ActionType.SET_IND_REARAXLE,
                payload: null,
              });
            }
          }

          // Insured Data Options
          if (fieldName === 'indAirbagsActiveInsured') {
            dispatch({
              type: ActionType.SET_IND_AIRBAG_INSURED,
              payload: fieldValue,
            });
          } else if (fieldName === 'indSeatBeltStagnantInsured') {
            dispatch({
              type: ActionType.SET_IND_SEATBELT_INSURED,
              payload: fieldValue,
            });
          } else if (fieldName === 'indFrontAxleDamageInsured') {
            dispatch({
              type: ActionType.SET_IND_FRONTAXLE_INSURED,
              payload: fieldValue,
            });
          } else if (fieldName === 'indRearAxleDamageInsured') {
            dispatch({
              type: ActionType.SET_IND_REARAXLE_INSURED,
              payload: fieldValue,
            });
          } else if (fieldName === 'indVehicleLoosePartsInsured') {
            dispatch({
              type: ActionType.SET_IND_LOOSEPARTS_INSURED,
              payload: fieldValue,
            });
          } else if (fieldName === 'indCustomerOpenTrunkInsured') {
            dispatch({
              type: ActionType.SET_IND_OPENTRUNK_INSURED,
              payload: fieldValue,
            });
          } else if (fieldName === 'sinisterTypeInsuredPR') {
            dispatch({
              type: ActionType.SET_IND_SINIESTER_TYPE_INSURED,
              payload: fieldValue,
            });

            if (fieldValue !== '1') {
              dispatch({
                type: ActionType.SET_IND_AIRBAG_INSURED,
                payload: null,
              });

              dispatch({
                type: ActionType.SET_IND_SEATBELT_INSURED,
                payload: null,
              });

              dispatch({
                type: ActionType.SET_IND_FRONTAXLE_INSURED,
                payload: null,
              });

              dispatch({
                type: ActionType.SET_IND_REARAXLE_INSURED,
                payload: null,
              });
            }
          }
        }

        if (fieldName === 'storyDetail') {
          dispatch({
            type: ActionType.SET_AUDIO_TRANSCRIPT,
            payload: fieldValue,
          });
        }

        if (fieldName === 'AcceptTermsEmail') {
          dispatch({
            type: ActionType.SET_BILLRIGHTS_MAIL,
            payload: fieldValue,
          });
        }

        if (fieldName === 'Ocurrido') {
          dispatch({
            type: ActionType.SET_AUDIO_TRANSCRIPT_INSURED,
            payload: fieldValue,
          });
        }

        if (fieldName === 'VehicleDescription2') {
          dispatch({
            type: ActionType.SET_AUDIO_TRANSCRIPT_INJURED,
            payload: fieldValue,
          });
        }

        if (fieldName === 'driverName') {
          dispatch({
            type: ActionType.SET_DRIVER_NAME,
            payload: fieldValue,
          });
        }

        if (fieldName === 'driverLastName') {
          dispatch({
            type: ActionType.SET_DRIVER_LAST_NAME,
            payload: fieldValue,
          });
        }

        if (fieldName === 'driverId') {
          dispatch({
            type: ActionType.SET_DRIVER_ID,
            payload: fieldValue,
          });
        }

        if (fieldName === 'driverNumLicense') {
          dispatch({
            type: ActionType.SET_DRIVER_LICENSE_NUMBER,
            payload: fieldValue,
          });
        }

        if (fieldName === 'driverExpireLicenseDate') {
          dispatch({
            type: ActionType.SET_DRIVER_LICENSE_EXPIRATION,
            payload: fieldValue,
          });
        }

        if (fieldName === 'driverBirthDate') {
          dispatch({
            type: ActionType.SET_DRIVER_BIRTH_DATE,
            payload: fieldValue,
          });
        }

        if (fieldName === 'driverLicenseType') {
          dispatch({
            type: ActionType.SET_DRIVER_LICENSE_TYPE,
            payload: fieldValue,
          });
        }

        if (fieldName === 'plateMetallic') {
          dispatch({
            type: ActionType.SET_PLATE_CIRCUNSTANCES_INSURED,
            payload: fieldValue,
          });
        }

        if (field && isAuditProcess && !isInspectorForm) {
          //For Audit
          field.isModifyForAudit = field.value !== field.originalValue;
        }

        setSections([..._sections]);

        break;
      }
    }
  };

  const handleAutoSave = () => {
    if (!isAuditProcess || isInspectorForm) {
      save();
    }
  };

  return (
    <Grid container className="inspectionForm__row" spacing={2}>
      {getFields(fields).map(
        (dataField: Field, index: number) =>
          dataField.visible && (
            <Grid
              key={`form-field-${index}`}
              item
              md={dataField.viewLength || 12}
              xs={12}
            >
              <InputField
                key={dataField.name}
                field={dataField}
                defaultValue={dataField.value ?? dataField.defaultValue}
                isVirtualInspector={isVirtualInspector}
                onChange={handleInputChange}
                saveFn={handleAutoSave}
                isInspectorForm={isInspectorForm}
                countryId={inspectionState.currentInspection?.CountryId}
                isDisabled={dataField.isDisabled}
                options={options}
                formValidation={formValidation}
                handleChangeDiagram={handleChangeDiagram}
                metadata={{
                  sections,
                  inspectionState,
                  inspectDispatch: dispatch,
                }}
                hasDefaultValue={!dataField.value && !!dataField?.defaultValue}
                isCMT={isCMT}
              />
            </Grid>
          ),
      )}
    </Grid>
  );
};

export default SectionFields;
