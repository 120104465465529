import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { HelpOutlineOutlined } from '@mui/icons-material';
import clsx from 'clsx';
import { EventStatus } from 'types/event-status';
import styles, { HtmlTooltip } from './override-mui-styles';
import { CountryCode } from 'types/common';
import { Typography } from '@mui/material';
import './styles.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  status?: EventStatus;
  amount?: number;
  notification?: boolean;
  subtitle?: string;
  title?: string;
  username?: string;
  assignedEvents?: number;
  description?: string;
  help?: string;
  selected?: boolean;
  onSelectStatus?: (value: EventStatus) => void;
}

const StatusCard: React.FC<Props> = React.memo(
  ({
    className,
    status,
    amount,
    subtitle = '',
    title = '',
    username = '',
    assignedEvents = null,
    description = '',
    help = '',
    selected,
    onSelectStatus,
  }) => {
    const classes = styles();
    const newStatusCard =
      localStorage.getItem('countryCode') === CountryCode.PRI;

    const handleSelectedStatus = () => {
      if (onSelectStatus && status) {
        onSelectStatus(status);
      }
    };

    return (
      <div
        className={clsx(
          'statusCard',
          `statusCard--${status ? status.colorClass : ''}`,
          { 'statusCard--selected': selected },
          className,
        )}
        onClick={handleSelectedStatus}
      >
        {status ? (
          <>
            <p className="statusCard__text">{status.label}</p>
            <p
              className={
                newStatusCard && assignedEvents
                  ? 'statusCard__text statusCard__text--regular'
                  : 'statusCard__text statusCard__text--big'
              }
            >
              <SvgIcon className="statusCard__icon">{status.icon}</SvgIcon>
              {amount ? amount : 0}
            </p>
          </>
        ) : (
          <>
            <p className="statusCard__text">{subtitle}</p>
            <p
              className={
                newStatusCard && assignedEvents
                  ? 'statusCard__text statusCard__text--title'
                  : 'statusCard__text statusCard__text--big'
              }
            >
              {title}
            </p>

            {newStatusCard && assignedEvents ? (
              <>
                <p className="statusCard__text statusCard__text--chip">
                  {username}
                </p>
                <Typography
                  variant="body1"
                  component="p"
                  align="left"
                  className="statusCard__text statusCard__text--light-notifications"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <span>
                    Tienes<span className="customSpan">{assignedEvents}</span>
                  </span>
                  <div>notificaciones pendientes</div>
                </Typography>
              </>
            ) : (
              <p className="statusCard__text statusCard__text--light">
                {description}
              </p>
            )}
          </>
        )}
        {help && (
          <div className="statusCard__help">
            <HtmlTooltip
              placement="left"
              title={
                <div>
                  <p>{status ? status.description : help}</p>
                </div>
              }
            >
              <SvgIcon className={classes.helpIcon}>
                <HelpOutlineOutlined />
              </SvgIcon>
            </HtmlTooltip>
          </div>
        )}
      </div>
    );
  },
);

export default StatusCard;
