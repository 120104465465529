import { useEffect } from 'react';
import { RefComponent } from 'types/ref-component';

interface Callback {
  (node: Node): void;
}

export default function useClickedOutside(
  ref: RefComponent<HTMLDivElement>,
  callback: Callback,
): any {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      const targetNode = event.target as Node;
      if (ref.current && !ref.current.contains(targetNode)) {
        callback(targetNode);
      } else {
        return null;
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}
