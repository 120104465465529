import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import './index.scss';
import { Grid, TextField } from '@mui/material';
import Button from '../button';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatNumberMoney, maskValue } from 'shared/utils';
import { useTotalLossAdjust } from 'context/adjust/totalLoss-adjust-context';
import { TotalLossAdjustDTO } from 'context/adjust/adjust';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { ActionType } from 'types/action';
import CATALOGS from 'static/constants/catalogs';

interface Props {
  claimUId?: string;
  totalIsured: string;
  depreciationValue: string;
}
export interface DepreciationCalculation {
  PurchaseDate: string;
  LossDate: string;
  Months: string;
  TotalInsured: string;
  Percentage: string;
  TotalDepreciation: string;
}

const ClaimDepreciationCalculation: FunctionComponent<Props> = ({
  claimUId,
  totalIsured,
  depreciationValue,
}): JSX.Element => {
  const [depreciationState, setDepreciationState] = useState<
    DepreciationCalculation
  >({
    PurchaseDate: '',
    LossDate: '',
    Months: '',
    TotalInsured: '',
    Percentage: '',
    TotalDepreciation: '',
  });
  const { dispatch } = useContext<IInspectionContext>(InspectionContext);
  const { settingTladjust } = useTotalLossAdjust();
  const [txtPurchaseDate, setTxtPurchaseDate] = useState<string>('');
  const [txtLossDate, setTxtLossDate] = useState<string>('');
  const [lblMonths, setLblMonths] = useState<any>('');
  const [disabled, setDisabled] = useState<any>(true);
  const [exist, setExist] = useState<any>(false);
  const [lblDepreciation, setLblDepreciation] = useState<any>('');
  const [lblTotalPay, setLblTotalPay] = useState<any>('');
  const [Total, setTotal] = useState<any>('');
  const [txtPriceCar, setTxtPriceCar] = useState<string>(totalIsured);
  const [txtDepreciationPercentage, setTxtDepreciationPercentage] = useState<
    string
  >('15');

  const onPriceCarChange = (event: any): void => {
    setTxtPriceCar(event?.target?.value);
  };

  const onPercentageChange = (event: any): void => {
    setTxtDepreciationPercentage(event?.target?.value);
  };
  useEffect(() => {
    if (txtPriceCar && txtDepreciationPercentage && lblMonths) {
      getDepreciation();
      setDisabled(false);
    }
  }, [txtPriceCar, txtDepreciationPercentage, lblMonths]);
  useEffect(() => {
    getCalculate().catch(console.error);
  }, []);

  useEffect(() => {
    getDepreciation();
  }, [depreciationValue, lblTotalPay]);

  const getCalculate = async (): Promise<void> => {
    try {
      const result = await new ClaimsTrackerClient().getDepreciationCalculate(
        claimUId,
      );

      if (result) {
        setExist(true);
        setDepreciationState(result);

        try {
          setTxtLossDate(result.lossDate?.split('T')[0]);
          setTxtPurchaseDate(result.purchaseDate?.split('T')[0]);
        } catch (error) {
          console.warn(error);
          setTxtLossDate('');
          setTxtPurchaseDate('');
        }

        setTxtDepreciationPercentage(result.percentage);
        setLblMonths(result.months);
        setTxtPriceCar(result.totalInsured);
        setLblDepreciation(result.totalDepreciation);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onDateChange = (date, type): void => {
    if (type === 'buy') {
      setTxtPurchaseDate(date);
    } else {
      setTxtLossDate(date);
    }
  };
  useEffect(() => {
    if (txtPurchaseDate && txtLossDate) {
      getMonths();
    }
  }, [txtPurchaseDate, txtLossDate]);

  const getMonths = (): void => {
    const dateIni = dayjs(txtPurchaseDate + ' 00:00:00');
    const dateFin = dayjs(txtLossDate + ' 00:00:00');

    let months = dateFin.diff(dateIni, 'month');

    if (months <= 0) {
      months = 1;
    }

    setLblMonths(months);
  };

  const getDepreciation = (): void => {
    const priceCar = parseFloat(txtPriceCar);
    const percentage =
      (parseFloat(txtDepreciationPercentage) * parseFloat(lblMonths)) /
      12 /
      100;
    const dep = priceCar - priceCar * percentage;
    setLblDepreciation(formatNumberMoney(dep));
    const depre = parseFloat(depreciationValue);
    setLblTotalPay(formatNumberMoney(depre < dep ? depre : dep));
    if (exist) {
      setTotal(formatNumberMoney(depre < dep ? depre : dep));

      const newTotalLossAdjust: TotalLossAdjustDTO = {
        ActualCost: formatNumberMoney(depre < dep ? depre : dep),
      };

      settingTladjust(newTotalLossAdjust);
    }
  };

  const onClickSave = async (): Promise<void> => {
    try {
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      const dep = depreciationState;
      dep.LossDate = txtLossDate;
      dep.Months = lblMonths;
      dep.Percentage = txtDepreciationPercentage;
      dep.PurchaseDate = txtPurchaseDate;
      dep.TotalDepreciation = lblDepreciation.replace(',', '');
      dep.TotalInsured = txtPriceCar;
      const result = await new ClaimsTrackerClient().saveDepreciationCalculate(
        dep,
        claimUId,
      );
      setTotal(lblTotalPay);

      // Refrescar la página
      window.location.reload();

      dispatch({ type: ActionType.SET_LOADING, payload: false });
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={6} className="claim-depreciation">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className="claim-depreciation__data--title"
        >
          <p>CALCULO DE DEPRECIACIÓN</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          className="claim-depreciation__content"
        >
          <Grid className="claim-depreciation__data">
            <Grid>
              <Grid className="claim-estimated__data--row">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div style={{ width: '300px' }}>
                    <DesktopDatePicker
                      label="Fecha de efectividad"
                      format={CATALOGS.getCountryFormat().dateTimeFormat}
                      value={dayjs(txtPurchaseDate)}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'outlined',
                          margin: 'none',
                          'aria-label': 'change date',
                        },
                      }}
                      onChange={(date: any) => {
                        if (date) {
                          const dayjsDate = dayjs(date);
                          if (dayjsDate.isValid()) {
                            onDateChange(dayjsDate.format('YYYY-MM-DD'), 'buy');
                          }
                        }
                      }}
                    />
                  </div>
                </LocalizationProvider>
              </Grid>
              <Grid className="claim-estimated__data--row">
                <TextField
                  name="PriceCar"
                  className="claim-depreciation__data--row--input"
                  label="Costo de auto nuevo *"
                  onChange={onPriceCarChange}
                  value={txtPriceCar}
                ></TextField>
              </Grid>
              <Grid className="claim-depreciation__data--row">
                <TextField
                  name="TotalValue"
                  className="claim-depreciation__data--row--input"
                  label="Cálculo a pagar"
                  disabled={true}
                  value={lblTotalPay}
                ></TextField>
              </Grid>
            </Grid>
            <Grid>
              <Grid className="claim-estimated__data--row">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div style={{ width: '300px' }}>
                    <DesktopDatePicker
                      label="Fecha de pérdida"
                      format={CATALOGS.getCountryFormat().dateTimeFormat}
                      value={dayjs(txtLossDate)}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'outlined',
                          margin: 'none',
                          'aria-label': 'change date',
                        },
                      }}
                      onChange={(date: any) => {
                        if (date) {
                          const dayjsDate = dayjs(date);
                          if (dayjsDate.isValid()) {
                            onDateChange(
                              dayjsDate.format('YYYY-MM-DD'),
                              'loss',
                            );
                          }
                        }
                      }}
                    />
                  </div>
                </LocalizationProvider>
              </Grid>
              <Grid className="claim-estimated__data--row">
                <TextField
                  name="PercentageDepreciation"
                  className="claim-depreciation__data--row--input"
                  label="% de Depreciación *"
                  onChange={onPercentageChange}
                  value={txtDepreciationPercentage}
                ></TextField>
              </Grid>
              <Grid className="claim-depreciation__data--row">
                <Button
                  style={{ width: '300px' }}
                  text="Guardar depreciación"
                  disabled={disabled}
                  onClick={onClickSave}
                />
              </Grid>
            </Grid>
            <Grid>
              <Grid className="claim-estimated__data--row">
                <TextField
                  name="Months"
                  className="claim-depreciation__data--row--input"
                  label="Meses transcurridos"
                  disabled={true}
                  value={lblMonths}
                ></TextField>
              </Grid>
              <Grid className="claim-depreciation__data--row">
                <TextField
                  name="DepreciationValue"
                  className="claim-depreciation__data--row--input"
                  label="Valor depreciación"
                  disabled={true}
                  value={lblDepreciation}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClaimDepreciationCalculation;
