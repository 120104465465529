import { ResourceAction, Rol } from 'types/common';

type PermissionCheckFunction = (action: ResourceAction) => boolean;

export const companyFilterPermission = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  roleId: any,
  isMultiCountry: boolean,
  checkPermission: PermissionCheckFunction,
): boolean => {
  if (isMultiCountry && [Rol.BROKER, Rol.PRE_BROKER].includes(Number(roleId))) {
    return true;
  }

  return checkPermission(ResourceAction.USE_FILTER_COMPANIES);
};
