import { Button, IconButton, SvgIcon, TextField } from '@mui/material';
import React, { useState } from 'react';
import clsx from 'clsx';
import styles from './override-mui-styles';
import useClickedOutside from 'shared/hooks/useClickedOutside';
import './styles.scss';
import { Close } from '@mui/icons-material';
import { ActionType, DispatchFunction } from 'types/action';

interface Props {
  visible: boolean;
  onClose: () => void;
  onClick: () => Promise<void>;
  dispatch: DispatchFunction;
}

const UserNameModal = (props: Props): JSX.Element => {
  const classes = styles();
  const modalRef = React.createRef<HTMLDivElement>();
  const [userName, setUserName] = useState('');
  const [isValid, setIsValid] = useState(true);

  const getName = async (): Promise<void> => {
    if (userName.length === 0) {
      setIsValid(false);
    } else {
      setIsValid(true);
      props.dispatch({
        type: ActionType.SET_USER_INSPECTOR,
        payload: userName,
      });
      props.onClick();
    }
  };

  useClickedOutside(modalRef, (callback) => {
    if (callback && props.visible) {
      props.onClose();
    }
  });

  return (
    <div
      ref={modalRef}
      className={clsx('name-modal', {
        'name-modal--hide': !props.visible,
      })}
    >
      <div className="name-modal__grid">
        <div className="name-modal__header">
          <h3>Nombre inspector</h3>
          <IconButton
            className={classes.closeIconButton}
            onClick={props.onClose}
          >
            <SvgIcon className={classes.closeIcon}>
              <Close />
            </SvgIcon>
          </IconButton>
        </div>
        <TextField
          key={'InspectorName'}
          className={clsx('inspectionForm__input')}
          name={'InspectorName'}
          label={'Digite su nombre:'}
          variant="outlined"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />

        {!isValid && <label style={{ color: 'red' }}>Campo requerido</label>}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.continueButton}
          onClick={getName}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};

export default UserNameModal;
