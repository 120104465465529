import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Loader from 'components/common/loader/Loader';
import FormSteps from 'components/inspection/form-steps';
import { FieldSection } from 'types/section-fields';
import CompaniesTab from './Tabs/CompaniesTab';
import ProvidersTab from './Tabs/ProvidersTab';
import UsersTab from './Tabs/UsersTab';
import { SettingsTab } from 'static/constants/settings-tabs';
import './styles.scss';
import DialogComponent from 'components/common/dialog';
import { deleteUserByCognitoId } from 'context/user';

const Settings = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(SettingsTab.COMPANIES);
  const [
    openDialogDeleteAccountConfirm,
    setOpenDialogDeleteAccountConfirm,
  ] = useState(false);
  const [idDeleteAccount, setIdDeleteAccount] = useState<string | null>(null);
  const elementToScroll = useRef<null | HTMLDivElement>(null);

  const executeScroll = () => {
    if (elementToScroll !== null && elementToScroll.current !== null) {
      elementToScroll.current.scrollIntoView();
    }
  };

  const tabSteps = (): FieldSection[] => {
    const tabs: FieldSection[] = [
      {
        title: 'Aseguradora',
        formSectionId: SettingsTab.COMPANIES,
        idName: 'setting-users',
        idOrder: 0,
        fields: [],
      },
      {
        title: 'Proveedor/Conductor',
        formSectionId: SettingsTab.PROVIDERS,
        idName: 'setting-users',
        idOrder: 1,
        fields: [],
      },
      {
        title: 'SIC/Connect',
        formSectionId: SettingsTab.USERS,
        idName: 'setting-users',
        idOrder: 2,
        fields: [],
      },
    ];

    return tabs;
  };

  const tabClick = (tab: any) => {
    setCurrentTab(tab.formSectionId);
    setLoading(true);
  };

  const handleDeleteAccount = (userGuidId: string): void => {
    setIdDeleteAccount(userGuidId);
  };

  const handleConfirmDeleteAccount = async () => {
    setLoading(true);
    if (idDeleteAccount) {
      setOpenDialogDeleteAccountConfirm(false);
      setIdDeleteAccount(null);

      await deleteUserByCognitoId(idDeleteAccount);
      const refreshTab = tabSteps().filter(
        (f) => f.formSectionId !== currentTab,
      )[0];
      tabClick(refreshTab);
    } else {
      setOpenDialogDeleteAccountConfirm(false);
      setIdDeleteAccount(null);
      setLoading(false);
    }
  };

  const renderTab = (tabData: number) => {
    setTimeout(() => setLoading(false), 1500);
    switch (tabData) {
      case SettingsTab.USERS:
        return (
          <UsersTab
            scrollTrigger={executeScroll}
            onDeleteUser={handleDeleteAccount}
          />
        );
      case SettingsTab.PROVIDERS:
        return (
          <ProvidersTab
            scrollTrigger={executeScroll}
            onDeleteUser={handleDeleteAccount}
          />
        );
      case SettingsTab.COMPANIES:
        return (
          <CompaniesTab
            scrollTrigger={executeScroll}
            onDeleteUser={handleDeleteAccount}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (idDeleteAccount && idDeleteAccount.length > 0) {
      setOpenDialogDeleteAccountConfirm(true);
    }
  }, [idDeleteAccount]);

  return (
    <>
      <div className={clsx('settings')}>
        <h1 className="settings__title">Ajustes</h1>
        <Loader isActive={loading} />
        <>
          <FormSteps
            steps={tabSteps()}
            activeStep={{
              formSectionId: currentTab,
              title: '',
            }}
            onSelectedTab={tabClick}
          />
          <div ref={elementToScroll}></div>
          {renderTab(currentTab)}
        </>
      </div>
      <DialogComponent
        messageStyle={{ fontSize: '16px' }}
        message={
          '<h3>¿Desea eliminar la cuenta seleccionada?</h3><p><i><strong>Nota:</strong> Esta acción inhabilitará completamente el usuario.</i></p>'
        }
        open={openDialogDeleteAccountConfirm}
        dialogTitle="Eliminar Cuenta de Usuario"
        buttonConfirmText="Sí, eliminar"
        buttonCancelText="Cancelar"
        handleClose={() => {
          setIdDeleteAccount(null);
          setOpenDialogDeleteAccountConfirm(false);
        }}
        handleConfirm={handleConfirmDeleteAccount}
      />
    </>
  );
};

export default Settings;
