import './index.scss';
import React, { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { Field } from 'formik';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { IClaimForm } from 'types/claimsTracker';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import Button from '../button';
import APIClient, { endpoints } from 'shared/api';

interface Props {
  claimUId: string;
  claimForm?: IClaimForm;
  notifySubrogation?: boolean;
  disabled: boolean;
}

const Subrogation: FunctionComponent<Props> = ({
  claimUId,
  claimForm,
  notifySubrogation,
  disabled,
}): JSX.Element => {
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [confirmApplySubroga, setConfirmApplySubroga] = useState<boolean>(
    false,
  );
  const [subrogIsApply, setSubrogIsApply] = useState<boolean>(false);
  const [subrogCheckValue, setSubrogCheckValue] = useState<boolean>(false);
  const [subrogAmountRecovered, setSubrogAmountRecovered] = useState<boolean>(
    false,
  );
  const [subrogRecoveryAmount, setSubrogRecoveryAmount] = useState<number>(0);
  const [subrogNonRecoveryCause, setSubrogNonRecoveryCause] = useState<string>(
    '',
  );
  const [subrogTxtDate, setSubrogTxtDate] = useState<string>('');
  const [insuranceRequired, setInsuranceRequired] = useState<string>(
    'subrogation__data__large',
  );
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openSendMail, setOpenSendMail] = useState<boolean>(false);
  const [sendMail, setSendMail] = useState<boolean>(false);

  const [companies, setCompanies] = useState([]);
  const [insuranceValue, setInsuranceValue] = useState('');
  const [insurance, setInsurance] = useState('');
  const [insuranceFinal, setInsuranceFinal] = useState('');
  const [insuranceOther, setInsuranceOther] = useState<boolean>(false);
  const [sectionsDiagrams, setSectionsDiagrams] = useState<any>(null);
  const [diagrams, setDiagrams] = useState<any>(null);
  const [section, setSection] = useState(null);
  const [diagramId, setDiagramId] = useState(null);
  const [diagramImage, setDiagramImage] = useState(null);
  const [disabledSave, setDisabledSave] = useState(true);
  const [disableLoadComplete, setDisableLoadComplete] = useState(false);

  const handleIsApplyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setSubrogCheckValue(value === 'true');
    if (value === 'true' && notifySubrogation) {
      setOpenSendMail(true);

      return;
    }

    setSendMail(value === 'true');
    setSubrogIsApply(value === 'true');
    if (firstRender) {
      setFirstRender(false);
    }
  };

  const handleSendEmailChange = (value: boolean) => {
    setSendMail(value);
    setSubrogIsApply(subrogCheckValue);
    if (firstRender) {
      setFirstRender(false);
    }
    setOpenSendMail(false);
  };

  const handleIsRecoveredChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setSubrogAmountRecovered(value === 'true');
    if (firstRender) {
      setFirstRender(false);
    }
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numberValue = Number(value.replace(/[^0-9.-]+/g, ''));
    setSubrogRecoveryAmount(numberValue);
  };

  const handleNonRecoveryCauseChange = (event: SelectChangeEvent) => {
    setSubrogNonRecoveryCause(event.target.value);
  };

  const handleCompanyListChange = (event: SelectChangeEvent) => {
    setInsuranceValue(event.target.value);
    if (event.target.value === 'Otro') {
      setInsurance('');
      setInsuranceFinal('');
      setInsuranceOther(true);
      setInsuranceRequired('subrogation__data__large subrogation__required');
    } else {
      if (firstRender) {
        setFirstRender(false);
      }
      setInsuranceOther(false);
      setInsurance(event.target.value);
      setInsuranceFinal(event.target.value);
    }
  };
  const handleInsuranceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (firstRender) {
      setFirstRender(false);
    }
    setInsurance(event.target.value);
    setInsuranceRequired('subrogation__data__large');
    if (event.target.value.length === 0) {
      setInsuranceRequired('subrogation__data__large subrogation__required');
    }
    setDisableLoadComplete(false);
  };

  const submitSubrogation = async (confirmSave = false): Promise<void> => {
    const insuranceData = subrogIsApply ? insuranceFinal : null;
    setConfirmApplySubroga(
      subrogIsApply && confirmSave && insuranceFinal.length > 0,
    );

    const rta = await new ClaimsTrackerClient().applySubrogation(
      claimUId,
      subrogIsApply,
      sendMail,
      null,
      null,
      null,
      null,
      insuranceData,
      Number(localStorage.getItem('userId')),
      diagramId,
    );
    if (rta) {
      setDisableLoadComplete(true);
    }
  };

  const confirmSubrogation = (confirmSave = false): void => {
    (async (): Promise<void> => {
      await submitSubrogation(confirmSave);
      setOpenConfirm(false);
    })();
  };

  const blurInsurance = (event) => {
    setInsuranceFinal(event?.target.value);
  };

  const changeSection = (event) => {
    setSection(event.target.value);
    const diagrams = sectionsDiagrams.find(
      (x) => x.sectionName === event.target.value,
    );
    setDiagrams(diagrams.diagrams);
    setDisableLoadComplete(false);
  };

  const changeDiagram = (event) => {
    setDiagramId(event.target.value);
    setDiagramImage(null);
    const diagram = sectionsDiagrams
      .find((x) => x.sectionName === section)
      .diagrams.find((x) => x.id === event.target.value);
    setDiagramImage(diagram.image);
    setDisableLoadComplete(false);
  };
  const getSeccions = async () => {
    const response = await new ClaimsTrackerClient().getDiagramsSubrogation();
    if (response?.length > 0) {
      setSectionsDiagrams(response);

      if (claimForm?.info?.subrogation?.diagramId) {
        for (let i = 0; i < response.length; i++) {
          const diag = response[i].diagrams.find(
            (x) => x.id === claimForm?.info?.subrogation?.diagramId,
          );
          if (diag) {
            setSection(response[i].sectionName);
            const diagrams = response.find(
              (x) => x.sectionName === response[i].sectionName,
            );
            setDiagrams(diagrams.diagrams);
            setDiagramId(diag.id);
            setDiagramImage(diag.image);
            setDisableLoadComplete(true);
            break;
          }
        }
      }
    }
  };
  const getCompanies = async () => {
    const countryCode = localStorage.getItem('countryCode');
    const response = await APIClient.get(
      endpoints.GET_COMPANIES_COUNTRY.replace(
        '{countryCode}',
        String(countryCode),
      ),
    );
    if (response && response.data?.data) {
      setCompanies(response.data?.data);
      if (claimForm?.info?.subrogation?.insurance) {
        setInsuranceOther(false);
        setInsurance(claimForm?.info?.subrogation?.insurance);
        setInsuranceFinal(claimForm?.info?.subrogation?.insurance);
        setInsuranceValue(claimForm?.info?.subrogation?.insurance);
        if (
          response?.data?.data.find(
            (x) => x.name === claimForm?.info?.subrogation?.insurance,
          ) === undefined
        ) {
          setInsuranceOther(true);
          setInsuranceValue('Otro');
        }
      }
    }
  };

  const loadData = () => {
    getCompanies();
    if (claimForm?.info?.subrogation?.isApply) {
      setSubrogIsApply(claimForm?.info?.subrogation?.isApply);
    }
    getSeccions();
  };

  useEffect(() => {
    loadData();
  }, [claimForm?.info?.case]);

  useEffect(() => {
    if (!firstRender) {
      confirmSubrogation(false);
    }
  }, [subrogIsApply, insuranceFinal]);

  useEffect(() => {
    setDisabledSave(
      !(subrogIsApply && insuranceFinal && diagramId && !disableLoadComplete),
    );
  }, [subrogIsApply, insuranceFinal, diagramId, disableLoadComplete]);

  return (
    <>
      <Box className="subrogation">
        <>
          <Box className="subrogation__data">
            <Box>
              <label>¿Aplica subrogación?</label>
              <br />
              <FormControl sx={{ textAlign: 'left' }}>
                <RadioGroup
                  row
                  aria-labelledby="apply-subrogation"
                  name="position"
                  defaultValue={claimForm?.info?.subrogation?.isApply ?? false}
                  onChange={handleIsApplyChange}
                >
                  <FormControlLabel
                    disabled={disabled}
                    value={true}
                    control={<Radio />}
                    label="Sí"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    disabled={disabled}
                    value={false}
                    control={<Radio />}
                    label="No"
                    labelPlacement="start"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          {subrogIsApply && (
            <>
              <label>
                Seleccione la aseguradora con la cuál se realizará el recobro
              </label>
              <Box className="subrogation__data" sx={{ mt: 2 }}>
                <Box>
                  <FormControl
                    sx={{ minWidth: 235, backgroundColor: '#ffffff' }}
                  >
                    <InputLabel id="insurances">Aseguradora</InputLabel>
                    <Select
                      labelId="insurances"
                      id="slct-insurances"
                      value={insuranceValue}
                      onChange={handleCompanyListChange}
                      label="Aseguradora"
                      disabled={disabled}
                    >
                      {companies?.map((item: any) => {
                        return (
                          <MenuItem key={item.id} value={item.name}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                      <MenuItem value={'Otro'}>Otro</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {insuranceOther && (
                  <Box className="subrogation__w500">
                    <Field
                      fullWidth
                      key="insurance-amount"
                      className={insuranceRequired}
                      as={TextField}
                      type="text"
                      label="Digite el nombre de la aseguradora"
                      name="company-amount"
                      value={insurance}
                      onChange={handleInsuranceChange}
                      onBlur={blurInsurance}
                      disabled={disabled}
                    />
                  </Box>
                )}
              </Box>

              <Box className="subrogation__data">
                <Box>
                  <br />
                  <FormControl
                    sx={{ minWidth: 235, backgroundColor: '#ffffff' }}
                  >
                    <InputLabel id="lblSection">Sección</InputLabel>
                    <Select
                      labelId="lblSection"
                      id="slct-lblSection"
                      value={section}
                      onChange={changeSection}
                      label="Sección"
                      disabled={disabled}
                    >
                      {sectionsDiagrams
                        ? sectionsDiagrams.map((entry, index) => {
                            return (
                              <MenuItem key={index} value={entry.sectionName}>
                                {entry.sectionName}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <br />
                  <FormControl
                    sx={{ minWidth: 235, backgroundColor: '#ffffff' }}
                  >
                    <InputLabel id="nonRecoveryCause">Diagrama</InputLabel>
                    <Select
                      labelId="lblDiagram"
                      id="slct-lblDiagram"
                      value={diagramId}
                      onChange={changeDiagram}
                      label="Diagrama"
                      disabled={disabled}
                    >
                      {diagrams
                        ? diagrams.map((entry, index) => {
                            return (
                              <MenuItem key={index} value={entry.id}>
                                {entry.diagramName}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <Box className="subrogation__data">
                <Box>
                  {diagramImage ? (
                    <img src={diagramImage} width={'100%'}></img>
                  ) : null}
                </Box>
                <Box>
                  <br />
                  <Button
                    text="Abrir Subrogación"
                    type="button"
                    onClick={() => setOpenConfirm(true)}
                    disabled={disabledSave}
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      </Box>

      <Dialog open={openConfirm}>
        <DialogTitle id="confirm-restore-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-payment-modal"
            style={{ width: '400px', height: '120px' }}
          >
            <br />
            ¿Está seguro que desea aplicar los cambios de subrogación?
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            text="Cancelar"
            variant="outlined"
            color="secondary"
            onClick={() => setOpenConfirm(false)}
          />
          <Button
            text="Sí, confirmar"
            variant="contained"
            color="primary"
            onClick={() => {
              confirmSubrogation(true);
            }}
          />
        </DialogActions>
      </Dialog>

      <Dialog open={openSendMail}>
        <DialogTitle id="confirm-restore-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-payment-modal"
            style={{ width: '400px', height: '120px' }}
          >
            <br />
            ¿Quiere notificar por Email a la aseguradora de la actualización del
            caso?
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            text="Cancelar"
            variant="outlined"
            color="secondary"
            onClick={() => handleSendEmailChange(false)}
          />
          <Button
            text="Sí, confirmar"
            variant="contained"
            color="primary"
            onClick={() => handleSendEmailChange(true)}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Subrogation;
