import {
  InspectionContext,
  downloadExpressAdjustReport,
} from 'context/inspections';
import { Grid, Button } from '@mui/material';
import DatePicker from 'components/common/datepicker/DatePicker';
import Loader from 'components/common/loader/Loader';
import React, { useContext, useState } from 'react';
import { format } from 'date-fns';
import style from 'override-mui-styles';
import clsx from 'clsx';
import './styles.scss';
import { BrandsId } from 'types/common';

export default function AjusteExpressReport(): JSX.Element {
  const [downloadURL, setDownloadURL] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = style();
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(format(new Date(), 'MM/dd/yyyy')),
  );
  const [endDate, setEndDate] = useState<Date | null>(
    new Date(format(new Date(), 'MM/dd/yyyy')),
  );

  //Context
  const { dispatch } = useContext(InspectionContext);

  const onChangeStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const onChangeEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const generateReport = () => {
    if (startDate && endDate) {
      const roleId = localStorage.getItem('rolId') ?? '1';
      setDownloadURL('');
      (async (): Promise<void> => {
        setLoading(true);
        const request = {
          countryCode: localStorage.getItem('countryCode'),
          inicio: format(startDate, 'yyyy-MM-dd') + ' 00:00:00',
          fin: format(endDate, 'yyyy-MM-dd') + ' 23:59:59',
          companyId: BrandsId.SURA,
          rolId: Number(roleId),
        };

        const result = await downloadExpressAdjustReport(request, dispatch);
        setDownloadURL(result);
        setTimeout(() => setLoading(false), 3000);
      })();
    }
  };

  const downloadFile = () => {
    setLoading(true);
    saveAs(downloadURL, 'reporteSIC');
    setTimeout(() => setLoading(false), 3000);
  };

  return (
    <>
      <Loader isActive={loading} />
      <div className="reportForm">
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sm={5} md={3} lg={3}>
            <label className="reportForm__label">Fecha Desde:</label>
            <DatePicker
              startDate={startDate}
              onChangeStartDate={(date) => onChangeStartDate(date)}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={3} lg={3}>
            <label className="reportForm__label">Fecha Hasta:</label>
            <DatePicker
              startDate={endDate}
              onChangeStartDate={(date) => onChangeEndDate(date)}
            />
          </Grid>
          <Grid className="generateSection" item xs={12} sm={1} md={3} lg={4}>
            <Button
              id="continue-btn"
              variant="contained"
              className={clsx(classes.button)}
              size="large"
              onClick={() => generateReport()}
            >
              Generar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={1} md={3} lg={4}></Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <div className="downloadSection">
                  {downloadURL?.length > 0 && (
                    <Button
                      id="continue-btn"
                      variant="contained"
                      className={clsx(classes.button)}
                      size="large"
                      onClick={() => downloadFile()}
                    >
                      Descargar Reporte
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={1} md={3} lg={4}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
