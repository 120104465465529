import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ICheckListModel } from 'types/common';

interface CheckListProps {
  name?: string | undefined;
  checkList: any;
  checkData: any;
  isDisabled?: boolean;
  onChange: (event: any) => void;
  onBlur: () => void;
  error?: boolean;
}

const CheckboxList: FunctionComponent<CheckListProps> = ({
  name,
  checkList,
  checkData,
  isDisabled,
  onChange,
  onBlur,
  error,
}): JSX.Element => {
  const [render, setRender] = useState(true);
  const [checkItems, setCheckItems] = useState<ICheckListModel[]>([]);
  const [updated, setUpdated] = useState(false);

  const triggerChanges = (event: any) => {
    let updateCheckList: ICheckListModel[] = [...checkItems];
    let findObj = checkItems.find(
      (checkElement: ICheckListModel) =>
        String(checkElement.id) === event.target?.name,
    );
    if (findObj) {
      findObj = { ...findObj, state: !findObj.state };
      updateCheckList = checkItems.map((checkElement: ICheckListModel) => {
        checkElement =
          checkElement.id === findObj?.id ? { ...findObj } : checkElement;

        return checkElement;
      });
      setCheckItems(updateCheckList);
    }

    onChange({
      target: {
        name: name,
        value: JSON.stringify(
          updateCheckList.filter(
            (uc: ICheckListModel) =>
              uc.state === true &&
              (typeof uc.id === 'number' ? uc.id > 0 : uc.id),
          ),
        ),
      },
    });

    // Request Save
    setUpdated(true);
  };

  const fetchList = useCallback(
    (checkFields: any): void => {
      const listData: ICheckListModel[] | undefined = Array.from(
        JSON.parse(checkData),
      );
      const listOptions: ICheckListModel[] | undefined = Array.from(
        JSON.parse(checkFields),
      ).map((check: any) => {
        let model: ICheckListModel = {
          id: check.id,
          name: check.name,
          state: false,
        };

        if (listData && listData.length > 0) {
          const findObj = listData.find(
            (dataObj: ICheckListModel) =>
              dataObj.id === model.id && dataObj.name === model.name,
          );
          model = findObj ? { ...findObj } : model;
        }

        return model;
      });

      setCheckItems(listOptions);
    },
    [checkData],
  );

  const lazyOnClick = useCallback((): void => {
    setTimeout(() => {
      onBlur();
    }, 500);
  }, [onBlur]);

  useEffect(() => {
    if (updated) {
      // Saved
      lazyOnClick();
      setUpdated(false);
    }
  }, [updated, setUpdated, lazyOnClick]);

  useEffect(() => {
    if (render && JSON.parse(checkList)?.length > 0) {
      fetchList(checkList);
      setRender(false);
    }
  }, [checkList, fetchList, checkItems, render]);

  return (
    <>
      {checkItems.length > 0 && (
        <Grid container className="inspectionForm__row" spacing={2}>
          {checkItems.map((checkField: ICheckListModel, index: number) => {
            return (
              <Grid key={`form-field-${index}`} item md={6} xs={12}>
                <FormControlLabel
                  key={checkField.id}
                  control={
                    <Checkbox
                      name={String(checkField.id)}
                      onChange={triggerChanges}
                      checked={Boolean(checkField.state)}
                      color="primary"
                      disabled={isDisabled}
                      value={Boolean(checkField.state)}
                      sx={{
                        color: error ? '#ff0000' : 'default',
                      }}
                    />
                  }
                  label={checkField.name}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default CheckboxList;
