import { IInspectionContext, InspectionContext } from 'context/inspections';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AAPRequest } from 'types/aap';
import { getAAPData } from 'context/inspections';
import { ActionType } from 'types/action';

const AAPForm: FunctionComponent = (): JSX.Element => {
  const ref = useRef(null);
  const {
    state: { currentInspection: inspection, aapFormRefresh },
    dispatch,
  } = useContext(InspectionContext) as IInspectionContext;
  const [aapData, setAapData] = useState(null);
  const [frameLoaded, setFrameLoaded] = useState(false);
  const [initRequest, setInitRequest] = useState(true);

  useEffect(() => {
    if (aapData) {
      return;
    }
    (async () => {
      if (initRequest) {
        const aapRequestData: AAPRequest = {
          apiKey: inspection?.APIKey !== undefined ? inspection?.APIKey : '',
          countryCode:
            inspection?.CountryCode !== undefined
              ? inspection?.CountryCode
              : '',
          eventFormId:
            inspection?.EventId !== undefined ? inspection?.EventId : 0,
          eventId:
            inspection?.EventId !== undefined
              ? inspection?.EventId.toString()
              : '0',
          eventStateId:
            inspection?.EventStateId !== undefined
              ? inspection?.EventStateId
              : 0,
          reportDateTime:
            inspection?.registerDate !== undefined
              ? inspection?.registerDate
              : '',
          driverName:
            inspection?.driverName !== undefined ? inspection?.driverName : '',
          driverLastNames:
            inspection?.driverLastName !== undefined
              ? inspection?.driverLastName
              : '',
          driverEmail:
            inspection?.driverEmail !== undefined
              ? inspection?.driverEmail
              : '',
          vehicleLisencePlate:
            inspection?.plateMetallic !== undefined
              ? inspection?.plateMetallic
              : '',
          vehicleDef: inspection?.vehicleDefinition || 'A',
          eventRecord:
            inspection?.EventRecord !== undefined
              ? inspection?.EventRecord
              : '',
          formulario: '',
          aapData: '',
        };

        setInitRequest(false);
        const data: any = await getAAPData(aapRequestData);
        data.eventId =
          inspection?.EventRecord !== undefined ? inspection?.EventRecord : '';

        // When the Vehicle definition is change
        if (aapFormRefresh) {
          data.typeData = 'aap-init';
          dispatch({
            type: ActionType.SET_REFRESH_AAP_IFRAME,
            payload: false,
          });
        }

        setAapData(data);
      }
    })();
  }, [initRequest, aapData, inspection, aapFormRefresh, dispatch]);

  useEffect(() => {
    if (!aapData || !frameLoaded || !ref) {
      return;
    }

    // Get AAP Data if available
    const element: HTMLIFrameElement = document.getElementById(
      'aapFrame',
    ) as HTMLIFrameElement;

    const iframe = element.contentWindow;
    if (iframe !== null) {
      // eslint-disable-next-line no-console
      // console.log('AAP_PostMessage :: ', aapData);
      iframe.postMessage(JSON.stringify(aapData), '*');
    }

    if (ref.current && (ref.current as any).postMessage) {
      (ref.current as any).postMessage(aapData, '*');
    }
  }, [aapData, frameLoaded, ref]);

  return (
    <iframe
      ref={ref}
      title="aapFrame"
      id="aapFrame"
      onLoad={() => setFrameLoaded(true)}
      src={process.env.REACT_APP_IFRAME_AAP}
      style={{ width: '100%', height: '80vh' }}
    ></iframe>
  );
};

export default AAPForm;
