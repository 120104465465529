import React from 'react';
import clsx from 'clsx';

import LoaderImg from 'assets/gifs/loader.gif';
import './styles.scss';

interface Props {
  isActive: boolean;
}

const Loader = (props: Props): JSX.Element => {
  const overlayStatus = props.isActive ? 'loader--on' : 'loader--off';

  return (
    <div className={clsx('loader', overlayStatus)}>
      <img className="loader__icon" src={LoaderImg} alt="SIC" />
    </div>
  );
};

export default Loader;
