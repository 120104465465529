import { CountryCode } from 'types/common';

const locations = (): any => {
  const locationData = localStorage.getItem('locations');
  const objLocations = locationData ? JSON.parse(locationData) : {};

  return objLocations;
};

/* eslint-disable @typescript-eslint/no-unused-vars */
const getProvinces = (): any => {
  const objLocations = locations();
  let strProvinces = '';
  Array.from(objLocations.provinces || []).forEach((prov: any) => {
    const newData = prov.id + '|' + prov.name;
    strProvinces += strProvinces.length > 0 ? '¬' + newData : newData;
  });

  return strProvinces.length > 0 ? strProvinces : '0|';
};

const getCantons = (code: string, province?: number): any => {
  const objLocations = locations();
  let cantons: any = [];
  let strCantons = '';

  const hasCanton = (canton: any) => {
    return canton.id === +code;
  };

  Array.from(objLocations.provinces || []).forEach(
    (prov: any, index: number) => {
      const currentCantons = objLocations.provinces[index].cantons;
      const filterData = currentCantons.find(hasCanton);

      if (province && province > 0 && province === prov.id) {
        cantons = currentCantons;
      } else if (!province && filterData && filterData.id > 0) {
        cantons = currentCantons;
      }
    },
  );

  if (cantons) {
    Array.from(cantons).forEach((cant: any) => {
      const newData = cant.id + '|' + cant.name;
      strCantons += strCantons.length > 0 ? '¬' + newData : newData;
    });
  }

  return strCantons.length > 0 ? strCantons : '0|';
};

const getDistricts = (code: string, canton?: number): any => {
  const objLocations = locations();
  let districts: any = [];
  let strDistricts = '';

  const hasDistrict = (district: any) => {
    return district.id === +code;
  };

  Array.from(objLocations.provinces || []).forEach(
    (prov: any, index: number) => {
      Array.from(objLocations.provinces[index].cantons).forEach((cant: any) => {
        const currentDistricts = cant.districts;
        const filterData = currentDistricts.find(hasDistrict);

        if (canton && canton > 0 && canton === cant.id) {
          districts = currentDistricts;
        } else if (!canton && filterData && filterData.id > 0) {
          districts = currentDistricts;
        }
      });
    },
  );

  if (districts) {
    Array.from(districts).forEach((dist: any) => {
      const newData = dist.id + '|' + dist.name;
      strDistricts += strDistricts.length > 0 ? '¬' + newData : newData;
    });
  }

  return strDistricts.length > 0 ? strDistricts : '0|';
};

const getRunners = (): any => {
  const runnerData = localStorage.getItem('runners');
  const objRunners = runnerData ? JSON.parse(runnerData) : {};

  return objRunners;
};

const getCountryFormat = (): any => {
  const countryCode = localStorage.getItem('countryCode');
  const data = {
    dateTimeFormat: 'MM/DD/YYYY',
  };

  switch (countryCode) {
    case CountryCode.PAN:
    case CountryCode.CRC:
      data.dateTimeFormat = 'DD/MM/YYYY';
      break;
    default:
      break;
  }

  return data;
};

const circunstances = [
  {
    id: 1,
    label: 'Circulaba sobre vía principal o Rotonda',
    value: 'CSVP',
    vehicleA: {
      name: 'insurance',
      code: 'CSVP1',
    },
    vehicleB: {
      name: 'third',
      code: 'CSVP2',
    },
  },
  {
    id: 2,
    label: 'Circulaba por vía secundaria',
    value: 'CSVS',
    vehicleA: {
      name: 'insurance',
      code: 'CSVS1',
    },
    vehicleB: {
      name: 'third',
      code: 'CSVS2',
    },
  },
  {
    id: 3,
    label: 'Circulaba a la izquierda en intersección',
    value: 'CIZINIA',
    vehicleA: {
      name: 'insurance',
      code: 'CIZINIA1',
    },
    vehicleB: {
      name: 'third',
      code: 'CIZINIA2',
    },
  },
  {
    id: 4,
    label: 'Circulaba a la derecha en intersección',
    value: 'CDEIN',
    vehicleA: {
      name: 'insurance',
      code: 'CDEIN1',
    },
    vehicleB: {
      name: 'third',
      code: 'CDEIN2',
    },
  },
  {
    id: 5,
    label: 'Choqué en la parte de atrás al otro vehículo (Alcance)',
    value: 'CAOV',
    vehicleA: {
      name: 'insurance',
      code: 'CAOV1',
    },
    vehicleB: {
      name: 'third',
      code: 'CAOV2',
    },
  },
  {
    id: 6,
    label: 'Me incorporaba a la vía',
    value: 'MIC',
    vehicleA: {
      name: 'insurance',
      code: 'MIC1',
    },
    vehicleB: {
      name: 'third',
      code: 'MIC2',
    },
  },
  {
    id: 7,
    label:
      'Daba vuelta (izquierda o derecha) y no tomé el extremo correspondiente',
    value: 'DVIZDE',
    vehicleA: {
      name: 'insurance',
      code: 'DVIZDE1',
    },
    vehicleB: {
      name: 'third',
      code: 'DVIZDE2',
    },
  },
  {
    id: 8,
    label: 'Circulaba en retroceso sobre la vía',
    value: 'CR',
    vehicleA: {
      name: 'insurance',
      code: 'CR1',
    },
    vehicleB: {
      name: 'third',
      code: 'CR2',
    },
  },
  {
    id: 9,
    label:
      'Circulaba en sentido contrario con o sin demarcación (Rebasando o no)',
    value: 'CSC',
    vehicleA: {
      name: 'insurance',
      code: 'CSC1',
    },
    vehicleB: {
      name: 'third',
      code: 'CSC2',
    },
  },
  {
    id: 10,
    label: 'Circulaba sobre carril exclusivo',
    value: 'CCE',
    vehicleA: {
      name: 'insurance',
      code: 'CCE1',
    },
    vehicleB: {
      name: 'third',
      code: 'CCE2',
    },
  },
  {
    id: 11,
    label: 'Cambiaba de carril',
    value: 'CC',
    vehicleA: {
      name: 'insurance',
      code: 'CC1',
    },
    vehicleB: {
      name: 'third',
      code: 'CC2',
    },
  },
  {
    id: 12,
    label: 'Salida de estacionamiento y/o cochera',
    value: 'SEC',
    vehicleA: {
      name: 'insurance',
      code: 'SEC1',
    },
    vehicleB: {
      name: 'third',
      code: 'SEC2',
    },
  },
  {
    id: 13,
    label: 'Tenía la puerta abierta o abría la puerta',
    value: 'PA',
    vehicleA: {
      name: 'insurance',
      code: 'PA1',
    },
    vehicleB: {
      name: 'third',
      code: 'PA2',
    },
  },
  {
    id: 14,
    label:
      'Circulaba vehículo de emergencia con códigos abiertos (sirena, luces, altavoz)',
    value: 'VECA',
    vehicleA: {
      name: 'insurance',
      code: 'VECA1',
    },
    vehicleB: {
      name: 'third',
      code: 'VECA2',
    },
  },
  {
    id: 15,
    label: 'Irrespeté la señal restrictiva',
    value: 'PSR',
    vehicleA: {
      name: 'insurance',
      code: 'PSR1',
    },
    vehicleB: {
      name: 'third',
      code: 'PSR2',
    },
  },
  {
    id: 16,
    label: 'Di vuelta en "U" con demarcación o sin demarcación',
    value: 'VU',
    vehicleA: {
      name: 'insurance',
      code: 'VU1',
    },
    vehicleB: {
      name: 'third',
      code: 'VU2',
    },
  },
];

export const availableCoverages = [
  {
    id: 1,
    name: 'Comprensivo',
  },
  {
    id: 2,
    name: 'Colisión o Vuelco',
  },
  {
    id: 3,
    name: 'Lesiones Corporales',
  },
  {
    id: 4,
    name: 'Daños a la Propiedad Ajena',
  },
  {
    id: 5,
    name: 'Gastos Médicos',
  },
  {
    id: 6,
    name: 'Incendio/Robo',
  },
  {
    id: 7,
    name: 'Asegurado',
  },
  {
    id: 8,
    name: 'Contraparte',
  },
];

export const aapSelectOptions = [
  {
    id: 4,
    description: 'Motocicleta',
  },
  {
    id: 5,
    description: 'Documentación vencida-no porta Asegurado',
  },
  {
    id: 6,
    description: 'Inspección se realiza en otro momento o lugar',
  },
  {
    id: 7,
    description: 'Tercero culpable No asegurado',
  },
  {
    id: 8,
    description: 'Otro (Ver opciones adicionales)',
  },
  {
    id: 9,
    description: 'Las partes no estuvieron de acuerdo',
  },
  {
    id: 10,
    description: 'No hay tercero',
  },
  {
    id: 12,
    description: 'Colisión contra objeto fijo',
  },
  {
    id: 13,
    description: 'Boleta de tránsito',
  },
];

export const aapSelectOtherOptions = [
  {
    id: 2,
    description: 'Heridos-Lesionados',
  },
  {
    id: 3,
    description: 'Alcohol-Sustancias Psicotrópicas',
  },
  {
    id: 11,
    description: 'Vandalismo',
  },
  {
    id: 14,
    description: 'Asegurado no tomó fotografías del accidente',
  },
];

export default {
  getProvinces,
  getCantons,
  getDistricts,
  getRunners,
  getCountryFormat,
  circunstances,
  availableCoverages,
  aapSelectOptions,
  aapSelectOtherOptions,
};
