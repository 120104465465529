import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';
import {
  MoreVertOutlined,
  ReportProblemOutlined,
  Insights,
} from '@mui/icons-material';
import clsx from 'clsx';
import Chip from 'components/common/chip/Chip';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import useDevice from 'shared/hooks/useDevice';
import React, { Fragment, useContext, useState } from 'react';
import { userActionsPermissions } from 'shared/utils';
import { EVENT_STATUSES } from 'static/constants/event-status';
import { MENU_PREINSPECTION } from 'static/constants/events-grid';
import {
  BrandsId,
  IGrid,
  ResourceAction,
  TransitionStatesIds,
} from 'types/common';
import { IPreInspection } from 'types/pre-inspection';
import TableMenu, { MenuOptionPI } from './menu';
import styles from './override-mui-styles';
import './styles.scss';
import {
  getPreinspectTrackData,
  IPreInspectionContext,
  PreInspectionContext,
} from 'context/preinspections';

interface StatusProps {
  value: string;
  label: string;
  colorClass: string;
}

interface Props {
  preInspections: Array<IPreInspection>;
  page: number;
  totalElements: number;
  onClickEditView(row: any, partnerKey: string, preId: number): any;
  onChangePage: (event: unknown, newPage: number) => void;
  reloadEvents?: () => void;
}

const eventAccessPermission = (): boolean => {
  return Boolean(userActionsPermissions(ResourceAction.VIEW_EVENT_DETAIL));
};

const getCurrentGridColumns = (): IGrid[] => {
  const columnsStr = localStorage.getItem('gridColumnsPreInspection') ?? '';
  if (columnsStr.length > 0) {
    return Array.from(JSON.parse(columnsStr));
  }

  return [];
};

const SHOW_ALWAYS = ['statusName', 'preId', 'vehiclePlate', 'showAlert'];

const EventsTable = (props: Props): JSX.Element => {
  const { dispatch } = useContext<IPreInspectionContext>(PreInspectionContext);
  const classes = styles();
  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);
  const [selectedRow, setSelectedRow] = useState<null | {
    el: HTMLElement;
    event: any;
  }>(null);

  const { isDesktop, isMobile } = useDevice();

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    event: IPreInspection,
  ) => {
    setSelectedRow({ el: e.currentTarget, event });
  };

  const handleClose = () => {
    setSelectedRow(null);
  };

  const setStatusId = (statusId: number) => {
    let newStatusId = -1;
    let menuOption = 0;
    if (statusId == null) {
      newStatusId = 0;
    } else {
      newStatusId = statusId;
    }
    if (
      newStatusId === TransitionStatesIds.COMPLETED ||
      newStatusId === TransitionStatesIds.PROCESSED ||
      newStatusId === TransitionStatesIds.FINISHED
    ) {
      menuOption = 1;
    }

    return menuOption;
  };

  const StatusChip = (eventStatus: string) => {
    const eventStatusProps: StatusProps | undefined = Object.values<
      StatusProps
    >(EVENT_STATUSES).find(
      (status: StatusProps) => status.value === eventStatus,
    );

    if (eventStatusProps) {
      return isMobile ? (
        <div
          className={clsx(
            'status-mobile',
            eventStatusProps.colorClass &&
              `status-mobile--${eventStatusProps.colorClass}`,
          )}
        ></div>
      ) : (
        <Chip
          label={eventStatusProps.label}
          color={eventStatusProps.colorClass}
          compact
        />
      );
    }

    return <></>;
  };

  const columnActionPermission = Boolean(
    userActionsPermissions(ResourceAction.USE_COLUMN_ACTIONS),
  );

  const rowMenuWithPermission = (statusId: number): MenuOptionPI[] => {
    const linkPermission = userActionsPermissions(ResourceAction.USE_LINK_FORM);
    const cancelPermission = userActionsPermissions(
      ResourceAction.USE_BTN_CANCEL_EVENT,
    );

    const menu = MENU_PREINSPECTION;

    menu.forEach((fe: MenuOptionPI) => {
      if (fe.value === 'CANCEL') {
        fe.isActive = cancelPermission;
      } else if (fe.value === 'OPEN_FORM') {
        fe.isActive = linkPermission;
      }
      if (
        fe.value === 'DOWNLOAD_PREINSPECTION' &&
        (setStatusId(statusId) === 1 || setStatusId(statusId) === 2)
      ) {
        fe.isActive = true;
      }
    });

    return menu;
  };

  const toggleSelectedEvents = (index: number) => {
    const indexes = [...selectedEvents];
    const found = indexes.indexOf(index);
    if (found !== -1) {
      indexes.splice(found, 1);
    } else {
      indexes.push(index);
    }

    setSelectedEvents(indexes.sort());
  };

  const addSelectedEvents = (index: number) => {
    const indexes = [...selectedEvents];
    if (indexes.length === 0) {
      // add index to empty array
      indexes.push(index);
    } else if (index < indexes[0]) {
      // index is lt first element
      const first = indexes[0];
      const length = first - index;
      for (let x = index; x <= length; x++) {
        indexes.push(x);
      }
    } else if (index < Number(indexes.slice(-1).pop())) {
      // index is between first and last element
      const newLast = indexes.indexOf(index);
      indexes.splice(newLast + 1);
    } else {
      // index is gt first element
      const first = indexes[0];
      for (let x = first; x <= index; x++) {
        indexes.push(x);
      }
    }
    setSelectedEvents(
      indexes.sort().filter((v, i, self) => self.indexOf(v) === i),
    );
  };

  const rowClick = (
    e: React.MouseEvent,
    eventIndex: number,
    partnerKey: string,
    uniqueId: string,
    preId: number,
  ) => {
    if ((e.metaKey || e.ctrlKey) && e.shiftKey) {
      addSelectedEvents(eventIndex);
    } else if (e.metaKey || e.ctrlKey) {
      toggleSelectedEvents(eventIndex);
    } else if (eventAccessPermission()) {
      props.onClickEditView(e, partnerKey, preId);
    }
  };

  const headerColumns = (classes: any): JSX.Element => {
    return (
      <>
        {isDesktop && columnActionPermission && (
          <TableCell key="menu" className={classes.header}>
            Acciones
          </TableCell>
        )}

        {getCurrentGridColumns()
          .sort((first, second) => 0 - (first.sortId > second.sortId ? -1 : 1))
          .map((g: IGrid, index: number) => {
            if (isDesktop || SHOW_ALWAYS.includes(g.columnDataName)) {
              return (
                <TableCell
                  key={`${g.columnDataName}-${index}`}
                  className={classes.header}
                >
                  {g.columnName}
                </TableCell>
              );
            }

            return null;
          })}
      </>
    );
  };

  const bodyColumns = (events: IPreInspection[]): JSX.Element => {
    return (
      <>
        {events.map((event: any, index: number) => {
          return (
            <TableRow
              key={`${event.PreInspectionId}-${index}`}
              className={clsx(
                classes.rowSelect,
                selectedEvents.includes(index) && 'selected',
              )}
              onClick={(e) =>
                rowClick(
                  e,
                  index,
                  event.apiKey,
                  event.uniqueId,
                  event.preInspectionId,
                )
              }
            >
              {isDesktop && columnActionPermission && (
                <TableCell
                  align="center"
                  className={clsx(classes.cell, classes.smallCell)}
                >
                  <IconButton onClick={(e) => handleClick(e, event)}>
                    <MoreVertOutlined className={classes.menuIcon} />
                  </IconButton>
                  {event.companyId && event.companyId === BrandsId.INS && (
                    <IconButton
                      color="primary"
                      onClick={(e) => getPreinspectTrackData(event, dispatch)}
                    >
                      <Insights />
                    </IconButton>
                  )}

                  {selectedRow && (
                    <TableMenu
                      anchorElement={selectedRow.el}
                      menuOptions={rowMenuWithPermission(
                        selectedRow.event.statusId,
                      )}
                      onClickMenu={(e) => handleClick(e, event)}
                      onCloseMenu={handleClose}
                      currentValue={selectedRow.event}
                    />
                  )}
                </TableCell>
              )}

              {getCurrentGridColumns()
                .sort(
                  (first, second) =>
                    0 - (first.sortId > second.sortId ? -1 : 1),
                )
                .map((g: IGrid, index: number) => {
                  if (isDesktop || SHOW_ALWAYS.includes(g.columnDataName)) {
                    return (
                      <Fragment key={`${g.columnDataName}-${index}`}>
                        {g.columnDataName === 'statusName' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.cell, classes.mediumCell)}
                          >
                            {StatusChip(event.statusName)}
                          </TableCell>
                        )}

                        {g.columnDataName === 'showAlert' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.cell, classes.mediumCell)}
                          >
                            {event.showAlert && (
                              <Tooltip title="Alertas pendientes de revisión">
                                <ReportProblemOutlined className="warn" />
                              </Tooltip>
                            )}
                          </TableCell>
                        )}

                        {g.columnDataName === 'creationDate' && (
                          <TableCell
                            align="center"
                            className={clsx(classes.cell, classes.dateCell)}
                          >
                            {format(
                              Date.parse(event.creationDate),
                              'dd/MM/yyyy HH:mm:ss',
                              {
                                locale: esLocale,
                              },
                            )}
                          </TableCell>
                        )}

                        {!['statusName', 'creationDate', 'showAlert'].includes(
                          g.columnDataName,
                        ) && (
                          <TableCell align="center" className={classes.cell}>
                            {event[g.columnDataName]}
                          </TableCell>
                        )}
                      </Fragment>
                    );
                  }

                  return null;
                })}
            </TableRow>
          );
        })}
      </>
    );
  };

  return (
    <>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={props.totalElements || -1}
        rowsPerPage={20}
        page={props.page}
        onPageChange={props.onChangePage}
      />
      <TableContainer className="table-container">
        <Table
          className={classes.table}
          size={isDesktop ? 'medium' : 'small'}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>{headerColumns(classes)}</TableRow>
          </TableHead>
          <TableBody>{bodyColumns(props.preInspections)}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EventsTable;
