import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import NotFoundImg from 'assets/images/404-not-found.png';
import './styles.scss';

const NotAccessRequest = (): JSX.Element => {
  return (
    <div className="not-found">
      <Grid container spacing={3}>
        <Grid item xs={1} sm={3}></Grid>
        <Grid item xs={10} sm={6}>
          <Card>
            <CardActionArea>
              <CardMedia
                className="nf-img"
                component="img"
                alt="404 Not Found"
                image={NotFoundImg}
                title="404 Not Found"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  align="center"
                >
                  ¡Inspección no encontrada!
                </Typography>
                <br />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="justify"
                >
                  ¡Oh no! Hay un problema con la configuración de la inspección.
                  <br />
                  <br />
                  Favor de contactarnos para que puedas continuar con la
                  recopilación del incidente.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={1} sm={3}></Grid>
      </Grid>
    </div>
  );
};

export default NotAccessRequest;
