import React, { createRef, useContext, useEffect, useState } from 'react';
import imageCompression from 'browser-image-compression';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, Snackbar } from '@mui/material';
import clsx from 'clsx';
import APIClient, { endpoints } from 'shared/api';
import { exifDataFromImage, getFileInfo } from 'shared/utils';
import {
  setLoading,
  IInspectionContext,
  InspectionContext,
  saveEventPhoto,
  updateImageSection,
  uploadInspectionPhotos,
  rotateImage,
  uploadMediaFile,
} from 'context/inspections';
import {
  deleteMediaFiles,
  IPreInspectionContext,
  PreInspectionContext,
  saveMediaFiles,
  uploadMediaFiles,
} from 'context/preinspections';
import { ReactComponent as UploadImage } from 'assets/icons/upload-image.svg';
import { ReactComponent as TicketImage } from 'assets/icons/boleta-icon.svg';
import { ReactComponent as TrafficTicketImage } from 'assets/icons/parte-icon.svg';
import { EventPhoto, MediaSection, PhotoSection } from 'types/images';
import deleteIcon from '../../../../assets/images/modal_delete_image.svg';
import DropArea from '../dnd/DropArea';
import './../styles.scss';
import Thumbnail from './Thumbnail';
import Gallery from './Gallery';
import { ActionType } from 'types/action';
import { BrandsId, IRotateImage, Rol } from 'types/common';
import { FieldSection } from 'types/section-fields';

interface SectionConfig {
  id: PhotoSection | MediaSection;
  title: string;
  photos?: EventPhoto[];
  allPhotos: EventPhoto[];
  selectedPhotos: string[];
  previousActivePhoto: string;
  imageForZoom: string;
  setImageForZoom: (source: string) => void;
  setSelectedPhotos: (imageUrls: string[]) => void;
  setPreviousActivePhoto: (imageUrl: string) => void;
  handleSavePhoto: () => void;
  handleRemovePhoto: (photoURI: string) => void;
  handleRefreshPhotos: () => void;
  customPhotos?: EventPhoto[];
  folderRoot?: string;
  sectionType: string;
  isReadOnly: boolean;
  setSections?: (sections: FieldSection[]) => void;
  sections?: FieldSection[];
  eventRecord?: string;
}

const SectionPhotos = (props: SectionConfig): JSX.Element => {
  const fileInputRef = createRef<HTMLInputElement>();
  const fileInputRefCustomTicket = createRef<HTMLInputElement>();
  const fileInputRefCustomTraffic = createRef<HTMLInputElement>();
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const { state: preInspectState, dispatch: dispatchPreInspect } = useContext<
    IPreInspectionContext
  >(PreInspectionContext);

  const [openModal, setOpenModal] = useState(false);
  const [updatePhotos, setUpdatePhotos] = useState(false);
  const [refreshPhotos, setRefreshPhotos] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [photoToDelete, setPhotoToDelete] = useState('');
  const [showImageAlert, setShowImageAlert] = useState(false);
  const [photoVersionChange, setPhotoVersionChange] = useState(0);

  const {
    selectedPhotos,
    setSelectedPhotos,
    previousActivePhoto,
    setPreviousActivePhoto,
  } = props;

  const galleryInteractionPermission = (): boolean => {
    const currentRoleId = localStorage.getItem('rolId') ?? '0';
    const companyid = localStorage.getItem('companyId');
    if (+currentRoleId > 0) {
      if (
        currentRoleId === Rol.INSURER.toString() ||
        (Rol.CLAIMS_ASSISTANT.toString() &&
          companyid === BrandsId.ACERTA.toString())
      ) {
        return +currentRoleId !== Rol.CUSTOMER && !props.isReadOnly;
      } else {
        return (
          +currentRoleId !== Rol.INSURER &&
          +currentRoleId !== Rol.CLAIMS_ASSISTANT &&
          +currentRoleId !== Rol.CUSTOMER &&
          !props.isReadOnly
        );
      }
    }

    return true;
  };

  const handleRotateImageClick = async (
    source: string | undefined,
    rotation: number | undefined,
  ) => {
    if (galleryInteractionPermission()) {
      const sendUpdate = {
        imageURL: source,
        rotateSide: rotation || 0,
      };
      const {
        data: { data },
      } = await rotateImage(sendUpdate);
      props.setImageForZoom(data);
      props.handleRefreshPhotos();
      setPhotoVersionChange(photoVersionChange + 1);
    }
  };

  const handlePictureSelectorClicked = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCustomPictureTicketClicked = () => {
    if (fileInputRefCustomTicket.current) {
      fileInputRefCustomTicket.current.click();
    }
  };

  const handleCustomPictureTrafficClicked = () => {
    if (fileInputRefCustomTraffic.current) {
      fileInputRefCustomTraffic.current.click();
    }
  };

  const handleCustomPictureAllClicked = (type: string) => {
    const currentCustomPhotos = enableCustomPhotos();
    const currentRef = currentCustomPhotos.filter(
      (f: EventPhoto) => f.imageType?.toUpperCase() === type.toUpperCase(),
    )[0];

    if (currentRef && currentRef.inputRef && currentRef.inputRef.current) {
      currentRef.inputRef.current.click();
    }
  };

  const deleteImage = async (imageToDelete: string): Promise<void> => {
    setLoading(true, dispatch);
    const url = imageToDelete;

    let eventNumber: string | undefined = undefined;

    if (inspectionState.currentInspection?.EventRecord) {
      eventNumber = inspectionState.currentInspection?.EventRecord.toString();
    } else {
      eventNumber = props.eventRecord;
    }

    const data = {
      countryCode: 'PRI',
      imageUrl: url.toString(),
    };
    const Response = await APIClient.delete(
      endpoints.DELETE_IMAGE.replace(
        '{eventRecord}',
        eventNumber != null ? eventNumber : '',
      ),
      { data: data },
    );
    if (Response) {
      const newListPhotos =
        inspectionState?.currentImages != null
          ? Array.from(inspectionState?.currentImages)
          : [];
      let photoIndex = -1;
      for (let i = 0; i < newListPhotos.length; i++) {
        if (newListPhotos[i].awsUrl === url.toString()) {
          photoIndex = i;
        }
      }
      newListPhotos.splice(photoIndex, 1);
      saveEventPhoto(newListPhotos, dispatch);

      //Update rotate images
      const listRotatePhotos =
        inspectionState?.rotateImages != null
          ? Array.from(inspectionState?.rotateImages)
          : [];

      const newListRotatePhotos = listRotatePhotos.filter(
        (f: IRotateImage) =>
          f.imageURL.length > 0 && url.indexOf(f.imageURL.split('?')[0]) === -1,
      );

      dispatch({
        type: ActionType.SET_LIST_FOR_ROTATE_IMAGES,
        payload: {
          data: newListRotatePhotos,
          forceUpdate: true,
        },
      });
    }
  };

  const deleteMedia = async (url: string): Promise<void> => {
    setLoading(true, dispatch);
    const response = await deleteMediaFiles(url);
    if (response) {
      const newListPhotos =
        preInspectState?.currentMediaFiles != null
          ? Array.from(preInspectState?.currentMediaFiles)
          : [];
      let photoIndex = -1;
      for (let i = 0; i < newListPhotos.length; i++) {
        if (newListPhotos[i].awsUrl === url.toString()) {
          photoIndex = i;
        }
      }
      newListPhotos.splice(photoIndex, 1);
      saveMediaFiles(newListPhotos, dispatchPreInspect);
    }
    setLoading(false, dispatch);
  };

  const thumbnailSingleClick = (
    imageSource: string,
    shiftKeyActive: boolean,
  ) => {
    const allPhotos = props.allPhotos || [];

    if (!props.isReadOnly) {
      if (shiftKeyActive && imageSource !== previousActivePhoto) {
        const activeCardIndex = allPhotos.findIndex((p) =>
          [p.awsUrl, p.dataURL].includes(previousActivePhoto),
        );
        const cardIndex = allPhotos.findIndex((p) =>
          [p.awsUrl, p.dataURL].includes(imageSource),
        );
        const lowerIndex = Math.min(activeCardIndex, cardIndex);
        const upperIndex = Math.max(activeCardIndex, cardIndex);

        setSelectedPhotos(
          allPhotos
            .slice(lowerIndex, upperIndex + 1)
            .map((p) => p.awsUrl || p.dataURL),
        );
      } else {
        if (selectedPhotos.indexOf(imageSource) > -1) {
          setSelectedPhotos(
            selectedPhotos.filter((imageUrl) => imageUrl !== imageSource),
          );
        } else {
          setSelectedPhotos([...selectedPhotos, imageSource]);
        }
        setPreviousActivePhoto(imageSource);
      }
    }
  };

  const thumbnailDoubleClick = (imageSource: string) => {
    props.setImageForZoom(imageSource);
    setOpenModal(true);
  };

  const readCurrentPhotos = (): EventPhoto[] => {
    return props.photos != null ? Array.from(props.photos) : [];
  };

  const enableCustomPhotos = (): EventPhoto[] => {
    const customPhotoFilter: EventPhoto[] = [];
    const customTypesArray = props.customPhotos
      ? props.customPhotos.map(({ imageType }) => imageType)
      : [];

    const resultArray: any[] = [];
    customTypesArray.reduce(function (res: any, value: any) {
      if (value) {
        if (!res[value]) {
          res[value] = { Id: value, qty: 0 };
          resultArray.push(res[value]);
        }
        res[value].qty += 1;
      }

      return res;
    }, {});

    if (resultArray) {
      resultArray.forEach((templatePhoto: any) => {
        let countNotInList = 0;
        const currentPhotos = readCurrentPhotos();
        countNotInList = currentPhotos.filter(
          (f: EventPhoto) =>
            f.imageName &&
            String(f.imageName)
              .toLowerCase()
              .indexOf(String(templatePhoto.Id).toLowerCase()) > -1,
        ).length;

        if (props.customPhotos && countNotInList < templatePhoto.qty) {
          const template = props.customPhotos.filter(
            (f: EventPhoto) =>
              f.imageType?.toLowerCase() ===
              String(templatePhoto.Id).toLowerCase(),
          )[0];
          for (let _i = 0; _i < templatePhoto.qty - countNotInList; _i++) {
            customPhotoFilter.push(template);
          }
        }
      });
    }

    return customPhotoFilter;
  };

  const progressCallback = (current: number, total: number) => {
    dispatch({
      type: ActionType.SET_PHOTO_ALERT,
      payload: {
        showAlertPhoto: true,
        alertMessagePhoto: `Cargando imágenes en proceso ${current} de ${total} ...`,
        alertTypePhoto: 'warning',
      },
    });
    dispatch({
      type: ActionType.SET_COUNT_PROGREES_UPLOAD_IMAGES,
      payload: {
        totalUploadImages: total,
        countUploadImages: current,
      },
    });
  };

  const progressCallbackEnd = () => {
    dispatch({
      type: ActionType.SET_PHOTO_ALERT,
      payload: {
        showAlertPhoto: true,
        alertMessagePhoto: 'Se finalizo el proceso de cargue de imágenes',
        alertTypePhoto: 'success',
      },
    });
  };

  const handleInputFileChange = async (
    { target: { files: fileList } }: React.ChangeEvent<HTMLInputElement>,
    type: string | undefined,
  ) => {
    if (!fileList || !galleryInteractionPermission()) {
      return;
    }
    setLoading(true, dispatch);
    dispatch({
      type: ActionType.SET_UPLOAD_PROGRESS,
      payload: {
        inProgress: true,
      },
    });
    let statePhotos =
      inspectionState?.currentImages != null
        ? Array.from(inspectionState?.currentImages)
        : [];

    if (props.sectionType === 'PRE-INSPECTIONS') {
      statePhotos =
        preInspectState?.currentMediaFiles != null
          ? Array.from(preInspectState?.currentMediaFiles)
          : [];
    }

    const files = Array.from(fileList);

    if (files.length > 15) {
      setShowImageAlert(true);
      setLoading(false, dispatch);

      return;
    }

    const photos: EventPhoto[] = [];

    dispatch({
      type: ActionType.SET_PHOTO_ALERT,
      payload: {
        showAlertPhoto: true,
        alertMessagePhoto: `Cargando imágenes en proceso 0 de ${files.length} ...`,
        alertTypePhoto: 'warning',
      },
    });

    // Procesar y almacenar las imágenes en paralelo
    await Promise.all(
      files.map(async (file) => {
        if (!file) {
          return;
        }
        // Validate if it is PDF or Video
        if (
          (file.type === 'application/pdf' ||
            (file.type === 'video/webm' &&
              props.id === PhotoSection.VEHICLE_VIDEO)) &&
          props.sectionType !== 'PRE-INSPECTIONS'
        ) {
          let eventNumber: string | undefined = undefined;

          if (!inspectionState.currentInspection?.EventRecord) {
            eventNumber = props.eventRecord;
          } else {
            eventNumber = inspectionState.currentInspection?.EventRecord.toString();
          }

          await uploadMediaFile(file, String(eventNumber), Number(props.id));
          props.handleRefreshPhotos();
        } else {
          const imageRecord = await processAndStoreImage(file, type);
          photos.push(imageRecord);
        }
      }),
    );

    setLoading(false, dispatch);
    if (props.sectionType === 'PRE-INSPECTIONS') {
      saveMediaFiles([...statePhotos, ...photos], dispatchPreInspect);
    } else {
      saveEventPhoto([...statePhotos, ...photos], dispatch);
    }

    await uploadImagesToServer(photos, statePhotos, progressCallback);

    setUpdatePhotos(true);
    setRefreshPhotos(true);
  };

  const processAndStoreImage = async (
    file: File,
    type: string | undefined,
  ): Promise<EventPhoto> => {
    const imgLocation = await exifDataFromImage(file);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    let updateFile = file;
    if (file.type.indexOf('image') > -1) {
      updateFile = await imageCompression(file, options);
    }

    const sFile = new File(
      [updateFile],
      type === 'default'
        ? file.name + imgLocation
        : type?.toLowerCase() + '_custom_' + file.name + imgLocation,
      { type: updateFile.type },
    );

    const imageRecord = await loadFile(sFile, imgLocation);

    return imageRecord;
  };

  const loadFile = async (
    file: File,
    locationString: string,
  ): Promise<EventPhoto> => {
    return new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        // Resolve model to retrieve
        if (props.sectionType === 'PRE-INSPECTIONS') {
          const fileInfo = getFileInfo(file.name);
          const fileName = `${props.id}_${MediaSection[props.id].toString()}_${
            fileInfo.fileName
          }`;

          resolve({
            dataURL: fileReader.result as string,
            section: props.id,
            imageName: fileName,
            imageType: fileInfo.fileExtension,
            location: locationString,
            isWaiting: true,
            //orientation: orientation,
          });
        } else {
          resolve({
            dataURL: fileReader.result as string,
            section: props.id,
            imageName: file.name,
            imageType: file.type,
            location: locationString,
            isWaiting: true,
            //orientation: orientation,
          });
        }
      };
      fileReader.readAsDataURL(file);
    });
  };

  const uploadImagesToServer = async (
    photos: EventPhoto[],
    statePhotos: any,
    progressCallback: (current: number, total: number) => void,
  ) => {
    console.warn(photos);
    try {
      if (
        props.sectionType === 'PRE-INSPECTIONS' &&
        preInspectState.currentPreInspection
      ) {
        await uploadMediaFiles(
          preInspectState.currentPreInspection?.preUniqueId,
          props.folderRoot ?? '',
          photos,
        );

        for (const index in photos) {
          photos[index].isWaiting = false;
        }

        saveMediaFiles([...statePhotos, ...photos], dispatchPreInspect);
        progressCallbackEnd();
      } else {
        let eventRecord: string | undefined = undefined;

        if (!inspectionState.currentInspection?.EventRecord) {
          eventRecord = props.eventRecord;
        } else {
          eventRecord = inspectionState.currentInspection?.EventRecord;
        }

        const uploadedPhotos = await uploadInspectionPhotos(
          photos,
          localStorage.getItem('countryCode'),
          eventRecord,
          dispatch,
          props.setSections,
          props.sections,
          progressCallback,
        );

        saveEventPhoto([...statePhotos, ...uploadedPhotos], dispatch);
        progressCallback(photos.length, photos.length);
        setTimeout(() => {
          progressCallbackEnd();
        }, 1000);
      }
    } catch (error) {
      dispatch({
        type: ActionType.SET_ERROR_MODAL,
        payload: {
          showErrorModalImages: true,
        },
      });
    } finally {
      dispatch({
        type: ActionType.SET_UPLOAD_PROGRESS,
        payload: {
          inProgress: false,
        },
      });
    }
  };

  const handlePhotoDrop = async (photo: EventPhoto, sectionId: number) => {
    if (
      galleryInteractionPermission() &&
      props.sectionType !== 'PRE-INSPECTIONS'
    ) {
      setLoading(true, dispatch);

      await new Promise((resolve) => {
        (async () => {
          const photos = [...(props.allPhotos || [])];
          const _selectedPhotos = [...selectedPhotos];
          if (!_selectedPhotos.length) {
            _selectedPhotos.push(photo.awsUrl || photo.dataURL);
          }

          for (const photoUrl of _selectedPhotos) {
            const i = photos.findIndex(
              (p) =>
                (p.awsUrl && photoUrl && p.awsUrl === photoUrl) ||
                (p.dataURL && photoUrl && p.dataURL === photoUrl),
            );

            photos[i].section = sectionId;
            if (
              photos[i].imageName &&
              (inspectionState.currentInspection?.EventRecord ||
                props.eventRecord)
            ) {
              const prefixName = sectionId + '_';
              const isValid = !photos[i].imageName?.startsWith(prefixName);

              let eventRecord;
              if (inspectionState.currentInspection?.EventRecord) {
                eventRecord = inspectionState.currentInspection.EventRecord;
              } else {
                eventRecord = props.eventRecord;
              }

              if (isValid) {
                const newUrl = await updateImageSection(
                  photos[i],
                  Number(eventRecord),
                );
                photos[i].awsUrl = newUrl;
                photos[i].imageName = newUrl.split('/').slice(-1).pop();
              }
            }
          }

          saveEventPhoto(photos, dispatch);
          props.handleRefreshPhotos();
          resolve(true);
        })();
      });

      setLoading(false, dispatch);
      setSelectedPhotos([]);
    }
  };

  const remove = async (): Promise<void> => {
    props.handleRemovePhoto('');
    if (props.sectionType === 'PRE-INSPECTIONS') {
      await deleteMedia(photoToDelete);
    } else {
      await deleteImage(photoToDelete);
    }
    setShowConfirmModal(false);
    setSelectedPhotos([]);
    setPhotoToDelete('');
  };

  const handleCustomPhotoClick = (photo: EventPhoto) => {
    if (photo.imageType && photo.imageType.toUpperCase() === 'TICKET_PHOTO') {
      handleCustomPictureTicketClicked();
    } else {
      handleCustomPictureTrafficClicked();
    }
  };

  useEffect(() => {
    if (updatePhotos) {
      props.handleSavePhoto();
      setUpdatePhotos(false);
    }

    if (refreshPhotos) {
      props.handleRefreshPhotos();
      setRefreshPhotos(false);
    }
  }, [props, updatePhotos, setUpdatePhotos, refreshPhotos, setRefreshPhotos]);

  useEffect(() => {
    setShowConfirmModal(Boolean(photoToDelete ? photoToDelete.length : 0));
  }, [photoToDelete]);

  return (
    <>
      <div
        key={`pictureRow-${props.id}`}
        className="inspectionForm__tabRow"
        id={`imageSection_${props.id}`}
      >
        <Accordion className="accordionSection" defaultExpanded={true}>
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className="inspectionForm__sectionTitle">
              {props.title}
            </Typography>
            <ExpandMoreIcon />
          </AccordionSummary>
          <AccordionDetails>
            <DropArea areaId={props.id} onDrop={handlePhotoDrop}>
              <Grid container>
                {props.id &&
                  photoVersionChange > -1 &&
                  readCurrentPhotos().map((p, index: number) => {
                    return (
                      <Thumbnail
                        key={index}
                        photo={p}
                        selectedPhotos={selectedPhotos}
                        onClick={thumbnailSingleClick}
                        onDeleteClick={(imageSource: string) => {
                          if (galleryInteractionPermission()) {
                            setPhotoToDelete(imageSource);
                            setShowConfirmModal(true);
                          }
                        }}
                        onDoubleClick={thumbnailDoubleClick}
                      />
                    );
                  })}
                {props.id === PhotoSection.OTHERS &&
                  enableCustomPhotos()
                    .sort(
                      (first, second) =>
                        0 -
                        (first.order &&
                        second.order &&
                        first.order > second.order
                          ? -1
                          : 1),
                    )
                    .map((p, index: number) => {
                      return (
                        <Grid key={index} item>
                          <div
                            className="gallerySelector customPhotoArea"
                            onClick={() => handleCustomPhotoClick(p)}
                            onKeyDown={() => handleCustomPhotoClick(p)}
                          >
                            <input
                              ref={
                                p.imageType &&
                                p.imageType.toUpperCase() === 'TICKET_PHOTO'
                                  ? fileInputRefCustomTicket
                                  : fileInputRefCustomTraffic
                              }
                              type="file"
                              id={'selectedFile_' + (p.imageType ?? '')}
                              className="gallerySelector__fileInput"
                              style={{ display: 'none' }}
                              onChange={(event) => {
                                handleInputFileChange(event, p.imageType);
                              }}
                              multiple={false}
                            />
                            {p.imageType &&
                              p.imageType.toUpperCase() === 'TICKET_PHOTO' && (
                                <TicketImage id="upload" />
                              )}

                            {p.imageType &&
                              p.imageType.toUpperCase() === 'TRAFFIC_PHOTO' && (
                                <TrafficTicketImage id="upload" />
                              )}
                          </div>
                        </Grid>
                      );
                    })}
                {props.id === PhotoSection.INSURED_OTHERS_DOCS &&
                  enableCustomPhotos()
                    .filter(
                      (f) => f.section === PhotoSection.INSURED_OTHERS_DOCS,
                    )
                    .sort(
                      (first, second) =>
                        0 -
                        (first.order &&
                        second.order &&
                        first.order > second.order
                          ? -1
                          : 1),
                    )
                    .map((p, index: number) => {
                      return (
                        <Grid key={index} item>
                          <div
                            id={'div_' + (p.imageType ?? '')}
                            className={clsx('gallerySelector', {
                              customPhotoArea:
                                p.imageType &&
                                ![
                                  'CUSTOM_PHOTO_LOOSEPARTS_INSURED',
                                  'CUSTOM_PHOTO_OPENTRUNK_INSURED',
                                ].includes(p.imageType.toUpperCase()),
                            })}
                            onClick={() => {
                              handleCustomPictureAllClicked(p.imageType ?? '');
                            }}
                            onKeyDown={() =>
                              handleCustomPictureAllClicked(p.imageType ?? '')
                            }
                          >
                            <input
                              ref={p.inputRef}
                              type="file"
                              id={'selectedFile_' + (p.imageType ?? '')}
                              className="gallerySelector__fileInput"
                              style={{ display: 'none' }}
                              alt={p.imageType}
                              title={p.imageType}
                              onChange={(event) => {
                                handleInputFileChange(event, p.imageType);
                              }}
                              multiple={false}
                            />
                            <img
                              src={p.icon}
                              alt="Imagen Requerida"
                              title="Imagen Requerida"
                              style={{ width: '80px', height: '71px' }}
                            />
                          </div>
                        </Grid>
                      );
                    })}
                {props.id === PhotoSection.INJURED_OTHERS_DOCS &&
                  enableCustomPhotos()
                    .filter(
                      (f) => f.section === PhotoSection.INJURED_OTHERS_DOCS,
                    )
                    .sort(
                      (first, second) =>
                        0 -
                        (first.order &&
                        second.order &&
                        first.order > second.order
                          ? -1
                          : 1),
                    )
                    .map((p, index: number) => {
                      return (
                        <Grid key={index} item>
                          <div
                            id={'div_' + (p.imageType ?? '')}
                            className="gallerySelector customPhotoArea"
                            onClick={() => {
                              handleCustomPictureAllClicked(p.imageType ?? '');
                            }}
                            onKeyDown={() =>
                              handleCustomPictureAllClicked(p.imageType ?? '')
                            }
                          >
                            <input
                              ref={p.inputRef}
                              type="file"
                              id={'selectedFile_' + (p.imageType ?? '')}
                              className="gallerySelector__fileInput"
                              style={{ display: 'none' }}
                              alt={p.imageType}
                              title={p.imageType}
                              onChange={(event) => {
                                handleInputFileChange(event, p.imageType);
                              }}
                              multiple={false}
                            />
                            <img
                              src={p.icon}
                              alt="Imagen Requerida"
                              title="Imagen Requerida"
                              style={{ width: '80px', height: '71px' }}
                            />
                          </div>
                        </Grid>
                      );
                    })}
                {props.id && (
                  <Grid item>
                    <div
                      className="gallerySelector"
                      onClick={handlePictureSelectorClicked}
                      onKeyDown={handlePictureSelectorClicked}
                    >
                      <input
                        ref={fileInputRef}
                        type="file"
                        id="selectedFile"
                        className="gallerySelector__fileInput"
                        style={{ display: 'none' }}
                        onChange={(event) => {
                          handleInputFileChange(event, 'default');
                        }}
                        multiple={true}
                      />
                      <UploadImage />
                    </div>
                  </Grid>
                )}
              </Grid>
            </DropArea>
          </AccordionDetails>
        </Accordion>
      </div>
      {openModal && (
        <Gallery
          imageToShow={props.imageForZoom}
          images={props.allPhotos}
          isOpen={openModal}
          onClose={(source, rotation) => {
            handleRotateImageClick(source, rotation);
            setOpenModal(false);
          }}
        />
      )}
      <Modal
        className="confirm-modal"
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
      >
        <div className="container">
          <img src={deleteIcon} alt="" />
          <h3>¿Desea eliminar la imagen?</h3>

          <div className="buttons-container">
            <Button variant="contained" onClick={remove}>
              Eliminar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setSelectedPhotos([]);
                setShowConfirmModal(false);
              }}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showImageAlert}
        onClose={() => setShowImageAlert(false)}
      >
        <Alert onClose={() => setShowImageAlert(false)} severity="warning">
          <div
            dangerouslySetInnerHTML={{
              __html:
                '<b>Cada intento tiene un máximo de <u>15 fotos</u> por envío.</b>',
            }}
          />
        </Alert>
      </Snackbar>
    </>
  );
};

export default SectionPhotos;
