import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import './styles.scss';
import HeaderContext from 'context/header/header-context';

interface Props {
  propContentTitle?: any;
}

const HeaderContent = (props: Props): JSX.Element => {
  const { propContentTitle, setPropContentTitle } = useContext(HeaderContext);

  return (
    <>
      {propContentTitle && (
        <div className="header-title">{propContentTitle}</div>
      )}
    </>
  );
};

export default HeaderContent;
