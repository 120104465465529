import React, { useState } from 'react';
import clsx from 'clsx';
import Chip from 'components/common/chip/Chip';
import { GRID_USER_COLUMNS } from 'static/constants/events-grid';
import { Column } from 'types/column';
import { IUserModel } from 'types/user';
import styles from '../override-mui-styles';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import GridModal from './GridModal/GridModal';

export const StatusChip = (eventStatus: boolean): JSX.Element => {
  if (eventStatus) {
    return <Chip label="Activo" color="green" />;
  } else {
    return <Chip label="Inactivo" color="red" />;
  }
};

interface Props {
  listUsers: IUserModel[];
  currentPage: number;
  cantRegisters: number;
  disabledModify: boolean;
  disabledDelete: boolean;
  hasCustomLevel?: boolean;
  typeCustomLevel?: string;
  disabledCustomLevel?: boolean;
  onChangePage: (event: any, page: number) => void;
  onModifyUser: (guid: string) => void;
  onDeleteUser: (guid: string) => void;
  scrollTrigger: () => void;
}

const UserList = (props: Props): JSX.Element => {
  const classes = styles();
  const [showGridModal, setShowGridModal] = useState<boolean>(false);
  const [typeGridModal, setTypeGridModal] = useState<string>('');
  const [refID_GridModal, setRefID_GridModal] = useState<string>('');
  const [refTitle_GridModal, setRefTitle_GridModal] = useState<string>('');

  const hideGridModal = () => {
    setShowGridModal(false);
  };

  const onClickCustomButton = (param: any) => {
    switch (props.typeCustomLevel) {
      case 'DRIVERS':
        setTypeGridModal(props.typeCustomLevel);
        setRefID_GridModal(param.guidID);
        setRefTitle_GridModal(param.name);
        setShowGridModal(true);
        props.scrollTrigger();
        break;
      default:
        break;
    }
  };

  const renderCustomButton = (param: any) => {
    let labelBtn = '';
    switch (props.typeCustomLevel) {
      case 'DRIVERS':
        labelBtn = 'Conductores';
        break;
      default:
        break;
    }

    return (
      <Button
        key="btnCustom"
        name="btnCustom"
        variant="outlined"
        disabled={props.disabledCustomLevel}
        disableElevation
        onClick={() => onClickCustomButton(param)}
        className={clsx('settingsForm__toggle-button-4')}
      >
        {labelBtn}
      </Button>
    );
  };

  return (
    <>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={props.cantRegisters}
        rowsPerPage={20}
        page={props.currentPage - 1}
        onPageChange={props.onChangePage}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {Object.values(GRID_USER_COLUMNS).map((column: Column) => (
                <TableCell key={column.name} className={classes.header}>
                  {column.header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.listUsers.map((user: IUserModel) => (
              <TableRow key={user.userCompanyID} className={classes.rowSelect}>
                <TableCell align="center" className={classes.cell}>
                  {user.name}
                </TableCell>
                <TableCell align="center" className={classes.cell}>
                  {user.email}
                </TableCell>
                <TableCell align="center" className={classes.cell}>
                  {user.companyName}
                </TableCell>
                <TableCell align="center" className={classes.cell}>
                  {user.rolDescription}
                </TableCell>
                <TableCell
                  align="center"
                  className={clsx(classes.cell, classes.mediumCell)}
                >
                  {StatusChip(user.active ?? false)}
                </TableCell>
                <TableCell align="center" className={classes.cell}>
                  <Button
                    key="btnEdit"
                    name="btnEdit"
                    variant="outlined"
                    disabled={props.disabledModify}
                    disableElevation
                    onClick={() => {
                      props.onModifyUser(user.guidID);
                      props.scrollTrigger();
                    }}
                    className={clsx('settingsForm__toggle-button')}
                  >
                    Editar
                  </Button>
                  <Button
                    key="btnDelete"
                    name="btnDelete"
                    variant="outlined"
                    disabled={props.disabledDelete}
                    disableElevation
                    onClick={() => {
                      props.onDeleteUser(user.guidID);
                      props.scrollTrigger();
                    }}
                    className={clsx('settingsForm__toggle-button-6')}
                  >
                    Borrar
                  </Button>
                  {props.hasCustomLevel && renderCustomButton(user)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <GridModal
        visible={showGridModal}
        gridType={typeGridModal}
        referenceId={refID_GridModal}
        referenceTitle={refTitle_GridModal}
        onClose={hideGridModal}
      />
    </>
  );
};

export default UserList;
