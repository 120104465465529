import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import theme from './assets/connect/theme';
import CssBaseline from '@mui/material/CssBaseline';
import * as serviceWorker from './serviceWorker';

import { SnackbarProvider } from 'context/snackbar/snackbar-context';
import { OnUpdateCallbackType } from 'types/service-worker';
const isProduction = process.env.NODE_ENV === 'production';

Sentry.init({
  dsn:
    'https://6df162df7ce74f14a95ee8b02de40c6a@o298640.ingest.sentry.io/5676127',
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  debug: !isProduction,

  // Per Sentry's recommendation:
  tracesSampleRate: isProduction ? 1.0 : 0.0,

  // Filter out large breadcrumbs (otherwise Sentry yields 413 Too Much Data error)
  beforeSend(event) {
    if (event.breadcrumbs) {
      event.breadcrumbs = event.breadcrumbs.filter((crumb) => {
        return !(crumb.category === 'fetch' && crumb?.data?.url.length > 255);
      });
    }

    return event;
  },
});

const onUpdateCallback: OnUpdateCallbackType = {};

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          <App onUpdateCallback={onUpdateCallback} />
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: () => {
    if (onUpdateCallback.onUpdate) {
      onUpdateCallback.onUpdate();
    }
  },
});
