import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { TabPanel } from 'components/common/tabpanel/TabPanel';
import style from 'override-mui-styles';
import { ActionType } from 'types/action';
import { IInjuryPerson, IInspection, IThird } from 'types/inspection';
import { FieldSection } from 'types/section-fields';
import './index.scss';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
  Alert,
} from '@mui/material';
import {
  addInjuredToEvent,
  addThirdToEvent,
  deleteCounterpart,
  deleteInjuredPerson,
  getFormConfiguration,
  getInspectionById,
  IInspectionContext,
  InspectionContext,
  saveInspection,
} from 'context/inspections';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  CountryCode,
  FormType,
  InspectionDataSections,
  Rol,
} from 'types/common';

import SectionFields from 'components/inspection/section/SectionFields';
import { parseState } from 'views/Inspection/Form';
import { FieldTypes } from 'types/claimsTracker';

interface ThirdsFormProps {
  isInspectorForm: boolean;
  claim: any;
  type: FieldTypes;
}

const ThirdsForm: FunctionComponent<ThirdsFormProps> = ({
  isInspectorForm,
  claim,
  type,
}): JSX.Element => {
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );

  const [counterpart, setCounterPartState] = useState(0);

  const [tabValue, setTabValue] = useState<number>(0);
  const [thirds, setThirds] = useState<any>([]);
  const [thirdsSections, setThirdsSections] = useState<any>([]);
  const [sections, setSections] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [requestSave, setRequestSave] = useState(false);
  const [requireFields, setRequireFields] = useState(false);
  const [moveToLastTab, setMoveToLastTab] = useState(true);

  useEffect(() => {
    (async () => {
      if (
        !localStorage.getItem('rolId') ||
        !localStorage.getItem('countryCode')
      ) {
        return;
      }

      const inspection = await fetchInspection();

      if (inspection) {
        dispatch({
          type: ActionType.SET_CURRENT_INSPECTION,
          payload: inspection,
        });
        if (type === FieldTypes.THIRDS_CLAIMS) {
          setThirds(inspection.thirds ?? []);
        } else {
          setThirds(inspection.injuryPersons ?? []);
        }
        await fetchForm(inspection?.CompanyId, inspection?.IsVitualInspector);
      }
    })();
  }, []);

  useEffect(() => {
    if (tabValue === counterpart) {
      return;
    }
    const value = thirds.length > counterpart ? counterpart : 0;
    setTabValue(value);
    setCounterPartState(value);
  }, [tabValue, thirds, counterpart]);

  const classes = style();

  const allProps = (index: number, id?: string) => {
    return {
      id: id || `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const fetchInspection = useCallback(async (): Promise<IInspection | null> => {
    // Get current event data
    const inspection: any = await getInspectionById(
      claim?.info.sicId,
      dispatch,
      isInspectorForm,
    );

    // set default storage data
    if (
      localStorage.getItem('countryCode')?.toUpperCase() === CountryCode.CRC &&
      inspection !== undefined
    ) {
      localStorage.setItem(
        'eventProvince',
        inspection?.provinceDirectionInspect,
      );
      localStorage.setItem('eventCanton', inspection?.cantonDirectionInspect);
      localStorage.setItem(
        'eventDistrict',
        inspection?.districtDirectionInspect,
      );
      localStorage.setItem(
        'placeDirectionInspect',
        inspection?.placeDirectionInspect,
      );
      localStorage.setItem(
        'eventDateInspection',
        inspection?.eventDateInspection,
      );
      localStorage.setItem('timeInspection', inspection?.timeInspection);
    }

    return inspection;
  }, [claim.sicId, dispatch, isInspectorForm]);

  const fetchForm = useCallback(
    async (companyId?: number, isVirtualInspector = false) => {
      // Get current form fields

      const country = localStorage.getItem('countryCode');
      const _companyId = companyId
        ? String(companyId)
        : localStorage.getItem('companyId');
      const hasCountryCode = country && country !== 'undefined';

      let userRolId = Number(localStorage.getItem('rolId'));
      if (isInspectorForm) {
        userRolId = Rol.INSPECTOR;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const formType = isVirtualInspector
        ? FormType.VIRTUAL_INSPECTOR
        : FormType.INSPECTION;

      const parameters = {
        companyId: _companyId,
        applicationId: 3,
        countryCode: (hasCountryCode && country) || 'PRI',
        rolId: userRolId,
        formType,
        IsInspector: isInspectorForm,
      };

      if (parameters.countryCode.length > 0) {
        const _sections = await getFormConfiguration(parameters, dispatch);

        setSections(
          parseState(
            _sections?.filter((section: any) => section.level === '2') ?? [],
            inspectionState,
            dispatch,
            isInspectorForm,
          ),
        );

        setThirdsSections(
          type === FieldTypes.THIRDS_CLAIMS
            ? _sections?.filter(
                (s) =>
                  s.name === InspectionDataSections.THIRDS && s.level === '2',
              )
            : _sections?.filter(
                (s) =>
                  s.name === InspectionDataSections.INJURIES && s.level === '2',
              ),
        );
      }
    },
    [dispatch, isInspectorForm, localStorage.getItem('rolId')],
  );

  const addCounterpart = useCallback(async (): Promise<void> => {
    if (inspectionState.currentInspection !== null) {
      const { EventId } = inspectionState.currentInspection;
      if (EventId !== undefined && EventId > 0) {
        dispatch({
          type: ActionType.SET_LOADING,
          payload: true,
        });

        if (type === FieldTypes.THIRDS_CLAIMS) {
          await addThirdToEvent(EventId);
        } else {
          await addInjuredToEvent(EventId);
        }

        setTimeout(async () => {
          if (fetchInspection) {
            const inspection = await fetchInspection();

            if (inspection) {
              dispatch({
                type: ActionType.SET_CURRENT_INSPECTION,
                payload: inspection,
              });

              if (type === FieldTypes.THIRDS_CLAIMS) {
                setThirds(inspection.thirds ?? []);
              } else {
                setThirds(inspection.injuryPersons ?? []);
              }
            }

            dispatch({
              type: ActionType.SET_LOADING,
              payload: false,
            });
          }

          setMoveToLastTab(true);
        }, 800);
      }
    }
  }, [dispatch, fetchInspection, inspectionState.currentInspection]);

  const setCounterpart = (index: number) => {
    setCounterPartState(index);
  };

  const parseSections = useCallback(
    (sections: FieldSection[]) => {
      return sections
        .sort((a, b) => a.idOrder - b.idOrder)
        .map((s) => ({
          ...s,
          fields: s.fields.map((f) => ({
            ...f,
            originalValue:
              f.originalValue ?? (thirds[tabValue] as any)?.[f.name],
            value: (thirds[tabValue] as any)?.[f.name],
          })),
        }));
    },
    [tabValue, thirds],
  );

  const handleSectionChange = (thirdSection: FieldSection[]) => {
    const _thirds = [...thirds];

    thirdSection.forEach((s) => {
      s.fields.forEach((f) => {
        (_thirds[counterpart] as any)[f.name] = f.value;
      });
    });
    const newSections = sections.map((section) => {
      const updatedSection = thirdSection.find(
        (updated) => updated.formSectionId === section.formSectionId,
      );

      return updatedSection ? updatedSection : section;
    });

    setThirdsSections([...thirdSection]);
    setSections([...newSections]);

    if (type === FieldTypes.THIRDS_CLAIMS) {
      dispatch({
        type: ActionType.SET_INSPECTION_THIRDS,
        payload: [..._thirds],
      });
    } else {
      dispatch({
        type: ActionType.SET_INSPECTION_INJURY_PERSONS,
        payload: [..._thirds],
      });
    }

    setRequestSave(true);
  };

  const removeCounterpart = async () => {
    if (type === FieldTypes.THIRDS_CLAIMS) {
      if (thirds[counterpart].thirdId) {
        await deleteCounterpart(thirds[counterpart].thirdId);
      }
    } else {
      if (thirds[counterpart].injuryId) {
        await deleteInjuredPerson(thirds[counterpart].injuryId);
      }
    }

    if (thirds.length > 1 && thirds.length === counterpart + 1) {
      setCounterPartState(counterpart - 1);
    }

    thirds.splice(counterpart, 1);

    if (type === FieldTypes.THIRDS_CLAIMS) {
      dispatch({
        type: ActionType.SET_INSPECTION_THIRDS,
        payload: thirds,
      });
    } else {
      dispatch({
        type: ActionType.SET_INSPECTION_INJURY_PERSONS,
        payload: thirds,
      });
    }

    setOpen(false);
  };

  const validateThirds = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (thirdSections: FieldSection[]): boolean => {
      let isValid = false;
      // Validate ID for Third parties
      if (type === FieldTypes.THIRDS_CLAIMS) {
        if (
          thirds[tabValue] !== null &&
          thirds[tabValue] !== undefined &&
          thirds[tabValue].thirdId !== undefined &&
          thirds[tabValue].thirdId !== null &&
          thirds[tabValue].thirdId > 0
        ) {
          isValid = true;
        }
      } else {
        if (
          thirds[tabValue] !== null &&
          thirds[tabValue] !== undefined &&
          thirds[tabValue].injuryId !== undefined &&
          thirds[tabValue].injuryId !== null &&
          thirds[tabValue].injuryId > 0
        ) {
          isValid = true;
        }
      }

      setRequireFields(!isValid);

      return isValid;
    },
    [setRequireFields, tabValue, thirds],
  );

  const autoSave = useCallback(async (): Promise<void> => {
    if (validateThirds(thirdsSections)) {
      await saveInspection(
        thirdsSections,
        inspectionState,
        false,
        false,
        dispatch,
        {
          counterpart:
            type === FieldTypes.THIRDS_CLAIMS ? tabValue || 0 : undefined,
          injuryNumber:
            type === FieldTypes.INJURED_CLAIMS ? tabValue || 0 : undefined,
        },
      );

      if (type === FieldTypes.THIRDS_CLAIMS) {
        if (!thirds.every((t: IThird) => t.thirdId) && fetchInspection) {
          await fetchInspection();
        }
      } else {
        if (
          !thirds.every((t: IInjuryPerson) => t.injuryId) &&
          fetchInspection
        ) {
          await fetchInspection();
        }
      }
    }
  }, [
    thirdsSections,
    inspectionState,
    dispatch,
    tabValue,
    thirds,
    fetchInspection,
    validateThirds,
  ]);

  useEffect(() => {
    if (requestSave) {
      autoSave();
      setRequestSave(false);
    }
  }, [autoSave, requestSave]);

  useEffect(() => {
    if (moveToLastTab) {
      let tab = thirds.length - 1;
      tab = tab < 0 ? 0 : tab;

      setCounterPartState(tab);
      setMoveToLastTab(false);
    }
  }, [moveToLastTab, thirds]);

  const fetchLabel = (): string => {
    if (type === FieldTypes.THIRDS_CLAIMS) {
      return 'Contraparte';
    } else {
      return 'Lesionado';
    }
  };

  return (
    <div className="dinamic-tab-form">
      {requireFields && (
        <Alert severity="error" onClose={() => setRequireFields(false)}>
          Se detectaron campos requeridos en el formulario
        </Alert>
      )}
      <div className="heading">
        <Tabs
          value={tabValue}
          onChange={(event: any, newValue: number) => setTabValue(newValue)}
          aria-label=""
          variant="scrollable"
          scrollButtons="auto"
        >
          {thirds.map((t, i) => (
            <Tab
              className="tab-claim"
              key={i}
              label={fetchLabel() + ` ${i + 1}`}
              {...allProps(i)}
              onClick={() => setCounterpart(i)}
            />
          ))}
        </Tabs>
        <div className="buttons">
          <div onClick={addCounterpart} onKeyDown={addCounterpart}>
            <AddCircleOutlineIcon style={{ cursor: 'pointer' }} />
          </div>
          <div onClick={() => setOpen(true)} onKeyDown={() => setOpen(true)}>
            <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} />
          </div>
        </div>
      </div>
      {thirds.map((t, i) => (
        <TabPanel key={i} value={tabValue} index={i}>
          {parseSections(thirdsSections).map((s, j) => (
            <Fragment key={j}>
              <div className="title-container">
                <h2 className="inspectionForm__title">{s.title}</h2>
              </div>
              <Divider />
              <SectionFields
                claimInfo={claim?.info}
                key={j}
                sections={parseSections(thirdsSections)}
                fields={s.fields}
                save={() => undefined}
                classes={classes}
                setSections={handleSectionChange}
                isInspectorForm={isInspectorForm}
                isVirtualInspector={Boolean(
                  inspectionState.currentInspection?.IsVitualInspector,
                )}
                options={{
                  fromThirds: type === FieldTypes.THIRDS_CLAIMS ? true : false,
                }}
                formValidation={() => {
                  return true;
                }}
                isCMT={true}
              />
            </Fragment>
          ))}
        </TabPanel>
      ))}

      <Dialog
        disableEscapeKeyDown
        maxWidth="md"
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">
          Eliminar {fetchLabel()}
        </DialogTitle>
        <DialogContent>
          ¿Seguro que desea eliminar la {fetchLabel()} {counterpart + 1}?
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="outlined"
            onClick={() => setOpen(false)}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={removeCounterpart}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ThirdsForm;
