import {
  TextField,
  Typography,
  Radio,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from '@mui/material';
import { TreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ConnectServicesClient from 'shared/clients/ConnectServiceClient';
import DocumentTemplatesClient from 'shared/clients/DocumentTemplatesClient';
import ButtonClaims from './../../claims/button';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import DataTable from 'components/claims/grid';
import DialogComponent from 'components/common/dialog';

interface Props {
  idTemplate: string;
}
export interface Document {
  id: string;
  name: string;
  permissionEmails: string;
  documentId: string;
  variables: string[];
}
export interface Variable {
  id: string;
  title: string;
  finally: boolean;
  children: Variable[];
}
export interface VariableTemplate {
  TemplateId: string;
  Variable: string;
  FormTypeId: string;
  FieldId: string;
  FieldName: string;
  FieldRoot: string;
}

const SetVariablesDocument: FunctionComponent<Props> = ({
  idTemplate,
}): JSX.Element => {
  const [document, setDocument] = useState<any>(null);
  const [variablesTemplate, setVariablesTemplate] = useState<any>([]);
  const [variablesTemplateHistory, setVariablesTemplateHistory] = useState<any>(
    [],
  );
  const [variables, setVariables] = useState<any>([]);
  const [variableSelected, setVariableSelected] = useState(null);
  const [formType, setFormType] = useState<any>(null);
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [selectedVarName, setSelectedVarName] = React.useState(null);
  const [variableTreeView, setVariableTreeView] = useState<Variable[]>([]);
  const [disabled, setDisabled] = useState(true);
  const [dataHistoric, setDataHistoric] = useState<any>([]);
  const [selectionFields, setSelectionFields] = useState<GridRowId[]>([]);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

  useEffect(() => {
    getVariables();
    getTemplate();
  }, []);
  const getFields = async () => {
    if (formType) {
      const data = await new ConnectServicesClient().getFieldsTemplates(
        formType,
        Number(localStorage.getItem('companyId')).toString(),
      );
      setVariables((prevtre) => [...data.data]);
    }
  };
  const getDataHistoric = async () => {
    if (formType) {
      const data = await new ConnectServicesClient().getVariablesTemplate(
        formType,
        idTemplate,
      );
      setDataHistoric((prevtre) => [...data.data]);
    }
  };

  const getTemplate = async () => {
    const data = await new DocumentTemplatesClient().getAllTemplates();
    setDocument((prevtre) => data.find((x) => x.id === idTemplate));
  };
  const getVariables = async () => {
    const data = await new DocumentTemplatesClient().getVariables(idTemplate);
    const dataFinal = data.filter((x) => !x.startsWith('currentDate'));
    setVariablesTemplateHistory((prevtm) => [...dataFinal]);
    setVariablesTemplate((prevtm) => [...dataFinal]);
  };
  useEffect(() => {
    getDataHistoric();
    getFields();
  }, [formType]);

  useEffect(() => {
    const data = variablesTemplateHistory.filter(function (item) {
      return dataHistoric.map((exam) => exam.variable).indexOf(item) === -1;
    });
    setVariablesTemplate((old) => [...data]);
  }, [dataHistoric]);
  useEffect(() => {
    setDisabled(!(formType && variableSelected && selectedValue));
  }, [formType, variableSelected, selectedValue]);
  useEffect(() => {
    const treeView: Variable[] = [];
    for (let i = 0; i < variables.length; i++) {
      let item = treeView.find((x) => x.title === variables[i].stageTitle);
      if (!item) {
        item = {
          id: '0',
          finally: false,
          title: variables[i].stageTitle,
          children: [],
        };
        item.children = setChildren(item.title, 2, variables, item.children);
        treeView.push(item);
      }
    }
    setVariableTreeView((prevtre) => [...treeView]);
  }, [variables]);

  const handleChange = (event) => {
    const valu = event?.target.value;
    setSelectedValue(valu);
    setSelectedVarName(
      variables.find((x) => x.fieldKeyName === valu).fieldLabel,
    );
  };

  const onChangeFormType = (event) => {
    setFormType(event?.target.value);
  };
  const onChangeSelectedVariable = (event) => {
    setVariableSelected(event?.target.value);
  };
  const setChildren = (parent, level, data, items) => {
    for (let i = 0; i < data.length; i++) {
      if (parent === (level === 2 ? data[i].stageTitle : data[i].title)) {
        let item = items.find(
          (x) => x.title === (level === 2 ? data[i].title : data[i].fieldLabel),
        );
        if (!item) {
          item = {
            title: level === 2 ? data[i].title : data[i].fieldLabel,
            id:
              data[i].level === level
                ? data[i].fieldKeyName.toString()
                : data[i].fieldKey.toString(),
            finally: data[i].level === level,
            children: [],
          };
          if (level < 3) {
            item.children = setChildren(
              item.title,
              level + 1,
              data,
              item.children,
            );
          }
          items.push(item);
        }
      }
    }

    return items;
  };

  const handleAddVariable = async () => {
    const data = {
      FieldId: selectedValue,
      FormTypeId: parseInt(formType),
      TemplateId: idTemplate,
      Variable: variableSelected,
      FieldName: selectedVarName,
      FieldRoot: selectedVarName,
    };
    const result = await new ConnectServicesClient().addVariableTemplate(data);
    if (result.data) {
      getDataHistoric();
      resetForm();
    }
  };
  const resetForm = () => {
    setSelectedValue(null);
    setSelectedVarName(null);
    setVariableSelected(null);
  };
  const columns: GridColDef[] = [
    { field: 'variable', headerName: 'Variable', width: 200 },
    { field: 'fieldName', headerName: 'Nombre de campo', width: 400 },
  ];

  const handleCancel = (): void => {
    setSelectionFields([]);
  };

  const openConfirmDialog = (): void => {
    setOpenDialogConfirm(true);
  };
  const handleConfirm = async (): Promise<void> => {
    setOpenDialogConfirm(false);
    await handleDelete();
  };
  const handleDelete = async (): Promise<void> => {
    for (const id of selectionFields) {
      await new ConnectServicesClient().deleteVariableTemplate(id.toString());
    }
    setSelectionFields([]);
    getDataHistoric();
  };

  return (
    <>
      <Typography className={'gridCard-claim__title'}>
        {document?.name}
      </Typography>
      <br />
      <Grid container spacing={2} style={{ height: 400, overflowY: 'auto' }}>
        <Grid item xs={2}>
          <FormControl sx={{ minWidth: 235, backgroundColor: '#ffffff' }}>
            <InputLabel id="workshopLbl">Tipo de formulario</InputLabel>
            <Select
              value={formType}
              label={'Tipo de formulario'}
              onChange={onChangeFormType}
            >
              <MenuItem value="6">Claim Management</MenuItem>
              <MenuItem value="1">Inspección</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl sx={{ minWidth: 235, backgroundColor: '#ffffff' }}>
            <InputLabel id="workshopLbl">Variables</InputLabel>
            <Select
              value={variableSelected}
              label={'Variables'}
              onChange={onChangeSelectedVariable}
            >
              {variablesTemplate &&
                variablesTemplate.map((vari, index) => {
                  return (
                    <MenuItem key={index} value={vari}>
                      {vari}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {variableTreeView.map((item, index) => {
              return (
                <TreeItem key={index} label={item.title} nodeId={item.title}>
                  {item.children.map((l1, i1) => {
                    return (
                      <TreeItem
                        nodeId={l1.id}
                        key={i1}
                        label={
                          l1.finally ? (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={selectedValue === l1.id}
                                    onChange={handleChange}
                                    value={l1.id}
                                  />
                                }
                                label={l1.title}
                              />
                            </div>
                          ) : (
                            l1.title
                          )
                        }
                      >
                        {l1.children.length > 0 &&
                          l1.children.map((l2, i2) => {
                            return (
                              <TreeItem
                                nodeId={l2.id}
                                key={i2}
                                label={
                                  l2.finally ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Radio
                                            checked={selectedValue === l2.id}
                                            onChange={handleChange}
                                            value={l2.id}
                                          />
                                        }
                                        label={l2.title}
                                      />
                                    </div>
                                  ) : (
                                    l2.title
                                  )
                                }
                              ></TreeItem>
                            );
                          })}
                      </TreeItem>
                    );
                  })}
                </TreeItem>
              );
            })}
          </TreeView>
        </Grid>
        <Grid item xs={2}>
          <ButtonClaims
            onClick={handleAddVariable}
            disabled={disabled}
            text="Guardar variable"
          />
        </Grid>
      </Grid>
      <Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          {dataHistoric?.length > 0 && (
            <Grid>
              <Grid>
                <p>Variables asignadas</p>
              </Grid>
              <Grid className="claim-adjust__table">
                {selectionFields.length > 0 && (
                  <Grid className="claim-adjust__table--options">
                    <span>{selectionFields.length} items selected</span>
                    <Grid className="claim-adjust__table--options--actions">
                      <ButtonClaims
                        text="Eliminar"
                        variant="text"
                        style={{ color: 'white', fontSize: 14 }}
                        onClick={openConfirmDialog}
                      ></ButtonClaims>
                      <span>|</span>
                      <ButtonClaims
                        text="Cancel"
                        variant="text"
                        style={{ color: 'white', fontSize: 14 }}
                        onClick={handleCancel}
                      ></ButtonClaims>
                    </Grid>
                  </Grid>
                )}
                <DataTable
                  columns={columns}
                  data={dataHistoric}
                  showCheckBox={true}
                  selectionModel={selectionFields}
                  onRowSelectionModelChange={(rows: any): void =>
                    setSelectionFields(rows)
                  }
                ></DataTable>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>

      <DialogComponent
        messageStyle={{ fontSize: '16px' }}
        message={`¿Desea eliminar los ${selectionFields.length} registros seleccionados?`}
        open={openDialogConfirm}
        handleClose={() => setOpenDialogConfirm(false)}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

export default SetVariablesDocument;
