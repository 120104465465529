import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Modal,
  Alert,
} from '@mui/material';
import clsx from 'clsx';
import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as Sentry from '@sentry/browser';
import SectionPhotos from './section';
import { TabPanel } from 'components/common/tabpanel/TabPanel';
import { IUserContext, UserContext } from 'context/user';
import {
  getMediaFiles,
  IPreInspectionContext,
  PreInspectionContext,
} from 'context/preinspections';
import {
  setLoading,
  getImages,
  IInspectionContext,
  InspectionContext,
  deleteMediaFiles,
} from 'context/inspections';
import { networkIsOnline } from 'shared/utils';
import { ActionType } from 'types/action';
import {
  EventPhoto,
  PhotoSection,
  SectionType,
  SectionTypes,
} from 'types/images';
import { BrandsId, CmtCountries, CountryCode, Rol } from 'types/common';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import deleteIcon from '../../../assets/images/modal_delete_image.svg';
import AirBagIcon from 'assets/images/airbag-icon.png';
import TrenDelanteroIcon from 'assets/images/tren-delant-icon.png';
import TrenTraseroIcon from 'assets/images/tren-trasero-icon.png';
import SeatBeltIcon from 'assets/images/seat-belt-icon.png';
import FrontSeatIcon from 'assets/images/front-seat-icon.png';
import BackSeatIcon from 'assets/images/back-seat-icon.png';
import LooseItemsVehIcon from 'assets/images/piezas-veh-icon.png';
import BaulIcon from 'assets/images/baul-veh-icon.png';
import DashIcon from 'assets/images/dash-veh-icon.png';
import './styles.scss';
import { FieldSection } from 'types/section-fields';
import { isInjuredOrInsured } from '../section/utils';
import { ClaimantType } from 'types/claimsTracker';
import db from '../../inspection/photos/indexDB';

interface photoConfig {
  country: string | undefined;
  folderRoot?: string;
  sectionType: string;
  handleSavePhoto: () => void;
  setSections?: (sections: FieldSection[]) => void;
  sections?: FieldSection[];
  eventRecord?: string;
  claimantType?: ClaimantType;
  isReadOnly?: boolean;
}

const allProps = (index: number, currentValue: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: clsx({ tabButton: index === currentValue }),
  };
};

const Photos = (props: photoConfig): JSX.Element => {
  const history = useHistory();
  const { state, dispatch } = useContext<IInspectionContext>(InspectionContext);
  const { state: userState, dispatch: userDispatch } = useContext<IUserContext>(
    UserContext,
  );
  const { state: preInspectState, dispatch: dispatchPreInspect } = useContext<
    IPreInspectionContext
  >(PreInspectionContext);
  const [enableTabs, setEnableTabs] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [photoSectionFields, setPhotoSectionFields] = useState<SectionType[]>(
    [],
  );
  const [currentPhotos, setCurrentPhotos] = useState<EventPhoto[]>([]);
  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([]);
  const [previousActivePhoto, setPreviousActivePhoto] = useState<string>('');
  const [imageForZoom, setImageForZoom] = useState('');
  const [initValidations, setInitValidations] = useState<boolean>(false);
  const [activeControlPending, setActiveControlPending] = useState<boolean>(
    true,
  );
  const [customPhotos, setCustomPhotos] = useState<EventPhoto[]>([]);
  const [isPendingBoleta, setIsPendingBoleta] = useState<boolean>(false);
  const [isPendingTraffic, setIsPendingTraffic] = useState<boolean>(false);
  const [messageTraffic, setMessageTraffic] = useState<boolean>(false);
  const [messageBoletas, setMessageBoletas] = useState<boolean>(false);
  const [messageCustomInjuredAlert, setMessageCustomInjuredAlert] = useState<
    boolean
  >(false);
  const [messageCustomInjuredMsg, setMessageCustomInjuredMsg] = useState<
    string
  >('');
  const [messageCustomInsuredAlert, setMessageCustomInsuredAlert] = useState<
    boolean
  >(false);
  const [messageCustomInsuredMsg, setMessageCustomInsuredMsg] = useState<
    string
  >('');
  const [showBoletaAlertFirstTime, setShowBoletaAlertFirstTime] = useState<
    boolean
  >(true);
  const [showTrafficAlertFirstTime, setShowTrafficAlertFirstTime] = useState<
    boolean
  >(true);
  const [checkNet, setCheckNet] = useState(true);
  const [showNetAlert, setShowNetAlert] = useState(false);
  const [showImageInfoAlert, setShowImageInfoAlert] = useState(false);
  const [showConfirmBulkDelete, setShowConfirmBulkDelete] = useState(false);

  const isInspectorForm = history.location.pathname.includes('/inspector/');
  const isAuditProcess = Number(localStorage.getItem('rolId')) === Rol.AUDIT;
  let eventRecord = state.currentInspection?.EventRecord;
  let countryCode = state.currentInspection?.CountryCode;
  countryCode =
    props.sectionType === 'PRE-INSPECTIONS'
      ? preInspectState.currentPreInspection?.preCountryCode
      : countryCode;

  const fetchImages = useCallback(async () => {
    if (!eventRecord) {
      eventRecord = props.eventRecord;
    }

    if (!countryCode) {
      countryCode = props.country;
    }

    if (
      props.folderRoot &&
      props.folderRoot.length > 0 &&
      preInspectState.currentPreInspection
    ) {
      await getMediaFiles(
        props.folderRoot,
        preInspectState.currentPreInspection?.preUniqueId,
        dispatchPreInspect,
      );
      setInitValidations(false);
    } else if (countryCode && eventRecord) {
      await getImages(countryCode, String(eventRecord), dispatch);
      setInitValidations(true);
    }
    setLoading(false, dispatch);
  }, [
    countryCode,
    eventRecord,
    dispatch,
    props.folderRoot,
    preInspectState.currentPreInspection,
    dispatchPreInspect,
  ]);

  const checkNetStatus = useCallback(() => {
    (async (): Promise<void> => {
      const result = await networkIsOnline();
      setShowNetAlert(!result);
      if (result && !userState.hasNetConnection) {
        fetchImages();
        setShowImageInfoAlert(true);
      }

      if (!result) {
        userDispatch({
          type: ActionType.SET_NET_CONNECTION,
          payload: false,
        });
      } else if (!userState.hasNetConnection) {
        userDispatch({
          type: ActionType.SET_NET_CONNECTION,
          payload: true,
        });
      }
    })();
  }, [fetchImages, userDispatch, userState]);

  const validateCustomPhotos = useCallback(() => {
    const customPhotoTemplate: EventPhoto[] = [];
    setActiveControlPending(true);

    if (countryCode === CountryCode.CRC) {
      if (state.currentImages) {
        // Parte tránsito photo configuration
        if (
          state.currentInspection &&
          state.currentInspection.hasTrafficTicket === '1'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.OTHERS,
            imageType: 'TRAFFIC_PHOTO',
            dataURL: '',
            order: 2,
          };
          customPhotoTemplate.push(templatePhoto);

          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            setIsPendingTraffic(true);
            setMessageTraffic(showTrafficAlertFirstTime);
          } else {
            setIsPendingTraffic(false);
            setMessageTraffic(false);
          }
        }
      }
    }

    if (
      countryCode === CountryCode.PRI &&
      state.currentInspection?.CompanyId === BrandsId.CSM
    ) {
      // eslint-disable-next-line prefer-const
      let insuredShowAlert = false;
      const insuredMessage =
        '<p>Se detectaron datos requeridos en el apartado de Asegurado:</p><ul>[INSURED_MESSAGE]</ul>';
      // eslint-disable-next-line prefer-const
      let insuredAlertMsg = '';
      let injuredShowAlert = false;
      const injuredMessage =
        '<p>Se detectaron datos requeridos en el apartado de Perjudicado:</p><ul>[INJURED_MESSAGE]</ul>';
      let injuredAlertMsg = '';

      if (state.currentImages) {
        // Airbags Insured
        if (
          state.currentInspection &&
          state.currentInspection.indAirbagsActiveInsured === '1'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INSURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_AIRBAGS_INSURED',
            dataURL: '',
            order: 1,
            icon: AirBagIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);

          // Validate images for CUSTOM_PHOTO_AIRBAGS_INSURED
          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            insuredShowAlert = true;
            insuredAlertMsg +=
              '<li>Fotografía de los <b>AirBags <u>es requerida</u></b>.</li>';
          }
        }

        // Airbags Injured
        if (
          state.currentInspection &&
          state.currentInspection.indAirbagsActive === '1'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INJURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_AIRBAGS',
            dataURL: '',
            order: 1,
            icon: AirBagIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);

          // Validate images for CUSTOM_PHOTO_AIRBAGS
          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            injuredShowAlert = true;
            injuredAlertMsg +=
              '<li>Fotografía de los <b>AirBags <u>es requerida</u></b>.</li>';
          }
        }

        // Cinturón de Seguridad Insured
        if (
          state.currentInspection &&
          state.currentInspection.indSeatBeltStagnantInsured === '1'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INSURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_SEATBELT_INSURED',
            dataURL: '',
            order: 2,
            icon: SeatBeltIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);

          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            insuredShowAlert = true;
            insuredAlertMsg +=
              '<li>Fotografía del <b>Cinturón de seguridad <u>es requerida</u></b>.</li>';
          }
        }

        // Cinturón de Seguridad Injured
        if (
          state.currentInspection &&
          state.currentInspection.indSeatBeltStagnant === '1'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INJURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_SEATBELT',
            dataURL: '',
            order: 2,
            icon: SeatBeltIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);

          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            injuredShowAlert = true;
            injuredAlertMsg +=
              '<li>Fotografía del <b>Cinturón de seguridad <u>es requerida</u></b>.</li>';
          }
        }

        // Tren Frontal Insured
        if (
          state.currentInspection &&
          state.currentInspection.indFrontAxleDamageInsured === '1'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INSURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_FRONTAXLE_INSURED',
            dataURL: '',
            order: 3,
            icon: TrenDelanteroIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);

          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            insuredShowAlert = true;
            insuredAlertMsg +=
              '<li>Fotografía del <b>Tren delantero <u>es requerida</u></b>.</li>';
          }
        }

        // Tren Frontal Injured
        if (
          state.currentInspection &&
          state.currentInspection.indFrontAxleDamage === '1'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INJURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_FRONTAXLE',
            dataURL: '',
            order: 3,
            icon: TrenDelanteroIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);

          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            injuredShowAlert = true;
            injuredAlertMsg +=
              '<li>Fotografía del <b>Tren delantero <u>es requerida</u></b>.</li>';
          }
        }

        // Tren Trasero Insured
        if (
          state.currentInspection &&
          state.currentInspection.indRearAxleDamageInsured === '1'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INSURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_REARAXLE_INSURED',
            dataURL: '',
            order: 4,
            icon: TrenTraseroIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);

          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            insuredShowAlert = true;
            insuredAlertMsg +=
              '<li>Fotografía del <b>Tren trasero <u>es requerida</u></b>.</li>';
          }
        }

        // Tren Trasero Injured
        if (
          state.currentInspection &&
          state.currentInspection.indRearAxleDamage === '1'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INJURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_REARAXLE',
            dataURL: '',
            order: 4,
            icon: TrenTraseroIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);

          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            injuredShowAlert = true;
            injuredAlertMsg +=
              '<li>Fotografía del <b>Tren trasero <u>es requerida</u></b>.</li>';
          }
        }

        // Piezas perdidas Insured
        if (
          state.currentInspection &&
          state.currentInspection.indVehicleLoosePartsInsured === '1'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INSURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_LOOSEPARTS_INSURED',
            dataURL: '',
            order: 5,
            icon: LooseItemsVehIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);
        }

        // Piezas perdidas Injured
        if (
          state.currentInspection &&
          state.currentInspection.indVehicleLooseParts === '1'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INJURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_LOOSEPARTS',
            dataURL: '',
            order: 5,
            icon: LooseItemsVehIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);

          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            injuredShowAlert = true;
            injuredAlertMsg +=
              '<li>Fotografía de las <b>Piezas sueltas <u>es requerida</u></b>.</li>';
          }
        }

        // Abrir cajuela Insured
        if (
          state.currentInspection &&
          state.currentInspection.indCustomerOpenTrunkInsured === '1'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INSURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_OPENTRUNK_INSURED',
            dataURL: '',
            order: 6,
            icon: BaulIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);
        }

        // Abrir cajuela Injured
        if (
          state.currentInspection &&
          state.currentInspection.indCustomerOpenTrunk === '1'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INJURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_OPENTRUNK',
            dataURL: '',
            order: 6,
            icon: BaulIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);

          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            injuredShowAlert = true;
            injuredAlertMsg +=
              '<li>Fotografía del <b>Baúl / Bonete abierto <u>es requerida</u></b>.</li>';
          }
        }

        // Por inundación - Delantera - Insured
        if (
          state.currentInspection &&
          state.currentInspection.sinisterTypeInsuredPR === '2'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INSURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_INTERNAS_DELANTERA_INSURED',
            dataURL: '',
            order: 7,
            icon: FrontSeatIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);

          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            insuredShowAlert = true;
            insuredAlertMsg +=
              '<li>Fotografía <b>Delantera interna, incluyendo asientos y suelos <u>es requerida</u></b>.</li>';
          }
        }

        // Por inundación - Delantera - Injured
        if (
          state.currentInspection &&
          state.currentInspection.sinisterTypePR === '2'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INJURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_INTERNAS_DELANTERA',
            dataURL: '',
            order: 7,
            icon: FrontSeatIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);

          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            injuredShowAlert = true;
            injuredAlertMsg +=
              '<li>Fotografía <b>Delantera interna, incluyendo asientos y suelos <u>es requerida</u></b>.</li>';
          }
        }

        // Por inundación - Trasera - Insured
        if (
          state.currentInspection &&
          state.currentInspection.sinisterTypeInsuredPR === '2'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INSURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_INTERNAS_TRASERA_INSURED',
            dataURL: '',
            order: 8,
            icon: BackSeatIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);

          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            insuredShowAlert = true;
            insuredAlertMsg +=
              '<li>Fotografía <b>Trasera interna, incluyendo asientos y suelos <u>es requerida</u></b>.</li>';
          }
        }

        // Por inundación - Trasera - Injured
        if (
          state.currentInspection &&
          state.currentInspection.sinisterTypePR === '2'
        ) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INJURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_INTERNAS_TRASERA',
            dataURL: '',
            order: 8,
            icon: BackSeatIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);

          if (
            state.currentImages.filter(
              (f: EventPhoto) =>
                f.imageName &&
                String(f.imageName)
                  .toLowerCase()
                  .indexOf(String(templatePhoto.imageType).toLowerCase()) > -1,
            ).length === 0
          ) {
            injuredShowAlert = true;
            injuredAlertMsg +=
              '<li>Fotografía <b>Trasera interna, incluyendo asientos y suelos <u>es requerida</u></b>.</li>';
          }
        }

        // Dash del vehículo - Insured
        if (state.currentInspection) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INSURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_DASH_INSURED',
            dataURL: '',
            order: 9,
            icon: DashIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);
        }

        // Dash del vehículo - Injured
        if (state.currentInspection) {
          const templatePhoto: EventPhoto = {
            section: PhotoSection.INJURED_OTHERS_DOCS,
            imageType: 'CUSTOM_PHOTO_DASH',
            dataURL: '',
            order: 9,
            icon: DashIcon,
            inputRef: createRef<HTMLInputElement>(),
          };
          customPhotoTemplate.push(templatePhoto);
        }
      }

      if (insuredShowAlert) {
        setMessageCustomInsuredMsg(
          insuredMessage.replace('[INSURED_MESSAGE]', insuredAlertMsg),
        );
        setMessageCustomInsuredAlert(true);
      } else {
        setMessageCustomInsuredMsg('');
        setMessageCustomInsuredAlert(false);
      }

      if (injuredShowAlert) {
        setMessageCustomInjuredMsg(
          injuredMessage.replace('[INJURED_MESSAGE]', injuredAlertMsg),
        );
        setMessageCustomInjuredAlert(true);
      } else {
        setMessageCustomInjuredMsg('');
        setMessageCustomInjuredAlert(false);
      }

      dispatch({
        type: ActionType.SET_IS_VALID_CUSTOM_PHOTOS,
        payload: !insuredShowAlert && !injuredShowAlert,
      });
    }

    setCustomPhotos(customPhotoTemplate);
    setInitValidations(false);
  }, [
    countryCode,
    isInspectorForm,
    state,
    showBoletaAlertFirstTime,
    showTrafficAlertFirstTime,
    dispatch,
  ]);

  const galleryIsReadOnly = (): boolean => {
    if (props.isReadOnly) {
      return true;
    }

    if (
      [
        Rol.PRE_BROKER,
        Rol.PRE_CONSULTANT,
        Rol.INSURER,
        Rol.CLAIMS_ASSISTANT,
      ].includes(Number(localStorage.getItem('rolId')))
    ) {
      return true;
    }

    if (isAuditProcess) {
      if (
        CmtCountries.includes(
          localStorage.getItem('countryCode') as CountryCode,
        )
      ) {
        return false;
      } else {
        return !state.auditEvent.auditIsActive;
      }
    }

    return false;
  };

  useEffect(() => {
    if (activeControlPending) {
      localStorage.setItem(
        'requireCustomPhotoValidation',
        isPendingBoleta || isPendingTraffic ? '1' : '0',
      );
      setActiveControlPending(false);
    }
  }, [activeControlPending, isPendingBoleta, isPendingTraffic]);

  useEffect(() => {
    if (!isInspectorForm) {
      fetchImages();
    } else {
      setInitValidations(true);
    }
  }, [fetchImages, isInspectorForm]);

  useEffect(() => {
    setEnableTabs(false);
    if (!countryCode) {
      countryCode = props.country;
    }

    switch (countryCode) {
      case SectionTypes.PAN.name:
        if (props.sectionType === 'INSPECTIONS') {
          let sectionType = SectionTypes.PAN.default;
          if (!isInspectorForm) {
            if (state.currentInspection?.CompanyId === BrandsId.ACERTA) {
              sectionType = SectionTypes.PAN.acerta;
            }
          }
          if (state.currentInspection?.CompanyId === BrandsId.FEDPA) {
            sectionType = SectionTypes.PAN.fedpa;
          }
          setPhotoSectionFields(sectionType);
        } else if (props.sectionType === 'PRE-INSPECTIONS') {
          setPhotoSectionFields(SectionTypes.PAN.preInspections);
        }
        break;
      case SectionTypes.CRC.name:
        if (props.sectionType === 'INSPECTIONS') {
          setPhotoSectionFields(SectionTypes.CRC.default);
        } else if (props.sectionType === 'PRE-INSPECTIONS') {
          const isBcr =
            Number(localStorage.getItem('companyId')) === BrandsId.BCR;
          setPhotoSectionFields(
            isBcr
              ? SectionTypes.CRC.preInspectionsBcr
              : SectionTypes.CRC.preInspections,
          );
        }
        break;
      default:
        setEnableTabs(true);
        setPhotoSectionFields(SectionTypes.PRI.csm);
        break;
    }

    if (state.currentImages != null) {
      setCurrentPhotos(state.currentImages);
    }

    if (preInspectState.currentMediaFiles.length > 0) {
      setCurrentPhotos(
        preInspectState.currentMediaFiles.sort((a, b) =>
          a.section > b.section ? 1 : -1,
        ),
      );
    }
  }, [
    countryCode,
    photoSectionFields,
    setPhotoSectionFields,
    state.currentImages,
    preInspectState.currentMediaFiles,
    props.sectionType,
    state.currentInspection,
    isInspectorForm,
  ]);

  useEffect(() => {
    if (initValidations) {
      validateCustomPhotos();
    }
  }, [validateCustomPhotos, initValidations]);

  useEffect(() => {
    if (checkNet) {
      checkNetStatus();
      setCheckNet(false);
    }
  }, [checkNet, showNetAlert, checkNetStatus]);

  useEffect(() => {
    if (isAuditProcess && state.auditEvent.auditPhotoSection) {
      dispatch({
        type: ActionType.SET_AUDIT_TRIGGER_PHOTO_SECTION,
        payload: false,
      });
      props.handleSavePhoto();
    }
  }, [dispatch, state.auditEvent.auditPhotoSection, isAuditProcess, props]);

  const handlePhotosWithSection = (photos: EventPhoto[]): SectionType[] => {
    let mergePhotos: SectionType[] =
      photoSectionFields != null ? Array.from(photoSectionFields) : [];

    mergePhotos = mergePhotos.map((mp) => {
      const getPhotos = photos.filter((f) => f.section === mp.section);
      let defaultOtherSection = PhotoSection.INJURED_OTHERS_DOCS;

      if (state.currentInspection?.ProjectId === '2') {
        defaultOtherSection = PhotoSection.INJURED_OTHERS_DOCS;
      } else if (
        state.currentInspection &&
        isInjuredOrInsured(state.currentInspection).isInsured
      ) {
        defaultOtherSection = PhotoSection.INSURED_OTHERS_DOCS;
      } else if (
        props.eventRecord &&
        props.claimantType == ClaimantType.CLAIM_INSURED
      ) {
        defaultOtherSection = PhotoSection.INSURED_OTHERS_DOCS;
      } else if (
        props.eventRecord &&
        props.claimantType == ClaimantType.CLAIM_INJURED
      ) {
        defaultOtherSection = PhotoSection.INJURED_OTHERS_DOCS;
      } else if (props.sectionType === 'PRE-INSPECTIONS') {
        defaultOtherSection = PhotoSection.PREINSPECT_DOCS;
      }

      if (mp.section === defaultOtherSection) {
        photos.forEach((p) => {
          if (p.awsUrl && p.awsUrl?.indexOf('.pdf') > -1) {
            getPhotos.push(p);
          } else if (
            props.sectionType === 'PRE-INSPECTIONS' &&
            p.awsUrl &&
            p.awsUrl?.indexOf('VEHICLE_PROPERTY_REGISTRY') > -1
          ) {
            getPhotos.push(p);
          }
        });
      }

      return {
        ...mp,
        photos: getPhotos != null ? Array.from(getPhotos) : [],
      };
    });

    return mergePhotos;
  };

  const sectionsInTab = (type: string): SectionType[] => {
    const mergePhotos = handlePhotosWithSection(currentPhotos);

    const sections = mergePhotos.filter(
      (f) => f.type != null && f.type === type,
    );

    return sections;
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const renderSection = (
    sectionData: SectionType,
    allSections: SectionType[],
  ) => {
    return (
      <SectionPhotos
        key={sectionData.section}
        id={sectionData.section}
        title={sectionData.description}
        sectionType={props.sectionType}
        folderRoot={props.folderRoot}
        photos={sectionData.photos}
        allPhotos={
          allSections
            .filter((s) => s.photos && s.photos.length)
            .flatMap((s) => s.photos) as EventPhoto[]
        }
        handleSavePhoto={() => {
          props.handleSavePhoto();
          setInitValidations(true);
        }}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        handleRemovePhoto={(data: string) => {
          setTimeout(() => {
            fetchImages();
          }, 2000);
        }}
        handleRefreshPhotos={fetchImages}
        selectedPhotos={selectedPhotos}
        setSelectedPhotos={setSelectedPhotos}
        previousActivePhoto={previousActivePhoto}
        setPreviousActivePhoto={setPreviousActivePhoto}
        imageForZoom={imageForZoom}
        setImageForZoom={setImageForZoom}
        customPhotos={customPhotos}
        isReadOnly={galleryIsReadOnly()}
        setSections={props.setSections}
        sections={props.sections}
        eventRecord={props.eventRecord}
      />
    );
  };

  const bulkPhotoDelete = async (): Promise<void> => {
    if (selectedPhotos && selectedPhotos.length > 0 && !galleryIsReadOnly()) {
      setLoading(true, dispatch);

      selectedPhotos.forEach(async (url: string) => {
        try {
          const response = await deleteMediaFiles(url);
          if (!response) {
            const errorObj = {
              message: 'Cant delete image in bulk delete process',
              url: url,
            };
            throw new Error(JSON.stringify(errorObj));
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      });

      setShowConfirmBulkDelete(false);

      setTimeout(async () => {
        setSelectedPhotos([]);
        await fetchImages();
      }, 5000);
    }
  };

  return (
    <>
      {showNetAlert && (
        <Alert severity="warning" onClose={() => setShowNetAlert(false)}>
          Verificar tu conexión a Internet e intentar nuevamente.
        </Alert>
      )}
      {showImageInfoAlert && (
        <Alert
          severity="info"
          onClose={() => {
            setShowImageInfoAlert(false);
            userDispatch({
              type: ActionType.SET_RESTORE_NET_CONNECTION,
              payload: false,
            });
          }}
        >
          Tu conexión de Internet se ha restaurado. Hemos refrescado solo las
          imágenes que lograron sincronizarse.
        </Alert>
      )}
      <DndProvider backend={HTML5Backend}>
        <div className="sectionContainer">
          {!enableTabs &&
            photoSectionFields != null &&
            handlePhotosWithSection(currentPhotos).map(
              (rowOfSection, i, sections) => {
                return renderSection(rowOfSection, sections);
              },
            )}

          {enableTabs && photoSectionFields != null && (
            <>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="">
                <Tab label="Asegurado" {...allProps(0, tabValue)} />
                <Tab label="Perjudicado" {...allProps(1, tabValue)} />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                {photoSectionFields != null &&
                  sectionsInTab('insured').map((rowOfSection, i, sections) => {
                    return renderSection(rowOfSection, sections);
                  })}
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                {photoSectionFields != null &&
                  sectionsInTab('injured').map((rowOfSection, i, sections) => {
                    return renderSection(rowOfSection, sections);
                  })}
              </TabPanel>
            </>
          )}
        </div>
      </DndProvider>

      <Dialog
        open={messageTraffic}
        onClose={() => {
          setMessageTraffic(false);
          setShowTrafficAlertFirstTime(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Validación de Parte</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Las fotografía del Parte es requerido.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              setMessageTraffic(false);
              setShowTrafficAlertFirstTime(false);
            }}
            color="primary"
          >
            Entendido
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={messageBoletas}
        onClose={() => {
          setMessageBoletas(false);
          setShowBoletaAlertFirstTime(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Validación de Boletas</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Las fotos de Boletas son requeridas.
          </DialogContentText>
          <DialogContentText>
            <b>Nota:</b>
            <br />
            Si el evento actual es una inspección digital, por favor proceder a
            seguir el proceso desde el Formulario Virtual.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              setMessageBoletas(false);
              setShowBoletaAlertFirstTime(false);
            }}
            color="primary"
          >
            Entendido
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={messageCustomInsuredAlert}
        onClose={() => {
          setMessageCustomInsuredAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Validación de Fotografía
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              dangerouslySetInnerHTML={{ __html: messageCustomInsuredMsg }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              setMessageCustomInsuredAlert(false);
            }}
            color="primary"
          >
            Entendido
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={messageCustomInjuredAlert}
        onClose={() => {
          setMessageCustomInjuredAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Validación de Fotografía
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              dangerouslySetInnerHTML={{ __html: messageCustomInjuredMsg }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              setMessageCustomInjuredAlert(false);
            }}
            color="primary"
          >
            Entendido
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        className="confirm-modal"
        open={showConfirmBulkDelete}
        onClose={() => setShowConfirmBulkDelete(false)}
      >
        <div className="container">
          <img src={deleteIcon} alt="" />
          <h3>¿Deseas eliminar las imágenes seleccionadas?</h3>

          <div className="buttons-container">
            <Button variant="contained" onClick={bulkPhotoDelete}>
              Sí, eliminar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setShowConfirmBulkDelete(false);
              }}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>

      {selectedPhotos && selectedPhotos.length > 1 && (
        <>
          {isInspectorForm && !galleryIsReadOnly() && (
            <Fab
              id="selectedDelete"
              color="primary"
              aria-label="Remover seleccionados"
              onClick={() => setShowConfirmBulkDelete(true)}
            >
              <DeleteForeverIcon />
            </Fab>
          )}
          {!isInspectorForm && !galleryIsReadOnly() && (
            <Fab
              id="selectedDeleteDesktop"
              variant="extended"
              color="primary"
              aria-label="Remover seleccionados"
              onClick={() => setShowConfirmBulkDelete(true)}
            >
              <DeleteForeverIcon />
              Eliminar
            </Fab>
          )}
        </>
      )}
    </>
  );
};

export default Photos;
