import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  ButtonGroup,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Alert,
} from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import StatusChip from 'components/common/status/chip';
import { IClaim, IClaimRequest } from 'types/claimsTracker';
import {
  addOrUpdateClaimRequest,
  notifyClaimRequest,
  removeClaimRequest,
} from 'context/inspections';
import { getDateByCountry } from 'shared/utils';

interface props {
  currentClaim?: IClaim;
  onRefresh: () => void;
  onLoad: (value: boolean) => void;
  onNotify: (msg: string, warn: boolean) => void;
  disabled?: boolean;
}

const useTableStyles = makeStyles({
  table: {
    minWidth: 100,
  },
});

export default function ClaimRequest({
  currentClaim,
  onRefresh,
  onLoad,
  onNotify,
  disabled,
}: props): JSX.Element {
  const classesTbl = useTableStyles();

  const [showModalClaimRequest, setShowModalClaimRequest] = useState(false);
  const [
    showModalConfirmClaimRequest,
    setShowModalConfirmClaimRequest,
  ] = useState<boolean>(false);
  const [
    showModalNotifyClaimRequest,
    setShowModalNotifyClaimRequest,
  ] = useState(false);
  const [showErrorClaimRequest, setShowErrorClaimRequest] = useState<boolean>(
    false,
  );
  const [claimRequestDescription, setClaimRequestDescription] = useState<
    string
  >('');
  const [claimRequestIdSelected, setClaimRequestIdSelected] = useState<string>(
    '',
  );

  const StyledTableCell = withStyles(() =>
    createStyles({
      head: {
        backgroundColor: 'white',
        color: '#6d7b91',
      },
      body: {
        fontSize: 14,
      },
    }),
  )(TableCell);

  const StyledTableRow = withStyles(() =>
    createStyles({
      root: {
        backgroundColor: '#F0F4F7',
        borderBottom: '10px solid white',
      },
    }),
  )(TableRow);

  const disableOption = (): boolean => {
    if (disabled) {
      return true;
    } else {
      return Boolean(
        currentClaim?.stages.find(
          (stage) =>
            stage.name === 'DAMAGE_ANALYSIS' &&
            stage.status === 'FINALIZED_ADJUSTMENT',
        ),
      );
    }
  };

  const getStatus = (status: string) => {
    switch (status) {
      case 'COMPLETE':
        return 'Claim_Request_Complete';
      default:
        return 'Claim_Request_Pending';
    }
  };

  const addClaimRequest = () => {
    setClaimRequestIdSelected('');
    setClaimRequestDescription('');
    setShowErrorClaimRequest(false);
    setShowModalClaimRequest(true);
  };

  const changeClaimRequestDescription = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setClaimRequestDescription(event.target.value);
  };

  const changeClaimRequestId = (UId: string) => {
    setClaimRequestIdSelected(UId);
    setShowModalClaimRequest(true);
  };

  const getCurrentDescription = (UId: string): string => {
    const currentData = currentClaim?.request?.filter((f) => f.uId === UId)[0];
    if (currentData) {
      return currentData.description ?? '';
    }

    return '';
  };

  const sendClaimRequest = (notify: boolean): void => {
    if (claimRequestDescription.length > 5) {
      setShowModalClaimRequest(false);
      setShowErrorClaimRequest(false);
      onLoad(true);
      const currentUserStr = localStorage.getItem('currentUser') ?? '{}';
      const currentUserObj = JSON.parse(currentUserStr);
      (async (): Promise<void> => {
        if (currentUserObj) {
          const resultRequest = await addOrUpdateClaimRequest(
            claimRequestIdSelected,
            currentClaim?.claimUId ?? '',
            currentUserObj.email,
            claimRequestDescription,
            notify,
          );

          if (resultRequest.length > 0) {
            onRefresh();
          } else {
            onNotify(
              'Se ha detectado una degradación en el proceso de actualización. Por favor intentelo nuevamente',
              true,
            );
          }
        }
      })();
    } else {
      setShowErrorClaimRequest(true);
      onLoad(false);
    }
  };

  const sendRemoveClaimRequest = (UId: string): void => {
    onLoad(true);

    (async (): Promise<void> => {
      if (UId.length > 0) {
        setShowModalConfirmClaimRequest(false);
        await removeClaimRequest(claimRequestIdSelected);
        setClaimRequestIdSelected('');
        onRefresh();
      }
    })();
  };

  const sendNotifyClaimRequest = (UId: string): void => {
    onLoad(true);
    (async (): Promise<void> => {
      if (UId.length > 0) {
        await notifyClaimRequest(UId);
        setShowModalNotifyClaimRequest(false);
        onLoad(false);
      }
    })();
  };

  return (
    <>
      <div className="title-container">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h2 className="title">Solicitudes en la Reclamación</h2>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3} className="btn-top-options">
            <Button
              disabled={disableOption()}
              id="notify-claim-request"
              variant="text"
              color="secondary"
              onClick={() => setShowModalNotifyClaimRequest(true)}
              size="small"
            >
              Notificar
            </Button>
            {' | '}
            <Button
              disabled={disableOption()}
              variant="contained"
              size="small"
              onClick={addClaimRequest}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className="request-log">
        <TableContainer>
          <Table className={classesTbl.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Descripción</StyledTableCell>
                <StyledTableCell>Estado</StyledTableCell>
                <StyledTableCell>Fecha de Solicitud</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentClaim?.request?.length === 0 && (
                <StyledTableRow key={0}>
                  <StyledTableCell colSpan={4} align="center">
                    No se encontraron solicitudes
                  </StyledTableCell>
                </StyledTableRow>
              )}

              {Array.from(currentClaim?.request ?? []).map(
                (row: IClaimRequest, index: number) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.description}</StyledTableCell>
                    <StyledTableCell align="center">
                      <StatusChip eventStatus={getStatus(row.status ?? '')} />
                    </StyledTableCell>
                    <StyledTableCell>
                      {getDateByCountry(
                        row.createdDate ??
                          format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
                        localStorage.getItem('countryCode') ?? '',
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        disabled={disableOption()}
                        variant="contained"
                        className={clsx('claims__toggle-button-rm')}
                        size="small"
                        onClick={() => {
                          setClaimRequestIdSelected(row.uId ?? '');
                          setShowModalConfirmClaimRequest(true);
                        }}
                      >
                        Eliminar
                      </Button>
                      <Button
                        disabled={disableOption()}
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => {
                          changeClaimRequestId(row.uId ?? '');
                        }}
                      >
                        Editar
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        id="modal-claim-request"
        open={showModalClaimRequest}
        onClose={() => setShowModalClaimRequest(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          Agregar / Editar Solicitud
        </DialogTitle>
        <DialogContent id="content-dialog-claim">
          <DialogContentText id="alert-dialog-description">
            {showErrorClaimRequest && (
              <>
                <Alert severity="warning">
                  La <b>descripción</b> para el envío de la solicitud{' '}
                  <b>es requerida</b>.
                </Alert>
                <br />
              </>
            )}

            <TextField
              required
              key="txtClaimRequestDescription"
              className={clsx('claimsForm__input')}
              name="txtClaimRequestDescription"
              label="Describa la solicitud para el reclamante..."
              variant="outlined"
              size="small"
              multiline={true}
              rows={5}
              inputProps={{ maxLength: 790 }}
              defaultValue={getCurrentDescription(claimRequestIdSelected)}
              onChange={changeClaimRequestDescription}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setShowModalClaimRequest(false)}
            color="secondary"
            size="small"
          >
            Cancelar
          </Button>
          {claimRequestIdSelected.length > 0 ? (
            <Button
              variant="contained"
              onClick={() => sendClaimRequest(false)}
              size="small"
            >
              Actualizar
            </Button>
          ) : (
            <ButtonGroup
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
              size="small"
            >
              <Button onClick={() => sendClaimRequest(true)}>
                Agregar y Notificar
              </Button>
              <Button onClick={() => sendClaimRequest(false)}>
                Solo Agregar
              </Button>
            </ButtonGroup>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        id="modal-claim-remove-request"
        open={showModalConfirmClaimRequest}
        onClose={() => setShowModalConfirmClaimRequest(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar Solicitud</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Deseas eliminar la solicitud seleccionada?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowModalConfirmClaimRequest(false)}
            size="small"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => sendRemoveClaimRequest(claimRequestIdSelected)}
          >
            Sí, eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        id="modal-claim-notify-request"
        open={showModalNotifyClaimRequest}
        onClose={() => setShowModalNotifyClaimRequest(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Notificación de Solicitudes
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Deseas notificar al reclamante de las solicitudes actuales?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowModalNotifyClaimRequest(false)}
            size="small"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => sendNotifyClaimRequest(currentClaim?.claimUId ?? '')}
          >
            Sí, notificar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
