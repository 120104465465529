import React, { useRef, useEffect } from 'react';
import {
  CardContent,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { FilterNone } from '@mui/icons-material';
import QRCodeStyling from 'qr-code-styling';
import { copyToClipboard } from 'shared/utils';
import emailImage from '../../assets/images/email_image.svg';
import { Controller, FormContextValues } from 'react-hook-form';
import {
  CreatePreInspectionSendType,
  ICreatePreinspectionForm,
} from 'types/pre-inspection';

const qrCode = new QRCodeStyling({
  width: 200,
  height: 200,
  dotsOptions: {
    type: 'dots',
  },
  cornersSquareOptions: {
    type: 'square',
  },
  cornersDotOptions: {
    type: 'square',
  },
});

const CreateScreenPreinspection = ({
  screenIndex,
  option,
  url,
  form,
}: {
  screenIndex: number;
  option: CreatePreInspectionSendType | undefined;
  url: string;
  form: FormContextValues<ICreatePreinspectionForm>;
}): JSX.Element => {
  const qrCodeRef = useRef<HTMLElement>();

  useEffect(() => {
    if (screenIndex > 1) {
      qrCode.update({
        data: url,
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      qrCode.append(qrCodeRef.current!);
    }
  }, [screenIndex, option]);

  const copyLink = () => {
    copyToClipboard(url || '');
  };

  return (
    <>
      {screenIndex === 1 && (
        <form noValidate>
          <CardContent className="create-preinspection__content">
            <p>Ingrese la siguiente Información:</p>
            <TextField
              required
              className="cp-textField"
              label="Correo electrónico"
              variant="outlined"
              name="email"
              type="email"
              inputRef={form.register}
            />
            <TextField
              className="cp-textField"
              label="Número de documento de identidad"
              variant="outlined"
              name="id"
              inputRef={form.register}
            />
            <TextField
              className="cp-textField"
              label="Nombres"
              variant="outlined"
              name="name"
              inputRef={form.register}
            />
            <TextField
              className="cp-textField"
              label="Apellidos"
              variant="outlined"
              name="lastName"
              inputRef={form.register}
            />
            <TextField
              className="cp-textField"
              label="Placa"
              variant="outlined"
              name="vehicleplate"
              inputRef={form.register}
            />
            <TextField
              required
              className="cp-textField"
              label="Chasis"
              variant="outlined"
              name="vin"
              inputRef={form.register}
            />
            <Divider className="cp-divider"></Divider>
            <p>
              Seleccione cómo desea habilitar el link para realizar la
              Inspección Virtual:
            </p>
            <div>
              <Controller
                name="option"
                defaultValue=""
                control={form.control}
                as={
                  <RadioGroup
                    aria-labelledby="controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={CreatePreInspectionSendType.QR}
                      control={<Radio />}
                      label="Código QR"
                      className="cp-radio"
                    />
                    <FormControlLabel
                      value={CreatePreInspectionSendType.EMAIL}
                      control={<Radio />}
                      label="Correo electrónico"
                      className="cp-radio"
                    />
                  </RadioGroup>
                }
              ></Controller>
            </div>
          </CardContent>
        </form>
      )}

      {screenIndex === 2 && option == CreatePreInspectionSendType.QR && (
        <CardContent className="create-preinspection__content">
          <p>Escaneá este código QR</p>
          <div ref={qrCodeRef as React.RefObject<HTMLDivElement>} />
          <Divider className="cp-divider">o</Divider>
          <p>Copia y comparte esta URL</p>
          <div className="cp-link">
            <p>{url}</p>
            <IconButton className="cp-link__button" onClick={copyLink}>
              <FilterNone />
            </IconButton>
          </div>
        </CardContent>
      )}

      {screenIndex === 2 && option == CreatePreInspectionSendType.EMAIL && (
        <CardContent className="create-preinspection__content">
          <p>¡Se ha enviado el enlace al correo electrónico!</p>
          <img src={emailImage} alt=""></img>
        </CardContent>
      )}
    </>
  );
};

export default CreateScreenPreinspection;
