import { makeStyles, createStyles } from '@mui/styles';
import { globalCss } from 'assets/connect/styles/_variables';

export default makeStyles(() =>
  createStyles({
    //menu
    menu: {
      boxShadow: globalCss.boxShadow,
      color: globalCss.boulder,
    },
    menuOption: {
      fontSize: '14px',
      padding: '8px',
      color: globalCss.boulder,
    },
    menuOptionIcon: {
      marginRight: '6px',
      color: globalCss.boulder,
    },
  }),
);
