import { EventPhoto } from 'types/images';

export const keepOrderPhotos = (
  currentList: EventPhoto[],
  newList: EventPhoto[],
): EventPhoto[] => {
  const orderPhotos: EventPhoto[] = [];

  currentList.forEach((cPhoto: EventPhoto) => {
    if (cPhoto.awsUrl) {
      const urlToSearch =
        cPhoto.awsUrl?.indexOf('?') > 0
          ? cPhoto.awsUrl.split('?')[0]
          : cPhoto.awsUrl;
      const findPhoto = newList?.find(
        (f) => f.awsUrl != null && f.awsUrl?.indexOf(urlToSearch) > -1,
      );
      if (findPhoto != null) {
        orderPhotos.push(findPhoto);
      }
    }
  });

  newList.forEach((nPhoto: EventPhoto) => {
    if (nPhoto.awsUrl) {
      const urlToSearch =
        nPhoto.awsUrl?.indexOf('?') > 0
          ? nPhoto.awsUrl.split('?')[0]
          : nPhoto.awsUrl;
      const findPhoto = currentList?.find(
        (f) => f.awsUrl != null && f.awsUrl?.indexOf(urlToSearch) > -1,
      );
      if (findPhoto == null) {
        orderPhotos.push(nPhoto);
      }
    } else {
      orderPhotos.push(nPhoto);
    }
  });

  return orderPhotos;
};
