import './styles.scss';
import Alert from '@mui/material/Alert';
import clsx from 'clsx';
import Loader from 'components/common/loader/Loader';
import ValidationLabel from 'components/common/validation-label';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import InputMask from 'react-input-mask';
import style from './override-mui-styles';
import {
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
} from '@mui/material';
import {
  IUserContext,
  UserContext,
  activeUserWithCode,
  claimsAuth,
  getCurrentCognitoUser,
  getUserInitialScreen,
} from 'context/user';
import { codeMask } from 'types/common';

interface VerificationCodeProps {
  style: any;
  open: boolean | false;
  onClose: () => void;
  onSendCode: (code: string) => void;
}

interface ChallengeCodeProps {
  style: any;
  open: boolean | false;
  hasError: boolean | false;
  errorMessage?: string;
  onClose: () => void;
  onSendCode: (code: string) => void;
  onRetryNewCode: () => void;
}

const VerificationCode = (props: VerificationCodeProps) => {
  const { handleSubmit, register, errors } = useForm();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmitCode = (data: any) => {
    props.onSendCode(data);
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="dialog-title"
      open={props.open}
    >
      <form
        className={'form'}
        onSubmit={handleSubmit(handleSubmitCode)}
        noValidate
      >
        <DialogTitle id="dialog-title">Confirmar Cuenta Connect</DialogTitle>
        <DialogContent>
          <Typography
            component="h4"
            variant="h4"
            className={clsx('description', props.style.description)}
          >
            Digite el código enviado a tu correo:
          </Typography>
          <TextField
            error={errors.code !== undefined}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="code"
            label="Código de verificación"
            name="code"
            autoComplete="code"
            autoFocus
            inputRef={register({
              required: 'Código de verificación es requerido',
            })}
          />
          {errors.code && (
            <ValidationLabel message={errors.code.message} isError={true} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button type="submit" color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const ChallengeCode = (props: ChallengeCodeProps) => {
  const { handleSubmit, register, errors } = useForm();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmitCode = (data: any) => {
    const filterData = { ...data, code: data.code.replaceAll(' ', '') };

    props.onSendCode(filterData);
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose();
        }
      }}
      disableEscapeKeyDown
      aria-labelledby="dialog-title"
      open={props.open}
    >
      <form
        className={'form'}
        onSubmit={handleSubmit(handleSubmitCode)}
        noValidate
      >
        <DialogTitle id="dialog-title">Autorización de Acceso</DialogTitle>
        <DialogContent>
          {props.hasError && (
            <Alert severity="error">{props.errorMessage}</Alert>
          )}
          <Typography
            component="h4"
            variant="h4"
            className={clsx('description', props.style.description)}
          >
            Digite el código enviado a tu contacto vía SMS:
          </Typography>
          <InputMask mask={codeMask['MFA']}>
            {() => (
              <TextField
                error={errors.code !== undefined}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                key="code"
                id="code"
                label="Código de verificación"
                name="code"
                autoComplete="code"
                className="mfaInput"
                autoFocus
                inputRef={register({
                  required: 'Código de verificación es requerido',
                })}
              />
            )}
          </InputMask>
          {errors.code && (
            <ValidationLabel message={errors.code.message} isError={true} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            onClick={props.onRetryNewCode}
          >
            Nuevo Código
          </Button>
          <Button type="submit" color="primary" variant="contained" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const SignIn = (): JSX.Element => {
  const overrideMuiClasses = style();
  const currentHistory = useHistory();
  const redirectTo = currentHistory.location.search.split('redirectTo=')[1];

  const { state, dispatch } = useContext<IUserContext>(UserContext);
  const {
    loading,
    userSession,
    currentUserStatus,
    showMfaChallenge,
    sessionMFAChallenge,
  } = state;

  const { handleSubmit, register, errors, getValues } = useForm();
  const [openVerificationCode, setOpenVerificationCode] = useState(false);
  const [openChallengeCode, setOpenChallengeCode] = useState(showMfaChallenge);

  const onSubmit = useCallback(
    (data: any) => {
      claimsAuth(data, dispatch);
    },
    [dispatch],
  );

  const handleCloseModalCode = () => {
    setOpenVerificationCode(false);
  };

  const handleCloseChallegeModal = () => {
    setOpenChallengeCode(false);
  };

  const handleSendModalCode = (data: any) => {
    activeUserWithCode(
      data.code,
      state?.userSession?.sessionAttr?.email,
      dispatch,
    );
    handleCloseModalCode();
  };

  const handleSendChallengeCode = (data: any) => {
    if (sessionMFAChallenge) {
      const userDataForChallenge = {
        username: getValues('username'),
        password: getValues('password'),
        verifyCode: data.code,
        challengeSession: sessionMFAChallenge,
      };

      claimsAuth(userDataForChallenge, dispatch);
    }
  };

  const handleRetryNewChallengeCode = (): void => {
    claimsAuth(
      {
        username: getValues('username'),
        password: getValues('password'),
      },
      dispatch,
    );
  };

  const validateUserSession = useCallback(() => {
    (async (): Promise<void> => {
      const { isAuthenticate, sessionExpired } = await getCurrentCognitoUser();

      if (isAuthenticate && !sessionExpired) {
        currentHistory.push(redirectTo || getUserInitialScreen());
      }
    })();
  }, [dispatch, currentHistory, currentUserStatus, redirectTo]);

  useEffect(() => {
    // User Session
    if (!currentUserStatus.role) {
      validateUserSession();
    }

    if (currentUserStatus.isLogin && currentUserStatus.token) {
      currentHistory.push(redirectTo || getUserInitialScreen());
    } else if (
      currentUserStatus.isLogin &&
      currentUserStatus.message === 'REQUEST_LOGIN'
    ) {
      onSubmit({
        username: getValues('username'),
        password: getValues('password'),
      });
    } else if (userSession.confirmAccount) {
      setOpenVerificationCode(true);
    }
  }, [
    userSession,
    currentUserStatus,
    currentHistory,
    setOpenVerificationCode,
    onSubmit,
    getValues,
    validateUserSession,
    redirectTo,
  ]);

  useEffect(() => {
    if (showMfaChallenge) {
      setOpenChallengeCode(true);
    }
  }, [showMfaChallenge]);

  return (
    <>
      <Loader isActive={loading} />
      <Typography
        className={clsx('title', overrideMuiClasses.title)}
        component="h1"
        variant="h2"
      >
        BIENVENIDO
      </Typography>
      <Typography
        className={clsx('subtitle', overrideMuiClasses.subtitle)}
        component="h1"
        variant="h4"
      >
        Ingrese a su cuenta
      </Typography>
      <form className={'form'} onSubmit={handleSubmit(onSubmit)} noValidate>
        {currentUserStatus.isError && (
          <Alert severity="error">{currentUserStatus.message}</Alert>
        )}
        <TextField
          error={errors.username !== undefined}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Usuario"
          name="username"
          autoComplete="username"
          autoFocus
          inputRef={register({ required: 'Usuario es requerido' })}
          InputProps={{
            classes: {
              input: overrideMuiClasses.loginTextField,
            },
          }}
        />
        {errors.username && (
          <ValidationLabel message={errors.username.message} isError={true} />
        )}
        <TextField
          error={errors.password !== undefined}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          autoComplete="current-password"
          inputRef={register({ required: 'Contraseña es requerida' })}
          InputProps={{
            classes: {
              input: overrideMuiClasses.loginTextField,
            },
          }}
        />
        {errors.password && (
          <ValidationLabel message={errors.password.message} isError={true} />
        )}
        <Grid container>
          <Grid item xs>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recuerdame"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              className={'passwordRestoreLabel'}
              control={
                <Link
                  className={overrideMuiClasses.passwordRestoreLink}
                  href="/request-access"
                  variant="body2"
                >
                  ¿Olvidó contraseña?
                </Link>
              }
              label=""
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={overrideMuiClasses.submit}
        >
          Ingresar
        </Button>
      </form>

      {openVerificationCode && (
        <VerificationCode
          open={openVerificationCode}
          style={overrideMuiClasses}
          onSendCode={handleSendModalCode}
          onClose={handleCloseModalCode}
        />
      )}

      {openChallengeCode && (
        <ChallengeCode
          open={openChallengeCode}
          hasError={currentUserStatus.isError}
          errorMessage={currentUserStatus.message}
          style={overrideMuiClasses}
          onSendCode={handleSendChallengeCode}
          onRetryNewCode={handleRetryNewChallengeCode}
          onClose={handleCloseChallegeModal}
        />
      )}
    </>
  );
};

export default SignIn;
