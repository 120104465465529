import axios from 'axios';

export default class RequestQueue {
  storageKey: string;
  queue: any[];
  isProcessing: boolean;

  constructor(storageKey: string) {
    this.storageKey = storageKey;
    this.queue = this.loadQueue();
    this.isProcessing = false;
  }

  // Add request to end of queue
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  enqueue(request: any): void {
    const serializedRequest = this.serializeRequest(request);
    this.queue.push(serializedRequest);
    this.saveQueue();
    this.processQueue();
  }

  // Save the Queue in the localStorage
  saveQueue(): void {
    try {
      localStorage.setItem(this.storageKey, JSON.stringify(this.queue));
    } catch (error) {
      console.error('Error saving request queue:', error);
    }
  }

  // Load Queue from localStorage
  loadQueue(): any[] {
    try {
      const savedQueue = localStorage.getItem(this.storageKey);

      return savedQueue ? JSON.parse(savedQueue) : [];
    } catch (error) {
      console.error('Error loading request queue:', error);

      return [];
    }
  }

  // Serialize the request
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  serializeRequest(request: any): any {
    return {
      url: request.config.baseURL + request.config.url,
      method: request.config.method,
      data: request.config.data,
      headers: request.config.headers,
      resolve: request.resolve,
      reject: request.reject,
    };
  }

  // Process the Queue
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async processQueue(callback?: any): Promise<void> {
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;

    while (this.queue.length > 0) {
      const serializedRequest = this.queue.shift(); // Get the first element
      this.saveQueue();

      const config = {
        url: serializedRequest.url,
        method: serializedRequest.method,
        data: serializedRequest.data,
        headers: serializedRequest.headers,
      };

      try {
        await axios(config);
      } catch (error) {
        this.queue.unshift(serializedRequest); // Reinsert at startup if failed
        this.saveQueue();
        this.isProcessing = false;
        console.error('Error to Send Save Queue :: ', error);
        break;
      }
    }

    this.isProcessing = false;
    if (callback) {
      callback();
    }
  }

  pendingRequestToProcess(): number {
    return this.queue.length;
  }

  processIsActive(): boolean {
    return this.isProcessing;
  }
}
