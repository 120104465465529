import { Rol } from 'types/common';

const useJiraWidget = (): void => {
  const roleId = localStorage.getItem('rolId') ?? '';
  const jiraRolePemissions = [
    Rol.ADMIN.toString(),
    Rol.ADJUSTER.toString(),
    Rol.AUDIT.toString(),
  ];

  const iframe: any = document.getElementById('jsd-widget');
  if (iframe) {
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow?.document;
    if (iframeDocument) {
      iframeDocument.getElementById(
        'button-container',
      ).style.display = jiraRolePemissions.includes(roleId) ? 'block' : 'none';
    }
  }
};

export default useJiraWidget;
