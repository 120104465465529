import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import './index.scss';
import { Grid, TextField } from '@mui/material';
import DataTable from '../grid';
import Button from '../button';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import { ActionType } from 'types/action';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import DialogComponent from 'components/common/dialog';

export interface PartFields {
  partDescription: string;
  partAmount: number | undefined;
}

export interface PropsAutoParts {
  claimUId?: string;
  disabled: boolean;
}

const columns: GridColDef[] = [
  {
    field: 'partDescription',
    headerName: 'Descripcion del repuesto',
    width: 300,
  },
  { field: 'partAmount', headerName: 'Valor', width: 150 },
];

const ClaimAutoParts: FunctionComponent<PropsAutoParts> = ({
  claimUId,
  disabled,
}): JSX.Element => {
  useEffect(() => {
    getAllParts().catch(console.error);
  }, []);
  const { dispatch, state } = useContext<IInspectionContext>(InspectionContext);

  const [dataHistoric, setDataHistoric] = useState([]);
  const [partFields, setPartFields] = useState<PartFields>({
    partDescription: '',
    partAmount: undefined,
  });
  const [selectionFields, setSelectionFields] = useState<GridRowId[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const getAllParts = async (): Promise<void> => {
    try {
      dispatch({
        type: ActionType.SET_PARTS_AMOUNT,
        payload: 0,
      });

      const result = await new ClaimsTrackerClient().getAllParts(claimUId);

      setDataHistoric(result);

      if (result?.length > 0) {
        let totalParts = 0;

        for (const row of result) {
          totalParts += row.partAmount;
        }

        dispatch({
          type: ActionType.SET_PARTS_AMOUNT,
          payload: totalParts,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onClickSave = async (): Promise<void> => {
    try {
      dispatch({ type: ActionType.SET_LOADING, payload: true });
      const result = await new ClaimsTrackerClient().SavePartAmount(
        partFields,
        claimUId,
      );

      if (result?.data?.id) {
        setPartFields({
          partDescription: '',
          partAmount: 0,
        });

        await getAllParts();
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    } catch (e) {
      console.warn(e);
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const validateFields = (): boolean => {
    if (!partFields.partDescription) {
      return true;
    }

    if (!partFields.partAmount) {
      return true;
    }

    return false;
  };

  const handleChange = (event: any): void => {
    const data = partFields;
    data[event?.target?.name] = event?.target?.value;
    setPartFields({ ...data });
  };

  const openConfirmDialog = (): void => {
    setOpenDialog(true);
  };

  const handleDelete = async (): Promise<void> => {
    try {
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      for (const id of selectionFields) {
        await new ClaimsTrackerClient().DeletePartAmount(id as number);
      }

      setSelectionFields([]);
      await getAllParts();

      dispatch({ type: ActionType.SET_LOADING, payload: false });
    } catch (e) {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const handleCancel = (): void => {
    setSelectionFields([]);
  };

  const handleConfirm = async (): Promise<void> => {
    setOpenDialog(false);
    await handleDelete();
  };

  return (
    <Grid item xs={12} sm={6} md={6} className="part">
      <Grid className="part__data">
        <Grid>
          <TextField
            className="part__data--description"
            name="partDescription"
            label="Nombre del repuesto *"
            type="text"
            value={partFields.partDescription}
            onChange={handleChange}
            disabled={disabled}
          ></TextField>
        </Grid>
      </Grid>
      <Grid className="part__data">
        <Grid>
          <TextField
            name="partAmount"
            label="Valor *"
            type="number"
            value={partFields.partAmount}
            onChange={handleChange}
            disabled={disabled}
          ></TextField>
        </Grid>
        <Grid className="part__data--button-save">
          <Button
            disabled={validateFields()}
            text="Agregar Repuesto"
            onClick={() => {
              if (!disabled) {
                onClickSave();
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid className="part__table">
        {selectionFields.length > 0 && (
          <Grid className="part__table--options">
            <span>{selectionFields.length} items selected</span>
            <Grid className="part__table--options--actions">
              <Button
                text="Eliminar"
                variant="text"
                style={{ color: 'white', fontSize: 14 }}
                onClick={openConfirmDialog}
              ></Button>
              <span>|</span>
              <Button
                text="Cancel"
                variant="text"
                style={{ color: 'white', fontSize: 14 }}
                onClick={handleCancel}
              ></Button>
            </Grid>
          </Grid>
        )}
        <Grid className="part__table--values">
          <div className="titleValueTable">VALORES</div>
          <div className="containerValuesTable">
            <span className="containerValuesTable__subtitle">Total:</span>
            <b>${state.partsAmount}</b>
          </div>
        </Grid>
        <DataTable
          columns={columns}
          data={dataHistoric}
          showCheckBox
          selectionModel={selectionFields}
          onRowSelectionModelChange={(rows: any): void =>
            setSelectionFields(rows)
          }
        ></DataTable>
      </Grid>

      <DialogComponent
        messageStyle={{ fontSize: '16px' }}
        message={`¿Desea eliminar los ${selectionFields.length} registros seleccionados?`}
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={handleConfirm}
      />
    </Grid>
  );
};

export default ClaimAutoParts;
