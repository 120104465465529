import { makeStyles, createStyles } from '@mui/styles';
import { globalCss } from 'assets/connect/styles/_variables';

export default makeStyles(() =>
  createStyles({
    inputRoot: {
      width: '100%',
      backgroundColor: globalCss.aquaHaze,
      height: '38px',
      minWidth: '200px',
      padding: '0 8px',
      '& fieldset': {
        border: 'none',
      },
    },
  }),
);
