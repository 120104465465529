import { Tab, Tabs } from '@mui/material';
import { TabPanel } from 'components/common/tabpanel/TabPanel';
import ActivityLog from 'components/events/event-state/ActivityLog';
import AuditLogs from 'components/events/event-state/AuditLog';
import Observations from 'components/events/event-state/Observations';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { userActionsPermissions } from 'shared/utils';
import { ResourceAction } from 'types/common';
import { ITransition } from 'types/common';
import './styles.scss';

function tableData(name: string, fecha: string, detalle: string) {
  return { name, fecha, detalle };
}

interface Props {
  states: ITransition[];
  sectionType: string;
  isReadOnly?: boolean;
}

const EventState = (props: Props): JSX.Element => {
  const history = useHistory();
  const qParams = history.location.search.split('lt=')[1];
  const logTab = Number(qParams ? qParams.split('&')[0] : 0);
  const [tabValue, setTabValue] = useState<number>(logTab);
  const [rowStates, setRowStates] = useState<any>([]);

  useEffect(() => {
    if (props.states && Array.from(rowStates).length === 0) {
      const currentStates = Array.from(rowStates);
      props.states.map((stateVal: ITransition) => {
        const data = tableData(
          stateVal.Name,
          stateVal.TransitionDate,
          stateVal.Description,
        );
        currentStates.push(data);

        return true;
      });
      setRowStates(currentStates);
    }
  }, [setRowStates, props, rowStates]);

  const allProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const observationPermission = (): boolean => {
    return Boolean(
      userActionsPermissions(ResourceAction.VIEW_EVENT_OBSERVATIONS),
    );
  };

  return (
    <div className="eventStateContainer">
      <Tabs
        value={tabValue}
        onChange={(event: any, newValue: number) => setTabValue(newValue)}
        aria-label=""
      >
        <Tab label="Transiciones" {...allProps(0)} />
        {observationPermission() === true &&
          props.sectionType === 'INSPECTIONS' && (
            <Tab label="Observaciones" {...allProps(1)} />
          )}
        {props.sectionType === 'INSPECTIONS' && (
          <Tab label="Bitácora" {...allProps(2)} />
        )}
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <ActivityLog
          sectionType={props.sectionType}
          isReadOnly={props.isReadOnly}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Observations />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <AuditLogs />
      </TabPanel>
    </div>
  );
};
export default EventState;
