import React from 'react';
import { useDrop } from 'react-dnd';
import { EventPhoto } from 'types/images';

interface DragPhoto extends EventPhoto {
  type: string;
}

const style: React.CSSProperties = {
  width: '100%',
};

export interface DropAreaProps {
  areaId: number;
  onDrop: (item: EventPhoto, sectionId: number) => void;
}

const DropArea: React.FC<DropAreaProps> = ({ areaId, onDrop, children }) => {
  const drop = useDrop({
    accept: ['photo'],
    drop(item: DragPhoto) {
      onDrop(item, areaId);

      return undefined;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      draggingColor: monitor.getItemType() as string,
    }),
  })[1];

  return (
    <div ref={drop} style={style}>
      {children}
    </div>
  );
};

export default DropArea;
