import React, { FunctionComponent } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

interface PropsFileInput {
  fileName: string;
  style?: any;
  handleFileChange: (event: any) => void;
  disabled?: boolean;
}

const FileInput: FunctionComponent<PropsFileInput> = ({
  fileName,
  style,
  disabled,
  handleFileChange,
}): JSX.Element => {
  return (
    <TextField
      value={fileName}
      style={style}
      type="text"
      disabled
      placeholder="Cargar Documento"
      InputProps={{
        endAdornment: (
          <IconButton edge="end" component="label" disabled={disabled}>
            <CloudUploadOutlinedIcon />
            <input hidden type="file" onChange={handleFileChange} />
          </IconButton>
        ),
      }}
    />
  );
};

export default FileInput;
