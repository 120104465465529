import { Checkbox, Grid, Link, TextField } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import DialogComponent from 'components/common/dialog';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import { formatNumber } from 'shared/utils';
import { ActionType } from 'types/action';
import { AdjustAmounts, calculateNegligence } from '../adjust';
import Button from '../button';
import DataTable from '../grid';
import './index.scss';
import { IClaimForm } from 'types/claimsTracker';

interface props {
  totalEstimated: string;
  claimUId?: string;
  visible: boolean;
  claimForm?: IClaimForm;
}

const BreakdownTotalLoss: FunctionComponent<props> = ({
  totalEstimated,
  claimUId,
  visible,
  claimForm,
}): JSX.Element => {
  const { dispatch } = useContext<IInspectionContext>(InspectionContext);
  const [dataHistoric, setDataHistoric] = useState<any>([]);
  const [selectionFields, setSelectionFields] = useState<GridRowId[]>([]);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [breakdownState, setBreakdownState] = useState<AdjustAmounts>({
    estimatedTotalAmount: totalEstimated,
    porcDepreciation: '0',
    percNegligence: '0',
    depreciationAmount: '0',
    deductibleAmount: '',
    advancePayment: '0',
    totalInsuranceAmount: '0',
    customerResponsabilityAmount: '0',
    constructive: '',
    fines: '',
    missingParts: '',
    negligence: '',
    others: '',
    othersPlus: '',
    totalToPay: '',
    trailer: '',
    isTotalLoss: true,
    closedWithoutPayment: false,
  });

  const columns: GridColDef[] = [
    { field: 'totalToPay', headerName: 'Estimado', width: 100 },
    { field: 'createdDate', headerName: 'Fecha', width: 180 },
    { field: 'userName', headerName: 'Usuario', width: 100 },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 100,
      renderCell: (params) => (
        <Link
          className="claim-estimated__table--link"
          href="#"
          onClick={() => loadBreakdown(params.id)}
        >
          Ver detalle
        </Link>
      ),
    },
    {
      field: 'fileUrl',
      headerName: 'Documentos',
      width: 150,
      renderCell: (params) => (
        <Link
          className="claim-adjust__table--link"
          target="_blank"
          href={params.value}
          hidden={!params.value}
        >
          Ver documento
        </Link>
      ),
    },
  ];

  useEffect(() => {
    getAllBreakdown().catch(console.error);
  }, []);
  useEffect(() => {
    const data = breakdownState;
    data.estimatedTotalAmount = totalEstimated;
    setBreakdownState({ ...data });
  }, [totalEstimated]);
  const handleCancel = (): void => {
    setSelectionFields([]);
  };

  const openConfirmDialog = (): void => {
    setOpenDialogConfirm(true);
  };

  const loadBreakdown = (id: any): void => {
    const register = dataHistoric.find((x) => x.id === id);
    calculateBreakdown(register);
  };

  const handleConfirm = async (): Promise<void> => {
    setOpenDialogConfirm(false);
    await handleDelete();
  };
  const handleDelete = async (): Promise<void> => {
    try {
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      for (const id of selectionFields) {
        await new ClaimsTrackerClient().DeleteAdjustAmount(id as number);
      }

      setSelectionFields([]);
      await getAllBreakdown();

      dispatch({ type: ActionType.SET_LOADING, payload: false });
    } catch (e) {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const onCalcBreakdown = (event: any): void => {
    if (breakdownState) {
      const data = breakdownState;
      data[event?.target?.name] = event?.target?.value.replace(/[^0-9.]/g, '');
      setBreakdownState({ ...data });
      calculateBreakdown(data);
    }
  };
  const calculateBreakdown = (data: AdjustAmounts): void => {
    const constructive = Number(data.constructive ?? 0);
    const currentAmount = Number(data.estimatedTotalAmount ?? 0);
    const deductible = Number(data.deductibleAmount ?? 0);
    const fines = Number(data.fines ?? 0);
    const missingParts = Number(data.missingParts ?? 0);
    // const negligence = Number(data.negligence ?? 0);
    const others = Number(data.others ?? 0);
    const othersPlus = Number(data.othersPlus ?? 0);
    const trailer = Number(data.trailer ?? 0);
    const percNegligence = Number(data.percNegligence ?? 0);

    const negligence = calculateNegligence(
      percNegligence,
      currentAmount - deductible,
    );

    const total =
      currentAmount -
      deductible -
      fines -
      missingParts -
      negligence -
      constructive -
      others +
      trailer +
      othersPlus;

    data.negligence = negligence.toFixed(2);
    data.totalToPay = total.toFixed(2);

    setBreakdownState({ ...data });
  };

  const validateBreakdown = (): boolean => {
    if (!breakdownState.constructive) {
      return true;
    }

    if (!breakdownState.estimatedTotalAmount) {
      return true;
    }

    if (!breakdownState.deductibleAmount) {
      return true;
    }

    if (!breakdownState.fines) {
      return true;
    }

    if (!breakdownState.missingParts) {
      return true;
    }

    if (!breakdownState.negligence) {
      return true;
    }

    if (!breakdownState.others) {
      return true;
    }
    if (!breakdownState.othersPlus) {
      return true;
    }
    if (!breakdownState.trailer) {
      return true;
    }
    if (!breakdownState.totalToPay) {
      return true;
    }

    return false;
  };
  const getAllBreakdown = async (): Promise<void> => {
    try {
      dispatch({
        type: ActionType.SET_ADJUST_AMOUNT,
        payload: undefined,
      });
      const result = await new ClaimsTrackerClient().getAllAdjustAmount(
        claimUId,
        true,
      );

      if (result?.length > 0) {
        const data = result.slice(-1)[0];
        dispatch({
          type: ActionType.SET_ADJUST_AMOUNT,
          payload: '1',
        });
      }

      result.forEach((x) => {
        x.createdDate = x.createdDate.substring(0, 10);
      });

      setDataHistoric(result);
    } catch (e) {
      console.error(e);
    }
  };
  const onChangeClosedWithoutPayment = (event: any): void => {
    const data = breakdownState;
    data.closedWithoutPayment = event?.target?.checked;
    setBreakdownState({ ...data });
  };
  const onClickSave = async (): Promise<void> => {
    try {
      dispatch({ type: ActionType.SET_LOADING, payload: true });
      const result = await new ClaimsTrackerClient().SaveAdjustAmount(
        breakdownState,
        claimUId,
      );

      if (result?.data?.success) {
        setBreakdownState({
          estimatedTotalAmount: totalEstimated,
          porcDepreciation: '0',
          percNegligence: '0',
          depreciationAmount: '0',
          deductibleAmount: '',
          advancePayment: '0',
          totalInsuranceAmount: '0',
          customerResponsabilityAmount: '0',
          constructive: '',
          fines: '',
          missingParts: '',
          negligence: '',
          others: '',
          othersPlus: '',
          totalToPay: '',
          trailer: '',
          isTotalLoss: true,
          closedWithoutPayment: false,
        });

        await getAllBreakdown();
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    } catch (e) {
      console.warn(e);
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  return (
    <>
      {visible ? (
        <>
          <div>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="claim-breakdownTotalLoss"
            >
              <Grid className="claim-breakdownTotalLoss__data--title">
                <p>ESTIMADO</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className="claim-breakdownTotalLoss__content"
              >
                <Grid className="claim-breakdownTotalLoss__data">
                  <Grid>
                    <Grid className="claim-breakdownTotalLoss__data--row">
                      <p className="claim-breakdownTotalLoss__data--icon">+</p>
                      <TextField
                        className="claim-breakdownTotalLoss__data--row--input"
                        label="Costo Actual  *"
                        value={formatNumber(
                          breakdownState.estimatedTotalAmount,
                        )}
                        name="estimatedTotalAmount"
                        onChange={onCalcBreakdown}
                      ></TextField>
                    </Grid>
                    <Grid className="claim-breakdownTotalLoss__data--row">
                      <p className="claim-breakdownTotalLoss__data--icon">-</p>
                      <TextField
                        className="claim-breakdownTotalLoss__data--row--input"
                        label="Deducible"
                        onChange={onCalcBreakdown}
                        name="deductibleAmount"
                        value={formatNumber(breakdownState.deductibleAmount)}
                      ></TextField>
                    </Grid>
                    <Grid className="claim-breakdownTotalLoss__data--dep">
                      <Grid className="claim-breakdownTotalLoss__data">
                        <Checkbox
                          checked={breakdownState.closedWithoutPayment}
                          onChange={onChangeClosedWithoutPayment}
                        />
                        <label className="claim-adjust__label">
                          Cerrado sin Pago
                        </label>
                      </Grid>
                    </Grid>
                    {claimForm?.info.type === 'Perjudicado' ? (
                      <Grid className="claim-adjust__data--dep">
                        <Grid className="claim-adjust__data">
                          <p className="claim-adjust__data--icon">-</p>
                          <TextField
                            // disabled={disabled}
                            name="percNegligence"
                            className="claim-adjust__data--dep--porc"
                            label="% Neg *"
                            value={formatNumber(breakdownState.percNegligence)}
                            onChange={onCalcBreakdown}
                          ></TextField>
                        </Grid>
                        <Grid>
                          <TextField
                            disabled
                            className="claim-adjust__data--dep--amount"
                            label="Negligencia"
                            value={formatNumber(breakdownState.negligence)}
                          ></TextField>
                        </Grid>
                      </Grid>
                    ) : null}
                    <Grid className="claim-breakdownTotalLoss__data--row">
                      <p className="claim-breakdownTotalLoss__data--icon">-</p>
                      <TextField
                        className="claim-breakdownTotalLoss__data--row--input"
                        label="Multas"
                        onChange={onCalcBreakdown}
                        name="fines"
                        value={formatNumber(breakdownState.fines)}
                      ></TextField>
                    </Grid>
                    <Grid className="claim-breakdownTotalLoss__data--row">
                      <p className="claim-breakdownTotalLoss__data--icon">-</p>
                      <TextField
                        className="claim-breakdownTotalLoss__data--row--input"
                        label="Piezas Faltantes"
                        onChange={onCalcBreakdown}
                        name="missingParts"
                        value={formatNumber(breakdownState.missingParts)}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid className="claim-breakdownTotalLoss__data--row">
                      <p className="claim-breakdownTotalLoss__data--icon">-</p>
                      <TextField
                        className="claim-breakdownTotalLoss__data--row--input"
                        label="Constructiva"
                        onChange={onCalcBreakdown}
                        name="constructive"
                        value={formatNumber(breakdownState.constructive)}
                      ></TextField>
                    </Grid>
                    <Grid className="claim-breakdownTotalLoss__data--row">
                      <p className="claim-breakdownTotalLoss__data--icon">-</p>
                      <TextField
                        className="claim-breakdownTotalLoss__data--row--input"
                        label="Otros"
                        onChange={onCalcBreakdown}
                        name="others"
                        value={formatNumber(breakdownState.others)}
                      ></TextField>
                    </Grid>
                    <Grid className="claim-breakdownTotalLoss__data--row">
                      <p className="claim-breakdownTotalLoss__data--icon">+</p>
                      <TextField
                        className="claim-breakdownTotalLoss__data--row--input"
                        label="Remolque"
                        onChange={onCalcBreakdown}
                        name="trailer"
                        value={formatNumber(breakdownState.trailer)}
                      ></TextField>
                    </Grid>
                    <Grid className="claim-breakdownTotalLoss__data--row">
                      <p className="claim-breakdownTotalLoss__data--icon">+</p>
                      <TextField
                        className="claim-breakdownTotalLoss__data--row--input"
                        label="Otros"
                        onChange={onCalcBreakdown}
                        name="othersPlus"
                        value={formatNumber(breakdownState.othersPlus)}
                      ></TextField>
                    </Grid>

                    <Grid className="claim-breakdownTotalLoss__data--row">
                      <p className="claim-breakdownTotalLoss__data--icon">=</p>
                      <TextField
                        className="claim-breakdownTotalLoss__data--row--input"
                        label="Total a pagar"
                        disabled
                        value={formatNumber(breakdownState.totalToPay)}
                      ></TextField>
                    </Grid>
                  </Grid>
                </Grid>
                {dataHistoric?.length > 0 && (
                  <Grid className="claim-breakdownTotalLoss__historic">
                    <Grid>
                      <p>HISTÓRICO DE AJUSTES</p>
                    </Grid>
                    <Grid className="claim-breakdownTotalLoss__table">
                      {selectionFields.length > 0 && (
                        <Grid className="claim-breakdownTotalLoss__table--options">
                          <span>{selectionFields.length} items selected</span>
                          <Grid className="claim-breakdownTotalLoss__table--options--actions">
                            <Button
                              text="Eliminar"
                              variant="text"
                              style={{ color: 'white', fontSize: 14 }}
                              onClick={openConfirmDialog}
                            ></Button>
                            <span>|</span>
                            <Button
                              text="Cancel"
                              variant="text"
                              style={{ color: 'white', fontSize: 14 }}
                              onClick={handleCancel}
                            ></Button>
                          </Grid>
                        </Grid>
                      )}
                      <DataTable
                        columns={columns}
                        data={dataHistoric}
                        showCheckBox={true}
                        selectionModel={selectionFields}
                        onRowSelectionModelChange={(rows: any): void =>
                          setSelectionFields(rows)
                        }
                      ></DataTable>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} className="claim-breakdownTotalLoss__footer">
                <Button
                  disabled={validateBreakdown()}
                  text="Guardar Desglose"
                  onClick={onClickSave}
                />
              </Grid>
            </Grid>
          </div>

          <DialogComponent
            messageStyle={{ fontSize: '16px' }}
            message={`¿Desea eliminar los ${selectionFields.length} registros seleccionados?`}
            open={openDialogConfirm}
            handleClose={() => setOpenDialogConfirm(false)}
            handleConfirm={handleConfirm}
          />
        </>
      ) : null}
    </>
  );
};

export default BreakdownTotalLoss;
