import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { TabPanel } from 'components/common/tabpanel/TabPanel';
import style from 'override-mui-styles';
import { useHistory } from 'react-router';
import { ActionType } from 'types/action';
import { IInspection, IThird } from 'types/inspection';
import { FieldSection } from 'types/section-fields';
import SectionFields from '../section/SectionFields';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
  Alert,
} from '@mui/material';
import {
  addThirdToEvent,
  deleteCounterpart,
  IInspectionContext,
  InspectionContext,
  saveInspection,
} from 'context/inspections';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { InspectionDataSections, Rol } from 'types/common';
import { Field } from 'types/form-field';

interface ThirdsFormProps {
  isInspectorForm: boolean;
  sections: FieldSection[];
  setSections: (sections: FieldSection[]) => void;
  fetchInspection?: () => Promise<IInspection | null>;
  formValidation: (field?: Field, isFinish?: boolean) => boolean;
}

const getCounterpartQuery = (query: string) => {
  return Number(new URLSearchParams(query).get('counterpart')) || 0;
};

const ThirdsForm: FunctionComponent<ThirdsFormProps> = ({
  isInspectorForm,
  sections,
  fetchInspection,
  setSections,
  formValidation,
}): JSX.Element => {
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const thirds: IThird[] = inspectionState.currentInspection?.thirds?.length
    ? inspectionState.currentInspection?.thirds
    : [];

  const history = useHistory();
  const counterpart = getCounterpartQuery(history.location.search);
  const isAuditProcess =
    Number(localStorage.getItem('rolId')) === Rol.AUDIT && !isInspectorForm;

  const [tabValue, setTabValue] = useState<number>(0);
  const [thirdsSections, setThirdsSections] = useState(
    sections.filter((s) => s.name === InspectionDataSections.THIRDS),
  );
  const [open, setOpen] = useState(false);
  const [counterpartAdded, setCounterpartAdded] = useState(false);
  const [actionToAdd, setActionToAdd] = useState(false);
  const [requestSave, setRequestSave] = useState(false);
  const [initEmptyArr, setInitEmptyArr] = useState(true);
  const [requireFields, setRequireFields] = useState(false);
  const [moveToLastTab, setMoveToLastTab] = useState(true);

  useEffect(() => {
    if (tabValue === counterpart) {
      return;
    }
    const value = thirds.length > counterpart ? counterpart : 0;
    setTabValue(value);
    history.replace(`${history.location.pathname}?counterpart=${value}`);
  }, [tabValue, thirds, counterpart, history]);

  const classes = style();

  const allProps = (index: number, id?: string) => {
    return {
      id: id || `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const addCounterpart = useCallback(async (): Promise<void> => {
    if (inspectionState.currentInspection !== null) {
      const { EventId } = inspectionState.currentInspection;
      if (EventId !== undefined && EventId > 0) {
        dispatch({
          type: ActionType.SET_LOADING,
          payload: true,
        });

        await addThirdToEvent(EventId);

        setTimeout(async () => {
          if (fetchInspection) {
            await fetchInspection();
          }
          dispatch({
            type: ActionType.SET_LOADING,
            payload: false,
          });

          setMoveToLastTab(true);
        }, 800);
      }
    }
  }, [dispatch, fetchInspection, inspectionState.currentInspection]);

  const setCounterpart = (index: number) => {
    history.replace(`${history.location.pathname}?counterpart=${index}`);
  };

  const parseSections = useCallback(
    (sections: FieldSection[]) => {
      return sections
        .sort((a, b) => a.idOrder - b.idOrder)
        .map((s) => ({
          ...s,
          fields: s.fields.map((f) => ({
            ...f,
            originalValue:
              f.originalValue ?? (thirds[tabValue] as any)?.[f.name],
            value: (thirds[tabValue] as any)?.[f.name],
          })),
        }));
    },
    [tabValue, thirds],
  );

  const handleChangeDiagram = (value: boolean) => {
    setRequestSave(value);
  };

  const handleSectionChange = (thirdSection: FieldSection[]) => {
    const counterpart =
      Number(new URLSearchParams(history.location.search).get('counterpart')) ||
      0;

    const _thirds = [...thirds];

    thirdSection.forEach((s) => {
      s.fields.forEach((f) => {
        (_thirds[counterpart] as any)[f.name] = f.value;
      });
    });
    const newSections = sections.map((section) => {
      const updatedSection = thirdSection.find(
        (updated) => updated.formSectionId === section.formSectionId,
      );

      return updatedSection ? updatedSection : section;
    });

    setThirdsSections([...thirdSection]);
    setSections([...newSections]);
    dispatch({ type: ActionType.SET_INSPECTION_THIRDS, payload: [..._thirds] });
  };

  const removeCounterpart = async () => {
    if (thirds[counterpart].thirdId) {
      await deleteCounterpart(thirds[counterpart].thirdId);
    }

    if (thirds.length > 1 && thirds.length === counterpart + 1) {
      history.replace(
        `${history.location.pathname}?counterpart=${counterpart - 1}`,
      );
    }

    thirds.splice(counterpart, 1);

    dispatch({
      type: ActionType.SET_INSPECTION_THIRDS,
      payload: thirds,
    });
    setOpen(false);
  };

  const validateThirds = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (thirdSections: FieldSection[]): boolean => {
      let isValid = false;

      // Validate ID for Third parties
      if (
        thirds[tabValue] !== null &&
        thirds[tabValue] !== undefined &&
        thirds[tabValue].thirdId !== undefined &&
        thirds[tabValue].thirdId !== null &&
        thirds[tabValue].thirdId > 0
      ) {
        isValid = true;
      }
      setRequireFields(!isValid);

      return isValid;
    },
    [setRequireFields, tabValue, thirds],
  );

  const autoSave = useCallback(async (): Promise<void> => {
    if (validateThirds(thirdsSections)) {
      await saveInspection(
        thirdsSections,
        inspectionState,
        false,
        false,
        dispatch,
        {
          counterpart: tabValue || 0,
        },
      );

      if (!thirds.every((t: IThird) => t.thirdId) && fetchInspection) {
        await fetchInspection();
      }
    }
  }, [
    thirdsSections,
    inspectionState,
    dispatch,
    tabValue,
    thirds,
    fetchInspection,
    validateThirds,
  ]);

  useEffect(() => {
    if (requestSave) {
      autoSave();
      setRequestSave(false);
    }
  }, [autoSave, requestSave]);

  useEffect(() => {
    if (thirds.length === 0 && initEmptyArr) {
      addCounterpart();
      setCounterpartAdded(true);
      setInitEmptyArr(false);
    }

    if (counterpartAdded) {
      autoSave();
      setCounterpartAdded(false);
    }
  }, [
    counterpartAdded,
    autoSave,
    dispatch,
    thirds,
    initEmptyArr,
    addCounterpart,
  ]);

  useEffect(() => {
    if (actionToAdd) {
      addCounterpart();
      setActionToAdd(false);
    }
  }, [actionToAdd, setActionToAdd, addCounterpart]);

  useEffect(() => {
    if (moveToLastTab) {
      let tab = thirds.length - 1;
      tab = tab < 0 ? 0 : tab;

      history.replace(`${history.location.pathname}?counterpart=${tab}`);
      setMoveToLastTab(false);
    }
  }, [moveToLastTab, thirds, history]);

  useEffect(() => {
    if (isAuditProcess && inspectionState.auditEvent.auditThirdSection) {
      dispatch({
        type: ActionType.SET_AUDIT_TRIGGER_THIRD_SECTION,
        payload: false,
      });
      setRequestSave(true);
    } else if (isAuditProcess && requestSave) {
      autoSave();
      setRequestSave(false);
    }
  }, [
    setRequestSave,
    dispatch,
    inspectionState.auditEvent.auditThirdSection,
    isAuditProcess,
    autoSave,
    requestSave,
  ]);

  useEffect(() => {
    formValidation();
  }, [sections]);

  return (
    <div className="dinamic-tab-form">
      {requireFields && (
        <Alert severity="error" onClose={() => setRequireFields(false)}>
          Se detectaron campos requeridos en el formulario
        </Alert>
      )}
      <div className="heading">
        <Tabs
          value={tabValue}
          onChange={(event: any, newValue: number) => setTabValue(newValue)}
          aria-label=""
          variant="scrollable"
          scrollButtons="auto"
        >
          {thirds.map((t, i) => (
            <Tab
              key={i}
              label={`Contraparte ${i + 1}`}
              {...allProps(i)}
              onClick={() => setCounterpart(i)}
            />
          ))}
        </Tabs>
        <div className="buttons">
          <div onClick={addCounterpart} onKeyDown={addCounterpart}>
            <AddCircleOutlineIcon />
          </div>
          <div onClick={() => setOpen(true)} onKeyDown={() => setOpen(true)}>
            <RemoveCircleOutlineIcon />
          </div>
        </div>
      </div>
      {thirds.map((t, i) => (
        <TabPanel key={i} value={tabValue} index={i}>
          {parseSections(thirdsSections).map((s, j) => (
            <Fragment key={j}>
              <div className="title-container">
                <h2 className="inspectionForm__title">{s.title}</h2>
              </div>
              <Divider />
              <SectionFields
                key={j}
                sections={parseSections(thirdsSections)}
                fields={s.fields}
                save={autoSave}
                classes={classes}
                setSections={handleSectionChange}
                isInspectorForm={isInspectorForm}
                isVirtualInspector={Boolean(
                  inspectionState.currentInspection?.IsVitualInspector,
                )}
                options={{ fromThirds: true }}
                formValidation={formValidation}
                handleChangeDiagram={handleChangeDiagram}
              />
            </Fragment>
          ))}
        </TabPanel>
      ))}

      <Dialog
        disableEscapeKeyDown
        maxWidth="md"
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">
          Eliminar Contraparte
        </DialogTitle>
        <DialogContent>
          ¿Seguro que desea eliminar la Contraparte {counterpart + 1}?
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="outlined"
            onClick={() => setOpen(false)}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={removeCounterpart}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ThirdsForm;
