// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Companies = (
  filterByCompanyIdList = false,
  filterByCurrentCountryCode = false,
) => {
  const dataCompaniesStr = localStorage.getItem('companies');
  const companyIdList = localStorage.getItem('companyIdList');
  const currentCountryCode = localStorage.getItem('currentCountryCode');
  let data: any[] = [];

  if (dataCompaniesStr) {
    data = Array.from(JSON.parse(dataCompaniesStr));
  }

  if (companyIdList && filterByCompanyIdList) {
    const companyIdListArray = companyIdList.split(',');
    data = data.filter((f) => companyIdListArray.includes(f.id.toString()));
  }

  if (currentCountryCode && filterByCurrentCountryCode) {
    data = data.filter((f) => f.code === currentCountryCode);
  }

  return data;
};

export default Companies;
